// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.contactUs_formTitle__7FsL\\+ {
  font-size: var(--font-28);
  font-weight: var(--font-semibold);
  padding-top: 20px;
  margin-bottom: 40px;
}

.contactUs_contactContainer__GiD4y {
  display: flex;
  flex-direction: row;
  margin-bottom: 30px;
}
.contactUs_contactContainer__GiD4y .contactUs_iconCustomClass__vj6HR {
  margin-top: 12px;
  width: 20px;
  height: 20px;
}
.contactUs_contactContainer__GiD4y .contactUs_infoContainer__MhIy8 {
  margin-left: 20px;
}
.contactUs_contactContainer__GiD4y .contactUs_infoContainer__MhIy8 .contactUs_infoTitle__yd4rF {
  color: var(--black2);
  font-size: var(--font-16);
  font-weight: var(--font-normal);
}
.contactUs_contactContainer__GiD4y .contactUs_infoContainer__MhIy8 .contactUs_infoText__YyyGQ {
  font-feature-settings: ;
  font-variant: var(--grey4);
  font-weight: var(--font-normal);
  font-size: var(--font-14);
  margin-top: 5px;
  max-width: 300px;
}

.contactUs_goBackButton__3jSDx {
  margin: 20px 0px 0px 0px !important;
  width: 100% !important;
  max-width: 300px !important;
  cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/pages/login/contact-us/contactUs.module.scss"],"names":[],"mappings":"AAAA;EACE,yBAAA;EACA,iCAAA;EACA,iBAAA;EACA,mBAAA;AACF;;AAEA;EACE,aAAA;EACA,mBAAA;EACA,mBAAA;AACF;AAAE;EACE,gBAAA;EACA,WAAA;EACA,YAAA;AAEJ;AAAE;EACE,iBAAA;AAEJ;AADI;EACE,oBAAA;EACA,yBAAA;EACA,+BAAA;AAGN;AADI;EACE,uBAAA;EAAA,0BAAA;EACA,+BAAA;EACA,yBAAA;EACA,eAAA;EACA,gBAAA;AAGN;;AAEA;EACE,mCAAA;EACA,sBAAA;EACA,2BAAA;EACA,eAAA;AACF","sourcesContent":[".formTitle {\n  font-size: var(--font-28);\n  font-weight: var(--font-semibold);\n  padding-top: 20px;\n  margin-bottom: 40px;\n}\n\n.contactContainer {\n  display: flex;\n  flex-direction: row;\n  margin-bottom: 30px;\n  .iconCustomClass {\n    margin-top: 12px;\n    width: 20px;\n    height: 20px;\n  }\n  .infoContainer {\n    margin-left: 20px;\n    .infoTitle {\n      color: var(--black2);\n      font-size: var(--font-16);\n      font-weight: var(--font-normal);\n    }\n    .infoText {\n      font-variant: var(--grey4);\n      font-weight: var(--font-normal);\n      font-size: var(--font-14);\n      margin-top: 5px;\n      max-width: 300px;\n    }\n  }\n}\n\n.goBackButton {\n  margin: 20px 0px 0px 0px !important;\n  width: 100% !important;\n  max-width: 300px !important;\n  cursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"formTitle": `contactUs_formTitle__7FsL+`,
	"contactContainer": `contactUs_contactContainer__GiD4y`,
	"iconCustomClass": `contactUs_iconCustomClass__vj6HR`,
	"infoContainer": `contactUs_infoContainer__MhIy8`,
	"infoTitle": `contactUs_infoTitle__yd4rF`,
	"infoText": `contactUs_infoText__YyyGQ`,
	"goBackButton": `contactUs_goBackButton__3jSDx`
};
export default ___CSS_LOADER_EXPORT___;
