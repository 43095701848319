// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.infoTooltip_informationContainer__zyFBE {
  position: relative;
  margin-right: 15px;
}
.infoTooltip_informationContainer__zyFBE .infoTooltip_infoTitle__UaOk6 {
  margin-right: 8px;
  color: var(--black1);
  font-size: var(--font-12);
  font-weight: var(--font-medium);
}
.infoTooltip_informationContainer__zyFBE .infoTooltip_infoChildren__rfbob {
  position: absolute;
  z-index: 1;
  top: 37px;
  left: -40px;
}`, "",{"version":3,"sources":["webpack://./src/components/common/info-tooltip/infoTooltip.module.scss","webpack://./src/styles/mixin.scss"],"names":[],"mappings":"AAEA;EACE,kBAAA;EACA,kBAAA;AADF;AAEE;EACE,iBAAA;ECIF,oBDHgB;ECIhB,yBDJ+B;ECK/B,+BDL+C;AAEjD;AAAE;EACE,kBAAA;EACA,UAAA;EACA,SAAA;EACA,WAAA;AAEJ","sourcesContent":["@import '../../../styles/mixin.scss';\n\n.informationContainer {\n  position: relative;\n  margin-right: 15px;\n  .infoTitle {\n    margin-right: 8px;\n    @include font(var(--black1), var(--font-12), var(--font-medium));\n  }\n  .infoChildren {\n    position: absolute;\n    z-index: 1;\n    top: 37px;\n    left: -40px;\n  }\n}\n","@mixin pageWrapper($padding) {\n  background: var(--white2);\n  // backdrop-filter: blur(6px);\n  box-shadow: 0px 10px 117px 14px rgba(0, 0, 0, 0.05);\n  border-radius: 10px;\n  padding: $padding;\n  margin: 20px 20px 30px 0px;\n}\n\n@mixin font($color, $size, $weight) {\n  color: $color;\n  font-size: $size;\n  font-weight: $weight;\n}\n\n@mixin flexCenter {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}\n\n@mixin flexVertical {\n  display: flex;\n  align-items: center;\n}\n\n@mixin flexColumn {\n  display: flex;\n  flex-direction: column;\n}\n\n@mixin responsive($width) {\n  @media (max-width: $width) {\n    @content;\n  }\n}\n\n@mixin animation($name) {\n  @keyframes #{$name} {\n    @content;\n  }\n}\n\n@mixin iconHoverText($content) {\n  cursor: pointer;\n\n  &:hover::after {\n    @include font(var(--black2), var(--font-12), var(--font-semibold));\n    @include flexCenter;\n    position: absolute;\n    content: $content;\n    background-color: var(--white1);\n    box-shadow: 0px 4px 34px rgba(0, 0, 0, 0.1);\n    border-radius: 6px;\n    width: 116px;\n    height: 36px;\n    z-index: 1;\n    opacity: 1;\n    margin: -25px 0 0 25px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"informationContainer": `infoTooltip_informationContainer__zyFBE`,
	"infoTitle": `infoTooltip_infoTitle__UaOk6`,
	"infoChildren": `infoTooltip_infoChildren__rfbob`
};
export default ___CSS_LOADER_EXPORT___;
