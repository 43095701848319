import {
  ADD_COMMON_EMPLOYEE_ATTENDANCE_TYPE,
  GET_ALL_EMPLOYEE_ATTENDANCE_TYPE,
  GET_MONTHLY_EMMPLOYEE_ATTENDANCE_TYPE,
} from '../../../constants/asyncActionsType'
import createAsyncThunkForSlice from '../../../utils/utils'
import {
  addAllEmployeeAttendance,
  getAllEmployeeAttendance,
  getMonthlyEmployeeAttendance,
} from './attendanceCrud'

// GET ALL EMPLOYEE ATTENDANCE
export const getAllEmployeeAttendanceAction = createAsyncThunkForSlice(
  GET_ALL_EMPLOYEE_ATTENDANCE_TYPE,
  getAllEmployeeAttendance
)

// ADD ALL EMPLOYEE ATTENDANCE
export const addAllEmployeeAttendanceAction = createAsyncThunkForSlice(
  ADD_COMMON_EMPLOYEE_ATTENDANCE_TYPE,
  addAllEmployeeAttendance,
  {
    isToast: true,
  }
)

// GET MONTHLY EMPLOYEE ATTENDANCE
export const getMonthlyEmployeeAttendanceAction = createAsyncThunkForSlice(
  GET_MONTHLY_EMMPLOYEE_ATTENDANCE_TYPE,
  getMonthlyEmployeeAttendance
)
