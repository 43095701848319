import { FC } from "react";
import { Outlet } from "react-router-dom";

interface IAttendanceLayout {}

const AttendaceLayout: FC<IAttendanceLayout> = (props) => {
  return <Outlet />;
};

export default AttendaceLayout;
