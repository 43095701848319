import {
  ADD_EMPLOYEE,
  DELETE_EMPLOYEE,
  EDIT_EMPLOYEE_BY_ID,
  GET_ALL_EMPLOYEE,
  GET_ALL_REPORTING_USER,
  GET_ALL_SEARCH_USER,
  UPDATE_EMPLOYEE_STATUS,
} from '../../../constants/asyncActionsType'
import createAsyncThunkForSlice from '../../../utils/utils'
import {
  addEmployees,
  deleteEmployees,
  editEmployeeByIds,
  getAllEmployeeList,
  getAllReportingUser,
  getAllSearchUser,
  updateEmployeeStatuss,
} from './employeeCrud'

export const getAllEmployee = createAsyncThunkForSlice(
  GET_ALL_EMPLOYEE,
  getAllEmployeeList,
  {
    isToast: false,
  }
)

export const addEmployee = createAsyncThunkForSlice(
  ADD_EMPLOYEE,
  addEmployees,
  {
    isToast: true,
  }
)

export const editEmployeeInfo = createAsyncThunkForSlice(
  EDIT_EMPLOYEE_BY_ID,
  editEmployeeByIds,
  {
    isToast: true,
  }
)

export const updateEmployeeStatus = createAsyncThunkForSlice(
  UPDATE_EMPLOYEE_STATUS,
  updateEmployeeStatuss,
  {
    isToast: true,
  }
)
export const deleteEmployee = createAsyncThunkForSlice(
  DELETE_EMPLOYEE,
  deleteEmployees,
  {
    isToast: true,
  }
)
export const getAllSearchUserList = createAsyncThunkForSlice(
  GET_ALL_SEARCH_USER,
  getAllSearchUser
)
export const getAllReportingUserList = createAsyncThunkForSlice(
  GET_ALL_REPORTING_USER,
  getAllReportingUser
)
