import { createSlice } from '@reduxjs/toolkit'
import {
  agentAdminGetData,
  callCenterAgentCount,
  callCenterAgentLead,
  callingApi,
  getAllAgent,
} from './agentAsyncActions'
export interface IInternal {
  isLoading: boolean
  allAgentData: {}
  documentDetail: {}
  addDocumentData: []
  editDocumentData: {}
  error: any
  isStatusUpdated: boolean
  getAllDocumentPaylod: {}
  generateToken: string
  callCenterAgentLeads: []
  agentLeadType: string
  agentLeadCounts: {}
  getLeadDataById: {}
  device: any
  callStatus: string
  adminAgentDetails: []
  agentsCategory: string
}
export const initialState: IInternal = {
  isLoading: false,
  allAgentData: [],
  documentDetail: {},
  addDocumentData: [],
  editDocumentData: {},
  isStatusUpdated: true,
  error: null,
  getAllDocumentPaylod: {},
  generateToken: '',
  callCenterAgentLeads: [],
  agentLeadType: 'ONGOING',
  agentLeadCounts: {},
  getLeadDataById: {},
  device: null,
  callStatus: 'Calling',
  adminAgentDetails: [],
  agentsCategory: 'CAMPAIGNS',
}

export const agentSlice = createSlice({
  name: 'agent',
  initialState,
  reducers: {
    clearAgentData: (state) => {
      state.allAgentData = []
      state.documentDetail = {}
      state.getAllDocumentPaylod = {}
    },
    setAgentLeadType: (state, action) => {
      state.agentLeadType = action.payload
    },
    setGetLeadDataById: (state, action) => {
      state.getLeadDataById = action.payload
    },
    setDevice: (state, action) => {
      state.device = action.payload
    },
    setCallStatus: (state, action) => {
      state.callStatus = action.payload
    },
    setAgentsCategory: (state, action) => {
      state.agentsCategory = action.payload
    },
  },
  extraReducers(builder) {
    // GET ALL Criteria
    builder
      .addCase(getAllAgent.pending, (state) => {
        state.isLoading = true
        state.isStatusUpdated = false
      })
      .addCase(getAllAgent.fulfilled, (state, action) => {
        state.isLoading = false
        state.isStatusUpdated = false
        state.allAgentData = action.payload?.data
      })
      .addCase(getAllAgent.rejected, (state, error) => {
        state.isLoading = false
        state.error = error
        state.isStatusUpdated = false
      })

    //generate token
    builder
      .addCase(callingApi.pending, (state) => {
        state.isLoading = true
      })
      .addCase(callingApi.fulfilled, (state, action) => {
        state.isLoading = false
        state.generateToken = action.payload.token
      })
      .addCase(callingApi.rejected, (state, error) => {
        state.isLoading = false
        state.error = error
      })

    //call center agent leads data
    builder
      .addCase(callCenterAgentLead.pending, (state) => {
        state.isLoading = true
      })
      .addCase(callCenterAgentLead.fulfilled, (state, action) => {
        state.isLoading = false
        state.callCenterAgentLeads = action.payload.data
      })
      .addCase(callCenterAgentLead.rejected, (state, error) => {
        state.isLoading = false
        state.error = error
      })

    //call center agent counts
    builder
      .addCase(callCenterAgentCount.pending, (state) => {
        state.isLoading = true
      })
      .addCase(callCenterAgentCount.fulfilled, (state, action) => {
        state.isLoading = false
        state.agentLeadCounts = action.payload
      })
      .addCase(callCenterAgentCount.rejected, (state, error) => {
        state.isLoading = false
        state.error = error
      })

    builder
      .addCase(agentAdminGetData.pending, (state) => {
        state.isLoading = true
      })
      .addCase(agentAdminGetData.fulfilled, (state, action) => {
        state.isLoading = false
        state.adminAgentDetails = action.payload.data ?? []
      })
      .addCase(agentAdminGetData.rejected, (state, error) => {
        state.isLoading = false
        state.error = error
      })
  },
})
export const {
  clearAgentData,
  setAgentLeadType,
  setGetLeadDataById,
  setDevice,
  setCallStatus,
  setAgentsCategory,
} = agentSlice.actions
export default agentSlice.reducer
