import { FC, useState, useEffect } from 'react'
import {
  CloseIcon,
  PrintIcon,
  ExportIcon,
  CopyIcon,
} from '../../../components/common/svg-components'

import { receiptPaymentModalHeaderData } from '../../../constants/table-data/receiptPaymentModalHeaderData'
import { useAppDispatch, useAppSelector } from '../../../hooks/index'
import { colors } from '../../../constants/color'
import styles from './receiptPaymentModal.module.scss'
import { handlePadStart, hanleCopy } from '../../../utils/utils'
import TableV2 from '../../../components/common/table/tableV2/TableV2'
import Loader from '../../../components/common/spinner/Loader'
import Button from '../../../components/common/button/Button'
import {
  addReceiptPaymentModeData,
  updateTotalAdvanceAndRefundAmount,
  clearSelectedInvoiceData,
  updateTotalOutstandingAmount,
  addExistingReceiptNoOutstanding,
  clearReceiptData,
} from '../../../redux/features/receipt/receiptSlice'
import ToolTip from '../../../components/common/toolTip/ToolTip'
import { useTranslation } from 'react-i18next'
import useCurrency from '../../../hooks/useCurrency'

interface IReceiptPaymentModal {
  handleOpen: any
  handleSubmitData: any
  handleYes: any
  handleClose: (e?: React.MouseEvent<HTMLElement>) => void
  popData: any
  show?: any
  setShow?: any
  handleSubmit?: any
}

const ReceiptPaymentModal: FC<IReceiptPaymentModal> = ({
  handleOpen,
  handleClose,
  handleSubmitData,
  handleYes,
  popData,
  show,
  setShow,
  handleSubmit,
}) => {
  const dispatch = useAppDispatch()
  const { formatCurrency } = useCurrency()
  const { t } = useTranslation()
  const {
    isLoading,
    receiptPaymentModeData,
    totalOutstandingAmount,
    totalAdvanceAndRefundAmount,
    entryReceiptAdvanceUpayData,
    // createReceiptOutStandingData,
    // createReceiptAdvanceData,
    // createReceiptRefundData,
  } = useAppSelector((state) => state.receipt)
  const { toolTipMessage } = useAppSelector((state) => state.toast)

  const { patientDataObjectById } = useAppSelector((state) => state?.patient)
  // state variables
  // const [outStandingReceiptData, setOutStandingReceiptData] = useState([]);
  // const [advanceAndRefundReceiptData, setAdvanceAndRefundReceiptData] =
  //   useState<string>("");
  const [disableReceiptSubmit, setDisableReceiptSubmit] =
    useState<boolean>(true)

  // useEffect for disable submit receipt button
  useEffect(() => {
    if (totalAdvanceAndRefundAmount <= 0) {
      setDisableReceiptSubmit(true)
    } else if (
      popData?.receipt_type === 'OUTSTANDING' &&
      totalOutstandingAmount < totalAdvanceAndRefundAmount
    ) {
      setDisableReceiptSubmit(true)
    } else {
      setDisableReceiptSubmit(false)
    }
  }, [popData, totalAdvanceAndRefundAmount, totalOutstandingAmount])

  // useEffect for default add field in refund
  useEffect(() => {
    if (popData?.receipt_type === 'REFUND') {
      let selectedPaymentModeData = {
        _id: 0,
        payment_mode: 'cash',
        payment_label: 'Cash',
        amount: 0,
      }
      dispatch(addReceiptPaymentModeData(selectedPaymentModeData))
    }
  }, [dispatch, popData])

  // function to create receipt number array
  // useEffect(() => {
  //   if (
  //     popData?.receipt_type === "OUTSTANDING" &&
  //     popData?.selectedInvoiceData?.length > 0
  //   ) {
  //     const receiptArray = popData?.selectedInvoiceData?.map(
  //       (item: any, index: any) => {
  //         const sixDigitString = item?.receipt_no?.toString().padStart(6, "0");
  //         return sixDigitString;
  //       }
  //     );
  //     setOutStandingReceiptData(receiptArray);
  //     dispatch(addOutstaningReceiptData(receiptArray));
  //   }
  // }, [dispatch, popData]);

  // function to create receipt number for advance and refund
  // useEffect(() => {
  //   if (popData?.receipt_type === "OUTSTANDING") {
  //     const sixDigitString = createReceiptOutStandingData?.receipt_no
  //       ?.toString()
  //       .padStart(6, "0");
  //     setAdvanceAndRefundReceiptData(sixDigitString);
  //   } else if (popData?.receipt_type === "ADVANCE") {
  //     const sixDigitString = createReceiptAdvanceData?.receipt_no
  //       ?.toString()
  //       .padStart(6, "0");
  //     setAdvanceAndRefundReceiptData(sixDigitString);
  //   } else if (popData?.receipt_type === "REFUND") {
  //     const sixDigitString = createReceiptRefundData?.receipt_no
  //       ?.toString()
  //       .padStart(6, "0");
  //     setAdvanceAndRefundReceiptData(sixDigitString);
  //   }
  // }, [
  //   popData,
  //   createReceiptOutStandingData,
  //   createReceiptAdvanceData,
  //   createReceiptRefundData,
  // ]);

  // useEffect for count total amount in advance and refund.
  useEffect(() => {
    let addPaymentModeSum = 0
    if (receiptPaymentModeData.length > 0) {
      for (let i = 0; i < receiptPaymentModeData.length; i++) {
        addPaymentModeSum += Number(receiptPaymentModeData[i]?.amount)
      }
      let selectedInvoiceSumToFixed = Number(addPaymentModeSum.toFixed(3))
      dispatch(updateTotalAdvanceAndRefundAmount(selectedInvoiceSumToFixed))
    } else {
      dispatch(updateTotalAdvanceAndRefundAmount(0))
    }
  }, [dispatch, receiptPaymentModeData])

  const sendSMS = () => {
    if (handleSubmit) {
      const payload = {
        ...popData,
        patient_default_branch_id: popData?.branch_type,
        email: popData?.patient_email,
        transaction_id: entryReceiptAdvanceUpayData?.transaction_id,
      }
      handleSubmit(payload)
    }
  }

  // clear receipt data on component remove
  useEffect(() => {
    return () => {
      dispatch(clearSelectedInvoiceData())
      dispatch(updateTotalOutstandingAmount(undefined))
      dispatch(addExistingReceiptNoOutstanding({}))
      setShow(false)
    }
  }, [])

  return (
    <>
      {isLoading && <Loader />}
      <div
        className={styles.receiptPaymentModalContainer}
        onClick={(e) => {
          e.stopPropagation()
        }}
      >
        <CloseIcon
          customClass={styles.closeIconStyle}
          fillColor={colors.green1}
          handleClick={() => {
            handleClose()
            setShow(false)
          }}
        />
        <h1 className={styles.receiptPaymentModalHeading}>
          {t('ReceiptPayment.Make Payment')}
        </h1>
        <hr className={styles.receiptPaymentModalDivider} />
        {/* {popData?.receipt_type === "OUTSTANDING" && (
          <div className={styles.outStandingReceiptNoContainer}>
            <p className={styles.outStandingReceiptNoLabel}>Receipt No :</p>
            <div className={styles.outStandingReceiptNoTxtContainer}>
              {outStandingReceiptData?.map((item: any, index: number) => (
                <p className={styles.outStandingReceiptNo} key={index}>
                  {item}
                  {index < outStandingReceiptData?.length - 1 ? "," : ""}
                </p>
              ))}
            </div>
          </div>
        )} */}
        {/* <div className={styles.receiptNoContainer}>
          <p className={styles.receiptNoLabel}>Receipt No :</p>
          <p className={styles.receiptNo}>{advanceAndRefundReceiptData}</p>
        </div> */}
        <div className={styles.receiptPaymentContainer}>
          {show ? (
            <div className={styles.upayLinkContainer}>
              <div className={styles.upayContainer}>
                <span className={styles.upayLabel}>
                  {t('ViewReceipt.Receipt No')}{' '}
                </span>
                <span className={styles.receiptNo}>
                  {handlePadStart(entryReceiptAdvanceUpayData?.receipt_no)}
                </span>
              </div>
              <div className={styles.upayContainer}>
                <span className={styles.upayLabel}>
                  {t('InvoicePayment.Upay Link')} :{' '}
                </span>
                <div className={styles.link}>
                  {entryReceiptAdvanceUpayData?.upay_link}
                </div>
                <CopyIcon
                  fillColor={colors.grey2}
                  handleClick={() => {
                    hanleCopy(
                      entryReceiptAdvanceUpayData?.upay_link,
                      t('QrCode.Copied')
                    )
                  }}
                  width={18}
                  height={18}
                />
                {toolTipMessage && <ToolTip customClass={styles.toolTip} />}
              </div>
              <div className={styles.buttonContainer}>
                {' '}
                <Button
                  title={t('OnlinePayment.Send Msg') || 'Send Msg'}
                  handleClick={sendSMS}
                />
                <Button
                  title={t('ActionBar.Exit') || 'Exit'}
                  customClass={styles.resetButtonStyle}
                  handleClick={() => {
                    dispatch(clearSelectedInvoiceData())
                    dispatch(updateTotalOutstandingAmount(undefined))
                    dispatch(addExistingReceiptNoOutstanding({}))
                    setShow(false)
                    dispatch(clearReceiptData())
                    handleClose()
                  }}
                />
              </div>
            </div>
          ) : (
            <>
              <div className={styles.receiptPaymentInfoContainer}>
                {popData?.receipt_type === 'OUTSTANDING' && (
                  <h1 className={styles.receiptPaymentOutstandingAmountDetail}>
                    {t('InvoicePayment.Outstanding Amount')}
                    {formatCurrency(totalOutstandingAmount) || 0}
                  </h1>
                )}

                {patientDataObjectById?.advance_amount ? (
                  <h1 className={styles.receiptPaymentAdvanceAmountDetail}>
                    {t('ReceiptPayment.Advance Amount')}
                    {formatCurrency(patientDataObjectById?.advance_amount) || 0}
                  </h1>
                ) : (
                  ''
                )}
                {popData?.receipt_type === 'REFUND' && (
                  <h1 className={styles.receiptPaymentRefundAmountDetail}>
                    {t('ReceiptPayment.Refund Amount')}
                    {formatCurrency(totalAdvanceAndRefundAmount) || 0}
                  </h1>
                )}
                <div className={styles.receiptPaymentBtnContainer}>
                  {popData?.receipt_type !== 'REFUND' && !show && (
                    <Button
                      title={
                        t('InvoicePayment.Add Payment Mode') ||
                        'Add Payment Mode'
                      }
                      type="button"
                      customClass={styles.AddPaymentModeBtn}
                      handleClick={handleOpen}
                    />
                  )}
                  {!show && (
                    <>
                      <PrintIcon customClass={styles.stylePrint} />
                      <ExportIcon customClass={styles.styleExport} />
                    </>
                  )}
                </div>
              </div>
              <div className={styles.receiptPaymentInfoTableContainer}>
                {popData?.receipt_type !== 'REFUND' && !show && (
                  <p
                    className={styles.receiptPaymentLink}
                    onClick={handleSubmitData}
                  >
                    {t('InvoicePayment.ClickURL')}
                  </p>
                )}
                <div className={styles.receiptPaymentTableContainer}>
                  <TableV2
                    tableHeaderData={receiptPaymentModalHeaderData}
                    tableRowData={receiptPaymentModeData}
                    active={false}
                    handleClick={popData}
                  />
                </div>
                <div className={styles.receiptPaymentSubmitBtnContainer}>
                  <Button
                    title={t('Common.Submit') || 'Submit'}
                    type="button"
                    customClass={styles.receiptPaytmentSubmitBtn}
                    handleClick={handleYes}
                    disable={disableReceiptSubmit}
                  />
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  )
}

export default ReceiptPaymentModal
