import { t } from 'i18next'
import {
  ACCOUNT_HOLDER_NAME,
  ACCOUNT_NUMBER,
  ANNUAL_LEAVES,
  BANK_IDENTIFIER_CODE,
  BANK_NAME,
  BASIC_SALARY,
  BIRTH_DATE,
  BRANCH,
  BRANCH_LOCATION,
  CIVIL_ID,
  DATE_OF_JOINING,
  DEPARTMENT,
  DESIGNATION,
  EMPLOYEES_ID,
  GENDER,
  LOCAL_ADDRESS,
  NAME_AR,
  NAME_EN,
  NATIONALITY,
  OVERTIME_TYPE,
  PAYMENT_MEDIUM,
  PHONE,
  PROBATION_END_DATE,
  PROBATION_PERIOD,
  REPORTING_TO,
  SHIFT_TYPE,
  SUB_COMPANY,
  TAX_PAYER_ID,
  WEEKEND,
  EMPLOYEE_NATIONAL_ID,
  SALARY_TYPE,
  HOURLY_SALARY,
  EMPLOYEE_PROFILE_PIC,
  ALLOWANCE_NAME,
  ALLOWANCE_VALUE,
  DEDUCTION_NAME,
  DEDEUCTION_VALUE,
} from '../../employees//employeeConstants'

export const addEmployeeDetailsValidators = {
  [BRANCH]: {
    required: '',
  },

  [EMPLOYEES_ID]: {
    required: '',
  },

  [NAME_EN]: {
    required: '',
  },
  [NAME_AR]: {
    required: t('AddEmpValidator.ArabicName'),
  },
  [BIRTH_DATE]: {
    required: t('PatientEmrValidators.DOB'),
    validate: {
      futureDate: (value: any) => {
        const currentDate = new Date()
        const selectedDate = new Date(value)
        if (selectedDate > currentDate) {
          return t('PatientEmrValidators.DOBFuture')
        }
        return true
      },
    },
  },
  [GENDER]: {
    required: t('PatientEmrValidators.Gender'),
  },
  [PHONE]: {
    required: t('PatientEmrValidators.MOB1'),
    minLength: {
      value: 7,
      message: t('AddEmpValidator.MinPhone'),
    },
    maxLength: {
      value: 16,
      message: t('AddEmpValidator.MaxPhone'),
    },
    // pattern: {
    //   value: /^\+?[1-9]\d{1,14}$/,
    //   message: "Invalid phone number"
    // }
  },
  [NATIONALITY]: {
    required: t('PatientEmrValidators.Nationality'),
  },
  [EMPLOYEE_NATIONAL_ID]: {
    required: t('AddEmpValidator.CivilId'),
    minLength: {
      value: 9,
      message: t('AddEmpValidator.MinCivil'),
    },
    maxLength: {
      value: 18,
      message: t('AddEmpValidator.MaxCivil'),
    },
    validate: {
      handleValue: (value: any) => {
        if (Number(value) < 0) {
          return t('LabConfiguration.positive_value')
        }
      },
    },
    // pattern: {
    //   value: /^[A-Za-z0-9]{10}$/,
    //   message: "Civil ID must contain only letters and digits"
    // }
  },
  [LOCAL_ADDRESS]: {
    required: t('AddEmpValidator.LocalAdd'),
  },
  [EMPLOYEE_PROFILE_PIC]: {
    required: t('AddEmpValidator.ProfilePic'),
    validate: {
      fileSize: (file: FileList) => {
        return (
          file[0].size <= 2 * 1024 * 1024 ||
          t('UploadImageValidators.UploadImgSize')
        )
      },
      fileType: (file: FileList) =>
        /jpeg|png/.test(file[0].type) ||
        t('UploadImageValidators.UploadImgType'),
    },
  },
  // Company Details
  [DEPARTMENT]: {
    required: t('DepartmentSetup.Dept validation'),
  },
  [DESIGNATION]: {
    required: t('ManageStaff.Designation_validation'),
  },
  [REPORTING_TO]: {
    required: t('AddEmpValidator.ReportingTo'),
  },
  [OVERTIME_TYPE]: {
    required: t('AddEmpValidator.OverTime'),
  },
  [SHIFT_TYPE]: {
    required: '',
  },
  [SUB_COMPANY]: {
    required: '',
  },
  [ANNUAL_LEAVES]: {
    required: t('AddEmpValidator.AnnualLeave'),
  },
  [DATE_OF_JOINING]: {
    required: t('AddEmpValidator.DateofJoining'),
  },

  [PROBATION_END_DATE]: {
    required: '',
  },
  [PROBATION_PERIOD]: {
    required: '',
  },
  [WEEKEND]: {
    required: t('AddEmpValidator.Weekend'),
  },
  [SALARY_TYPE]: {
    required: '',
  },
  [BASIC_SALARY]: {
    required: t('AddEmpValidator.BasicSalary'),
  },
  [HOURLY_SALARY]: {
    required: t('AddEmpValidator.HourlyRate'),
  },
  [PAYMENT_MEDIUM]: {
    required: t('AddEmpValidator.PaymentMed'),
  },
  [ALLOWANCE_NAME]: {
    required: '',
  },
  [ALLOWANCE_VALUE]: {
    required: '',
  },
  [DEDUCTION_NAME]: {
    required: '',
  },
  [DEDEUCTION_VALUE]: {
    required: '',
  },

  // Bank Details
  [ACCOUNT_HOLDER_NAME]: {
    required: '',
    pattern: {
      value: /^[a-zA-Z\s]*$/,
      message: t('AddEmpValidator.AccHolder'),
    },
  },
  [ACCOUNT_NUMBER]: {
    required: '',
    minLength: {
      value: 5,
      message: t('AddEmpValidator.MinAccNo'),
    },
    maxLength: {
      value: 17,
      message: t('AddEmpValidator.MaxNo'),
    },
  },
  [BANK_NAME]: {
    required: '',
    pattern: {
      value: /^[a-zA-Z\s]*$/,
      message: t('AddEmpValidator.AccHolder'),
    },
  },
  [BANK_IDENTIFIER_CODE]: {
    required: '',
  },
  [BRANCH_LOCATION]: {
    required: '',
  },
  [TAX_PAYER_ID]: {
    required: '',
  },
}
