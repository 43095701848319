import {
  EMPLOYEES_BENIFT,
  EMPLOYEES_NAME,
  EMP_LEAVE,
  EMP_SALARY,
  GRATUITY,
  NET_PAYABLE,
  PAYMENT_DETAILS,
  PAYMENT_MODE,
  PAYMENT_STATUS,
  TOTAL_DEDUCTION,
} from '../pages/payroll/add-new/addNewConstant'
import { t } from 'i18next'

export const addnewValidators = {
  [EMPLOYEES_NAME]: {
    required: t('ServiceMaster.service_validation'),
  },
  [EMP_SALARY]: {
    required: t('LeaveManagement.Salary_Validation'),
  },
  [GRATUITY]: {
    required: t('Payroll.gratuity_validation'),
  },
  [EMP_LEAVE]: {
    required: t('Payroll.accured_leave_validation'),
  },
  [EMPLOYEES_BENIFT]: {
    required: t('Payroll.benefits_validation'),
  },
  [TOTAL_DEDUCTION]: {
    required: t('Payroll.deduction_validation'),
  },
  [NET_PAYABLE]: {
    required: t('Payroll.net_payable_validation'),
  },
  [PAYMENT_MODE]: {
    required: t('Payroll.payment_mode_validation'),
  },
  [PAYMENT_DETAILS]: {
    required: t('Payroll.payment_details_validation'),
  },
  [PAYMENT_STATUS]: {
    required: t('Payroll.payment_details_validation'),
  },
}
