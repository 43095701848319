import axios from 'axios'
import {
  APPOINTNMENT_DATA_BY_ID,
  PHYSIO_VIEW_SLOTS,
  PHYSIO_VIEW_SLOTS_RECURRING,
} from '../../../config/config'
import { IAPIPayload } from '../../../interfaces/apiInterface'

// non-recurring
export const physioViewSlotsApi = (data: IAPIPayload) => {
  return axios.post(PHYSIO_VIEW_SLOTS, data)
}

//recurring
export const physioViewSlotsApiRecurring = (data: IAPIPayload) => {
  return axios.post(PHYSIO_VIEW_SLOTS_RECURRING, data)
}
export const appointmentDataById = (data: IAPIPayload) => {
  return axios.post(APPOINTNMENT_DATA_BY_ID, data)
}
