import { FC, useState, useEffect } from 'react'
import TableV2 from '../../../../../components/common/table/tableV2/TableV2'
import { consultationHeaderData } from '../../../../../constants/table-data/consultationTableData'
import Popup from '../../../../../components/common/popup/Popup'
import ConsultationNotesPopup from '../../../../../components/common/modal/consultation-notes-popup/ConsultationNotesPopup'
import NotesPopup from '../../../../../components/common/modal/notes-popup/NotesPopup'
import { useAppDispatch, useAppSelector } from '../../../../../hooks'
import { requestGenerator } from '../../../../../utils/payloadGenerator'
import { getAllIpdMedication } from '../../../../../redux/features/ipd/ipdAsyncActions'
import { useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

interface IConsultation {
  heading?: string
  childern?: any
}

const Consultation: FC<IConsultation> = () => {
  const { t } = useTranslation()
  const [showNotes, setShowNotes] = useState(false)
  const [viewNotes, setViewNotes] = useState(false)
  const [notesData, setNotesData] = useState()
  const [dateNotes, setDateNotes] = useState<any>()
  const { ipdMedicationData } = useAppSelector((state) => state.ipd)
  const dispatch = useAppDispatch()
  const location = useLocation()?.state

  let payloadData = {
    ipd_id: location?.ipd_id,
    patient_id: location?.patient_id,
    date: '',
  }

  useEffect(() => {
    dispatch(getAllIpdMedication(requestGenerator(payloadData)))
  }, [])

  return (
    <>
      {showNotes && (
        <Popup
          Children={ConsultationNotesPopup}
          handleClose={() => setShowNotes(false)}
          handleOpen={(item: any) => {
            setViewNotes(true)
            setDateNotes(item)
          }}
          popData={notesData}
        />
      )}
      {viewNotes && (
        <Popup
          Children={NotesPopup}
          handleClose={() => setViewNotes(false)}
          popData={{
            updatedAt: dateNotes?.date,
            notes: dateNotes,
          }}
        />
      )}
      <TableV2
        tableHeaderData={consultationHeaderData}
        tableRowData={ipdMedicationData}
        active={false}
        handleRowClick={(item: any) => {
          setShowNotes(true)
          setNotesData(item)
        }}
      />
    </>
  )
}

export default Consultation
