// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.buttonGroups_buttonContainer__fsxN\\+ {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.buttonGroups_buttonContainer__fsxN\\+ .buttonGroups_btnOneStyle__oxuqz {
  margin-right: 20px;
}`, "",{"version":3,"sources":["webpack://./src/components/common/button-group/buttonGroups.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,mBAAA;EACA,mBAAA;AACF;AAAE;EACE,kBAAA;AAEJ","sourcesContent":[".buttonContainer {\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  .btnOneStyle {\n    margin-right: 20px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"buttonContainer": `buttonGroups_buttonContainer__fsxN+`,
	"btnOneStyle": `buttonGroups_btnOneStyle__oxuqz`
};
export default ___CSS_LOADER_EXPORT___;
