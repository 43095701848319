import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'

import { initReactI18next } from 'react-i18next'
import translationEnglish from './translation/en/translation.json'
import translationArabic from './translation/ar/translation.json'
const resources = {
  en: {
    translation: translationEnglish,
  },
  ar: {
    translation: translationArabic,
  },
}

const defaultLang = localStorage.getItem('i18nextLng')
i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    resources,
    fallbackLng: defaultLang ? defaultLang : 'en',
    lng: defaultLang ? defaultLang : 'en', //default language
  })

export default i18n

// function definition to use translation without useTranslation hook /
// export const translate = (translationString: string) => {
//   return i18n.t(translationString)
// }
// function definition to use translation without useTranslation hook /
