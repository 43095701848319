import { createSlice } from '@reduxjs/toolkit'
import { getAllTrialBalance } from './trialBalanceAsyncActions'

export interface ITrialBalance {
  isLoading: boolean
  allTrialBalanceData: []
  allTrialBalanceListDataObject: {}
  error: any
  isStatusUpdated: boolean
}
export const initialState: ITrialBalance = {
  isLoading: false,
  allTrialBalanceData: [],
  allTrialBalanceListDataObject: {},
  isStatusUpdated: true,
  error: null,
}

export const trialBalanceSlice = createSlice({
  name: 'trialBalance',
  initialState,
  reducers: {
    clearTrialBalanceData: (state) => {
      state.allTrialBalanceData = []
    },
  },
  extraReducers(builder) {
    // GET ALL Trial Balance
    builder
      .addCase(getAllTrialBalance.pending, (state) => {
        state.isLoading = true
        state.isStatusUpdated = false
      })
      .addCase(getAllTrialBalance.fulfilled, (state, action) => {
        state.isLoading = false
        state.isStatusUpdated = false
        // state.allTrialBalanceData = action.payload?.data
        state.allTrialBalanceData = action.payload
        state.allTrialBalanceListDataObject = action.payload
      })
      .addCase(getAllTrialBalance.rejected, (state, error) => {
        state.isLoading = false
        state.error = error
        state.isStatusUpdated = false
      })
  },
})

export const { clearTrialBalanceData } = trialBalanceSlice.actions
export default trialBalanceSlice.reducer
