import { io } from 'socket.io-client'
export const BASE_URL_SOCKET = process.env.REACT_APP_SOCKET_URL!

let socket: any

async function connectToSocket() {
  socket = io(BASE_URL_SOCKET, {
    transports: ['websocket'],
    withCredentials: true,
    reconnectionDelayMax: 1000,
  })

  socket.on('connect', () => {})
}

export { connectToSocket, socket }
