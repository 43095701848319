import { t } from 'i18next'

export const viewServiceModalHeaderData: any = [
  {
    Header: t('InsurancePlan_Form.SERVICE_ID'),
    accessor: 'service_no',
    Cell: (props: any) => {
      const planAmount = props?.row?.original?.service_no
      return planAmount ? planAmount : '-'
    },
  },
  {
    Header: t('InsurancePlan_Form.SERVICE_NAME'),
    accessor: 'name',
    Cell: (props: any) => {
      return (
        <>
          {props?.row?.original?.name ? (
            <span>{props?.row?.original?.name}</span>
          ) : (
            '-'
          )}
        </>
      )
    },
  },
  {
    Header: t('ServiceMaster.ITEM_ID'),
    accessor: 'item_no',
    Cell: (props: any) => {
      const planAmount = props?.row?.original?.item_no
      return planAmount ? planAmount : '-'
    },
  },
  {
    Header: t('ServiceMaster.ITEM_NAME'),
    accessor: 'item_name',
    Cell: (props: any) => {
      const item_name = props?.row?.original?.item_name
      return item_name ? item_name : '-'
    },
  },
  {
    Header: t('ServiceMaster.SERVICE_PRICE'),
    accessor: 'price',
    Cell: (props: any) => {
      const price = props?.row?.original?.price
      return price ? price : '-'
    },
  },
  {
    Header: t('ServiceMaster.NO_OF_SESSIONS'),
    accessor: 'sessions',
  },
  {
    Header: t('ServiceMaster.SERVICE_AMOUNT'),
    accessor: 'service_amt',
    Cell: (props: any) => {
      const price = props?.row?.original?.price
      const sessions = props?.row?.original?.sessions
      return price && sessions ? price * sessions : '-'
    },
  },
]
