import { SOURCE_EXTERNAL, UPLOAD_CSV_EXTERNAL } from '../constants/constant'
import { t } from 'i18next'

export interface ILeadPoolCsvUploadValidators {
  [UPLOAD_CSV_EXTERNAL]: {
    required: string
    validate: any
  }
  [SOURCE_EXTERNAL]: {
    required: string
  }
}

export const leadPoolCsvUploadValidators: ILeadPoolCsvUploadValidators = {
  [SOURCE_EXTERNAL]: {
    required: t('ReportGenerator.source_validation'),
  },
  [UPLOAD_CSV_EXTERNAL]: {
    required: t('LeadPool.csv_validation'),
    validate: {
      fileSize: (file: FileList) => {
        return (
          file[0].size <= 2 * 1024 * 1024 ||
          t('UploadImageValidators.UploadImgSize')
        )
      },
      fileType: (file: FileList) =>
        /csv/.test(file[0].type) || t('LeadPool.csv_filesize_validation'),
    },
  },
}
