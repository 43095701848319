import { PACKAGE_AMOUNT, TEST_PROFILE_NAME } from '../constants/constant'
import { t } from 'i18next'

export const createTestProfileValidators = {
  [PACKAGE_AMOUNT]: {
    required: t('LabConfiguration.Package_Amount_Validation'),
    pattern: {
      value: /^[0-9]{0,6}(?:\.[0-9]{1,3})?$/,
      message: t('InventoryItemTable.Quantity_Value_Validation'),
    },
  },
  [TEST_PROFILE_NAME]: {
    required: t('LabConfiguration.Test_Profile_Name_Validation'),
  },
}
