import { FC, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import styles from './end-of-services.module.scss'
import ButtonGroups from '../../../components/common/button-group/ButtonGroups'
import Pagination from '../../../components/common/pagination/Pagination'
import { SearchIcon } from '../../../components/common/svg-components'
import TableV2 from '../../../components/common/table/tableV2/TableV2'
import {
  endservcesRowData,
  endservicesTableHeaderData,
} from '../../../constants/table-data/payrollTableHeader'
import { t } from 'i18next'

interface IServices {}

const EndofServices: FC<IServices> = () => {
  const navigate = useNavigate()
  const [showCostViewPopup, setShowCostViewPopup] = useState<boolean>(false)
  const [dataPerPage, setDataPerPage] = useState<number>(10)
  const [totalPage, setTotalPage] = useState<number>(0)
  const [pageIndex, setPageIndex] = useState<number>(1)
  const pageIndexArray = () => {
    let pageIndexOptions = []
    for (let i = 1; i <= totalPage; i++) {
      pageIndexOptions.push(i)
    }
    return pageIndexOptions
  }
  const pageIndexOptions = pageIndexArray()
  const handleCostView = () => {
    setShowCostViewPopup((prevState) => !prevState)
  }
  return (
    <>
      <div className={styles.serviceContainer}>
        <div className={styles.searchContainer}>
          {/* <Search /> */}
          <div className={styles.searchMainContainer}>
            <div className={styles.searchContainer}>
              <input
                className={styles.searchInput}
                type="text"
                placeholder={t('ServiceMaster.Search') || 'Search'}
              />
              <div className={styles.searchButton}>
                <SearchIcon fillColor={'#ffffff'} />
              </div>
            </div>
          </div>
          <div className={styles.searchBtnContainer}>
            <ButtonGroups
              titleOne={t('DoctorDashbord.Add New') || 'Add New'}
              titleTwo={t('Payroll.EOS_Report') || 'EOS Report'}
              btnOneCustomClass={styles.btn1}
              btnTwoCustomClass={styles.btn2}
              handleClickOne={() => {
                navigate('new')
              }}
            />
          </div>
        </div>
        <TableV2
          tableHeaderData={endservicesTableHeaderData}
          tableRowData={endservcesRowData}
          handleClick={handleCostView}
          active={false}
        />
        {endservcesRowData && endservcesRowData.length !== 0 ? (
          <Pagination
            setDataPerPage={setDataPerPage}
            pageIndexOptions={pageIndexOptions}
            pageIndex={pageIndex}
            setPageIndex={setPageIndex}
          />
        ) : (
          ''
        )}
      </div>
    </>
  )
}
export default EndofServices
