import axios from "axios";
import { IAPIPayload } from "../../../interfaces/apiInterface";
import {
  ADD_COMMON_EMPLOYEE_ATTENDANCE,
  GET_ALL_EMPLOYEE_ATTENDANCE,
  GET_MONTHLY_EMMPLOYEE_ATTENDANCE,
} from "../../../config/config";

// GET ALL EMPLOYEE ATTENDANCE
export const getAllEmployeeAttendance = (data: IAPIPayload) => {
  return axios.post(GET_ALL_EMPLOYEE_ATTENDANCE, data);
};

// ADD ALL EMPLOYEE ATTENDANCE
export const addAllEmployeeAttendance = (data: IAPIPayload) => {
  return axios.post(ADD_COMMON_EMPLOYEE_ATTENDANCE, data);
};

// GET MONTHLY EMPLOYEE ATTENDANCE
export const getMonthlyEmployeeAttendance = (data: IAPIPayload) => {
  return axios.post(GET_MONTHLY_EMMPLOYEE_ATTENDANCE, data);
};
