import { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router'
import Button from '../../../../components/common/button/Button'
import Select from 'react-select'
import { DropdownIndicator } from '../../../../components/common/dropdown-indicator/DropdownIndicator'
import { Input } from '../../../../components/common/input/input'
import { useAppDispatch, useAppSelector } from '../../../../hooks'
import { requestGenerator } from '../../../../utils/payloadGenerator'
import { useLocation } from 'react-router-dom'
import Loader from '../../../../components/common/spinner/Loader'
import {
  addRooms,
  editRoom,
  getRoomById,
} from '../../../../redux/features/room/roomAsyncActions'
import { getAllWard } from '../../../../redux/features/ward/wardAsyncActions'
import { trimValue } from '../../../../utils/utils'
import styles from './manageRoom.module.scss'
import { reactSelectStyle } from '../../../../constants/data'
import { t } from 'i18next'

const roomTypeData = [
  {
    label: 'IPD',
    value: 'IPD',
  },
  {
    label: 'OPD',
    value: 'OPD',
  },
]

const ManageRoom = () => {
  const navigate = useNavigate()
  const { isLoading, roomInfo } = useAppSelector((state) => state.room)
  const { wardData } = useAppSelector((state) => state.ward)
  const dispatch = useAppDispatch()
  const location = useLocation().state
  const checkDataIdExist = location?.hasOwnProperty('id')

  useEffect(() => {
    let data = {
      page: 0,
      pageSize: 100,
      order_by: { name: 1 },
      is_active: true,
    }
    dispatch(getAllWard(requestGenerator(data)))
  }, [dispatch])

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    reset,
    formState: { errors },
  } = useForm<any>()

  const watchDepartnment = watch('department')
  const watchRoomType = watch('roomType')
  const checkOpdRoomType = watchRoomType?.value === 'OPD'

  const onSubmit = (data: any) => {
    if (!checkDataIdExist) {
      let responseData = {
        ward_id: watchDepartnment?.value,
        room_name: data?.roomName,
        notes: data?.notes,
        type: watchRoomType?.value,
        allowed_no_of_booking: checkOpdRoomType
          ? Number(data?.bookings)
          : undefined,
        isHybride: checkOpdRoomType ? data.genderSelect : undefined,
      }
      dispatch(addRooms(requestGenerator(responseData))).then((result) => {
        if (result.type === 'room/addRooms/fulfilled') {
          reset()
          setValue('department', '')
          setValue('roomType', '')
          navigate('/medicalcenter/room')
        }
      })
    } else {
      let responseData = {
        id: location?.id,
        data: {
          ward_id: data?.department?.value,
          room_name: data?.roomName,
          notes: data?.notes,
          type: data?.roomType.value,
          allowed_no_of_booking: checkOpdRoomType
            ? Number(data?.bookings)
            : undefined,
          isHybride: checkOpdRoomType ? data.genderSelect : undefined,
        },
      }
      dispatch(editRoom(requestGenerator(responseData))).then((result) => {
        if (result.type === 'room/editRoom/fulfilled') {
          reset()
          setValue('department', '')
          setValue('roomType', '')
          navigate('/medicalcenter/room')
        }
      })
    }
  }

  useEffect(() => {
    if (checkDataIdExist) {
      dispatch(getRoomById(requestGenerator({ id: location?.id })))
    }
  }, [checkDataIdExist, dispatch, location?.id])

  useEffect(() => {
    if (checkDataIdExist) {
      setValue('roomName', roomInfo?.room_name)
      setValue('notes', roomInfo?.notes)
      setValue('bookings', roomInfo?.allowed_no_of_booking)
      setValue('genderSelect', roomInfo?.isHybride)
      setValue('department', {
        label: roomInfo?.ward_id?.ward_name,
        value: roomInfo?.ward_id?._id,
      })
      setValue('roomType', {
        label: roomInfo?.type,
        value: roomInfo?.type,
      })
    } else {
      setValue('roomName', '')
      setValue('notes', '')
      setValue('department', null)
      setValue('roomType', null)
      setValue('bookings', '')
    }
  }, [
    checkDataIdExist,
    roomInfo?.allowed_no_of_booking,
    roomInfo?.isHybride,
    roomInfo?.notes,
    roomInfo?.room_name,
    roomInfo?.type,
    roomInfo?.ward_id?._id,
    roomInfo?.ward_id?.ward_name,
    setValue,
  ])

  return (
    <>
      {isLoading && <Loader />}
      <div className={styles.manageBranchContainer}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className={styles.inputFieldsContainer}>
            <div className={styles.dropDownField}>
              <label className={styles.labelWrapper}>
                {t('RoomSetup.Room_type')}
                <span className={styles.requiredField}>*</span>
              </label>
              <div className={styles.errorContainer}>
                <Select
                  className={styles.select}
                  placeholder={t('RoomSetup.Room_type_placeholder')}
                  closeMenuOnSelect={true}
                  isSearchable={true}
                  value={watchRoomType}
                  components={{ DropdownIndicator }}
                  {...register('roomType', { required: true })}
                  options={roomTypeData}
                  onChange={(selectedOption: any) => {
                    setValue('roomType', selectedOption)
                    setValue('bookings', '')
                  }}
                  maxMenuHeight={200}
                />
                {errors?.roomType?.type === 'required' && (
                  <p className={styles.errorMessage}>
                    {t('RoomSetup.Room_type_validation')}
                  </p>
                )}
              </div>
            </div>
            <div className={styles.dropDownField}>
              <label className={styles.labelWrapper}>
                {t('RoomSetup.select_ward')}
                <span className={styles.requiredField}>*</span>
              </label>
              <div className={styles.errorContainer}>
                <Select
                  className={styles.select}
                  placeholder={t('RoomSetup.select_ward')}
                  closeMenuOnSelect={true}
                  isSearchable={true}
                  value={watchDepartnment}
                  components={{ DropdownIndicator }}
                  {...register('department', { required: true })}
                  options={wardData?.map((item: any) => ({
                    label: item?.ward_name,
                    value: item?._id,
                  }))}
                  onChange={(selectedOption: any) => {
                    setValue('department', selectedOption)
                  }}
                  maxMenuHeight={200}
                  styles={reactSelectStyle}
                />
                {errors?.department?.type === 'required' && (
                  <p className={styles.errorMessage}>
                    {t('RoomSetup.select_ward_validation')}
                  </p>
                )}
              </div>
            </div>
            {checkOpdRoomType && (
              <>
                <div style={{ flexBasis: '50%', marginBlock: '10px' }}>
                  <Input
                    labelText={
                      t('RoomSetup.no_of_booking') || 'No. of allowed bookings'
                    }
                    type="number"
                    placeholder={
                      t('RoomSetup.no_of_booking_placeholder') ||
                      'Please enter capacity'
                    }
                    requiredField={true}
                    {...register('bookings', {
                      required: checkOpdRoomType ? true : false,
                    })}
                    showErrors={errors?.bookings?.type === 'required'}
                    errorMessage={t('RoomSetup.no_of_booking_validation')}
                    onChange={(e) => trimValue(e)}
                  />
                </div>
              </>
            )}
            <div style={{ flexBasis: '50%', marginBlock: '10px' }}>
              <Input
                labelText={t('RoomSetup.room_name') || 'Room Name'}
                placeholder={
                  t('RoomSetup.room_name_placeholder') ||
                  'Please enter room name'
                }
                requiredField={true}
                {...register('roomName', { required: true })}
                showErrors={errors?.roomName?.type === 'required'}
                errorMessage={t('RoomSetup.room_name_placeholder')}
                onChange={(e) => trimValue(e)}
              />
            </div>
            <div style={{ flexBasis: '50%', marginBlock: '10px' }}>
              <Input
                labelText={t('Common.Notes') || 'Notes'}
                placeholder={
                  t('WardSetup.Notes_placeholder') || 'Please enter notes'
                }
                {...register('notes')}
                onChange={(e) => trimValue(e)}
              />
            </div>
            {checkOpdRoomType && (
              <div className={styles.dropDownField}>
                <label className={styles.labelWrapper}>
                  {t('RoomSetup.allow_multiple')}
                </label>
                <div className={styles.checkboxStyles}>
                  <input type="checkbox" {...register('genderSelect')} />
                </div>
              </div>
            )}
          </div>
          <div className={styles.btnContainer}>
            <Button title={t('Common.Submit') || 'Submit'} type="submit" />
            <Button
              title={t('Common.Back') || 'Back'}
              customClass={styles.backBtn}
              type="button"
              handleClick={() => {
                navigate('/medicalcenter/room')
              }}
            />
          </div>
        </form>
      </div>
    </>
  )
}

export default ManageRoom
