import promedLogo from '../../../assets/images/promedLogo.png'
import proactLogo from '../../../assets/images/proactLogo.png'
import styles from './PrivacyPolicy.module.scss'

const StaffPrivacyPolicy = () => {
  return (
    <>
      <div className={styles.mainContainer}>
        <div className={styles.logoContainer}>
          <img
            src={promedLogo}
            alt="error to display"
            className={styles.logoImg}
          />
        </div>
        <div className={styles.container}>
          <div className={styles.privacyPolicyContainer}>
            <h1 className={styles.privacyPolicyHeading}>
              Privacy Policy for PROMED Staff
            </h1>
            <p className={styles.privacyPolicyHeadingText}>
              At PROMED 8.0, we are committed to safeguarding the privacy of our
              staff. This Privacy Policy outlines how we collect, use, disclose,
              and protect your personal information in relation to your role at
              PROMED. By working with us, you agree to the terms and practices
              outlined in this policy.
            </p>
            <div className={styles.privacyPolicy}>
              <p className={styles.privacyPolicyPointHeading}>
                1. Information We Collect
              </p>
              <p className={styles.privacyPolicyPointTitle}>
                We do not collect personal information directly from you.
                Instead, we receive your information from the medical center
                where you are employed. This may include:
              </p>
              <p>
                - Personal and demographic information such as your name, age,
                gender, and contact details.
              </p>
              <p>
                - Employment-related information such as your job title,
                department, and work schedule.
              </p>
              <p>- Professional qualifications and certifications.</p>
              <p>
                - Usage data related to your interactions with the PROMED App,
                including device information and log data.
              </p>
            </div>
            <div className={styles.privacyPolicy}>
              <p className={styles.privacyPolicyPointHeading}>
                2. How We Use Your Information
              </p>
              <p className={styles.privacyPolicyPointTitle}>
                We may use the collected information for the following purposes:
              </p>
              <p>- To facilitate your login and access to the PROMED App.</p>
              <p>- To manage and schedule appointments.</p>
              <p>
                - To enable and support audio and video consultations
                (one-to-one calls).
              </p>
              <p>
                - To provide chat functionality for better communication and
                understanding between staff and patients.
              </p>
              <p>
                - To communicate with you regarding your use of the App,
                including updates and notifications.
              </p>
              <p>
                - To analyze usage trends and improve the quality of our
                services.
              </p>
              <p>- To comply with legal and regulatory requirements.</p>
            </div>
            <div className={styles.privacyPolicy}>
              <p className={styles.privacyPolicyPointHeading}>
                3. Data Sharing and Disclosure
              </p>
              <p className={styles.privacyPolicyPointTitle}>
                We do not sell, trade, or otherwise transfer your personal
                information to third parties without your consent, except in the
                following circumstances:
              </p>
              <p>- With your explicit consent.</p>
              <p>
                - To trusted third-party service providers who assist us in
                operating the App and delivering services, subject to
                confidentiality agreements.
              </p>
              <p>
                - When required by law or to protect our rights, property, or
                safety, or that of others.
              </p>
            </div>
            <div className={styles.privacyPolicy}>
              <p className={styles.privacyPolicyPointHeading}>
                4. Data Security
              </p>
              <p className={styles.privacyPolicyPointTitle}>
                We implement appropriate security measures to protect your
                personal information against unauthorized access, alteration,
                disclosure, or destruction. However, no method of transmission
                over the internet or electronic storage is 100% secure, and we
                cannot guarantee absolute security.
              </p>
            </div>
            <div className={styles.privacyPolicy}>
              <p className={styles.privacyPolicyPointHeading}>
                5. User Account Removal
              </p>
              <p className={styles.privacyPolicyPointTitle}>
                To ensure the integrity of our services and to protect user
                privacy, we have implemented a policy to automatically remove
                user accounts that have been inactive for a period of 90 days.
                This policy is designed to safeguard user data and streamline
                our database by removing accounts that are no longer actively
                used.
              </p>
              <p className={styles.privacyPolicyPointTitle}>
                When an account becomes inactive for 90 days, it will be flagged
                for automatic removal from our system. Prior to the removal, we
                will send notifications to the email address associated with the
                account, informing the user of the upcoming action. These
                notifications will provide ample opportunity for users to
                reactivate their accounts if they wish to continue using our
                services.
              </p>
              <p className={styles.privacyPolicyPointTitle}>
                If no action is taken within the specified notification period,
                the account will be permanently removed from our system. This
                includes deletion of all associated personal data and
                information, in accordance with our privacy policy.
              </p>
            </div>
            <div className={styles.privacyPolicy}>
              <p className={styles.privacyPolicyPointHeading}>
                6. Children's Privacy
              </p>
              <p className={styles.privacyPolicyPointTitle}>
                The App is not intended for use by individuals under the age of
                18. We do not knowingly collect personal information from
                children. If you believe that we have collected personal
                information from a child without parental consent, please
                contact us immediately, and we will take steps to remove such
                information.
              </p>
            </div>
            <div className={styles.privacyPolicy}>
              <p className={styles.privacyPolicyPointHeading}>
                7. Changes to This Policy
              </p>
              <p className={styles.privacyPolicyPointTitle}>
                We reserve the right to update or modify this Privacy Policy at
                any time. Any changes will be effective immediately upon posting
                the revised policy on this page. We encourage you to review this
                page periodically for any updates.
              </p>
            </div>
            <div className={styles.privacyPolicy}>
              <p className={styles.privacyPolicyPointHeading}>8. Contact Us</p>
              <p className={styles.privacyPolicyPointTitle}>
                If you have any questions or concerns about this Privacy Policy
                or our data practices, please contact us at
                promed@proact-kw.com.
              </p>
            </div>
            <p>This Privacy Policy was last updated on 28th March 2024.</p>
            <p>PROMED 8.0 reserves all rights not expressly granted herein.</p>
          </div>
        </div>
        <div className={styles.proactLogoFooter}>
          <span className={styles.poweredByText}>
            Powered by Proact United, Kuwait
          </span>
          <img
            src={proactLogo}
            alt="proact logo"
            className={styles.proactLogo}
          />
        </div>
      </div>
    </>
  )
}

export default StaffPrivacyPolicy
