import { t } from 'i18next'
import styles from './tableData.module.scss'

export const ipdTestlistPopupData: any = [
  {
    Header: t('LabJob.PROFILE_NAME'),
    accessor: 'profile_name',
    Cell: ({ row }: any) => {
      return <p>-</p>
    },
  },
  // {
  //   Header: 'TEST ID',
  //   accessor: 'test_no',
  // },
  {
    Header: t('RequestDiagnosis.TESTNAME'),
    accessor: 'test_name',
  },
  {
    Header: t('InsurancePlan_Form.PRICE'),
    accessor: 'price',
  },
]

export const ipdTestResultPopupData: any = [
  {
    Header: t('LabJob.PROFILE_NAME'),
    accessor: 'profile_name',
    Cell: ({ row }: any) => {
      return <p>-</p>
    },
  },
  {
    Header: t('RequestDiagnosis.TESTNAME'),
    accessor: 'test_name',
  },
  {
    Header: t('ViewReports.COMPONENTS'),
    accessor: 'name',
    // Cell: ({ row }: any) => {
    //   return (
    //     <p>
    //       {row?.original?.components?.length > 0 &&
    //       row?.original?.components[0]?.name
    //         ? row?.original?.components[0]?.name
    //         : '-'}
    //     </p>
    //   );
    // },
  },
  {
    Header: t('ViewReports.RANGE'),
    Cell: ({ row }: any) => {
      return (
        // <p>
        //   {row?.original?.components?.length > 0 &&
        //   row?.original?.components[0]?.range?.from !== undefined
        //     ? row?.original?.components?.length > 0 &&
        //       row?.original?.components[0]?.range?.from +
        //         ' - ' +
        //         row?.original?.components[0]?.range?.to
        //     : '-'}
        // </p>
        <p>
          {row?.original?.range === undefined
            ? '-'
            : row?.original?.range?.from + ' - ' + row?.original?.range?.to}
        </p>
      )
    },
  },
  {
    Header: t('IPDConsultation.RESULT'),
    accessor: 'result',
    Cell: ({ row }: any) => {
      return (
        <p>{row?.original?.result?.length > 0 ? row?.original?.result : '-'}</p>
      )
    },
  },
  {
    Header: t('PatientHistoryTableHeader.NOTES'),
    accessor: 'test_notes',
    Cell: (props: any) => {
      return (
        <p
          className={styles.blueLinkText}
          onClick={() => {
            props?.row?.original?.test_notes?.length > 0 &&
              props?.onRowClick(props?.row?.original?.test_notes)
          }}
        >
          {props?.row?.original?.test_notes?.length > 0
            ? t('Common.View')
            : '-'}
        </p>
      )
    },
  },
]
