import { createSlice } from '@reduxjs/toolkit'
import {
  getAllGrn,
  getAllSupplierDetails,
  getPurchaseInvoice,
  getSubmittedPiAsync,
} from './purchaseAsyncActions'
import { IPurChaseInvoice } from '../../../interfaces/apiInterface'

export const initialState: IPurChaseInvoice = {
  suppliersInfo: [],
  grnListObject: {},
  grnList: [],
  conformPurchaseInvoiceList: [],
  addTestText: [],
  generatedInvoiceData: '',
  isLoading: false,
  file: '',
  submittedPiData: [],
}

export const purchaseInvoiceSlice = createSlice({
  name: 'purchaseInvoice',
  initialState,
  reducers: {
    setPurchaseInvoiceList: (state, action) => {
      state.conformPurchaseInvoiceList = [
        ...state.conformPurchaseInvoiceList,
        action.payload,
      ]
    },

    setTestAddText: (state: any, action: any) => {
      const index = state.addTestText.indexOf(action.payload)
      if (index > -1) {
        state.addTestText.splice(index, 1)
      } else {
        state.addTestText.push(action.payload)
      }
    },

    removeFromInvoiceList: (state, action) => {
      state.conformPurchaseInvoiceList =
        state.conformPurchaseInvoiceList.filter((item: any) => {
          return item._id !== action.payload
        })
    },

    updatedPurchaseInvoiceList: (state, action) => {
      state.conformPurchaseInvoiceList = action.payload
    },

    clearStates: (state) => {
      state.conformPurchaseInvoiceList = []
      state.suppliersInfo = []
      state.grnListObject = {}
      state.grnList = []
      // state.generatedInvoiceData = '';
      state.isLoading = false
      state.file = ''
    },

    clearConformPurchaseInvoiceList: (state) => {
      state.conformPurchaseInvoiceList = []
      state.addTestText = []
    },

    clearGrnList: (state) => {
      state.grnList = []
    },
  },

  extraReducers: (builder) => {
    builder.addCase(getAllSupplierDetails.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(getAllSupplierDetails.fulfilled, (state, action) => {
      state.suppliersInfo = action.payload
      state.isLoading = false
    })
    builder.addCase(getAllSupplierDetails.rejected, (state, error) => {
      state.isLoading = false
    })

    // Get All GRN
    builder.addCase(getAllGrn.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(getAllGrn.fulfilled, (state, action) => {
      state.grnListObject = action.payload
      state.grnList = action.payload?.data
      state.isLoading = false
    })
    builder.addCase(getAllGrn.rejected, (state, error) => {
      state.grnList = []
      state.isLoading = false
    })

    // Get Purchase Invoice
    builder.addCase(getPurchaseInvoice.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(getPurchaseInvoice.fulfilled, (state, action) => {
      state.isLoading = false
      state.generatedInvoiceData = action.payload
    })
    builder.addCase(getPurchaseInvoice.rejected, (state, error) => {
      state.isLoading = false
    })

    //submitted pi data
    builder.addCase(getSubmittedPiAsync.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(getSubmittedPiAsync.fulfilled, (state, action) => {
      state.isLoading = false
      state.submittedPiData = action.payload.data
    })
    builder.addCase(getSubmittedPiAsync.rejected, (state) => {
      state.isLoading = false
    })
  },
})

export const {
  setPurchaseInvoiceList,
  setTestAddText,
  removeFromInvoiceList,
  updatedPurchaseInvoiceList,
  clearStates,
  clearConformPurchaseInvoiceList,
  clearGrnList,
} = purchaseInvoiceSlice.actions
export default purchaseInvoiceSlice.reducer
