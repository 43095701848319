import {
  GET_ALL_LEAVE,
  ADD_LEAVE,
  GET_LEAVE_BY_ID,
  EDIT_LEAVE_BY_ID,
  UPDATE_LEAVE_STATUS,
  DELETE_LEAVE,
  GET_LEAVE_TABLE,
  GET_ALL_ATACHED,
  GET_LEAVE_CALCULATION,
  GET_SALARY,
} from '../../../constants/asyncActionsType'
import createAsyncThunkForSlice from '../../../utils/utils'
import {
  addLeaves,
  deleteLeaves,
  editLeaveByIds,
  getAllAtach,
  getAllLeaveList,
  getLeaveByIds,
  getLeaveCalculationList,
  getLeaveTableList,
  getSalaryList,
  updateLeaveStatuss,
} from './leaveCrud'

export const getAllLeave = createAsyncThunkForSlice(
  GET_ALL_LEAVE,
  getAllLeaveList,
  {
    isToast: false,
  }
)
export const getSalary = createAsyncThunkForSlice(GET_SALARY, getSalaryList, {
  isToast: false,
})

export const getAllAtachs = createAsyncThunkForSlice(
  GET_ALL_ATACHED,
  getAllAtach
)

export const getLeaveTable = createAsyncThunkForSlice(
  GET_LEAVE_TABLE,
  getLeaveTableList,
  {
    isToast: false,
  }
)

export const getLeaveCalcuations = createAsyncThunkForSlice(
  GET_LEAVE_CALCULATION,
  getLeaveCalculationList,
  {
    isToast: true,
  }
)

export const addLeave = createAsyncThunkForSlice(ADD_LEAVE, addLeaves, {
  isToast: true,
})
export const getLeaveById = createAsyncThunkForSlice(
  GET_LEAVE_BY_ID,
  getLeaveByIds,
  {
    isToast: true,
  }
)

export const editLeaveById = createAsyncThunkForSlice(
  EDIT_LEAVE_BY_ID,
  editLeaveByIds,
  {
    isToast: true,
  }
)

export const updateLeaveStatus = createAsyncThunkForSlice(
  UPDATE_LEAVE_STATUS,
  updateLeaveStatuss,
  {
    isToast: true,
  }
)

export const deleteLeave = createAsyncThunkForSlice(
  DELETE_LEAVE,
  deleteLeaves,
  {
    isToast: true,
  }
)
