import { FC } from 'react'
import styles from './paginationV2.module.scss'
import { NextIcon, PreviousIcon, ResetIcon } from '../../svg-components'
import { colors } from '../../../../constants/color'
interface IPaginationProps {
  handlePrevious: any
  handleReset: any
  handleNext: any
  nextPage?: boolean
  previousPage?: boolean
  customClass?: any
}

const PaginationV2: FC<IPaginationProps> = ({
  handlePrevious,
  handleReset,
  handleNext,
  nextPage,
  previousPage,
  customClass,
}) => {
  return (
    <div className={[styles.paginationContainer, customClass].join(' ')}>
      <div className={styles.iconContainer} onClick={() => handlePrevious()}>
        <PreviousIcon fillColor={previousPage ? colors.grey2 : colors.green1} />
      </div>
      <div className={styles.iconContainer} onClick={() => handleReset()}>
        <ResetIcon fillColor={colors.grey2} />
      </div>
      <div
        className={nextPage ? styles.iconContainerActive : styles.iconContainer}
        onClick={() => handleNext()}
      >
        <NextIcon fillColor={nextPage ? colors.green1 : colors.grey2} />
      </div>
    </div>
  )
}
export default PaginationV2
