import { FC, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router'
import Button from '../../../../components/common/button/Button'
import Select from 'react-select'
import { DropdownIndicator } from '../../../../components/common/dropdown-indicator/DropdownIndicator'
import { Input } from '../../../../components/common/input/input'
import { useAppDispatch, useAppSelector } from '../../../../hooks'
import { requestGenerator } from '../../../../utils/payloadGenerator'
import { useLocation } from 'react-router-dom'
import Loader from '../../../../components/common/spinner/Loader'
import { getAllroom } from '../../../../redux/features/room/roomAsyncActions'
import styles from './manageBed.module.scss'
import {
  addBeds,
  editBed,
  getBedById,
} from '../../../../redux/features/bed/bedAsyncActions'
import { trimValue } from '../../../../utils/utils'
import { reactSelectStyle } from '../../../../constants/data'
import { t } from 'i18next'

const ManageBed: FC = () => {
  const navigate = useNavigate()
  const { isLoading, bedInfo } = useAppSelector((state) => state.bed)
  const { roomData } = useAppSelector((state) => state.room)
  const dispatch = useAppDispatch()
  const location = useLocation().state
  const checkDataIdExist = location?.hasOwnProperty('id')

  useEffect(() => {
    let data = {
      page: 0,
      pageSize: 100,
      order_by: { name: 1 },
      is_active: true,
      type: 'IPD',
    }
    dispatch(getAllroom(requestGenerator(data)))
  }, [dispatch])

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    reset,
    formState: { errors },
  } = useForm<any>()

  const watchDepartnment = watch('department')

  const onSubmit = (data: any) => {
    if (!checkDataIdExist) {
      let responseData = {
        room_id: watchDepartnment?.value,
        bed_name: data?.bedName,
        notes: data?.notes,
        cost_price: data?.costPrice,
        sell_price: Number(data?.sellPrice),
      }
      dispatch(addBeds(requestGenerator(responseData))).then((result) => {
        if (result.type === 'room/addBeds/fulfilled') {
          reset()
          setValue('department', '')
          navigate('/medicalcenter/bed')
        }
      })
    } else {
      let responseData = {
        id: location?.id,
        data: {
          room_id: data?.department?.value,
          bed_name: data?.bedName,
          notes: data?.notes,
          cost_price: data?.costPrice,
          sell_price: Number(data?.sellPrice),
        },
      }
      dispatch(editBed(requestGenerator(responseData))).then((result) => {
        if (result.type === 'room/editBed/fulfilled') {
          navigate('/medicalcenter/bed')
        }
      })
    }
  }

  useEffect(() => {
    if (checkDataIdExist) {
      dispatch(getBedById(requestGenerator({ id: location?.id })))
    }
  }, [checkDataIdExist, dispatch, location?.id])

  useEffect(() => {
    if (checkDataIdExist) {
      setValue('bedName', bedInfo?.bed_name)
      setValue('notes', bedInfo?.notes)
      setValue('department', {
        label: bedInfo?.room_id?.room_name,
        value: bedInfo?.room_id?._id,
      })
      setValue('costPrice', bedInfo?.cost_price)
      setValue('sellPrice', bedInfo?.sell_price)
    } else {
      setValue('bedName', '')
      setValue('notes', '')
      setValue('department', null)
      setValue('costPrice', '')
      setValue('sellPrice', '')
    }
  }, [
    bedInfo?.bed_name,
    bedInfo?.cost_price,
    bedInfo?.notes,
    bedInfo?.room_id?._id,
    bedInfo?.room_id?.room_name,
    bedInfo?.sell_price,
    checkDataIdExist,
    setValue,
  ])

  return (
    <>
      {isLoading && <Loader />}
      <div className={styles.manageBranchContainer}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className={styles.inputFieldsContainer}>
            <div className={styles.dropDownField}>
              <label className={styles.labelWrapper}>
                {t('BedSetup.select_room')}
                <span className={styles.requiredField}>*</span>
              </label>
              <div className={styles.errorContainer}>
                <Select
                  className={styles.select}
                  placeholder={t('BedSetup.select_room')}
                  closeMenuOnSelect={true}
                  isSearchable={true}
                  value={watchDepartnment}
                  components={{ DropdownIndicator }}
                  {...register('department', { required: true })}
                  options={roomData?.map((item: any) => ({
                    label: item?.room_name,
                    value: item?._id,
                  }))}
                  onChange={(selectedOption: any) => {
                    setValue('department', selectedOption)
                  }}
                  maxMenuHeight={200}
                  styles={reactSelectStyle}
                />
                {errors?.department?.type === 'required' && (
                  <p className={styles.errorMessage}>
                    {t('BedSetup.select_room_validation')}
                  </p>
                )}
              </div>
            </div>
            <div style={{ flexBasis: '50%', marginBlock: '10px' }}>
              <Input
                labelText={t('BedSetup.bed_name') || 'Bed Name'}
                placeholder={
                  t('BedSetup.bed_name_placeholder') || 'Please enter bed name'
                }
                requiredField={true}
                {...register('bedName', { required: true })}
                showErrors={errors?.bedName?.type === 'required'}
                errorMessage={t('BedSetup.bed_name_placeholder')}
                onChange={(e) => trimValue(e)}
              />
            </div>
            <div style={{ flexBasis: '50%', marginBlock: '10px' }}>
              <Input
                requiredField={true}
                placeholder={
                  t('BedSetup.cost_price_placeholder') ||
                  'Please enter cost price'
                }
                labelText={t('BedSetup.cost_price') || 'Cost Price'}
                {...register('costPrice', { required: true })}
                showErrors={errors?.costPrice?.type === 'required'}
                errorMessage={t('BedSetup.cost_price_placeholder')}
                onChange={(e) => trimValue(e)}
              />
            </div>
            <div style={{ flexBasis: '50%', marginBlock: '10px' }}>
              <Input
                requiredField={true}
                placeholder={
                  t('BedSetup.sell_price_placeholder') ||
                  'Please enter sell price'
                }
                labelText={t('BedSetup.sell_price') || 'Sell Price'}
                {...register('sellPrice', { required: true })}
                showErrors={errors?.sellPrice?.type === 'required'}
                errorMessage={t('BedSetup.sell_price_placeholder')}
                onChange={(e) => trimValue(e)}
              />
            </div>
            <div style={{ flexBasis: '50%', marginBlock: '10px' }}>
              <Input
                labelText={t('Common.Notes') || 'Notes'}
                placeholder={
                  t('WardSetup.Notes_placeholder') || 'Please enter notes'
                }
                {...register('notes')}
                onChange={(e) => trimValue(e)}
              />
            </div>
          </div>
          <div className={styles.btnContainer}>
            <Button title={t('Common.Submit') || 'Submit'} type="submit" />
            <Button
              title={t('Common.Back') || 'Back'}
              customClass={styles.backBtn}
              type="button"
              handleClick={() => {
                navigate('/medicalcenter/bed')
              }}
            />
          </div>
        </form>
      </div>
    </>
  )
}

export default ManageBed
