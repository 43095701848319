import { useNavigate } from 'react-router'
import ToggleSwitchV2 from '../../components/common/toggle-switch/ToggleSwitchV2'
import { useAppDispatch } from '../../hooks'
import { requestGenerator } from '../../utils/payloadGenerator'
import styles from './tableData.module.scss'
import { EditIcon } from '../../components/common/svg-components'
import { colors } from '../color'
import { utcToDate } from '../../utils/utils'
import {
  getInventoryMasterList,
  updateInventoryMaster,
} from '../../redux/features/inventory-master/inventoryMasterAsyncActions'
import { t } from 'i18next'

export const inventoryMasterTableHeaderData: any = [
  {
    Header: t('AllAttachmentHeader.NAME'),
    accessor: 'name',
  },
  {
    Header: t('SupplierMaster.CONTACT_PERSON'),
    accessor: 'contact_name',
  },
  {
    Header: t('SupplierMaster.CONTACT_NO'),
    accessor: 'phone',
  },

  {
    Header: t('TimelineHeader.ATTACHMENTS'),
    Cell: (props: any) => {
      return (
        <>
          {props?.row?.original?.attachments?.length > 0 ? (
            <span
              className={styles.blueLinkText}
              onClick={() => {
                props?.onPopClose(props?.row?.original?.attachments)
              }}
            >
              {t('Common.View')}
            </span>
          ) : (
            '-'
          )}
        </>
      )
    },
  },
  {
    Header: t('PatientHistoryTableHeader.NOTES'),
    accessor: 'notes',
    Cell: (props: any) => {
      const notesObject = {
        noteDetail: props?.row?.original?.notes,
        lastUpdateDate: utcToDate(props?.row?.original?.updatedAt),
      }

      return (
        <>
          {notesObject?.noteDetail !== undefined &&
          notesObject?.noteDetail?.length > 0 ? (
            <span
              className={styles.view}
              onClick={() => props.onClick(notesObject)}
            >
              {t('Common.View')}
            </span>
          ) : (
            '-'
          )}
        </>
      )
    },
  },

  {
    Header: t('RcptTableHeader.STATUS'),
    Cell: ({ row }: any) => {
      const dispatch = useAppDispatch()
      const is_active = row?.original?.is_active
      const handleToggle = (item: any) => {
        const payload = {
          id: item._id,
          is_active: !item.is_active,
        }
        dispatch(updateInventoryMaster(requestGenerator(payload))).then((e) => {
          if (e.type === 'inventoryMaster/updateInventoryMaster/fulfilled') {
            dispatch(getInventoryMasterList(requestGenerator({})))
          }
        })
      }
      return (
        <ToggleSwitchV2
          isToggled={is_active}
          handleToggle={() => handleToggle(row?.original)}
        />
      )
    },
  },
  {
    Header: t('DocUploadHeader.ACTION'),

    // accessor: '',
    Cell: ({ row }: any) => {
      const editable = row?.original?.is_active
      const navigate = useNavigate()
      const handleEdit = (item: any) => {
        navigate('/inventorymastertable/addnewsuplier', {
          state: { supplierDetailData: item },
        })
      }
      return (
        <EditIcon
          fillColor={colors.grey4}
          handleClick={() =>
            row?.original?.is_active && handleEdit(row?.original)
          }
          customClass={editable ? styles.cursorPointor : styles.cursorDefault}
        />
      )
    },
  },
]
