import {
  // COMPONENT,
  COST_PRICE,
  SAMPLE_TYPE,
  SELL_PRICE,
  SOURCE,
  TEST_CATEGORY,
  TEST_NAME,
  TEST_TAT,
  UNIT,
} from '../constants/constant'
import { t } from 'i18next'

export const createTestValidators = {
  [TEST_NAME]: {
    required: t('LabConfiguration.Test_Validation'),
  },
  [TEST_CATEGORY]: {
    required: t('LabConfiguration.Test_Category_Validators'),
  },
  [TEST_TAT]: {
    required: t('LabConfiguration.TAT_Validation'),
  },
  [SAMPLE_TYPE]: {
    required: t('LabConfiguration.Sample_Type_Validation'),
  },
  [SOURCE]: {
    required: t('ReportGenerator.source_validation'),
  },
  [UNIT]: {
    required: t('LabConfiguration.Unit_Validation'),
  },
  // [COMPONENT]: {
  //   required: "Please select component",
  // },
  [COST_PRICE]: {
    required: t('BedSetup.cost_price_placeholder'),
    pattern: {
      value: /^[0-9]{0,6}(?:\.[0-9]{1,3})?$/,
      message: t('InventoryItemTable.Quantity_Value_Validation'),
    },
  },
  [SELL_PRICE]: {
    required: t('BedSetup.sell_price_placeholder'),
    pattern: {
      value: /^[0-9]{0,6}(?:\.[0-9]{1,3})?$/,
      message: t('InventoryItemTable.Quantity_Value_Validation'),
    },
  },
}
