// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.callcenterdashboard_top__qKuvY {
  display: flex;
}
.callcenterdashboard_top__qKuvY .callcenterdashboard_tab__Qbant {
  background-color: var(--white1);
  box-shadow: 0px 10px 117px 14px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  padding: 17px;
  margin: 0 20px 50px 0;
  height: 84px;
  width: 280px;
}
.callcenterdashboard_top__qKuvY .callcenterdashboard_tab__Qbant .callcenterdashboard_data__Mudz- {
  display: flex;
  justify-content: flex-start;
  cursor: pointer;
}
.callcenterdashboard_top__qKuvY .callcenterdashboard_tab__Qbant .callcenterdashboard_data__Mudz- .callcenterdashboard_right__4hG6B {
  margin-left: 20px;
}
.callcenterdashboard_top__qKuvY .callcenterdashboard_tab__Qbant h4 {
  font-weight: 700;
}
.callcenterdashboard_top__qKuvY .callcenterdashboard_select__0hVJB {
  margin: 25px 0 0 0;
}
.callcenterdashboard_top__qKuvY .callcenterdashboard_select__0hVJB label {
  margin: 0 10px 0 0;
  font-style: normal;
  line-height: 23px;
  font-weight: var(--font-semibold);
  color: var(--black2);
  font-size: var(--font-16);
}
.callcenterdashboard_top__qKuvY .callcenterdashboard_select__0hVJB .callcenterdashboard_selectInputField__WMJK1 {
  background: #ffffff;
  width: 234px;
  height: 42px;
  border-radius: 6px;
  margin-right: 20px;
  color: #797979;
}

.callcenterdashboard_admin__iuqIf {
  background-color: var(--white1);
  box-shadow: 0px 10px 117px 14px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  padding: 25px;
  margin: 0 20px 50px 0;
}`, "",{"version":3,"sources":["webpack://./src/pages/call-center-admin/admin/admin/callcenterdashboard.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;AACF;AACE;EACE,+BAAA;EACA,mDAAA;EACA,mBAAA;EACA,aAAA;EACA,qBAAA;EACA,YAAA;EACA,YAAA;AACJ;AACI;EACE,aAAA;EACA,2BAAA;EACA,eAAA;AACN;AACM;EACE,iBAAA;AACR;AAGI;EACE,gBAAA;AADN;AAKE;EACE,kBAAA;AAHJ;AAKI;EACE,kBAAA;EACA,kBAAA;EACA,iBAAA;EACA,iCAAA;EACA,oBAAA;EACA,yBAAA;AAHN;AAMI;EACE,mBAAA;EACA,YAAA;EACA,YAAA;EACA,kBAAA;EACA,kBAAA;EACA,cAAA;AAJN;;AASA;EACE,+BAAA;EACA,mDAAA;EACA,mBAAA;EACA,aAAA;EACA,qBAAA;AANF","sourcesContent":[".top {\n  display: flex;\n\n  .tab {\n    background-color: var(--white1);\n    box-shadow: 0px 10px 117px 14px rgba(0, 0, 0, 0.05);\n    border-radius: 10px;\n    padding: 17px;\n    margin: 0 20px 50px 0;\n    height: 84px;\n    width: 280px;\n\n    .data {\n      display: flex;\n      justify-content: flex-start;\n      cursor: pointer;\n\n      .right{\n        margin-left: 20px;\n      }\n    }\n\n    h4 {\n      font-weight: 700;\n    }\n  }\n\n  .select {\n    margin: 25px 0 0 0;\n\n    label {\n      margin: 0 10px 0 0;\n      font-style: normal;\n      line-height: 23px;\n      font-weight: var(--font-semibold);\n      color: var(--black2);\n      font-size: var(--font-16);\n    }\n\n    .selectInputField {\n      background: #ffffff;\n      width: 234px;\n      height: 42px;\n      border-radius: 6px;\n      margin-right: 20px;\n      color: #797979; \n    }\n  }\n}\n\n.admin {\n  background-color: var(--white1);\n  box-shadow: 0px 10px 117px 14px rgba(0, 0, 0, 0.05);\n  border-radius: 10px;\n  padding: 25px;\n  margin: 0 20px 50px 0;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"top": `callcenterdashboard_top__qKuvY`,
	"tab": `callcenterdashboard_tab__Qbant`,
	"data": `callcenterdashboard_data__Mudz-`,
	"right": `callcenterdashboard_right__4hG6B`,
	"select": `callcenterdashboard_select__0hVJB`,
	"selectInputField": `callcenterdashboard_selectInputField__WMJK1`,
	"admin": `callcenterdashboard_admin__iuqIf`
};
export default ___CSS_LOADER_EXPORT___;
