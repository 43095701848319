import { useEffect, useState } from 'react'
import styles from './PatientIPDInfo.module.scss'
import Button from '../../../components/common/button/Button'
import TableV2 from '../../../components/common/table/tableV2/TableV2'
import {
  patientIPDInfoHeaderData,
  PatientListHeaderData,
} from '../../../constants/table-data/patientIpdInvoiceTableData'
import { SearchIcon } from '../../../components/common/svg-components'
import { CustomModal } from '../../../components/common/custom-modal/modal'
import PatientListModal from '../../../components/common/modal/patient-list-modal/PatientListModal'
import { useAppDispatch, useAppSelector } from '../../../hooks'
import { requestGenerator } from '../../../utils/payloadGenerator'
import {
  getAllIpdPatientsList,
  getIpdInvoiceCaseDetail,
  getPatientProfilePic,
} from '../../../redux/features/ipd-invoice/ipdAsyncActions'
import { GET_IPD_INVOICE_PATIENT_LIST } from '../../../constants/asyncActionsType'
import { SubmitHandler, useForm } from 'react-hook-form'
import { IIpdInvoiceForm } from '../../../interfaces/interfaces'
import {
  IPD_INVOICE_CASE_ID,
  IPD_INVOICE_FILE_NUMBER,
  IPD_INVOICE_PATIENT_NAME,
} from '../../../constants/constant'
import { ipdInvoiceValidators } from '../../../form-validators/ipdInvoiceValidators'
import { Input } from '../../../components/common/input/input'
import { allowedNumberOfDigitsAfterDecimal } from '../../../utils/utils'
import { useLocation, useNavigate } from 'react-router-dom'
import Loader from '../../../components/common/spinner/Loader'
import {
  clearIpdInvoiceData,
  patientIpdInfoData,
  setPatientProfileImg,
  setSelectedPatientData,
} from '../../../redux/features/ipd-invoice/ipdInvoiceSlice'
import SelectImage from '../../../assets/images/Default Image.png'
import InvoiceConformationModal from '../../../components/common/modal/invoice-conformation-modal/InvoiceConformationModal'
import Popup from '../../../components/common/popup/Popup'
import { useTranslation } from 'react-i18next'

const PatientIPDInfo = () => {
  const { t } = useTranslation()
  const [searchPatientPopup, setsearchPatientPopup] = useState(false)
  // const [patientRowData, setpatientRowData] = useState<any>();
  const [searchValue, setSearchValue] = useState<string>('')
  const [activateSmartSearch, setActivateSmartSearch] = useState<boolean>(false)

  const {
    patientList,
    patientCaseDetails,
    isLoading,
    patientProfileImg,
    patientIpdInfo,
    selectedPatientRowData,
  } = useAppSelector((state) => state.ipdInvoice)

  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const [dataPerPage, setDataPerPage] = useState<number>(10)
  const [totalPage, setTotalPage] = useState<number>(0)
  const [pageIndex, setPageIndex] = useState<number>(1)

  const [selectRow, setSelectRow] = useState<any>({})
  const [invoiceConformationModal, setinvoiceConformationModal] =
    useState<boolean>(false)

  const pageIndexArray = () => {
    let pageIndexOptions = []
    for (let i = 1; i <= totalPage; i++) {
      pageIndexOptions.push(i)
    }
    return pageIndexOptions
  }
  const pageIndexOptions = pageIndexArray()

  // FORM
  const {
    register,
    reset,
    handleSubmit,
    setValue,
    formState: { errors },
    watch,
  } = useForm<any>({})

  // Submit Function
  const onSubmit: SubmitHandler<IIpdInvoiceForm> = (data) => {
    dispatch(patientIpdInfoData(data))
    navigate('/ipd-invoice/consumble')
  }

  const handlePatientSearchPopup = () => {
    setsearchPatientPopup(!searchPatientPopup)
    setSearchValue('')
  }

  const watchPatientName = watch(IPD_INVOICE_PATIENT_NAME)

  const handlePatientList = () => {
    if (searchValue === '') {
      const requestData = {
        page: pageIndex,
        pageSize: dataPerPage,
      }
      dispatch(getAllIpdPatientsList(requestGenerator(requestData))).then(
        (e: any) => {
          if (e.type === `${GET_IPD_INVOICE_PATIENT_LIST}/fulfilled`) {
            setTotalPage(e?.payload.lastPage)
          } else {
          }
        }
      )
    }
  }

  const handleSelectPatient = (obj: any) => {
    reset(obj)
    setValue(IPD_INVOICE_CASE_ID, obj?.ipdData ? obj?.ipdData?.case_no : '')
    dispatch(setSelectedPatientData(obj))
    setsearchPatientPopup(!searchPatientPopup)
    setPageIndex(1)
  }

  const handlePatientData = (row: any) => {
    let obj: any = {}
    if (row?.ipdData?.insurance_plan_id) {
      setinvoiceConformationModal(true)
      setSelectRow(row)
    } else {
      obj = { ...row, isIns: false }
      handleSelectPatient(obj)
    }
  }

  const handleYes = () => {
    const obj = { ...selectRow, isIns: true }
    handleSelectPatient(obj)
    setinvoiceConformationModal(false)
  }

  const handleNo = () => {
    const obj = { ...selectRow, isIns: false }
    handleSelectPatient(obj)
    setinvoiceConformationModal(false)
  }

  useEffect(() => {
    if (selectedPatientRowData?._id) {
      const requestedPayload = {
        patient_id: selectedPatientRowData?._id,
        page: pageIndex,
        pageSize: dataPerPage,
      }

      const requestedData = {
        attachements: [selectedPatientRowData?.profile_pic],
      }

      dispatch(
        getIpdInvoiceCaseDetail(
          requestGenerator({
            patient_id: selectedPatientRowData?._id,
            page: 1,
            pageSize: 10,
          })
        )
      )
      // .then((result) => setTotalPage(result.payload.lastPage));
      if (watchPatientName?.length) {
        dispatch(getPatientProfilePic(requestGenerator(requestedData)))
        // .then(
        //   (result) => setTotalPage(result.payload.lastPage)
        // );
      }
    }
  }, [
    selectedPatientRowData,
    dataPerPage,
    pageIndex,
    dispatch,
    watchPatientName?.length,
  ])

  const handleSearch = () => {
    setActivateSmartSearch(true)
    setPageIndex(1)
    const requestData = {
      search: searchValue,
      page: 1,
      pageSize: dataPerPage,
    }
    dispatch(getAllIpdPatientsList(requestGenerator(requestData))).then(
      (result) => setTotalPage(result.payload.lastPage)
    )
  }

  useEffect(() => {
    if (patientIpdInfo?._id) {
      reset(patientIpdInfo)
      setValue(IPD_INVOICE_CASE_ID, patientIpdInfo?.ipdData?.case_no)
    }
  }, [patientIpdInfo, reset, setValue])

  // useEffect(() => {
  //   if (searchValue === "") {
  //     const requestData = {
  //       // search:"",
  //       page: pageIndex,
  //       pageSize: 5,
  //     };
  //     dispatch(getAllIpdPatientsList(requestGenerator(requestData))).then(
  //       (e: any) => {
  //         if (e.type === `${GET_IPD_INVOICE_PATIENT_LIST}/fulfilled`) {
  //           setTotalPage(e?.payload.lastPage);
  //         } else {
  //         }
  //       }
  //     );
  //   }
  // }, [searchValue, dataPerPage, pageIndex]);

  return (
    <>
      {isLoading && <Loader />}
      <CustomModal
        title={t('Common.Patient Search') || 'Patient Search'}
        showModal={searchPatientPopup}
        closeModal={() => setsearchPatientPopup(false)}
        width="750px"
        height="600px"
      >
        <PatientListModal
          tableHeaderData={PatientListHeaderData}
          tableRowData={
            patientList?.data && patientList?.data?.length > 0
              ? patientList?.data
              : []
          }
          handleRowClick={handlePatientData}
          handlePatientList={() => handlePatientList()}
          dataPerPage={dataPerPage}
          setDataPerPage={setDataPerPage}
          searchValue={searchValue}
          setSearchValue={setSearchValue}
          totalPage={totalPage}
          pageIndex={pageIndex}
          setPageIndex={setPageIndex}
          pageIndexOptions={pageIndexOptions}
          setActivateSmartSearch={setActivateSmartSearch}
          handleSearch={handleSearch}
        />
      </CustomModal>

      {invoiceConformationModal && (
        <Popup
          Children={InvoiceConformationModal}
          handleClose={() =>
            setinvoiceConformationModal(!invoiceConformationModal)
          }
          handleNo={() => handleNo()}
          setModelOpenClose={() => handleYes()}
          heading={t('IPDInvoice.IPDInvoice') || 'IPD Invoice'}
          message={t('IPDInvoice.ContinueINS') || 'Continue with insurance ?'}
        />
      )}

      <form className={styles.mainContainer} onSubmit={handleSubmit(onSubmit)}>
        <div className={styles.mainFormContainer}>
          <div className={styles.formContainer}>
            <div className={styles.labelFieldContainer}>
              <label className={styles.labelText}>
                {t('IPDDashboard.Case ID')}
              </label>
              <div className={styles.fieldErrorContainer}>
                <input
                  type="text"
                  className={styles.searchInputField}
                  {...register(IPD_INVOICE_CASE_ID)}
                  disabled={true}
                />
                <div className={styles.errorContainer}>
                  <span className={styles.extraSpan}></span>
                </div>
              </div>
            </div>

            <div className={styles.labelFieldContainer}>
              <label className={styles.labelText}>
                {t('ShareQuestionnaire.Patient Name')}
                <span className="asterick">*</span>
              </label>
              <div className={styles.fieldErrorContainer}>
                <Input
                  type="text"
                  customClass={styles.searchInputField}
                  {...register(
                    IPD_INVOICE_PATIENT_NAME,
                    ipdInvoiceValidators[IPD_INVOICE_PATIENT_NAME]
                  )}
                  inlineClass={styles.inputField}
                  disabled={true}
                />
                <SearchIcon
                  fillColor="#797979"
                  customClass={styles.searchIconStyle}
                  handleClick={handlePatientSearchPopup}
                />

                <div className={styles.errorContainer}>
                  <span className={styles.extraSpan}></span>
                  {errors[IPD_INVOICE_PATIENT_NAME] && (
                    <p className="dashboardFormError">
                      {errors[IPD_INVOICE_PATIENT_NAME].message as any}
                    </p>
                  )}
                </div>
              </div>
            </div>

            <div className={styles.labelFieldContainer}>
              <label className={styles.labelText}>
                {t('PatientEMR.File No.')}
                <span className="asterick">*</span>
              </label>
              <div className={styles.fieldErrorContainer}>
                <input
                  type="text"
                  className={styles.searchInputField}
                  {...register(
                    IPD_INVOICE_FILE_NUMBER,
                    ipdInvoiceValidators[IPD_INVOICE_FILE_NUMBER]
                  )}
                  disabled={true}
                />

                <div className={styles.errorContainer}>
                  {errors[IPD_INVOICE_FILE_NUMBER] && (
                    <p className="dashboardFormError">
                      {errors[IPD_INVOICE_FILE_NUMBER].message as any}
                    </p>
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className={styles.amountContainer}>
            <div className={styles.dueAmountStyle}>
              {t('Common.Due')}{' '}
              {allowedNumberOfDigitsAfterDecimal(
                selectedPatientRowData?.ipdData?.invoiceSum ?? 0,
                3
              )}
            </div>
            <div className={styles.paidAmountStyle}>
              {t('Common.Advance Due')}{' '}
              {allowedNumberOfDigitsAfterDecimal(
                selectedPatientRowData?.advance_amount ?? 0,
                3
              )}
            </div>
          </div>

          <div className={styles.avtarContainer}>
            <div className={styles.avtarImageStyle}>
              <img
                src={
                  patientProfileImg.length ? patientProfileImg[0] : SelectImage
                }
                className={styles.avtarImageFitStyle}
                alt=""
              />
            </div>
          </div>
        </div>

        <div className={styles.tableContainer}>
          <TableV2
            tableHeaderData={patientIPDInfoHeaderData}
            tableRowData={
              patientCaseDetails?.data?.length > 0
                ? patientCaseDetails?.data
                : []
            }
          />
        </div>
        <div className={styles.buttonContainer}>
          <Button
            type={'submit'}
            disable={
              !selectedPatientRowData?.is_ipd_booked &&
              !selectedPatientRowData?.is_case_open &&
              selectedPatientRowData?.ipdData?.case_no
                ? false
                : true
            }
            title={t('IPDInvoice.Next') || 'Next'}
            customClass={styles.nextButton}
          />
        </div>
      </form>
    </>
  )
}

export default PatientIPDInfo
