import { FC } from 'react'
import { CloseIcon } from '../../../svg-components'
import Divider from '../../../divider/Divider'
import { colors } from '../../../../../constants/color'
import styles from './addresultsnotesPopup.module.scss'
import { useTranslation } from 'react-i18next'

interface IPropsData {
  handleClose?: any
  popData?: any
}
const ViewLRadiologyReportNotes: FC<IPropsData> = ({
  handleClose,
  popData,
}) => {
  const { t } = useTranslation()
  return (
    <div
      className={styles.addResultsNotesPopupContainer}
      onClick={(e) => e.stopPropagation()}
    >
      <CloseIcon
        customClass={styles.closeIconStyle}
        fillColor={colors.green1}
        handleClick={() => handleClose()}
      />
      <div className={styles.addResultsNotesContainer}>
        <p className={styles.title}>{t('Common.Notes')}</p>
        <Divider customClass={styles.dividerStyle} />

        <div className={styles.notesData}>
          {popData?.test_notes?.length
            ? popData?.test_notes
            : t('Common.No Notes Found')}
        </div>
      </div>
    </div>
  )
}

export default ViewLRadiologyReportNotes
