/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect, useState } from 'react'
import { Input } from '../../../../components/common/input/input'
import Select from 'react-select'
import { DropdownIndicator } from '../../../../components/common/dropdown-indicator/DropdownIndicator'
import Button from '../../../../components/common/button/Button'
import { useAppDispatch, useAppSelector } from '../../../../hooks'
import { viewAllInventoryData } from '../../../../redux/features/branch-store/branchStoreAsyncActions'
import { requestGenerator } from '../../../../utils/payloadGenerator'
import { debounce } from 'lodash'
import { adjustmentFormUpdateApi } from '../../../../redux/features/pharmacy/pharmacyAsyncActions'
import Loader from '../../../../components/common/spinner/Loader'
import styles from './styles.module.scss'
import { t } from 'i18next'

const adjustmentDropdownData = [
  {
    label: 'Addition',
    value: 'ADD',
  },
  {
    label: 'Subtraction',
    value: 'SUB',
  },
]

const AdjustmentForm = ({ closeModal }: any) => {
  const dispatch = useAppDispatch()
  const { viewInventoryData } = useAppSelector((state) => state.branchStore)
  const { isFormLoading } = useAppSelector((state) => state.pharmacy)
  const { branchData } = useAppSelector((state) => state.login)
  const filterBranchStore = branchData?.pharmacy_store
  const [adjustmentValue, setAdjustmentValue] = useState<any>('')
  const [itemCode, setItemCode] = useState<any>('')
  const [itemNo, setItemNo] = useState<any>('')
  const [itemName, setItemName] = useState<any>('')
  const [qty, setQty] = useState('')
  const [adjustmentqty, setAdjustmentQty] = useState('')

  const createRequestPayload = (data?: any, event?: any) => {
    let requestData: any = {
      page: 1,
      pageSize: 10,
      store_id: filterBranchStore?.[0]?._id,
    }
    if (data && event) {
      requestData[data] = event
    }
    return requestData
  }

  useEffect(() => {
    dispatch(viewAllInventoryData(requestGenerator(createRequestPayload())))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch])

  const handleItemCodeApi = (event: any) => {
    dispatch(
      viewAllInventoryData(
        requestGenerator(createRequestPayload('item_code', event))
      )
    )
  }

  const handleItemCodeChange = useCallback(
    debounce((text) => handleItemCodeApi(text), 1000),
    []
  )

  const handleItemNoApi = (event: any) => {
    dispatch(
      viewAllInventoryData(
        requestGenerator(createRequestPayload('item_no', Number(event)))
      )
    )
  }

  const handleItemNoChange = useCallback(
    debounce((text) => handleItemNoApi(text), 1000),
    []
  )

  const handleItemNameApi = (event: any) => {
    dispatch(
      viewAllInventoryData(
        requestGenerator(createRequestPayload('item_name', event))
      )
    )
  }

  const handleItemNameChange = useCallback(
    debounce((text) => handleItemNameApi(text), 1000),
    []
  )

  const updateData = () => {
    let data = {
      item_id: itemCode?.value,
      store_id: filterBranchStore?.[0]?._id,
      qty: Number(adjustmentqty),
      action: adjustmentValue,
      name: itemName?.label,
    }

    dispatch(adjustmentFormUpdateApi(requestGenerator(data))).then(
      (response) => {
        if (response.type === 'pharmacy/adjustmentFormUpdateApi/fulfilled') {
          closeModal()
        }
      }
    )
  }
  return (
    <>
      {isFormLoading && <Loader />}
      <div className={styles.formContainer}>
        <div className={styles.formFields}>
          <div className={styles.inputFieldContainer}>
            <label htmlFor="branchType" className={styles.formLabel}>
              {t('InventoryItemTable.Item_Code')}
            </label>
            <div className={styles.fieldAndErrorTxtContainer}>
              <Select
                className={styles.selectInputField}
                placeholder={t('Pharmacy.Select_Item_Code')}
                closeMenuOnSelect={true}
                value={itemCode}
                onInputChange={handleItemCodeChange}
                components={{ DropdownIndicator }}
                options={viewInventoryData?.map((items: any) => ({
                  label: items.item_code,
                  value: items.item_id,
                  itemNo: items.item_no,
                  itemName: items.item_name,
                  qty: items.qty,
                }))}
                isSearchable={true}
                onChange={(e: any) => {
                  setItemCode({
                    label: e.label,
                    value: e.value,
                  })
                  setItemNo({
                    label: e.itemNo,
                    value: e.value,
                  })
                  setItemName({
                    label: e.itemName,
                    value: e.value,
                  })
                  setQty(e.qty)
                }}
                maxMenuHeight={200}
              />
            </div>
          </div>
          <div className={styles.inputFieldContainer}>
            <label htmlFor="branchType" className={styles.formLabel}>
              {t('Pharmacy.Item_No')}.
            </label>
            <div className={styles.fieldAndErrorTxtContainer}>
              <Select
                className={styles.selectInputField}
                placeholder={t('Pharmacy.Select_No')}
                closeMenuOnSelect={true}
                onInputChange={handleItemNoChange}
                components={{ DropdownIndicator }}
                value={itemNo}
                options={viewInventoryData?.map((items: any) => ({
                  label: items.item_no,
                  value: items.item_id,
                  itemCode: items.item_code,
                  itemName: items.item_name,
                  qty: items.qty,
                }))}
                isSearchable={true}
                onChange={(e: any) => {
                  setItemNo({
                    label: e.label,
                    value: e.value,
                  })
                  setItemCode({
                    label: e.itemCode,
                    value: e.value,
                  })
                  setItemName({
                    label: e.itemName,
                    value: e.value,
                  })
                  setQty(e.qty)
                }}
                maxMenuHeight={200}
              />
            </div>
          </div>
        </div>
        <div className={styles.formFields}>
          <div className={styles.inputFieldContainer}>
            <label htmlFor="branchType" className={styles.formLabel}>
              {t('InventoryItemTable.Item_Name')}
            </label>
            <div className={styles.fieldAndErrorTxtContainer}>
              <Select
                className={styles.selectInputField}
                placeholder={t('Pharmacy.Select_Item_Name')}
                closeMenuOnSelect={true}
                onInputChange={handleItemNameChange}
                components={{ DropdownIndicator }}
                value={itemName}
                options={viewInventoryData?.map((items: any) => ({
                  label: items.item_name,
                  value: items.item_id,
                  itemCode: items.item_code,
                  itemNo: items.item_no,
                  qty: items.qty,
                }))}
                isSearchable={true}
                onChange={(e: any) => {
                  setItemName({
                    label: e.label,
                    value: e.value,
                  })
                  setItemCode({
                    label: e.itemCode,
                    value: e.value,
                  })
                  setItemNo({
                    label: e.itemNo,
                    value: e.value,
                  })
                  setQty(e.qty)
                }}
                maxMenuHeight={200}
              />
            </div>
          </div>
          <div style={{ flexBasis: '50%', marginBlock: '20px' }}>
            <Input
              labelText={t('ItemUnitMaster.Qty') || 'Qty'}
              disabled
              disabledFields
              value={qty}
            />
          </div>
        </div>
        <div className={styles.formFields}>
          <div className={styles.inputFieldContainer}>
            <label htmlFor="branchType" className={styles.formLabel}>
              {t('Pharmacy.Adjustment')}
              <span className="asterick">*</span>
            </label>
            <div className={styles.fieldAndErrorTxtContainer}>
              <Select
                className={styles.selectInputField}
                placeholder={t('PatientEMRPlaceHolder.Select branch')}
                closeMenuOnSelect={true}
                components={{ DropdownIndicator }}
                options={adjustmentDropdownData}
                isSearchable={false}
                onChange={(e: any) => {
                  setAdjustmentValue(e.value)
                }}
                maxMenuHeight={200}
              />
            </div>
          </div>
          <div style={{ flexBasis: '50%', marginBlock: '20px' }}>
            <Input
              labelText={t('Pharmacy.Adjustment_Qty') || 'Adjustment Qty'}
              requiredField
              onChange={(e: any) => setAdjustmentQty(e.target.value)}
            />
          </div>
        </div>
        <div className={styles.btnCenter}>
          <Button
            title={t('ManageCategory.Update') || 'Update'}
            handleClick={updateData}
            disable={!viewInventoryData?.length}
          />
        </div>
      </div>
    </>
  )
}

export default AdjustmentForm
