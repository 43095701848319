import { t } from 'i18next'
import { SETTLED_INVOICE_PATIENT_EMAIL, VIEW_ID } from '../constants/constant'

export const settledInvoiceEmailValidator = {
  [SETTLED_INVOICE_PATIENT_EMAIL]: {
    required: t('AuthValidator.Email'),
  },
  [VIEW_ID]: {
    required: t('DynamicTemplate.SelectTemplateVal'),
  },
}
