import { createSlice } from "@reduxjs/toolkit";
import { getAllCriteria, getAllInternal } from "./internalAsyncActions";
import { error } from "console";
export interface IInternal {
  isLoading: boolean;
  allCriteriaData: {};
  allInternalData:{};
  error: any;
  isStatusUpdated: boolean
  getAllDocumentPaylod:{};
}
export const initialState: IInternal = {
  isLoading: false,
  allCriteriaData: [],
  allInternalData:[],
  isStatusUpdated: true,
  error: null,
  getAllDocumentPaylod:{},
};

export const internalSlice = createSlice({
  name: "internal",
  initialState, 
  reducers: {
    clearCriteriaData: (state) => {
      state.allCriteriaData = [];
      state.allInternalData = [];
      state.getAllDocumentPaylod = {};
    },
  },
  extraReducers(builder) {
    // GET ALL Criteria
    builder
    .addCase(getAllCriteria.pending, (state) => {
      state.isLoading = true;
      state.isStatusUpdated = false
    })
    .addCase(getAllCriteria.fulfilled, (state, action) => {
      state.isLoading = false;
      state.isStatusUpdated = false
      state.allCriteriaData = action.payload?.data;     
    })
    .addCase(getAllCriteria.rejected, (state, error) => {
      state.isLoading = false;
      state.error = error;
      state.isStatusUpdated = false;
    });

    //GET ALL INTERNAL
    builder.addCase(getAllInternal.pending,(state) => {
      state.isLoading = true;
      state.isStatusUpdated = false
    })
    
    .addCase(getAllInternal.fulfilled, (state, action) => {
      state.isLoading = false;
      state.isStatusUpdated = false;
      state.allInternalData = action.payload?.data
    })

    .addCase(getAllInternal.rejected, (state, error) => {
      state.isLoading = false;
      state.isStatusUpdated = false; 
      state.error = error;
    })
},
});
export const { clearCriteriaData } = internalSlice.actions;
export default internalSlice.reducer;