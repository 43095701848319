// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media only screen and (min-width: 768px) {
  .searchdropdown_inputData__oiPu5 {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    width: 234px;
    height: 42px;
    border: 1px solid var(--grey6);
    background: var(--white1);
    border-radius: 10px;
    margin: auto;
    position: relative;
  }
  .searchdropdown_inputData__oiPu5 .searchdropdown_cursorPointer__B9Fcv {
    cursor: pointer;
  }
  .searchdropdown_inputData__oiPu5 .searchdropdown_inputFilled__YNgGh {
    border: none;
    background: var(--white1);
    font-size: var(--XSmall);
    width: 78%;
  }
  .searchdropdown_inputData__oiPu5 :focus {
    outline: none;
  }
  .searchdropdown_inputData__oiPu5 .searchdropdown_seeDataInDropDownMain__G2xe1 {
    position: absolute;
    cursor: pointer;
    padding: 10px;
    width: 234px;
    max-height: 145px;
    overflow-y: scroll;
    border: 1px solid var(--grey6);
    background: var(--white1);
    top: 41px;
    left: -1px;
    border-radius: 10px;
    z-index: 10;
  }
  .searchdropdown_inputData__oiPu5 .searchdropdown_seeDataInDropDownMain__G2xe1 :-webkit-scrollbar {
    display: none;
  }
  .searchdropdown_inputData__oiPu5 .searchdropdown_seeDataInDropDownMain__G2xe1 .searchdropdown_dropdownItemText__OLgai {
    cursor: pointer;
    margin: 0px 0 5px 10px;
  }
  .searchdropdown_inputData__oiPu5 ::-webkit-scrollbar {
    display: none;
  }
  .searchdropdown_inputData__oiPu5 .searchdropdown_hideDataInDropDownMain__2k1ON {
    display: none;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/common/search-dropdown/searchdropdown.module.scss"],"names":[],"mappings":"AAAA;EACE;IACE,aAAA;IACA,mBAAA;IACA,6BAAA;IACA,YAAA;IACA,YAAA;IACA,8BAAA;IACA,yBAAA;IACA,mBAAA;IACA,YAAA;IACA,kBAAA;EACF;EAAE;IACE,eAAA;EAEJ;EAAE;IACE,YAAA;IACA,yBAAA;IACA,wBAAA;IACA,UAAA;EAEJ;EAAE;IACE,aAAA;EAEJ;EAAE;IACE,kBAAA;IACA,eAAA;IACA,aAAA;IACA,YAAA;IACA,iBAAA;IACA,kBAAA;IACA,8BAAA;IACA,yBAAA;IACA,SAAA;IACA,UAAA;IACA,mBAAA;IACA,WAAA;EAEJ;EAAI;IACE,aAAA;EAEN;EACI;IACE,eAAA;IACA,sBAAA;EACN;EAGE;IACE,aAAA;EADJ;EAGE;IACE,aAAA;EADJ;AACF","sourcesContent":["@media only screen and (min-width: 768px) {\n  .inputData {\n    display: flex;\n    align-items: center;\n    justify-content: space-evenly;\n    width: 234px;\n    height: 42px;\n    border: 1px solid var(--grey6);\n    background: var(--white1);\n    border-radius: 10px;\n    margin: auto;\n    position: relative;\n    .cursorPointer{\n      cursor: pointer;\n    }\n    .inputFilled {\n      border: none;\n      background: var(--white1);\n      font-size: var(--XSmall);\n      width: 78%;\n    }\n    :focus {\n      outline: none;\n    }\n    .seeDataInDropDownMain {\n      position: absolute;\n      cursor: pointer;\n      padding: 10px;\n      width: 234px;\n      max-height: 145px;\n      overflow-y: scroll;\n      border: 1px solid var(--grey6);\n      background: var(--white1);\n      top: 41px;\n      left: -1px;\n      border-radius: 10px;\n      z-index: 10;\n\n      :-webkit-scrollbar {\n        display: none;\n      }\n\n      .dropdownItemText {\n        cursor: pointer;\n        margin: 0px 0 5px 10px;\n      }\n    }\n\n    ::-webkit-scrollbar {\n      display: none;\n    }\n    .hideDataInDropDownMain {\n      display: none;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"inputData": `searchdropdown_inputData__oiPu5`,
	"cursorPointer": `searchdropdown_cursorPointer__B9Fcv`,
	"inputFilled": `searchdropdown_inputFilled__YNgGh`,
	"seeDataInDropDownMain": `searchdropdown_seeDataInDropDownMain__G2xe1`,
	"dropdownItemText": `searchdropdown_dropdownItemText__OLgai`,
	"hideDataInDropDownMain": `searchdropdown_hideDataInDropDownMain__2k1ON`
};
export default ___CSS_LOADER_EXPORT___;
