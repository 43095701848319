import { FC, useEffect } from 'react'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import styles from './campaignsLayout.module.scss'
import { CampaignsTabData } from '../../../constants/data'
import Tabs from '../../../components/core/tab/Tabs'

interface ICampaignsLayout {}

const CampaignsLayout: FC<ICampaignsLayout> = () => {
  const navigate = useNavigate()
  const location = useLocation().pathname
  useEffect(() => {
    if (location === '/campaigns') {
      navigate('/campaigns/onGoingCampaigns')
    }
  }, [location])
  return (
    <>
      <div className={styles.mainContainer}>
        <div className={styles.tabContainer}>
          <Tabs tabData={CampaignsTabData} />
          <Outlet />
        </div>
      </div>
    </>
  )
}

export default CampaignsLayout
