import { useNavigate } from 'react-router'
import ToggleSwitchV2 from '../../components/common/toggle-switch/ToggleSwitchV2'
import { useAppDispatch } from '../../hooks'
import { requestGenerator } from '../../utils/payloadGenerator'
import styles from './tableData.module.scss'
import { EditIcon } from '../../components/common/svg-components'
import { colors } from '../color'
import { updateStatusInventoryItem } from '../../redux/features/inventory-item/inventoryItemAsyncActions'
import { t } from 'i18next'

export const inventoryItemTableHeaderData: any = [
  {
    Header: t('AllAttachmentHeader.NAME'),
    accessor: 'name',
  },
  {
    Header: t('InventoryItemTable.ITEM_CODE'),
    accessor: 'item_code',
  },
  {
    Header: t('ViewInventory.ITEM NO'),
    accessor: 'item_no',
  },
  {
    Header: t('InventoryItemTable.UNIT_TYPE'),
    accessor: 'base_unit_type.value',
  },
  {
    Header: t('InventoryItemTable.GROUP'),
    accessor: 'group',
  },
  {
    Header: t('InventoryItemTable.QUANTITY'),
    accessor: (row: any) => {
      return row?.quantity ?? '-'
    },
  },
  {
    Header: t('ServiceMaster.CHARGEABLE'),
    accessor: 'chargable',
  },
  {
    Header: t('BedSetup.COST_PRICE'),
    accessor: 'cost_price',
  },
  {
    Header: t('BedSetup.SELL_PRICE'),
    accessor: 'sell_price',
  },
  {
    Header: t('InventoryItemTable.EXPIRY_DAYS'),
    accessor: (row: any) => {
      return row?.expiry_days ?? '-'
    },
  },
  {
    Header: t('InventoryItemTable.COMPOSITIONS'),
    accessor: (row: any) => {
      return row?.medicines_compositions ?? '-'
    },
  },
  {
    Header: t('RcptTableHeader.STATUS'),
    Cell: (props: any) => {
      const dispatch = useAppDispatch()
      const is_active = props?.row?.original?.is_active
      const handleToggle = (item: any) => {
        const payload = {
          id: item._id,
          is_active: !item.is_active,
        }
        dispatch(updateStatusInventoryItem(requestGenerator(payload))).then(
          (e) => {}
        )
      }
      return (
        <ToggleSwitchV2
          isToggled={is_active}
          handleToggle={() => {
            props?.onRowClick(props?.row?.original)
          }}
        />
      )
    },
  },
  {
    Header: t('DocUploadHeader.ACTION'),
    Cell: ({ row }: any) => {
      const editable = row?.original?.is_active
      const navigate = useNavigate()
      const handleEdit = (item: any) => {
        navigate('/inventoryitemtable/addnewitem', {
          state: { itemDetailData: item },
        })
      }
      return (
        <EditIcon
          fillColor={colors.grey4}
          handleClick={() => handleEdit(row?.original)}
          customClass={editable ? styles.cursorPointor : styles.cursorDefault}
        />
      )
    },
  },
]
