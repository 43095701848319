import { t } from 'i18next'
import {
  REMARK,
  EXPIRY_DATE,
  SUB_COMPANIES,
  DOCS,
  DOCUMENT_TYPE,
  ATTACHEMENTS,
  BRANCH,
  _EMPID,
  EMP_TYPE,
  EMP_NAME,
} from '../constants/constant'
export const documentsValidators = {
  [_EMPID]: {
    required: t('HrDocument.EmpVal'),
  },
  [EMP_TYPE]: {
    required: t('BookingConfirmationValidators.TYPE'),
  },
  [DOCUMENT_TYPE]: {
    required: t('HrDocument.DocType'),
  },
  [REMARK]: {
    required: t('HrDocument.RemarkVal'),
  },
  [DOCS]: {
    required: t('HrDocument.DocType'),
  },

  [SUB_COMPANIES]: {
    required: t('HrDocument.SubCompany'),
  },
  [EMP_NAME]: {
    required: t('HrDocument.DocNameVal'),
  },
  [EXPIRY_DATE]: {
    required: t('HrDocument.ExpiryVal'),
  },
  [ATTACHEMENTS]: {
    required: t('HrDocument.AttachVal'),
  },

  [BRANCH]: {
    required: t('HrDocument.AttachVal'),
  },
}
