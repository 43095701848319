import axios from "axios";
import { IAPIPayload } from "../../../interfaces/apiInterface";
import { GET_ALL_CRITERIA, GET_ALL_INTERNAL } from "../../../config/config";

export const getAllCriteriaList = (data: IAPIPayload) => {
    return axios.post(GET_ALL_CRITERIA, data);
};

export const getAllInternalList = (data:IAPIPayload) => {{
    return axios.post(GET_ALL_INTERNAL, data);
}};