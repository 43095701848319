// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tagListPopup_notesPopupContainer__okhKU {
  width: 350px;
  min-height: 300px;
  margin: auto;
  background: var(--white1);
  border-radius: 10px;
  padding: 10px;
}
.tagListPopup_notesPopupContainer__okhKU .tagListPopup_closeIconStyle__Hh9z2 {
  cursor: pointer;
  float: right;
}
.tagListPopup_notesPopupContainer__okhKU .tagListPopup_notesContainer__DuvQe {
  margin-top: 50px;
}
.tagListPopup_notesPopupContainer__okhKU .tagListPopup_notesContainer__DuvQe .tagListPopup_title__Q79rY {
  text-align: center;
  font-weight: var(--font-semibold);
  font-size: var(--font-24);
  line-height: 36px;
  color: var(--black2);
}
.tagListPopup_notesPopupContainer__okhKU .tagListPopup_notesContainer__DuvQe .tagListPopup_dividerStyle__pp04V {
  margin: 10px auto 30px auto;
  width: 80px;
  height: 3px;
  background: var(--green1);
}
.tagListPopup_notesPopupContainer__okhKU .tagListPopup_notesContainer__DuvQe .tagListPopup_listContainer__5wAPc {
  max-height: 180px;
  overflow-y: scroll;
}
.tagListPopup_notesPopupContainer__okhKU .tagListPopup_notesContainer__DuvQe .tagListPopup_listContainer__5wAPc .tagListPopup_deptText__UINPx {
  margin: 0 0 5px 30px;
  font-size: var(--font-20);
  font-weight: var(--font-medium);
  color: var(--grey4);
}
.tagListPopup_notesPopupContainer__okhKU .tagListPopup_notesContainer__DuvQe .tagListPopup_listContainer__5wAPc::-webkit-scrollbar {
  display: block;
  width: 7px;
  height: 7px;
}
.tagListPopup_notesPopupContainer__okhKU .tagListPopup_notesContainer__DuvQe .tagListPopup_listContainer__5wAPc::-webkit-scrollbar-track {
  background: transparent;
}
.tagListPopup_notesPopupContainer__okhKU .tagListPopup_notesContainer__DuvQe .tagListPopup_listContainer__5wAPc::-webkit-scrollbar-thumb {
  background-color: var(--grey7);
  border-radius: 10px;
  border: 1px solid var(--grey7);
}`, "",{"version":3,"sources":["webpack://./src/components/common/modal/tag-list-popup/tagListPopup.module.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;EACA,iBAAA;EACA,YAAA;EACA,yBAAA;EACA,mBAAA;EACA,aAAA;AACF;AAAE;EACE,eAAA;EACA,YAAA;AAEJ;AAAE;EACE,gBAAA;AAEJ;AADI;EACE,kBAAA;EACA,iCAAA;EACA,yBAAA;EACA,iBAAA;EACA,oBAAA;AAGN;AADI;EACE,2BAAA;EACA,WAAA;EACA,WAAA;EACA,yBAAA;AAGN;AADI;EACE,iBAAA;EACA,kBAAA;AAGN;AAFM;EACE,oBAAA;EACA,yBAAA;EACA,+BAAA;EACA,mBAAA;AAIR;AAFM;EACE,cAAA;EACA,UAAA;EACA,WAAA;AAIR;AAFM;EACE,uBAAA;AAIR;AADM;EACE,8BAAA;EACA,mBAAA;EACA,8BAAA;AAGR","sourcesContent":[".notesPopupContainer {\n  width: 350px;\n  min-height: 300px;\n  margin: auto;\n  background: var(--white1);\n  border-radius: 10px;\n  padding: 10px;\n  .closeIconStyle {\n    cursor: pointer;\n    float: right;\n  }\n  .notesContainer {\n    margin-top: 50px;\n    .title {\n      text-align: center;\n      font-weight: var(--font-semibold);\n      font-size: var(--font-24);\n      line-height: 36px;\n      color: var(--black2);\n    }\n    .dividerStyle {\n      margin: 10px auto 30px auto;\n      width: 80px;\n      height: 3px;\n      background: var(--green1);\n    }\n    .listContainer {\n      max-height: 180px;\n      overflow-y: scroll;\n      .deptText {\n        margin: 0 0 5px 30px;\n        font-size: var(--font-20);\n        font-weight: var(--font-medium);\n        color: var(--grey4);\n      }\n      &::-webkit-scrollbar {\n        display: block;\n        width: 7px;\n        height: 7px;\n      }\n      &::-webkit-scrollbar-track {\n        background: transparent;\n      }\n\n      &::-webkit-scrollbar-thumb {\n        background-color: var(--grey7);\n        border-radius: 10px;\n        border: 1px solid var(--grey7);\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"notesPopupContainer": `tagListPopup_notesPopupContainer__okhKU`,
	"closeIconStyle": `tagListPopup_closeIconStyle__Hh9z2`,
	"notesContainer": `tagListPopup_notesContainer__DuvQe`,
	"title": `tagListPopup_title__Q79rY`,
	"dividerStyle": `tagListPopup_dividerStyle__pp04V`,
	"listContainer": `tagListPopup_listContainer__5wAPc`,
	"deptText": `tagListPopup_deptText__UINPx`
};
export default ___CSS_LOADER_EXPORT___;
