import { useEffect, useState } from 'react'
import { useLocation } from 'react-router'
import Select, { components } from 'react-select'
import {
  CrossIcon,
  SearchIcon,
} from '../../../components/common/svg-components'
import SelectImage from '../../../assets/images/Default Image.png'
import NoSelectImage from '../../../assets/images/Delete Image.png'
import {
  trimValue,
  dataURI,
  isDataUri,
  getDataUriFileSize,
  branchDropdownStyles,
  reactSelectStyles,
} from '../../../utils/utils'
import Button from '../../../components/common/button/Button'
import { colors } from '../../../constants/color'
import {
  ACCOUNT_HOLDER_NAME,
  ACCOUNT_NUMBER,
  ANNUAL_LEAVES,
  BANK_IDENTIFIER_CODE,
  BANK_NAME,
  BASIC_SALARY,
  BIRTH_DATE,
  BRANCH,
  BRANCH_LOCATION,
  DATE_OF_JOINING,
  DEPARTMENT,
  DESIGNATION,
  EMPLOYEES_ID,
  EMPLOYEE_NATIONAL_ID,
  EMPLOYEE_PROFILE_PIC,
  GENDER,
  HOURLY_SALARY,
  LOCAL_ADDRESS,
  NAME_AR,
  NAME_EN,
  NATIONALITY,
  OVERTIME_TYPE,
  PAYMENT_MEDIUM,
  PHONE,
  PROBATION_END_DATE,
  PROBATION_PERIOD,
  REPORTING_TO,
  SALARY_TYPE,
  SHIFT_TYPE,
  SUB_COMPANY,
  TAX_PAYER_ID,
  WEEKEND,
} from '../../employees/employeeConstants'
import { IAddEmployeePersonalDetailsForm } from '../../employees/employeeInterface'
import { SubmitHandler, useFieldArray, useForm } from 'react-hook-form'
import PhoneInput from 'react-phone-input-2'
import AttachSingleFileV3 from '../../../components/common/attach-files/single-file-V3/AttachSingleFileV3'
import AttachSingleFileV2 from '../../../components/common/attach-files/single-file-V2/AttachSingleFileV2'
import { useNavigate } from 'react-router-dom'
import { addEmployeeDetailsValidators } from './addEmployeeValidator'
import Popup from '../../../components/common/popup/Popup'
import { useAppDispatch, useAppSelector } from '../../../hooks'
import {
  addEmployee,
  editEmployeeInfo,
  getAllReportingUserList,
} from '../../../redux/features/employee/employeeAsyncActions'
import { requestGenerator } from '../../../utils/payloadGenerator'
import moment from 'moment'
import EmployeeSearchModal from './employee-search-modal/EmployeeSearchModal'
import { EDIT_EMPLOYEE_BY_ID } from '../../../constants/asyncActionsType'
import {
  clearEditEmployeeData,
  clearEmployeeData,
} from '../../../redux/features/employee/employeeSlice'

import { Input } from '../../../components/common/input/input'
import Loader from '../../../components/common/spinner/Loader'
import { setMessage } from '../../../redux/features/toast/toastSlice'
import { failure } from '../../../constants/data'
import styles from './addEmployee.module.scss'
import { getAllDepartment } from '../../../redux/features/department/departmentAsyncActions'
import { getAllBranch } from '../../../redux/features/branch/branchAsyncActions'
import { Label } from '../../../components/common/label'
import { CustomRadio } from '../../../components/common/custom-radio'
import { useTranslation } from 'react-i18next'

interface FileList {
  name: string
  data_uri: any
}
const AddEmployee = () => {
  const dispatch = useAppDispatch()
  const { t } = useTranslation()
  const { isLoading, reportingUserListData } = useAppSelector(
    (state) => state.employee
  )
  const { branchData } = useAppSelector((state) => state.branch)
  const { departmentData } = useAppSelector((state) => state.department)
  const { masterValueData } = useAppSelector((state) => state.login)
  const [nationalityDropdownData, setNationalityDropdownData] = useState<any[]>(
    []
  )
  const [subCompanyDropdownData, setSubCompanyDropdownData] = useState<any[]>(
    []
  )
  const [shiftTypeDropdownData, setShiftTypeDropdownData] = useState<any[]>([])
  const [weekendDropdownData, setWeekendDropdownData] = useState<any[]>([])
  const [allowanceDropdownData, setAllowanceDropdownData] = useState<any[]>([])
  const [deductionDropdownData, setDeductionDropdownData] = useState<any[]>([])
  const [showSearchModal, setShowSearchModal] = useState<boolean>(false)
  const [searchModalData, setSearchModalData] = useState({})
  const [showAnotherComponent, setShowAnotherComponent] = useState(false)
  const [showNoImage, setShowNoImage] = useState(false)
  const [showWebCamComponent, setShowWebCamComponent] = useState(false)
  const [selectedOption, setSelectedOption] = useState<any>('')
  const [imageFiles, setImageFiles] = useState({ name: '', data_uri: '' })
  const [showSalaryType, setSalaryType] = useState<string>('HOURLY')
  const [employeesData, setEmployeesData] = useState<any>({})
  const [inputValue, setinputValue] = useState<any>('a')
  const [deductionInputValue, setDeductionValue] = useState<any>('a')
  const [overTimeTypeValue, setOverTimeTypeValue] = useState<boolean>(false)
  const navigate = useNavigate()
  const location = useLocation()
  const {
    register,
    reset,
    handleSubmit,
    watch,
    setValue,
    getValues,
    trigger,
    setError,
    clearErrors,
    formState: { errors },
    control,
  } = useForm<any>({})

  const joiningDate = watch(DATE_OF_JOINING)
  const probationEndDate = watch(PROBATION_END_DATE)
  const watchBranchValue = watch(BRANCH)

  const {
    append: allowanceAppend,
    remove: allowanceRemove,
    fields: allowanceFields,
  } = useFieldArray<any>({
    control,
    name: 'fixed_allowance',
  })

  const {
    append: deductionAppend,
    remove: deductionRemove,
    fields: deductionFields,
  } = useFieldArray<any>({
    control,
    name: 'fixed_deductions',
  })

  const watchFixedDeductionsValue = watch('fixed_deductions')
  const watchAllowancensValue = watch('fixed_allowance')
  const [selectedOptionsAllowance, setSelectedOptionsAllowance] = useState<
    any[]
  >([])
  const [selectedOptionsDeduction, setSelectedOptionsDeduction] = useState<
    any[]
  >([])

  const handleAllowanceChange = (event: any, index: any) => {
    const selectedOption = event.target.value
    let updatedSelectedOptions = [...selectedOptionsAllowance]
    updatedSelectedOptions[index] = selectedOption
    setSelectedOptionsAllowance(updatedSelectedOptions)
  }

  const handleDeductionChange = (event: any, index: any) => {
    const selectedOption = event?.target?.value
    let updatedSelectedOptions = [...selectedOptionsDeduction]
    updatedSelectedOptions[index] = selectedOption
    setSelectedOptionsDeduction(updatedSelectedOptions)
  }

  const handleAdd = () => {
    setinputValue('')
    allowanceAppend({ allowanceName: '', allowanceValue: '' })
  }

  const handleDeductionAdd = () => {
    setDeductionValue?.('')
    deductionAppend?.({ deductionName: '', deductionValue: '' })
  }

  const showValidationErrors = () => {
    const checkLength = watchAllowancensValue?.filter(
      (s: any) => !s?.allowanceName?.length
    )
    const checkDeductionLength = watchFixedDeductionsValue?.filter(
      (s: any) => !s?.deductionName?.length
    )
    if (
      (watchAllowancensValue?.length && checkLength?.length) ||
      (watchFixedDeductionsValue?.length && checkDeductionLength?.length)
    ) {
      dispatch(
        setMessage({
          type: failure,
          message: t('AddEmp.SelectAllowance'),
        })
      )
    }
  }

  // Employee Image
  const handleImageMouseEnter = () => {
    setShowAnotherComponent(true)
  }
  const handleImageMouseLeave = () => {
    setShowAnotherComponent(false)
  }
  const handleDeleteImage = () => {
    setShowNoImage(true)
    setImageFiles({ name: '', data_uri: '' })
  }
  const handleNoImageMouseEnter = () => {
    setShowWebCamComponent(true)
  }
  const handleNoImageMouseLeave = () => {
    setShowWebCamComponent(false)
  }
  // PROBATION PERIOD
  useEffect(() => {
    const joiningDates = new Date(joiningDate)
    const probationEndDates = new Date(probationEndDate)
    const differenceInMilliseconds =
      probationEndDates.getTime() - joiningDates.getTime()
    const differenceInDays = differenceInMilliseconds / (1000 * 60 * 60 * 24)
    if (isNaN(differenceInDays)) {
      return
    }
    setValue(PROBATION_PERIOD, `${differenceInDays} days`)
  }, [joiningDate, probationEndDate])

  // PROFILE PIC
  const selectImageFile = watch(EMPLOYEE_PROFILE_PIC)
  const fileName = isDataUri(selectImageFile) ? '' : selectImageFile?.[0]
  const probationPeriodValue = watch(PROBATION_PERIOD)

  // convert file object to data_uri (base64)
  useEffect(() => {
    const fileList: FileList = { name: '', data_uri: '' }
    const getDataURI = async (fileName: File) => {
      try {
        const result = await dataURI(fileName)
        fileList.name = fileName.name
        fileList.data_uri = result
        setImageFiles(fileList)
      } catch (error) {}
    }
    if (fileName) {
      getDataURI(fileName)
    }
  }, [fileName])

  //  Radio Salary Type Buttons
  const onSalaryTypeChange = (e: any) => {
    const value = e.target.value
    setSalaryType(value === 'MONTHLY' ? 'MONTHLY' : 'HOURLY')
    setSelectedOption(value === selectedOption ? null : value)
  }
  /* Initial API calls for Department and Branch */
  useEffect(() => {
    let data = {
      search: '',
      page: 1,
      pageSize: 1000,
    }
    dispatch(getAllDepartment(requestGenerator(data)))
    dispatch(getAllBranch(requestGenerator(data)))
  }, [dispatch])

  // API Calling for Reporting to initial data
  useEffect(() => {
    const requestData = {
      search: '',
      page: '',
      pageSize: 1000,
      excludeEmployees: false,
    }
    dispatch(getAllReportingUserList(requestGenerator(requestData)))
  }, [dispatch])
  // set Dropdown Value from Master Data
  const setDropdownValue = (valueArr: any, setter: any) => {
    if (valueArr?.values?.length > 0) {
      let tempArr: any = []
      tempArr = valueArr?.values?.map((item: any) => {
        return {
          label: item?.value,
          value: item?._id,
        }
      })
      setter(tempArr)
    } else {
      setter([])
    }
  }
  // Find Category Name from Master Data
  useEffect(() => {
    const nationality = masterValueData?.find(
      (item: any) => item?.category_name === 'NATIONALITY'
    )

    const shiftType = masterValueData?.find(
      (item: any) => item?.category_name === 'SHIFT-TYPE'
    )
    const subCompany = masterValueData?.find(
      (item: any) => item?.category_name === 'SUB-COMPANY'
    )
    const weekend = masterValueData?.find(
      (item: any) => item?.category_name === 'WEEKDAYS'
    )
    const allowance = masterValueData?.find(
      (item: any) => item?.category_name === 'ALLOWANCE'
    )
    const deduction = masterValueData?.find(
      (item: any) => item?.category_name === 'DEDUCTIONS'
    )

    setDropdownValue(nationality, setNationalityDropdownData)
    setDropdownValue(shiftType, setShiftTypeDropdownData)
    setDropdownValue(subCompany, setSubCompanyDropdownData)
    setDropdownValue(weekend, setWeekendDropdownData)
    setDropdownValue(allowance, setAllowanceDropdownData)
    setDropdownValue(deduction, setDeductionDropdownData)
  }, [masterValueData])
  // Allowance Dropdown

  const handleBack = () => {
    reset()
    dispatch(clearEmployeeData())
    navigate('/employees')
  }
  // Employee Search Popup
  const handleSearchPopup = () => {
    setSearchModalData({})
    setShowSearchModal((prevState) => !prevState)
  }
  // Employee Search Popup close
  const handleSearchModalClose = () => {
    setShowSearchModal((prevState) => !prevState)
    setSearchModalData({})
  }
  // Employee Search Get Popup Data By Id
  const handleSearchGetPopupData = (item: any) => {
    handleReset()
    setEmployeesData(item)
    setShowSearchModal((prevState) => !prevState)
  }
  // Prefix field from Employee Search popup
  useEffect(() => {
    setValue(NAME_EN, employeesData?.name)
    setValue(PHONE, employeesData?.phone)
    setValue(DESIGNATION, employeesData?.designation)
    const branchArray = employeesData?.branches?.map((item: any) => {
      return {
        label: item?.name,
        value: item?._id,
      }
    })
    setValue(BRANCH, branchArray)
    const departmentArray = employeesData?.departments?.map((item: any) => {
      return {
        label: item?.name,
        value: item?._id,
      }
    })
    setValue(DEPARTMENT, departmentArray)
  }, [employeesData])

  // Initial API call for Edit Employee
  let editEmployeeById = location.state

  useEffect(() => {
    if (editEmployeeById) {
      const checkArr =
        editEmployeeById?.user?.company_details?.fixed_allowance?.map(
          (s: any) => ({
            allowanceName: s?.name,
            allowanceValue: s?.value,
          })
        )
      setSelectedOptionsAllowance(checkArr)
      setValue('fixed_allowance', checkArr)
    }
  }, [editEmployeeById, setValue])

  useEffect(() => {
    if (editEmployeeById) {
      const editDeductions =
        editEmployeeById?.user?.company_details?.fixed_deduction?.map(
          (s: any) => ({
            deductionName: s.name,
            deductionValue: s?.value,
          })
        )
      setSelectedOptionsDeduction(editDeductions)
      // setValue("fixed_deductions", editDeductions);
      setTimeout(() => {
        setValue('fixed_deductions', editDeductions)
      }, 1000)
    }
  }, [editEmployeeById, setValue])

  // Edit Employee Set Data
  useEffect(() => {
    let birthdayDate = moment(editEmployeeById?.user?.birth_date).format(
      'YYYY-MM-DD'
    )
    let joiningDate = moment(
      editEmployeeById?.user?.company_details?.joining_date
    ).format('YYYY-MM-DD')
    if (editEmployeeById) {
      reset(editEmployeeById.user)
      setValue(
        'fixed_allowance',
        editEmployeeById?.user?.company_details?.fixed_allowance?.map(
          (s: any) => ({
            allowanceName: s.name,
            allowanceValue: s.value,
          })
        )
      )
      setValue(EMPLOYEES_ID, editEmployeeById?.user?.emp_id)
      setValue(EMPLOYEE_NATIONAL_ID, editEmployeeById?.user?.civil_id)
      setValue(LOCAL_ADDRESS, editEmployeeById?.user?.address)
      setValue(PHONE, editEmployeeById?.user?.phone)
      setValue(BIRTH_DATE, birthdayDate)
      setImageFiles({
        name: 'abc.jpg',
        data_uri: editEmployeeById?.user?.profile_pic,
      })
      // setValue for Branch
      const setBranchData = editEmployeeById?.user?.branch_id?.map((s: any) =>
        branchData?.find((items: any) => items?._id === s)
      )
      setValue(
        BRANCH,
        setBranchData?.map((branch: any) => {
          return {
            label: branch?.name,
            value: branch?._id,
          }
        })
      )

      // Company Details
      setValue(
        DESIGNATION,
        editEmployeeById?.user?.company_details?.designation
      )
      setValue(DATE_OF_JOINING, joiningDate)
      setValue(
        PAYMENT_MEDIUM,
        editEmployeeById?.user?.company_details?.payment_medium
      )
      setValue(
        PROBATION_PERIOD,
        editEmployeeById?.user?.company_details?.probation_period
      )
      // setValue(
      //   OVERTIME_TYPE,
      //   editEmployeeById?.user?.company_details?.overtime_type
      // );
      setOverTimeTypeValue(
        editEmployeeById?.user?.company_details?.overtime_type
      )

      setValue(
        ANNUAL_LEAVES,
        editEmployeeById?.user?.company_details?.annual_leave
      )
      setValue(
        BASIC_SALARY,
        editEmployeeById?.user?.company_details?.basic_salary
      )
      setValue(
        SALARY_TYPE,
        editEmployeeById?.user?.company_details?.salary_type
      )
      setValue(
        HOURLY_SALARY,
        editEmployeeById?.user?.company_details?.hourly_rate
      )
      setSalaryType(editEmployeeById?.user?.company_details?.salary_type)

      setSelectedOption(editEmployeeById?.user?.company_details?.salary_type)
      // setValue for Department
      const departmentArray = editEmployeeById?.user?.department?.map(
        (item: any) => {
          return {
            label: item?.name,
            value: item?._id,
          }
        }
      )
      setValue(DEPARTMENT, departmentArray)

      // Bank Details
      setValue(
        ACCOUNT_NUMBER,
        editEmployeeById?.user?.bank_details?.account_number
      )
      setValue(
        BANK_IDENTIFIER_CODE,
        editEmployeeById?.user?.bank_details?.bank_identifier_code
      )
      setValue(BANK_NAME, editEmployeeById?.user?.bank_details?.bank_name)
      setValue(
        BRANCH_LOCATION,
        editEmployeeById?.user?.bank_details?.branch_localtion
      )
      setValue(ACCOUNT_HOLDER_NAME, editEmployeeById?.user?.bank_details?.name)
      setValue(TAX_PAYER_ID, editEmployeeById?.user?.bank_details?.tax_payer_id)
    }

    if (
      (editEmployeeById?.user?.company_details?.probation_end_date !== null ||
        editEmployeeById?.user?.company_details?.probation_end_date !== '') &&
      editEmployeeById
    ) {
      let probationDate = moment(
        editEmployeeById?.user?.company_details?.probation_end_date
      ).format('YYYY-MM-DD')
      setValue(PROBATION_END_DATE, probationDate)
    } else {
      setValue(PROBATION_END_DATE, '')
    }
  }, [editEmployeeById])
  // setValue for Nationality Dropdown
  useEffect(() => {
    if (editEmployeeById) {
      const nationalityValue = nationalityDropdownData?.find((item: any) => {
        return item?.value == editEmployeeById?.user?.nationality
      })
      setValue(NATIONALITY, nationalityValue)
    }
  }, [nationalityDropdownData])
  // setValue for Sub Company Dropdown
  useEffect(() => {
    if (editEmployeeById) {
      const subCompanyValue = subCompanyDropdownData?.find(
        (item: any) =>
          item?.value === editEmployeeById?.user?.company_details?.sub_company
      )
      setValue(SUB_COMPANY, subCompanyValue)
    }
  }, [subCompanyDropdownData])
  // setValue for Shift Type Dropdown
  useEffect(() => {
    if (editEmployeeById) {
      const shiftTypeValue = shiftTypeDropdownData?.find(
        (item: any) =>
          item?.value === editEmployeeById?.user?.company_details?.shift_type
      )
      setValue(SHIFT_TYPE, shiftTypeValue)
    }
  }, [shiftTypeDropdownData])
  // setValue for Weekdays Dropdown
  useEffect(() => {
    if (editEmployeeById) {
      const weekendValue = weekendDropdownData?.map((item: any) => {
        let checkWeekend =
          editEmployeeById?.user?.company_details?.weekend?.filter(
            (p: any) => p?.toString() === item?.value?.toString()
          )
        if (checkWeekend?.length > 0) {
          return {
            label: item?.label,
            value: item?.value,
          }
        }
      })
      setValue(WEEKEND, weekendValue)
    }
  }, [weekendDropdownData])
  // Reporting To
  useEffect(() => {
    if (editEmployeeById) {
      const reportValue: any = reportingUserListData?.find(
        (item: any) =>
          item?._id === editEmployeeById?.user?.company_details?.reporting_to
      )
      setValue(REPORTING_TO, {
        label: reportValue?.name,
        value: reportValue?._id,
      })
    }
  }, [reportingUserListData])

  // React Hook form for the form handling
  const onSubmit: SubmitHandler<IAddEmployeePersonalDetailsForm> = (data) => {
    const checkAllowanceData = data?.fixed_allowance?.filter(
      (s: any) => !s.allowanceName?.length || !s.allowanceValue?.length
    )
    const checkDeductionData = data?.fixed_deductions?.filter(
      (s: any) => !s.deductionName?.length || !s.deductionValue?.length
    )
    if (checkAllowanceData?.length || checkDeductionData?.length) {
      dispatch(
        setMessage({
          type: failure,
          message: 'Please select Allowances/Deductions value',
        })
      )
    } else {
      const payloadData = {
        user_id: employeesData?._id,
        name_en: data?.name_en,
        name_ar: data?.name_ar,
        gender: data?.gender,
        branch_id: watchBranchValue?.map((item: any) => item.value),
        nationality: data?.nationality?.value,
        birth_date: data?.birth_date,
        civil_id: data?.employee_national_id,
        address: data?.local_address,
        phone: data?.employee_phone,
        file: imageFiles,
        company_details: {
          department_id: data?.department?.map((item: any) => item?.value),
          designation: data?.designation,
          reporting_to: data?.reporting_to?.value,
          overtime_type: overTimeTypeValue,
          shift_type: data?.shift_type?.value,
          sub_company: data?.sub_company?.value,
          annual_leave: data?.annual_leaves,
          joining_date: data?.date_of_joining,
          probation_period: Number(data?.probation_period),
          probation_end_date: data?.probation_end_date,
          weekend: data?.weekend.map((item: any) => item?.value),
          hourly_rate: data?.hourly_salary,
          basic_salary: data.basic_salary,
          salary_type: showSalaryType,
          payment_medium: data?.payment_medium,
          fixed_deduction: data?.fixed_deductions?.map((s: any) => ({
            name: s.deductionName,
            value: s.deductionValue,
          })),
          fixed_allowance: data?.fixed_allowance?.map((s: any) => ({
            name: s.allowanceName,
            value: s.allowanceValue,
          })),
        },
        bank_details: {
          name: data?.account_holder_name,
          account_number: data?.account_number,
          bank_name: data?.bank_name,
          bank_identifier_code: data?.bank_identifier_code,
          branch_localtion: data?.branch_location,
          tax_payer_id: data?.tax_payer_id,
        },
      }
      if (imageFiles) {
        if (getDataUriFileSize(imageFiles?.data_uri, true) >= 2 * 1024 * 1024) {
          setError(EMPLOYEE_PROFILE_PIC, {
            type: 'custom',
            message:
              t('UploadImageValidators.UploadImgSize') ||
              'File size should be less than 2MB',
          })
        } else {
          clearErrors(EMPLOYEE_PROFILE_PIC)
        }
      }
      if (editEmployeeById && editEmployeeById?.user?._id) {
        getDataUriFileSize(imageFiles?.data_uri, true) < 2 * 1024 * 1024 &&
          dispatch(
            editEmployeeInfo(
              requestGenerator({
                id: editEmployeeById?.user?._id,
                isUserDetailsChange: true,
                companyDetailsChange: true,
                bankDetailsChange: true,
                data: {
                  user_id: editEmployeeById?.user?.user_id,
                  name_en: data?.name_en,
                  name_ar: data?.name_ar,
                  gender: data?.gender,
                  nationality: data?.nationality?.value,
                  birth_date: data?.birth_date,
                  civil_id: data?.employee_national_id,
                  address: data?.local_address,
                  phone: data?.employee_phone,
                  branch_id: watchBranchValue?.map((item: any) => item.value),
                  file: imageFiles,
                  company_details: {
                    department_id: data?.department?.map(
                      (item: any) => item?.value
                    ),
                    designation: data?.designation,
                    reporting_to: data?.reporting_to?.value,
                    overtime_type: overTimeTypeValue,
                    shift_type: data?.shift_type?.value,
                    sub_company: data?.sub_company?.value,
                    annual_leave: data?.annual_leaves,
                    joining_date: data?.date_of_joining,
                    probation_period: Number(data?.probation_period),
                    probation_end_date:
                      data?.probation_end_date !== 'Invalid date'
                        ? data?.probation_end_date
                        : undefined,
                    weekend: data?.weekend.map((item: any) => item?.value),
                    hourly_rate: data?.hourly_salary,
                    basic_salary: data.basic_salary,
                    salary_type: showSalaryType,
                    payment_medium: data?.payment_medium,
                    fixed_deduction: data?.fixed_deductions?.map((s: any) => ({
                      name: s.deductionName,
                      value: s.deductionValue,
                    })),
                    fixed_allowance: data?.fixed_allowance?.map((s: any) => ({
                      name: s.allowanceName,
                      value: s.allowanceValue,
                    })),
                  },
                  bank_details: {
                    name: data?.account_holder_name,
                    account_number: data?.account_number,
                    bank_name: data?.bank_name,
                    bank_identifier_code: data?.bank_identifier_code,
                    branch_localtion: data?.branch_location,
                    tax_payer_id: data?.tax_payer_id,
                  },
                },
              })
            )
          ).then((e: any) => {
            if (e.type === `${EDIT_EMPLOYEE_BY_ID}/fulfilled`) {
              navigate('/employees', {
                state: { payloadData: data },
              })
            }
          })
      } else {
        dispatch(
          addEmployee(
            requestGenerator({
              ...payloadData,
            })
          )
        ).then((e: any) => {
          if (e.type === 'employee/addEmployee/fulfilled') {
            navigate('/employees', {
              state: { payloadData: data },
            })
          }
        })
      }
    }
  }

  // Reset Employee Data
  const handleReset = () => {
    reset()
    setValue(EMPLOYEES_ID, '')
    setValue(EMPLOYEE_NATIONAL_ID, '')
    setValue(NAME_AR, '')
    setValue(NAME_EN, '')
    setValue(GENDER, '')
    setValue(LOCAL_ADDRESS, '')
    setValue(DESIGNATION, '')
    setValue(DATE_OF_JOINING, '')
    setValue(PROBATION_END_DATE, '')
    setValue(PAYMENT_MEDIUM, '')
    setValue(PROBATION_PERIOD, '')
    setValue(ANNUAL_LEAVES, '')
    setValue(BASIC_SALARY, '')
    setValue(HOURLY_SALARY, '')
    setValue(ACCOUNT_NUMBER, '')
    setValue(BANK_IDENTIFIER_CODE, '')
    setValue(BANK_NAME, '')
    setValue(BRANCH_LOCATION, '')
    setValue(ACCOUNT_HOLDER_NAME, '')
    setValue(TAX_PAYER_ID, '')
    setValue(PHONE, '')
    setValue(BIRTH_DATE, '')
    setImageFiles({ name: '', data_uri: '' })
    setValue(NATIONALITY, [])
    setValue(DEPARTMENT, [])
    setValue(BRANCH, [])
    setValue(REPORTING_TO, [])
    setValue(OVERTIME_TYPE, '')
    setValue(SUB_COMPANY, [])
    setValue(SHIFT_TYPE, [])
    setValue(WEEKEND, [])
    dispatch(clearEditEmployeeData())
  }

  const fixedAllowanceData = allowanceFields?.filter(
    (s: any) => !s.allowanceName?.length
  )
  const fixedDedeuctionData = deductionFields?.filter(
    (x: any) => !x.deductionName?.length
  )

  const handleChange = (e: any) => {
    if (e?.target?.value == 'true') {
      setOverTimeTypeValue(true)
    } else {
      setOverTimeTypeValue(false)
    }
  }

  return (
    <>
      {isLoading && <Loader />}
      {showSearchModal && (
        <Popup
          Children={EmployeeSearchModal}
          popData={searchModalData}
          handleClose={() => handleSearchModalClose()}
          setModelOpenClose={setShowSearchModal}
          handleSubmit={handleSearchGetPopupData}
        />
      )}
      {isLoading && <Loader />}
      <div className={styles.searchMainContainer}>
        <div className={styles.searchContainer}>
          <input
            type="text"
            placeholder={t('AddEmp.SelectEmp') || 'Select Employee'}
            className={
              editEmployeeById?.user?._id
                ? styles.disableInputSearchContainer
                : styles.inputSearchContainer
            }
            onClick={handleSearchPopup}
            disabled={editEmployeeById?.user?._id ? true : false}
          />
          <SearchIcon
            fillColor={colors?.grey2}
            customClass={styles.searchIconStyle}
          />
        </div>
        {/* Branch */}
        <div className={styles.branchSelectContainer}>
          <label htmlFor="branch" className={styles.branchTitle}>
            {t('Header.Branch')}
          </label>
          <Select
            placeholder={
              <span className="selectPlaceholder">
                {' '}
                {t('ManageStaff.Branch_placeholder')}
              </span>
            }
            {...register(BRANCH, addEmployeeDetailsValidators[BRANCH])}
            isSearchable={true}
            isClearable={false}
            value={watch(BRANCH) || []}
            isMulti
            options={branchData?.map((item: any) => ({
              label: item?.name,
              value: item?._id,
            }))}
            isDisabled={true}
            components={{
              Menu: () => null,
              MenuList: () => null,
              DropdownIndicator: () => null,
              IndicatorSeparator: () => null,
            }}
            styles={branchDropdownStyles}
            className={styles.disableSelectInputField}
            onChange={(e: any) => {
              setValue(BRANCH, e)
              trigger(BRANCH)
            }}
          />
        </div>
      </div>
      <form
        className={styles.addEmployeesMainContainer}
        onSubmit={handleSubmit(onSubmit)}
      >
        <fieldset
          disabled={employeesData?._id || location?.state ? false : true}
          className={styles.formDisableContainer}
        >
          <div className={styles.addEmployeesContainer}>
            {/* Personal Details */}
            <div className={styles.addEmployeeTitle}>
              <p className={styles.addEmployeeTitleText}>
                {t('AddEmp.Personal')}
              </p>
            </div>
            {/* Form */}
            <div className={styles.personalDetailsContainer}>
              <div className={styles.formContainer}>
                {/* Employee ID / Name (EN)*/}
                <div className={styles.formFields}>
                  <div className={styles.inputFields}>
                    <Input
                      type="text"
                      placeholder="XXXXXX"
                      {...register(
                        EMPLOYEES_ID,
                        addEmployeeDetailsValidators[EMPLOYEES_ID]
                      )}
                      disabled={true}
                      onChange={(e: any) => trimValue(e)}
                      htmlFor={EMPLOYEES_ID}
                      customClass={styles.disableInputFields}
                      labelText={t('AddEmp.Employee ID') || 'Employee ID'}
                      // requiredField={true}
                      errorMessage={errors?.[EMPLOYEES_ID]?.message}
                      showErrors={errors?.[EMPLOYEES_ID]}
                    />
                  </div>
                  <div className={styles.inputFields}>
                    <Input
                      type="text"
                      placeholder={t('AddEmp.EnterName') || 'Enter Name (EN)'}
                      {...register(
                        NAME_EN,
                        addEmployeeDetailsValidators[NAME_EN]
                      )}
                      disabled={true}
                      onChange={(e: any) => trimValue(e)}
                      htmlFor={NAME_EN}
                      labelText={t('AddEmp.Name') || 'Name (EN)'}
                      customClass={styles.disableInputFields}
                      requiredField={true}
                      errorMessage={errors?.[NAME_EN]?.message}
                      showErrors={errors?.[NAME_EN]}
                    />
                  </div>
                </div>
                {/* Name (AR) / Date of Birth */}
                <div className={styles.formFields}>
                  <div className={styles.inputFields}>
                    <Input
                      type="text"
                      placeholder={t('AddEmp.EnterNameAr') || 'Enter Name (AR'}
                      {...register(
                        NAME_AR,
                        addEmployeeDetailsValidators[NAME_AR]
                      )}
                      onChange={(e: any) => trimValue(e)}
                      htmlFor={NAME_AR}
                      labelText={t('AddEmp.NameAr') || 'Name (AR)'}
                      requiredField={true}
                      errorMessage={errors?.[NAME_AR]?.message}
                      showErrors={errors?.[NAME_AR]}
                    />
                  </div>
                  <div className={styles.inputFields}>
                    <Input
                      type="date"
                      placeholder={
                        t('PatientEMR.Date Of Birth') || 'Date of Birth'
                      }
                      {...register(
                        BIRTH_DATE,
                        addEmployeeDetailsValidators[BIRTH_DATE]
                      )}
                      onChange={(e: any) => trimValue(e)}
                      htmlFor={BIRTH_DATE}
                      labelText={
                        t('PatientEMR.Date Of Birth') || 'Date of Birth'
                      }
                      requiredField={true}
                      max={new Date()?.toISOString()?.split?.('T')?.[0] ?? ''}
                      errorMessage={errors?.[BIRTH_DATE]?.message}
                      showErrors={errors?.[BIRTH_DATE]}
                    />
                  </div>
                </div>
                {/* Gender / Phone */}
                <div className={styles.formFields}>
                  <div className={styles.inputFields}>
                    <div className={styles.radioFieldGenderContainer}>
                      <label htmlFor={GENDER} className={styles.radioFieldName}>
                        {t('PatientEMR.Gender')}
                        <span className="asterick">*</span>
                      </label>
                      <CustomRadio
                        label={t('PatientEMR.Male') || 'Male'}
                        id="Male"
                        value="MALE"
                        {...register(
                          GENDER,
                          addEmployeeDetailsValidators[GENDER]
                        )}
                      />
                      <CustomRadio
                        label={t('PatientEMR.Female') || 'Female'}
                        id="Female"
                        value="FEMALE"
                        {...register(
                          GENDER,
                          addEmployeeDetailsValidators[GENDER]
                        )}
                      />
                    </div>
                    {errors[GENDER] && (
                      <div className={styles.errorContainer}>
                        <span className={styles.extraRadioSpan}></span>
                        <p className="dashboardFormError">
                          {errors[GENDER].message as any}
                        </p>
                      </div>
                    )}
                  </div>
                  <div className={styles.inputFields}>
                    <div className={styles.inputPhoneContainer}>
                      <label htmlFor={PHONE} className={styles.phoneLabel}>
                        {t('ManageStaff.Phone No')}
                        <span className="asterick">*</span>
                      </label>
                      <PhoneInput
                        country={'kw'}
                        {...register(
                          PHONE,
                          addEmployeeDetailsValidators[PHONE]
                        )}
                        value={getValues(PHONE)}
                        placeholder={t('ManageStaff.Phone No') || 'Phone No'}
                        inputClass={styles.phoneNumberInput}
                        onChange={(phone) => {
                          //  trimValue(e)
                          const formattedPhone = phone && `+${phone}`
                          setValue(PHONE, formattedPhone)
                          trigger(PHONE)
                        }}
                      />
                    </div>
                    {errors[PHONE] && (
                      <div className={styles.errorContainer}>
                        <span className={styles.extraSpan}></span>
                        <p className="dashboardFormError">
                          {errors[PHONE].message as any}
                        </p>
                      </div>
                    )}
                  </div>
                </div>
                {/* </div> {errors[PHONE] && (
                    <div className={styles.errorContainer}>
                      <span className={styles.extraSpan}></span>
                      <p className="dashboardFormError">
                        {errors[PHONE].message as any}
                      </p>
                    </div>
                  )} */}
                {/* Nationality / Civil ID No  */}
                <div className={styles.formFields}>
                  <div className={styles.inputFields}>
                    <div className={styles.inputFieldContainer}>
                      <Label
                        htmlFor={NATIONALITY}
                        labelText={t('PatientEMR.Nationality') || 'Nationality'}
                        requiredField={true}
                        customClass={styles.customLabel}
                      />
                      <Select
                        className={styles.nationalityDropdown}
                        placeholder={
                          <span className="selectPlaceholder">
                            {t('PatientEMRPlaceHolder.SelectNationality')}
                          </span>
                        }
                        value={watch(NATIONALITY || [])}
                        {...register(
                          NATIONALITY,
                          addEmployeeDetailsValidators[NATIONALITY]
                        )}
                        isSearchable={true}
                        isClearable={true}
                        options={nationalityDropdownData}
                        components={{
                          DropdownIndicator: (props: any) => {
                            if (employeesData?._id || location?.state) {
                              return <components.DropdownIndicator {...props} />
                            } else {
                              return null
                            }
                          },
                        }}
                        closeMenuOnSelect={true}
                        maxMenuHeight={120}
                        styles={reactSelectStyles}
                        onChange={(e: any) => {
                          setValue(NATIONALITY, e)
                          trigger(NATIONALITY)
                        }}
                      />
                    </div>
                    {errors[NATIONALITY] && (
                      <div className={styles.errorContainer}>
                        <span className={styles.extraSpan}></span>
                        <p className="dashboardFormError">
                          {errors[NATIONALITY].message as any}
                        </p>
                      </div>
                    )}
                  </div>
                  <div className={styles.inputFields}>
                    <Input
                      type="number"
                      placeholder={t('PatientEMR.Civil ID') || 'Civil ID'}
                      {...register(
                        EMPLOYEE_NATIONAL_ID,
                        addEmployeeDetailsValidators[EMPLOYEE_NATIONAL_ID]
                      )}
                      onChange={(e: any) => trimValue(e)}
                      htmlFor={EMPLOYEE_NATIONAL_ID}
                      labelText={t('PatientEMR.Civil ID') || 'Civil ID'}
                      requiredField={true}
                      errorMessage={errors?.[EMPLOYEE_NATIONAL_ID]?.message}
                      showErrors={errors?.[EMPLOYEE_NATIONAL_ID]}
                    />
                  </div>
                </div>
                {/* Local Address (EN) */}
                <div className={styles.formFields}>
                  <div className={styles.inputFields}>
                    <div
                      className={[
                        styles.inputFieldContainer,
                        styles.textAreaFieldContainer,
                      ].join(' ')}
                    >
                      <label
                        htmlFor={LOCAL_ADDRESS}
                        className={styles.customLabel}
                      >
                        {t('AddEmp.LocalAddress')}
                        <span className="asterick">*</span>
                      </label>
                      <textarea
                        className={styles.textArea}
                        placeholder={
                          t('AddEmp.EnterLocal') || 'Enter Local Address (EN)'
                        }
                        {...register(
                          LOCAL_ADDRESS,
                          addEmployeeDetailsValidators[LOCAL_ADDRESS]
                        )}
                        onChange={(e) => trimValue(e)}
                      />
                    </div>
                    {errors[LOCAL_ADDRESS] && (
                      <div className={styles.errorContainer}>
                        <span className={styles.extraTextSpan}></span>
                        <p className="dashboardFormError">
                          {errors[LOCAL_ADDRESS].message as any}
                        </p>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              {/* Image Container */}
              <aside className={styles.addEmployeeImageContainer}>
                <figure className={styles.userImage}>
                  {showNoImage ? (
                    showNoImage && (
                      <div
                        className={styles.avtarNoImageContainer}
                        onMouseEnter={handleNoImageMouseEnter}
                        onMouseLeave={handleNoImageMouseLeave}
                      >
                        {imageFiles?.data_uri ? (
                          <img
                            src={imageFiles?.data_uri}
                            style={{
                              objectFit: 'cover',
                              width: '100%',
                              borderRadius: '20px',
                              height: '200px',
                            }}
                            alt=""
                          />
                        ) : (
                          <img
                            src={NoSelectImage}
                            className={styles.avtarImageFitStyle}
                            alt="employee avtar"
                          />
                        )}
                        {showWebCamComponent && (
                          <div className={styles.avtarChangeImageStyle}>
                            <div
                              className={styles.avtarChangeImageStyleContainer}
                            >
                              <p className={styles.avtarImageTextStyle}>
                                {t('PatientEMR.Recommended Image Text')}
                              </p>
                              <div
                                className={styles.avtarChangeImageBtnContainer}
                              >
                                <AttachSingleFileV3
                                  id={EMPLOYEE_PROFILE_PIC}
                                  isName={true}
                                  register={register}
                                  fileKey={EMPLOYEE_PROFILE_PIC}
                                  fileList={imageFiles}
                                />
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    )
                  ) : (
                    <div
                      className={styles.avtarImageStyle}
                      onMouseEnter={handleImageMouseEnter}
                      onMouseLeave={handleImageMouseLeave}
                    >
                      {imageFiles?.data_uri ? (
                        <img
                          src={imageFiles?.data_uri}
                          style={{
                            objectFit: 'cover',
                            width: '100%',
                            borderRadius: '20px',
                            height: '200px',
                          }}
                          alt=""
                        />
                      ) : (
                        <img
                          src={SelectImage}
                          className={styles.avtarImageFitStyle}
                          alt="employee Avtar"
                          width={350}
                          height={196}
                        />
                      )}
                      {showAnotherComponent && (
                        <div className={styles.avtarChangeImageStyle}>
                          <AttachSingleFileV2
                            id={EMPLOYEE_PROFILE_PIC}
                            isName={true}
                            register={register}
                            fileKey={EMPLOYEE_PROFILE_PIC}
                            fileList={imageFiles}
                            validation={
                              addEmployeeDetailsValidators[EMPLOYEE_PROFILE_PIC]
                            }
                            changeBtnCustomClass={
                              imageFiles?.data_uri
                                ? styles.changeBtnCustomClassV1
                                : styles.changeBtnCustomClass
                            }
                          />
                          <div className={styles.avtarChangeBtnContainer}>
                            <Button
                              title={
                                t('Receptionist Dashboard.Delete') || 'Delete'
                              }
                              type="button"
                              customClass={styles.deleteButtonStyle}
                              handleClick={handleDeleteImage}
                            />
                          </div>
                        </div>
                      )}
                    </div>
                  )}
                </figure>
              </aside>
            </div>
          </div>
          {/* Company Details */}
          <div className={styles.companyDetailsMainContainer}>
            <div className={styles.companyDetailsTitle}>
              <p className={styles.companyDetailsTitleText}>
                {t('AddEmp.Company Details')}
              </p>
            </div>
            {/*Company Details  Form */}
            <section className={styles.companyDetailsFormContainer}>
              <div className={styles.formContainer}>
                <div className={styles.formFields}>
                  {/* Department */}
                  <div className={styles.inputFields}>
                    <div className={styles.inputFieldContainer}>
                      <Label
                        htmlFor={DEPARTMENT}
                        labelText={t('IPDBooking.Department') || 'Department'}
                        requiredField={true}
                        customClass={styles.customLabel}
                      />
                      <Select
                        className={styles.selectInputField}
                        {...register(
                          DEPARTMENT,
                          addEmployeeDetailsValidators[DEPARTMENT]
                        )}
                        isSearchable={true}
                        isClearable={true}
                        isMulti
                        value={watch(DEPARTMENT || [])}
                        options={departmentData?.map((item: any) => ({
                          label: item?.name,
                          value: item?._id,
                        }))}
                        components={{
                          DropdownIndicator: (props: any) => {
                            if (employeesData?._id || location?.state) {
                              return <components.DropdownIndicator {...props} />
                            } else {
                              return null
                            }
                          },
                        }}
                        closeMenuOnSelect={false}
                        placeholder={
                          <span className="selectPlaceholder">
                            {t('IPDBooking.SelectDepartment')}
                          </span>
                        }
                        maxMenuHeight={120}
                        styles={reactSelectStyles}
                        onChange={(e: any) => {
                          setValue(DEPARTMENT, e)
                          trigger(DEPARTMENT)
                        }}
                      />
                    </div>
                    {errors[DEPARTMENT] && (
                      <div className={styles.errorContainer}>
                        <span className={styles.extraSpan}></span>
                        <p className="dashboardFormError">
                          {errors[DEPARTMENT].message as any}
                        </p>
                      </div>
                    )}
                  </div>
                  {/* Designation */}
                  <div className={styles.inputFields}>
                    <Input
                      type="text"
                      placeholder={
                        t('ManageStaff.Designation_Placeholder') ||
                        'Enter Designation'
                      }
                      {...register(
                        DESIGNATION,
                        addEmployeeDetailsValidators[DESIGNATION]
                      )}
                      onChange={(e: any) => trimValue(e)}
                      htmlFor={DESIGNATION}
                      labelText={t('ManageStaff.Designation') || 'Designation'}
                      requiredField={true}
                      errorMessage={errors?.[DESIGNATION]?.message}
                      showErrors={errors?.[DESIGNATION]}
                    />
                  </div>
                  {/* Reporting to */}
                  <div className={styles.inputFields}>
                    <div className={styles.inputFieldContainer}>
                      <Label
                        htmlFor={REPORTING_TO}
                        labelText={t('AddEmp.Reporting To') || 'Reporting To'}
                        requiredField={true}
                        customClass={styles.customLabel}
                      />
                      <Select
                        className={styles.selectInputField}
                        placeholder={
                          <span className="selectPlaceholder">
                            {t('AddEmp.SelectReporting')}
                          </span>
                        }
                        {...register(
                          REPORTING_TO,
                          addEmployeeDetailsValidators[REPORTING_TO]
                        )}
                        isSearchable={true}
                        isClearable={true}
                        options={reportingUserListData.map((item: any) => ({
                          label: item.name,
                          value: item._id,
                        }))}
                        value={watch(REPORTING_TO || [])}
                        components={{
                          DropdownIndicator: (props: any) => {
                            if (employeesData?._id || location?.state) {
                              return <components.DropdownIndicator {...props} />
                            } else {
                              return null
                            }
                          },
                        }}
                        closeMenuOnSelect={true}
                        maxMenuHeight={130}
                        styles={reactSelectStyles}
                        onChange={(e: any) => {
                          setValue(REPORTING_TO, {
                            label: e.label,
                            value: e.value,
                          })
                          trigger(REPORTING_TO)
                        }}
                      />
                    </div>
                    {errors[REPORTING_TO] && (
                      <div className={styles.errorContainer}>
                        <span className={styles.extraSpan}></span>
                        <p className="dashboardFormError">
                          {errors[REPORTING_TO].message as any}
                        </p>
                      </div>
                    )}
                  </div>
                </div>
                <div className={styles.formFields}>
                  {/* Overtime Type */}
                  <div className={styles.inputFields}>
                    <div className={styles.radioFieldContainer}>
                      <label
                        htmlFor={OVERTIME_TYPE}
                        className={styles.radioFieldOverTimeText}
                      >
                        {t('AddEmp.Overtime Type')}
                        <span className="asterick">*</span>
                      </label>
                      <CustomRadio
                        label={t('ServiceTable.True') || 'True'}
                        id="True"
                        name="overTime"
                        value={'true'}
                        // {...register(
                        //   OVERTIME_TYPE,
                        //   addEmployeeDetailsValidators[OVERTIME_TYPE]
                        // )}
                        checked={overTimeTypeValue == true}
                        onChange={(e) => handleChange(e)}
                      />
                      <CustomRadio
                        label={t('ServiceTable.False') || 'False'}
                        id="False"
                        name="overTime"
                        value={'false'}
                        // {...register(
                        //   OVERTIME_TYPE,
                        //   addEmployeeDetailsValidators[OVERTIME_TYPE]
                        // )}
                        checked={overTimeTypeValue == false}
                        onChange={(e) => handleChange(e)}
                      />
                    </div>
                    {/* {errors[OVERTIME_TYPE] && (
                      <div className={styles.errorContainer}>
                        <span className={styles.extraRadioSpan}></span>
                        <p className="dashboardFormError">
                          {errors[OVERTIME_TYPE].message as any}
                        </p>
                      </div>
                    )} */}
                  </div>
                  {/* Shift Type */}
                  <div className={styles.inputFields}>
                    <div className={styles.inputFieldContainer}>
                      <Label
                        htmlFor={SHIFT_TYPE}
                        labelText={t('AddEmp.Shift Type') || 'Shift Type'}
                        // requiredField={true}
                        customClass={styles.customLabel}
                      />
                      <Select
                        className={styles.selectInputField}
                        placeholder={
                          <span className="selectPlaceholder">
                            {t('AddEmp.SelectShift')}
                          </span>
                        }
                        {...register(
                          SHIFT_TYPE,
                          addEmployeeDetailsValidators[SHIFT_TYPE]
                        )}
                        isSearchable={true}
                        isClearable={true}
                        options={shiftTypeDropdownData}
                        value={watch(SHIFT_TYPE || [])}
                        components={{
                          DropdownIndicator: (props: any) => {
                            if (employeesData?._id || location?.state) {
                              return <components.DropdownIndicator {...props} />
                            } else {
                              return null
                            }
                          },
                        }}
                        closeMenuOnSelect={true}
                        maxMenuHeight={130}
                        styles={reactSelectStyles}
                        onChange={(e: any) => {
                          setValue(SHIFT_TYPE, {
                            label: e.label,
                            value: e.value,
                          })
                          trigger(SHIFT_TYPE)
                        }}
                      />
                    </div>
                  </div>

                  <div className={styles.inputFields}>
                    {/* Sub Company */}
                    <div className={styles.inputFieldContainer}>
                      <Label
                        htmlFor={SUB_COMPANY}
                        labelText={t('Employee.SubCompany') || 'Sub Company'}
                        // requiredField={true}
                        customClass={styles.customLabel}
                      />
                      <Select
                        className={styles.selectInputField}
                        placeholder={
                          <span className="selectPlaceholder">
                            {t('Employee.SelectSub')}
                          </span>
                        }
                        {...register(
                          SUB_COMPANY,
                          addEmployeeDetailsValidators[SUB_COMPANY]
                        )}
                        isSearchable={true}
                        isClearable={true}
                        value={watch(SUB_COMPANY || [])}
                        options={subCompanyDropdownData}
                        components={{
                          DropdownIndicator: (props: any) => {
                            if (employeesData?._id || location?.state) {
                              return <components.DropdownIndicator {...props} />
                            } else {
                              return null
                            }
                          },
                        }}
                        closeMenuOnSelect={true}
                        maxMenuHeight={120}
                        styles={reactSelectStyles}
                        onChange={(e: any) => {
                          setValue(SUB_COMPANY, {
                            label: e.label,
                            value: e.value,
                          })
                          trigger(SUB_COMPANY)
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className={styles.formFields}>
                  {/* Annual Leaves */}
                  <div className={styles.inputFields}>
                    <Input
                      type="number"
                      placeholder={
                        t('AddEmp.EnterAnnual') || 'Enter Annual Leaves'
                      }
                      {...register(
                        ANNUAL_LEAVES,
                        addEmployeeDetailsValidators[ANNUAL_LEAVES]
                      )}
                      onChange={(e: any) => trimValue(e)}
                      htmlFor={ANNUAL_LEAVES}
                      labelText={t('AddEmp.Annual Leaves') || 'Annual Leaves'}
                      requiredField={true}
                      errorMessage={errors?.[ANNUAL_LEAVES]?.message}
                      showErrors={errors?.[ANNUAL_LEAVES]}
                    />
                  </div>
                  {/*Joining Date */}
                  <div className={styles.inputFields}>
                    <div className={styles.inputFieldContainer}>
                      <label
                        htmlFor={DATE_OF_JOINING}
                        className={styles.formLabel}
                      >
                        {t('AddEmp.Joining Date')}
                        <span className="asterick">*</span>
                      </label>
                      <input
                        type="date"
                        max={
                          probationEndDate === undefined ||
                          'Invalid date' ||
                          null
                            ? ''
                            : new Date(probationEndDate)
                                ?.toISOString()
                                ?.split('T')[0] ?? ''
                        }
                        className={styles.inputField}
                        value={watch(DATE_OF_JOINING) || []}
                        {...register(
                          DATE_OF_JOINING,
                          addEmployeeDetailsValidators[DATE_OF_JOINING]
                        )}
                      />
                    </div>
                    {errors[DATE_OF_JOINING] && (
                      <div className={styles.errorContainer}>
                        <span className={styles.extraSpan}></span>
                        <p className="dashboardFormError">
                          {errors[DATE_OF_JOINING].message as any}
                        </p>
                      </div>
                    )}
                  </div>
                  {/* Probation End Date */}
                  <div className={styles.inputFields}>
                    <div className={styles.inputFieldContainer}>
                      <label
                        htmlFor={PROBATION_END_DATE}
                        className={styles.formLabel}
                      >
                        {t('AddEmp.EndDate')}
                      </label>
                      <input
                        type="date"
                        min={
                          joiningDate
                            ? new Date(joiningDate)
                                ?.toISOString()
                                ?.split('T')[0] ?? ''
                            : ''
                        }
                        className={styles.inputField}
                        value={watch(PROBATION_END_DATE) || []}
                        {...register(
                          PROBATION_END_DATE,
                          addEmployeeDetailsValidators[PROBATION_END_DATE]
                        )}
                      />
                    </div>
                  </div>
                </div>
                <div className={styles.formFields}>
                  {/* Probation Period */}
                  <div className={styles.inputFields}>
                    <Input
                      type="text"
                      placeholder={t('AddEmp.days') || '0 days'}
                      {...register(
                        PROBATION_PERIOD,
                        addEmployeeDetailsValidators[PROBATION_PERIOD]
                      )}
                      htmlFor={PROBATION_PERIOD}
                      labelText={t('AddEmp.Probation') || 'Probation Period'}
                      disabled={true}
                    />
                  </div>
                  {/* Weekend */}
                  <div className={styles.inputFields}>
                    <div className={styles.inputFieldContainer}>
                      <Label
                        htmlFor={WEEKEND}
                        labelText={t('AddEmp.Weekend') || 'Weekend'}
                        requiredField={true}
                        customClass={styles.customLabel}
                      />
                      <Select
                        className={styles.selectInputField}
                        {...register(
                          WEEKEND,
                          addEmployeeDetailsValidators[WEEKEND]
                        )}
                        isMulti
                        isSearchable={true}
                        isClearable={true}
                        options={weekendDropdownData}
                        components={{
                          DropdownIndicator: (props: any) => {
                            if (employeesData?._id || location?.state) {
                              return <components.DropdownIndicator {...props} />
                            } else {
                              return null
                            }
                          },
                        }}
                        closeMenuOnSelect={false}
                        placeholder={
                          <span className="selectPlaceholder">
                            {t('AddEmp.SelectWeekend')}
                          </span>
                        }
                        styles={reactSelectStyles}
                        maxMenuHeight={120}
                        value={watch(WEEKEND || [])}
                        onChange={(e: any) => {
                          setValue(WEEKEND, e)
                          trigger(WEEKEND)
                        }}
                      />
                    </div>
                    {errors[WEEKEND] && (
                      <div className={styles.errorContainer}>
                        <span className={styles.extraSpan}></span>
                        <p className="dashboardFormError">
                          {errors[WEEKEND].message as any}
                        </p>
                      </div>
                    )}
                  </div>
                  {/* Payment Medium */}
                  <div className={styles.inputFields}>
                    <Input
                      type="text"
                      placeholder={
                        t('AddEmp.EnterPayment') || 'Enter Payment Medium'
                      }
                      {...register(
                        PAYMENT_MEDIUM,
                        addEmployeeDetailsValidators[PAYMENT_MEDIUM]
                      )}
                      onChange={(e: any) => trimValue(e)}
                      htmlFor={PAYMENT_MEDIUM}
                      labelText={t('AddEmp.Payment Medium') || 'Payment Medium'}
                      requiredField={true}
                      errorMessage={errors?.[PAYMENT_MEDIUM]?.message}
                      showErrors={errors?.[PAYMENT_MEDIUM]}
                    />
                  </div>
                </div>
                <div className={styles.formFields}>
                  <div className={styles.inputFields}>
                    {/* Salary Type */}
                    <div className={styles.radioFieldContainer}>
                      <span className={styles.radioFieldName}>
                        {t('AddEmp.Salary Type')}
                        <span className="asterick">*</span>
                      </span>

                      <CustomRadio
                        id="hourly"
                        value="HOURLY"
                        label={t('AddEmp.Hourly') || 'Hourly'}
                        checked={showSalaryType === 'HOURLY'}
                        {...register(
                          SALARY_TYPE,
                          addEmployeeDetailsValidators[SALARY_TYPE]
                        )}
                        onChange={onSalaryTypeChange}
                      />
                      <CustomRadio
                        id="monthly"
                        value="MONTHLY"
                        label={t('AddEmp.Monthly') || 'Monthly'}
                        checked={showSalaryType === 'MONTHLY'}
                        {...register(
                          SALARY_TYPE,
                          addEmployeeDetailsValidators[SALARY_TYPE]
                        )}
                        onChange={onSalaryTypeChange}
                      />
                    </div>
                  </div>
                  {/* Hourly Rate */}
                  {showSalaryType === 'HOURLY' ? (
                    <>
                      <div className={styles.inputFields}>
                        <Input
                          type="number"
                          placeholder={
                            t('AddEmp.EnterHourly') || 'Enter Hourly Rate'
                          }
                          {...register(
                            HOURLY_SALARY,
                            addEmployeeDetailsValidators[HOURLY_SALARY]
                          )}
                          onChange={(e: any) => trimValue(e)}
                          htmlFor={HOURLY_SALARY}
                          labelText={t('AddEmp.Hourly Rate') || 'Hourly Rate'}
                          requiredField={true}
                          errorMessage={errors?.[HOURLY_SALARY]?.message}
                          showErrors={errors?.[HOURLY_SALARY]}
                        />
                      </div>
                    </>
                  ) : (
                    <>
                      {/* Basic Salary */}
                      <div className={styles.inputFields}>
                        <Input
                          type="number"
                          placeholder={
                            t('AddEmp.EnterBasic') || 'Enter Basic Salary'
                          }
                          {...register(
                            BASIC_SALARY,
                            addEmployeeDetailsValidators[BASIC_SALARY]
                          )}
                          onChange={(e: any) => trimValue(e)}
                          htmlFor={BASIC_SALARY}
                          labelText={t('AddEmp.Basic Salary') || 'Basic Salary'}
                          requiredField={true}
                          errorMessage={errors?.[BASIC_SALARY]?.message}
                          showErrors={errors?.[BASIC_SALARY]}
                        />
                      </div>
                    </>
                  )}
                  <div className={styles.inputFields}></div>
                </div>
              </div>
            </section>
            {/* fixed Allowances Container */}
            <div className={styles.fixedContainer}>
              <div className={styles.fixedFormContainer}>
                <div className={styles.fixedTitle}>
                  <p className={styles.fixedTitleText}>
                    {t('AddEmp.Fixed Allowances')}
                  </p>
                </div>
                <div className={styles.fixedForm}>
                  {/* Food */}
                  {allowanceFields.map((s: any, index: number) => {
                    const loopData = allowanceFields?.map(
                      (s: any) => s?.allowanceName
                    )
                    const filterData = loopData?.filter((x: any) => x?.length)
                    const arr2 = allowanceFields.map((y: any) =>
                      filterData.includes(y.allowanceName)
                    )

                    return (
                      <div key={s?.value} className={styles.dynamicFields}>
                        <select
                          key={s?.value}
                          className={styles.dynamicFieldsSelect}
                          {...register(
                            `fixed_allowance[${index}].allowanceName`
                          )}
                          // defaultValue={s?.allowanceName}
                          onChange={(event) =>
                            handleAllowanceChange(event, index)
                          }
                          value={selectedOptionsAllowance[index]?.allowanceName}
                          disabled={arr2[index]}
                        >
                          {allowanceDropdownData?.map((s, index) => {
                            const arr = allowanceDropdownData?.map((x) =>
                              filterData.includes(x.label)
                            )
                            return (
                              <option
                                key={index}
                                disabled={arr[index]}
                                value={s?.label}
                              >
                                {s.label}
                              </option>
                            )
                          })}
                        </select>
                        {/* <input
                          className={styles.dynamicFieldsInput}
                          type="number"
                          {...register(
                            `fixed_allowance[${index}].allowanceValue`
                          )}
                          onChange={(e: any) => setinputValue(e.target.value)}
                          defaultValue={s?.allowanceValue}
                        /> */}
                        <input
                          key={s?.value}
                          className={styles.dynamicFieldsInput}
                          type="number"
                          {...register(
                            `fixed_allowance[${index}].allowanceValue`
                          )}
                          onChange={(e: any) => setinputValue(e.target.value)}
                          defaultValue={s?.allowanceValue}
                        />
                        <CrossIcon
                          handleClick={() => allowanceRemove(index)}
                          fillColor={colors.white1}
                          fillColor1={colors.red1}
                        />
                      </div>
                    )
                  })}
                  {allowanceDropdownData?.length !==
                    allowanceFields?.length && (
                    <Button
                      title={t('InvoiceService.Add') || 'Add'}
                      type="button"
                      handleClick={() => handleAdd()}
                      disable={
                        inputValue.length || !fixedAllowanceData?.length
                          ? false
                          : true
                      }
                    />
                  )}
                </div>
              </div>
              <div className={styles.fixedSubContainer}>
                <div className={styles.fixedTitle}>
                  <p className={styles.fixedTitleText}>
                    {t('AddEmp.Fixed Deductions')}
                  </p>
                </div>
                <div className={styles.fixedForm}>
                  {/* Food */}
                  {deductionFields?.map((s: any, index: number) => {
                    const loopData = deductionFields?.map(
                      (s: any) => s?.deductionName
                    )
                    const filterData = loopData?.filter((x: any) => x?.length)
                    const arr2 = deductionFields?.map((y: any) =>
                      filterData?.includes(y.deductionName)
                    )
                    return (
                      <div key={s?.value} className={styles.dynamicFields}>
                        <select
                          key={s?.value}
                          className={styles.dynamicFieldsSelect}
                          {...register(
                            `fixed_deductions[${index}].deductionName`
                          )}
                          // defaultValue={s?.deductionName}
                          onChange={(event) =>
                            handleDeductionChange(event, index)
                          }
                          value={
                            selectedOptionsDeduction?.[index]?.deductionName
                          }
                          disabled={arr2[index]}
                        >
                          {deductionDropdownData?.map((s, index) => {
                            const arr = deductionDropdownData?.map((x) =>
                              filterData?.includes(x.label)
                            )
                            return (
                              <option
                                key={index}
                                disabled={arr[index]}
                                value={s?.label}
                              >
                                {s.label}
                              </option>
                            )
                          })}
                        </select>

                        <input
                          key={s?.value}
                          className={styles.dynamicFieldsInput}
                          type="number"
                          {...register(
                            `fixed_deductions[${index}].deductionValue`
                          )}
                          onChange={(e: any) =>
                            setDeductionValue(e.target.value)
                          }
                          defaultValue={s?.deductionValue}
                        />
                        <CrossIcon
                          handleClick={() => deductionRemove(index)}
                          fillColor={colors.white1}
                          fillColor1={colors.red1}
                        />
                      </div>
                    )
                  })}
                  {deductionDropdownData?.length !==
                    deductionFields?.length && (
                    <Button
                      title={t('InvoiceService.Add') || 'Add'}
                      type="button"
                      handleClick={() => handleDeductionAdd()}
                      disable={
                        deductionInputValue?.length ||
                        !fixedDedeuctionData?.length
                          ? false
                          : true
                      }
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
          {/* Bank Details */}
          <div className={styles.bankDetailsContainer}>
            <div className={styles.bankDetailsTitle}>
              <p className={styles.bankDetailsTitleText}>
                {t('AddEmp.Bank Details')}
              </p>
            </div>
            {/* Bank Details Form*/}
            <section className={styles.bankDetailsFormContainer}>
              <div className={styles.formContainer}>
                <div className={styles.formFields}>
                  {/* Account Holder Name */}
                  <div className={styles.inputFields}>
                    <Input
                      type="text"
                      placeholder={
                        t('AddEmp.EnterAccHolder') ||
                        'Enter Account Holder Name'
                      }
                      {...register(
                        ACCOUNT_HOLDER_NAME,
                        addEmployeeDetailsValidators[ACCOUNT_HOLDER_NAME]
                      )}
                      onChange={(e: any) => trimValue(e)}
                      htmlFor={ACCOUNT_HOLDER_NAME}
                      labelText={t('AddEmp.AccHolder') || 'Account Holder Name'}
                      errorMessage={errors?.[ACCOUNT_HOLDER_NAME]?.message}
                      showErrors={errors?.[ACCOUNT_HOLDER_NAME]}
                    />
                  </div>
                  {/* Account Number */}
                  <div className={styles.inputFields}>
                    <Input
                      type="number"
                      placeholder={
                        t('AddEmp.EnterAccNumber') || 'Enter Account Number'
                      }
                      {...register(
                        ACCOUNT_NUMBER,
                        addEmployeeDetailsValidators[ACCOUNT_NUMBER]
                      )}
                      onChange={(e: any) => trimValue(e)}
                      htmlFor={ACCOUNT_NUMBER}
                      labelText={t('AddEmp.Acc No') || 'Account Number'}
                      errorMessage={errors?.[ACCOUNT_NUMBER]?.message}
                      showErrors={errors?.[ACCOUNT_NUMBER]}
                    />
                  </div>
                  {/* Bank Name */}
                  <div className={styles.inputFields}>
                    <Input
                      type="text"
                      placeholder={t('AddEmp.EnterBank') || 'Enter Bank Name'}
                      {...register(
                        BANK_NAME,
                        addEmployeeDetailsValidators[BANK_NAME]
                      )}
                      onChange={(e: any) => trimValue(e)}
                      htmlFor={BANK_NAME}
                      labelText={t('AddEmp.Bank Name') || 'Bank Name'}
                      errorMessage={errors?.[BANK_NAME]?.message}
                      showErrors={errors?.[BANK_NAME]}
                    />
                  </div>
                </div>
                <div className={styles.formFields}>
                  {/* Bank Transfer Code */}
                  <div className={styles.inputFields}>
                    <Input
                      type="text"
                      placeholder={
                        t('AddEmp.EnterTransfer') || 'Enter Bank Transfer Code'
                      }
                      {...register(
                        BANK_IDENTIFIER_CODE,
                        addEmployeeDetailsValidators[BANK_IDENTIFIER_CODE]
                      )}
                      onChange={(e: any) => trimValue(e)}
                      htmlFor={BANK_IDENTIFIER_CODE}
                      labelText={
                        t('AddEmp.TransferCode') || 'Bank Transfer Code'
                      }
                    />
                  </div>
                  {/* Branch Location */}
                  <div className={styles.inputFields}>
                    <Input
                      type="text"
                      placeholder={
                        t('AddEmp.EnterLocation') || 'Enter Branch Location'
                      }
                      {...register(
                        BRANCH_LOCATION,
                        addEmployeeDetailsValidators[BRANCH_LOCATION]
                      )}
                      onChange={(e: any) => trimValue(e)}
                      htmlFor={BRANCH_LOCATION}
                      labelText={t('AddEmp.Location') || 'Branch Location'}
                    />
                  </div>
                  {/* Tax Payer Id */}
                  <div className={styles.inputFields}>
                    <Input
                      type="text"
                      placeholder={t('AddEmp.EnterTax') || 'Enter Tax Payer Id'}
                      {...register(
                        TAX_PAYER_ID,
                        addEmployeeDetailsValidators[TAX_PAYER_ID]
                      )}
                      onChange={(e: any) => trimValue(e)}
                      htmlFor={TAX_PAYER_ID}
                      labelText={t('AddEmp.Tax') || 'Tax Payer Id'}
                    />
                  </div>
                </div>
              </div>
            </section>
            {/* Button Container */}
            <div className={styles.addEmployeeBtnContainer}>
              <Button
                title={
                  editEmployeeById?.hasOwnProperty('user')
                    ? t('ManageCategory.Update') || 'Update'
                    : t('Common.Submit') || 'Submit'
                }
                type="submit"
                handleClick={() => showValidationErrors()}
                customClass={styles.submitBtn}
                disable={employeesData?._id || location?.state ? false : true}
              />
              <Button
                title={t('BranchSetup.Reset') || 'Reset'}
                type="reset"
                handleClick={() => handleReset()}
                disable={employeesData?._id ? false : true}
                customClass={styles.backBtn}
              />
              <Button
                title={t('Common.Back') || 'Back'}
                type="button"
                handleClick={() => handleBack()}
                customClass={styles.backBtn}
              />
            </div>
          </div>
        </fieldset>
      </form>
    </>
  )
}
export default AddEmployee
