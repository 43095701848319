import { useEffect, useState } from 'react'
import styles from './inventoryItemMasterTable.module.scss'
import { trimValue } from '../../utils/utils'
import { SearchButton } from '../../components/common/svg-components'
import Pagination from '../../components/common/pagination/Pagination'
import Popup from '../../components/common/popup/Popup'
import StatusConfirmationPopup from '../../components/common/modal/status-confirmation-popup/StatusConfirmationPopup'
import Loader from '../../components/common/spinner/Loader'
import { useNavigate } from 'react-router'
import { useAppDispatch, useAppSelector } from '../../hooks'
import Button from '../../components/common/button/Button'
import TableV2 from '../../components/common/table/tableV2/TableV2'
import { inventoryItemTableHeaderData } from '../../constants/table-data/inventoryItemTableData'
import { requestGenerator } from '../../utils/payloadGenerator'
import {
  getInventoryItemList,
  updateStatusInventoryItem,
} from '../../redux/features/inventory-item/inventoryItemAsyncActions'
import { CustomRadio } from '../../components/common/custom-radio'
import { t } from 'i18next'

const InventoryItemMasterTable = () => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const { isLoading, inventoryItemList } = useAppSelector(
    (state) => state.inventoryItem
  )
  const [dataPerPage, setDataPerPage] = useState<number>(10)
  const [totalPage, setTotalPage] = useState<number>(0)
  const [pageIndex, setPageIndex] = useState<number>(1)
  const [totalData, setTotalData] = useState(0)
  const [toggleData, setToggleData] = useState({})
  const [confirm, setConfirm] = useState<boolean>(false)
  const [searchValue, setSearchValue] = useState('')
  const [itemType, setItemType] = useState('GENERAL')

  //function for creating Page Index Array
  const pageIndexArray = () => {
    let pageIndexOptions = []
    for (let i = 1; i <= totalPage; i++) {
      pageIndexOptions.push(i)
    }
    return pageIndexOptions
  }
  const pageIndexOptions = pageIndexArray()

  //  getInventoryItemList Api
  useEffect(() => {
    let payloadData = {
      page: pageIndex,
      pageSize: dataPerPage,
      name: searchValue,
      is_active: '',
      type: itemType,
    }
    dispatch(getInventoryItemList(requestGenerator(payloadData))).then(
      (result: any) => {
        setTotalPage(result.payload.lastPage)
        setTotalData(result.payload.total)
      }
    )
  }, [dispatch, dataPerPage, pageIndex])

  useEffect(() => {
    let payloadData = {
      page: 1,
      pageSize: 10,
      name: searchValue,
      is_active: '',
      type: itemType,
    }
    dispatch(getInventoryItemList(requestGenerator(payloadData))).then(
      (result: any) => {
        setPageIndex(result.payload.currentPage)
        setTotalPage(result.payload.lastPage)
        setTotalData(result.payload.total)
      }
    )
  }, [itemType])

  // search functionality

  const handleInputSearch = () => {
    setPageIndex(1)
    const requestData = {
      page: pageIndex,
      pageSize: dataPerPage,
      name: searchValue,
      is_active: '',
      type: itemType,
    }
    dispatch(getInventoryItemList(requestGenerator(requestData))).then(
      (result: any) => {
        setTotalPage(result?.payload?.lastPage)
        setTotalData(result.payload.total)
      }
    )
  }

  useEffect(() => {
    setItemType('GENERAL')
  }, [])

  const handleStatus = (item: any) => {
    const payload = {
      id: item._id,
      is_active: !item.is_active,
    }
    dispatch(updateStatusInventoryItem(requestGenerator(payload))).then((e) => {
      if (e?.type === 'inventoryItem/updateStatusInventoryItem/fulfilled') {
        setPageIndex(1)
        setDataPerPage(10)
        let data = {
          search: '',
          is_active: '',
          page: 1,
          pageSize: 10,
        }
        dispatch(getInventoryItemList(requestGenerator(data))).then(
          (result) => {
            setTotalPage(result.payload.lastPage)
            setTotalData(result.payload.total)
          }
        )
      }
    })
  }

  return (
    <>
      {isLoading && <Loader />}

      {confirm && (
        <Popup
          popData={toggleData}
          Children={StatusConfirmationPopup}
          handleClose={() => setConfirm(false)}
        />
      )}

      <div className={styles.mainContainer}>
        <div className={styles.searchContainer}>
          <div className={styles.inputFieldContainer}>
            <input
              type="text"
              className={styles.inputSearchContainer}
              placeholder={
                t('InventoryItemTable.Search') || 'Search by item name'
              }
              value={searchValue}
              onKeyDown={(e: any) => {
                if (e?.key === 'Enter') {
                  handleInputSearch()
                }
              }}
              onChange={(e) => {
                trimValue(e)
                setSearchValue(e.target.value)
                if (searchValue !== '' && e.target.value === '') {
                  setPageIndex(1)
                  setDataPerPage(10)
                  let data = {
                    search: '',
                    is_active: '',
                    page: 1,
                    pageSize: 10,
                    type: itemType,
                  }
                  dispatch(getInventoryItemList(requestGenerator(data))).then(
                    (result) => {
                      setTotalPage(result.payload.lastPage)
                      setTotalData(result.payload.total)
                    }
                  )
                }
              }}
            />

            <SearchButton
              handleClick={() => {
                if (!!searchValue) {
                  handleInputSearch()
                }
              }}
              customClass={styles.inputSearchButton}
            />
            <div className={styles.radioBtns}>
              <CustomRadio
                value="GENERAL"
                label={t('InventoryItemTable.GENERAL') || 'GENERAL'}
                checked={itemType === 'GENERAL'}
                name="type"
                onClick={() => setItemType('GENERAL')}
              />
              <CustomRadio
                value="MEDICATION"
                label={t('InventoryItemTable.MEDICINES') || 'MEDICINES'}
                name="type"
                checked={itemType === 'MEDICINES'}
                onClick={() => setItemType('MEDICINES')}
              />
            </div>
          </div>
          <div>
            <Button
              title={t('Header Title.Add New Item') || 'Add New Item'}
              handleClick={() => navigate('/inventoryitemtable/addnewitem')}
            />
          </div>
        </div>

        <div className={styles.tableContainer}>
          <TableV2
            tableHeaderData={inventoryItemTableHeaderData}
            tableRowData={inventoryItemList}
            active={false}
            handleRowClick={handleStatus}
            setModelOpenClose={() => {}}
          />
        </div>

        {totalData > 10 && (
          <Pagination
            setDataPerPage={setDataPerPage}
            pageIndexOptions={pageIndexOptions}
            pageIndex={pageIndex}
            setPageIndex={setPageIndex}
            pageSize={dataPerPage}
          />
        )}
      </div>
    </>
  )
}

export default InventoryItemMasterTable
