// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.labServiceTableData_inputContainer__IpBTy {
  width: 75px;
  height: 29px;
  border-radius: 6px;
  border: 1px solid #CDD4D8;
  text-align: center;
}

.labServiceTableData_billableContainer__Umq9C {
  margin-right: 15px;
}`, "",{"version":3,"sources":["webpack://./src/constants/table-data/labServiceTableData.module.scss"],"names":[],"mappings":"AAAA;EACI,WAAA;EACA,YAAA;EACA,kBAAA;EACA,yBAAA;EAEA,kBAAA;AAAJ;;AAGA;EACI,kBAAA;AAAJ","sourcesContent":[".inputContainer {\n    width: 75px;\n    height: 29px;\n    border-radius: 6px;\n    border: 1px solid #CDD4D8;\n    // padding-left: 12px;  \n    text-align: center;\n}\n\n.billableContainer {\n    margin-right: 15px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"inputContainer": `labServiceTableData_inputContainer__IpBTy`,
	"billableContainer": `labServiceTableData_billableContainer__Umq9C`
};
export default ___CSS_LOADER_EXPORT___;
