

import {
  GET_ALL_BALANCE_SHEET,
} from "../../../constants/asyncActionsType";
import createAsyncThunkForSlice from "../../../utils/utils";
import { getAllBalanceSheetList,  } from "./balanceSheetCrud";

export const getAllBalanceSheet = createAsyncThunkForSlice(
  GET_ALL_BALANCE_SHEET,
  getAllBalanceSheetList,
  {
    isToast: false,
  }
);


