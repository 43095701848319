export const requestDropdownData: any = [
  {
    label: 'Department',
    value: 'DEPARTMENT',
  },
  {
    label: 'Room',
    value: 'ROOM',
  },
  {
    label: 'Individual',
    value: 'INDIVIDUAL',
  },
  {
    label: 'Branch store',
    value: 'BRANCH_STORE',
  },
]

export const roomsDropDownData = [
  {
    label: '101',
    value: '101',
  },
  {
    label: '102',
    value: '102',
  },
  {
    label: '103',
    value: '103',
  },
]

export const branchStoreDropdownData: any = [
  {
    label: 'Gotri',
    value: '0',
  },
  {
    label: 'Alkapuri',
    value: '1',
  },
  {
    label: 'Karelibaug',
    value: '2',
  },
]
