import { CheckIcon, UncheckIcon } from '../../components/common/svg-components'
import { colors } from '../color'
import moment from 'moment'
import { useAppDispatch, useAppSelector } from '../../hooks'
import {
  getDirectPoData,
  removeDirectPoData,
} from '../../redux/features/inventory-request/inventoryRequestSlice'
import { t } from 'i18next'

export const directPoPoupHeaderData: any = [
  {
    Header: t('RcptTableHeader.DATE'),
    accessor: (row: any) => {
      return moment(row?.createdAt).format('DD-MMM-YYYY') ?? '-'
    },
    disableSortBy: true,
  },
  {
    Header: t('ViewInventory.ITEM NAME'),
    accessor: 'name',
  },
  {
    Header: t('INVTableHeader.AvailStockQTY'),
    accessor: (row: any) => {
      return row?.store_item_detail?.qty ?? 0
    },
  },
  {
    Header: t('MainStore.BASE UNIT TYPE'),
    accessor: 'base_unit_type.value',
  },
  {
    Header: t('InsurancePlan_Form.PRICE'),
    disableSortBy: true,
    accessor: 'sell_price',
  },
  {
    Header: t('MainStore.RAISE PO'),
    disableSortBy: true,
    Cell: (props: any) => {
      const { directPoStatus } = useAppSelector(
        (state) => state.inventoryRequest
      )
      const dispatch = useAppDispatch()
      const loadIssueData = () => {
        dispatch(getDirectPoData(props.row.original))
      }

      // const addIssueId = () => {
      //   dispatch(setDirectPoStatus(props?.row?.original?._id));
      // };

      const removeIssueData = () => {
        dispatch(removeDirectPoData(props.row.original._id))
      }
      return (
        // <div onClick={addIssueId}>
        //   {directPoStatus.includes(props.row.original._id) ? (
        //     <CheckIcon
        //       fillColor={colors.green1}
        //       handleClick={() => removeIssueData()}
        //     />
        //   ) : (
        //     <UncheckIcon
        //       fillColor={colors.grey1}
        //       handleClick={() => loadIssueData()}
        //     />
        //   )}
        // </div>
        <div>
          {directPoStatus?.some(
            (item: any) => item._id === props.row.original._id
          ) ? (
            <CheckIcon
              fillColor={colors.green1}
              handleClick={() => removeIssueData()}
            />
          ) : (
            <UncheckIcon
              fillColor={colors.grey1}
              handleClick={() => loadIssueData()}
            />
          )}
        </div>
      )
    },
  },
]
