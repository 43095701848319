import axios from "axios";
import {
  CRM_ADMIN_CRITERIA,
  CRM_ADMIN_SEARCH,
  CRM_ADMIN_SENT,
} from "../../../config/config";
import { IAPIPayload } from "../../../interfaces/apiInterface";

export const crmAdminSearch = (data: IAPIPayload) => {
  return axios.post(CRM_ADMIN_SEARCH, data);
};

export const crmAdminCriteria = (data: IAPIPayload) => {
  return axios.post(CRM_ADMIN_CRITERIA, data);
};

export const crmAdminSent = (data: IAPIPayload) => {
  return axios.post(CRM_ADMIN_SENT, data);
};


