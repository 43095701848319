import styles from './issuemainstoretabledata.module.scss'
import moment from 'moment'
import { useAppDispatch, useAppSelector } from '../../../hooks'
import { updateInventoryIssueData } from '../../../redux/features/inventory-request/inventoryRequestSlice'
import { blockInvalidCharacter, disableArrowKey } from '../../../utils/utils'
import { t } from 'i18next'

export const issueMainStoreHeaderData: any = [
  {
    Header: t('RcptTableHeader.DATE'),
    accessor: (row: any) => {
      return moment(row?.date).format('DD-MMM-YYYY') ?? '-'
    },
  },
  {
    Header: t('INVTableHeader.REQ_TYPE'),
    accessor: 'tag',
  },
  {
    Header: t('INVTableHeader.REQID'),
    accessor: 'req_no',
  },
  {
    Header: t('INVRequest.BRANCH_STORE'),
    accessor: 'request_source',
  },
  {
    Header: t('ViewInventory.ITEM NAME'),
    accessor: 'item_name',
  },
  {
    Header: t('INVTableHeader.ReqStockQTY'),
    accessor: 'pending_qty',
  },
  {
    Header: t('INVTableHeader.AvailStockQTY'),
    accessor: 'available_qty',
  },
  {
    Header: t('INVTableHeader.IssueQTY'),
    Cell: (props: any) => {
      const { inventoryIssueData } = useAppSelector(
        (state) => state.inventoryRequest
      )
      const createUpdateDataPayload = (data: any, quantity: any) => {
        let tempArr = data?.map((item: any) => {
          if (item._id === props.row.original._id) {
            return {
              ...item,
              issueQuantity: quantity,
            }
          } else {
            return item
          }
        })
        return tempArr
      }
      const dispatch = useAppDispatch()
      const updateQuantity = (event: any) => {
        const quantity = event?.target.value
        dispatch(
          updateInventoryIssueData(
            createUpdateDataPayload(inventoryIssueData, quantity)
          )
        )
      }

      return (
        <input
          type="number"
          className={styles.inputField}
          value={props?.row?.original?.issueQuantity}
          key={props?.row?.original?._id}
          onChange={updateQuantity}
          onKeyDown={(e: any) => {
            disableArrowKey(e)
            blockInvalidCharacter(e)
          }}
          onWheel={(e: any) => {
            e.target.blur()
          }}
        />
      )
    },
  },

  {
    Header: t('MainStore.POST ISSUE BAL'),
    Cell: (props: any) => {
      const isNanQuantity = Number(props.row.original.issueQuantity)
      const isAddFunc = () => {
        if (isNaN(isNanQuantity)) {
          return props.row.original.available_qty
        } else {
          return props.row.original.available_qty - isNanQuantity
        }
      }

      return <>{isAddFunc()}</>
    },
  },
  {
    Header: t('MainStore.BASE UNIT TYPE'),
    accessor: 'base_unit_type.value',
  },
  {
    Header: t('InventoryItemTable.UNIT_TYPE'),
    accessor: 'req_unit_type.value',
  },
  {
    Header: t('INVTableHeader.QTYCLOSURE'),
    Cell: (props: any) => {
      const isNanQuantity = Number(props.row.original.issueQuantity)
      const isAddFunc = () => {
        if (isNaN(isNanQuantity)) {
          return props.row.original.pending_qty
        } else {
          return props.row.original.pending_qty - isNanQuantity
        }
      }

      return <>{isAddFunc()}</>
    },
  },
]
