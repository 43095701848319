// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pharmacyOnhold_onholdMainContainer__8YXr1 {
  background-color: var(--white1);
  box-shadow: 0px 10px 117px 14px rgba(0, 0, 0, 0.05);
  -webkit-backdrop-filter: blur(97px);
  backdrop-filter: blur(97px);
  border-radius: 10px;
  padding: 25px;
  margin: 0px 20px 20px 0px;
  box-shadow: 0px 10px 117px 14px rgba(0, 0, 0, 0.05);
}
.pharmacyOnhold_onholdMainContainer__8YXr1 .pharmacyOnhold_titleText__SD0hm {
  font-size: var(--font-18);
  font-weight: var(--font-semibold);
}
.pharmacyOnhold_onholdMainContainer__8YXr1 .pharmacyOnhold_tableContainer__akFQX {
  margin: 25px 0 50px 0;
}
.pharmacyOnhold_onholdMainContainer__8YXr1 .pharmacyOnhold_btnContainer__NeuQA {
  display: flex;
  justify-content: center;
  margin-bottom: 40px;
}`, "",{"version":3,"sources":["webpack://./src/pages/pharmacy/pharmacy-onhold/pharmacyOnhold.module.scss"],"names":[],"mappings":"AAAA;EACE,+BAAA;EACA,mDAAA;EACA,mCAAA;EACA,2BAAA;EACA,mBAAA;EACA,aAAA;EACA,yBAAA;EACA,mDAAA;AACF;AAAE;EACE,yBAAA;EACA,iCAAA;AAEJ;AAAE;EACE,qBAAA;AAEJ;AAAE;EACE,aAAA;EACA,uBAAA;EACA,mBAAA;AAEJ","sourcesContent":[".onholdMainContainer {\n  background-color: var(--white1);\n  box-shadow: 0px 10px 117px 14px rgba(0, 0, 0, 0.05);\n  -webkit-backdrop-filter: blur(97px);\n  backdrop-filter: blur(97px);\n  border-radius: 10px;\n  padding: 25px;\n  margin: 0px 20px 20px 0px;\n  box-shadow: 0px 10px 117px 14px rgba(0, 0, 0, 0.05);\n  .titleText {\n    font-size: var(--font-18);\n    font-weight: var(--font-semibold);\n  }\n  .tableContainer {\n    margin: 25px 0 50px 0;\n  }\n  .btnContainer {\n    display: flex;\n    justify-content: center;\n    margin-bottom: 40px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"onholdMainContainer": `pharmacyOnhold_onholdMainContainer__8YXr1`,
	"titleText": `pharmacyOnhold_titleText__SD0hm`,
	"tableContainer": `pharmacyOnhold_tableContainer__akFQX`,
	"btnContainer": `pharmacyOnhold_btnContainer__NeuQA`
};
export default ___CSS_LOADER_EXPORT___;
