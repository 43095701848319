import { FC } from 'react';
import styles from './ipdTimelineMainLayout.module.scss';
import Divider from '../../../../../components/common/divider/Divider';

interface IIpdTimelineMainLayout {
  heading?: string;
  childern?: any;
}

const IpdTimelineMainLayout: FC<IIpdTimelineMainLayout> = ({
  heading,
  childern,
}) => {
  return (
    <div className={styles.consultationContainer}>
      <p className={styles.title}>{heading}</p>
      <Divider customClass={styles.dividerStyle} />
      <div className={styles.tableContainer}>{childern}</div>
    </div>
  );
};

export default IpdTimelineMainLayout;
