import {
  ADD_IPD_TIMELINE_MEDICATION,
  ADD_IPD_TIMELINE_NOTES,
  ADD_IPD_TREATMENT,
  CREATE_CASE_IPD,
  DELETE_IPD_TIMELINE_NOTES,
  EDIT_IPD_TIMELINE_NOTES,
  FORM_DISCHARGE,
  GET_ALL_IPD_MEDICATION,
  GET_ALL_IPD_TEST,
  GET_IPD_CASE_BY_ID,
  IPD_CASE_BY_ID,
  IPD_HISTORY,
  IPD_INS_ADD_TYPE,
  UPDATE_CASE_IPD,
} from '../../../constants/asyncActionsType'
import createAsyncThunkForSlice from '../../../utils/utils'
import {
  addInsurancetoCase,
  addIpdTimelineMedications,
  addIpdTimelineNotes,
  addIpdTreatmentPlan,
  createIpdCase,
  deleteIpdTimelineNotes,
  editIpdTimelineNotes,
  getAllIpdMedications,
  getAllIpdTests,
  getIpdBookingCaseById,
  getIpdCase,
  getIpdTreatmentPlan,
  ipdDischargeForm,
  updateIpdCase,
  viewIpdHistory,
} from './ipdCrud'

export const getAllIpdMedication = createAsyncThunkForSlice(
  GET_ALL_IPD_MEDICATION,
  getAllIpdMedications
)

export const addIpdTimelineNote = createAsyncThunkForSlice(
  ADD_IPD_TIMELINE_NOTES,
  addIpdTimelineNotes
  // {
  //   isToast: true,
  // }
)

export const editIpdTimelineNote = createAsyncThunkForSlice(
  EDIT_IPD_TIMELINE_NOTES,
  editIpdTimelineNotes,
  {
    isToast: true,
  }
)

export const deleteIpdTimelineNote = createAsyncThunkForSlice(
  DELETE_IPD_TIMELINE_NOTES,
  deleteIpdTimelineNotes,
  {
    isToast: true,
  }
)

export const getAllIpdTest = createAsyncThunkForSlice(
  GET_ALL_IPD_TEST,
  getAllIpdTests
)

export const ipdCreateCase = createAsyncThunkForSlice(
  CREATE_CASE_IPD,
  createIpdCase,
  {
    isToast: true,
  }
)

export const addIpdTimelineMedication = createAsyncThunkForSlice(
  ADD_IPD_TIMELINE_MEDICATION,
  addIpdTimelineMedications,
  { isToast: true }
)

export const getIpdCaseById = createAsyncThunkForSlice(
  GET_IPD_CASE_BY_ID,
  getIpdCase
)

export const updateCaseIpd = createAsyncThunkForSlice(
  UPDATE_CASE_IPD,
  updateIpdCase,
  { isToast: true }
)

export const addIpdTreatment = createAsyncThunkForSlice(
  ADD_IPD_TREATMENT,
  addIpdTreatmentPlan,
  { isToast: true }
)

export const getIpdTreatment = createAsyncThunkForSlice(
  ADD_IPD_TREATMENT,
  getIpdTreatmentPlan
)

export const ipdFormDischarge = createAsyncThunkForSlice(
  FORM_DISCHARGE,
  ipdDischargeForm,
  { isToast: true }
)

export const applyInsurancetoCase = createAsyncThunkForSlice(
  IPD_INS_ADD_TYPE,
  addInsurancetoCase,
  {
    isToast: true,
  }
)
export const ipdHistory = createAsyncThunkForSlice(IPD_HISTORY, viewIpdHistory)

export const getCaseByIdIpdBooking = createAsyncThunkForSlice(
  IPD_CASE_BY_ID,
  getIpdBookingCaseById
)
