import { FC } from 'react'
import Divider from '../../../components/common/divider/Divider'
import { CloseIcon } from '../../../components/common/svg-components'
import TableV2 from '../../../components/common/table/tableV2/TableV2'
import { colors } from '../../../constants/color'
import styles from './unittypePopup.module.scss'

interface IUnitypePopup {
  handleClose: any
  heading: any
  popData: any[]
  headerData: any
}

const UnitTypePopup: FC<IUnitypePopup> = ({
  handleClose,
  heading,
  popData,
  headerData,
}) => {
  return (
    <div
      className={styles.popupContainer}
      onClick={(e) => {
        e.stopPropagation()
      }}
    >
      <CloseIcon
        customClass={styles.closeIconStyle}
        fillColor={colors.green1}
        handleClick={() => handleClose()}
      />
      <div className={styles.viewAppointment}>
        <p className={styles.title}>{heading}</p>
        <Divider customClass={styles.dividerStyle} />
        <div className={styles.tableBlock}>
          <TableV2 tableHeaderData={headerData} tableRowData={popData} />
        </div>
      </div>
    </div>
  )
}

export default UnitTypePopup
