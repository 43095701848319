import { REPORT_MAP_ROLE, REPORT_MAP_TYPE } from '../constants/constant'

export const reportMapValidators = {
  [REPORT_MAP_ROLE]: {
    required: 'Please select role name',
  },
  [REPORT_MAP_TYPE]: {
    required: 'Please select report type',
  },
}
