// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.manageInventoryUnitType_mainContainer__TOWlO {
  background-color: var(--white1);
  box-shadow: 0px 10px 117px 14px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  padding: 25px;
  margin: 0 20px 50px 0;
}
.manageInventoryUnitType_mainContainer__TOWlO .manageInventoryUnitType_appointmentStatusInputMain__UBe55 {
  display: flex;
}
.manageInventoryUnitType_mainContainer__TOWlO .manageInventoryUnitType_appointmentStatusInputMain__UBe55 .manageInventoryUnitType_appointmanetInput__9kQ9N .manageInventoryUnitType_appointmentLable__z4H0t {
  font-weight: 600;
  font-size: 16px;
}
.manageInventoryUnitType_mainContainer__TOWlO .manageInventoryUnitType_appointmentStatusInputMain__UBe55 .manageInventoryUnitType_appointmanetInput__9kQ9N .manageInventoryUnitType_inputSearchContainer__Y\\+4yG {
  margin-left: 12px;
  width: 234px;
  height: 42px;
  border: none;
  padding: 15px;
  border-radius: 10px;
  background: rgba(255, 255, 255, 0.7);
  box-shadow: 0px 10px 117px 14px rgba(0, 0, 0, 0.05);
  border-radius: 6px;
  border: 1px solid #cdd4d8;
}
.manageInventoryUnitType_mainContainer__TOWlO .manageInventoryUnitType_appointmentStatusInputMain__UBe55 .manageInventoryUnitType_appointmanetInput__9kQ9N .manageInventoryUnitType_inputAttechments__stT\\+S {
  display: flex;
  margin-left: 20px;
  width: 160px;
  align-items: center;
}
.manageInventoryUnitType_mainContainer__TOWlO .manageInventoryUnitType_appointmentStatusInputMain__UBe55 .manageInventoryUnitType_appointmanetInput__9kQ9N .manageInventoryUnitType_appointmentLables__qx\\+Ao {
  font-weight: 600;
  font-size: 16px;
  margin-right: 10px;
}
.manageInventoryUnitType_mainContainer__TOWlO .manageInventoryUnitType_buttonMainColor__C-v-l {
  display: flex;
  width: 20%;
  justify-content: center;
  margin-top: 30px;
  margin-bottom: 30px;
}
.manageInventoryUnitType_mainContainer__TOWlO .manageInventoryUnitType_buttonMainColor__C-v-l .manageInventoryUnitType_addBUtton__QtpDe {
  width: 90px;
  height: 42px;
}

.manageInventoryUnitType_errorContainer__YSba7 {
  padding-left: 98px;
}

.manageInventoryUnitType_errorContainers__LslQV {
  padding-left: 78px;
}`, "",{"version":3,"sources":["webpack://./src/pages/manageMasterTable-New/manageInventoryUnitType/manageInventoryUnitType.module.scss"],"names":[],"mappings":"AACA;EACE,+BAAA;EACA,mDAAA;EACA,mBAAA;EACA,aAAA;EACA,qBAAA;AAAF;AACE;EACE,aAAA;AACJ;AACM;EACE,gBAAA;EACA,eAAA;AACR;AACM;EACE,iBAAA;EACA,YAAA;EACA,YAAA;EACA,YAAA;EACA,aAAA;EACA,mBAAA;EACA,oCAAA;EACA,mDAAA;EACA,kBAAA;EACA,yBAAA;AACR;AACM;EACE,aAAA;EACA,iBAAA;EACA,YAAA;EACA,mBAAA;AACR;AACM;EACE,gBAAA;EACA,eAAA;EACA,kBAAA;AACR;AAGE;EACE,aAAA;EACA,UAAA;EACA,uBAAA;EACA,gBAAA;EACA,mBAAA;AADJ;AAEI;EACE,WAAA;EACA,YAAA;AAAN;;AAIA;EACE,kBAAA;AADF;;AAGA;EACE,kBAAA;AAAF","sourcesContent":["@import \"../../../styles/mixin.scss\";\n.mainContainer {\n  background-color: var(--white1);\n  box-shadow: 0px 10px 117px 14px rgba(0, 0, 0, 0.05);\n  border-radius: 10px;\n  padding: 25px;\n  margin: 0 20px 50px 0;\n  .appointmentStatusInputMain {\n    display: flex;\n    .appointmanetInput {\n      .appointmentLable {\n        font-weight: 600;\n        font-size: 16px;\n      }\n      .inputSearchContainer {\n        margin-left: 12px;\n        width: 234px;\n        height: 42px;\n        border: none;\n        padding: 15px;\n        border-radius: 10px;\n        background: rgba(255, 255, 255, 0.7);\n        box-shadow: 0px 10px 117px 14px rgba(0, 0, 0, 0.05);\n        border-radius: 6px;\n        border: 1px solid #cdd4d8;\n      }\n      .inputAttechments {\n        display: flex;\n        margin-left: 20px;\n        width: 160px;\n        align-items: center;\n      }\n      .appointmentLables {\n        font-weight: 600;\n        font-size: 16px;\n        margin-right: 10px;\n      }\n    }\n  }\n  .buttonMainColor {\n    display: flex;\n    width: 20%;\n    justify-content: center;\n    margin-top: 30px;\n    margin-bottom: 30px;\n    .addBUtton {\n      width: 90px;\n      height: 42px;\n    }\n  }\n}\n.errorContainer {\n  padding-left: 98px;\n}\n.errorContainers {\n  padding-left: 78px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"mainContainer": `manageInventoryUnitType_mainContainer__TOWlO`,
	"appointmentStatusInputMain": `manageInventoryUnitType_appointmentStatusInputMain__UBe55`,
	"appointmanetInput": `manageInventoryUnitType_appointmanetInput__9kQ9N`,
	"appointmentLable": `manageInventoryUnitType_appointmentLable__z4H0t`,
	"inputSearchContainer": `manageInventoryUnitType_inputSearchContainer__Y+4yG`,
	"inputAttechments": `manageInventoryUnitType_inputAttechments__stT+S`,
	"appointmentLables": `manageInventoryUnitType_appointmentLables__qx+Ao`,
	"buttonMainColor": `manageInventoryUnitType_buttonMainColor__C-v-l`,
	"addBUtton": `manageInventoryUnitType_addBUtton__QtpDe`,
	"errorContainer": `manageInventoryUnitType_errorContainer__YSba7`,
	"errorContainers": `manageInventoryUnitType_errorContainers__LslQV`
};
export default ___CSS_LOADER_EXPORT___;
