// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.assignTagModal_mainContainer__IEbuA {
  width: 500px;
  height: 100%;
  background: #ffffff;
  border-radius: 10px;
  padding-bottom: 50px;
  display: flex;
  flex-direction: column;
}
.assignTagModal_mainContainer__IEbuA .assignTagModal_closeIconContainer__seNSK {
  width: 6%;
  height: 30px;
  align-self: end;
  margin: 10px;
}
.assignTagModal_mainContainer__IEbuA .assignTagModal_title__zRYCC {
  text-align: center;
  font-weight: var(--font-semibold);
  font-size: var(--font-24);
  color: var(--black2);
}
.assignTagModal_mainContainer__IEbuA .assignTagModal_dividerStyle__jfyxF {
  margin: 10px auto 30px auto;
  width: 80px;
  height: 3px;
  background: var(--green1);
}
.assignTagModal_mainContainer__IEbuA .assignTagModal_tableContainer__i7Hvp {
  margin: 20px 0px 30px 0px;
  height: 280px;
  overflow-y: scroll;
}
.assignTagModal_mainContainer__IEbuA .assignTagModal_tableContainer__i7Hvp::-webkit-scrollbar {
  display: block;
  width: 7px;
  height: 7px;
}
.assignTagModal_mainContainer__IEbuA .assignTagModal_tableContainer__i7Hvp::-webkit-scrollbar-track {
  background: transparent;
}
.assignTagModal_mainContainer__IEbuA .assignTagModal_tableContainer__i7Hvp::-webkit-scrollbar-thumb {
  background-color: var(--grey7);
  border-radius: 10px;
  border: 1px solid var(--grey7);
}
.assignTagModal_mainContainer__IEbuA .assignTagModal_buttonStyle__QYZzX {
  align-self: center;
}`, "",{"version":3,"sources":["webpack://./src/components/common/modal/assign-tag-modal/assignTagModal.module.scss"],"names":[],"mappings":"AACA;EACE,YAAA;EACA,YAAA;EACA,mBAAA;EACA,mBAAA;EACA,oBAAA;EACA,aAAA;EACA,sBAAA;AAAF;AACE;EACE,SAAA;EACA,YAAA;EACA,eAAA;EACA,YAAA;AACJ;AACE;EACE,kBAAA;EACA,iCAAA;EACA,yBAAA;EACA,oBAAA;AACJ;AACE;EACE,2BAAA;EACA,WAAA;EACA,WAAA;EACA,yBAAA;AACJ;AACE;EACE,yBAAA;EACA,aAAA;EACA,kBAAA;AACJ;AAAI;EACE,cAAA;EACA,UAAA;EACA,WAAA;AAEN;AAAI;EACE,uBAAA;AAEN;AACI;EACE,8BAAA;EACA,mBAAA;EACA,8BAAA;AACN;AAEE;EACE,kBAAA;AAAJ","sourcesContent":["@import \"../../../../styles/mixin.scss\";\n.mainContainer {\n  width: 500px;\n  height: 100%;\n  background: #ffffff;\n  border-radius: 10px;\n  padding-bottom: 50px;\n  display: flex;\n  flex-direction: column;\n  .closeIconContainer {\n    width: 6%;\n    height: 30px;\n    align-self: end;\n    margin: 10px;\n  }\n  .title {\n    text-align: center;\n    font-weight: var(--font-semibold);\n    font-size: var(--font-24);\n    color: var(--black2);\n  }\n  .dividerStyle {\n    margin: 10px auto 30px auto;\n    width: 80px;\n    height: 3px;\n    background: var(--green1);\n  }\n  .tableContainer {\n    margin: 20px 0px 30px 0px;\n    height: 280px;\n    overflow-y: scroll;\n    &::-webkit-scrollbar {\n      display: block;\n      width: 7px;\n      height: 7px;\n    }\n    &::-webkit-scrollbar-track {\n      background: transparent;\n    }\n\n    &::-webkit-scrollbar-thumb {\n      background-color: var(--grey7);\n      border-radius: 10px;\n      border: 1px solid var(--grey7);\n    }\n  }\n  .buttonStyle {\n    align-self: center;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"mainContainer": `assignTagModal_mainContainer__IEbuA`,
	"closeIconContainer": `assignTagModal_closeIconContainer__seNSK`,
	"title": `assignTagModal_title__zRYCC`,
	"dividerStyle": `assignTagModal_dividerStyle__jfyxF`,
	"tableContainer": `assignTagModal_tableContainer__i7Hvp`,
	"buttonStyle": `assignTagModal_buttonStyle__QYZzX`
};
export default ___CSS_LOADER_EXPORT___;
