import { t } from 'i18next'
import {
  IPD_INVOICE_CASE_ID,
  IPD_INVOICE_FILE_NUMBER,
  IPD_INVOICE_PATIENT_NAME,
} from '../constants/constant'

export interface IIpdInvoiceValidators {
  [IPD_INVOICE_CASE_ID]: {
    required: string
  }
  [IPD_INVOICE_PATIENT_NAME]: {
    required: string
  }
  [IPD_INVOICE_FILE_NUMBER]: {
    required: string
  }
}

export const ipdInvoiceValidators: any = {
  [IPD_INVOICE_CASE_ID]: {
    required: t('IPDInvoice.CASEIDVal'),
  },
  [IPD_INVOICE_PATIENT_NAME]: {
    required: t('IPDInvoice.PatientVal'),
  },
  [IPD_INVOICE_FILE_NUMBER]: {
    required: t('IPDInvoice.FileVal'),
  },
}
