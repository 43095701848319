import { t } from 'i18next'
import styles from './taggedPatientPopupData.module.scss'

export const taggedPatientPopupHeaderData: any = [
  {
    Header: t('RcptTableHeader.FILE NO'),
    accessor: 'patient_emr',
    Cell: ({ row, onRowClick }: any) => {
      const objectId = row?.original
      return (
        <p
          className={styles.emrFileNoActive}
          onClick={() => {
            onRowClick(row?.original)
          }}
        >
          {objectId?.patient_emr}
        </p>
      )
    },
  },
  {
    Header: t('PatientHistoryFormHeader.PATIENT NAME'),
    accessor: 'patient_name',
  },
  {
    Header: t('TaggedPatient.LAST APPOINTMENT'),
    accessor: 'appointment_date',
  },

  {
    Header: t('PatientHistoryTableHeader.SYMPTOMS'),
    Cell: (props: any) => {
      return (
        <>
          {props?.row?.original?.appointment_date ? (
            <span
              className={styles.viewPopupLink}
              onClick={() => {
                props?.onClick(props?.row?.original)
              }}
            >
              {t('Common.View')}
            </span>
          ) : (
            '-'
          )}
        </>
      )
    },
  },
]
