import { t } from 'i18next'
import {
  DOCTOR_ID,
  END_DATE,
  NOTES,
  ROOM_ID,
  START_DATE,
} from './unavailabilityConstant'

export const unavailabilityValidators = {
  [DOCTOR_ID]: {
    required: t('RoomBooking.SelectDoctorValidation'),
  },
  [ROOM_ID]: {
    required: t('BedSetup.select_room_validation'),
  },
  [START_DATE]: {
    required: t('BookingConfirmationValidators.RECURRING_START_DATE'),
  },
  [END_DATE]: {
    required: t('Unavailability.SelectEndDate'),
  },
  [NOTES]: {
    required: t('WardSetup.Notes_placeholder'),
  },
}
