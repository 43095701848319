import { useCallback, useEffect, useState } from 'react'
import Pagination from '../../../components/common/pagination/Pagination'
import TableV2 from '../../../components/common/table/tableV2/TableV2'
import styles from './doctorUnavailability.module.scss'
import { Label } from '../../../components/common/label'
import Select from 'react-select'
import { useTranslation } from 'react-i18next'
import { useAppDispatch, useAppSelector } from '../../../hooks'
import {
  createUnavailablity,
  deleteUnavailablity,
  getAllDoctors,
  getDocUnavailability,
  updateUnavailablity,
} from '../../../redux/features/appointments/bookingAppointmentAsyncActions'
import { requestGenerator } from '../../../utils/payloadGenerator'
import {
  ADD_UNAVAILABILITY_TYPE,
  DELETE_UNAVAILABILITY_TYPE,
  GET_ALL_DOCTORS_TYPE,
  GET_ALL_ROOM,
  GET_DOC_UNAVAILABILITY_TYPE,
  UPDATE_UNAVAILABILITY_TYPE,
} from '../../../constants/asyncActionsType'
import makeAnimated from 'react-select/animated'
import { reactSelectStyle, warning } from '../../../constants/data'
import { doctorUnavailabilityData } from '../tableData'
import { SearchButton } from '../../../components/common/svg-components'
import Loader from '../../../components/common/spinner/Loader'
import Button from '../../../components/common/button/Button'
import Popup from '../../../components/common/popup/Popup'
import DoctorPopup from './DoctorPopup'
import {
  DOCTOR_ID,
  END_DATE,
  END_TIME,
  NOTES,
  ROOM_ID,
  START_DATE,
  START_TIME,
  TYPE,
} from '../unavailabilityConstant'
import { IAddDocUnavailablity } from '../../../interfaces/interfaces'
import DeleteMedicationPopup from '../../../components/common/modal/delete-medication-popup/DeleteMedicationPopup'
import NotesPopup from '../../../components/common/modal/notes-popup/NotesPopup'
import { useLocation } from 'react-router-dom'
import { getAllroom } from '../../../redux/features/room/roomAsyncActions'
import { setMessage } from '../../../redux/features/toast/toastSlice'

const DoctorUnavailablity = () => {
  const location = useLocation().pathname
  const isRoom = location === '/unavailability/rooms'
  const [dataPerPage, setDataPerPage] = useState<number>(10)
  const [totalPage, setTotalPage] = useState<number>(0)
  const [pageIndex, setPageIndex] = useState<number>(1)
  const [doctorData, setDoctorData] = useState([])
  const [editData, setEditData] = useState<any>({})
  const [selectedDoctor, setSelectedDoctor] = useState<{
    label: string
    value: string
  }>({ label: 'Select Doctor', value: '' })
  const [dateFilter, setDateFilter] = useState<{ from: string; to: string }>({
    from: '',
    to: '',
  })
  const [addPopup, setAddPopup] = useState<boolean>(false)
  const [deletePopup, setDeletePopup] = useState<boolean>(false)
  const [notePopup, setNotePopup] = useState<boolean>(false)
  const dispatch = useAppDispatch()
  const { loading, docUnavailabilty } = useAppSelector(
    (state) => state.appointments
  )
  const animatedComponent = makeAnimated()

  const pageIndexArray = () => {
    let pageIndexOptions = []
    for (let i = 1; i <= totalPage; i++) {
      pageIndexOptions.push(i)
    }
    return pageIndexOptions
  }
  const pageIndexOptions = pageIndexArray()
  const { t } = useTranslation()
  useEffect(() => {
    let data = {
      page: 1,
      pageSize: 1000,
    }
    if (isRoom) {
      dispatch(getAllroom(requestGenerator(data))).then((e) => {
        if (e.type === `${GET_ALL_ROOM}/fulfilled`) {
          if (e.payload?.data && e.payload?.data?.length > 0) {
            const options = e.payload.data.map((item: any) => {
              return {
                label: item?.room_name,
                value: item?._id,
              }
            })
            setSelectedDoctor(options[0])
            setDoctorData(options)
          }
        }
      })
    } else {
      dispatch(getAllDoctors(requestGenerator(data))).then((e) => {
        if (e.type === `${GET_ALL_DOCTORS_TYPE}/fulfilled`) {
          if (e.payload?.data && e.payload?.data?.length > 0) {
            const options = e.payload.data.map((item: any) => {
              return {
                label: item?.doctor_name,
                value: item?._id,
              }
            })
            setSelectedDoctor(options[0])
            setDoctorData(options)
          }
        }
      })
    }
  }, [dispatch, isRoom])

  const getUnavailability = useCallback(() => {
    const payload = {
      type: isRoom ? 'room' : 'doctor',
      doctor_id: !isRoom ? selectedDoctor.value : undefined,
      room_id: isRoom ? selectedDoctor.value : undefined,
      start_date: dateFilter.from,
      end_date: dateFilter.to,
      page: pageIndex,
      pageSize: dataPerPage,
    }

    if (payload.doctor_id || payload.room_id) {
      dispatch(getDocUnavailability(requestGenerator(payload))).then((e) => {
        if (e.type === `${GET_DOC_UNAVAILABILITY_TYPE}/fulfilled`) {
          setTotalPage(e.payload.lastPage)
        }
      })
    }
  }, [dataPerPage, pageIndex, selectedDoctor, dispatch, isRoom])

  useEffect(() => {
    getUnavailability()
  }, [getUnavailability])

  const edit = (item: any) => {
    setEditData(item)
    if (item?.type === 'edit') {
      setAddPopup(true)
    } else {
      setDeletePopup(item)
    }
  }

  const handleAdd = (data: any) => {
    const payload = {
      [TYPE]: isRoom ? 'room' : 'doctor',
      [DOCTOR_ID]: !isRoom ? data[DOCTOR_ID]?.value : undefined,
      [ROOM_ID]: isRoom ? data[ROOM_ID]?.value : undefined,
      [START_DATE]: data[START_DATE],
      [END_DATE]: data[END_DATE],
      [START_TIME]: data[START_TIME],
      [END_TIME]: data[END_TIME],
      [NOTES]: data[NOTES],
    }
    const startTime = data[START_TIME]
    const endTime = data[END_TIME]
    if (startTime && endTime && endTime <= startTime) {
      dispatch(
        setMessage({
          message: t('upay.EndTimeVal'),
          type: warning,
        })
      )
    } else if (data?.isEdit) {
      const editPayload = {
        _id: data?._id,
        data: payload,
      }
      dispatch(updateUnavailablity(requestGenerator(editPayload))).then((e) => {
        if (e.type === `${UPDATE_UNAVAILABILITY_TYPE}/fulfilled`) {
          setAddPopup(false)
          setEditData({})
          getUnavailability()
        }
      })
    } else {
      dispatch(createUnavailablity(requestGenerator(payload))).then((e) => {
        if (e.type === `${ADD_UNAVAILABILITY_TYPE}/fulfilled`) {
          setAddPopup(false)
          getUnavailability()
        }
      })
    }
  }
  const deleteUnavailability = () => {
    dispatch(
      deleteUnavailablity(requestGenerator({ _id: editData?._id }))
    ).then((e) => {
      if (e.type === `${DELETE_UNAVAILABILITY_TYPE}/fulfilled`) {
        getUnavailability()
        setEditData({})
        setDeletePopup(false)
      }
    })
  }
  return (
    <>
      {addPopup && (
        <Popup
          Children={DoctorPopup}
          handleClose={() => setAddPopup(false)}
          handleYes={(data: IAddDocUnavailablity) => {
            handleAdd(data)
          }}
          headerData={doctorData}
          popData={editData}
          isDefault={isRoom}
        />
      )}
      {deletePopup && (
        <Popup
          Children={DeleteMedicationPopup}
          handleYes={deleteUnavailability}
          handleClose={() => {
            setDeletePopup(false)
            setEditData({})
          }}
          handleNo={() => {
            setDeletePopup(false)
            setEditData({})
          }}
        />
      )}
      {notePopup && (
        <Popup
          Children={NotesPopup}
          popData={editData}
          handleClose={() => {
            setNotePopup(false)
            setEditData({})
          }}
        />
      )}
      {loading ? <Loader /> : ''}
      <div className={styles.mainContainer}>
        <div className={styles.searchFilterContainer}>
          <div className={styles.formWrapper}>
            <div className={styles.inputWrapper}>
              <Label
                htmlFor=""
                labelText={
                  isRoom
                    ? t('IPDBooking.Room') || 'Room'
                    : t('Receptionist Dashboard.Doctor') || 'Doctor'
                }
              />
              <Select
                className={styles.selectField}
                isSearchable={true}
                options={doctorData}
                value={selectedDoctor}
                components={animatedComponent}
                closeMenuOnSelect={true}
                placeholder={
                  isRoom
                    ? t('BedSetup.select_room') || 'Select Room'
                    : t('IPDBooking.Select Doctor') || 'Select Doctor'
                }
                onChange={(e: any) => {
                  setSelectedDoctor(e)
                }}
                styles={reactSelectStyle}
                maxMenuHeight={200}
              />
            </div>
          </div>
          <div className={styles.dateRangeContainer}>
            <span className={styles.dateLabel}>{t('OngoingClaims.From')}</span>
            <div className={styles.dateFieldContainer}>
              <input
                type="date"
                value={dateFilter?.from}
                onChange={(e: any) => {
                  setDateFilter({ ...dateFilter, from: e?.target?.value })
                }}
                className={styles.dateField}
                max={
                  dateFilter?.to &&
                  (new Date(dateFilter?.to)?.toISOString().split('T')[0] ?? '')
                }
              />
            </div>
            <span className={styles.dateLabel}>
              {t('ShareQuestionnaire.To')}
            </span>
            <div className={styles.dateFieldContainer}>
              <input
                type="date"
                value={dateFilter?.to}
                max="9999-12-31"
                onChange={(e: any) => {
                  setDateFilter({ ...dateFilter, to: e?.target?.value })
                }}
                className={styles.dateField}
              />
            </div>
            <SearchButton
              handleClick={() => {
                setPageIndex(1)
                const payload = {
                  type: isRoom ? 'room' : 'doctor',
                  doctor_id: !isRoom ? selectedDoctor.value : undefined,
                  room_id: isRoom ? selectedDoctor.value : undefined,
                  start_date: dateFilter.from,
                  end_date: dateFilter.to,
                  page: pageIndex,
                  pageSize: dataPerPage,
                }

                if (payload.doctor_id || payload.room_id) {
                  dispatch(
                    getDocUnavailability(requestGenerator(payload))
                  ).then((e) => {
                    if (e.type === `${GET_DOC_UNAVAILABILITY_TYPE}/fulfilled`) {
                      setTotalPage(e.payload.lastPage)
                    }
                  })
                }
              }}
            />
            <Button
              title={t('InvoiceService.Add') || 'Add'}
              customClass={styles.addButton}
              handleClick={() => {
                setEditData({})
                setAddPopup(true)
              }}
            />
          </div>
        </div>
        <div className={styles.TableMainContainer}>
          <div className={styles.tableContainer}>
            <TableV2
              tableHeaderData={doctorUnavailabilityData}
              tableRowData={docUnavailabilty}
              active={false}
              handleClick={(row: any) => edit(row)}
              handleClicks={(row: any) => {
                setEditData(row)
                setNotePopup(true)
              }}
            />
          </div>

          <Pagination
            pageSize={dataPerPage}
            setDataPerPage={setDataPerPage}
            pageIndexOptions={pageIndexOptions}
            pageIndex={pageIndex}
            setPageIndex={setPageIndex}
          />
        </div>
      </div>
    </>
  )
}

export default DoctorUnavailablity
