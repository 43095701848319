// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.paginationV2_paginationContainer__7TPSI {
  display: flex;
  align-items: center;
}
.paginationV2_paginationContainer__7TPSI .paginationV2_iconContainer__oLwSU {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 34px;
  width: 34px;
  background: var(--white1);
  border: 1px solid var(--grey6);
  border-radius: 3px;
  margin-left: 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 34px;
  width: 34px;
  background: var(--white1);
  border: 1px solid var(--grey6);
  border-radius: 3px;
  margin-left: 10px;
  cursor: pointer;
}
.paginationV2_paginationContainer__7TPSI .paginationV2_iconContainerActive__WkIAB {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 34px;
  width: 34px;
  background: var(--white1);
  border: 1px solid var(--green1);
  border-radius: 3px;
  margin-left: 10px;
  cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/components/common/pagination/paginationv2/paginationV2.module.scss"],"names":[],"mappings":"AAYA;EACE,aAAA;EACA,mBAAA;AAXF;AAYE;EACE,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,YAAA;EACA,WAAA;EACA,yBAAA;EACA,8BAAA;EACA,kBAAA;EACA,iBAAA;EACA,eAAA;EAxBF,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,YAAA;EACA,WAAA;EACA,yBAAA;EACA,8BAmBgB;EAlBhB,kBAAA;EACA,iBAAA;EACA,eAAA;AAeF;AAGE;EA3BA,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,YAAA;EACA,WAAA;EACA,yBAAA;EACA,+BAsBgB;EArBhB,kBAAA;EACA,iBAAA;EACA,eAAA;AA2BF","sourcesContent":["@mixin icon($border) {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  height: 34px;\n  width: 34px;\n  background: var(--white1);\n  border: $border;\n  border-radius: 3px;\n  margin-left: 10px;\n  cursor: pointer;\n}\n.paginationContainer {\n  display: flex;\n  align-items: center;\n  .iconContainer {\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    height: 34px;\n    width: 34px;\n    background: var(--white1);\n    border: 1px solid var(--grey6);\n    border-radius: 3px;\n    margin-left: 10px;\n    cursor: pointer;\n    @include icon(1px solid var(--grey6));\n  }\n  .iconContainerActive {\n    @include icon(1px solid var(--green1));\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"paginationContainer": `paginationV2_paginationContainer__7TPSI`,
	"iconContainer": `paginationV2_iconContainer__oLwSU`,
	"iconContainerActive": `paginationV2_iconContainerActive__WkIAB`
};
export default ___CSS_LOADER_EXPORT___;
