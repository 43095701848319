import axios from "axios";
import { CALL_CENTER_ADMIN_AGENT_STATUS_UPDATE, GET_CALL_CENTER_ADMIN_ALL_LEADS, GET_CALL_CENTER_ADMIN_ASSIGN_LEAD, GET_CALL_CENTER_ADMIN_DASHBORD_AGENT, GET_CALL_CENTER_ADMIN_DASHBORD_COUNT } from "../../../config/config";
import { IAPIPayload } from "../../../interfaces/apiInterface";


export const callCenterAdminDashborCount = (data: IAPIPayload) => {
    return axios.post(GET_CALL_CENTER_ADMIN_DASHBORD_COUNT, data);
};

export const callCenterAdminDashborAgent = (data: IAPIPayload) => {
    return axios.post(GET_CALL_CENTER_ADMIN_DASHBORD_AGENT, data);
};

export const callCenterAdminDashbordAllLeads = (data: IAPIPayload) => {
    return axios.post(GET_CALL_CENTER_ADMIN_ALL_LEADS, data);
};

export const callCenterAdminDashbordAssignLead = (data: IAPIPayload) => {
    return axios.post(GET_CALL_CENTER_ADMIN_ASSIGN_LEAD, data);
};

export const callCenterAdminDashbordUpdateStatus = (data: IAPIPayload) => {
    return axios.post(CALL_CENTER_ADMIN_AGENT_STATUS_UPDATE, data);
};

