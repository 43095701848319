import createAsyncThunkForSlice from '../../../utils/utils'
import {
  CREATE_IPD_NEW_INVOICE,
  GET_ALL_IPD_INVOICE_JOBS,
  GET_INVENTORY_ITEM_ALL,
  GET_IPD_INVOICE_CASE,
  GET_IPD_INVOICE_PATIENT_DETAILS,
  GET_IPD_INVOICE_PATIENT_LIST,
  GET_IPD_INVOICE_TREATMENT,
  GET_PATIENT_PROFILE_PIC,
  UPDATE_IPD_INVOICE_TREATMENT,
} from '../../../constants/asyncActionsType'
import {
  allInventoryItems,
  createNewIpdInvoice,
  ipdInvoicePatientList,
  patientInvoiceAllJobs,
  patientInvoiceCase,
  patientInvoiceDetails,
  patientInvoiceTreatment,
  patientProfilePic,
  patientTreatmentUpdate,
} from './ipdCrud'

export const getAllIpdPatientsList = createAsyncThunkForSlice(
  GET_IPD_INVOICE_PATIENT_LIST,
  ipdInvoicePatientList
)

export const getIpdInvoiceDetails = createAsyncThunkForSlice(
  GET_IPD_INVOICE_PATIENT_DETAILS,
  patientInvoiceDetails
)

export const getIpdInvoiceCaseDetail = createAsyncThunkForSlice(
  GET_IPD_INVOICE_CASE,
  patientInvoiceCase
)

export const getIpdInvoiceTreatment = createAsyncThunkForSlice(
  GET_IPD_INVOICE_TREATMENT,
  patientInvoiceTreatment
)

export const getIpdInvoiceAllJobs = createAsyncThunkForSlice(
  GET_ALL_IPD_INVOICE_JOBS,
  patientInvoiceAllJobs
)

export const getAllInventoryItems = createAsyncThunkForSlice(
  GET_INVENTORY_ITEM_ALL,
  allInventoryItems
)

export const createIpdNewInvoice = createAsyncThunkForSlice(
  CREATE_IPD_NEW_INVOICE,
  createNewIpdInvoice,
  {
    isToast: true,
  }
)

export const getPatientProfilePic = createAsyncThunkForSlice(
  GET_PATIENT_PROFILE_PIC,
  patientProfilePic
)

export const updatePatientIpdTreatment = createAsyncThunkForSlice(
  UPDATE_IPD_INVOICE_TREATMENT,
  patientTreatmentUpdate
)
