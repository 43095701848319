import {
  GET_ALL_COUNT_TYPE,
  GET_ALL_DASHBOARD_TYPE,
} from '../../../constants/asyncActionsType'
import createAsyncThunkForSlice from '../../../utils/utils'
import { getAllCount, getAllDashboardList } from './hrDashboardCrud'

export const getAllDashboard = createAsyncThunkForSlice(
  GET_ALL_DASHBOARD_TYPE,
  getAllDashboardList
)

export const getAllCounts = createAsyncThunkForSlice(
  GET_ALL_COUNT_TYPE,
  getAllCount
)
