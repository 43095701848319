import { CrossIcon2 } from '../../components/common/svg-components'
import { colors } from '../color'
import moment from 'moment'
import { t } from 'i18next'

export const pharmacyOnholdHeaderData: any = [
  {
    Header: t('PatientActivityheader.DATE & TIME'),
    accessor: 'date',
    Cell: ({ row }: any) => {
      const originalDate = row?.original?.createdAt
      const formattedDate = moment(originalDate).format('DD MMM YYYY')
      const formattedTime = moment(originalDate).format('hh:mm')
      return <p>{formattedDate + '  ' + formattedTime}</p>
    },
  },
  { Header: t('LabInvoice.CUSTOMER_NAME'), accessor: 'name' },
  { Header: t('POS.TOTAL_AMOUNT'), accessor: 'total_amount' },
  {
    Header: ' ',
    Cell: (props: any) => {
      return (
        <>
          <div>
            <CrossIcon2
              fillColor1={colors.red1}
              handleClick={() => {
                props?.onRowClick(props?.row?.original?._id)
              }}
            />
          </div>
        </>
      )
    },
  },
]

export const pharmacyMedicineHeaderData: any = [
  { Header: t('POS.PRODUCT'), accessor: 'name' },
  { Header: t('MedicationHeader.QTY'), accessor: 'qty' },
  { Header: t('POS.UNIT_PRICE'), accessor: 'price' },
  { Header: `${t('InsuranceMaster.DISCOUNT')}%`, accessor: 'discount' },
  { Header: t('InvoiceService.AMOUNT'), accessor: 'total_amount' },
  {
    Header: t('Insurance.Expiry Date'),
    Cell: ({ row }: any) => {
      return <p>-</p>
    },
  },
  {
    Header: t('POS.BATCH'),
    Cell: ({ row }: any) => {
      return <p>-</p>
    },
  },
  {
    Header: ' ',
    Cell: (props: any) => {
      return (
        <>
          <div>
            <CrossIcon2
              fillColor1={colors.red1}
              handleClick={() => {
                props?.onRowClick(props?.row?.original?._id)
              }}
            />
          </div>
        </>
      )
    },
  },
]
