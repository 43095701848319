import { useEffect, useState } from 'react'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import { level2TabData } from '../../constants/data'
import { useAppDispatch, useAppSelector } from '../../hooks'
import {
  accountsLevel2,
  addAttribute,
  getAllLevelData,
} from '../../redux/features/view-chat-of-accounts/accountsAsynActions'
import { requestGenerator } from '../../utils/payloadGenerator'
import {
  setDropdownAttempts,
  setSelectedOptionDropdown,
} from '../../redux/features/view-chat-of-accounts/accountsSlice'
import styles from './ViewCartOfAccountsLayout.module.scss'
import Loader from '../../components/common/spinner/Loader'
import {
  DropDownArrowIcon,
  DropDownIcon,
} from '../../components/common/svg-components'
import Tabs from '../../components/core/tab/Tabs'
import { t } from 'i18next'

const ViewChartOfAccountsLayout = () => {
  const [selectedOption, setSelectedOption] = useState<any>({})
  const [showArrow, setArrow] = useState<boolean>(false)

  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const { level2, loading } = useAppSelector((state) => state.accounts)
  const data = level2.accountAttributes

  const location = useLocation().pathname

  useEffect(() => {
    if (location === '/viewchartofaccounts') {
      navigate('/viewchartofaccounts/level2list')
    }
  }, [location])

  useEffect(() => {
    dispatch(accountsLevel2(requestGenerator({})))
  }, [])

  const handleRoleName = (e: any) => {
    const selectedId = e.target.value
    const selectedName = e.target.options[e.target.selectedIndex].text
    const option = {
      ...selectedOption,
      id: selectedId,
      name: selectedName,
    }
    setSelectedOption(option)
    dispatch(setSelectedOptionDropdown(option))
    dispatch(addAttribute(requestGenerator(option))).then((e) => {
      if (e.type === 'post-attribute/addAttribute/fulfilled') {
        const levelID = {
          level_Id: 2,
          parent_Level_Type_Id: option?.id,
        }
        dispatch(setDropdownAttempts(requestGenerator(option)))
        navigate('/viewchartofaccounts/level2list', {
          state: {
            levelID,
          },
        })
      }
    })
  }

  useEffect(() => {
    setSelectedOption({})
    dispatch(setSelectedOptionDropdown({}))
  }, [])
  const handleDropdown = () => {
    setArrow((prevState) => !prevState)
  }
  return (
    <>
      {loading && <Loader />}
      <div className={styles.accountListContainer}>
        <div className={styles.levelContainer}>
          <div className={styles.levelInfo}>
            <h1 className={styles.levelText}>
              {t('ViewChartOfAccount.Level1')}
            </h1>
            <select
              className={styles.selectInputField}
              // value={selectedOption.id}
              onChange={handleRoleName}
              onClick={handleDropdown}
            >
              <option hidden>{t('ViewChartOfAccount.Select_Level1')}</option>
              {data?.map((item: any) => {
                return (
                  <option value={item.id} key={item.id}>
                    {item.name}
                  </option>
                )
              })}
            </select>
            {showArrow ? (
              <DropDownArrowIcon
                fillColor="#797979"
                customClass={styles.downArrowStyle}
              />
            ) : (
              <DropDownIcon
                fillColor="#797979"
                customClass={styles.downArrowStyle}
              />
            )}
          </div>
        </div>
        <Tabs tabData={level2TabData} />
        <Outlet />
      </div>
    </>
  )
}

export default ViewChartOfAccountsLayout
