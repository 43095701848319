import React from 'react'
import { Link } from 'react-router-dom'
import { t } from 'i18next'

/* tableHeaderColumns definition for create New Forms module */
export const createNewFormsTableHeaderData: any = [
  {
    Header: t('FormBuilder.ROLE'),
    accessor: 'role_id',
    Cell: ({ row }: any) => {
      const roles = row?.original?.role_detail
      return roles && roles?.length > 0
        ? roles?.map((item: any, index: number) => {
            return (
              <div key={`row=${index}`}>
                {index + 1}. {item?.name}
              </div>
            )
          })
        : '-'
    },
  },

  {
    Header: t('FormBuilder.CURRENT FORMS'),
    accessor: 'name',
  },
  {
    Header: t('PatientHistoryTableHeader.NOTES'),
    accessor: 'notes',
    Cell: () => {
      return React.createElement(
        Link,
        { to: '/', className: 'viewLinkStyle' },
        'View'
      )
    },
  },
  {
    Header: t('RcptTableHeader.STATUS'),
  },
  {
    Header: t('DocUploadHeader.ACTION'),
  },
  {
    Header: t('PatientHistoryFormHeader.PREVIEW'),
  },
]
/* tableHeaderColumns definition for create New Forms module */
