// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.posummarypopup_tablestyle__ebmaX {
  max-height: 230px;
  background-color: var(--white1);
  overflow: auto;
  scroll-behavior: smooth;
}
.posummarypopup_tablestyle__ebmaX::-webkit-scrollbar {
  display: block !important;
  width: 7px !important;
  height: 7px !important;
}
.posummarypopup_tablestyle__ebmaX::-webkit-scrollbar-track {
  background: transparent !important;
}
.posummarypopup_tablestyle__ebmaX::-webkit-scrollbar-thumb {
  background-color: var(--grey7) !important;
  border-radius: 10px !important;
  border: 1px solid var(--grey7) !important;
}

.posummarypopup_radioFieldContainer__tm32\\+ {
  margin: 25px 0px;
}

.posummarypopup_yellowTextColor__E-9t0 {
  color: #ffa009;
}

.posummarypopup_blueTextColor__nfmrN {
  color: #0e26a3;
}

.posummarypopup_greenTextColor__y5zcM {
  color: #02bf90;
}

.posummarypopup_notesContainer__4qX8J {
  margin-block: 30px;
  text-align: center;
}`, "",{"version":3,"sources":["webpack://./src/pages/mainstore/po-summary-popup/posummarypopup.module.scss"],"names":[],"mappings":"AAEA;EACE,iBAAA;EACA,+BAAA;EACA,cAAA;EACA,uBAAA;AADF;AAEE;EACE,yBAAA;EACA,qBAAA;EACA,sBAAA;AAAJ;AAEE;EACE,kCAAA;AAAJ;AAGE;EACE,yCAAA;EACA,8BAAA;EACA,yCAAA;AADJ;;AAKA;EACE,gBAAA;AAFF;;AAKA;EACE,cAAA;AAFF;;AAKA;EACE,cAAA;AAFF;;AAKA;EACE,cAAA;AAFF;;AAKA;EACE,kBAAA;EACA,kBAAA;AAFF","sourcesContent":["@import '../../../styles/mixin.scss';\n\n.tablestyle {\n  max-height: 230px;\n  background-color: var(--white1);\n  overflow: auto;\n  scroll-behavior: smooth;\n  &::-webkit-scrollbar {\n    display: block !important;\n    width: 7px !important;\n    height: 7px !important;\n  }\n  &::-webkit-scrollbar-track {\n    background: transparent !important;\n  }\n\n  &::-webkit-scrollbar-thumb {\n    background-color: var(--grey7) !important;\n    border-radius: 10px !important;\n    border: 1px solid var(--grey7) !important;\n  }\n}\n\n.radioFieldContainer {\n  margin: 25px 0px;\n}\n\n.yellowTextColor {\n  color: #ffa009;\n}\n\n.blueTextColor {\n  color: #0e26a3;\n}\n\n.greenTextColor {\n  color: #02bf90;\n}\n\n.notesContainer {\n  margin-block: 30px;\n  text-align: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tablestyle": `posummarypopup_tablestyle__ebmaX`,
	"radioFieldContainer": `posummarypopup_radioFieldContainer__tm32+`,
	"yellowTextColor": `posummarypopup_yellowTextColor__E-9t0`,
	"blueTextColor": `posummarypopup_blueTextColor__nfmrN`,
	"greenTextColor": `posummarypopup_greenTextColor__y5zcM`,
	"notesContainer": `posummarypopup_notesContainer__4qX8J`
};
export default ___CSS_LOADER_EXPORT___;
