import {
  ADD_DOCUMENT,
  EDIT_DOCUMENT_BY_ID,
  GET_ALL_DOCUMENT,
  GET_ALL_SEARCH_USER,
  GET_DOCUMENT_BY_ID,
  UPDATE_DOCUMENT_STATUS,
  DELETE_DOCUMENT,
  GET_ALL_ATTACHED,
} from '../../../constants/asyncActionsType'
import createAsyncThunkForSlice from '../../../utils/utils'
import {
  getAllDocumentList,
  addDocuments,
  getDocumentByIds,
  editDocumentByIds,
  updateDocumentStatuss,
  deleteDocuments,
  getAllSearchUsers,
  getAllAttach,
} from './documentCrud'

export const getAllDocument = createAsyncThunkForSlice(
  GET_ALL_DOCUMENT,
  getAllDocumentList,
  {
    isToast: false,
  }
)

export const getAllAttachs = createAsyncThunkForSlice(
  GET_ALL_ATTACHED,
  getAllAttach
)

export const addDocument = createAsyncThunkForSlice(
  ADD_DOCUMENT,
  addDocuments,
  {
    isToast: true,
  }
)
export const getDocumentById = createAsyncThunkForSlice(
  GET_DOCUMENT_BY_ID,
  getDocumentByIds,
  {
    isToast: true,
  }
)

export const editDocumentById = createAsyncThunkForSlice(
  EDIT_DOCUMENT_BY_ID,
  editDocumentByIds,
  {
    isToast: true,
  }
)

export const updateDocumentStatus = createAsyncThunkForSlice(
  UPDATE_DOCUMENT_STATUS,
  updateDocumentStatuss,
  {
    isToast: true,
  }
)
export const deleteDocument = createAsyncThunkForSlice(
  DELETE_DOCUMENT,
  deleteDocuments,
  {
    isToast: true,
  }
)

export const getAllSearchUserList = createAsyncThunkForSlice(
  GET_ALL_SEARCH_USER,
  getAllSearchUsers
)
