import axios from 'axios'
import {
  ADD_CAMPAIGN,
  SEARCH_CRITERIA,
  GET_ALL_CAMPAIGN,
  UPDATE_CAMPAIGN,
  GET_CAMPAIGNS_LEADS_URL,
  ASSIGN_AGENT_TO_LEAD_URL,
} from '../../../config/config'
import { IAPIPayload } from '../../../interfaces/apiInterface'

export const addCampaigns = (data: IAPIPayload) => {
  return axios.post(ADD_CAMPAIGN, data)
}
export const searchCriterias = (data: IAPIPayload) => {
  return axios.post(SEARCH_CRITERIA, data)
}

export const getAllCampaignList = (data: IAPIPayload) => {
  return axios.post(GET_ALL_CAMPAIGN, data)
}

export const updateCampaigns = (data: IAPIPayload) => {
  return axios.post(UPDATE_CAMPAIGN, data)
}

export const getCampaignLeads = (data: IAPIPayload) => {
  return axios.post(GET_CAMPAIGNS_LEADS_URL, data)
}

export const assignAgenttoLead = (data: IAPIPayload) => {
  return axios.post(ASSIGN_AGENT_TO_LEAD_URL, data)
}
