import { FC, useState, useEffect } from 'react'
import Select, { components } from 'react-select'
import { useLocation, useNavigate } from 'react-router-dom'
import SearchModal from '../leave-search-modal/SearchModal'
import moment from 'moment'
import { useAppDispatch, useAppSelector } from '../../../hooks'
import { applyLeaveValidator } from './applyLeaveValidator'
import {
  addLeave,
  editLeaveById,
  getAllAtachs,
  getAllLeave,
  getLeaveCalcuations,
  getLeaveTable,
} from '../../../redux/features/leave/leaveAsyncActions'
import { IApplyLeaveForm } from '../../../interfaces/applyLeaveInterface'
import { trimValue } from '../../../utils/utils'
import { SubmitHandler, useForm } from 'react-hook-form'
import {
  APPLY_LEAVE_ATTACHMENT,
  ATTACHEMENTS,
  END_DATE,
  LEAVE_CALCULATION,
  LEAVE_TYPE,
  NAME,
  REASON,
  START_DATE,
  emp_id,
  STATUS,
} from '../../../constants/applyLeaveConstants'
import { requestGenerator } from '../../../utils/payloadGenerator'
import Button from '../../../components/common/button/Button'
import { InfoIcon, SearchIcon } from '../../../components/common/svg-components'
import { colors } from '../../../constants/color'
import {
  ADD_LEAVE,
  EDIT_LEAVE_BY_ID,
} from '../../../constants/asyncActionsType'
import Loader from '../../../components/common/spinner/Loader'
import Popup from '../../../components/common/popup/Popup'
import styles from './applyLeave.module.scss'
import LeaveTable from './LeaveTable'
import AttachFile from '../../../components/common/attach-files/multiple-file/Attachfiles'
import { setSelectedObj } from '../../../redux/features/employee/employeeSlice'
import { StatusArray } from '../../../constants/data'
import {
  clearAllCalculation,
  clearallLeaveTable,
} from '../../../redux/features/leave/leaveSlice'
import { t } from 'i18next'

interface IApplyLeave {}
export type fileType = {
  name: string
  data_uri: any
  attachements: any
}
const ApplyLeave: FC<IApplyLeave> = () => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const location = useLocation()
  const [disable, setDisable] = useState(true)
  const [showInfo, setShowInfo] = useState<boolean>(false)
  const [leaveData, setleaveData] = useState<any>({})
  const [showSearchModal, setShowSearchModal] = useState<boolean>(false)
  const [searchModalData, setSearchModalData] = useState({})
  const [file, setFile] = useState<any>()
  const [attachments, setAttachments] = useState<any[]>([])
  const [leaveError, setLeaveError] = useState(false)

  // Redux state
  const {
    isLoading,
    allAtchsData,
    allCalculation,
    allLeaveTable,
    leaveDataInfo,
  } = useAppSelector((state) => state.leave)
  const { masterValueData } = useAppSelector((state) => state.login)

  // React Form
  const {
    register,
    watch,
    reset,
    handleSubmit,
    setValue,
    clearErrors,
    setError,
    formState: { errors },
  } = useForm<IApplyLeaveForm>({})
  const onSubmit: SubmitHandler<IApplyLeaveForm> = (data: any, e: any) => {
    const dataPayload = {
      status: data.status,
      emp_id: leaveData._id,
      name: data.name,
      leave_type:
        data.status === 'PAID'
          ? data?.leave_type?.label === 'Maternity Leave'
            ? 'MATERNITY_LEAVE'
            : data?.leave_type?.label === 'Medical Leave'
            ? 'MEDICAL_LEAVE'
            : data?.leave_type?.label === 'Haj Leave'
            ? 'HAJ_LEAVE'
            : 'ANNUAL_LEAVE'
          : '',
      leave_calculation: data.leave_calculation,
      start_date: data.start_date,
      end_date: data.end_date,
      selected_days: data.selected_days,
      paid_leaves: data.paid_leaves,
      unpaid_leaves: data.unpaid_leaves,
      reason: data.reason,
      file: data.attachements,
      // file:attachments,
      is_approved: 'INITIATE',
    }

    if (data && data._id) {
      let id = data._id
      delete data.emp_id
      const dataPayload = {
        status: data.status,
        leave_type:
          data.status === 'PAID'
            ? data?.leave_type?.label === 'Maternity Leave'
              ? 'MATERNITY_LEAVE'
              : data?.leave_type?.label === 'Medical Leave'
              ? 'MEDICAL_LEAVE'
              : data?.leave_type?.label === 'Haj Leave'
              ? 'HAJ_LEAVE'
              : 'ANNUAL_LEAVE'
            : '',
        leave_calculation: data.leave_calculation,
        start_date: data.start_date,
        end_date: data.end_date,
        selected_days: data.selected_days,
        paid_leaves: data.paid_leaves,
        unpaid_leaves: data.unpaid_leaves,
        reason: data.reason,
        is_approved: data.is_approved,
        file: attachments,
      }
      if (data.status === 'PAID' && !data.leave_type?.label?.length) {
        setLeaveError(true)
      } else {
        dispatch(
          editLeaveById(requestGenerator({ id, data: dataPayload }))
        ).then((e) => {
          if (e.type === `${EDIT_LEAVE_BY_ID}/fulfilled`) {
            navigate('/leave-management')
            let payloadData = {
              is_active: true,
              page: 1,
              pageSize: 100,
            }
            dispatch(getAllLeave(requestGenerator(payloadData)))
          }
        })
      }
    } else {
      dispatch(addLeave(requestGenerator(dataPayload))).then((e) => {
        if (e.type === `${ADD_LEAVE}/fulfilled`) {
          navigate('/leave-management')
          let payloadData = {
            is_active: true,
            page: 1,
            pageSize: 100,
          }
          dispatch(getAllLeave(requestGenerator(payloadData)))
        }
      })
    }
  }

  const watchStartDate = watch(START_DATE)
  const watchEndDate = watch(END_DATE)
  const leaveType = watch(LEAVE_TYPE)

  useEffect(() => {
    dispatch(clearallLeaveTable())
  }, [])

  //Set data from Popup
  useEffect(() => {
    if (leaveData?.name_en) {
      setValue(NAME, leaveData?.name_en)
      setValue(emp_id, leaveData?.emp_id)
    }
  }, [leaveData])
  //Set data for Edit form
  let data = location.state

  useEffect(() => {
    if (data && masterValueData?.length) {
      reset(data.user)
      const startdate = moment(data?.user?.start_date).format('YYYY-MM-DD')
      const enddate = moment(data?.user?.end_date).format('YYYY-MM-DD')
      setValue(emp_id, data?.user?.emp_id?.emp_id)
      setValue(START_DATE, startdate)
      setValue(END_DATE, enddate)
      setValue(LEAVE_CALCULATION, data?.user?.leave_calculation)
      const nationality = masterValueData?.find(
        (item: any) => item?.category_name === 'LEAVE-TYPE'
      )?.values

      const selectedLeave = nationality?.filter(
        (item: any) =>
          item?.value.toUpperCase().split(' ').join('_') ===
          data?.user?.leave_type
      )
      setValue(LEAVE_TYPE, {
        label: selectedLeave[0]?.value?.toUpperCase().split(' ').join('_'),
        value: selectedLeave[0]?._id,
      })

      let attachements = data?.user?.file
      // let attachementsData: any = []
      // if(attachements && attachements.length > 0){

      //   attachements?.forEach((element: any) => {
      //     attachementsData.push({ name: element, data_uri: element })
      //   });
      // }
      // attachements?.forEach((element: any) => {
      //   attachementsData.push({ name: element, data_uri: element })
      // });

      // const storeDataUri = attachementsData?.flatMap((s: any) => s?.data_uri)
      // const flatNestedData = attachements?.map((x: any) => x.data_uri)
      let attachementsData: any = []
      attachements.forEach((element: any) => {
        attachementsData.push({ name: element, data_uri: element })
      })
      const requestData = {
        attachements: attachementsData,
      }
      dispatch(getAllAtachs(requestGenerator(requestData))).then((e) => {
        data.user.attachements = e?.payload
      })
    }
  }, [dispatch, masterValueData?.length])

  // Set Attachements on Edit
  useEffect(() => {
    if (allAtchsData && allAtchsData?.length > 0) {
      const attachementsData = allAtchsData?.map((item: fileType) => {
        let nameWithId =
          item?.name?.split?.('/')?.[item?.name?.split?.('/')?.length - 1] ?? ''
        let nameWithoutID = nameWithId
          ? nameWithId?.split?.('-')?.[nameWithId?.split?.('-')?.length - 1]
          : ''
        const finalItem = {
          name: nameWithoutID ?? 'File.pdf',
          data_uri: item?.data_uri,
        }
        return finalItem
      })
      setValue(APPLY_LEAVE_ATTACHMENT, attachementsData)
      setAttachments(attachementsData)
    } else {
      setValue(APPLY_LEAVE_ATTACHMENT, [])
      setAttachments([])
    }
  }, [allAtchsData, setValue])

  // Reset
  const handleReset = () => {
    reset()
    setValue(LEAVE_TYPE, '')
    setAttachments([])
  }

  // Search Employee modal close
  const handleSearchModalClose = () => {
    setShowSearchModal((prevState) => !prevState)
    setSearchModalData({})
  }
  // Search Employee Get Popup Data By Id
  const handleSearchGetPopupData = (item: any) => {
    dispatch(setSelectedObj(item))
    setleaveData(item)
    setShowSearchModal((prevState) => !prevState)
  }
  // Search Employee Popup
  const handleSearchPopup = () => {
    reset()
    setAttachments([])
    setSearchModalData({})
    setShowSearchModal((prevState) => !prevState)
  }
  const multiSelectStyles = {
    control: (provided: any) => ({
      ...provided,
      height: '40px',
      cursor: 'pointer',
    }),
    indicatorSeparator: () => ({
      color: '#FF0000 ',
    }),
    dropdownIndicator: (provided: any, state: any) => ({
      ...provided,
      color: '#797979',
      transform: state.selectProps.menuIsOpen ? 'rotate(180deg)' : null,
    }),
    clearIndicator: (provided: any) => ({
      ...provided,
      color: '#FF0000',
      display: 'none',
    }),
  }

  useEffect(() => {
    if (watchEndDate?.length) {
      let data = {
        startDate: watchStartDate,
        endDate: watchEndDate,
        emp_id: leaveData?._id || location?.state?.user?.employee_id,
      }
      dispatch(getLeaveCalcuations(requestGenerator(data)))
    }
    return () => {
      dispatch(clearAllCalculation())
    }
  }, [watchStartDate, watchEndDate])

  useEffect(() => {
    if (leaveData) {
      setAttachments([])
      setValue(emp_id, leaveData?.emp_id)
    }
  }, [leaveData])

  useEffect(() => {
    setValue(LEAVE_CALCULATION, allCalculation?.workingDays)
  }, [allCalculation])

  useEffect(() => {
    if (leaveType?.label) {
      let payloadData = {
        emp_id: leaveData?._id || data?.user?.employee_id,
        type:
          leaveType?.label === 'Maternity Leave'
            ? 'MATERNITY_LEAVE'
            : leaveType?.label === 'Medical Leave'
            ? 'MEDICAL_LEAVE'
            : leaveType?.label === 'Haj Leave'
            ? 'HAJ_LEAVE'
            : 'ANNUAL_LEAVE',
      }
      dispatch(getLeaveTable(requestGenerator(payloadData)))
    }
  }, [leaveType])

  const status = watch(STATUS)

  useEffect(() => {
    if (data?.user?._id === undefined) {
      setValue(LEAVE_CALCULATION, '')
    }
  }, [])

  useEffect(() => {
    if (showSearchModal) {
      setValue(LEAVE_TYPE, null)
    }
  }, [showSearchModal])

  const totalLeave = allLeaveTable[0]?.leave_count

  const available = leaveDataInfo?.leave_data?.days - totalLeave

  const current = allCalculation?.workingDays ?? 0

  const totalBalance = Number(available) - Number(current)

  return (
    <>
      {isLoading && <Loader />}
      {showSearchModal && (
        <Popup
          Children={SearchModal}
          popData={searchModalData}
          handleClose={() => handleSearchModalClose()}
          setModelOpenClose={setShowSearchModal}
          handleSubmit={handleSearchGetPopupData}
        />
      )}
      <div className={styles.applyLeaveContainer}>
        {data?.user?._id === undefined && (
          <div
            className={
              data?.user?._id ? styles.disableContainer : styles.searchContainer
            }
            onClick={handleSearchPopup}
            style={{ cursor: 'pointer' }}
          >
            <SearchIcon
              fillColor={colors?.grey2}
              customClass={
                data?.user?._id ? styles.disableStyle : styles.searchIconStyle
              }
              // handleClick={handleSearchPopup}
            />
            <p className={styles.searchText}>
              {t('LeaveManagement.Search_Employee')}
            </p>
            {/* <SearchDesign
            handleSearchClick={handleSearchPopup}
            customClass={
              data?.user?._id
                ? styles.disableInputSearchContainer
                : styles.inputSearchContainer
            }
          /> */}
          </div>
        )}
        <form onSubmit={handleSubmit(onSubmit)}>
          <fieldset
            disabled={leaveData?._id || location?.state ? false : true}
            className={styles.formDisable}
          >
            <div className={styles.leaveApplicationDetailsContainer}>
              <div className={styles.leaveApplicationDetailsTitle}>
                <p className={styles.leaveApplicationDetailsTitleText}>
                  {t('LeaveManagement.Leave_Application_Details')}
                </p>
              </div>
              <div className={styles.statusContainer}>
                <label className={styles.statusLableText}>
                  {t('MobileAppointmentReq.Status')}
                  <span className="asterick">*</span>
                </label>
                <div className={styles.inputFieldContainer}>
                  <select
                    className={styles.selectInputFields}
                    {...register(STATUS, applyLeaveValidator[STATUS])}
                    value={watch(STATUS)}
                    onChange={(e: any) => {
                      setValue(STATUS, e?.target.value)
                      setValue(LEAVE_TYPE, null)
                      dispatch(clearallLeaveTable())
                    }}
                  >
                    <option value="">{t('ReportGenerator.Select')} </option>
                    {StatusArray?.map((StatusArray, index) => (
                      <option key={index}>{StatusArray}</option>
                    ))}
                  </select>
                  {errors[STATUS] && (
                    <div className={styles.errorContainer}>
                      <span className={styles.extraSpan}></span>
                      <p className="dashboardFormError">
                        {errors[STATUS].message}
                      </p>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className={styles.leaveApplicationDetailsForm}>
              <div className={styles.formRowContainer}>
                <div className={styles.formFieldContainer}>
                  <div className={styles.inputFieldContainer}>
                    <label className={styles.formLabel}>
                      {t('AddEmp.Employee ID')}
                      <span className="asterick">*</span>
                    </label>
                    <input
                      placeholder="Employee Id"
                      className={
                        disable ? styles.disableInputField : styles.inputField
                      }
                      disabled={true}
                      {...register(emp_id, applyLeaveValidator[emp_id])}
                    />
                  </div>
                  {errors[emp_id] && (
                    <div className={styles.errorContainer}>
                      <span className={styles.extraSpan}></span>
                      <p className="dashboardFormError">
                        {errors[emp_id].message}
                      </p>
                    </div>
                  )}
                </div>
                <div className={styles.formFieldContainer}>
                  <div className={styles.inputFieldContainer}>
                    <label className={styles.formLabel}>
                      {t('PatientEMR.Name')}
                      <span className="asterick">*</span>
                    </label>
                    <input
                      type="text"
                      placeholder={t('PatientEMR.Name') || 'Name'}
                      className={
                        disable ? styles.disableInputField : styles.inputField
                      }
                      disabled={true}
                      {...register(NAME, applyLeaveValidator[NAME])}
                    />
                  </div>
                  {errors[NAME] && (
                    <div className={styles.errorContainer}>
                      <span className={styles.extraSpan}></span>
                      <p className="dashboardFormError">
                        {errors[NAME].message}
                      </p>
                    </div>
                  )}
                </div>
                <div className={styles.formFieldContainer}>
                  <div className={styles.inputFieldContainer}>
                    <label className={styles.formLabel}>
                      {t('LeaveManagement.Leave_Types')}
                      <span className="asterick">*</span>
                    </label>
                    <Select
                      className={styles.select}
                      placeholder={
                        <div className="selectPlaceholder">
                          {' '}
                          {t('LeaveManagement.Select_Leave')}
                        </div>
                      }
                      isClearable={true}
                      options={masterValueData
                        ?.find(
                          (item: any) => item?.category_name === 'LEAVE-TYPE'
                        )
                        ?.values?.map((item: any) => ({
                          label: item?.value,
                          value: item?._id,
                        }))}
                      value={watch(LEAVE_TYPE || '')}
                      isDisabled={status === 'UNPAID' ? true : false}
                      {...register(LEAVE_TYPE, {
                        required: status === 'UNPAID' ? false : true,
                      })}
                      components={{
                        DropdownIndicator: (props: any) => {
                          if (leaveData?._id || location?.state) {
                            return <components.DropdownIndicator {...props} />
                          } else {
                            return null
                          }
                        },
                      }}
                      closeMenuOnSelect={true}
                      maxMenuHeight={120}
                      styles={multiSelectStyles}
                      onChange={(e: any) => {
                        setValue(LEAVE_TYPE, e)
                      }}
                    />
                  </div>

                  {errors?.[LEAVE_TYPE]?.type === 'required' && (
                    <div className={styles.errorContainer}>
                      <span className={styles.extraSpan}></span>
                      <p className="dashboardFormError">
                        {t('LeaveManagement.leave_type_validation')}
                      </p>
                    </div>
                  )}
                </div>
                <div className={styles.formFieldContainer}>
                  <div className={styles.inputFieldContainer}>
                    <label className={styles.formLabelText}>
                      {t('LeaveManagement.Leave_Calculation')}
                      <span className="asterick">*</span>
                    </label>
                    <div className={styles.infoContainer}>
                      <InfoIcon
                        fillColor={colors.grey2}
                        mouseEnter={() => setShowInfo(true)}
                        mouseLeave={() => setShowInfo(false)}
                        customClass={styles.iconStyle}
                      />
                      {showInfo && (
                        <p className={styles.infoText}>
                          {t('LeaveManagement.holiday_value_validation')}
                        </p>
                      )}
                    </div>
                    <input
                      placeholder="0"
                      disabled
                      className={
                        disable ? styles.disableInputField : styles.inputField
                      }
                      {...register(
                        LEAVE_CALCULATION,
                        applyLeaveValidator[LEAVE_CALCULATION]
                      )}
                    />
                  </div>
                </div>
                <div className={styles.formFieldContainer}>
                  <div className={styles.inputFieldContainer}>
                    <label className={styles.formLabel}>
                      {t('BookingConfirmation.Start Date')}
                      <span className="asterick">*</span>
                    </label>
                    {data?.user?.start_date ? (
                      <input
                        disabled
                        type="date"
                        className={styles.inputField}
                        value={watchStartDate || ''}
                        min={new Date()?.toISOString()?.split?.('T')?.[0]}
                        max={
                          watchEndDate &&
                          (new Date(watchEndDate)
                            ?.toISOString()
                            ?.split?.('T')[0] ??
                            '')
                        }
                        {...register(
                          START_DATE,
                          applyLeaveValidator[START_DATE]
                        )}
                      />
                    ) : (
                      <input
                        type="date"
                        min={new Date()?.toISOString()?.split?.('T')?.[0]}
                        max={
                          watchEndDate &&
                          (new Date(watchEndDate)
                            ?.toISOString()
                            ?.split?.('T')[0] ??
                            '')
                        }
                        className={styles.inputField}
                        value={watchStartDate || ''}
                        {...register(
                          START_DATE,
                          applyLeaveValidator[START_DATE]
                        )}
                      />
                    )}
                  </div>
                  {errors[START_DATE] && (
                    <div className={styles.errorContainer}>
                      <span className={styles.extraSpan}></span>
                      <p className="dashboardFormError">
                        {errors[START_DATE].message}
                      </p>
                    </div>
                  )}
                </div>
                <div className={styles.formFieldContainer}>
                  <div className={styles.inputFieldContainer}>
                    <label className={styles.formLabel}>
                      {t('ReportBuilder.End_Date')}
                      <span className="asterick">*</span>
                    </label>
                    <input
                      type="date"
                      disabled={!(watchStartDate?.length > 0)}
                      value={watchEndDate || ''}
                      min={
                        watchStartDate &&
                        new Date(watchStartDate)
                          ?.toISOString()
                          ?.split?.('T')?.[0]
                      }
                      className={styles.inputField}
                      {...register(END_DATE, applyLeaveValidator[END_DATE])}
                    />
                  </div>
                  {errors[END_DATE] && (
                    <div className={styles.errorContainer}>
                      <span className={styles.extraSpan}></span>
                      <p className="dashboardFormError">
                        {errors[END_DATE].message}
                      </p>
                    </div>
                  )}
                </div>
              </div>

              <div className={styles.formTextAttachContainer}>
                <div className={styles.formFieldTextContainer}>
                  <div
                    className={[
                      styles.inputFieldContainer,
                      styles.textAreaFieldContainer,
                    ].join(' ')}
                  >
                    <label className={styles.formLabelTextarea}>
                      {t('StatusConfirmation.Reason')}
                      <span className="asterick">*</span>
                    </label>
                    <input
                      type="text"
                      className={styles.textArea}
                      placeholder={
                        t('StatusConfirmation.EnterReason') || 'Enter Reason'
                      }
                      {...register(REASON, applyLeaveValidator[REASON])}
                      onChange={(e: any) => trimValue(e)}
                    />
                  </div>
                  {errors[REASON] && (
                    <div className={styles.errorTextContainer}>
                      <span className={styles.extraTextSpan}></span>
                      <p className="dashboardFormError">
                        {errors[REASON].message}
                      </p>
                    </div>
                  )}
                </div>
                <div className={styles.attcherror}>
                  <div className={styles.attachmentContainer}>
                    <label className={styles.attachmentLabel}>
                      {t('Common.Attachments')}
                    </label>
                    <AttachFile
                      fileKey={ATTACHEMENTS}
                      defaultAttachments={attachments ?? []}
                      setValue={setValue}
                      clearErrors={clearErrors}
                      isMultiSelect={true}
                      setErrors={setError}
                      file={file}
                      customClass={styles.attacheFileName}
                      setFile={setFile}
                      attachments={attachments}
                      setAttachments={setAttachments}
                      {...register(
                        ATTACHEMENTS,
                        applyLeaveValidator[ATTACHEMENTS]
                      )}
                    />
                  </div>
                </div>
              </div>

              {status === 'PAID' && allLeaveTable?.length > 0 && (
                <div className={styles.leavedata}>
                  <LeaveTable
                    tableRowData={[
                      allLeaveTable,
                      //   , ...[{}]
                    ]}
                  />
                </div>
              )}
              <div className={styles.applyLeaveBtnContainer}>
                <Button
                  type="submit"
                  title={t('Common.Submit') || 'Submit'}
                  customClass={styles.submitBtn}
                  disable={
                    totalBalance < 0
                      ? true
                      : leaveDataInfo?.type === 'HAJ_LEAVE' &&
                        allLeaveTable[0]?.leave_count > 0
                      ? true
                      : Number(current) === 0
                      ? true
                      : false
                  }
                />
                <Button
                  title={t('BranchSetup.Reset') || 'Reset'}
                  type="reset"
                  customClass={styles.styleBtn}
                  handleClick={handleReset}
                  disable={leaveData?._id ? false : true}
                />
                <Button
                  title={t('Common.Back') || 'Back'}
                  type="button"
                  handleClick={() => navigate('/leave-management')}
                  customClass={styles.styleBtn}
                />
              </div>
            </div>
          </fieldset>
        </form>
      </div>
    </>
  )
}
export default ApplyLeave
