import { FC } from 'react'
import { CloseIcon } from '../../../components/common/svg-components'
import Divider from '../../../components/common/divider/Divider'
import { colors } from '../../../constants/color'
import styles from './remarkspopup.module.scss'
import { t } from 'i18next'
interface IPropsData {
  handleClose?: any
  popData?: string | any
}
const RemarksPopup: FC<IPropsData> = ({ handleClose, popData }) => {
  return (
    <>
      <div
        className={styles.actionSavePopupContainer}
        onClick={(e) => e.stopPropagation()}
      >
        <CloseIcon
          customClass={styles.closeIconStyle}
          fillColor={colors.green1}
          handleClick={() => handleClose()}
        />

        <div className={styles.actionSaveContainer}>
          <p className={styles.title}>{t('StatusConfirmation.Reason')}</p>
          <Divider customClass={styles.dividerStyle} />
          <p className={styles.descriptionText}> {popData?.noteDetail}</p>
        </div>
      </div>
    </>
  )
}
export default RemarksPopup
