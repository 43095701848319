import { FC, useEffect } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import Button from '../../button/Button'
import styles from './Level5Modal.module.scss'
import { AssetsArray } from '../../../../constants/data'
import {
  IaddLevel5Validators,
  addLevel5Validators,
} from '../../../../form-validators/addLevel5Validators'
import { trimValue } from '../../../../utils/utils'
import {
  Level5_NAME,
  Level5_CODE,
  Level5_LIST,
} from '../../../../constants/constant'
import { useAppDispatch, useAppSelector } from '../../../../hooks'
import { useLocation } from 'react-router-dom'
import {
  addAsset,
  updateAsset,
} from '../../../../redux/features/view-chat-of-accounts/accountsAsynActions'
import { requestGenerator } from '../../../../utils/payloadGenerator'
import Loader from '../../spinner/Loader'
import {
  ADD_ASSETDATA,
  UPDATE_ASSETDATA,
} from '../../../../constants/asyncActionsType'
import { setGenerateCode } from '../../../../redux/features/view-chat-of-accounts/accountsSlice'
import { t } from 'i18next'

interface ILevel5ModalProps {
  handleClose?: any
  popData?: string | any
  formData?: any
  handleSuccess?: any
}

const Level5Modal: FC<ILevel5ModalProps> = ({
  handleClose,
  popData,
  formData,
  handleSuccess,
}) => {
  const { dropdownAttempt, getGenerateData, loading } = useAppSelector(
    (state) => state.accounts
  )
  const {
    register,
    reset,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm<IaddLevel5Validators>({})

  const dispatch = useAppDispatch()
  const location = useLocation()

  const onSubmit: SubmitHandler<IaddLevel5Validators> = (data) => {
    const init = {
      name: data?.name,
      code: getGenerateData?.code,
      parentLevelTypeId: location?.state?.levelID?.parent_Level_Type_Id,
      isFinalLevel: true,
      creditOrDebit: data?.creditOrDebit,
      levelId: 5,
      level_one: getGenerateData?.code?.toString().substring(0, 1),
      level_two: getGenerateData?.code?.toString().substring(0, 3),
    }

    if (formData?._id) {
      const editPaylod = {
        id: formData?._id,
        data: init,
      }
      dispatch(updateAsset(requestGenerator(editPaylod))).then((e) => {
        if (e.type === `${UPDATE_ASSETDATA}/fulfilled`) {
          handleSuccess && handleSuccess()
        }
      })
    } else {
      dispatch(addAsset(requestGenerator(init))).then((e) => {
        if (e.type === `${ADD_ASSETDATA}/fulfilled`) {
          handleSuccess && handleSuccess()
        }
      })
    }
    handleClose()
  }
  const handleReset = () => {
    reset()
  }
  useEffect(() => {
    if (formData?.name) {
      setValue(Level5_NAME, formData?.name)

      setValue(Level5_LIST, formData?.creditOrDebit)
    }
  }, [formData])
  useEffect(() => {
    return () => {
      dispatch(setGenerateCode({}))
    }
  }, [])
  return (
    <>
      {loading && <Loader />}
      <div>
        <h1 className={styles.levelModalHeading}>
          {popData ? `Add ${dropdownAttempt?.name} at Level-5` : ''}
        </h1>
        <hr className={styles.levelDivider} />
        <form onSubmit={handleSubmit(onSubmit)} className={styles.addlevelForm}>
          <div className={styles.formFieldColumn}>
            <div className={styles.formFieldContainer}>
              <div className={styles.inputFieldContainer}>
                <label className={styles.formLabel}>
                  {' '}
                  {t('ViewChartOfAccount.Code')}
                </label>
                <input
                  type="text"
                  placeholder={t('ViewChartOfAccount.Code') || 'Code'}
                  className={styles.inputField}
                  value={getGenerateData?.code}
                  disabled
                  {...register(Level5_CODE, addLevel5Validators[Level5_CODE])}
                />
                <div className={styles.checkboxContainerBlank}></div>
              </div>
            </div>

            <div className={styles.formFieldContainer}>
              <div className={styles.inputFieldContainer}>
                <label className={styles.formLabels}>
                  {t('PatientEMR.Name')}
                  <span className="asterick">*</span>
                </label>
                <div className={styles.fieldErrorContainer}>
                  <input
                    type="text"
                    placeholder={
                      t('SupplierMaster.name_placeholder') || 'Enter Name'
                    }
                    className={styles.inputFields}
                    {...register(Level5_NAME, addLevel5Validators[Level5_NAME])}
                    onChange={(e) => trimValue(e)}
                  />
                  <div className={styles.errorContainer}>
                    <span className={styles.extraSpan}></span>
                    {errors[Level5_NAME] && (
                      <p className="dashboardFormError">
                        {errors[Level5_NAME].message}
                      </p>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.formFieldContainer}>
              <div className={styles.inputFieldContainer}>
                <label className={styles.formLabels}></label>
                <select
                  className={styles.selectInputField}
                  {...register(Level5_LIST, addLevel5Validators[Level5_LIST])}
                >
                  <option value="">
                    {' '}
                    {t('ViewChartOfAccount.Select_Debit_or_Credit')}
                  </option>
                  {AssetsArray?.map((AssetsArray, index) => (
                    <option value={AssetsArray} key={index}>
                      {AssetsArray}
                    </option>
                  ))}
                </select>
              </div>
              <div className={styles.errorContainer}>
                <span className={styles.extraSpanSelect}></span>
                {errors[Level5_LIST] && (
                  <p className="dashboardFormError">
                    {errors[Level5_LIST].message}
                  </p>
                )}
              </div>
            </div>
          </div>
          <div className={styles.formButtonContainer}>
            <Button
              title={t('Common.Submit') || 'Submit'}
              type="submit"
              customClass={styles.submitButton}
            />
            <Button
              title={t('BranchSetup.Reset') || 'Reset'}
              type="reset"
              handleClick={() => handleReset()}
              customClass={styles.resetButton}
            />
          </div>
        </form>
      </div>
    </>
  )
}

export default Level5Modal
