import { FC, useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Table from '../../../components/common/table/Table'
import { masterTableHeaderData } from '../../../constants/data'
import Popup from '../../../components/common/popup/Popup'
import AddCategoryModal from '../../../components/common/modal/add-category-modal/AddCategoryModal'
import { useAppDispatch, useAppSelector } from '../../../hooks'
import {
  getAllMasterTableCategory,
  updateStatusMasterTableCategory,
} from '../../../redux/features/master-table-category/MasterTableCategoryAsyncActions'
import { requestGenerator } from '../../../utils/payloadGenerator'
import Pagination from '../../../components/common/pagination/Pagination'
import StatusConfirmationPopup from '../../../components/common/modal/status-confirmation-popup/StatusConfirmationPopup'
import Loader from '../../../components/common/spinner/Loader'
import { SearchButton } from '../../../components/common/svg-components'
import { debounce } from 'lodash'
import { trimValue } from '../../../utils/utils'
import styles from './ManageMasterNew.module.scss'
import StatusConfirmationPopupV4 from '../../../components/common/modal/status-confirmation-popup/statusConfirmationPopupV4'
import { t } from 'i18next'

interface IManageMasterNew {
  setSearchMedicalCenter?: (value: string) => void
  searchMedicalCenter?: string
  customClass?: string
  customClassInput?: string
  placeHolder?: string
}

const ManageMasterNew: FC<IManageMasterNew> = ({
  customClass,
  customClassInput,
  placeHolder,
}) => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const { isLoading, masterCategoryData, isStatusUpdated } = useAppSelector(
    (state) => state.masterTableCategory
  )
  const [searchMedicalCenter, setSearchMedicalCenter] = useState<any>('')
  const [showAddModal, setShowAddModal] = useState<boolean>(false)
  const [addModalData, setAddModalData] = useState({})
  const [toggle, setToggle] = useState<boolean>(false)
  const [toggleValue, setToggleValue] = useState()
  const [toggleData, setToggleData] = useState<any>({})
  const [confirm, setConfirm] = useState<boolean>(false)
  const [searchValue, setSearchValue] = useState('')
  /* Pagination Dependency */
  const [dataPerPage, setDataPerPage] = useState<number>(10)
  const [totalPage, setTotalPage] = useState<number>(0)
  const [pageIndex, setPageIndex] = useState<number>(1)
  const [totalData, setTotalData] = useState(0)
  /* Pagination Dependency */

  // Debounce Search String
  const searchText = useCallback(
    debounce(
      (text: string): void =>
        setSearchMedicalCenter && setSearchMedicalCenter(text),
      500
    ),
    [setSearchMedicalCenter]
  )

  // function for creating Page Index Array
  const pageIndexArray = () => {
    let pageIndexOptions = []
    for (let i = 1; i <= totalPage; i++) {
      pageIndexOptions.push(i)
    }
    return pageIndexOptions
  }
  const pageIndexOptions = pageIndexArray()

  // add modal close
  const handleModalClose = () => {
    setShowAddModal(false)
    setAddModalData({})
    // setSearchValue('')
  }

  // getAllMasterCategorylist Api
  useEffect(() => {
    let payloadData = {
      page: pageIndex,
      pageSize: dataPerPage,
      search: searchValue,
      is_active: '',
    }
    dispatch(getAllMasterTableCategory(requestGenerator(payloadData))).then(
      (result) => {
        setTotalPage(result.payload.lastPage)
        setTotalData(result.payload.total)
      }
    )
  }, [dispatch, dataPerPage, pageIndex, isStatusUpdated])

  // Master Category record getbyId
  const handleGetMcById = (item: any) => {
    if (item.category_name === 'APPOINTMENT_STATUS') {
      item &&
        navigate(`/mastertablemanagenew/manageappoinmentstatus`, {
          state: {
            id: item,
          },
        })
    } else if (item.category_name === 'ASSIGN_TAG') {
      item &&
        navigate(`/mastertablemanagenew/manageassigntag`, {
          state: {
            id: item,
          },
        })
    } else if (item.category_name === 'INVENTORY_UNIT_TYPE') {
      item &&
        navigate(`/mastertablemanagenew/manageinventorytype`, {
          state: {
            id: item,
          },
        })
    } else if (item.category_name === 'PAYMENT_MODE') {
      item &&
        navigate(`/mastertablemanagenew/managepaymentmode`, {
          state: {
            id: item,
          },
        })
    } else {
      item &&
        navigate(`/mastertablemanagenew/managecategoryvalues`, {
          state: {
            id: item,
          },
        })
    }
  }

  // handle toggle
  const handleToggleStatusModal = (item: any) => {
    // setSearchValue('')
    setConfirm(!confirm)
    setToggleData(item)
    setToggle(item.is_active)
    setToggleValue(item?._id)
  }

  const handleInputSearch = () => {
    setPageIndex(1)
    if (searchValue?.length > 0) {
      const requestData = {
        search: searchValue,
        pageSize: dataPerPage,
        page: 1,
        is_active: '',
      }
      dispatch(getAllMasterTableCategory(requestGenerator(requestData))).then(
        (result) => {
          setTotalPage(result.payload.lastPage)
          setTotalData(result.payload.total)
        }
      )
    }
  }

  const handleStatus = () => {
    const statusPayload = {
      id: toggleData?._id,
      data: {
        is_active: !toggleData?.is_active,
      },
    }
    let payloadData = {
      page: pageIndex,
      pageSize: dataPerPage,
      search: searchValue,
      is_active: '',
    }
    dispatch(
      updateStatusMasterTableCategory(requestGenerator(statusPayload))
    ).then((e) => {
      if (
        (e.type = `masterTableManagementCategory/updateStatusCategory/rejected/fulfilled`)
      ) {
        setConfirm(false)

        dispatch(getAllMasterTableCategory(requestGenerator(payloadData))).then(
          (result) => {
            setTotalPage(result.payload.lastPage)
            setTotalData(result.payload.total)
          }
        )
      }
    })
  }

  return (
    <>
      {isLoading && <Loader />}
      {showAddModal && (
        <Popup
          Children={AddCategoryModal}
          popData={addModalData}
          handleClose={() => handleModalClose()}
          setModelOpenClose={setShowAddModal}
        />
      )}

      {confirm && (
        <Popup
          popData="Category"
          Children={StatusConfirmationPopupV4}
          handleClose={() => {
            setConfirm(false)
          }}
          handleYes={handleStatus}
        />
      )}

      <div className={styles.mainContainer}>
        <div className={styles.searchContainer}>
          <div className={styles.inputFieldContainer}>
            <input
              type="text"
              className={styles.inputSearchContainer}
              placeholder={t('ManageCategory.Search') || 'Search by category'}
              value={searchValue}
              onKeyDown={(e: any) => {
                if (e?.key === 'Enter') {
                  handleInputSearch()
                }
              }}
              onChange={(e) => {
                trimValue(e)
                setSearchValue(e.target.value)
                if (searchValue !== '' && e.target.value === '') {
                  setPageIndex(1)
                  setDataPerPage(10)
                  const requestData = {
                    search: '',
                    page: 1,
                    pageSize: 10,
                    is_active: '',
                  }
                  dispatch(
                    getAllMasterTableCategory(requestGenerator(requestData))
                  ).then((result) => {
                    setTotalPage(result.payload.lastPage)
                    setTotalData(result.payload.total)
                  })
                }
              }}
            />

            <SearchButton
              handleClick={() => {
                if (!!searchValue) {
                  handleInputSearch()
                }
              }}
              customClass={styles.inputSearchButton}
            />
          </div>
        </div>

        <div className={styles.tableContainer}>
          <Table
            tableHeaderData={masterTableHeaderData}
            tableRowData={masterCategoryData}
            handleAction={handleGetMcById}
            handleActiveMC={handleToggleStatusModal}
          />
        </div>
        {totalData > 10 && (
          <Pagination
            setDataPerPage={setDataPerPage}
            pageIndexOptions={pageIndexOptions}
            pageIndex={pageIndex}
            setPageIndex={setPageIndex}
            pageSize={dataPerPage}
          />
        )}
      </div>
    </>
  )
}

export default ManageMasterNew
