import { t } from "i18next";

export const ViewServicesTableData: any = [
  {
    Header: t("DentistDiagnosis.PROCEDURE"),
    Cell: (props: any) => {
      return <>{props.row.original.procedure_id.name ?? "-"}</>;
    },
  },
  {
    Header: t("DentistDiagnosis.PROCEDURE SUBTYPE"),
    Cell: (props: any) => {
      return <>{props.row.original.procedure_id.procedure_subtype ?? "-"}</>;
    },
  },
  {
    Header: t("TreatmentTableHeader.SERVICES"),
    Cell: (props: any) => {
      return <>{props.row.original?.service_id?.name ?? "-"}</>;
    },
  },
  {
    Header: t("DentistDiagnosis.SELECTED TOOTHS"),
    Cell: (props: any) => {
      let tooth_numbers = props.row.original.selected_tooths
        ? props.row.original.selected_tooths
            .map((item: any) => item.display_tooth_number)
            .join(",")
        : " - ";
      return <>{tooth_numbers}</>;
    },
  },
  {
    Header: t("TreatmentTableHeader.SESSIONS"),
    Cell: (props: any) => {
      return <>{props.row.original.sessions ?? ""}</>;
    },
  },
  {
    Header: t("TreatmentTableHeader.SESSIONS#"),
    Cell: (props: any) => {
      return <>{props.row.original.sessionsIndex ?? ""}</>;
    },
  },
  {
    Header: t("RcptTableHeader.STATUS"),
    Cell: (props: any) => {
      return (
        <div style={{ textTransform: "capitalize" }}>
          {props.row.original.status ?? ""}
        </div>
      );
    },
  },
  {
    Header: t("InventoryItemTable.QUANTITY"),
    Cell: (props: any) => {
      return <>{props.row.original.quantity ?? "- "}</>;
    },
  },
  {
    Header: t("ServiceTable.UNIT PRICE (E)"),
    Cell: (props: any) => {
      return <>{props.row.original.unit_price ?? " - "}</>;
    },
  },
  {
    Header: t("InsurancePlan_Form.PRICE"),
    Cell: (props: any) => {
      return <>{props.row.original.price ?? " - "}</>;
    },
  },
  {
    Header: t("InsuranceMaster.DISCOUNT"),
    Cell: (props: any) => {
      return <>{props.row.original.discount ?? " - "}</>;
    },
  },
  {
    Header: t("TreatmentTableHeader.ATTENDEDBY"),
    Cell: (props: any) => {
      return <>{props.row.original.attended_by_id.name ?? ""}</>;
    },
  },
];
