import { FC, useState } from 'react'
import { colors } from '../../../../constants/color'
import Divider from '../../../../components/common/divider/Divider'
import { CloseIcon } from '../../../../components/common/svg-components'
import styles from './deletePopup.module.scss'
import { useAppDispatch } from '../../../../hooks'
import Button from '../../../../components/common/button/Button'
import { requestGenerator } from '../../../../utils/payloadGenerator'
import {
  deleteLeave,
  getAllLeave,
} from '../../../../redux/features/leave/leaveAsyncActions'
import { DELETE_LEAVE } from '../../../../constants/asyncActionsType'
import { t } from 'i18next'
interface IDeletePopup {
  popData?: any
  handleClose?: any
}
const DeletePopup: FC<IDeletePopup> = ({ popData, handleClose }) => {
  const dispatch = useAppDispatch()
  const [type, setType] = useState<string>('employee')
  const handleYes = () => {
    handleClose()
    let deletePayload = {
      id: popData.id,
    }
    dispatch(deleteLeave(requestGenerator(deletePayload))).then((e) => {
      if (e.type === `${DELETE_LEAVE}/fulfilled`) {
        let payloadData = {
          is_active: true,
          page: 1,
          pageSize: 100,
        }
        dispatch(getAllLeave(requestGenerator(payloadData)))
      }
    })
  }
  return (
    <>
      <div
        className={styles.deletePopupContainer}
        onClick={(e) => {
          e.stopPropagation()
        }}
      >
        <CloseIcon
          customClass={styles.closeIconStyle}
          fillColor={colors.green1}
          handleClick={() => handleClose()}
        />
        <div className={styles.deleteContainer}>
          <p className={styles.title}>{t('Receptionist Dashboard.Delete')}</p>
          <Divider customClass={styles.dividerStyle} />

          <div className={styles.confirmationContainer}>
            <p className={styles.confirmationText}>
              {t('Common.SureWantDelete')}
            </p>
            <div className={styles.buttonContainer}>
              <Button
                title={t('Common.Yes') || 'Yes'}
                customClass={styles.yesBtn}
                handleClick={handleYes}
              />
              <Button
                title={t('Common.No') || 'No'}
                customClass={styles.noBtn}
                handleClick={() => handleClose()}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default DeletePopup
