import { useTranslation } from 'react-i18next'
import Button from '../../../components/common/button/Button'
import styles from './styles.module.scss'
import { Label } from '../../../components/common/label'
import Select from 'react-select'
import { useEffect, useState } from 'react'
import { DropdownIndicator } from '../../../components/common/dropdown-indicator/DropdownIndicator'
import { useAppDispatch, useAppSelector } from '../../../hooks'
import {
  IImportTreatmentService,
  fileType,
} from '../../../interfaces/interfaces'
import { useForm } from 'react-hook-form'
import { DEPT_ID, SERVICE_FILE } from '../import-constant'
import { importValidation } from './validators'
import AttachFiles from '../../../components/common/attach-files/single-file/AttachSingleFile'
import { dataURI } from '../../../utils/utils'
import { requestGenerator } from '../../../utils/payloadGenerator'
import { importTreatementServicesUtility } from '../../../redux/features/import-utility/importUtilityAsyncActions'
import { getAllDepartment } from '../../../redux/features/department/departmentAsyncActions'
import { clearImportData } from '../../../redux/features/import-utility/importUtilitySlice'

const TreatmentServiceImport = () => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const [deptOptions, setDeptOptions] = useState([])
  const { departmentData } = useAppSelector((state) => state.department)
  const [serviceFile, setServiceFile] = useState({ name: '', data_uri: '' })
  const { inValidData } = useAppSelector((state) => state.import)

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    trigger,
    formState: { errors },
  } = useForm<IImportTreatmentService>({})

  useEffect(() => {
    let data = {
      search: '',
      page: 1,
      pageSize: 1000,
      reqType: 'SERVICE_MASTER',
    }
    dispatch(getAllDepartment(requestGenerator(data)))
  }, [dispatch])

  useEffect(() => {
    if (departmentData && departmentData?.length > 0) {
      const departments = departmentData?.map((item: any) => {
        return {
          label: item?.name,
          value: item?._id,
        }
      })
      setDeptOptions(departments)
      setValue(DEPT_ID, departments[0])
    }
  }, [departmentData, setValue])

  const serviceField = watch(SERVICE_FILE)
  const fileName = serviceField?.[0]

  useEffect(() => {
    const fileList: fileType = { name: '', data_uri: '' }
    const getDataURI = async (fileName: File) => {
      try {
        const result = await dataURI(fileName)
        fileList.data_uri = result
        fileList.name = fileName.name

        setServiceFile(fileList)
      } catch (error) {
        console.log({ error })
      }
    }
    if (fileName) {
      getDataURI(fileName)
    }
  }, [fileName])

  const onSubmit = (data: any) => {
    const payload = {
      [DEPT_ID]: data[DEPT_ID]?.value,
      [SERVICE_FILE]: serviceFile,
    }
    dispatch(importTreatementServicesUtility(requestGenerator(payload))).then(
      (e) => {
        setValue(SERVICE_FILE, undefined)
        setServiceFile({ name: '', data_uri: '' })
      }
    )
  }

  useEffect(() => {
    if (inValidData?.length !== 0 && serviceFile.name) {
      dispatch(clearImportData())
    }
  }, [dispatch, serviceFile])

  return (
    <form className={styles.container} onSubmit={handleSubmit(onSubmit)}>
      <div className={styles.importFieldContainer}>
        <div className={styles.inputFieldContainer}>
          <Label
            labelText={t('IPDBooking.Department')}
            requiredField={true}
            flexBasis="30%"
          />
          <div className={styles.inlineItems}>
            <Select
              className={styles.selectInputField}
              placeholder={
                t('IPDBooking.SelectDepartment') || `Select Department`
              }
              closeMenuOnSelect={true}
              value={watch(DEPT_ID)}
              {...register(DEPT_ID, importValidation[DEPT_ID])}
              onChange={(e: any) => {
                setValue(DEPT_ID, e)
                trigger(DEPT_ID)
              }}
              components={{ DropdownIndicator }}
              options={deptOptions}
              isSearchable={true}
              maxMenuHeight={200}
            />
            {errors[DEPT_ID]?.message && (
              <p className="dashboardFormError">
                {errors[DEPT_ID]?.message as any}
              </p>
            )}
          </div>
        </div>
      </div>
      <div className={styles.importFieldContainer}>
        <div className={styles.inputFieldContainer}>
          <Label
            labelText={t('Common.Upload')}
            requiredField={true}
            flexBasis="30%"
          />
          <div className={styles.inlineItems}>
            <AttachFiles
              register={register}
              fileKey={SERVICE_FILE}
              id={SERVICE_FILE}
              fileList={serviceFile}
              validation={importValidation[SERVICE_FILE]}
              isName={true}
              isDocument={true}
              showCsv={true}
            />
            {errors[SERVICE_FILE] && (
              <p className="errorText">{errors[SERVICE_FILE].message as any}</p>
            )}
          </div>
        </div>
      </div>
      <div className={styles.buttonContainer}>
        <Button title={t('Common.Submit') || 'Submit'} />
      </div>
    </form>
  )
}

export default TreatmentServiceImport
