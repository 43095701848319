import moment from 'moment'
import styles from './tableData.module.scss'
import { t } from 'i18next'

export const insurancePlanPopupData: any = [
  {
    Header: t('InsuranceHeader.MEMBERSHIP NO'),
    accessor: 'policy_no',
  },
  {
    Header: t('InsuranceHeader.INSURANCE PLAN'),
    accessor: 'insurance_plan',
  },
  {
    Header: t('InsuranceHeader.DEPARTMENT'),
    Cell: (props: any) => {
      return (
        <>
          {props?.row?.original?.departments?.length > 0 ? (
            <span
              className={styles.blueLinkText}
              onClick={() => {
                props?.onPopClose(props?.row?.original?.departments)
              }}
            >
              {t('Common.View')}
            </span>
          ) : (
            '-'
          )}
        </>
      )
    },
  },
  {
    Header: t('InsuranceHeader.EXPIRY DATE'),
    Cell: ({ row }: any) => {
      const originalDate = row?.original?.expiry_date
      const formattedDate = moment(originalDate).format('DD MMM YYYY')
      return <>{formattedDate}</>
    },
  },
  {
    Header: t('InsuranceHeader.CLAIM TYPE'),
    accessor: 'claim_type',
  },

  {
    Header: t('TimelineHeader.ATTACHMENTS'),
    Cell: (props: any) => {
      return (
        <>
          {props?.row?.original?.attachments?.length > 0 ? (
            <span
              className={styles.blueLinkText}
              onClick={() => {
                props?.onClick(props?.row?.original?.attachments)
              }}
            >
              {t('Common.View')}
            </span>
          ) : (
            '-'
          )}
        </>
      )
    },
  },
]
