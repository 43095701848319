
//Add Leaves Encashment Form Details
export const SELECT_EMPLOYEE = "select_employee";
export const  SALARY = "salary";
export const PERIOD = "period";
export const  LEAVES_AVAILABLE = "leaves_available";
export const DAYS = "days";
export const AMOUNT = "amount";
export const SELECT_PAID_DATE = "select_paid_date";
export const STATUS = "status";
