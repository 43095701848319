import { FC } from "react";
import QuickAccess from "./quick-access/page";

interface IPharmacyStore {}

const PharmacyStore: FC<IPharmacyStore> = () => {
  return (
      <QuickAccess />
  );
};
export default PharmacyStore;
