import { FC, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import {
  leaveFormDataTableDummyData,
  leaveFormDataTableHeaderData,
} from './leaveFormDataTableData'
import { trimValue } from '../../../../utils/utils'
import { SearchIcon } from '../../../../components/common/svg-components'
import Button from '../../../../components/common/button/Button'
import { utils, writeFile, WorkBook, WorkSheet } from 'xlsx'
import Pagination from '../../../../components/common/pagination/Pagination'
import styles from './leaveFormData.module.scss'
import TableV2 from '../../../../components/common/table/tableV2/TableV2'
import { t } from 'i18next'

interface ILeaveFormData {}

const LeaveFormData: FC<ILeaveFormData> = () => {
  const navigate = useNavigate()
  const [showDelete, setShowDelete] = useState<boolean>(false)
  const [dataPerPage, setDataPerPage] = useState<number>(10)
  const [totalPage, setTotalPage] = useState<number>(0)
  const [pageIndex, setPageIndex] = useState<number>(1)

  // function for creating Page Index Array
  const pageIndexArray = () => {
    let pageIndexOptions = []
    for (let i = 1; i <= totalPage; i++) {
      pageIndexOptions.push(i)
    }
    return pageIndexOptions
  }
  const pageIndexOptions = pageIndexArray()

  // Delete modal close
  const handleDeleteModalClose = () => {
    setShowDelete((prevState) => !prevState)
  }

  // Table data to Excel Sheet
  const handleDownloadExcel = () => {
    const worksheet: WorkSheet = utils.json_to_sheet(
      leaveFormDataTableDummyData
    )
    const workbook: WorkBook = utils.book_new()
    utils.book_append_sheet(workbook, worksheet, 'leave-encashment')

    //Download the excel sheet
    const excelBuffer = writeFile(workbook, 'leave-encashment.xlsx', {
      type: 'buffer',
    })
    const blob = new Blob([excelBuffer], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    })
    const link = document.createElement('a')
    link.download = 'leave-encashment.xlsx'
    link.click()
  }

  return (
    <>
      {/* Delete Modal */}
      {/* {showDelete && (
        <Popup
          Children={DeletePopup}
          handleClose={() => handleDeleteModalClose()}
        />
      )} */}
      {/* Form */}
      <div
        className={styles.leaveFormDataContainer}
        onClick={(e) => {
          e.stopPropagation()
        }}
      >
        {/* search */}
        <div className={styles.searchMainContainer}>
          <div className={styles.searchContainer}>
            <input
              className={styles.searchInput}
              type="text"
              placeholder={t('ServiceMaster.Search') || 'Search'}
              onChange={(e) => {
                trimValue(e)
              }}
            />
            <div className={styles.searchButton}>
              <SearchIcon fillColor={'#ffffff'} />
            </div>
          </div>
          {/* Search Button Container */}
          <div className={styles.searchBtnContainer}>
            <Button
              title={t('InvoiceService.Add') || 'Add'}
              customClass={styles.addBtn}
              handleClick={() => {
                navigate('/leave-management')
              }}
            />
            {/* Button */}
            <Button
              title={t('LeaveManagement.Download_Excel') || 'Download Excel'}
              customClass={styles.excelBtn}
              type="button"
              handleClick={() => handleDownloadExcel()}
            />
          </div>
        </div>
        {/* table */}
        <div className={styles.tableContainer}>
          <TableV2
            tableHeaderData={leaveFormDataTableHeaderData}
            tableRowData={leaveFormDataTableDummyData}
            handleClick={handleDeleteModalClose}
          />
        </div>
        {/* Pagination */}
        {leaveFormDataTableDummyData &&
        leaveFormDataTableDummyData.length !== 0 ? (
          <Pagination
            setDataPerPage={setDataPerPage}
            pageIndexOptions={pageIndexOptions}
            pageIndex={pageIndex}
            setPageIndex={setPageIndex}
          />
        ) : (
          ''
        )}
      </div>
    </>
  )
}

export default LeaveFormData
