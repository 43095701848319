import { t } from 'i18next'
import { DeleteIcon, EyeIcon } from '../../components/common/svg-components'
import { attachmentName } from '../../utils/utils'
import { colors } from '../color'

export const docUploadTableData: any[] = [
  {
    Header: t('DocUploadHeader.FILE NAME'),
    accessor: 'name',
    Cell: (props: any) => {
      const name = props.row?.original
      const originalName = attachmentName(name)
      return <>{originalName ? originalName : '-'}</>
    },
  },

  {
    Header: t('DocUploadHeader.ACTION'),
    Cell: (props: any) => {
      const doc = props?.row?.original
      return (
        <>
          <EyeIcon
            fillColor={colors.grey1}
            height={20}
            width={20}
            style={{ cursor: 'pointer', marginRight: '10px' }}
            handleClick={() => {
              props.onClick(doc)
            }}
          />
          <DeleteIcon
            fillColor={colors.grey1}
            height={20}
            width={20}
            handleClick={() => {
              props.onPopClose(doc)
            }}
          />
        </>
      )
    },
  },
]
