import { t } from 'i18next'
import moment from 'moment'

export const CrmSentHeaderData: any = [
  {
    Header: t('RcptTableHeader.DATE'),
    accessor: 'date',
    Cell: ({ row }: any) => {
      return moment(row?.appointment_date).format('DD/MM/YYYY') ?? '-'
    },
  },
  {
    Header: t('RcptTableHeader.FILE NO'),
    accessor: 'emr_no',
  },
  {
    Header: t('PatientHistoryFormHeader.PATIENT NAME'),
    accessor: 'patient_name',
  },
]
