// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.style_downloadButton__WoZZX {
  color: var(--blue1);
  font-size: var(--font-12);
  font-weight: var(--font-normal);
  cursor: pointer;
  border: 1px solid var(--blue1);
  border-radius: 20px;
  padding: 4px 14px;
}

.style_tableContainer__MQNk9 {
  width: 100%;
  white-space: nowrap;
  overflow: auto;
  height: 370px;
  margin-top: 35px;
}
.style_tableContainer__MQNk9::-webkit-scrollbar {
  display: block;
  width: 7px;
  height: 7px;
}
.style_tableContainer__MQNk9::-webkit-scrollbar-track {
  background: transparent;
}
.style_tableContainer__MQNk9::-webkit-scrollbar-thumb {
  background-color: var(--grey7);
  border-radius: 10px;
  border: 1px solid var(--grey7);
}

.style_note__aVUT3 {
  color: var(--grey4);
  font-size: var(--font-14);
  font-weight: var(--font-medium);
  text-align: center;
}`, "",{"version":3,"sources":["webpack://./src/components/common/modal/radiology-report-modal/download-radiology-attachments/style.module.scss"],"names":[],"mappings":"AAEA;EACI,mBAAA;EACA,yBAAA;EACA,+BAAA;EACA,eAAA;EACA,8BAAA;EACA,mBAAA;EACA,iBAAA;AADJ;;AAIA;EACI,WAAA;EACA,mBAAA;EACA,cAAA;EACA,aAAA;EACA,gBAAA;AADJ;AAEI;EACE,cAAA;EACA,UAAA;EACA,WAAA;AAAN;AAEI;EACE,uBAAA;AAAN;AAGI;EACE,8BAAA;EACA,mBAAA;EACA,8BAAA;AADN;;AAME;EACE,mBAAA;EACA,yBAAA;EACA,+BAAA;EACA,kBAAA;AAHJ","sourcesContent":["@import \"../../../../../styles/mixin.scss\";\n\n.downloadButton{\n    color: var(--blue1);\n    font-size: var(--font-12);\n    font-weight: var(--font-normal);\n    cursor: pointer;\n    border: 1px solid var(--blue1);\n    border-radius: 20px;\n    padding: 4px 14px;\n}\n\n.tableContainer {\n    width: 100%;\n    white-space: nowrap;\n    overflow: auto;\n    height: 370px;\n    margin-top: 35px;\n    &::-webkit-scrollbar {\n      display: block;\n      width: 7px;\n      height: 7px;\n    }\n    &::-webkit-scrollbar-track {\n      background: transparent;\n    }\n  \n    &::-webkit-scrollbar-thumb {\n      background-color: var(--grey7);\n      border-radius: 10px;\n      border: 1px solid var(--grey7);\n    }\n  }\n\n\n  .note{\n    color: var(--grey4);\n    font-size: var(--font-14);\n    font-weight: var(--font-medium);\n    text-align: center;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"downloadButton": `style_downloadButton__WoZZX`,
	"tableContainer": `style_tableContainer__MQNk9`,
	"note": `style_note__aVUT3`
};
export default ___CSS_LOADER_EXPORT___;
