import { createSlice } from '@reduxjs/toolkit'
import { getAllBalanceSheet } from './balanceSheetAsyncActions'

export interface IbalanceSheet {
  isLoading: boolean
  allBalanceSheetData: []
  allBalanceSheetListDataObject: {}
  error: any
  isStatusUpdated: boolean
}
export const initialState: IbalanceSheet = {
  isLoading: false,
  allBalanceSheetData: [],
  allBalanceSheetListDataObject: {},
  isStatusUpdated: true,
  error: null,
}

export const balanceSheetSlice = createSlice({
  name: 'balanceSheet',
  initialState,
  reducers: {
    clearbalanceSheetData: (state) => {
      state.allBalanceSheetData = []
    },
  },
  extraReducers(builder) {
    // GET ALL Balance sheet
    builder
      .addCase(getAllBalanceSheet.pending, (state) => {
        state.isLoading = true
        state.isStatusUpdated = false
      })
      .addCase(getAllBalanceSheet.fulfilled, (state, action) => {
        state.isLoading = false
        state.isStatusUpdated = false
        state.allBalanceSheetData = action.payload?.data
        state.allBalanceSheetListDataObject = action.payload
      })
      .addCase(getAllBalanceSheet.rejected, (state, error) => {
        state.isLoading = false
        state.error = error
        state.isStatusUpdated = false
      })
  },
})

export const { clearbalanceSheetData } = balanceSheetSlice.actions
export default balanceSheetSlice.reducer
