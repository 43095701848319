import moment from "moment";
import styles from "./ongoingtreatmentplsnpopup.module.scss";
import { t } from "i18next";

export const ongoingTreatmentPlanPopupHeaderData: any = [
  {
    Header: t("PatientHistoryTableHeader.APPOINTMENT DT."),
    accessor: (row: any) => {
      return moment(row?.date).format("DD-MMM-YYYY") ?? "-";
    },
  },
  {
    Header: t("DentistDiagnosis.Prescription Plan"),
    accessor: (row: any) => {
      return row?.prescription_name ?? "-";
    },
  },
  {
    Header: t("Treatment.Services"),
    Cell: (props: any) => {
      return (
        <p
          className={styles.blueLinkText}
          onClick={() => props.onRowClick(props.row.original)}
        >
          {t("Common.View")}
        </p>
      );
    },
  },
];
