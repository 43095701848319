import { FC, useState, useEffect, useRef } from 'react'
import Button from '../../../components/common/button/Button'
import { SubmitHandler, useForm } from 'react-hook-form'
import { manageAttendanceValidator } from './manageAttendanceValidator'
import {
  DEPARTMENT,
  SELECT_MONTH,
  SELECT_YEAR,
  SUB_COMPANY,
} from '../../../constants/manageAttendanceConstants'
import {
  Cols,
  IManageAttendanceForm,
} from '../../../interfaces/manageAttendanceInterface'
import Pagination from '../../../components/common/pagination/Pagination'
import Popup from '../../../components/common/popup/Popup'
import MarkAllDaysModal from './mark-all-day-modal/MarkAllDaysModal'
import Select from 'react-select'
import makeAnimated from 'react-select/animated'
import { Column } from 'react-table'
import { failure, monthOptions } from '../../../constants/data'
import ManageAttendanceModal from './ManageAttendanceModal'
import { useAppDispatch, useAppSelector } from '../../../hooks'
import { getMonthlyEmployeeAttendanceAction } from '../../../redux/features/attendance/attendanceAsyncAction'
import { requestGenerator } from '../../../utils/payloadGenerator'
import moment from 'moment'
import styles from './manageAttendance.module.scss'

import Loader from '../../../components/common/spinner/Loader'
import AttendanceList from './attendance- list/AttendanceList'
import {
  clearEmployIDList,
  clearSelectedEmployList,
  clearState,
  removeSelctedEmployes,
  setAttendenceOption,
  setSelectedEmployes,
  setSelectedMonthYear,
} from '../../../redux/features/attendance/attendanceSlice'
import {
  AbsentIcon,
  CheckIcon,
  OpenEyeIcon,
  PresentIcon,
  UncheckIcon,
} from '../../../components/common/svg-components'
import { colors } from '../../../constants/color'
import EmployeeLeavePopup from './EyeModal/Employleave'
import { setMessage } from '../../../redux/features/toast/toastSlice'
import TableV2 from '../../../components/common/table/tableV2/TableV2'
import { getAllDepartment } from '../../../redux/features/department/departmentAsyncActions'
import { t } from 'i18next'

interface IManageAttendance {}
const ManageAttendance: FC<IManageAttendance> = () => {
  const [showMarkDaysModal, setShowMarkDaysModal] = useState<boolean>(false)
  const [showMarkDaysModalsPopup, setShowMarkDaysModalsPopup] =
    useState<boolean>(false)
  const [dataPerPage, setDataPerPage] = useState<number>(10)
  const [totalPage, setTotalPage] = useState<number>(0)
  const [pageIndex, setPageIndex] = useState<number>(1)
  const [showleave, setShowLeave] = useState<boolean>(false)
  const [employMonthlyAttendenceDetails, setemployMonthlyAttendenceDetails] =
    useState<any>('')
  const [setshowTableBlock, setsetshowTableBlock] = useState(false)
  const [daysInMonth, setdaysInMonth] = useState('')
  const [dynamicArray, setdynamicArray] = useState<any>([])

  const [patientAttendenceModal, setpatientAttendenceModal] =
    useState<any>(false)
  const [onDesh, setOnDesh] = useState<any>('')
  const { departmentData } = useAppSelector((state) => state.department)
  const [totalData, setTotalData] = useState(0)
  const [showApi, setShowApi] = useState(false)

  // getAllDepartment API calls
  useEffect(() => {
    let payloadData = {
      search: '',
      page: 1,
      pageSize: 1000,
    }
    dispatch(getAllDepartment(requestGenerator(payloadData)))
  }, [pageIndex, dataPerPage])

  const [showAttendenceModal, setshowAttendenceModal] = useState(false)

  const [markeAttendenceChangeDate, setmarkeAttendenceChangeDate] = useState('')

  const yearOption = [
    {
      value: new Date().getFullYear() - 1,
      label: new Date().getFullYear() - 1,
    },
    {
      value: new Date().getFullYear(),
      label: new Date().getFullYear(),
    },
  ]

  const currentMonthInNum = new Date().getMonth() + 1
  const currentDateInNum = new Date().getDate()
  const currentYear = new Date().getFullYear()

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm<IManageAttendanceForm>({})

  const watchSelectMonth = watch(SELECT_MONTH)
  const watchSelectYear = watch(SELECT_YEAR)
  const watchSubCompany = watch(SUB_COMPANY)
  const watchDepartment = watch(DEPARTMENT)
  const createSubmitCondition =
    watchSelectMonth && watchSelectYear !== undefined

  // data fetch from use selector
  const {
    getMonthlyAttendance,
    isLoading,
    attendenceOption,
    selectedEmployList,
    selectedMonthYear,
    employIDList,
  } = useAppSelector((state) => state?.attendance)

  const { masterValueData } = useAppSelector((state) => state.login)
  const dispatch = useAppDispatch()

  const ref = useRef<any>()
  const ref2 = useRef<any>()

  const pageIndexArray = () => {
    let pageIndexOptions = []
    for (let i = 1; i <= totalPage; i++) {
      pageIndexOptions.push(i)
    }
    return pageIndexOptions
  }
  const pageIndexOptions = pageIndexArray()

  const animatedComponent = makeAnimated()

  const onSubmit: SubmitHandler<IManageAttendanceForm> = (data) => {
    dispatch(clearEmployIDList())
    dispatch(clearSelectedEmployList())
    setpatientAttendenceModal(false)
    setShowApi(false)
    let daysInMonth = moment(`${data?.select_year}-${data?.select_month}`)
      ?.daysInMonth()
      ?.toString()
    setdaysInMonth(daysInMonth)
    const payloadData = {
      sub_company: data?.sub_company || undefined,
      search: '',
      range: {
        fromDate: moment
          .utc(`${data?.select_month}/01/${data?.select_year}`)
          .startOf('month')
          .toISOString(),
        toDate: moment
          .utc(`${data?.select_month}/01/${data?.select_year}`)
          .endOf('month')
          .toISOString(),
      },
      department_id: data?.department_id,
      page: 1,
      pageSize: dataPerPage,
    }

    const monthName = function (monthNumber: any) {
      const date = new Date()
      date.setMonth(monthNumber - 1)
      return date.toLocaleString('en-US', {
        month: 'long',
      })
    }

    dispatch(
      setSelectedMonthYear({
        month: monthName(Number(data?.select_month)),
        year: data?.select_year,
        sub_company: data?.sub_company,
        department_id: data?.department_id,
      })
    )

    dispatch(
      getMonthlyEmployeeAttendanceAction(requestGenerator(payloadData))
    ).then((result: any) => {
      setTotalPage(result.payload.lastPage)
      setTotalData(result.payload.total)
      setPageIndex(1)
      if (
        result?.type ===
        'attendanceSlice/getMonthlyEmployeeAttendance/fulfilled'
      ) {
        setsetshowTableBlock(true)
        setShowApi(true)
      }
    })
  }

  const handleMarkDays = () => {
    setShowMarkDaysModal((prevState) => !prevState)
    setpatientAttendenceModal(false)
  }
  const handleMarkDaysDaata = () => {
    setShowMarkDaysModalsPopup(false)
    setpatientAttendenceModal(false)
    setshowAttendenceModal(false)
    dispatch(setAttendenceOption({}))
    setmarkeAttendenceChangeDate('')
  }

  // function for creating Page Index Array

  // function for creating Page Index Array
  useEffect(() => {
    const headerArraysFetch = (length: any) => {
      const arrayCreate = Array.from({ length: length }, (_, i) => i + 1)

      let tempArray: Column<Cols>[] = [
        {
          Header: '',
          accessor: 'check',
          Cell: (props: any) => {
            const handleSelectEmploye = (item: any, status: any) => {
              if (status === 'unCheck') {
                dispatch(setSelectedEmployes(props?.row?.original))
              } else {
                dispatch(removeSelctedEmployes(props?.row?.original?._id))
              }
            }

            const addedEmploy = employIDList?.some((item: any) => {
              return item == props?.row?.original?._id
            })

            return (
              <>
                {addedEmploy ? (
                  <CheckIcon
                    fillColor={colors.green1}
                    handleClick={() =>
                      handleSelectEmploye(props?.row?.original, 'check')
                    }
                    customClass={styles.centerAlign}
                  />
                ) : (
                  <UncheckIcon
                    fillColor={colors.grey2}
                    handleClick={() =>
                      handleSelectEmploye(props?.row?.original?._id, 'unCheck')
                    }
                    customClass={styles.centerAlign}
                  />
                )}
              </>
            )
          },
        },
        {
          Header: t('HrDocument.Employee') || 'Employee',
          accessor: 'name_en',
        },
      ]

      arrayCreate?.map((x: any) => {
        tempArray.push({
          Header: '' + x,
          Cell: (props: any) => {
            const dispatch = useAppDispatch()
            const attendenceArray = props?.row?.original?.attendance
            const dateObj = attendenceArray?.find((item: any) => {
              const getDateNum = new Date(item?.date)?.getDate()
              return getDateNum == x
            })

            const weekendArray = props?.row?.original?.weekends
            const weekendDateObj = weekendArray?.find((item: any) => {
              const getDateNumN = new Date(item)?.getDate()
              return getDateNumN == x
            })
            const gettingWeekendDateNum = new Date(weekendDateObj)?.getDate()

            const holidayList = props?.row?.original?.holiday
            const holidayDateObj = holidayList?.find((item: any) => {
              const getHolidayDateNum = new Date(item)?.getDate()
              return getHolidayDateNum == x
            })
            const gettingHolidayDateNum = new Date(holidayDateObj)?.getDate()

            const gettingDateNum = new Date(dateObj?.date)?.getDate()

            const currentM = new Date().getMonth() + 1

            const currentYear = new Date().getFullYear()

            const handleClick = (x: any) => {
              if (
                currentYear === watchSelectYear &&
                watchSelectMonth > currentM
              ) {
                dispatch(
                  setMessage({
                    message: t('Attendance.no_mark_future_dates'),
                    type: failure,
                  })
                )
                setpatientAttendenceModal(false)
              } else if (
                currentYear === watchSelectYear &&
                watchSelectMonth === currentM &&
                x > currentDateInNum
              ) {
                if (x > currentDateInNum) {
                  dispatch(
                    setMessage({
                      message: t('Attendance.no_mark_future_dates'),
                      type: failure,
                    })
                  )
                }
              } else {
                dispatch(
                  setAttendenceOption({ ...props?.row?.original, no: x })
                )
                setpatientAttendenceModal(true)
                setshowAttendenceModal(true)
                setOnDesh(x)
                setmarkeAttendenceChangeDate('')
              }
            }

            const handleClicks = () => {
              setShowMarkDaysModalsPopup(true)
              setemployMonthlyAttendenceDetails({
                ...props?.row?.original,
                no: x,
                selectedMonthYearObj: selectedMonthYear,
              })
            }

            const handlePresentMarkeAttendence = (x: any) => {
              setmarkeAttendenceChangeDate(x)
              dispatch(setAttendenceOption({ ...props?.row?.original, no: x }))
              setshowAttendenceModal(true)
            }

            const handleMarkeAbsentAttendenceChange = (x: any) => {
              setmarkeAttendenceChangeDate(x)
              dispatch(setAttendenceOption({ ...props?.row?.original, no: x }))
              setshowAttendenceModal(true)
            }
            return (
              <div>
                <>
                  {attendenceArray?.length ? (
                    <>
                      {holidayList?.length > 0 &&
                      gettingHolidayDateNum === x ? (
                        <p className={styles.holidayStyle}>H</p>
                      ) : gettingDateNum === x ? (
                        dateObj?.attendance === 'PRESENT' ? (
                          <div>
                            {props?.row?.original?._id ===
                              attendenceOption?._id &&
                            markeAttendenceChangeDate == x &&
                            showAttendenceModal ? (
                              <AttendanceList
                                handleChange={() => {
                                  handleClicks()
                                }}
                                onClick={props.onClick}
                              />
                            ) : (
                              <PresentIcon
                                handleClick={() =>
                                  handlePresentMarkeAttendence(x)
                                }
                              />
                            )}
                          </div>
                        ) : (
                          <div>
                            {markeAttendenceChangeDate == x &&
                            props?.row?.original?._id ===
                              attendenceOption?._id &&
                            showAttendenceModal ? (
                              <AttendanceList
                                handleChange={() => {
                                  handleClicks()
                                }}
                                onClick={props.onClick}
                              />
                            ) : dateObj?.isPaid === true ? (
                              <p
                                className={styles.leaveStyle}
                                onClick={() => {
                                  handleMarkeAbsentAttendenceChange(x)
                                }}
                              >
                                L
                              </p>
                            ) : (
                              <AbsentIcon
                                handleClick={() => {
                                  handleMarkeAbsentAttendenceChange(x)
                                }}
                              />
                            )}
                          </div>
                        )
                      ) : (
                        <>
                          {weekendArray?.length &&
                          gettingWeekendDateNum === x ? (
                            <p className={styles.weekendStyle}>W</p>
                          ) : (
                            <div>
                              <div
                                className={styles.dashContainer}
                                onClick={() => handleClick(x)}
                              >
                                -
                              </div>

                              {attendenceOption?.no === x &&
                                props?.row?.original?._id ===
                                  attendenceOption?._id &&
                                patientAttendenceModal && (
                                  <AttendanceList
                                    handleChange={handleClicks}
                                    onClick={props.onClick}
                                  />
                                )}
                            </div>
                          )}
                        </>
                      )}
                    </>
                  ) : (
                    <>
                      {holidayList?.length > 0 &&
                      gettingHolidayDateNum === x ? (
                        <p className={styles.holidayStyle}>H</p>
                      ) : weekendArray?.length &&
                        gettingWeekendDateNum === x ? (
                        <p className={styles.weekendStyle}>W</p>
                      ) : (
                        <div
                          className={styles.dashContainer}
                          onClick={() => handleClick(x)}
                        >
                          -
                        </div>
                      )}
                      {attendenceOption?.no === x &&
                        props?.row?.original?._id === attendenceOption?._id &&
                        patientAttendenceModal && (
                          <AttendanceList
                            handleChange={() => handleClicks()}
                            onClick={props.onClick}
                          />
                        )}
                    </>
                  )}
                </>
              </div>
            )
          },
        })
      })

      tempArray = [
        ...tempArray,
        {
          Header: t('DocUploadHeader.ACTION') || 'ACTION',
          Cell: (props: any) => {
            const handleShowAllAttendence = () => {
              setShowLeave((prevState) => !prevState)
              setemployMonthlyAttendenceDetails(props?.row?.original)
            }

            return (
              <>
                <OpenEyeIcon
                  fillColor={colors.grey4}
                  customClass={styles.openEyeIcon}
                  handleClick={handleShowAllAttendence}
                />
              </>
            )
          },
        },
      ]
      return tempArray
    }
    setdynamicArray(headerArraysFetch(daysInMonth))
  }, [
    attendenceOption,
    daysInMonth,
    employIDList,
    patientAttendenceModal,
    markeAttendenceChangeDate,
    showAttendenceModal,
  ])

  //  Ref for close AttendanceList component
  useEffect(() => {
    const checkIfClickedOutside = (e: any) => {
      if (
        (patientAttendenceModal || showAttendenceModal) &&
        ref.current &&
        !ref.current.contains(e.target)
      ) {
        setpatientAttendenceModal(false)
        setshowAttendenceModal(false)
      }
    }

    document.addEventListener('mousedown', checkIfClickedOutside)
    return () => {
      document.removeEventListener('mousedown', checkIfClickedOutside)
    }
  }, [patientAttendenceModal, showAttendenceModal])

  //React Select Dropdown Styles
  const customDropdownStyles = {
    control: (provided: any) => ({
      ...provided,
      boxShadow: 'none',
    }),
    input: (provided: any) => ({
      ...provided,
      height: '48px',
      margin: '0px !important',
    }),
    option: (provided: any) => ({
      ...provided,
      padding: '8px',
    }),
    indicatorSeparator: () => ({
      display: 'none',
    }),
    dropdownIndicator: (provided: any, state: any) => ({
      ...provided,
      color: '#797979',
      transform: state.selectProps.menuIsOpen ? 'rotate(180deg)' : null,
    }),
    clearIndicator: (provided: any) => ({
      ...provided,
      display: 'none',
    }),
    menuList: (base: any) => ({
      ...base,

      '::-webkit-scrollbar': {
        width: '4px',
        height: '0px',
      },
      '::-webkit-scrollbar-track': {
        background: '#f1f1f1',
      },
      '::-webkit-scrollbar-thumb': {
        background: '#a49e9e',
      },
      '::-webkit-scrollbar-thumb:hover': {
        background: '#a49e9e',
      },
    }),
  }

  const handleLeaveClose = () => {
    setShowLeave((prevState) => !prevState)
  }

  useEffect(() => {
    dispatch(clearState())

    setValue(SELECT_MONTH, monthOptions?.[currentMonthInNum - 1]?.value)
    setValue(SELECT_YEAR, yearOption?.[1]?.value)
  }, [])

  useEffect(() => {
    const payloadData = {
      sub_company: watchSubCompany || undefined,
      search: '',
      range: {
        fromDate: moment
          ?.utc(`${watchSelectMonth}/01/${watchSelectYear}`)
          ?.startOf('month')
          ?.toISOString(),
        toDate: moment
          .utc(`${watchSelectMonth}/01/${watchSelectYear}`)
          .endOf('month')
          .toISOString(),
      },
      department_id: watchDepartment,
      page: pageIndex,
      pageSize: dataPerPage,
    }
    const monthName = function (monthNumber: any) {
      const date = new Date()
      date.setMonth(monthNumber - 1)
      return date.toLocaleString('en-US', {
        month: 'long',
      })
    }

    dispatch(
      setSelectedMonthYear({
        month: monthName(Number(watchSelectMonth)),
        year: watchSelectYear,
        sub_company: watchSubCompany,
        department_id: watchDepartment,
      })
    )
    dispatch(
      getMonthlyEmployeeAttendanceAction(requestGenerator(payloadData))
    ).then((result: any) => {
      setTotalPage(result.payload.lastPage)
      setTotalData(result.payload.total)

      setsetshowTableBlock(true)
    })
  }, [pageIndex, dataPerPage])

  useEffect(() => {
    setpatientAttendenceModal(false)
  }, [showMarkDaysModal])

  return (
    <>
      {isLoading && <Loader />}
      {showMarkDaysModal && (
        <Popup
          Children={MarkAllDaysModal}
          handleClose={() => handleMarkDays()}
        />
      )}
      {showleave && (
        <Popup
          Children={EmployeeLeavePopup}
          heading={selectedMonthYear?.month}
          popData={employMonthlyAttendenceDetails}
          handleClose={() => handleLeaveClose()}
        />
      )}
      {showMarkDaysModalsPopup && (
        <Popup
          Children={ManageAttendanceModal}
          popData={employMonthlyAttendenceDetails}
          handleClose={() => handleMarkDaysDaata()}
        />
      )}
      <div className={styles.manageAttendanceMainContainer}>
        {/* Form */}
        <form
          className={styles.manageAttendanceForm}
          onSubmit={handleSubmit(onSubmit)}
        >
          <div className={styles.dropDownContainer}>
            <div className={styles.formFieldContainer}>
              <div className={styles.inputFieldContainer}>
                <label htmlFor={SUB_COMPANY} className={styles.dropDownLabel}>
                  {t('Employee.SubCompany')}
                </label>
                <Select
                  className={styles.selectInputField}
                  placeholder={
                    <div className="selectPlaceholder">
                      {t('Employee.SelectSub')}
                    </div>
                  }
                  {...register(
                    SUB_COMPANY,
                    manageAttendanceValidator[SUB_COMPANY]
                  )}
                  options={
                    Array.isArray(masterValueData) && masterValueData.length > 0
                      ? masterValueData
                          .find(
                            (item: any) => item.category_name === 'SUB-COMPANY'
                          )
                          ?.values?.map((item: any) => ({
                            label: item?.value,
                            value: item?._id,
                          }))
                      : []
                  }
                  isSearchable={false}
                  closeMenuOnSelect={true}
                  maxMenuHeight={130}
                  styles={customDropdownStyles}
                  onChange={(e: any) => {
                    setValue(SUB_COMPANY, e.value)
                  }}
                />
              </div>
            </div>
            <div className={styles.formFieldContainer}>
              <div className={styles.inputFieldContainer}>
                <label htmlFor={SUB_COMPANY} className={styles.dropDownLabel}>
                  {t('IPDBooking.Department')}
                </label>
                <Select
                  className={styles.selectInputField}
                  placeholder={
                    <div className="selectPlaceholder">
                      {' '}
                      {t('IPDBooking.SelectDepartment')}
                    </div>
                  }
                  {...register(
                    DEPARTMENT,
                    manageAttendanceValidator[DEPARTMENT]
                  )}
                  options={departmentData?.map((item: any) => ({
                    label: item.name,
                    value: item._id,
                  }))}
                  isSearchable={false}
                  closeMenuOnSelect={true}
                  maxMenuHeight={130}
                  styles={customDropdownStyles}
                  onChange={(e: any) => {
                    setValue(DEPARTMENT, e.value)
                  }}
                />
              </div>
            </div>

            <div className={styles.formFieldContainer}>
              <div className={styles.inputFieldContainer}>
                <label htmlFor={SELECT_MONTH} className={styles.dropDownLabel}>
                  {t('KPI.Select Month')}
                </label>
                <Select
                  className={styles.selectInputField}
                  placeholder={
                    <div className="selectPlaceholder">
                      {' '}
                      {t('KPI.Select Month')}
                    </div>
                  }
                  defaultValue={{
                    label: monthOptions?.[currentMonthInNum - 1]?.label,
                    value: monthOptions?.[currentMonthInNum - 1]?.value,
                  }}
                  {...register(
                    SELECT_MONTH,
                    manageAttendanceValidator[SELECT_MONTH]
                  )}
                  isSearchable={false}
                  isClearable={true}
                  options={monthOptions?.map((item: any) => ({
                    label: item.label,
                    value: item.value,
                  }))}
                  closeMenuOnSelect={true}
                  maxMenuHeight={130}
                  styles={customDropdownStyles}
                  onChange={(e: any) => {
                    setValue(SELECT_MONTH, e.value)
                  }}
                />
              </div>
            </div>

            <div className={styles.formFieldContainer}>
              <div className={styles.inputFieldContainer}>
                <label htmlFor={SELECT_YEAR} className={styles.dropDownLabel}>
                  {t('KPI.Select Year')}
                </label>
                <Select
                  className={styles.selectInputField}
                  placeholder={
                    <div className="selectPlaceholder">
                      {' '}
                      {t('KPI.Select Year')}
                    </div>
                  }
                  defaultValue={{
                    label: yearOption?.[1]?.label,
                    value: yearOption?.[1]?.value,
                  }}
                  {...register(
                    SELECT_YEAR,
                    manageAttendanceValidator[SELECT_YEAR]
                  )}
                  isSearchable={false}
                  isClearable={true}
                  options={yearOption?.map((item: any) => ({
                    label: item.label,
                    value: item.value,
                  }))}
                  components={animatedComponent}
                  closeMenuOnSelect={true}
                  maxMenuHeight={130}
                  styles={customDropdownStyles}
                  onChange={(e: any) => {
                    setValue(SELECT_YEAR, e?.value)
                  }}
                />
              </div>
            </div>

            <div className={styles.formFieldContainer}>
              <Button
                title={t('Attendance.Go') || 'Go'}
                customClass={styles.goBtn}
                type="submit"
                disable={!createSubmitCondition}
              />
            </div>
          </div>
        </form>

        {setshowTableBlock && (
          <div className={styles.markForAllDaysContainer}>
            <div className={styles.markForAllDaysBtnContainer}>
              <Button
                title={t('Attendance.Mark_For_All_Days') || 'Mark For All Days'}
                customClass={styles.markBtn}
                disable={selectedEmployList?.length <= 0 ? true : false}
                type="submit"
                handleClick={handleMarkDays}
              />
            </div>
            <div className={styles.tableContainer} ref={ref}>
              {showApi &&
              getMonthlyAttendance &&
              getMonthlyAttendance?.length > 0 ? (
                <TableV2
                  tableHeaderData={dynamicArray}
                  tableRowData={getMonthlyAttendance}
                  active={false}
                  ref={ref2}
                />
              ) : (
                t('Attendance.No_Data_Found')
              )}
            </div>

            {totalData > 10 && (
              <Pagination
                setDataPerPage={setDataPerPage}
                pageIndexOptions={pageIndexOptions}
                pageIndex={pageIndex}
                setPageIndex={setPageIndex}
              />
            )}
          </div>
        )}
      </div>
    </>
  )
}
export default ManageAttendance
