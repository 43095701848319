import {
  INVENTORY_MASTER_NAME,
  INVENTORY_MASTER_CURRENCY,
  INVENTORY_MASTER_CONTACT_NO,
  INVENTORY_MASTER_CONTACT_PERSON,
} from '../constants/constant'
import { t } from 'i18next'

export interface IinventoryMasterTableValidators {
  [INVENTORY_MASTER_NAME]: {
    required: any
    pattern: {
      value: any
      message: any
    }
  }
  [INVENTORY_MASTER_CURRENCY]: {
    required: any
  }
  [INVENTORY_MASTER_CONTACT_NO]: {
    required: any
  }
  [INVENTORY_MASTER_CONTACT_PERSON]: {
    required: any
  }
}

export const inventoryMasterTableValidators: IinventoryMasterTableValidators = {
  [INVENTORY_MASTER_NAME]: {
    required: t('SupplierMaster.name_validation'),
    pattern: {
      value: /^[a-zA-Z\s]*$/,
      message: t('SupplierMaster.name_validation_value'),
    },
  },
  [INVENTORY_MASTER_CURRENCY]: {
    required: t('SupplierMaster.Currency_Validation'),
  },
  [INVENTORY_MASTER_CONTACT_NO]: {
    required: t('SupplierMaster.Contact_No_Validation'),
  },
  [INVENTORY_MASTER_CONTACT_PERSON]: {
    required: t('SupplierMaster.Contact_Person_Validation'),
  },
}
