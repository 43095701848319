import { createSlice } from '@reduxjs/toolkit'
import { IImportState } from '../../../interfaces/apiInterface'
import {
  getAllReceptionistWithBranches,
  importAppointmentUtility,
  importInvItemsUtility,
  importInvoiceUtility,
  importPatientEmrUtility,
  importReceiptUtility,
  importTreatementServicesUtility,
} from './importUtilityAsyncActions'

export const initialState: IImportState = {
  isLoading: false,
  inValidData: [],
}

export const ImportUtility = createSlice({
  name: 'import',
  initialState,
  reducers: {
    clearImportData: (state) => {
      state.isLoading = false
      state.inValidData = []
    },
  },
  extraReducers: (builder) => {
    // import patient emr
    builder.addCase(importPatientEmrUtility.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(importPatientEmrUtility.fulfilled, (state, action) => {
      state.isLoading = false
      state.inValidData = action.payload
    })
    builder.addCase(importPatientEmrUtility.rejected, (state) => {
      state.isLoading = false
    })
    // import treatment services
    builder.addCase(importTreatementServicesUtility.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(
      importTreatementServicesUtility.fulfilled,
      (state, action) => {
        state.isLoading = false
        state.inValidData = action.payload
      }
    )
    builder.addCase(
      importTreatementServicesUtility.rejected,
      (state, error) => {
        state.isLoading = false
      }
    )

    // import inventory items
    builder.addCase(importInvItemsUtility.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(importInvItemsUtility.fulfilled, (state, action) => {
      state.isLoading = false
      state.inValidData = action.payload
    })
    builder.addCase(importInvItemsUtility.rejected, (state, error) => {
      state.isLoading = false
    })

    // import appointment
    builder.addCase(importAppointmentUtility.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(importAppointmentUtility.fulfilled, (state, action) => {
      state.isLoading = false
      state.inValidData = action.payload
    })
    builder.addCase(importAppointmentUtility.rejected, (state, error) => {
      state.isLoading = false
    })

    // get all receptionist with branch list
    builder.addCase(getAllReceptionistWithBranches.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(
      getAllReceptionistWithBranches.fulfilled,
      (state, action) => {
        state.isLoading = false
      }
    )
    builder.addCase(getAllReceptionistWithBranches.rejected, (state, error) => {
      state.isLoading = false
    })

    // import receipt
    builder.addCase(importReceiptUtility.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(importReceiptUtility.fulfilled, (state, action) => {
      state.isLoading = false
      state.inValidData = action.payload
    })
    builder.addCase(importReceiptUtility.rejected, (state, error) => {
      state.isLoading = false
    })

    // import invoice
    builder.addCase(importInvoiceUtility.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(importInvoiceUtility.fulfilled, (state, action) => {
      state.isLoading = false
      state.inValidData = action.payload
    })
    builder.addCase(importInvoiceUtility.rejected, (state, error) => {
      state.isLoading = false
    })
  },
})
export const { clearImportData } = ImportUtility.actions
export default ImportUtility.reducer
