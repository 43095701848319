import { useState, useEffect } from 'react'
import Select, { components } from 'react-select'
import Button from '../../../components/common/button/Button'
import styles from './create.module.scss'

import { SubmitHandler, useForm, useFieldArray } from 'react-hook-form'
import { colors } from '../../../constants/color'
import { PaymentArray, failure, monthOptions } from '../../../constants/data'
import { useNavigate, useLocation } from 'react-router-dom'
import { ICreatesalary } from '../../../interfaces/interfaces'
import { createsalaryValidators } from '../../../form-validators/createsalaryValidators'
import {
  addPayrolls,
  editPayrollById,
  getAllPayroll,
  getSalary,
} from '../../../redux/features/payroll/payrollAsyncActions'
import { requestGenerator } from '../../../utils/payloadGenerator'
import { useAppDispatch, useAppSelector } from '../../../hooks'
import SearchModal from '../payroll-search-modal/SearchModal'
import Popup from '../../../components/common/popup/Popup'
import SearchDesign from '../payroll-search-modal/SearchDesign'
import Loader from '../../../components/common/spinner/Loader'
import {
  ADD_PAYROLL,
  EDIT_PAYROLL_BY_ID,
} from '../../../constants/asyncActionsType'
import { Input } from '../../../components/common/input/input'
import {
  blockInvalidCharacter,
  disableArrowKey,
  handleKeyDown,
  trimValue,
} from '../../../utils/utils'
import moment from 'moment'
import {
  ALLOWANCE_NAME1,
  ALLOWANCE_VALUE1,
  BASIC_SALARY,
  DEDUCTION_NAME1,
  DEDUCTION_VALUE1,
  EXPENSE_CLAIM,
  // FIXED_ALLOWANCE,
  HOUR_CLOCKED,
  HOUR_RATE,
  MONTH,
  NET_SALARY,
  PRESENT_DAYS,
  STATUS_TYPE,
  TOTAL_ALLOWANCE,
  TOTAL_DEDUCTION,
  TOTAL_SALARY,
  WORKING_DAYS,
  YEAR,
  emp_id,
  name_en,
} from './createSalaryConstant'
import { CrossIcon } from '../../../components/common/svg-components'
import { setMessage } from '../../../redux/features/toast/toastSlice'
import { t } from 'i18next'

const Create = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const [searchModalData, setSearchModalData] = useState({})
  const [showSearchModal, setShowSearchModal] = useState<boolean>(false)
  const [payrollData, setpayrollData] = useState<any>({})
  const [hideButton, setHideButton] = useState(false)
  const [showDiv, setShowDiv] = useState(false)
  //For Allowances
  const [inputContainers, setInputContainers] = useState([0])
  //For Deduction
  const [inputContainer, setInputContainer] = useState([0])
  const [yearOptions, setYearOptions] = useState([])
  const { isLoading, payrollDetail, allSalary } = useAppSelector(
    (state) => state.payroll
  )
  const [inputValue, setinputValue] = useState<any>('a')
  const [deductionInputValue, setDeductionValue] = useState<any>(0)
  const { masterValueData } = useAppSelector((state) => state.login)

  const { userListData } = useAppSelector((state) => state.employee)

  const dispatch = useAppDispatch()
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    reset,
    trigger,
    formState: { errors },
    control,
  } = useForm<ICreatesalary>({})

  // HR CHANGES

  const {
    append: allowanceAppend,
    remove: allowanceRemove,
    fields: allowanceFields,
  } = useFieldArray<any>({
    control,
    name: 'fixed_allowance',
  })

  const {
    append: deductionAppend,
    remove: deductionRemove,
    fields: deductionFields,
  } = useFieldArray<any>({
    control,
    name: 'fixed_deductions',
  })

  const watchAllowancensValue = watch('fixed_allowance')
  const watchFixedDeductionsValue = watch('fixed_deductions')
  const [selectedOptionsAllowance, setSelectedOptionsAllowance] = useState<
    any[]
  >([])
  const [selectedOptionsDeduction, setSelectedOptionsDeduction] = useState<
    any[]
  >([])

  const [selectedDeductionValue, setSelectedDeductionValue] = useState<any>([])

  const [allowanceDropdownData, setAllowanceDropdownData] = useState<any[]>([])
  const [deductionDropdownData, setDeductionDropdownData] = useState<any[]>([])

  const fixedAllowanceData = allowanceFields?.filter(
    (s: any) => !s.allowance_name?.length
  )
  const fixedDedeuctionData = deductionFields?.filter(
    (x: any) => !x.deductionName?.length
  )

  // const showValidationErrors = () => {
  //   const checkLength = watchAllowancensValue?.filter(
  //     (s: any) => !s?.allowance_name?.length
  //   )
  //   const checkDeductionLength = watchFixedDeductionsValue?.filter(
  //     (s: any) => !s?.deductionName?.length
  //   )
  //   if (
  //     (watchAllowancensValue?.length && checkLength?.length) ||
  //     (watchFixedDeductionsValue?.length && checkDeductionLength?.length)
  //   ) {
  //     dispatch(
  //       setMessage({
  //         type: failure,
  //         message: 'Please select Allowances/Deductions',
  //       })
  //     )
  //   }
  // }

  const onSubmit: SubmitHandler<ICreatesalary> = (data: any) => {
    // const allowanceList = data.allowance_name1?.map((s: any, i: any) => {
    //   return {
    //     allowance_name: s.allowance_name1,
    //     allowance_value: data.allowance_value1[i]?.allowance_value1,
    //   }
    // })
    // const deductionList = data.deduction_name1?.map((s: any, i: any) => {
    //   return {
    //     deduction_name: s.deduction_name1,
    //     deduction_value: data.deduction_value1[i]?.deduction_value1,
    //   }
    // })
    const checkAllowanceData = data?.fixed_allowance?.filter(
      (s: any) => !s.allowance_name?.length || !s.allowance_value?.length
    )
    const checkDeductionData = data?.fixed_deductions?.filter(
      (s: any) => !s.deduction_name?.length || !s.deduction_value?.length
    )
    const checkAllowanceDataValue = data?.fixed_allowance?.filter(
      (s: any) => Number(s.allowance_value) == 0
    )
    const checkDeductionDataValue = data?.fixed_deductions?.filter(
      (s: any) => Number(s.deduction_value) == 0
    )
    if (checkAllowanceData?.length || checkDeductionData?.length) {
      dispatch(
        setMessage({
          type: failure,
          message: t('CreateSalary.select_allowance_deduction'),
        })
      )
    } else {
      if (checkAllowanceDataValue?.length || checkDeductionDataValue?.length) {
        dispatch(
          setMessage({
            type: failure,
            message: t('CreateSalary.allowance_deduction_greater_zero'),
          })
        )
      } else {
        if (data?.net_salary < 0) {
          dispatch(
            setMessage({
              type: failure,
              message: t('CreateSalary.deduction_not_exceed_allowance_value'),
            })
          )
        } else {
          const dataPayload = {
            emp_id: data.emp_id,
            name: data.name_en,
            month: data?.month?.label,
            year: data?.year?.label,
            salary_type: payrollData?.company_details?.salary_type,
            hour_rate: data.hour_rate,
            hour_clocked: data.hour_clocked,
            total_salary: data.total_salary,
            basic_salary: data?.basic_salary ? data?.basic_salary : undefined,
            expense_claim: data.expense_claim,
            status_type: data.status_type,
            net_salary: data?.net_salary || 0,
            fixed_allowance: data?.fixed_allowance,
            fixed_deduction: data?.fixed_deductions,
            total_allowance: data.total_allowance,
            total_deduction: data.total_deduction,
            is_active: data?.is_active,
            status: data?.status,
            prasent_days: data?.prasent_days,
            working_days: data?.working_days,
          }

          if (data && data._id) {
            let id = data._id
            data.month = data?.user?.month?.label
            data.year = data?.user?.year?.label
            dispatch(
              editPayrollById(requestGenerator({ id, data: dataPayload }))
            ).then((e) => {
              if (e.type === `${EDIT_PAYROLL_BY_ID}/fulfilled`) {
                navigate('/payroll')
                let payloadData = {
                  page: 1,
                  pageSize: 100,
                  search: '',
                  is_active: true,
                }
                dispatch(getAllPayroll(requestGenerator(payloadData)))
              }
            })
          } else {
            dispatch(addPayrolls(requestGenerator(dataPayload))).then((e) => {
              if (e.type === `${ADD_PAYROLL}/fulfilled`) {
                navigate('/payroll')
                let payloadData = {
                  page: 1,
                  pageSize: 100,
                  search: '',
                  is_active: true,
                }
                dispatch(getAllPayroll(requestGenerator(payloadData)))
              }
            })
          }
        }
      }
    }
  }

  useEffect(() => {
    if (payrollDetail) {
      reset(payrollDetail)
    }
  }, [reset, payrollDetail])

  const handlego = () => {
    setShowDiv(true)

    if (formData[MONTH] && formData[YEAR]) {
      let requestData = {
        emp_id: payrollData?._id,
        month: formData[MONTH]?.value,
        salary_type: payrollData?.company_details?.salary_type?.toLowerCase(),
        year: formData[YEAR]?.value,
      }
      dispatch(getSalary(requestGenerator(requestData)))
      // setHideButton(true)
    }
  }

  const handleReset = () => {
    reset()
    setValue(MONTH, '')
  }
  // Search Employee Popup
  const handleSearchPopup = () => {
    handleReset()
    setSearchModalData({})
    setShowSearchModal((prevState) => !prevState)
    setShowDiv(false)
    setHideButton(false)
  }
  // Search Employee modal close
  const handleSearchModalClose = () => {
    setShowSearchModal((prevState) => !prevState)
    setSearchModalData({})
  }
  // Search Employee Get Popup Data By Id
  const handleSearchGetPopupData = (item: any) => {
    setpayrollData(item)
    setShowSearchModal((prevState) => !prevState)
  }
  //Set the value from Popup Data
  useEffect(() => {
    if (payrollData?._id) {
      setValue(emp_id, payrollData?.emp_id)
      setValue(name_en, payrollData?.name_en)
      setValue(HOUR_RATE, payrollData?.company_details?.hourly_rate)
      if (payrollData?.company_details?.salary_type === 'MONTHLY') {
        let newDate = new Date(
          `${formData[MONTH]?.value}/01/${formData[YEAR]?.value}`
        )
        const days = moment(newDate).daysInMonth()
        const basicSalary =
          (Number(payrollData?.company_details?.basic_salary) / days) *
          (Number(allSalary?.presentCount) + Number(allSalary?.paidLeaveCount))

        setValue(BASIC_SALARY, basicSalary ? Math.round(basicSalary) : 0)
        setValue(TOTAL_SALARY, 0)
      } else {
        const totalHour = allSalary[0]?.hours ? allSalary[0]?.hours : 0
        setValue(HOUR_CLOCKED, totalHour)
        const totalHourPay =
          payrollData?.company_details?.hourly_rate * totalHour
        setValue(TOTAL_SALARY, totalHourPay)
      }
      var d = new Date(payrollData?.company_details?.joining_date)
      const first = d.getFullYear() - 1
      var s = new Date()
      const second = s.getFullYear()
      const arr: any = []
      for (let i = first; i <= second; i++) {
        arr.push({ label: i, value: i })
      }
      setYearOptions(arr)
      // dynamic fields
      const fixedAllowence = payrollData?.company_details?.fixed_allowance
      if (fixedAllowence && fixedAllowence?.length > 0) {
        const checkArr = fixedAllowence?.map((s: any) => ({
          allowance_name: s?.name,
          allowance_value: s?.value,
        }))
        setSelectedOptionsAllowance(checkArr)
        setValue('fixed_allowance', checkArr)
        const arr = Array.from({ length: fixedAllowence?.length }, (_, i) => i)
        setInputContainers(checkArr)
        const valueArr = fixedAllowence?.map((item: any, index: any) => {
          return {
            [ALLOWANCE_VALUE1]: item?.value,
          }
        })
        const nameArr = fixedAllowence?.map((item: any, index: any) => {
          return {
            [ALLOWANCE_NAME1]: item?.name,
          }
        })
        // setValue(ALLOWANCE_NAME1, nameArr)
        // setValue(ALLOWANCE_VALUE1, valueArr)
      }
      const deduction = payrollData?.company_details?.fixed_deduction
      if (deduction && deduction?.length > 0) {
        const arr = Array.from({ length: deduction?.length }, (_, i) => i)
        const checkArr = deduction?.map((s: any) => ({
          deduction_name: s?.name,
          deduction_value: s?.value,
        }))
        setSelectedOptionsDeduction(checkArr)
        setSelectedDeductionValue(checkArr)
        setValue('fixed_deductions', checkArr)
        setInputContainer(checkArr)
        const valueArr = deduction?.map((item: any, index: any) => {
          return {
            [DEDUCTION_VALUE1]: item?.value,
          }
        })
        const nameArr = deduction?.map((item: any, index: any) => {
          return {
            [DEDUCTION_NAME1]: item?.name,
          }
        })
        setValue(DEDUCTION_NAME1, nameArr)
        setValue(DEDUCTION_VALUE1, valueArr)
      }
    }
  }, [payrollData, allSalary])

  const formData = watch()

  useEffect(() => {
    const basicTotal =
      Number(location?.state?.user?.total_salary) +
      Number(formData[TOTAL_ALLOWANCE]) -
      Number(formData[TOTAL_DEDUCTION])
    // const netSalary = formData[BASIC_SALARY]
    //   ? Number(formData[BASIC_SALARY]) + basicTotal
    //   : formData[TOTAL_SALARY]
    //   ? Number(formData[TOTAL_SALARY]) + basicTotal
    //   : basicTotal
    setValue(NET_SALARY, basicTotal || 0)
  }, [
    formData[BASIC_SALARY],
    formData[TOTAL_SALARY],
    formData[EXPENSE_CLAIM],
    formData[TOTAL_ALLOWANCE],
    formData[TOTAL_DEDUCTION],
  ])

  useEffect(() => {
    let totalAllowance: any = formData[ALLOWANCE_VALUE1]
      ? formData[ALLOWANCE_VALUE1].reduce((acc: any, obj: any) => {
          return Number(acc) + Number(obj[ALLOWANCE_VALUE1])
        }, 0)
      : 0
    // setValue(TOTAL_ALLOWANCE, totalAllowance || 0)

    // total deduction
    let totalDeduction: any = formData[DEDUCTION_VALUE1]
      ? formData[DEDUCTION_VALUE1].reduce((acc: any, obj: any) => {
          return Number(acc) + Number(obj[DEDUCTION_VALUE1])
        }, 0)
      : 0
    // setValue(TOTAL_DEDUCTION, totalDeduction || 0)
  }, [formData[ALLOWANCE_VALUE1], formData[DEDUCTION_VALUE1]])

  const [objct, setobjct] = useState(false)

  useEffect(() => {
    let allowanceArray = watchAllowancensValue?.map((item: any) => {
      return Number(item?.allowance_value)
    })
    let totalAllowance =
      allowanceArray?.length > 0 &&
      allowanceArray?.reduce((total: any, value: any) => {
        return Number(total) + Number(value)
      })
    setValue(TOTAL_ALLOWANCE, totalAllowance || 0)

    let deductionArray = watchFixedDeductionsValue?.map((item: any) => {
      return Number(item?.deduction_value)
    })
    let totalDeduction =
      deductionArray?.length > 0 &&
      deductionArray?.reduce((total: any, value: any) => {
        return Number(total) + Number(value)
      })
    setValue(TOTAL_DEDUCTION, totalDeduction || 0)
  }, [
    watchFixedDeductionsValue,
    watchAllowancensValue,
    selectedOptionsDeduction,
    objct,
  ])

  const handleAllowance = (val: any, data: any) => {
    let dea = watchAllowancensValue?.filter((item: any, index: number) => {
      if (index === val) {
        return item
      }
    })
    let nArr = watchAllowancensValue?.map((item: any, index: number) => {
      if (index === val) {
        return { ...item, allowance_value: data }
      } else {
        return item
      }
    })
    setValue('fixed_allowance', nArr)
    if (dea?.length > 0) {
      let allowanceArr = watchAllowancensValue?.filter((item: any) => {
        if (!item?.allowance_name === dea[0]?.allowance_name) {
          return Number(item?.allowance_value)
        }
      })
      let allowanceArray = allowanceArr?.map((item: any) => {
        return Number(item?.allowance_value)
      })
      let totalAllowance = allowanceArray?.reduce((total: any, value: any) => {
        return Number(total) + Number(value)
      })
      setValue(TOTAL_ALLOWANCE, totalAllowance || 0)
    }
  }

  const handleDeduct = (val: any, data: any) => {
    let dea = watchFixedDeductionsValue?.filter((item: any, index: number) => {
      if (index === val) {
        return item
      }
    })
    let nArr = watchFixedDeductionsValue?.map((item: any, index: number) => {
      if (index === val) {
        return { ...item, deduction_value: data }
      } else {
        return item
      }
    })
    setValue('fixed_deductions', nArr)
    if (dea?.length > 0) {
      let deductArr = watchFixedDeductionsValue?.filter((item: any) => {
        if (!item?.deduction_name === dea[0]?.deduction_name) {
          return Number(item?.deduction_value)
        }
      })
      let deductionArray = deductArr?.map((item: any) => {
        return Number(item?.deduction_value)
      })
      let totalDeduction = deductionArray?.reduce((total: any, value: any) => {
        return Number(total) + Number(value)
      })
      setValue(TOTAL_DEDUCTION, totalDeduction || 0)
    }
  }

  //Set Value for Edit Form
  let data = location.state
  const companyPayrollData = userListData?.find((item: any) => {
    return item?.emp_id === data?.user?.emp_id
  })

  useEffect(() => {
    if (data) {
      reset(data.user)
      setValue(name_en, data?.user?.name)
      setValue(STATUS_TYPE, data?.user?.status_type)
      const days = moment().daysInMonth()
      const basicSalary = companyPayrollData?.company_details?.basic_salary
        ? (Number(companyPayrollData?.company_details?.basic_salary) / days) *
          Number(allSalary?.presentCount)
        : Number(data?.user?.basic_salary || 0)
      setValue(BASIC_SALARY, basicSalary ? basicSalary : 0)

      const value: any = monthOptions?.find(
        (i: any) => i.label === data?.user?.month
      )
      setValue(MONTH, {
        label: value?.label,
        value: value?.value,
        index: value?.index,
      })

      setValue(YEAR, {
        label: data?.user?.year,
        value: data?.user?.year,
      })

      // dynamic fields
      const fixedAllowence = data?.user?.fixed_allowance
        ? data?.user?.fixed_allowance
        : []

      if (fixedAllowence && fixedAllowence?.length > 0) {
        const checkArr = fixedAllowence?.map((s: any) => ({
          allowance_name: s?.allowance_name,
          allowance_value: s?.allowance_value,
        }))

        setSelectedOptionsAllowance(checkArr)
        setTimeout(() => setValue('fixed_allowance', checkArr), 300)
        // setValue('fixed_allowance', checkArr)
        const arr = Array.from({ length: fixedAllowence?.length }, (_, i) => i)
        setInputContainers(checkArr)
        let allowanceLabel: any = []
        let allowance_value: any = []
        fixedAllowence.forEach((item: any, index: any) => {
          allowanceLabel.push({ [ALLOWANCE_NAME1]: item?.allowance_name })
          allowance_value.push({ [ALLOWANCE_VALUE1]: item?.allowance_value })
        })
        // setValue(ALLOWANCE_NAME1, allowanceLabel)
        // setValue(ALLOWANCE_VALUE1, allowance_value)
      }

      const deduction = data?.user?.fixed_deduction
        ? data?.user?.fixed_deduction
        : []
      if (deduction && deduction?.length > 0) {
        const arr = Array.from({ length: deduction?.length }, (_, i) => i)
        const checkArr = deduction?.map((s: any) => ({
          deduction_name: s?.deduction_name,
          deduction_value: s?.deduction_value,
        }))
        setSelectedOptionsDeduction(checkArr)
        setSelectedDeductionValue(checkArr)
        setTimeout(() => setValue('fixed_deductions', checkArr), 300)

        setInputContainer(checkArr)
        let deductionLabel: any = []
        let deductionValue: any = []
        deduction.forEach((item: any, index: any) => {
          deductionLabel.push({ [DEDUCTION_NAME1]: item?.deduction_name })
          deductionValue.push({ [DEDUCTION_VALUE1]: item?.deduction_value })
        })
        setValue(DEDUCTION_NAME1, deductionLabel)
        setValue(DEDUCTION_VALUE1, deductionValue)
      }
      let requestData = {
        emp_id: payrollData?._id,
        month: formData[MONTH]?.index,
        year: Number(formData[YEAR]),
        salary_type: payrollData?.company_details?.salary_type?.toLowerCase(),
      }
      dispatch(getSalary(requestGenerator(requestData)))
      setShowDiv(!showDiv)
      setHideButton(true)
    }
  }, [])
  const handleRemove = (id: any) => {
    const filterarray = inputContainers?.filter((item: any) => item !== id)
    setInputContainers(filterarray)
  }
  const handleCrossIon = (id: any) => {
    const filterarray = inputContainer?.filter((item: any) => item !== id)
    setInputContainer(filterarray)
  }
  const multiSelectStyles = {
    control: (provided: any) => ({
      ...provided,
      scroll: 'none',
      height: '45px',
      border: 'none',
    }),
    option: (provided: any) => ({
      ...provided,
      padding: '8px 10px',
    }),
    indicatorSeparator: () => ({
      display: 'none',
      color: '#797979',
    }),
    dropdownIndicator: (provided: any, state: any) => ({
      ...provided,
      color: '#797979',
      transform: state.selectProps.menuIsOpen ? 'rotate(180deg)' : null,
    }),
    clearIndicator: (provided: any) => ({
      ...provided,
      color: '#797979',
      display: 'none',
    }),
  }
  const GoSubmit = formData[MONTH] && formData[YEAR] !== undefined

  const handleAllowanceChange = (event: any, index: any) => {
    const selectedOption = event.target.value
    let updatedSelectedOptions = [...selectedOptionsAllowance]
    updatedSelectedOptions[index] = selectedOption
    setSelectedOptionsAllowance(updatedSelectedOptions)
  }

  const handleDeductionChange = (event: any, index: any) => {
    const selectedOption = event?.target?.value
    let updatedSelectedOptions = [...selectedOptionsDeduction]
    updatedSelectedOptions[index] = selectedOption
    setSelectedOptionsDeduction(updatedSelectedOptions)
  }

  const handleDeductionValChange = (event: any, index: any) => {
    const selectedOption = event?.target?.value
    let updatedSelectedOptions = [...selectedDeductionValue]
    updatedSelectedOptions[index] = selectedOption
    setSelectedDeductionValue(updatedSelectedOptions)
  }

  const handleAdd = () => {
    // setinputValue('')
    allowanceAppend({ allowance_name: '', allowance_value: '' })
  }

  const handleDeductionAdd = () => {
    // setDeductionValue?.('')
    // setDeductionValue(0)
    deductionAppend?.({ deduction_name: '', deduction_value: '' })
  }

  const setDropdownValue = (valueArr: any, setter: any) => {
    if (valueArr?.values?.length > 0) {
      let tempArr: any = []
      tempArr = valueArr?.values?.map((item: any) => {
        return {
          label: item?.value,
          value: item?._id,
        }
      })
      setter(tempArr)
    } else {
      setter([])
    }
  }

  // Find Category Name from Master Data
  useEffect(() => {
    const allowance = masterValueData?.find(
      (item: any) => item?.category_name === 'ALLOWANCE'
    )
    const deduction = masterValueData?.find(
      (item: any) => item?.category_name === 'DEDUCTIONS'
    )

    setDropdownValue(allowance, setAllowanceDropdownData)
    setDropdownValue(deduction, setDeductionDropdownData)
  }, [masterValueData])

  return (
    <>
      {isLoading && <Loader />}
      {showSearchModal && (
        <Popup
          Children={SearchModal}
          popData={searchModalData}
          handleClose={() => handleSearchModalClose()}
          setModelOpenClose={setShowSearchModal}
          handleSubmit={handleSearchGetPopupData}
        />
      )}
      <div
        className={styles.addContainer}
        onClick={() => {
          if (objct === true) {
            setobjct(false)
          } else {
            setobjct(true)
          }
        }}
      >
        {/* <div className={styles.searchMainContainer}>
          <div className={styles.searchContainer}>
            <SearchDesign
              handleSearchClick={handleSearchPopup}
              customClass={
                data?.user?._id
                  ? styles.disableInputSearchContainer
                  : styles.inputSearchContainer
              }
            />
          </div>
        </div> */}

        <form onSubmit={handleSubmit(onSubmit)}>
          <fieldset
            disabled={payrollData?._id || location?.state ? false : true}
            className={styles.formDisable}
          >
            <div className={styles.addContainer}>
              <h1 className={styles.title}>{t('Payroll.Employee_Info')}</h1>
              <div className={styles.div}>
                <div className={styles.formFieldContainer}>
                  <label>
                    {t('AddEmp.Employee ID')}
                    <span className="asterick">*</span>
                  </label>
                  <div className={styles.inputFieldContainer}>
                    {/* <Input
                      disabled={true}
                      placeholder="Employee Id"
                      {...register(emp_id, createsalaryValidators[emp_id])}
                    /> */}
                    <input
                      value={location?.state?.user?.employeeData?.emp_id}
                      disabled
                      className={styles.employeeIdFieldStyle}
                    />
                    {errors[emp_id] && (
                      <div className={styles.errorContainer}>
                        <span className={styles.extraSpan}></span>
                        <p className="dashboardFormError">
                          {errors[emp_id].message}
                        </p>
                      </div>
                    )}
                  </div>
                  <label>
                    {' '}
                    {t('PatientEMR.Name')}
                    <span className="asterick">*</span>
                  </label>
                  <div className={styles.inputFieldContainer}>
                    <Input
                      disabled={true}
                      placeholder={t('PatientEMR.Name') || 'Name'}
                      {...register(name_en, createsalaryValidators[name_en])}
                    />
                    {errors[name_en] && (
                      <div className={styles.errorContainer}>
                        <span className={styles.extraSpan}></span>
                        <p className="dashboardFormError">
                          {errors[name_en].message}
                        </p>
                      </div>
                    )}
                  </div>
                </div>

                <div className={styles.formFieldContainer}>
                  <label>
                    {' '}
                    {t('KPI.Month')}
                    <span className="asterick">*</span>{' '}
                  </label>
                  <div className={styles.inputFieldContainer}>
                    <Select
                      className={styles.selectInputField}
                      isDisabled={data?.user?.month ? true : false}
                      placeholder={
                        <div className="selectPlaceholder">
                          {t('KPI.Select Month')}
                        </div>
                      }
                      isClearable={true}
                      options={monthOptions}
                      {...register(MONTH, { required: true })}
                      value={watch(MONTH || [])}
                      components={{
                        DropdownIndicator: (props: any) => {
                          if (payrollData?._id || location?.state) {
                            return <components.DropdownIndicator {...props} />
                          } else {
                            return null
                          }
                        },
                      }}
                      closeMenuOnSelect={true}
                      maxMenuHeight={120}
                      styles={multiSelectStyles}
                      onChange={(e: any) => {
                        setValue(MONTH, e)
                        trigger(MONTH)
                      }}
                    />
                    {errors[MONTH]?.type === 'required' && (
                      <div className={styles.errorContainer}>
                        <span className={styles.extraSpan}></span>
                        <p className="dashboardFormError">
                          {t('Attendance.month_validation')}
                        </p>
                      </div>
                    )}
                  </div>
                </div>

                <div className={styles.formFieldContainer}>
                  <label>
                    {' '}
                    {t('KPI.Year')}
                    <span className="asterick">*</span>{' '}
                  </label>
                  <div className={styles.inputFieldContainer}>
                    <Select
                      className={styles.selectInputField}
                      isDisabled={data?.user?.year ? true : false}
                      placeholder={
                        <div className="selectPlaceholder">
                          {t('KPI.Select Year')}
                        </div>
                      }
                      isClearable={true}
                      options={yearOptions}
                      {...register(YEAR, { required: true })}
                      value={watch(YEAR || [])}
                      components={{
                        DropdownIndicator: (props: any) => {
                          if (payrollData?._id || location?.state) {
                            return <components.DropdownIndicator {...props} />
                          } else {
                            return null
                          }
                        },
                      }}
                      closeMenuOnSelect={true}
                      maxMenuHeight={120}
                      styles={multiSelectStyles}
                      onChange={(e: any) => {
                        setValue(YEAR, e)
                        trigger(YEAR)
                      }}
                    />
                    {errors[YEAR]?.type === 'required' && (
                      <div className={styles.errorContainer}>
                        <span className={styles.extraSpan}></span>
                        <p className="dashboardFormError">
                          {t('Attendance.year_validation')}
                        </p>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.buttonConatiner}>
              {!hideButton && (
                <Button
                  title={t('Attendance.Go') || 'Go'}
                  type="button"
                  disable={!GoSubmit}
                  handleClick={handlego}
                  customClass={styles.btn1}
                />
              )}
            </div>
            {/* {showDiv && ( */}
            <div className={styles.addContainer}>
              <h3>{t('CreateSalary.Salary_Info')}</h3>
              <div className={styles.salary}>
                {payrollData?.company_details?.salary_type === 'MONTHLY' ||
                data?.user?.salary_type === 'MONTHLY' ? (
                  <>
                    <div className={styles.row}>
                      <label className={styles.label}>
                        {t('AddEmp.Basic Salary')}
                      </label>
                      <div className={styles.inputFieldContainer}>
                        <Input
                          type="text"
                          disabled
                          placeholder={
                            t('AddEmp.Basic Salary') || 'Basic Salary'
                          }
                          {...register(
                            BASIC_SALARY,
                            createsalaryValidators[BASIC_SALARY]
                          )}
                        />
                      </div>
                    </div>
                    <div className={styles.row}>
                      <label className={styles.label}>
                        {t('CreateSalary.Working_Days')}
                      </label>
                      <div className={styles.inputFieldContainer}>
                        <Input
                          type="text"
                          disabled
                          placeholder={
                            t('CreateSalary.Working_Days') || 'Working Days'
                          }
                          {...register(
                            WORKING_DAYS,
                            createsalaryValidators[WORKING_DAYS]
                          )}
                        />
                      </div>
                    </div>
                    <div className={styles.row}>
                      <label className={styles.label}>
                        {t('CreateSalary.Present_Days')}
                      </label>
                      <div className={styles.inputFieldContainer}>
                        <Input
                          type="text"
                          disabled
                          placeholder={
                            t('CreateSalary.Present_Days') || 'Present Days'
                          }
                          {...register(
                            PRESENT_DAYS,
                            createsalaryValidators[PRESENT_DAYS]
                          )}
                        />
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <div className={styles.row}>
                      <label>{t('AddEmp.Hourly Rate')}</label>
                      <div className={styles.inputFieldContainer}>
                        <Input
                          disabled
                          className={styles.input}
                          type="number"
                          min=" 1"
                          {...register(
                            HOUR_RATE,
                            createsalaryValidators[HOUR_RATE]
                          )}
                        />
                        {errors[HOUR_RATE] && (
                          <div className={styles.errorContainer}>
                            <span className={styles.extraSpan}></span>
                            <p className="dashboardFormError">
                              {errors[HOUR_RATE].message}
                            </p>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className={styles.row}>
                      <label>{t('CreateSalary.Hours_Clocked')}</label>
                      <div className={styles.inputFieldContainer}>
                        <Input
                          placeholder={
                            t('CreateSalary.Hours_Clocked') || 'Hours Clocked'
                          }
                          type="number"
                          min="1"
                          disabled
                          {...register(
                            HOUR_CLOCKED,
                            createsalaryValidators[HOUR_CLOCKED]
                          )}
                        />
                        {errors[HOUR_CLOCKED] && (
                          <div className={styles.errorContainer}>
                            <span className={styles.extraSpan}></span>
                            <p className="dashboardFormError">
                              {errors[HOUR_CLOCKED].message}
                            </p>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className={styles.row}>
                      <label>{t('CreateSalary.Total_Hours_Payment')}</label>
                      <div className={styles.inputFieldContainer}>
                        <Input
                          placeholder={t('InvoicePayment.Payment') || 'Payment'}
                          disabled
                          {...register(
                            TOTAL_SALARY,
                            createsalaryValidators[TOTAL_SALARY]
                          )}
                        />
                      </div>
                    </div>
                    {/* <div className={styles.row}>
                      <label>Expense Claim</label>
                      <div className={styles.inputFieldContainer}>
                        <Input
                          type="number"
                          min="1"
                          placeholder="Expense Claim"
                          {...register(
                            EXPENSE_CLAIM,
                            createsalaryValidators[EXPENSE_CLAIM]
                          )}
                        />
                        {errors[EXPENSE_CLAIM] && (
                          <div className={styles.errorContainer}>
                            <span className={styles.extraSpan}></span>
                            <p className="dashboardFormError">
                              {errors[EXPENSE_CLAIM].message}
                            </p>
                          </div>
                        )}
                      </div>
                    </div> */}
                  </>
                )}

                <div className={styles.row}>
                  <label>{t('MobileAppointmentReq.Status')}</label>
                  <div className={styles.inputFieldContainer}>
                    <select
                      className={styles.selectInputFields}
                      {...register(
                        STATUS_TYPE,
                        createsalaryValidators[STATUS_TYPE]
                      )}
                    >
                      <option value="">{t('ReportGenerator.Select')}</option>
                      {PaymentArray?.map((PaymentArray, index) => (
                        <option value={PaymentArray} key={index}>
                          {PaymentArray}
                        </option>
                      ))}
                    </select>
                    {errors[STATUS_TYPE] && (
                      <div className={styles.errorContainer}>
                        <span className={styles.extraSpan}></span>
                        <p className="dashboardFormError">
                          {errors[STATUS_TYPE].message}
                        </p>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            {/* )} */}

            {/* {showDiv && ( */}
            <div className={styles.usd}>
              <div className={styles.addContainer}>
                <h3>{t('CreateSalary.Allowances')}</h3>
                <div className={styles.data}>
                  {/* {inputContainers.map((item, index) => {
                      return (
                        <>
                          <div className={styles.rows}>
                            <input
                              disabled
                              type="text"
                              placeholder="Bonus"
                              {...register(
                                `${ALLOWANCE_NAME1}.${[
                                  index,
                                ]}.${ALLOWANCE_NAME1}`,
                                createsalaryValidators[ALLOWANCE_NAME1]
                              )}
                            />
                            <input
                              disabled
                              type="text"
                              placeholder="value"
                              {...register(
                                `${ALLOWANCE_VALUE1}.${[
                                  index,
                                ]}.${ALLOWANCE_VALUE1}`,
                                createsalaryValidators[ALLOWANCE_VALUE1]
                              )}
                            />
                            <label
                              className={styles.label}
                              style={{ margin: '0 20px 0 5px' }}
                            >
                              USD
                            </label>
                          </div>
                        </>
                      )
                    })} */}

                  {allowanceFields.map((s: any, index: number) => {
                    const loopData = allowanceFields?.map(
                      (s: any) => s?.allowance_name
                    )
                    const filterData = loopData?.filter((x: any) => x?.length)

                    let data = inputContainers?.map((itm: any) => {
                      return itm?.allowance_name
                    })
                    const arr2 = allowanceFields.map((y: any) =>
                      data.includes(y?.allowance_name)
                    )

                    return (
                      <div key={s?.value} className={styles.rows}>
                        <select
                          key={s?.value}
                          className={styles.dynamicFieldsSelect}
                          {...register(
                            `fixed_allowance.[${index}].allowance_name`
                          )}
                          // defaultValue={s?.allowance_name}
                          onChange={(event) =>
                            handleAllowanceChange(event, index)
                          }
                          value={
                            selectedOptionsAllowance[index]?.allowance_name
                          }
                          disabled={arr2[index]}
                        >
                          {allowanceDropdownData?.map((s, index) => {
                            const arr = allowanceDropdownData?.map((x) =>
                              filterData.includes(x.label)
                            )
                            return (
                              <option
                                key={index}
                                disabled={arr[index]}
                                value={s?.label}
                              >
                                {s.label}
                              </option>
                            )
                          })}
                        </select>
                        <input
                          key={s?.value}
                          // className={styles.dynamicFieldsInput}
                          type="number"
                          {...register(
                            `fixed_allowance.[${index}].allowance_value`
                          )}
                          onChange={(e: any) => {
                            setinputValue(e.target.value)
                            handleAllowance(index, e.target.value)
                            disableArrowKey(e)
                          }}
                          onKeyDown={(e: any) => {
                            disableArrowKey(e)
                            blockInvalidCharacter(e)
                          }}
                          onWheel={(e: any) => {
                            e.target.blur()
                          }}
                          defaultValue={s?.allowance_value}
                          disabled={arr2[index]}
                        />
                        <label
                          className={
                            !arr2[index] ? styles.label : styles.newLabel
                          }
                        ></label>
                        {!arr2[index] && (
                          <CrossIcon
                            handleClick={() => allowanceRemove(index)}
                            fillColor={colors.white1}
                            fillColor1={colors.red1}
                          />
                        )}
                      </div>
                    )
                  })}
                </div>

                <div className={styles.buttonConatiner}>
                  <Button
                    title={t('ActionBar.Add') || 'Add'}
                    type="button"
                    customClass={styles.btn1}
                    handleClick={handleAdd}
                    // disable={
                    //   inputValue.length || !fixedAllowanceData?.length
                    //     ? false
                    //     : true
                    // }
                  />
                </div>
              </div>
              <div className={styles.addContainer}>
                <h3>{t('Payroll.Deduction')}</h3>
                <div className={styles.data}>
                  {/* {inputContainer.map((item, index) => {
                      return (
                        <div className={styles.rows}>
                          <input
                            disabled
                            type="text"
                            placeholder="Bonus"
                            {...register(
                              `${DEDUCTION_NAME1}.${[
                                index,
                              ]}.${DEDUCTION_NAME1}`,
                              createsalaryValidators[DEDUCTION_NAME1]
                            )}
                          />
                          <input
                            disabled
                            type="text"
                            placeholder="value"
                            {...register(
                              `${DEDUCTION_VALUE1}.${[
                                index,
                              ]}.${DEDUCTION_VALUE1}`,
                              createsalaryValidators[DEDUCTION_VALUE1]
                            )}
                          />
                          <label className={styles.label}>USD</label>
                        </div>
                      )
                    })} */}
                  {deductionFields?.map((s: any, index: number) => {
                    const loopData = deductionFields?.map(
                      (s: any) => s?.deduction_name
                    )
                    const filterData = loopData?.filter((x: any) => x?.length)
                    let data = inputContainer?.map((itm: any) => {
                      return itm?.deduction_name
                    })
                    const arr2 = deductionFields.map((y: any) =>
                      data.includes(y?.deduction_name)
                    )

                    return (
                      <div key={s?.value} className={styles.rows}>
                        <select
                          key={s?.value}
                          className={styles.dynamicFieldsSelect}
                          {...register(
                            `fixed_deductions.[${index}].deduction_name`
                          )}
                          // defaultValue={s?.deductionName}
                          onChange={(event) =>
                            handleDeductionChange(event, index)
                          }
                          value={
                            selectedOptionsDeduction?.[index]?.deduction_name
                          }
                          disabled={arr2[index]}
                        >
                          {deductionDropdownData?.map((s, index) => {
                            const arr = deductionDropdownData?.map((x) =>
                              filterData?.includes(x.label)
                            )
                            return (
                              <option
                                key={index}
                                disabled={arr[index]}
                                value={s?.label}
                              >
                                {s.label}
                              </option>
                            )
                          })}
                        </select>
                        <input
                          key={s?.value}
                          // className={styles.dynamicFieldsInput}
                          type="number"
                          {...register(
                            `fixed_deductions.[${index}].deduction_value`
                          )}
                          onChange={(e: any) => {
                            setDeductionValue(e.target.value)
                            handleDeduct(index, e.target.value)
                            disableArrowKey(e)
                          }}
                          // onKeyDown={handleKeyDown}
                          onKeyDown={(e: any) => {
                            disableArrowKey(e)
                            blockInvalidCharacter(e)
                          }}
                          onWheel={(e: any) => {
                            e.target.blur()
                          }}
                          defaultValue={s?.deduction_value}
                          disabled={arr2[index]}
                        />
                        <label
                          className={
                            !arr2[index] ? styles.label : styles.newLabel
                          }
                        ></label>
                        {!arr2[index] && (
                          <CrossIcon
                            handleClick={() => {
                              deductionRemove(index)
                              setDeductionValue(0)
                            }}
                            fillColor={colors.white1}
                            fillColor1={colors.red1}
                          />
                        )}
                      </div>
                    )
                  })}
                </div>
                <div className={styles.buttonConatiner}>
                  <Button
                    title={t('ActionBar.Add') || 'Add'}
                    type="button"
                    customClass={styles.btn1}
                    handleClick={handleDeductionAdd}
                  />
                </div>
              </div>
            </div>
            {/* )} */}

            {/* {showDiv && ( */}
            <div className={styles.grosssalary}>
              <h3>{t('CreateSalary.Gross_Salary')}</h3>
              <div className={styles.salary}>
                <div className={styles.row}>
                  <div className={styles.formFieldContainer}>
                    <label style={{ marginTop: '0px' }}>
                      {t('CreateSalary.Gross_Salary')}
                      <span className="asterick">*</span>
                    </label>
                    <div className={styles.inputFieldContainer}>
                      <input
                        value={location?.state?.user?.total_salary || 0}
                        disabled
                        className={styles.grossFieldStyle}
                      />
                      {/* <Input
                        disabled
                        placeholder="Basic Salary"
                        {...register(
                          NET_SALARY,
                          createsalaryValidators[NET_SALARY]
                        )}
                      /> */}
                    </div>
                  </div>
                </div>
                <div className={styles.row}>
                  <div className={styles.formFieldContainer}>
                    <label
                      className={styles.text}
                      style={{ marginTop: '0px', marginRight: '18px' }}
                    >
                      {t('CreateSalary.Total_Allowance')}
                      <span className="asterick">*</span>
                    </label>
                    <div className={styles.inputFieldContainer}>
                      <Input
                        placeholder={
                          t('CreateSalary.Total_Allowance') || 'Total Allowance'
                        }
                        disabled
                        {...register(
                          TOTAL_ALLOWANCE,
                          createsalaryValidators[TOTAL_ALLOWANCE]
                        )}
                        onChange={(e: any) => trimValue(e)}
                      />
                      {errors[TOTAL_ALLOWANCE] && (
                        <div className={styles.errorContainer}>
                          <span className={styles.extraSpan}></span>
                          <p className="dashboardFormError">
                            {errors[TOTAL_ALLOWANCE].message}
                          </p>
                        </div>
                      )}
                    </div>
                  </div>
                </div>

                <div className={styles.row}>
                  <div className={styles.formFieldContainer}>
                    <label style={{ marginTop: '0px' }}>
                      {t('Payroll.Total_Deduction')}
                      <span className="asterick">*</span>
                    </label>
                    <div className={styles.inputFieldContainer}>
                      <Input
                        placeholder={
                          t('Payroll.Total_Deduction') || 'Total Deduction'
                        }
                        disabled
                        {...register(
                          TOTAL_DEDUCTION,
                          createsalaryValidators[TOTAL_DEDUCTION]
                        )}
                        onChange={(e: any) => trimValue(e)}
                      />
                      {errors[TOTAL_DEDUCTION] && (
                        <div className={styles.errorContainer}>
                          <span className={styles.extraSpan}></span>
                          <p className="dashboardFormError">
                            {errors[TOTAL_DEDUCTION].message}
                          </p>
                        </div>
                      )}
                    </div>
                  </div>
                </div>

                <div className={styles.row}>
                  <div className={styles.formFieldContainer}>
                    <label>
                      {t('CreateSalary.Net_Salary')}
                      <span className="asterick">*</span>
                    </label>
                    <div className={styles.inputFieldContainer}>
                      <Input
                        disabled
                        placeholder={
                          t('CreateSalary.Net_Salary') || 'Net Salary'
                        }
                        {...register(
                          NET_SALARY,
                          createsalaryValidators[NET_SALARY]
                        )}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.buttonConatiner}>
                <Button
                  type="submit"
                  title={t('Common.Submit') || 'Submit'}
                  customClass={styles.button}
                  // handleClick={() => showValidationErrors()}
                />
                <Button
                  title={t('Common.Back') || 'Back'}
                  customClass={styles.resetButtonStyle}
                  handleClick={() => {
                    navigate('/payroll')
                  }}
                />
              </div>
            </div>
            {/* )} */}
          </fieldset>
        </form>
      </div>
    </>
  )
}
export default Create
