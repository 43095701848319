import {
  CheckIcon,
  UncheckIcon,
} from '../../../components/common/svg-components'
import { colors } from '../../../constants/color'
import moment from 'moment'
import { useAppDispatch, useAppSelector } from '../../../hooks'
import {
  getInventoryIssueData,
  getInventoryPoData,
  removeInventoryIssueData,
  removeInventoryPoData,
  setIssueCheckStatus,
} from '../../../redux/features/inventory-request/inventoryRequestSlice'
import { t } from 'i18next'

export const mainstoreRequestPopupHeaderData: any = [
  {
    Header: t('RcptTableHeader.DATE'),
    accessor: (row: any) => {
      return moment(row?.date).format('DD-MMM-YYYY') ?? '-'
    },
  },
  {
    Header: t('INVTableHeader.REQID'),
    accessor: (row: any) => {
      return row?.req_no?.toString().padStart(6, '0') ?? '-'
    },
    disableSortBy: true,
  },
  {
    Header: t('INVTableHeader.SOURCE'),
    accessor: (row: any) => {
      return row?.request_source ?? '-'
    },
  },
  {
    Header: t('ViewInventory.ITEM NAME'),
    accessor: 'item_name',
  },
  {
    Header: t('INVTableHeader.AvailStockQTY'),
    accessor: 'available_qty',
  },
  {
    Header: t('MainStore.FULFILLED QTY'),
    accessor: 'fulfilled_qty',
  },
  {
    Header: t('MainStore.BASE UNIT TYPE'),
    accessor: 'base_unit_type.value',
  },
  {
    Header: t('MainStore.REQ. UNIT TYPE'),
    accessor: 'req_unit_type.value',
  },
  {
    Header: t('INVTableHeader.ISSUE'),
    disableSortBy: true,
    Cell: (props: any) => {
      const { issueCheckStatus, getAllInventoryRequestData } = useAppSelector(
        (state) => state.inventoryRequest
      )

      const dispatch = useAppDispatch()
      const loadIssueData = () => {
        const checkCommonIds = getAllInventoryRequestData?.find(
          (s: any) => s._id === props.row.original._id
        )

        let inventoryIssueData = {
          ...checkCommonIds,
        }

        dispatch(getInventoryIssueData(inventoryIssueData))
      }

      const addIssueId = () => {
        dispatch(setIssueCheckStatus(props.row.original._id))
      }

      const removeIssueData = () => {
        dispatch(removeInventoryIssueData(props.row.original._id))
      }
      return (
        <>
          {!(props.row.original.tag === 'red') ? (
            <div onClick={addIssueId}>
              {issueCheckStatus.includes(props.row.original._id) ? (
                <CheckIcon
                  fillColor={colors.green1}
                  handleClick={() => removeIssueData()}
                />
              ) : (
                <UncheckIcon
                  fillColor={colors.grey1}
                  handleClick={() => loadIssueData()}
                />
              )}
            </div>
          ) : (
            '-'
          )}
        </>
      )
    },
  },
  {
    Header: t('INVTableHeader.REQ_QTY'),
    accessor: 'requested_qty',
  },
  {
    Header: t('MainStore.RAISE PO'),
    disableSortBy: true,
    Cell: (props: any) => {
      const { poCheckStatus } = useAppSelector(
        (state) => state.inventoryRequest
      )

      const dispatch = useAppDispatch()

      const loadIssueData = () => {
        // const checkCommonIds = getAllInventoryRequestData?.find(
        //   (s: any) => s._id === props.row.original._id
        // );
        // let inventoryIssueData = {
        //   ...checkCommonIds,
        // };
        dispatch(getInventoryPoData(props.row.original))
      }

      // const addIssueId = () => {
      //   dispatch(setPoCheckStatus(props.row.original));
      // };

      const removeIssueData = () => {
        dispatch(removeInventoryPoData(props.row.original._id))
      }
      return (
        // <div onClick={addIssueId}>
        <div>
          {poCheckStatus?.some(
            (item: any) => item._id === props.row.original._id
          ) ? (
            <CheckIcon
              fillColor={colors.green1}
              handleClick={() => removeIssueData()}
            />
          ) : (
            <UncheckIcon
              fillColor={colors.grey1}
              handleClick={() => loadIssueData()}
            />
          )}
        </div>
      )
    },
  },
]
