import {
  INSURANCE_CLAIM_TYPE,
  INSURANCE_COMPANY,
  INSURANCE_REIMBURSEMENT_TYPE,
  MARKETPLACE,
} from '../constants/constant'
import { t } from 'i18next'

export const addInsuranceCompanyValidators = {
  [MARKETPLACE]: {
    required: 'Please select marketplace company',
  },
  [INSURANCE_COMPANY]: {
    required: t('Marketplace_Form.company_validation'),
  },
  [INSURANCE_CLAIM_TYPE]: {
    required: t('InsurancePlanValidators.ClaimType'),
  },
  [INSURANCE_REIMBURSEMENT_TYPE]: {
    required: t('Marketplace_Form.Reimbursement_Type'),
  },
}
