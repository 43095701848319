export const job_type_array = [
  { label: 'All', value: '' },
  { label: 'Internal', value: 'INTERNAL' },
  { label: 'External', value: 'EXTERNAL' },
]

export const job_status_array = [
  { label: 'All Status', value: '' },
  { label: 'Pending', value: 'PENDING' },
  { label: 'Confirmed', value: 'CONFIRMED' },
]
