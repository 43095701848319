import styles from './tableData.module.scss'
import { utcToDate } from '../../utils/utils'
import moment from 'moment'
import { t } from 'i18next'
import MasterAppointmentStatusDropdown from '../../components/common/status-dropdown/master-appointment-status-dropdown/MasterAppoinmentStatusDropdown'

export const actionLogData: any = [
  {
    Header: t('RcptTableHeader.TIME'),
    accessor: 'action_time',
    Cell: ({ row }: any) => {
      const createTime = moment(row?.original?.action_date).format('HH:mm:ss')
      return <p>{createTime}</p>
    },
  },
  {
    Header: t('RcptTableHeader.DATE'),
    Cell: ({ row }: any) => {
      let date = utcToDate(row?.original?.action_date)
      // let date = new Date(row?.original?.action_date).toLocaleDateString();
      return <p>{date}</p>
    },
  },
  {
    Header: t('ViewAppointmentHeader.ACTIVITY'),
    // accessor: "action_activity",
    Cell: ({ row }: any) => {
      let status = row?.original?.apnt_status
      return (
        <MasterAppointmentStatusDropdown
          appointmentStatus={status}
          isDisable={true}
        />
      )
    },
  },
  {
    Header: t('ViewAppointmentHeader.BY'),
    accessor: 'action_taken_by',
    Cell: ({ row }: any) => {
      let status = row?.original?.apnt_status
      return (
        <p>
          {status === 'PENDING'
            ? row?.original?.patient_name
            : row?.original?.action_taken_by}
        </p>
      )
    },
  },
  {
    Header: t('ViewAppointmentHeader.APPOINTMENT DATE'),
    accessor: 'appointment_date',
    Cell: ({ row }: any) => {
      let date = row?.original?.updated_details?.appointment_date
      return <p>{date ? utcToDate(date) : '-'}</p>
    },
  },
  {
    Header: t('ViewAppointmentHeader.APPOINTMENT TIME'),
    accessor: 'appointment_time',
    Cell: ({ row }: any) => {
      let start_time = row?.original?.updated_details?.appointment_time
      let duration = row?.original?.updated_details?.appointment_duration
      const end_time = moment(start_time, 'HH:mm')
        .add(Number(duration), 'minutes')
        .format('HH:mm')
      return (
        <p>{start_time && end_time ? `${start_time} to ${end_time}` : '-'}</p>
      )
    },
  },
  {
    Header: t('ViewAppointmentHeader.PHONE'),
    accessor: 'phone',
    Cell: ({ row }: any) => {
      let phone = row?.original?.updated_details?.phone

      return <p>{phone ? phone : '-'}</p>
    },
  },
]
