const useCurrency = () => {
  const currencyCode = 'KWD'
  const locale = 'en-US'

  const formatCurrency = (amount: number | string): string => {
    let numberAmount: number = Number(amount)
    if (isNaN(numberAmount)) {
      numberAmount = 0
    }

    const hasDecimal = !Number.isInteger(numberAmount)
    const fractionDigits = currencyCode === 'KWD' && hasDecimal ? 3 : 2

    const formatter = new Intl.NumberFormat(locale, {
      style: 'currency',
      currency: currencyCode,
      minimumFractionDigits: hasDecimal ? fractionDigits : 0,
      maximumFractionDigits: fractionDigits,
    })

    let formattedAmount = formatter.format(numberAmount)

    // // Special handling for KWD
    // if (currencyCode === 'KWD') {
    //   // Remove the currency symbol and any leading/trailing whitespace
    //   formattedAmount = formattedAmount.replace(/KWD|[\s]/g, '')
    //   // Add 'KD' to the beginning
    //   formattedAmount = `KD ${formattedAmount}`
    // }

    return formattedAmount
  }

  return { formatCurrency }
}

export default useCurrency
