import { FC } from 'react'
import { CloseIcon } from '../../../components/common/svg-components'
import { colors } from '../../../constants/color'
import { useAppDispatch, useAppSelector } from '../../../hooks'
import Button from '../../../components/common/button/Button'
import Divider from '../../../components/common/divider/Divider'
import Select from 'react-select'
import makeAnimated from 'react-select/animated'
import { StatusArray } from '../../../constants/data'
import styles from './ManageAttendanceModal.module.scss'
import {
  clearState,
  setAddDataInAttendance,
} from '../../../redux/features/attendance/attendanceSlice'
import {
  addAllEmployeeAttendanceAction,
  getMonthlyEmployeeAttendanceAction,
} from '../../../redux/features/attendance/attendanceAsyncAction'
import { requestGenerator } from '../../../utils/payloadGenerator'
import { SubmitHandler, useForm } from 'react-hook-form'
import { IManageAttendanceModalForm } from '../../../interfaces/manageAttendanceInterface'
import { manageAllDaysAttendanceValidator } from './manageAttendanceValidator'
import {
  HALFDAY,
  IS_LATE,
  LEAVE_STATUS,
  LEAVE_TYPE,
  PUNCH_IN,
  PUNCH_OUT,
  REASON,
} from '../../../constants/manageAttendanceConstants'
import moment from 'moment'
import { t } from 'i18next'

interface IPropsData {
  handleClose: (e?: React.MouseEvent<HTMLElement>) => void
  isEditing?: boolean
  setIsEditing?: React.Dispatch<React.SetStateAction<boolean>>
  popData?: any
}

const ManageAttendanceModal: FC<IPropsData> = ({
  handleClose,
  setIsEditing,
  popData,
}) => {
  const dispatch = useAppDispatch()

  const { getAttendance, setAttendanceType } = useAppSelector(
    (state) => state?.attendance
  )
  const animatedComponent = makeAnimated()
  const { masterValueData } = useAppSelector((state) => state.login)

  //React Select Dropdown Styles
  const customDropdownStyles = {
    control: (provided: any) => ({
      ...provided,
      boxShadow: 'none',
      width: '190px',
    }),
    input: (provided: any) => ({
      ...provided,
      padding: '0px !important',
    }),
    option: (provided: any) => ({
      ...provided,
      padding: '5px',
    }),
    indicatorSeparator: () => ({
      display: 'none',
    }),
    dropdownIndicator: (provided: any, state: any) => ({
      ...provided,
      color: '#797979',
      margin: '0px !important',
      padding: '0px',
      transform: state.selectProps.menuIsOpen ? 'rotate(180deg)' : null,
    }),
    clearIndicator: (provided: any) => ({
      ...provided,
      display: 'none',
    }),
  }

  const {
    register,
    reset,
    handleSubmit,
    setValue,
    trigger,
    watch,
    formState: { errors },
  } = useForm<IManageAttendanceModalForm>({})

  const onSubmit: SubmitHandler<IManageAttendanceModalForm> = (data) => {
    const startDate = moment
      .utc(
        `${popData?.selectedMonthYearObj?.month}/${popData?.no}/${popData?.selectedMonthYearObj?.year}`
      )
      .toISOString()

    const endDate = moment
      .utc(
        `${popData?.selectedMonthYearObj?.month}/${popData?.no}/${popData?.selectedMonthYearObj?.year}`
      )
      .toISOString()

    const absentData = [
      {
        name: popData?.name_en,
        emp_id: popData?._id,
        attendance: 'ABSENT',
        start_time: data?.start_time,
        end_time: data?.end_time,
        leave_type:
          data?.leave_type == 'Maternity Leave'
            ? 'MATERNITY_LEAVE'
            : data?.leave_type == 'Medical Leave'
            ? 'MEDICAL_LEAVE'
            : data?.leave_type == 'Haj Leave'
            ? 'HAJ_LEAVE'
            : data?.leave_type == 'Annual Leave'
            ? 'ANNUAL_LEAVE'
            : undefined,
        isPaid: data?.isPaid == 'PAID' ? true : false,
        halfday: data?.halfDay,
        reason: data?.reason,
        startDate: moment(startDate).format('YYYY-MM-DD'),
        endDate: moment(endDate).format('YYYY-MM-DD'),
        is_active: true,
      },
    ]

    const presentData = [
      {
        attendance: 'PRESENT',
        date: moment(startDate).format('YYYY-MM-DD'),
        emp_id: popData?._id,
        endDate: moment(endDate).format('YYYY-MM-DD'),
        end_time: data?.end_time,
        name: popData?.name_en,
        startDate: moment(startDate).format('YYYY-MM-DD'),
        start_time: data?.start_time,
        is_late: data?.is_late,
        reason: '',
        is_active: true,
      },
    ]

    const payloadData = {
      sub_company: popData?.selectedMonthYearObj?.sub_company,
      search: '',
      range: {
        fromDate: moment
          .utc(
            `${popData?.selectedMonthYearObj?.month}/01/${popData?.selectedMonthYearObj?.year} `
          )
          .startOf('month')
          .toISOString(),
        toDate: moment
          .utc(
            `${popData?.selectedMonthYearObj?.month}/01/${popData?.selectedMonthYearObj?.year}`
          )
          .endOf('month')
          .toISOString(),
      },
      department_id: popData?.selectedMonthYearObj?.department_id,
    }

    if (setAttendanceType === 'PRESENT') {
      dispatch(
        addAllEmployeeAttendanceAction(requestGenerator(presentData))
      ).then((e) => {
        if (e.type === 'attendanceSlice/addAllEmployeeAttendance/fulfilled') {
          dispatch(
            getMonthlyEmployeeAttendanceAction(requestGenerator(payloadData))
          )
          dispatch(clearState())
          handleClose()
        } else {
        }
      })
    } else {
      dispatch(
        addAllEmployeeAttendanceAction(requestGenerator(absentData))
      ).then((e) => {
        if (e.type === 'attendanceSlice/addAllEmployeeAttendance/fulfilled') {
          dispatch(
            getMonthlyEmployeeAttendanceAction(requestGenerator(payloadData))
          )
          dispatch(clearState())
          handleClose()
        } else {
        }
      })
    }
  }

  const handleTextAreaChange = (e: any) => {
    let reasonType = e?.target?.value
    const attendanceTiming = {
      ...getAttendance,
      reason: reasonType,
    }
    dispatch(setAddDataInAttendance(attendanceTiming))
  }

  const isHalfDay = watch(HALFDAY)

  const status = watch(LEAVE_STATUS)

  return (
    <>
      <div
        className={styles.manageAttendanceModalContainer}
        onClick={(e) => e.stopPropagation()}
      >
        <CloseIcon
          customClass={styles.closeIconStyle}
          fillColor={colors.green1}
          handleClick={() => {
            handleClose()
            setIsEditing && setIsEditing(false)
          }}
        />
        <form
          className={styles.markAllDaysForm}
          onSubmit={handleSubmit(onSubmit)}
        >
          {setAttendanceType === 'PRESENT' ? (
            <>
              <div className={styles.manageAttendancePresentModalContainer}>
                <div className={styles.manageAttendanceTitleContainer}>
                  <p className={styles.title}>{t('Attendance.Present')}</p>
                  <Divider customClass={styles.dividerStyle} />
                </div>
                <div className={styles.formRowContainer}>
                  <div className={styles.formFieldContainer}>
                    <div className={styles.inputFieldContainer}>
                      <label htmlFor={PUNCH_IN} className={styles.formLabel}>
                        {t('Attendance.Punch_In')}
                        <span className="asterick">*</span>
                      </label>
                      <div>
                        <input
                          type="time"
                          className={styles.inputField}
                          {...register(
                            PUNCH_IN,
                            manageAllDaysAttendanceValidator[PUNCH_IN]
                          )}
                        />
                        {errors[PUNCH_IN] && (
                          <div className={styles.errorContainer2}>
                            <p className="dashboardFormError">
                              {errors[PUNCH_IN].message}
                            </p>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className={styles.formFieldContainer}>
                    <div className={styles.inputFieldContainer}>
                      <label htmlFor={PUNCH_OUT} className={styles.formLabel}>
                        {t('Attendance.Punch_Out')}
                        <span className="asterick">*</span>
                      </label>
                      <div>
                        <input
                          type="time"
                          className={styles.inputField}
                          {...register(
                            PUNCH_OUT,
                            manageAllDaysAttendanceValidator[PUNCH_OUT]
                          )}
                        />
                        {errors[PUNCH_OUT] && (
                          <div>
                            <span className={styles.extraSpan}></span>
                            <p className="dashboardFormError">
                              {errors[PUNCH_OUT].message}
                            </p>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>

                  <div>
                    <div className={styles.formFieldContainer}>
                      <div className={styles.inputFieldContainer}>
                        <label className={styles.formLabel}>
                          {t('Attendance.Late')}
                        </label>
                        <input
                          type="checkbox"
                          className={styles.checkboxField}
                          {...register(IS_LATE)}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <>
              <div className={styles.manageAttendanceAbsentModalContainer}>
                <div className={styles.manageAttendanceTitleContainer}>
                  <p className={styles.title}>{t('Attendance.Absent')}</p>
                  <Divider customClass={styles.dividerStyle} />
                </div>
                {/* Reason */}
                <div className={styles.textareaFieldContainer}>
                  <div className={styles.inputFieldContainer}>
                    <label htmlFor="" className={styles.formLabel}>
                      {t('MobileAppointmentReq.Status')}
                      <span className="asterick">*</span>
                    </label>
                    <div className={styles.attendanceFormInputContainer}>
                      <Select
                        className={styles.selectInputField}
                        placeholder={t('MobileAppointmentReq.Status')}
                        options={StatusArray?.map((item: any) => ({
                          label: item,
                          value: item,
                        }))}
                        {...register(
                          LEAVE_STATUS,
                          manageAllDaysAttendanceValidator[LEAVE_STATUS]
                        )}
                        onChange={(e: any) => {
                          setValue(LEAVE_STATUS, e?.value)
                        }}
                        components={animatedComponent}
                        closeMenuOnSelect={true}
                        maxMenuHeight={120}
                        styles={customDropdownStyles}
                      />
                    </div>
                  </div>
                  {errors[LEAVE_STATUS] && (
                    <div className={styles.errorContainer}>
                      <span className={styles.extraSpanTextarea}></span>
                      <p className="dashboardFormError">
                        {errors[LEAVE_STATUS].message}
                      </p>
                    </div>
                  )}
                  <div className={styles.inputFieldContainer}>
                    <label htmlFor="" className={styles.formLabel}>
                      {t('Attendance.Leave_Type')}
                      <span className="asterick">*</span>
                    </label>
                    <div className={styles.attendanceFormInputContainer}>
                      <Select
                        className={styles.selectInputField}
                        placeholder={t('Attendance.Leave_Type')}
                        options={masterValueData
                          ?.find(
                            (item: any) => item?.category_name === 'LEAVE-TYPE'
                          )
                          ?.values?.map((item: any) => ({
                            label: item?.value,
                            value: item?._id,
                          }))}
                        {...register(
                          LEAVE_TYPE,
                          manageAllDaysAttendanceValidator[LEAVE_TYPE] as any
                        )}
                        onChange={(e: any) => {
                          setValue(LEAVE_TYPE, e?.label)
                        }}
                        components={animatedComponent}
                        closeMenuOnSelect={true}
                        maxMenuHeight={120}
                        styles={customDropdownStyles}
                        isDisabled={status === 'UNPAID' ? true : false}
                      />
                      <label className={styles.checkboxContainer}>
                        <input
                          type="checkbox"
                          className={styles.checkboxField}
                          {...register(HALFDAY)}
                        />
                        <span className={styles.checkboxLabel}></span>
                        {t('Attendance.Half_Day')}
                      </label>
                    </div>
                  </div>
                  {errors[LEAVE_TYPE] && (
                    <div className={styles.errorContainer}>
                      <span className={styles.extraSpanTextarea}></span>
                      <p className="dashboardFormError">
                        {errors[LEAVE_TYPE].message as any}
                      </p>
                    </div>
                  )}

                  {isHalfDay && (
                    <div className={styles.formRowContainer}>
                      <div className={styles.formFieldContainer}>
                        <div className={styles.inputFieldContainer}>
                          <label
                            htmlFor={PUNCH_IN}
                            className={styles.formLabel}
                          >
                            {t('Attendance.Punch_In')}
                            <span className="asterick">*</span>
                          </label>
                          <div>
                            <input
                              type="time"
                              className={styles.inputField}
                              {...register(
                                PUNCH_IN,
                                manageAllDaysAttendanceValidator[PUNCH_IN]
                              )}
                            />
                            {errors[PUNCH_IN] && (
                              <div className={styles.errorContainer2}>
                                <p className="dashboardFormError">
                                  {errors[PUNCH_IN].message}
                                </p>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>

                      <div className={styles.formFieldContainer}>
                        <div className={styles.inputFieldContainer}>
                          <label
                            htmlFor={PUNCH_OUT}
                            className={styles.formLabel}
                          >
                            {t('Attendance.Punch_Out')}
                            <span className="asterick">*</span>
                          </label>
                          <div>
                            <input
                              type="time"
                              className={styles.inputField}
                              {...register(
                                PUNCH_OUT,
                                manageAllDaysAttendanceValidator[PUNCH_OUT]
                              )}
                            />
                            {errors[PUNCH_OUT] && (
                              <div>
                                <span className={styles.extraSpan}></span>
                                <p className="dashboardFormError">
                                  {errors[PUNCH_OUT].message}
                                </p>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                      <div></div>
                    </div>
                  )}

                  <div className={styles.inputFieldContainer}>
                    <label htmlFor={REASON} className={styles.formLabel}>
                      {t('StatusConfirmation.Reason')}
                      <span className="asterick">*</span>
                    </label>
                    <textarea
                      className={styles.textareaInputField}
                      placeholder={
                        t('Attendance.reason_leave') || 'Enter Reason For Leave'
                      }
                      {...register(
                        REASON,
                        manageAllDaysAttendanceValidator[REASON]
                      )}
                      onChange={(e) => handleTextAreaChange(e)}
                    />
                  </div>
                  {errors[REASON] && (
                    <div className={styles.errorContainer}>
                      <span className={styles.extraSpanTextarea}></span>
                      <p className="dashboardFormError">
                        {errors[REASON].message}
                      </p>
                    </div>
                  )}
                </div>
              </div>
            </>
          )}

          <div className={styles.btnContainer}>
            <Button
              title={t('Common.Submit') || 'Submit'}
              type="submit"
              customClass={styles.submitBtn}
            />
            <Button
              title={t('BranchSetup.Reset') || 'Reset'}
              type="reset"
              customClass={styles.resetBtn}
            />
          </div>
        </form>
      </div>
    </>
  )
}
export default ManageAttendanceModal
