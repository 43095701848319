import moment from 'moment'
import { utcToDate } from '../../utils/utils'
import { t } from 'i18next'
import MasterAppointmentStatusDropdown from '../../components/common/status-dropdown/master-appointment-status-dropdown/MasterAppoinmentStatusDropdown'

export const appointmentLogData: any = [
  {
    Header: t('PatientHistoryFormHeader.PATIENT NAME'),
    accessor: 'patient_name',
  },
  {
    Header: t('RcptTableHeader.STATUS'),
    Cell: ({ row }: any) => {
      let status = row?.original?.apnt_status
      return (
        <MasterAppointmentStatusDropdown
          appointmentStatus={status}
          isDisable={true}
        />
      )
    },
  },
  {
    Header: t('ViewAppointmentHeader.TIME SPENT'),
    // accessor: "timeSpent",
    Cell: ({ row }: any) => {
      let time = row?.original?.timeSpent
      return <p>{time !== null ? time + ' min' : '-'}</p>
    },
  },
  {
    Header: t('RcptTableHeader.TIME'),
    accessor: 'action_time',
    Cell: ({ row }: any) => {
      const createTime = moment(row?.original?.action_date).format('HH:mm:ss')
      return <p>{createTime}</p>
    },
  },
  {
    Header: t('RcptTableHeader.DATE'),
    Cell: ({ row }: any) => {
      let date = utcToDate(row?.original?.action_date)
      return <p>{date}</p>
    },
  },
]
