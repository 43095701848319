import React, { FC, useEffect, useRef, useState } from 'react'
import styles from './invoiceInformation.module.scss'
import { failure, warning } from '../../../constants/data'
import {
  allowedNumberOfDigitsAfterDecimal,
  trimValue,
} from '../../../utils/utils'
import { useAppDispatch, useAppSelector } from '../../../hooks'
import { SubmitHandler, useForm } from 'react-hook-form'
import { IPatientInvoiceForm } from '../../../interfaces/interfaces'
import {
  INVOICE_PATIENT_BRANCH_NAME,
  INVOICE_PATIENT_FILE_NO,
  INVOICE_PATIENT_MOBILE_NO,
  INVOICE_PATIENT_NAME,
  INVOICE_PATIENT_NATIONAL_ID,
  INVOICE_PATIENT_PRIMARY_DOCTOR,
  INVOICE_PATIENT_TYPE,
  INVOICE_REFERRAL_TYPE,
  INVOICE_REFER_BY,
  INVOICE_TYPE,
} from '../../../constants/constant'
import { receptionistInvoiceValidators } from '../../../form-validators/receptionistInvoiceValidators'
import Button from '../../../components/common/button/Button'
import PhoneInput from 'react-phone-input-2'
import {
  DropDownArrowIcon,
  DropDownIcon,
  EyeIcon,
  SearchIcon,
} from '../../../components/common/svg-components'
import Divider from '../../../components/common/divider/Divider'
import Popup from '../../../components/common/popup/Popup'
import SearchModal from '../../../components/common/modal/search-modal/SearchModal'
import DoctorModal from '../../../components/common/modal/doctor-search-modal/DoctorModal'
import AddInsuranceModal from '../../../components/common/modal/add-insurance-modal/AddInsuranceModal'
import SettledInvoiceModal from '../../../components/common/modal/settled-invoice-modal/SettledInvoiceModal'
import { getAllDoctors } from '../../../redux/features/appointments/bookingAppointmentAsyncActions'
import { requestGenerator } from '../../../utils/payloadGenerator'
import { useNavigate } from 'react-router-dom'
import {
  clearRegularPatientData,
  savePatientInvoice,
} from '../../../redux/features/invoice-module/invoiceSlice'
import DescriptionDataModal from '../../../components/common/modal/description-data-Modal/DescriptionDataModal'
import { setMessage } from '../../../redux/features/toast/toastSlice'
import AddInsuranceApprovalNoModal from '../../../components/common/modal/add-insurance-approvalNo-modal/AddInsuranceApprovalNoModal'
import { clearDoctorIdState } from '../../../redux/features/receptionist/receptionistSlice'
import GlobalPatientInfoModal from '../../../components/common/patient-info-modal/PatientInfoModal'

import Loader from '../../../components/common/spinner/Loader'
import { useTranslation } from 'react-i18next'
import useCurrency from '../../../hooks/useCurrency'
interface IInvoiceInformation {}

const InvoiceInformation: FC<IInvoiceInformation> = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const { t } = useTranslation()
  const ref = useRef<any>()
  const { branchData } = useAppSelector((state) => state.login)
  const { formatCurrency } = useCurrency()
  const {
    invoiceObjectById,
    generatedInvoiceObject,
    patientInvoiceData,
    selectedInsurancePlan,
    isLoading,
  } = useAppSelector((state) => state.invoice)
  const referredDoctor = useAppSelector(
    (state) => state.invoice?.invoiceObjectById?.refer_info?.doctor_name
  )

  const { doctorDataById } = useAppSelector((state) => state.receptionist)

  const { doctorData } = useAppSelector((state) => state.appointments)

  const disable = true

  const [prefixValue, setPrefixValue] = useState<any>('')
  const [suffixValue, setSuffixValue] = useState<any>('')
  const [patientType, setPatientType] = useState('REGULAR')
  const [showInsurance, setShowInsurance] = useState(true)
  const [showReferralInfo, setShowReferralInfo] = useState(false)
  const [showSearchModal, setShowSearchModal] = useState<boolean>(false)

  const [searchModalData, setSearchModalData] = useState({})
  const [showDoctorModal, setShowDoctorModal] = useState<boolean>(false)
  const [doctorModalData, setDoctorModalData] = useState({})
  const [showPatientInfoModal, setShowPatientInfoModal] =
    useState<boolean>(false)

  const [showInsuranceModal, setShowInsuranceModal] = useState<boolean>(false)
  const [insuranceModalData, setInsuranceModalData] = useState({})

  const [showSettleInvoiceModal, setShowSettleInvoiceModal] =
    useState<boolean>(false)

  const [showDescriptionModal, setShowDescriptionModal] =
    useState<boolean>(false)
  const [descriptionPopupData, setDescriptionPopupData] = useState<any>({})
  const [showNotesModal, setShowNotesModal] = useState<boolean>(false)
  const [notesPopupData, setNotesPopupData] = useState<any>({})
  const [showApprovalNoModal, setShowApprovalNoModal] = useState<boolean>(false)
  const [approvalNoPopupData, setApprovalNoPopupData] = useState<any>({})
  const [selecteddoctorName, setSelectedDoctorName] = useState<any>(
    doctorDataById?._id
  )
  const [patientInfoDisabled, setPatientInfoDisabled] = useState<boolean>(false)

  const invoiceTypeOptions = [
    {
      name: 'Select Invoice Type',
      _id: '',
    },
    {
      name: 'Diagnosis',
      _id: 'DIAGNOSIS',
    },
    {
      name: 'Dental Diagnosis',
      _id: 'DENTAL_DIAGNOSIS',
    },
  ]
  const [invoiceType, setInvoiceType] = useState<any>(invoiceTypeOptions[0])
  useEffect(() => {
    if (patientInvoiceData?.patient_type === 'OUT PATIENT') {
      setPatientType('OUT PATIENT')
    } else {
      setPatientType('REGULAR')
    }
  }, [patientInvoiceData?.patient_type])

  // handleshowInsurance
  const handleShowInsurance = () => {
    setShowInsurance(!showInsurance)
  }

  // handleshowReferralInfo
  const handleShowReferralInfo = () => {
    setShowReferralInfo(!showReferralInfo)
  }

  // FORM
  const {
    register,
    reset,
    handleSubmit,
    setValue,
    getValues,
    trigger,
    watch,
    formState: { errors },
  } = useForm<IPatientInvoiceForm>({})
  const formData = watch()

  const onSubmit: SubmitHandler<IPatientInvoiceForm> = (data: any) => {
    data.patient_type = patientType
    data.refer_by = selecteddoctorName
    data.insurance_approval_no = selectedInsurancePlan?.insurance_approval_no
    data.insurance_approval_no = selectedInsurancePlan?.insurance_approval_no
    data.patient_insurance_id = selectedInsurancePlan?.patient_insurance_id
    if (
      data?.insurance_plan_ids &&
      data?.insurance_plan_ids?.length > 0 &&
      !selectedInsurancePlan?.patient_insurance_id
    ) {
      dispatch(
        setMessage({
          message:
            t('InvoiceInfo.SelectOneINS') || 'Please select one insurance plan',
          type: failure,
        })
      )
    } else {
      let diagnosisServiceWithDicPercantage: any[] = []
      if (data?.diagnosis_services && data?.diagnosis_services?.length > 0) {
        diagnosisServiceWithDicPercantage = data.diagnosis_services.map(
          (item: any) => {
            const { quantity, unitPrice, discount } = item
            const percantage = discount
              ? (discount * 100) / (quantity * unitPrice)
              : 0
            return {
              ...item,
              discountPercantage: percantage
                ? allowedNumberOfDigitsAfterDecimal(percantage, 3)
                : 0,
            }
          }
        )
      }
      dispatch(
        savePatientInvoice({
          ...data,
          diagnosis_services: diagnosisServiceWithDicPercantage,
        })
      )
      navigate('/invoice/services')
    }
  }

  useEffect(() => {
    if (doctorDataById?._id) {
      setSelectedDoctorName(doctorDataById?._id)
    }
  }, [doctorDataById?._id])

  // handleSearchPopup
  const handleSearchPopup = () => {
    setShowSearchModal(!showSearchModal)
  }
  // search modal close
  const handleSearchModalClose = () => {
    setShowSearchModal(!showSearchModal)
    setSearchModalData({})
  }

  // handleDoctorPopup
  const handleDoctorPopup = () => {
    setShowDoctorModal(!showDoctorModal)
  }
  // Doctor modal close
  const handleDoctorModalClose = () => {
    setShowDoctorModal(!showDoctorModal)
    setDoctorModalData({})
  }

  // handleInsurancePopup
  const handleInsurancePopup = () => {
    if (!invoiceObjectById?._id) {
      dispatch(
        setMessage({
          message:
            t('InvoiceInfo.LoadPatient') || 'Please load patient id first',
          type: warning,
        })
      )
    } else {
      setShowInsuranceModal(!showInsuranceModal)
      setInsuranceModalData(invoiceObjectById)
    }
  }
  //Insurance modale close
  const handleInsuranceModalClose = () => {
    setShowInsuranceModal(!showInsuranceModal)
    setInsuranceModalData({})
  }

  // handleSettled invoice
  const handleSettledInvoice = () => {
    setShowSettleInvoiceModal(true)
  }

  // set the data on form
  useEffect(() => {
    if (invoiceObjectById?.name) {
      setPatientInfoDisabled(true)
      reset(invoiceObjectById)
    }
  }, [reset, invoiceObjectById])

  useEffect(() => {
    if (patientInvoiceData?.emr_no) {
      const fileNumber = patientInvoiceData?.emr_no
      setPrefixValue(fileNumber?.substring(0, 2))
      setSuffixValue(fileNumber?.slice(2))
    }
  }, [patientInvoiceData?.emr_no])

  const handleSelectInvoiceType = (e: any) => {
    const selectedInvoice = invoiceTypeOptions.find(
      (invoice: any) => invoice?._id === e.target.value
    )
    setInvoiceType(selectedInvoice)
  }

  useEffect(() => {
    let data = {
      search: '',
      page: 0,
      pageSize: 100,
    }
    dispatch(getAllDoctors(requestGenerator(data)))
  }, [dispatch])

  const handleClear = () => {
    dispatch(clearRegularPatientData())
    dispatch(clearDoctorIdState())
    setPrefixValue('')
    setSuffixValue('')
    //setValue(INVOICE_TYPE, '')
    // setValue(INVOICE_PATIENT_BRANCH_NAME, '')
    setValue(INVOICE_PATIENT_FILE_NO, '')
    setValue(INVOICE_PATIENT_NAME, '')
    // setValue(INVOICE_PATIENT_PRIMARY_DOCTOR, '')
    setValue(INVOICE_PATIENT_MOBILE_NO, '')
    setValue(INVOICE_PATIENT_NATIONAL_ID, '')
    //setValue(INVOICE_DOCTOR_NAME, '')
    setValue(INVOICE_REFER_BY, '')
    setValue(INVOICE_REFERRAL_TYPE, '')
    // setShowPatientInfoModal(false)
    setPatientInfoDisabled(false)
    setPatientType('OUT PATIENT')
  }

  const handleRadioChange = (e: any) => {
    setPatientType(e.target.value)
    if (e.target.value === 'OUT PATIENT') {
      handleClear()
    }
    setValue(INVOICE_PATIENT_NAME, '')
  }

  useEffect(() => {
    if (invoiceObjectById === null) {
      handleClear()
      setPatientType('REGULAR')
    }
  }, [invoiceObjectById])

  useEffect(() => {
    const checkIfClickedOutside = (e: any) => {
      if (
        showPatientInfoModal &&
        ref.current &&
        !ref.current.contains(e.target)
      ) {
        setShowPatientInfoModal(false)
      }
    }
    document.addEventListener('mousedown', checkIfClickedOutside)
    return () => {
      document.removeEventListener('mousedown', checkIfClickedOutside)
    }
  }, [showPatientInfoModal])

  const descriptionModalClose = () => {
    setDescriptionPopupData({})
    setShowDescriptionModal((prevState) => !prevState)
  }

  const handleDescriptionModalOpen = (item: any) => {
    const payload = {
      description: item?.details,
    }
    setShowDescriptionModal(!showDescriptionModal)
    setDescriptionPopupData(payload)
  }

  const notesModalClose = () => {
    setNotesPopupData({})
    setShowNotesModal((prevState) => !prevState)
  }

  const handleNotesModalOpen = (item: any) => {
    const payload = {
      description: item?.notes,
    }
    setShowNotesModal(!showNotesModal)
    setNotesPopupData(payload)
  }

  // handleApprovalNoPopup
  const handleApprovalNoPopup = (item: any) => {
    const payload = {
      patient_insurance_id: item?._id,
      insurance_plan: item?.insurance_plan,
    }
    setShowApprovalNoModal(!showApprovalNoModal)
    setApprovalNoPopupData(payload)
  }

  //ApprovalNo modal close
  const handleApprovalNoModalClose = () => {
    setShowApprovalNoModal((prevState) => !prevState)
    setApprovalNoPopupData({})
  }

  const handlePadStart = (invoiceNo: any) => {
    let padstartInvoiceNumber = invoiceNo
    let invoice_number = String(padstartInvoiceNumber).padStart(6, '0')
    return invoice_number
  }

  useEffect(() => {
    if (patientInvoiceData) {
      // branch
      if (patientInvoiceData?.patient_default_branch_id) {
        const found = branchData?.branches?.some(
          (item: any) =>
            item?._id === patientInvoiceData?.patient_default_branch_id
        )

        found
          ? setValue(
              INVOICE_PATIENT_BRANCH_NAME,
              patientInvoiceData?.patient_default_branch_id
            )
          : setValue(
              INVOICE_PATIENT_BRANCH_NAME,
              formData[INVOICE_PATIENT_BRANCH_NAME] ||
                branchData?.branches[0]?._id ||
                ''
            )
      } else {
        // setValue(INVOICE_PATIENT_BRANCH_NAME, '')
      }

      // doctor
      if (patientInvoiceData?.patient_primary_Doctor) {
        setValue(
          INVOICE_PATIENT_PRIMARY_DOCTOR,
          patientInvoiceData?.patient_primary_Doctor
        )
      } else {
        setValue(
          INVOICE_PATIENT_PRIMARY_DOCTOR,
          formData[INVOICE_PATIENT_PRIMARY_DOCTOR] || doctorData[0]?._id || ''
        )
      }

      if (patientInvoiceData?.type) {
        setValue(INVOICE_TYPE, patientInvoiceData?.type)
      } else {
        // setValue(INVOICE_TYPE, '')
      }
    }
  }, [patientInvoiceData])

  return (
    <>
      {isLoading ? <Loader /> : ''}
      {/* popup section */}
      {showSearchModal && (
        <Popup
          Children={SearchModal}
          popData={searchModalData}
          handleClose={() => handleSearchModalClose()}
          setModelOpenClose={setShowSearchModal}
          invoiceFlag={true}
          message={invoiceType._id}
        />
      )}

      {showDoctorModal && (
        <Popup
          Children={DoctorModal}
          popData={doctorModalData}
          handleClose={() => handleDoctorModalClose()}
          setModelOpenClose={setShowDoctorModal}
        />
      )}

      {showInsuranceModal && (
        <Popup
          Children={AddInsuranceModal}
          popData={insuranceModalData}
          handleClose={() => handleInsuranceModalClose()}
          setModelOpenClose={setShowInsuranceModal}
          handleOpen={handleDescriptionModalOpen}
          handleNotesPreview={handleNotesModalOpen}
          handleInsuranceRowClick={handleApprovalNoPopup}
        />
      )}

      {showSettleInvoiceModal && (
        <Popup
          Children={SettledInvoiceModal}
          handleClose={() => setShowSettleInvoiceModal(false)}
          setModelOpenClose={setShowSettleInvoiceModal}
          message={invoiceType._id}
          popData={invoiceObjectById}
        />
      )}

      {showDescriptionModal && (
        <Popup
          Children={DescriptionDataModal}
          handleClose={descriptionModalClose}
          popData={descriptionPopupData}
          heading={t('MobileAppConfiguration.Description') || 'Description'}
        />
      )}

      {showNotesModal && (
        <Popup
          Children={DescriptionDataModal}
          handleClose={notesModalClose}
          popData={notesPopupData}
          heading={t('Common.Notes') || 'Notes'}
        />
      )}

      {showApprovalNoModal && (
        <Popup
          Children={AddInsuranceApprovalNoModal}
          handleClose={handleApprovalNoModalClose}
          popData={approvalNoPopupData}
          setModelOpenClose={setShowApprovalNoModal}
        />
      )}

      <form className={styles.mainContainer} onSubmit={handleSubmit(onSubmit)}>
        <div className={styles.mainFormContainer}>
          {/* form */}
          <div className={styles.formLayoutContainer}>
            <div className={styles.formContainer}>
              {/* branch and patient type */}
              <div className={styles.formLayout}>
                <div className={styles.labelFieldContainer}>
                  <label className={styles.labelText}>
                    {t('Header.Branch')}
                    <span className="asterick">*</span>
                  </label>
                  <div className={styles.fieldErrorContainer}>
                    <select
                      className={styles.selectInputField}
                      // value={
                      //   selectedOption
                      //     ? selectedOption
                      //     : patientInvoiceData?.patient_default_branch_id
                      // }
                      {...register(
                        INVOICE_PATIENT_BRANCH_NAME,
                        receptionistInvoiceValidators[
                          INVOICE_PATIENT_BRANCH_NAME
                        ]
                      )}
                    >
                      <option value="" selected>
                        {t('PatientEMRPlaceHolder.Select branch')}
                      </option>

                      {branchData?.branches
                        ?.filter((item: any) => item?._id)
                        .map((item: any, i: number) => {
                          return (
                            <React.Fragment key={i}>
                              <option
                                value={item?._id}
                                // selected={
                                //   item?._id ===
                                //   invoiceObjectById?.patient_default_branch_id
                                // }
                              >
                                {item?.name}
                              </option>
                            </React.Fragment>
                          )
                        })}
                    </select>
                    <div className={styles.errorContainer}>
                      <span className={styles.extraSpan}></span>
                      {errors[INVOICE_PATIENT_BRANCH_NAME] && (
                        <p className="dashboardFormError">
                          {errors[INVOICE_PATIENT_BRANCH_NAME].message as any}
                        </p>
                      )}
                    </div>
                  </div>
                </div>

                <div className={styles.labelFieldContainer}>
                  <label className={styles.labelText}>
                    {t('ViewAppointment.Patient')}
                  </label>
                  <div className={styles.fieldErrorContainer}>
                    <label htmlFor="regular" className={styles.radioLabel}>
                      <input
                        className={styles.radioInput}
                        type="radio"
                        id="regular"
                        name="patientType"
                        value="REGULAR"
                        checked={patientType === 'REGULAR'}
                        // {...register(
                        //   INVOICE_PATIENT_TYPE,
                        //   receptionistInvoiceValidators[INVOICE_PATIENT_TYPE]
                        // )}
                        onChange={handleRadioChange}
                      />
                      <span className={styles.customRadio} />
                      {t('InvoiceInfo.Regular')}
                    </label>

                    <label htmlFor="outpatient" className={styles.radioLabel}>
                      <input
                        className={styles.radioInput}
                        type="radio"
                        id="outpatient"
                        name="patientType"
                        value="OUT PATIENT"
                        // {...register(
                        //   INVOICE_PATIENT_TYPE,
                        //   receptionistInvoiceValidators[INVOICE_PATIENT_TYPE]
                        // )}
                        onChange={handleRadioChange}
                        onClick={() => setValue(INVOICE_PATIENT_MOBILE_NO, '')}
                      />
                      <span className={styles.customRadio} />
                      {t('InvoiceInfo.Out Patient')}
                    </label>

                    <div className={styles.errorContainer}>
                      <span className={styles.extraSpan}></span>
                      {errors[INVOICE_PATIENT_TYPE] && (
                        <p className="dashboardFormError">
                          {errors[INVOICE_PATIENT_TYPE].message as any}
                        </p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              {/* invoice type and file no */}

              <div className={styles.formLayout}>
                <div className={styles.labelFieldContainer}>
                  <label className={styles.labelText}>
                    {t('InvoiceInfo.Invoice Type')}
                    <span className="asterick">*</span>
                  </label>
                  <div className={styles.fieldErrorContainer}>
                    <select
                      className={styles.selectInputField}
                      {...register(
                        INVOICE_TYPE,
                        receptionistInvoiceValidators[INVOICE_TYPE]
                      )}
                      onChange={handleSelectInvoiceType}
                      defaultValue="DIAGNOSIS"
                      // value={invoiceType?._id}
                    >
                      {invoiceTypeOptions.map((item: any, i: number) => {
                        return (
                          <React.Fragment key={i}>
                            <option value={item?._id}>{item?.name}</option>
                          </React.Fragment>
                        )
                      })}
                    </select>
                    <div className={styles.errorContainer}>
                      <span className={styles.extraSpan}></span>
                      {errors[INVOICE_TYPE] && (
                        <p className="dashboardFormError">
                          {errors[INVOICE_TYPE].message as any}
                        </p>
                      )}
                    </div>
                  </div>
                </div>
                <div className={styles.labelFieldContainer}>
                  <label className={styles.labelText}>
                    {patientType === 'REGULAR'
                      ? t('PatientEMR.File No.')
                      : t('PatientEMR.Fixed File No.')}
                    <span className="asterick">*</span>
                  </label>
                  <div className={styles.fieldErrorContainer}>
                    {patientType === 'REGULAR' ? (
                      <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <input
                          type="text"
                          className={[
                            disable
                              ? styles.disableInputFieldBranchInitials
                              : styles.inputField,
                            patientType === 'REGULAR' && styles.disabledInput,
                          ]?.join(' ')}
                          disabled={true}
                          // defaultValue={prefixValue}
                          value={prefixValue}
                        />
                        <input
                          type="text"
                          className={[
                            disable
                              ? styles.disableInputFieldFileNo
                              : styles.inputField,
                            patientType === 'REGULAR' && styles.disabledInput,
                          ]?.join(' ')}
                          disabled={true}
                          defaultValue={suffixValue}
                          // value={suffixValue}
                        />
                      </div>
                    ) : (
                      <div>
                        <input
                          type="text"
                          className={styles.fixedFileNoinputField}
                          disabled={true}
                          value={
                            generatedInvoiceObject?.fixed_file_number
                              ? handlePadStart(
                                  generatedInvoiceObject?.fixed_file_number
                                )
                              : 'XXXXXX'
                          }
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>

              {/* patient name  and primary doctor*/}
              <div className={styles.formLayout}>
                <div className={styles.labelFieldContainer}>
                  <label
                    htmlFor={INVOICE_PATIENT_NAME}
                    className={styles.labelText}
                  >
                    {t('ShareQuestionnaire.Patient Name')}
                    <span className="asterick">*</span>
                  </label>
                  <div className={styles.fieldErrorContainer}>
                    <input
                      type="text"
                      placeholder={
                        t('PatientEMRPlaceHolder.EnterName') ||
                        'Enter patient name'
                      }
                      className={[styles.searchInputField]?.join(' ')}
                      {...register(
                        INVOICE_PATIENT_NAME,
                        receptionistInvoiceValidators[INVOICE_PATIENT_NAME]
                      )}
                      defaultValue={
                        invoiceObjectById?._id
                          ? invoiceObjectById?.name
                          : patientInvoiceData?.name
                      }
                      onChange={(e) => trimValue(e)}
                      disabled={patientType === 'REGULAR' ? true : false}
                    />

                    {patientType === 'REGULAR' ? (
                      <SearchIcon
                        fillColor="#797979"
                        customClass={styles.searchIconStyle}
                        handleClick={handleSearchPopup}
                      />
                    ) : (
                      ''
                    )}

                    {patientType === 'REGULAR' ? (
                      <>
                        <span ref={ref}>
                          <EyeIcon
                            fillColor="#02BF90"
                            customClass={styles.eyeIconStyle}
                            handleClick={() => {
                              if (patientInfoDisabled) {
                                setShowPatientInfoModal(!showPatientInfoModal)
                              }
                            }}
                          />
                        </span>
                      </>
                    ) : (
                      ''
                    )}
                    {showPatientInfoModal && (
                      <GlobalPatientInfoModal
                        nationalId={invoiceObjectById?.national_id ?? ''}
                        mobileNo={invoiceObjectById?.phone ?? ''}
                        patientImage={invoiceObjectById?.profile_pic}
                      />
                    )}

                    <div className={styles.errorContainer}>
                      <span className={styles.extraSpan}></span>
                      {errors[INVOICE_PATIENT_NAME] && (
                        <p className="dashboardFormError">
                          {errors[INVOICE_PATIENT_NAME].message as any}
                        </p>
                      )}
                    </div>
                  </div>
                </div>
                <div className={styles.labelFieldContainer}>
                  <label className={styles.labelText}>
                    {t('IPDBooking.Primary Doctor')}
                    <span className="asterick">*</span>
                  </label>
                  <div className={styles.fieldErrorContainer}>
                    <select
                      className={styles.selectInputField}
                      placeholder={
                        t('IPDBooking.Select Doctor') || 'Select doctor'
                      }
                      // value={
                      //   selectedDoctorOption
                      //     ? selectedDoctorOption
                      //     : patientInvoiceData?.patient_primary_Doctor
                      // }
                      {...register(
                        INVOICE_PATIENT_PRIMARY_DOCTOR,
                        receptionistInvoiceValidators[
                          INVOICE_PATIENT_PRIMARY_DOCTOR
                        ]
                      )}
                      defaultValue={doctorData ? doctorData[0]?._id : ''}
                      // disabled={patientInvoiceData?.patient_primary_Doctor ? true : false}
                    >
                      <option value="" selected>
                        {t('IPDBooking.Select Doctor')}
                      </option>

                      {doctorData?.map((item: any, i: number) => {
                        return (
                          <React.Fragment key={i}>
                            <option
                              value={item?._id}
                              // selected={
                              //   item?._id ===
                              //   invoiceObjectById?.patient_primary_Doctor
                              // }
                            >
                              {item?.doctor_name}
                            </option>
                          </React.Fragment>
                        )
                      })}
                    </select>
                    <div className={styles.errorContainer}>
                      <span className={styles.extraSpan}></span>

                      {errors[INVOICE_PATIENT_PRIMARY_DOCTOR] && (
                        <p className="dashboardFormError">
                          {
                            errors[INVOICE_PATIENT_PRIMARY_DOCTOR]
                              .message as any
                          }
                        </p>
                      )}
                    </div>
                  </div>
                </div>
              </div>

              {/*  mobile no */}
              <div className={styles.formLayout}>
                <div className={styles.labelFieldContainer}>
                  <label
                    htmlFor={INVOICE_PATIENT_MOBILE_NO}
                    className={styles.labelText}
                  >
                    {t('PatientEMR.Mobile No.')}
                    <span className="asterick">*</span>
                  </label>
                  <div className={styles.fieldErrorContainer}>
                    <PhoneInput
                      country={'kw'}
                      {...register(
                        INVOICE_PATIENT_MOBILE_NO,
                        receptionistInvoiceValidators[INVOICE_PATIENT_MOBILE_NO]
                      )}
                      value={
                        getValues(INVOICE_PATIENT_MOBILE_NO) ??
                        patientInvoiceData?.phone
                      }
                      onChange={(phone: any) => {
                        const formattedPhone = phone && `+${phone}`
                        setValue(INVOICE_PATIENT_MOBILE_NO, formattedPhone)
                        trigger(INVOICE_PATIENT_MOBILE_NO)
                      }}
                      inputClass={[
                        patientType === 'REGULAR' && styles.disabledInput,
                        styles.phoneNumberInput,
                      ]?.join(' ')}
                      disabled={patientType === 'REGULAR' ? true : false}
                    />
                    <div className={styles.errorContainer}>
                      <span className={styles.extraSpan}></span>
                      {errors[INVOICE_PATIENT_MOBILE_NO] && (
                        <p className="dashboardFormError">
                          {errors[INVOICE_PATIENT_MOBILE_NO].message as any}
                        </p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* insurance container */}
            {patientType === 'REGULAR' ? (
              <div className={styles.insuranceContainer}>
                <div>
                  <span className={styles.dividerStyleContainer}>
                    <Divider customClass={styles.dividerStyle} />
                    <span
                      className={styles.formFieldMoreContainer}
                      onClick={handleShowInsurance}
                    >
                      {!showInsurance ? (
                        <>
                          <p className={styles.dividerText}>
                            {t('Common.Insurance')}
                          </p>
                          <DropDownIcon
                            fillColor="#797979"
                            customClass={styles.dropDownIcon}
                          />
                        </>
                      ) : (
                        <>
                          <p className={styles.dividerText}>
                            {t('Common.Insurance')}
                          </p>
                          <DropDownArrowIcon
                            fillColor="#797979"
                            customClass={styles.dropDownIcon}
                          />
                        </>
                      )}
                    </span>
                    <Divider customClass={styles.dividerStyle} />
                  </span>
                </div>
                {showInsurance && (
                  <Button
                    title={
                      t('InvoiceInfo.Select Insurance') || 'Select Insurance'
                    }
                    type="button"
                    customClass={styles.insuranceButtonStyle}
                    handleClick={handleInsurancePopup}
                    disable={
                      patientInvoiceData?.insurance_plan_ids &&
                      patientInvoiceData?.insurance_plan_ids?.length > 0
                        ? false
                        : true
                    }
                  />
                )}
              </div>
            ) : (
              ''
            )}

            {/* referral info container */}
            <div className={styles.referralContainer}>
              <div>
                <span className={styles.dividerStyleContainer}>
                  <Divider customClass={styles.dividerStyle} />
                  <span
                    className={styles.formFieldMoreContainer}
                    onClick={handleShowReferralInfo}
                  >
                    {!showReferralInfo ? (
                      <>
                        <p className={styles.dividerText}>
                          {t('InvoiceInfo.Referral Info')}
                        </p>
                        <DropDownIcon
                          fillColor="#797979"
                          customClass={styles.dropDownIcon}
                        />
                      </>
                    ) : (
                      <>
                        <p className={styles.dividerText}>
                          {t('InvoiceInfo.Referral Info')}
                        </p>
                        <DropDownArrowIcon
                          fillColor="#797979"
                          customClass={styles.dropDownIcon}
                        />
                      </>
                    )}
                  </span>
                  <Divider customClass={styles.dividerStyle} />
                </span>
              </div>
              {showReferralInfo && (
                <div className={styles.referralLayout}>
                  <div className={styles.labelFieldContainer}>
                    <div className={styles.fieldErrorContainer}>
                      <label htmlFor="internal" className={styles.radioLabel}>
                        <input
                          className={styles.radioInput}
                          type="radio"
                          id="internal"
                          value="INTERNAL"
                          {...register(INVOICE_REFERRAL_TYPE)}
                          // disabled={patientType === 'REGULAR' ? true : false}
                        />
                        <span className={styles.customRadio} />
                        {t('InvoiceInfo.Internal')}
                      </label>

                      <label htmlFor="external" className={styles.radioLabel}>
                        <input
                          className={styles.radioInput}
                          type="radio"
                          id="external"
                          value="EXTERNAL"
                          {...register(INVOICE_REFERRAL_TYPE)}
                          // disabled={patientType === "REGULAR" ? true : false}
                          disabled={true}
                        />
                        <span className={styles.customRadio} />
                        {t('InvoiceInfo.External')}
                      </label>
                    </div>
                  </div>

                  <div className={styles.fieldContainer}>
                    <label className={styles.labelText}>
                      {t('InvoiceInfo.Doctor / Medical Center')}
                      {/* <span className="asterick">*</span> */}
                    </label>
                    <div
                      className={styles.fieldErrorContainer}
                      onClick={() => {
                        // if (patientType === 'OUT PATIENT') {
                        handleDoctorPopup()
                        // }
                      }}
                    >
                      <input
                        type="text"
                        placeholder={
                          t('InvoiceInfo.Search doctor / medical center') ||
                          'Search doctor / medical center'
                        }
                        className={styles.inputField}
                        value={
                          invoiceObjectById?.patient_id
                            ? referredDoctor !== undefined
                              ? referredDoctor
                              : doctorDataById?._id
                              ? doctorDataById?.doctor_name
                              : ''
                            : doctorDataById?._id
                            ? doctorDataById?.doctor_name
                            : ''
                        }
                        // {...register(INVOICE_DOCTOR_NAME)}
                        onChange={(e) => trimValue(e)}
                        disabled={true}
                      />
                      <SearchIcon
                        fillColor="#797979"
                        customClass={styles.searchIconStyle}
                        // handleClick={handleDoctorPopup}
                        // handleClick={() => {
                        //   if (patientType === "OUT PATIENT") {
                        //     handleDoctorPopup();
                        //   }
                        // }}
                      />
                    </div>
                  </div>
                </div>
              )}
            </div>

            <Button
              title={t('InvoiceInfo.Save & Next') || 'Save & Next'}
              type="submit"
              customClass={styles.saveButtonStyle}
            />
          </div>

          {/* amount */}
          <div className={styles.settleInvoiceAmountContainer}>
            {patientType === 'REGULAR' ? (
              <div className={styles.settleInvoiceContainer}>
                <div></div>
                <Button
                  type="button"
                  title={t('SettledInvoice.All Invoices') || 'All Invoices'}
                  customClass={styles.settleButton}
                  handleClick={handleSettledInvoice}
                />
              </div>
            ) : (
              ''
            )}

            <div className={styles.amountContainer}>
              {patientType === 'REGULAR' ? (
                <>
                  <div className={styles.dueAmountStyle}>
                    {t('Common.Due')}{' '}
                    {invoiceObjectById?._id &&
                    invoiceObjectById?.outstanding_amount
                      ? formatCurrency(invoiceObjectById?.outstanding_amount)
                      : '0.000'}
                  </div>
                  <div className={styles.advanceDueAmountStyle}>
                    {t('Common.Advance Due')}
                    {invoiceObjectById?.advance_amount
                      ? formatCurrency(invoiceObjectById?.advance_amount)
                      : '0.000'}
                  </div>
                </>
              ) : (
                <>
                  <div className={styles.dueAmountStyle}>
                    {t('Common.Due')} 0
                  </div>
                  <div className={styles.advanceDueAmountStyle}>
                    {t('Common.Advance Due')} 0
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
        {/* action bar */}
        {/* <div className={styles.mainContainerFormActionSidebar}>
          <div className={styles.sidebarData}>
            {invoiceFormActionData?.map((item: any, index: any) => {
              return (
                <React.Fragment key={index}>
                  <div className={styles.iconStyleContainer} key={index}>
                    <item.icon
                      customClass={styles.iconStyle}
                      fillColor="#CDD4D8"
                      mouseEnter={() => setFormActionValue(index)}
                      mouseLeave={() => setFormActionValue(-1)}
                    />
                    <p className={styles.tooltiptext}>{item.name}</p>
                  </div>
                </React.Fragment>
              )
            })}
          </div>
        </div> */}
      </form>
    </>
  )
}

export default InvoiceInformation