import {
  RADIOLOGY_INVOICE_PATIENT_BRANCH_NAME,
  RADIOLOGY_INVOICE_PATIENT_MOBILE_NO,
  RADIOLOGY_INVOICE_PATIENT_NAME,
  RADIOLOGY_INVOICE_PATIENT_PRIMARY_DOCTOR,
  RADIOLOGY_INVOICE_PATIENT_TYPE,
} from '../constants/constant'
import { t } from 'i18next'

export interface IradiologyInvoiceValidators {
  [RADIOLOGY_INVOICE_PATIENT_BRANCH_NAME]: {
    required: string
  }
  [RADIOLOGY_INVOICE_PATIENT_TYPE]: {
    required: string
  }
  [RADIOLOGY_INVOICE_PATIENT_NAME]: {
    required: string
  }
  [RADIOLOGY_INVOICE_PATIENT_PRIMARY_DOCTOR]: {
    required: string
  }
  [RADIOLOGY_INVOICE_PATIENT_MOBILE_NO]: {
    required: string
  }
}
export const radiologyInvoiceValidators: IradiologyInvoiceValidators = {
  [RADIOLOGY_INVOICE_PATIENT_BRANCH_NAME]: {
    required: t('InvoiceInfo.BranchVal'),
  },
  [RADIOLOGY_INVOICE_PATIENT_TYPE]: {
    required: t('InvoiceInfo.PatientType'),
  },
  [RADIOLOGY_INVOICE_PATIENT_NAME]: {
    required: t('IPDBooking.PleaseSelectPatient'),
  },
  [RADIOLOGY_INVOICE_PATIENT_PRIMARY_DOCTOR]: {
    required: t('InvoiceInfo.PrimaryDoctor'),
  },
  [RADIOLOGY_INVOICE_PATIENT_MOBILE_NO]: {
    required: t('PatientEmrValidators.MOB1'),
  },
}
