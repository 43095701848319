import { FC, useCallback, useEffect, useState } from 'react'
import Button from '../../components/common/button/Button'
import { CustomRadio } from '../../components/common/custom-radio'
import { Input } from '../../components/common/input/input'
import { TextArea } from '../../components/common/text-area'
import Select from 'react-select'
import { DropdownIndicator } from '../../components/common/dropdown-indicator/DropdownIndicator'
import { Label } from '../../components/common/label'
import { useForm } from 'react-hook-form'
import { CustomModal } from '../../components/common/custom-modal/modal'
import SearchModal from './patient-search/page'
import { useAppDispatch, useAppSelector } from '../../hooks'
import { getAllroom } from '../../redux/features/room/roomAsyncActions'
import { requestGenerator } from '../../utils/payloadGenerator'
import { getAllInternalDocotorReferral } from '../../redux/features/referral/referralAsyncActions'
import {
  appointmentDataByIdAsync,
  getAllPhysioSlots,
  getAllPhysioSlotsRecurring,
} from '../../redux/features/physio/physioAsyncActions'
import TableV2 from '../../components/common/table/tableV2/TableV2'
import { viewSlotsHeaderData, viewSlotsHeaderDataRecurring } from './data'
import {
  clearAppointmentDataById,
  clearRoomBookingDetailById,
  setSelectedSlotStatus,
} from '../../redux/features/physio/physioSlice'
import { setMessage } from '../../redux/features/toast/toastSlice'
import {
  daysList,
  defaultBooking,
  hourDropdown,
  minDropdown,
  reactSelectStyle,
  sessionTimeData,
  warning,
} from '../../constants/data'
import moment from 'moment'
import {
  bookingConfirmation,
  getAllPhysioAppointments,
} from '../../redux/features/appointments/bookingAppointmentAsyncActions'
import Loader from '../../components/common/spinner/Loader'
import {
  disableArrowKey,
  filterAppointmentStatusList,
  getBookedSlotsFromAppointmentDuration,
  isEmptyObj,
  isIqualSameDate,
  setShiftTiming,
  trimValue,
} from '../../utils/utils'
import PhoneInput from 'react-phone-input-2'
import styles from './style.module.scss'
import makeAnimated from 'react-select/animated'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'
import { CheckIcon, UncheckIcon } from '../../components/common/svg-components'
import { colors } from '../../constants/color'
import { setRoomCalendarDate } from '../../redux/features/appointments/bookingAppointmentsSlice'

interface IPhysio {
  closeModal?: any;
  calendarView?:string
}

const PhysioAppointmentBooking: FC<IPhysio> = (props) => {
  const { closeModal,calendarView } = props
  const [gender, setGender] = useState('MALE')
  const [patientModal, setPatientModal] = useState(false)
  const [patientData, setPateintData] = useState<any>({})
  const [viewSlots, setViewSlots] = useState(false)
  const [recurringIcon, setRecurringIcon] = useState<boolean>(false)
  const { roomData } = useAppSelector((state) => state.room)
  const { referDoctorData } = useAppSelector((state) => state.referral)
  const { branchData } = useAppSelector((state) => state.ipdBooking)
  const location = useLocation()
  const navigate = useNavigate()
  const {
    isLoading,
    physioSlots,
    selectedSlotsStatus,
    roomBookingDetailById,
    appointmentDataById,
    physioSlotsRecurring,
  } = useAppSelector((state) => state.physio)
  const { colorSchemeList } = useAppSelector((state) => state.login)

  const { t } = useTranslation()
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
    reset,
    trigger,
    getValues,
  } = useForm({})

  const dispatch = useAppDispatch()
  const animatedComponent = makeAnimated()

  const watchRoom = watch('room')
  const watchDoctor = watch('doctor')
  const watchDate = watch('date')
  const watchTimeFrom = watch('timeFrom')
  const watchTimeTo = watch('timeTo')
  const timeFromHr = watch('timeFromHr')
  const timeFromMin = watch('timeFromMin')
  const timeToHr = watch('timeToHr')
  const timeToMin = watch('timeToMin')
  const watchPatient = watch('patientName')
  const watchNumber = watch('mobileNo')
  const watchNotes = watch('notes')
  const watchSession = watch('session')
  const watchSessionTime = watch('session_time')
  const watchStartDate = watch('start_date')
  const watchInterval = watch('interval')
  const recurringStartTime = watch('recurring_start_time')
  const recurringEndTime = watch('recurring_end_time')
  const recurringStartTimeHr = watch('recurring_start_time_hr')
  const recurringStartTimeMin = watch('recurring_start_time_min')
  const recurringEndTimeHr = watch('recurring_end_time_hr')
  const recurringEndTimeMin = watch('recurring_end_time_min')
  const watchStatus = watch('status')
  const date = moment(watchDate)
  const days = watch('day')
  const dayIndex = date && date.isoWeekday()

  useEffect(() => {
    if (roomBookingDetailById?.appt_id) {
      let data = {
        appointment_id: roomBookingDetailById?.appt_id,
      }
      dispatch(appointmentDataByIdAsync(requestGenerator(data)))
    } else {
      const room = roomData?.find(
        (item: any) => item?._id === roomBookingDetailById?._id
      )
      room
        ? setValue('room', {
            label: room?.room_name,
            value: room?._id,
          })
        : setValue('room', undefined)
      if (roomBookingDetailById?.dateStart) {
        const startDate = new Date(roomBookingDetailById?.dateStart)
        setValue('date', moment(startDate).format('YYYY-MM-DD'))
        const startHour =
          startDate.getHours() < 10
            ? `0${startDate.getHours()}`
            : startDate.getHours()
        const startMin =
          startDate.getMinutes() < 10
            ? `0${startDate.getMinutes()}`
            : startDate.getMinutes() || '00'
        setValue('timeFrom', `${startHour}:${startMin}` || '')

        // new state for 15min time interval
        setValue('timeFromHr', {
          label: `${startHour}`,
          value: `${startHour}`,
        })
        setValue('timeFromMin', {
          label: `${startMin}`,
          value: `${startMin}`,
        })
      }
      if (roomBookingDetailById?.dateEnd) {
        const endDate = new Date(roomBookingDetailById?.dateEnd)
        const endHour =
          endDate.getHours() < 10
            ? `0${endDate.getHours()}`
            : endDate.getHours()
        const endMin =
          endDate.getMinutes() < 10
            ? `0${endDate.getMinutes()}`
            : endDate.getMinutes() || '00'
        setValue('timeTo', `${endHour}:${endMin}` || '')

        // new state for 15min time interval
        setValue('timeToHr', {
          label: `${endHour}`,
          value: `${endHour}`,
        })
        setValue('timeToMin', { label: `${endMin}`, value: `${endMin}` })
      }

      setValue('status', { label: 'SCHEDULED', value: 'SCHEDULED' })
    }
  }, [dispatch, roomBookingDetailById, roomData, setValue])

  const checkAppointnmentDataExist =
    appointmentDataById?.hasOwnProperty('room_id')

  useEffect(() => {
    if (checkAppointnmentDataExist) {
      const endTime = getBookedSlotsFromAppointmentDuration(
        appointmentDataById?.appointment_duration,
        appointmentDataById?.appointment_time
      )
      setValue(
        'patientName',
        appointmentDataById?.patient_name ||
          appointmentDataById?.patient_id?.user_id?.name
      )
      setValue('mobileNo', appointmentDataById?.phone)
      setValue('date', appointmentDataById?.appointment_date)
      setValue('timeFrom', appointmentDataById?.appointment_time)
      setValue('doctor', {
        label: appointmentDataById?.doctor_id?.doctor_name,
        value: appointmentDataById?.doctor_id?._id,
      })
      setValue('room', {
        label: appointmentDataById?.room_id?.room_name,
        value: appointmentDataById?.room_id?._id,
      })
      setValue('timeTo', endTime)
      // setValues for 15min time interval
      const timeFromArr = appointmentDataById?.appointment_time?.split(':')
      const timeToArr = endTime?.split(':')
      setShiftTiming(timeFromArr, 'timeFromHr', 'timeFromMin', setValue)
      setShiftTiming(timeToArr, 'timeToHr', 'timeToMin', setValue)
      setGender(appointmentDataById?.gender)
      if (appointmentDataById?.status) {
        setValue('status', {
          label: appointmentDataById?.status,
          value: appointmentDataById?.status,
        })
      }
      setValue('notes', appointmentDataById?.problem_description)
    }
  }, [appointmentDataById, checkAppointnmentDataExist, setValue])
  useEffect(() => {
    const mergeTwoArrays = physioSlots.map((item: any, index: number) => {
      const startTime = item
      const endTime = moment(startTime, 'HH:mm')
        .add(15, 'minutes')
        .format('HH:mm')

      return {
        id: index,
        label: `${startTime} to ${endTime}`,
        selected: true,
      }
    })
    dispatch(setSelectedSlotStatus(mergeTwoArrays))
  }, [dispatch, physioSlots])

  useEffect(() => {
    if (physioSlotsRecurring && physioSlotsRecurring?.length > 0) {
      const mergeTwoArrays = physioSlotsRecurring.map(
        (item: any, index: number) => {
          const { time, day, date, duration } = item

          const endTime = moment(time, 'HH:mm')
            .add(duration, 'minutes')
            .format('HH:mm')

          return {
            id: index,
            time,
            label: `${time} to ${endTime}`,
            day,
            date,
            duration,
            selected: true,
          }
        }
      )
      dispatch(setSelectedSlotStatus(mergeTwoArrays))
    } else {
      dispatch(setSelectedSlotStatus([]))
    }
  }, [dispatch, physioSlotsRecurring])

  const filterSelectedData = selectedSlotsStatus?.filter(
    (s: any) => s?.selected === true
  )

  // non-recurring
  const getAppointmentTimes = filterSelectedData?.map(
    (s: any) => s.label.split(' ')?.[0]
  )

  const checkPatientDataLength = patientData?.hasOwnProperty('patient_name')

  useEffect(() => {
    let data = {
      page: 0,
      pageSize: 1000,
      order_by: { name: 1 },
      is_active: true,
      type: 'OPD',
    }
    dispatch(getAllroom(requestGenerator(data)))
  }, [dispatch])

  useEffect(() => {
    dispatch(
      getAllInternalDocotorReferral(
        requestGenerator({ page: 1, pageSize: 100, branch: branchData?._id })
      )
    )
  }, [branchData?._id, dispatch])

  const onSubmit = (data: any) => {
    const currentTime = moment().format('HH:mm')
    if (!selectedSlotsStatus?.length) {
      let requestdata = {
        room_id: data?.room?.value,
        doctor_id: data?.doctor?.value,
        gender: gender,
        appointment_date: new Date(data?.date),
        appointment_day: dayIndex === 7 ? 0 : dayIndex,
        current_time: currentTime,
        patient_id: patientData?._id || appointmentDataById?.patient_id?._id,
        appointment_id: checkAppointnmentDataExist
          ? appointmentDataById?._id
          : undefined,
        convenient_time: {
          start_time: data?.timeFrom,
          end_time: data?.timeTo,
        },
      }
      dispatch(getAllPhysioSlots(requestGenerator(requestdata))).then(
        (res: any) => {
          if (res.type === 'physio/getAllPhysioSlots/fulfilled') {
            setViewSlots(true)
          }
        }
      )
    } else {
      setViewSlots(true)
    }
  }

  const getRecurringSlot = useCallback(() => {
    const currentTime = moment().format('HH:mm')
    if (
      watchPatient?.length > 0 &&
      watchRoom?.hasOwnProperty('value') &&
      watchDoctor?.hasOwnProperty('value') &&
      watchSession?.length > 0 &&
      watchSessionTime?.hasOwnProperty('value') &&
      watchStartDate?.length > 0 &&
      watchInterval?.length > 0 &&
      recurringIcon
    ) {
      if (recurringStartTime?.length && recurringEndTime?.length) {
        let requestdata = {
          room_id: watchRoom?.value,
          doctor_id: watchDoctor?.value,
          gender: gender,
          current_time: currentTime,
          patient_id: patientData?._id || appointmentDataById?.patient_id?._id,
          is_recurring: true,
          recurring_details: {
            day:
              days && days?.length > 0
                ? days.map((item: any) => item?.value)
                : [],
            convenient_time: {
              start_time: recurringStartTime,
              end_time: recurringEndTime,
            },
            duration: watchSessionTime?.value,
            interval: Number(watchInterval),
            session: Number(watchSession),
            start_date: watchStartDate,
          },
        }
        dispatch(getAllPhysioSlotsRecurring(requestGenerator(requestdata)))
      }
    }
  }, [
    watchPatient,
    appointmentDataById,
    days,
    dispatch,
    gender,
    patientData,
    recurringEndTime,
    recurringIcon,
    recurringStartTime,
    watchDoctor,
    watchInterval,
    watchRoom,
    watchSession,
    watchSessionTime,
    watchStartDate,
  ])

  const handleRecurringSlots = () => {
    trigger('patientName')
    trigger('room')
    trigger('mobileNo')
    trigger('doctor')
    trigger('session')
    trigger('session_time')
    trigger('start_date')
    trigger('interval')
    if (isEmptyObj(errors)) {
      if (!selectedSlotsStatus?.length) {
        getRecurringSlot()
      } else {
        setViewSlots(true)
      }
    }
  }

  useEffect(() => {
    const currentTime = moment().format('HH:mm')
    if (
      watchPatient?.length > 0 &&
      watchRoom?.hasOwnProperty('value') &&
      watchDoctor?.hasOwnProperty('value') &&
      !recurringIcon
    ) {
      if (watchTimeTo?.length && watchTimeFrom?.length && watchDate?.length) {
        let requestdata = {
          room_id: watchRoom?.value,
          doctor_id: watchDoctor?.value,
          gender: gender,
          appointment_date: new Date(watchDate),
          appointment_day: dayIndex === 7 ? 0 : dayIndex,
          current_time: currentTime,
          patient_id: patientData?._id || appointmentDataById?.patient_id?._id,
          appointment_id: checkAppointnmentDataExist
            ? appointmentDataById?._id
            : undefined,
          convenient_time: {
            start_time: watchTimeFrom,
            end_time: watchTimeTo,
          },
        }
        dispatch(getAllPhysioSlots(requestGenerator(requestdata)))
      }
    }
  }, [
    recurringIcon,
    watchTimeTo,
    watchTimeFrom,
    watchDate,
    watchPatient,
    watchDoctor,
    watchRoom,
    patientData._id,
    gender,
    dayIndex,
    dispatch,
    checkAppointnmentDataExist,
    appointmentDataById,
  ])

  useEffect(() => {
    getRecurringSlot()
  }, [getRecurringSlot])

  useEffect(() => {
    if (checkPatientDataLength) {
      setValue('patientName', patientData?.patient_name)
      setValue('mobileNo', patientData?.phone)
      setGender(patientData?.gender)
    }
  }, [checkPatientDataLength, patientData, setValue])

  const handleSave = () => {
    const date = new Date(watchDate)

    date.setHours(parseInt(getAppointmentTimes[0]?.split(':')[0]) || 0)
    date.setMinutes(parseInt(getAppointmentTimes[0]?.split(':')[1]) || 0)
    const payload = {
      patient_name: watchPatient,
      gender: gender,
      patient_emr: patientData?.emr_no ?? undefined,
      doctor_name: watchDoctor?.label,
      appointment_date: date,
      start_time: watchTimeFrom,
      end_time: watchTimeTo,
      phone: `+${Number(watchNumber)}`,
      // appointment_status: checkAppointnmentDataExist
      //   ? 'RESCHEDULED'
      //   : 'SCHEDULED',
      appointment_status: watchStatus?.value,
      problem_description: watchNotes,
      room_id: watchRoom?.value,
      doctor_id: watchDoctor?.value,
      patient_id: patientData?._id || appointmentDataById?.patient_id?._id,
      branch_id: branchData?._id,
      appointment_duration: getAppointmentTimes?.length * 15,
      appointment_type: 'INPERSON',
      appointment_id: checkAppointnmentDataExist
        ? appointmentDataById?._id
        : undefined,
      appointment_time: getAppointmentTimes,
      is_booked_by_agent: false,
      is_update: checkAppointnmentDataExist ? true : false,
    }

    const selectedDateMiliseconds = new Date(watchDate).getTime()
    const apptDateMilliSec = new Date(
      appointmentDataById?.appointment_date
    ).getTime()

    const recurringSelectedSlotsWithTimestamp = recurringIcon
      ? filterSelectedData?.map((slotItem: any) => {
          const date = new Date(slotItem?.date)
          date.setHours(parseInt(slotItem?.time?.split(':')[0]) || 0)
          date.setMinutes(parseInt(slotItem?.time?.split(':')[1]) || 0)
          return { ...slotItem, appointment_timestamp: date }
        })
      : undefined
    let requestData: any = recurringIcon
      ? {
          ...payload,
          appointment_duration: watchSessionTime?.value,
          is_recurring: true,
          recurring_details: recurringSelectedSlotsWithTimestamp,
          appointment_date: undefined,
          appointment_time: undefined,
          doctor_name: undefined,
          start_time: undefined,
          end_time: undefined,
        }
      : payload
    if (!recurringIcon) {
      if (
        !watchPatient?.length ||
        !watchNumber?.length ||
        !watchRoom?.hasOwnProperty('value') ||
        !watchDoctor?.hasOwnProperty('value')
      ) {
        trigger('patientName')
        trigger('room')
        trigger('mobileNo')
        trigger('doctor')
      } else if (requestData?.appointment_time?.length === 0) {
        dispatch(
          setMessage({
            message: t('BookingConfirmation.SelectSlots'),
            type: warning,
          })
        )
      } else if (
        apptDateMilliSec === selectedDateMiliseconds &&
        watchNotes === appointmentDataById?.problem_description &&
        isIqualSameDate({
          aptData: roomBookingDetailById,
          formData: {
            appointment_date: watchDate,
            start_time: watchTimeFrom,
            end_time: watchTimeTo,
          },
        }) &&
        watchStatus?.value === 'RESCHEDULED'
      ) {
        dispatch(
          setMessage({
            message: 'Please change appointment time or date',
            type: warning,
          })
        )
      } else {
        dispatch(bookingConfirmation(requestGenerator(requestData))).then(
          (res: any) => {
            if (res.type === 'appoinments/bookingConfirmation/fulfilled') {
              dispatch(setRoomCalendarDate(watchDate))
              if (location.pathname === '/schedular/room') {
                dispatch(
                  getAllPhysioAppointments(
                    requestGenerator({ branch_id: branchData?._id })
                  )
                )
              } else {

                navigate(calendarView?.length ? '/schedular/room' : '/schedular', {
                  state: { notRenderSidebar: true },
                })
              }
              closeModal()
              dispatch(setSelectedSlotStatus([]))
            }
          }
        )
      }
    } else {
      if (
        !watchPatient?.length ||
        !watchNumber?.length ||
        !watchRoom?.hasOwnProperty('value') ||
        !watchDoctor?.hasOwnProperty('value') ||
        !watchSession?.length ||
        !watchSessionTime?.hasOwnProperty('value') ||
        !watchStartDate?.length ||
        !watchInterval?.length
      ) {
        trigger('patientName')
        trigger('room')
        trigger('mobileNo')
        trigger('doctor')
        trigger('session')
        trigger('session_time')
        trigger('start_date')
        trigger('interval')
      } else {
        requestData?.recurring_details?.length > 0
          ? dispatch(bookingConfirmation(requestGenerator(requestData))).then(
              (res: any) => {
                if (res.type === 'appoinments/bookingConfirmation/fulfilled') {
                  if (location.pathname === '/schedular/room') {
                    dispatch(
                      getAllPhysioAppointments(
                        requestGenerator({ branch_id: branchData?._id })
                      )
                    )
                  } else {
                    navigate('/schedular/room', {
                      state: { notRenderSidebar: true },
                    })
                  }
                  closeModal()
                  dispatch(setSelectedSlotStatus([]))
                }
              }
            )
          : dispatch(
              setMessage({
                message: t('BookingConfirmation.SelectSlots'),
                type: warning,
              })
            )
      }
    }
  }

  const handleSaveSlots = () => {
    let toastData = {
      message: t('BookingConfirmationValidators.MaxSlot'),
      type: warning,
    }
    if (filterSelectedData?.length > 8 && !recurringIcon) {
      dispatch(setMessage(toastData))
    } else {
      setViewSlots(false)
    }
  }

  const handleRecurring = () => {
    dispatch(setSelectedSlotStatus([]))
    if (patientData?._id) {
      setRecurringIcon((prevState) => !prevState)
    } else {
      dispatch(
        setMessage({
          message: t('BookingConfirmationValidators.SelectPatient'),
          type: warning,
        })
      )
    }
  }

  useEffect(() => {
    return () => {
      dispatch(setSelectedSlotStatus([]))
      dispatch(clearRoomBookingDetailById())
      dispatch(clearAppointmentDataById())
      reset()
      setValue('room', null)
      setValue('doctor', null)
      setGender('MALE')
      setValue('mobileNo', '')
    }
  }, [dispatch, reset, setValue])

  return (
    <>
      {isLoading && <Loader />}
      <CustomModal
        showModal={patientModal}
        closeModal={() => setPatientModal(false)}
        height="80dvh"
        width="60dvw"
        title={t('Common.Patient Search') || 'Patient Search'}
      >
        <SearchModal
          handleRowClick={(item: any) => {
            setPateintData(item)
            setPatientModal(false)
          }}
        />
      </CustomModal>
      <CustomModal
        showModal={viewSlots}
        closeModal={() => setViewSlots(false)}
        width="50dvw"
        height="75dvh"
        title={t('ViewSlots.View Slots') || 'View Slots'}
      >
        <div className={styles.slotsHeader}>
          <TableV2
            tableHeaderData={
              recurringIcon ? viewSlotsHeaderDataRecurring : viewSlotsHeaderData
            }
            tableRowData={selectedSlotsStatus ?? []}
            active={false}
          />
        </div>
        <div className={styles.saveBtn}>
          <Button
            type="button"
            title={t('Common.Save') || 'Save'}
            handleClick={handleSaveSlots}
          />
        </div>
      </CustomModal>
      <form className={styles.formWrapper} onSubmit={handleSubmit(onSubmit)}>
        <div className={styles.fieldsContainer}>
          <div className={styles.inputFields}>
            <Input
              labelText={t('ShareQuestionnaire.Patient Name') || 'Patient Name'}
              placeholder={
                t('RoomBooking.SelectEnterPatient') ||
                'Please enter/select patient'
              }
              requiredField
              autoComplete="off"
              showIcon={true}
              handleIconClick={() =>
                checkAppointnmentDataExist ? undefined : setPatientModal(true)
              }
              {...register('patientName', { required: true })}
              showErrors={errors?.patientName?.type === 'required'}
              errorMessage={
                t('PatientEmrValidators.PatientName') ||
                'Please enter patient name'
              }
              onChange={(e: any) => {
                dispatch(setSelectedSlotStatus([]))
                trimValue(e)
              }}
              disabled={checkAppointnmentDataExist}
              disabledFields={checkAppointnmentDataExist}
            />
          </div>
          <div className={styles.inputFields}>
            <div className={styles.radioFieldGenderContainer}>
              <Label
                requiredField
                labelText={t('PatientEMR.Gender') || 'Gender'}
                flexBasis="31%"
              />
              <CustomRadio
                label={t('PatientEMR.Male') || 'Male'}
                name="gender"
                value="Male"
                customLabel={styles.customRadioLabel}
                checked={gender === 'MALE'}
                onClick={() => {
                  setGender('MALE')
                  dispatch(setSelectedSlotStatus([]))
                }}
                disabled={checkAppointnmentDataExist || checkPatientDataLength}
              />
              <CustomRadio
                label={t('PatientEMR.Female') || 'Female'}
                name="gender"
                value="Female"
                customLabel={styles.customRadioLabel}
                checked={gender === 'FEMALE'}
                onClick={() => {
                  setGender('FEMALE')
                  dispatch(setSelectedSlotStatus([]))
                }}
                disabled={checkAppointnmentDataExist || checkPatientDataLength}
              />
            </div>
          </div>
          <div className={styles.inputFields}>
            <div className={styles.inputFieldContainer}>
              <Label
                htmlFor={'mobileNo'}
                labelText={t('PatientEMR.Mobile No.') || 'Mobile No.'}
                requiredField={true}
                customClass={styles.customLabel}
              />
              <PhoneInput
                country={'kw'}
                {...register('mobileNo', { required: true })}
                value={getValues('mobileNo')}
                placeholder={t('PatientEMR.Mobile No.') || 'Mobile No.'}
                onChange={(phone) => {
                  const formattedPhone = phone && `+${phone}`
                  setValue('mobileNo', formattedPhone)
                  trigger('mobileNo')
                }}
                inputClass={[styles.phoneNumberInput]?.join(' ')}
                containerStyle={{ flexBasis: '70%' }}
                disabled={checkAppointnmentDataExist}
              />
            </div>
            {errors?.mobileNo?.type === 'required' && (
              <div className={styles.phoneErrorContainer}>
                <span className={styles.extraSpan} />
                <p className={styles.phoneErrorContainer}>
                  {t('BookingConfirmationValidators.PATINET_MOBILE_NO')}
                </p>
              </div>
            )}
          </div>
          <div className={styles.inputFields}>
            <div className={styles.dropDownField}>
              <Label
                requiredField
                labelText={t('IPDBooking.Room') || 'Room'}
                flexBasis="31%"
              />
              <div className={styles.errorContainer}>
                <Select
                  className={styles.select}
                  placeholder={t('IPDBooking.SelectRoom') || 'Select Room'}
                  // isDisabled={checkAppointnmentDataExist}
                  value={watchRoom}
                  {...register('room', { required: true })}
                  onChange={(e: any) => {
                    setValue('room', e)
                    dispatch(setSelectedSlotStatus([]))
                  }}
                  isSearchable={true}
                  components={{ DropdownIndicator }}
                  options={roomData?.map((item: any) => ({
                    label: item?.room_name,
                    value: item?._id,
                  }))}
                  maxMenuHeight={200}
                />
                {errors?.room?.type === 'required' && (
                  <p className={styles.errorMessage}>
                    {t('RoomBooking.SelectRoomValidation')}
                  </p>
                )}
              </div>
            </div>
          </div>
          <div className={styles.inputFields}>
            <div className={styles.dropDownField}>
              <Label
                requiredField
                labelText={t('Receptionist Dashboard.Doctor') || 'Doctor'}
                flexBasis="31%"
              />
              <div className={styles.errorContainer}>
                <Select
                  className={styles.select}
                  placeholder={t('IPDBooking.Select Doctor') || 'Select Doctor'}
                  value={watchDoctor}
                  {...register('doctor', { required: true })}
                  onChange={(e: any) => {
                    setValue('doctor', e)
                    dispatch(setSelectedSlotStatus([]))
                  }}
                  isSearchable={true}
                  components={{ DropdownIndicator }}
                  options={referDoctorData?.map((items: any) => ({
                    label: items?.doctor_name,
                    value: items?._id,
                  }))}
                  maxMenuHeight={200}
                  styles={reactSelectStyle}
                />
                {errors?.doctor?.type === 'required' && (
                  <p className={styles.errorMessage}>
                    {t('RoomBooking.SelectDoctorValidation')}
                  </p>
                )}
              </div>
            </div>
          </div>
          <div className={styles.inputFields}>
            <Input
              labelText={t('ViewAppointment.Date') || 'Date'}
              type="date"
              requiredField
              {...register('date', { required: true })}
              showErrors={errors?.date?.type === 'required'}
              errorMessage={
                t('BookingConfirmationValidators.BOOKING_DATE') ||
                'Please select date'
              }
              onChange={(e) => {
                setValue('date', e.target.value)
                dispatch(setSelectedSlotStatus([]))
              }}
              customClass={styles.datePicker}
            />
          </div>
          <div className={styles.inputFields}>
            <div className={styles.dropDownField}>
              <Label
                requiredField
                labelText={t('MobileAppointmentReq.Status') || 'Status'}
                flexBasis="31%"
              />
              <div className={styles.errorContainer}>
                <Select
                  className={styles.select}
                  placeholder={
                    t('BookingConfirmation.Select Status Name') ||
                    'Select Status Name'
                  }
                  value={watchStatus}
                  {...register('status', { required: true })}
                  onChange={(e: any) => {
                    setValue('status', e)
                    trigger('status')
                  }}
                  isSearchable={true}
                  components={{ DropdownIndicator }}
                  options={filterAppointmentStatusList(
                    colorSchemeList,
                    appointmentDataById?.status || defaultBooking
                  ).map((item) => {
                    return {
                      label: item?.label,
                      value: item?.label,
                    }
                  })}
                  maxMenuHeight={200}
                  styles={reactSelectStyle}
                />
                {errors?.status?.type === 'required' && (
                  <p className={styles.errorMessage}>
                    {t('BookingConfirmation.Select Status Name')}
                  </p>
                )}
              </div>
            </div>
          </div>
          <div
            style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}
          >
            <div className={styles.inputFields}>
              <div className={styles.radioFieldGenderContainer}>
                <Label
                  requiredField
                  labelText={t('BookingConfirmation.Time')}
                  flexBasis="31%"
                />
                <Input
                  type="time"
                  requiredField
                  inlineStyle={{ flexBasis: '30%', display: 'none' }}
                  {...register('timeFrom')}
                  // onChange={() => dispatch(setSelectedSlotStatus([]))}
                />
                <Select
                  className={styles.selectTimeInputField}
                  {...register('timeFromHr')}
                  isSearchable={true}
                  isClearable={true}
                  options={hourDropdown}
                  maxMenuHeight={200}
                  value={watch('timeFromHr') || []}
                  components={animatedComponent}
                  placeholder="Hour"
                  onChange={(e) => {
                    setValue('timeFromHr', e)
                    trigger('timeFromHr')
                    if (timeFromMin) {
                      setValue('timeFrom', `${e.value}:${timeFromMin.value}`)
                    }
                  }}
                  styles={reactSelectStyle}
                />
                <Select
                  className={styles.selectTimeInputField}
                  {...register('timeFromMin')}
                  isSearchable={true}
                  isClearable={true}
                  options={minDropdown?.filter((item) => item.label !== '59')}
                  maxMenuHeight={200}
                  value={watch('timeFromMin') || []}
                  components={animatedComponent}
                  placeholder="Min"
                  onChange={(e) => {
                    setValue('timeFromMin', e)
                    trigger('timeFromMin')
                    if (timeFromHr) {
                      setValue('timeFrom', `${timeFromHr.value}:${e.value}`)
                    }
                  }}
                  styles={reactSelectStyle}
                />
                <p>{t('ShareQuestionnaire.To')}</p>
                <Input
                  type="time"
                  requiredField
                  inlineStyle={{
                    flexBasis: '30%',
                    justifyContent: 'flex-end',
                    display: 'none',
                  }}
                  {...register('timeTo')}
                  // onChange={() => dispatch(setSelectedSlotStatus([]))}
                />
                <Select
                  className={styles.selectTimeInputField}
                  {...register('timeToHr')}
                  isSearchable={true}
                  isClearable={true}
                  options={hourDropdown}
                  maxMenuHeight={200}
                  value={watch('timeToHr') || []}
                  components={animatedComponent}
                  placeholder="Hour"
                  onChange={(e) => {
                    setValue('timeToHr', e)
                    trigger('timeToHr')
                    if (timeToMin) {
                      setValue('timeTo', `${e.value}:${timeToMin.value}`)
                    }
                  }}
                  styles={reactSelectStyle}
                />
                <Select
                  className={styles.selectTimeInputField}
                  {...register('timeToMin')}
                  isSearchable={true}
                  isClearable={true}
                  options={minDropdown?.filter((item) => item.label !== '59')}
                  maxMenuHeight={200}
                  value={watch('timeToMin') || []}
                  components={animatedComponent}
                  placeholder="Min"
                  onChange={(e) => {
                    setValue('timeToMin', e)
                    trigger('timeToMin')
                    if (timeToHr) {
                      setValue('timeTo', `${timeToHr.value}:${e.value}`)
                    }
                  }}
                  styles={reactSelectStyle}
                />
              </div>
            </div>
            <div className={styles.inputFieldsButton}>
              <div className={styles.viewSlotsBtn}>
                <Button
                  title={t('ViewSlots.View Slots') || 'View Slots'}
                  type="submit"
                  disable={recurringIcon}

                  // handleClick={() => setViewSlots(true)}
                />
              </div>
            </div>
          </div>
          {patientData?._id ? (
            <>
              <div className={styles.recurringContainer}>
                <div className={styles.extraForCheckBox}></div>
                {recurringIcon ? (
                  <CheckIcon
                    fillColor={colors.green1}
                    handleClick={handleRecurring}
                  />
                ) : (
                  <UncheckIcon
                    fillColor={colors.grey2}
                    handleClick={handleRecurring}
                  />
                )}
                <p className={styles.recurringText}>
                  {t('BookingConfirmation.Recurring')}
                </p>
              </div>
              {recurringIcon && (
                <>
                  <div className={styles.inputFields}>
                    <Input
                      type="number"
                      labelText={t('BookingConfirmation.Session') || 'Session'}
                      placeholder={
                        t('BookingConfirmation.Enter Session') ||
                        'Enter Session'
                      }
                      requiredField
                      {...register('session', { required: true })}
                      showErrors={errors?.session?.type === 'required'}
                      errorMessage={
                        t('BookingConfirmationValidators.SESSION') ||
                        'Please Enter Session'
                      }
                      onKeyDown={(e: any) => disableArrowKey(e)}
                      onWheel={(e: any) => {
                        e.target.blur()
                      }}
                    />
                  </div>
                  <div className={styles.inputFields}>
                    <div className={styles.dropDownField}>
                      <Label
                        requiredField
                        labelText={
                          t('BookingConfirmation.Session Time') ||
                          'Session Time'
                        }
                        flexBasis="31%"
                      />
                      <div className={styles.errorContainer}>
                        <Select
                          className={styles.select}
                          placeholder={
                            t('BookingConfirmation.Select Session Time') ||
                            'Select Session Time'
                          }
                          value={watch('session_time')}
                          {...register('session_time', { required: true })}
                          onChange={(e: any) => {
                            setValue('session_time', e)
                          }}
                          isSearchable={true}
                          components={{ DropdownIndicator }}
                          options={sessionTimeData}
                          maxMenuHeight={200}
                          styles={reactSelectStyle}
                        />
                        {errors?.session_time?.type === 'required' && (
                          <p className={styles.errorMessage}>
                            {t('BookingConfirmationValidators.SESSION_TIME')}{' '}
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className={styles.inputFields}>
                    <Input
                      labelText={
                        t('BookingConfirmation.Start Date') || 'Start Date'
                      }
                      type="date"
                      requiredField
                      // defaultValue={moment(new Date()).format('YYYY-MM-DD')}
                      {...register('start_date', { required: true })}
                      showErrors={errors?.start_date?.type === 'required'}
                      errorMessage={
                        t(
                          'BookingConfirmationValidators.RECURRING_START_DATE'
                        ) || 'Please select start date'
                      }
                      onChange={(e) => {
                        setValue('date', e.target.value)
                        dispatch(setSelectedSlotStatus([]))
                      }}
                      customClass={styles.datePicker}
                    />
                  </div>
                  <div className={styles.inputFields}>
                    <Input
                      type="number"
                      labelText={
                        t('BookingConfirmation.Interval') || 'Interval'
                      }
                      placeholder={
                        t('BookingConfirmation.Enter Interval') ||
                        'Enter Interval'
                      }
                      requiredField
                      {...register('interval', { required: true })}
                      showErrors={errors?.interval?.type === 'required'}
                      errorMessage={
                        t('BookingConfirmationValidators.INTERVAL') ||
                        'Please Enter Interval'
                      }
                      onKeyDown={(e: any) => disableArrowKey(e)}
                      onWheel={(e: any) => {
                        e.target.blur()
                      }}
                    />
                  </div>
                  <div className={styles.inputFields}>
                    <div className={styles.dropDownField}>
                      <Label
                        labelText={t('BookingConfirmation.Day') || 'Day'}
                        flexBasis="31%"
                      />
                      <div className={styles.errorContainer}>
                        <Select
                          className={styles.select}
                          placeholder={
                            t('BookingConfirmation.Select Day') || 'Select Day'
                          }
                          isDisabled={!(watchInterval >= 7) ? true : false}
                          value={watch('day')}
                          {...register('day')}
                          onChange={(e: any) => {
                            setValue('day', e)
                          }}
                          isMulti
                          isSearchable={true}
                          components={{ DropdownIndicator }}
                          options={daysList}
                          maxMenuHeight={200}
                          styles={reactSelectStyle}
                        />
                      </div>
                    </div>
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      flexWrap: 'wrap',
                    }}
                  >
                    <div className={styles.inputFields}>
                      <div className={styles.radioFieldGenderContainer}>
                        <Label
                          requiredField
                          labelText={t('BookingConfirmation.Time')}
                          flexBasis="31%"
                        />
                        <Input
                          type="time"
                          requiredField
                          inlineStyle={{ flexBasis: '30%', display: 'none' }}
                          {...register('recurring_start_time')}
                          // onChange={() => dispatch(setSelectedSlotStatus([]))}
                        />
                        <Select
                          className={styles.selectTimeInputField}
                          {...register('recurring_start_time_hr')}
                          isSearchable={true}
                          isClearable={true}
                          options={hourDropdown}
                          maxMenuHeight={200}
                          value={watch('recurring_start_time_hr') || []}
                          components={animatedComponent}
                          placeholder="Hour"
                          onChange={(e) => {
                            setValue('recurring_start_time_hr', e)
                            trigger('recurring_start_time_hr')
                            if (recurringStartTimeMin) {
                              setValue(
                                'recurring_start_time',
                                `${e.value}:${recurringStartTimeMin.value}`
                              )
                            }
                          }}
                          styles={reactSelectStyle}
                        />
                        <Select
                          className={styles.selectTimeInputField}
                          {...register('recurring_start_time_min')}
                          isSearchable={true}
                          isClearable={true}
                          options={minDropdown?.filter(
                            (item) => item.label !== '59'
                          )}
                          maxMenuHeight={200}
                          value={watch('recurring_start_time_min') || []}
                          components={animatedComponent}
                          placeholder="Min"
                          onChange={(e) => {
                            setValue('recurring_start_time_min', e)
                            trigger('recurring_start_time_min')
                            if (recurringStartTimeHr) {
                              setValue(
                                'recurring_start_time',
                                `${recurringStartTimeHr.value}:${e.value}`
                              )
                            }
                          }}
                          styles={reactSelectStyle}
                        />
                        <p>{t('ShareQuestionnaire.To')}</p>
                        <Input
                          type="time"
                          requiredField
                          inlineStyle={{
                            flexBasis: '30%',
                            justifyContent: 'flex-end',
                            display: 'none',
                          }}
                          {...register('recurring_end_time')}
                          // onChange={() => dispatch(setSelectedSlotStatus([]))}
                        />
                        <Select
                          className={styles.selectTimeInputField}
                          {...register('recurring_end_time_hr')}
                          isSearchable={true}
                          isClearable={true}
                          options={hourDropdown}
                          maxMenuHeight={200}
                          value={watch('recurring_end_time_hr') || []}
                          components={animatedComponent}
                          placeholder="Hour"
                          onChange={(e) => {
                            setValue('recurring_end_time_hr', e)
                            trigger('recurring_end_time_hr')
                            if (recurringEndTimeMin) {
                              setValue(
                                'recurring_end_time',
                                `${e.value}:${recurringEndTimeMin.value}`
                              )
                            }
                          }}
                          styles={reactSelectStyle}
                        />
                        <Select
                          className={styles.selectTimeInputField}
                          {...register('recurring_end_time_min')}
                          isSearchable={true}
                          isClearable={true}
                          options={minDropdown?.filter(
                            (item) => item.label !== '59'
                          )}
                          maxMenuHeight={200}
                          value={watch('recurring_end_time_min') || []}
                          components={animatedComponent}
                          placeholder="Min"
                          onChange={(e) => {
                            setValue('recurring_end_time_min', e)
                            trigger('recurring_end_time_min')
                            if (recurringEndTimeHr) {
                              setValue(
                                'recurring_end_time',
                                `${recurringEndTimeHr.value}:${e.value}`
                              )
                            }
                          }}
                          styles={reactSelectStyle}
                        />
                      </div>
                    </div>
                    <div className={styles.inputFieldsButton}>
                      <div className={styles.viewSlotsBtn}>
                        <Button
                          title={t('ViewSlots.View Slots') || 'View Slots'}
                          // type="submit"
                          disable={!recurringIcon}
                          handleClick={() => handleRecurringSlots()}
                        />
                      </div>
                    </div>
                  </div>
                </>
              )}
            </>
          ) : (
            ''
          )}

          <div className={styles.textFields}>
            <TextArea
              label={t('Common.Notes') || 'Notes'}
              {...register('notes')}
              placeholder={t('BookingConfirmation.EnterNotes') || 'Enter Notes'}
            />
          </div>
        </div>
        <div className={styles.submitBtn}>
          <Button
            title={t('Common.Save & Book') || 'Save & Book'}
            type="button"
            handleClick={() => handleSave()}
            // disable={
            //   !patientData?.hasOwnProperty("_id") &&
            //   !selectedSlotsStatus?.length
            // }
          />
        </div>
      </form>
    </>
  )
}

export default PhysioAppointmentBooking
