// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.agentlayout_mainContainer__4PG0v {
  display: flex;
  position: relative;
}
.agentlayout_mainContainer__4PG0v .agentlayout_tabContainer__GWH-- {
  width: 95%;
}
.agentlayout_mainContainer__4PG0v .agentlayout_floatingBarContainer__lnSdb {
  position: sticky;
  top: 30px;
}`, "",{"version":3,"sources":["webpack://./src/pages/call-center-admin/agents/agentlayout.module.scss"],"names":[],"mappings":"AACA;EACE,aAAA;EACA,kBAAA;AAAF;AACE;EACE,UAAA;AACJ;AACE;EACE,gBAAA;EACA,SAAA;AACJ","sourcesContent":["@import \"../../../styles/mixin.scss\";\n.mainContainer {\n  display: flex;\n  position: relative;\n  .tabContainer {\n    width: 95%;\n  }\n  .floatingBarContainer {\n    position: sticky;\n    top: 30px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"mainContainer": `agentlayout_mainContainer__4PG0v`,
	"tabContainer": `agentlayout_tabContainer__GWH--`,
	"floatingBarContainer": `agentlayout_floatingBarContainer__lnSdb`
};
export default ___CSS_LOADER_EXPORT___;
