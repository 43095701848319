import { FC, useEffect, useState } from 'react'
import styles from './manageDynamicTemplate.module.scss'
import { useForm } from 'react-hook-form'
import { Label } from '../../../components/common/label'
import Select from 'react-select'
import { IManageDynamicTemplateForm } from '../../../interfaces/interfaces'
import { useTranslation } from 'react-i18next'
import {
  DEFAULT_TEMP,
  defaultTempOptions,
  HEADER,
  invoiceNormalField,
  invoiceTableField,
  LOWER,
  matchNormalFields,
  matchTableField,
  NORMAL_FIELD,
  normalfieldHeaderData,
  TABLE_FIELD,
  tablefieldHeaderData,
  TEMP_NAME,
  TEMP_TYPE,
  templateTypes,
} from '../templateConstant'
import { DropdownIndicator } from '../../../components/common/dropdown-indicator/DropdownIndicator'
import { convertArrayToObj, objToArray, trimValue } from '../../../utils/utils'
import { templateValidators } from '../templateValidators'
import Button from '../../../components/common/button/Button'
import RichTextEditor from '../../../components/editor/Editor'
import TableV2 from '../../../components/common/table/tableV2/TableV2'
import { useAppDispatch, useAppSelector } from '../../../hooks'
import {
  setNormalFields,
  setTableFields,
} from '../../../redux/features/dynamic-template/dynamicTemplateSlice'
import Loader from '../../../components/common/spinner/Loader'
import {
  addTemplate,
  editTemplate,
  getTemplateById,
} from '../../../redux/features/dynamic-template/dynamicTemplateAsyncActions'
import { requestGenerator } from '../../../utils/payloadGenerator'
import {
  CREATE_TEMPLATE_TYPE,
  GET_TEMPLATE_BY_ID_TYPE,
  UPDATE_TEMPLATE_TYPE,
} from '../../../constants/asyncActionsType'
import { useLocation, useNavigate } from 'react-router-dom'
import { setMessage } from '../../../redux/features/toast/toastSlice'
import { warning } from '../../../constants/data'

interface IManageDynamicTemplate {}

const ManageDynamicTemplate: FC<IManageDynamicTemplate> = () => {
  const { t } = useTranslation()
  const location = useLocation()
  const [headerContent, setHeaderContent] = useState('')
  const [lowerContent, setLowerContent] = useState('')
  const dispatch = useAppDispatch()
  const { isLoading, tableField, normalField } = useAppSelector(
    (state) => state.template
  )
  const navigate = useNavigate()
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    trigger,
    formState: { errors },
  } = useForm<IManageDynamicTemplateForm>({})
  const onSubmit = (data: any) => {
    const missingContent = []
    if (!headerContent) missingContent.push('header')
    if (!lowerContent) missingContent.push('lower')

    if (missingContent.length > 0) {
      const contentType = missingContent.join(' and ')
      dispatch(
        setMessage({
          message: `Please provide valid ${contentType} content`,
          type: warning,
        })
      )
    } else {
      const payload = {
        ...data,
        [TEMP_TYPE]: data[TEMP_TYPE]?.value,
        [HEADER]: headerContent,
        [LOWER]: lowerContent,
        [NORMAL_FIELD]: convertArrayToObj(normalField),
        [TABLE_FIELD]: convertArrayToObj(tableField),
        [DEFAULT_TEMP]: data[DEFAULT_TEMP]?.value,
      }
      if (location.state?.template?._id) {
        dispatch(
          editTemplate(
            requestGenerator({
              _id: location.state?.template?._id,
              data: payload,
            })
          )
        ).then((e) => {
          if (e.type === `${UPDATE_TEMPLATE_TYPE}/fulfilled`) {
            navigate('/template')
          }
        })
      } else {
        dispatch(addTemplate(requestGenerator(payload))).then((e) => {
          if (e.type === `${CREATE_TEMPLATE_TYPE}/fulfilled`) {
            navigate('/template')
          }
        })
      }
    }
  }

  useEffect(() => {
    setValue(TEMP_TYPE, templateTypes[0])
    setValue(DEFAULT_TEMP, defaultTempOptions[1])
    if (location.state?.template?._id) {
      dispatch(
        getTemplateById(
          requestGenerator({ _id: location.state?.template?._id })
        )
      ).then((e) => {
        if (e.type === `${GET_TEMPLATE_BY_ID_TYPE}/fulfilled`) {
          // set template type
          const type: { label: string; value: string } | undefined =
            templateTypes.find((item) => item.value === e.payload?.type)
          setValue(TEMP_TYPE, type)
          // set is_default flag
          const isDefault = defaultTempOptions.find(
            (item) => item?.value === e?.payload?.is_default
          )
          setValue(DEFAULT_TEMP, isDefault)
          // set template name
          setValue(TEMP_NAME, e.payload?.name)
          // header and lower content
          setHeaderContent(e.payload?.header_content)
          setLowerContent(e.payload?.lower_content)
          let tableField = objToArray(e.payload?.table_field)
          dispatch(setTableFields(tableField))
          const normalField = objToArray(e.payload?.normal_field)
          dispatch(setNormalFields(normalField))
        }
      })
    }
  }, [location, dispatch, setValue])

  useEffect(() => {
    return () => {
      dispatch(setTableFields(invoiceTableField))
      dispatch(setNormalFields(invoiceNormalField))
    }
  }, [dispatch])
  return (
    <>
      {isLoading ? <Loader /> : ''}
      <div className={styles.mainContainer}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className={styles.nameTypeContainer}>
            <div className={styles.importFieldContainer}>
              <div className={styles.inputFieldContainer}>
                <Label
                  labelText={t('DynamicTemplate.TempType')}
                  requiredField={true}
                  flexBasis="30%"
                  customClass={styles.label}
                />
                <div className={styles.inlineItems}>
                  <Select
                    placeholder={
                      t('DynamicTemplate.SelectTypePlaceholder') ||
                      `Select Template Type`
                    }
                    closeMenuOnSelect={true}
                    value={watch(TEMP_TYPE)}
                    {...register(TEMP_TYPE, templateValidators[TEMP_TYPE])}
                    onChange={(e: any) => {
                      setValue(TEMP_TYPE, e)
                      trigger(TEMP_TYPE)
                      const table = matchTableField[e.value]
                      const normal = matchNormalFields[e.value]
                      dispatch(setTableFields(table))
                      dispatch(setNormalFields(normal))
                    }}
                    components={{ DropdownIndicator }}
                    options={templateTypes}
                    isSearchable={true}
                    maxMenuHeight={200}
                  />
                  {errors[TEMP_TYPE] && (
                    <p className={styles.formError}>
                      {errors[TEMP_TYPE].message}
                    </p>
                  )}
                </div>
              </div>
            </div>
            <div className={styles.importFieldContainer}>
              <div className={styles.inputFieldContainer}>
                <Label
                  labelText={t('DynamicTemplate.TempName')}
                  requiredField={true}
                  flexBasis="30%"
                  customClass={styles.label}
                />
                <div className={styles.inlineItems}>
                  <input
                    type="text"
                    className={styles.inputField}
                    {...register(TEMP_NAME, templateValidators[TEMP_NAME])}
                    placeholder={
                      t('DynamicTemplate.EnterName') || `Enter Template Name`
                    }
                    onChange={(e: any) => {
                      trimValue(e)
                    }}
                  />
                  {errors[TEMP_NAME] && (
                    <p className={styles.formError}>
                      {errors[TEMP_NAME].message}
                    </p>
                  )}
                </div>
              </div>
            </div>
            <div className={styles.importFieldContainer}>
              <div className={styles.inputFieldContainer}>
                <Label
                  labelText={t('DynamicTemplate.Default')}
                  flexBasis="30%"
                  customClass={styles.label}
                />
                <div className={styles.inlineItems}>
                  <Select
                    placeholder={
                      t('DynamicTemplate.SelectTypePlaceholder') ||
                      `Select Template Type`
                    }
                    closeMenuOnSelect={true}
                    value={watch(DEFAULT_TEMP)}
                    {...register(DEFAULT_TEMP)}
                    onChange={(e: any) => {
                      setValue(DEFAULT_TEMP, e)
                      trigger(DEFAULT_TEMP)
                    }}
                    components={{ DropdownIndicator }}
                    options={defaultTempOptions}
                    isSearchable={true}
                    maxMenuHeight={200}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className={styles.importFieldContainer}>
            <div className={styles.inputFieldContainer}>
              <Label
                labelText={t('DynamicTemplate.HeaderContent')}
                requiredField={true}
                flexBasis="30%"
                customClass={styles.label}
              />
              <div className={styles.inlineItems}>
                <RichTextEditor
                  value={headerContent}
                  setValue={setHeaderContent}
                />
              </div>
            </div>
          </div>

          <div className={styles.importFieldContainer}>
            <div className={styles.inputFieldContainer}>
              <Label
                labelText={t('DynamicTemplate.LowerContent')}
                requiredField={true}
                flexBasis="30%"
                customClass={styles.label}
              />
              <div className={styles.inlineItems}>
                <RichTextEditor
                  value={lowerContent}
                  setValue={setLowerContent}
                />
              </div>
            </div>
          </div>

          <div className={styles.importFieldContainer}>
            <div className={styles.inputFieldContainer}>
              <Label
                labelText={t('DynamicTemplate.NormalField')}
                requiredField={true}
                flexBasis="30%"
                customClass={styles.label}
              />
              <div className={styles.inlineItems}>
                <TableV2
                  tableHeaderData={normalfieldHeaderData}
                  tableRowData={normalField}
                  active={false}
                  customClassForTd={styles.td}
                />
              </div>
            </div>
          </div>

          <div className={styles.importFieldContainer}>
            <div className={styles.inputFieldContainer}>
              <Label
                labelText={t('DynamicTemplate.TableField')}
                requiredField={true}
                flexBasis="30%"
                customClass={styles.label}
              />
              <div className={styles.inlineItems}>
                <TableV2
                  tableHeaderData={tablefieldHeaderData}
                  tableRowData={tableField}
                  active={false}
                  customClassForTd={styles.td}
                />
              </div>
            </div>
          </div>

          <div className={styles.btnContainer}>
            <Button type="submit" title={t('Common.Submit') || 'Submit'} />
          </div>
        </form>
      </div>
    </>
  )
}

export default ManageDynamicTemplate
