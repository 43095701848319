import { createSlice } from "@reduxjs/toolkit";
import { IIpdDashboard } from "../../../interfaces/apiInterface";
import { getIpdDashboardCaseList } from "./ipdDashBoardAsyncActions";

export const initialState: IIpdDashboard = {
  isLoading: false,
  ipdDashboardCaseData: [],
};

export const ipdDashboardSlice = createSlice({
  name: "ipdDashboard",
  initialState,
  reducers: {
    clearIpdDashboardData: (state) => {
      state.isLoading = false;
      state.ipdDashboardCaseData = [];
    },
  },
  extraReducers: (builder) => {
    builder
      // get all ipd dashboard case data
      .addCase(getIpdDashboardCaseList.pending, (state) => {
        state.isLoading = true;
        state.ipdDashboardCaseData = [];
      })
      .addCase(getIpdDashboardCaseList.fulfilled, (state, action) => {
        state.isLoading = false;
        state.ipdDashboardCaseData = action.payload.data;
      })
      .addCase(getIpdDashboardCaseList.rejected, (state, error) => {
        state.isLoading = false;
        state.ipdDashboardCaseData = [];
      });
  },
});

export const { clearIpdDashboardData } = ipdDashboardSlice.actions;
export default ipdDashboardSlice.reducer;
