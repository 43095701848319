import {
  AGE_FROM,
  AGE_TO,
  GENDER_RANGE,
  PERIOD,
  RANGE_FROM,
  RANGE_TO,
} from '../constants/constant'
import { t } from 'i18next'

export const rangePopupValidators = {
  [GENDER_RANGE]: {
    required: t('PatientEmrValidators.Gender'),
  },
  [AGE_FROM]: {
    required: t('LabConfiguration.Age_From_Validation'),
    validate: {
      handleValue: (value: any) => {
        if (Number(value) < 0) {
          return t('LabConfiguration.positive_value')
        }
      },
    },
  },
  [AGE_TO]: {
    required: t('LabConfiguration.Age_To_validation'),
    validate: {
      handleValue: (value: any, data: any) => {
        if (Number(value) < 0) {
          return t('LabConfiguration.positive_value')
        } else if (Number(value) <= Number(data.age_from)) {
          return t('LabConfiguration.age_value_validation')
        }
      },
    },
  },
  [RANGE_FROM]: {
    required: t('LabConfiguration.Range_From_validation'),
    validate: {
      handleValue: (value: any) => {
        if (Number(value) < 0) {
          return t('LabConfiguration.positive_value')
        }
      },
    },
  },
  [RANGE_TO]: {
    required: t('LabConfiguration.Range_To_Validation'),
    validate: {
      handleValue: (value: any, data: any) => {
        if (Number(value) < 0) {
          return t('LabConfiguration.positive_value')
        } else if (Number(value) <= Number(data.range_from)) {
          return 'Range to should greater than Range from'
        }
      },
    },
  },
  [PERIOD]: {
    required: t('LabConfiguration.period_validation'),
  },
}
