export const STATUS = "status";
export const _id = "_id";
export const ID = "ID";
export const emp_id = "emp_id"
export const EMPLOYEE = "employee";
export const LEAVE_TYPE = "leave_type";
export const LEAVE_CALCULATION = "leave_calculation";
export const START_DATE = "start_date";
export const END_DATE = "end_date";
export const SELECTED_DAYS = "selected_days";
export const PAID_LEAVES= "paid_leaves";
export const UNPAID_LEAVES = "unpaid_leaves";
export const REASON = "reason";
export const APPLY_LEAVE_ATTACHMENT = 'attachments';
export const name_en = "name_en";
export const FILE = "file";
export const EMPLOYEE_PROFILE_PIC = "profile_pic";
export const NAME = "name";
export const NAME_EN = "name_en";
export const DEPARTMENT = "department";
export const ATTACHEMENTS = "attachements";