// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.style_errorContainer__t81K0 {
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
}
.style_errorContainer__t81K0 .style_extraSpan__1l0tM {
  color: var(--black2);
  font-size: var(--font-16);
  font-weight: var(--font-semibold);
  width: 34%;
  flex-shrink: 0;
  text-align: end;
}`, "",{"version":3,"sources":["webpack://./src/components/common/error-message-component/style.module.scss","webpack://./src/styles/mixin.scss"],"names":[],"mappings":"AAEA;ECoBE,aAAA;EACA,mBAAA;EDnBA,kBAAA;EACA,WAAA;AAAF;AACE;ECIA,oBDHgB;ECIhB,yBDJ+B;ECK/B,iCDL+C;EAC7C,UAAA;EACA,cAAA;EACA,eAAA;AAGJ","sourcesContent":["@import \"../../../styles/mixin.scss\";\n\n.errorContainer {\n  @include flexVertical;\n  position: relative;\n  width: 100%;\n  .extraSpan {\n    @include font(var(--black2), var(--font-16), var(--font-semibold));\n    width: 34%;\n    flex-shrink: 0;\n    text-align: end;\n  }\n}\n","@mixin pageWrapper($padding) {\n  background: var(--white2);\n  // backdrop-filter: blur(6px);\n  box-shadow: 0px 10px 117px 14px rgba(0, 0, 0, 0.05);\n  border-radius: 10px;\n  padding: $padding;\n  margin: 20px 20px 30px 0px;\n}\n\n@mixin font($color, $size, $weight) {\n  color: $color;\n  font-size: $size;\n  font-weight: $weight;\n}\n\n@mixin flexCenter {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}\n\n@mixin flexVertical {\n  display: flex;\n  align-items: center;\n}\n\n@mixin flexColumn {\n  display: flex;\n  flex-direction: column;\n}\n\n@mixin responsive($width) {\n  @media (max-width: $width) {\n    @content;\n  }\n}\n\n@mixin animation($name) {\n  @keyframes #{$name} {\n    @content;\n  }\n}\n\n@mixin iconHoverText($content) {\n  cursor: pointer;\n\n  &:hover::after {\n    @include font(var(--black2), var(--font-12), var(--font-semibold));\n    @include flexCenter;\n    position: absolute;\n    content: $content;\n    background-color: var(--white1);\n    box-shadow: 0px 4px 34px rgba(0, 0, 0, 0.1);\n    border-radius: 6px;\n    width: 116px;\n    height: 36px;\n    z-index: 1;\n    opacity: 1;\n    margin: -25px 0 0 25px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"errorContainer": `style_errorContainer__t81K0`,
	"extraSpan": `style_extraSpan__1l0tM`
};
export default ___CSS_LOADER_EXPORT___;
