import { createSlice } from '@reduxjs/toolkit'
import { IIpdBooking } from '../../../interfaces/apiInterface'
import {
  getIpdBookingPatientList,
  getIpdBookDetails,
  getIpdBookDoctorsList,
  getIpdAvailableBedsList,
  getIpdAllBedsList,
  getIpdBookDropdownList,
  updateIpdBooking,
  getAllBookedBeds,
  getIpdAllBedsListForSchedular,
} from './ipdBookingAsyncActions'

export const initialState: IIpdBooking = {
  isLoading: false,
  bedLoading: false,
  branchData: {},
  ipdBookingPatientData: [],
  ipdBookDetailsData: {},
  ipdBookDoctorsData: [],
  ipdBedsData: [],
  ipdBookDropDownData: [],
  bookedBed: [],
  dataSource: [],
  resources: [],
  ipdBedsDataForSchedular: [],
  hasMorePage: false,
  totalCount: 0,
}

export const ipdBookingSlice = createSlice({
  name: 'ipdBooking',
  initialState,
  reducers: {
    clearIpdBookingData: (state) => {
      state.isLoading = false
      state.bedLoading = false
      // state.branchData = {};
      state.ipdBookingPatientData = []
      state.ipdBookDetailsData = {}
      state.ipdBookDoctorsData = []
      state.ipdBedsData = []
      state.ipdBookDropDownData = []
    },
    addBranchData: (state, action) => {
      state.branchData = action.payload
    },
    clearIpdBookingDropDownData: (state) => {
      state.ipdBookDropDownData = []
    },
    clearCalanderData: (state) => {
      state.ipdBedsData = []
      state.ipdBedsDataForSchedular = []
      state.hasMorePage = false
      state.resources = []
      state.totalCount = 0
      state.bookedBed = []
      state.dataSource = []
    },
  },
  extraReducers: (builder) => {
    builder
      // get all ipd booking patient data
      .addCase(getIpdBookingPatientList.pending, (state) => {
        state.isLoading = true
        state.ipdBookingPatientData = []
      })
      .addCase(getIpdBookingPatientList.fulfilled, (state, action) => {
        state.isLoading = false
        state.ipdBookingPatientData = action.payload.data
      })
      .addCase(getIpdBookingPatientList.rejected, (state, error) => {
        state.isLoading = false
        state.ipdBookingPatientData = []
      })
      // get book ipd book data
      .addCase(getIpdBookDetails.pending, (state) => {
        state.isLoading = true
        state.ipdBookDetailsData = {}
      })
      .addCase(getIpdBookDetails.fulfilled, (state, action) => {
        state.isLoading = false
        state.ipdBookDetailsData = action.payload
      })
      .addCase(getIpdBookDetails.rejected, (state, error) => {
        state.isLoading = false
        state.ipdBookDetailsData = {}
      })
      // get book ipd doctors data
      .addCase(getIpdBookDoctorsList.pending, (state) => {
        state.isLoading = true
        state.ipdBookDoctorsData = []
      })
      .addCase(getIpdBookDoctorsList.fulfilled, (state, action) => {
        state.isLoading = false
        state.ipdBookDoctorsData = action.payload
      })
      .addCase(getIpdBookDoctorsList.rejected, (state, error) => {
        state.isLoading = false
        state.ipdBookDoctorsData = []
      })
      // get ipd available beds
      .addCase(getIpdAvailableBedsList.pending, (state) => {
        state.isLoading = true
        state.ipdBedsData = []
      })
      .addCase(getIpdAvailableBedsList.fulfilled, (state, action) => {
        state.isLoading = false
        state.ipdBedsData = action.payload.data
      })
      .addCase(getIpdAvailableBedsList.rejected, (state, error) => {
        state.isLoading = false
        state.ipdBedsData = []
      })
      // get ipd all beds
      .addCase(getIpdAllBedsList.pending, (state) => {
        state.isLoading = true
        state.ipdBedsData = []
        state.bedLoading = true
      })
      .addCase(getIpdAllBedsList.fulfilled, (state, action) => {
        state.isLoading = false
        state.bedLoading = false

        state.ipdBedsData = action.payload.data
        state.totalCount = action?.payload?.total
      })
      .addCase(getIpdAllBedsList.rejected, (state, error) => {
        state.isLoading = false
        state.bedLoading = false
        state.ipdBedsData = []
      })
      // get ipd all beds for schedular
      .addCase(getIpdAllBedsListForSchedular.pending, (state) => {
        state.isLoading = true
        state.ipdBedsDataForSchedular = []
        state.bedLoading = true
        state.hasMorePage = false
      })
      .addCase(getIpdAllBedsListForSchedular.fulfilled, (state, action) => {
        state.isLoading = false
        state.bedLoading = false

        state.ipdBedsDataForSchedular = action.payload.data
        state.totalCount = action?.payload?.total
        state.hasMorePage = action.payload?.hasMorePages
        const bedArray =
          action?.payload?.data.length > 0 &&
          action?.payload?.data.map((item: any) => {
            return {
              label: item?.bed_name,
              id: item._id,
            }
          })

        state.resources = [
          {
            label: 'Beds',
            value: '_id',
            dataSource: bedArray || [],
          },
        ]
      })
      .addCase(getIpdAllBedsListForSchedular.rejected, (state, error) => {
        state.isLoading = false
        state.bedLoading = false
        state.ipdBedsData = []
      })
      // get ipd book drop down data
      .addCase(getIpdBookDropdownList.pending, (state) => {
        state.isLoading = true
        state.ipdBookDropDownData = []
      })
      .addCase(getIpdBookDropdownList.fulfilled, (state, action) => {
        state.isLoading = false
        state.ipdBookDropDownData = action.payload
      })
      .addCase(getIpdBookDropdownList.rejected, (state, error) => {
        state.isLoading = false
        state.ipdBookDropDownData = []
      })
      // update ipd book data
      .addCase(updateIpdBooking.pending, (state) => {
        state.isLoading = true
      })
      .addCase(updateIpdBooking.fulfilled, (state, action) => {
        state.isLoading = false
      })
      .addCase(updateIpdBooking.rejected, (state, error) => {
        state.isLoading = false
      })

      // get booked bed data
      .addCase(getAllBookedBeds.pending, (state) => {
        // state.isLoading = true
      })
      .addCase(getAllBookedBeds.fulfilled, (state, action) => {
        state.isLoading = false
        state.bookedBed =
          action.payload && action.payload?.length > 0
            ? action.payload?.data
            : []
        state.dataSource =
          action.payload && action.payload?.length > 0
            ? action.payload?.map((item: any) => {
                let dateStart = new Date(item?.booked_date)
                dateStart.setHours(0)
                dateStart.setMinutes(0)
                dateStart.setSeconds(0)
                dateStart.setMilliseconds(0)
                let dateEnd = new Date('9999-12-31T23:59:59.999Z')
                return {
                  label: `${item?.patient_name}  ${
                    item?.emr_no ? `File no. - ${item?.emr_no}` : ''
                  }  ${item?.phone ? `Phone - ${item?.phone}` : ''} `,
                  _id: item?.bed_id,
                  bed_name: item?.bed_name,
                  dateStart,
                  dateEnd,
                  backgroundColor: '#5936F1',
                  patient_name: item?.patient_name,
                  file_no: item?.emr_no,
                  patient_id: item?.patient_id,
                  patient_phone: item?.phone,
                }
              })
            : []
      })
      .addCase(getAllBookedBeds.rejected, (state, error) => {
        state.isLoading = false
      })
  },
})

export const {
  clearIpdBookingData,
  addBranchData,
  clearIpdBookingDropDownData,
  clearCalanderData,
} = ipdBookingSlice.actions
export default ipdBookingSlice.reducer
