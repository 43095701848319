import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import Tabs from '../../components/core/tab/Tabs'
import { unavailabilityTabData } from '../../constants/data'
import { useEffect } from 'react'

const UnavailabilityLayout = () => {
  const navigate = useNavigate()
  const location = useLocation().pathname
  useEffect(() => {
    if (location === '/unavailability') {
      navigate('/unavailability/doctor')
    }
  }, [location, navigate])
  return (
    <>
      <Tabs tabData={unavailabilityTabData} />
      <Outlet />
    </>
  )
}

export default UnavailabilityLayout
