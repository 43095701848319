import moment from 'moment'
import { t } from 'i18next'

export const DetailHeader: any[] = [
  {
    Header: t('CallCenterAdmin.LEAD_NAME'),
    accessor: 'name',
  },
  {
    Header: t('CallCenterAdmin.DATE_ASSIGNED'),
    accessor: 'dateassigned',
    Cell: ({ row }: any) => {
      const originalDate = row?.original?.assigned_date
      const formattedDate = moment(originalDate).format('DD MMM YYYY')
      return <>{formattedDate}</>
    },
  },
  {
    Header: t('CallCenterAdmin.DATE_CONTACTED'),
    accessor: 'datecontacted',
    Cell: ({ row }: any) => {
      const originalDate = row?.original?.feadbacks?.createdAt
      const formattedDate = moment(originalDate).format('DD MMM YYYY')
      return <>{formattedDate}</>
    },
  },
  // {
  //   Header: "FOLLOW UP CALLS",
  //   accessor: "followupscall",
  //   Cell: ({ row}: any) => {
  //     const follwupCalls = row?.original?.feadbacks?.filter((item: any) => {
  //       return (
  //         item?.status === "INTERESTED" || item?.status === "SCHEDULE_LATER"
  //       );
  //     });
  //     return (
  //       <>
  //         <span>{follwupCalls?.length ?? 0 }</span>
  //       </>
  //     );
  //   },
  // },
  {
    Header: t('RcptTableHeader.STATUS'),
    accessor: 'status',
  },
  {
    Header: t('PatientHistoryTableHeader.NOTES'),
    accessor: 'notes',
    Cell: ({ row, onPopClose, onRowClick }: any) => {
      return (
        <>
          {/* <span style={{ cursor: "pointer", color: "#0e26a3" }}>View</span> */}
          <span>-</span>
        </>
      )
    },
  },
]
