import React, { FC, useRef } from 'react'
import styles from './scribeDialog.module.scss'
import {
  CloseIcon,
  ZoomInICon,
  ZoomOutIcon,
} from '../../../../components/common/svg-components'

import { colors } from '../../../../constants/color'

import {
  ReactZoomPanPinchRef,
  TransformComponent,
  TransformWrapper,
} from 'react-zoom-pan-pinch'

interface IScribeImageAndNotesPreviewDialog {
  handleClose?: any
  popData?: any
}

const ScribeImageAndNotesPreviewDialog: FC<
  IScribeImageAndNotesPreviewDialog
> = ({ handleClose, popData }) => {
  const transformComponentRef = useRef<ReactZoomPanPinchRef | null>(null)

  return (
    <>
      {/* Scribe Images and Notes Preview Dialog */}
      <div
        className={styles.imageZoomInOutModalContainer}
        onClick={(e) => {
          e.stopPropagation()
        }}
      >
        <CloseIcon
          customClass={styles.closeIconStyle}
          fillColor={colors.green1}
          handleClick={() => {
            handleClose()
          }}
        />
        <TransformWrapper
          ref={transformComponentRef}
          initialScale={1}
          disablePadding={true}
          wheel={{ wheelDisabled: true }}
          doubleClick={{ disabled: true }}
        >
          {(utils) => (
            <React.Fragment>
              <ImageControllar {...utils} />
              <TransformComponent wrapperClass={styles.zoomImageWrapper}>
                <img
                  className={styles.zoomImageStyle}
                  alt="zoom_img"
                  src={popData?.imageUrl}
                />
              </TransformComponent>
            </React.Fragment>
          )}
        </TransformWrapper>
      </div>
      {/* Scribe Images and Notes Preview Dialog */}
    </>
  )
}
export default ScribeImageAndNotesPreviewDialog

const ImageControllar = ({ zoomIn, zoomOut }: any) => (
  <div className={styles.imageControllarContainer}>
    <ZoomInICon
      customClass={styles.zoomInIconStyle}
      fillColor={colors.black1}
      handleClick={() => zoomIn()}
    />
    <ZoomOutIcon
      customClass={styles.zoomOutIconStyle}
      fillColor={colors.black1}
      handleClick={() => zoomOut()}
    />
  </div>
)
