// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.addNotesPopup_notesPopupContainer__v3ehA {
  width: 450px;
  margin: auto;
  background: var(--white1);
  border-radius: 10px;
  padding: 10px;
}
.addNotesPopup_notesPopupContainer__v3ehA .addNotesPopup_closeIconStyle__dnoYW {
  cursor: pointer;
  float: right;
}
.addNotesPopup_notesPopupContainer__v3ehA .addNotesPopup_notesContainer__v6h3i {
  margin-top: 50px;
}
.addNotesPopup_notesPopupContainer__v3ehA .addNotesPopup_notesContainer__v6h3i .addNotesPopup_title__QHn1F {
  text-align: center;
  font-weight: var(--font-semibold);
  font-size: var(--font-24);
  line-height: 36px;
  color: var(--black2);
}
.addNotesPopup_notesPopupContainer__v3ehA .addNotesPopup_notesContainer__v6h3i .addNotesPopup_dividerStyle__sFfIO {
  margin: 5px auto 50px auto;
  width: 80px;
  height: 3px;
  background: var(--green1);
}
.addNotesPopup_notesPopupContainer__v3ehA .addNotesPopup_notesContainer__v6h3i .addNotesPopup_labelFieldContainer__baII6 {
  display: flex;
  flex-direction: row;
  margin: 0 20px;
}
.addNotesPopup_notesPopupContainer__v3ehA .addNotesPopup_notesContainer__v6h3i .addNotesPopup_labelFieldContainer__baII6 .addNotesPopup_labelText__1iDbV {
  margin-right: 10px;
  font-size: var(--font-16);
  font-weight: var(--font-semibold);
}
.addNotesPopup_notesPopupContainer__v3ehA .addNotesPopup_notesContainer__v6h3i .addNotesPopup_labelFieldContainer__baII6 .addNotesPopup_inputField__LqeET {
  border-radius: 6px;
  border: 1px solid var(--grey7);
  height: 120px;
  width: 100%;
  padding: 10px;
}
.addNotesPopup_notesPopupContainer__v3ehA .addNotesPopup_notesContainer__v6h3i .addNotesPopup_btnContainer__SwFLX {
  margin: 30px auto;
  text-align: center;
}`, "",{"version":3,"sources":["webpack://./src/pages/insurance/ongoing-claims/add-notes-popup/addNotesPopup.module.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;EACA,YAAA;EACA,yBAAA;EACA,mBAAA;EACA,aAAA;AACF;AAAE;EACE,eAAA;EACA,YAAA;AAEJ;AAAE;EACE,gBAAA;AAEJ;AADI;EACE,kBAAA;EACA,iCAAA;EACA,yBAAA;EACA,iBAAA;EACA,oBAAA;AAGN;AADI;EACE,0BAAA;EACA,WAAA;EACA,WAAA;EACA,yBAAA;AAGN;AADI;EACE,aAAA;EACA,mBAAA;EACA,cAAA;AAGN;AAFM;EACE,kBAAA;EACA,yBAAA;EACA,iCAAA;AAIR;AAFM;EACE,kBAAA;EACA,8BAAA;EACA,aAAA;EACA,WAAA;EACA,aAAA;AAIR;AADI;EACE,iBAAA;EACA,kBAAA;AAGN","sourcesContent":[".notesPopupContainer {\n  width: 450px;\n  margin: auto;\n  background: var(--white1);\n  border-radius: 10px;\n  padding: 10px;\n  .closeIconStyle {\n    cursor: pointer;\n    float: right;\n  }\n  .notesContainer {\n    margin-top: 50px;\n    .title {\n      text-align: center;\n      font-weight: var(--font-semibold);\n      font-size: var(--font-24);\n      line-height: 36px;\n      color: var(--black2);\n    }\n    .dividerStyle {\n      margin: 5px auto 50px auto;\n      width: 80px;\n      height: 3px;\n      background: var(--green1);\n    }\n    .labelFieldContainer {\n      display: flex;\n      flex-direction: row;\n      margin: 0 20px;\n      .labelText {\n        margin-right: 10px;\n        font-size: var(--font-16);\n        font-weight: var(--font-semibold);\n      }\n      .inputField {\n        border-radius: 6px;\n        border: 1px solid var(--grey7);\n        height: 120px;\n        width: 100%;\n        padding: 10px;\n      }\n    }\n    .btnContainer {\n      margin: 30px auto;\n      text-align: center;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"notesPopupContainer": `addNotesPopup_notesPopupContainer__v3ehA`,
	"closeIconStyle": `addNotesPopup_closeIconStyle__dnoYW`,
	"notesContainer": `addNotesPopup_notesContainer__v6h3i`,
	"title": `addNotesPopup_title__QHn1F`,
	"dividerStyle": `addNotesPopup_dividerStyle__sFfIO`,
	"labelFieldContainer": `addNotesPopup_labelFieldContainer__baII6`,
	"labelText": `addNotesPopup_labelText__1iDbV`,
	"inputField": `addNotesPopup_inputField__LqeET`,
	"btnContainer": `addNotesPopup_btnContainer__SwFLX`
};
export default ___CSS_LOADER_EXPORT___;
