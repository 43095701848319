import { Outlet } from "react-router";

const MedicalCenterWard = () => {
  return (
    <>
      <Outlet />
    </>
  );
};

export default MedicalCenterWard;
