import moment from 'moment'
import { CheckIcon, UncheckIcon } from '../../components/common/svg-components'
import { colors } from '../../constants/color'
import { useAppDispatch, useAppSelector } from '../../hooks'
import {
  addIssueData,
  removeIssueData,
  addMainStoreData,
  removeMainStoreData,
} from '../../redux/features/branch-store/branchStoreSlice'
import { t } from 'i18next'

export const branchstoreRequestPopupHeaderData: any = [
  {
    Header: t('RcptTableHeader.DATE'),
    accessor: (row: any) => {
      return moment(row?.date).format('DD MMM YYYY')
    },
    Cell: (props: any) => {
      const convertDate = moment(props?.row?.original?.date).format(
        'DD MMM YYYY'
      )
      return (
        <>{props?.row?.original?.date ? <span>{convertDate}</span> : '-'}</>
      )
    },
  },
  {
    Header: t('INVTableHeader.REQID'),
    accessor: (row: any) => {
      return row?.req_no?.toString().padStart(6, '0') ?? '-'
    },
    Cell: (props: any) => {
      return (
        <>
          {props?.row?.original?.req_no ? (
            <span>
              {props?.row?.original?.req_no?.toString().padStart(6, '0')}
            </span>
          ) : (
            '-'
          )}
        </>
      )
    },
  },
  {
    Header: t('INVTableHeader.SOURCE'),
    Cell: (props: any) => {
      return (
        <>
          {props?.row?.original?.request_source_type ? (
            <span>
              {props?.row?.original?.request_source_type?.toLowerCase()}
            </span>
          ) : (
            '-'
          )}
        </>
      )
    },
  },
  {
    Header: t('INVTableHeader.SUB_SOURCE'),
    accessor: 'request_source',
    Cell: (props: any) => {
      return (
        <>
          {props?.row?.original?.request_source ? (
            <span>{props?.row?.original?.request_source}</span>
          ) : (
            '-'
          )}
        </>
      )
    },
  },
  {
    Header: t('ViewInventory.ITEM NAME'),
    accessor: 'item_name',
  },
  {
    Header: t('INVTableHeader.MINBAL'),
    accessor: 'qty_to_maintain',
  },
  {
    Header: t('InventoryItemTable.UNIT_TYPE'),
    accessor: 'req_unit_type.value',
  },
  {
    Header: t('INVTableHeader.AvailStockQTY'),
    accessor: 'available_qty',
  },
  {
    Header: t('INVTableHeader.ReqStockQTY'),
    accessor: 'pending_qty',
  },

  {
    Header: t('INVTableHeader.REQTOMAIN'),
    disableSortBy: true,
    Cell: (props: any) => {
      const dispatch = useAppDispatch()
      const { selectedMainStoreData } = useAppSelector(
        (state) => state.branchStore
      )
      const handleMainStoreCheck = (checkType: string) => {
        if (checkType === 'REMOVE') {
          dispatch(removeMainStoreData(props?.row?.original))
        } else if (checkType === 'ADD') {
          dispatch(addMainStoreData(props?.row?.original))
        }
      }
      return (
        <>
          {selectedMainStoreData?.some(
            (item: any) => item?._id === props.row.original._id
          ) ? (
            <CheckIcon
              fillColor={colors.green1}
              handleClick={() => handleMainStoreCheck('REMOVE')}
            />
          ) : (
            <UncheckIcon
              fillColor={colors.grey1}
              handleClick={() => handleMainStoreCheck('ADD')}
            />
          )}
        </>
      )
    },
  },
  {
    Header: t('INVTableHeader.ISSUE'),
    disableSortBy: true,
    Cell: (props: any) => {
      const dispatch = useAppDispatch()
      const { selectedIssueData } = useAppSelector((state) => state.branchStore)
      const handleIssueCheck = (checkType: string) => {
        if (checkType === 'REMOVE') {
          dispatch(removeIssueData(props?.row?.original))
        } else if (checkType === 'ADD') {
          dispatch(addIssueData(props?.row?.original))
        }
      }
      return (
        <>
          {!(props.row.original.tag === 'red') ? (
            <div>
              {selectedIssueData?.some(
                (item: any) => item?._id === props.row.original._id
              ) ? (
                <CheckIcon
                  fillColor={colors.green1}
                  handleClick={() => handleIssueCheck('REMOVE')}
                />
              ) : (
                <UncheckIcon
                  fillColor={colors.grey1}
                  handleClick={() => handleIssueCheck('ADD')}
                />
              )}
            </div>
          ) : (
            '-'
          )}
        </>
      )
    },
  },
]
