import {
  GET_ALL_DOCTORS_APPOINTMENT_TYPE,
  GET_ALL_DOCTORS_TYPE,
  GET_AVAILABLE_SLOTS_TYPE,
  BOOKING_CONFIRMATION_TYPE,
  GET_RECURRING_AVAILABLE_SLOTS_TYPE,
  GET_ALL_PHYSIO_APPOINTMENT_TYPE,
  GET_ALL_ROOMS_TYPE,
  GET_DOC_UNAVAILABILITY_TYPE,
  ADD_UNAVAILABILITY_TYPE,
  UPDATE_UNAVAILABILITY_TYPE,
  DELETE_UNAVAILABILITY_TYPE,
} from "../../../constants/asyncActionsType";
import createAsyncThunkForSlice from "../../../utils/utils";
import {
  getAllDoctorsList,
  getAllDoctorAppointmentsList,
  getAllAvailableSlotsDoctor,
  addBookingConfirmation,
  getAllAvailableRecurringSlotsDoctor,
  physioAppointments,
  getAllRoomListForCal,
  getUnavailabiltyDOC,
  addUnavailability,
  editUnavailability,
  removeUnavailability,
} from "./bookingAppointmentsCrud";

// get all doctors
export const getAllDoctors = createAsyncThunkForSlice(
  GET_ALL_DOCTORS_TYPE,
  getAllDoctorsList
);
// get all appointments of doctor's

export const getAllDoctorAppointments = createAsyncThunkForSlice(
  GET_ALL_DOCTORS_APPOINTMENT_TYPE,
  getAllDoctorAppointmentsList
);

// get available slots of doctors
export const getAvailableSlots = createAsyncThunkForSlice(
  GET_AVAILABLE_SLOTS_TYPE,
  getAllAvailableSlotsDoctor
);

// get recurring available slots
export const getRecurringAvailableSlots = createAsyncThunkForSlice(
  GET_RECURRING_AVAILABLE_SLOTS_TYPE,
  getAllAvailableRecurringSlotsDoctor
);

// add booking confirmation
export const bookingConfirmation = createAsyncThunkForSlice(
  BOOKING_CONFIRMATION_TYPE,
  addBookingConfirmation,
  {
    isToast: true,
  }
);

export const getAllPhysioAppointments = createAsyncThunkForSlice(
  GET_ALL_PHYSIO_APPOINTMENT_TYPE,
  physioAppointments
);

// get all doctors
export const getAllRoomsForCalnedar = createAsyncThunkForSlice(
  GET_ALL_ROOMS_TYPE,
  getAllRoomListForCal
);

// get doctor unavailability
export const getDocUnavailability = createAsyncThunkForSlice(
  GET_DOC_UNAVAILABILITY_TYPE,
  getUnavailabiltyDOC
);

// add unavailablity
export const createUnavailablity = createAsyncThunkForSlice(
  ADD_UNAVAILABILITY_TYPE,
  addUnavailability,
  { isToast: true }
);

// update unavailability

export const updateUnavailablity = createAsyncThunkForSlice(
  UPDATE_UNAVAILABILITY_TYPE,
  editUnavailability,
  { isToast: true }
);

// delete unavailability
export const deleteUnavailablity = createAsyncThunkForSlice(
  DELETE_UNAVAILABILITY_TYPE,
  removeUnavailability,
  { isToast: true }
);
