import { FC, useRef } from 'react'
import { Chart } from 'smart-webcomponents-react/chart'
import styles from './chart.module.scss'
import moment from 'moment'

interface IBarchart {
  data: any[]
  captionText: string
  XDataField: string
  YDataDescription: string
  isDate?: boolean
  showToolTips?: boolean
  series: { dataField: string; displayText: string; fillColor: string }[]
}

const BarChart: FC<IBarchart> = ({
  data,
  captionText,
  XDataField,
  YDataDescription,
  isDate,
  showToolTips = false,
  series,
}) => {
  const chartRef = useRef<Chart | null>(null)

  const padding = { left: 5, top: 5, right: 5, bottom: 5 }
  const titlePadding = { left: 90, top: 0, right: 0, bottom: 10 }

  const xAxis = {
    dataField: XDataField,
    gridLines: { visible: false },
    labels: {
      angle: -90,
      rotationPoint: 'center',
      offset: { x: 0, y: 10 },
      autoRotate: true,
      autoRotateAngle: 45,
      formatFunction: (value: any) =>
        isDate ? moment(value).format('DD-MMM') : value,
    },
    type: isDate ? 'dateTime' : 'basic',
    baseUnit: isDate ? 'month' : undefined,
    unitInterval: 1,
  }

  const colorScheme = 'scheme32'
  // const colorScheme = ['#1A6642', '#46C26F', '#F9B956', '#F38443', '#DE513D'];

  const seriesGroups = [
    {
      type: 'column',
      columnsGapPercent: 50,
      columnsMaxWidth: 20,
      seriesGapPercent: 0,
      valueAxis: {
        // unitInterval: 10,
        minValue: 0,
        // maxValue: 100,
        description: YDataDescription,
        axisSize: 'auto',
      },
      labels: {
        visible: (value: number) => value !== 0,
        verticalAlignment: 'top',
        offset: {
          x: 0,
          y: -20,
        },
      },
      series: series,
    },
  ]

  return (
    <Chart
      ref={chartRef}
      id="chart"
      caption={captionText}
      padding={padding}
      description=""
      titlePadding={titlePadding}
      dataSource={data}
      xAxis={xAxis}
      colorScheme={colorScheme}
      seriesGroups={seriesGroups}
      className={styles.chart}
      showToolTips={showToolTips}
      animation="none"
    />
  )
}

export default BarChart
