import axios from 'axios'
import {
  ADD_EMPLOYEE,
  DELETE_EMPLOYEE,
  EDIT_EMPLOYEE_BY_ID,
  GET_ALL_EMPLOYEE,
  GET_ALL_REPORTING_USER,
  GET_ALL_SEARCH_USER,
  UPDATE_EMPLOYEE_STATUS,
} from '../../../config/config'
import { IAPIPayload } from '../../../interfaces/apiInterface'

export const getAllEmployeeList = (data: IAPIPayload) => {
  return axios.post(GET_ALL_EMPLOYEE, data)
}

export const addEmployees = (data: IAPIPayload) => {
  return axios.post(ADD_EMPLOYEE, data)
}

export const editEmployeeByIds = (data: IAPIPayload) => {
  return axios.post(EDIT_EMPLOYEE_BY_ID, data)
}

export const updateEmployeeStatuss = (data: IAPIPayload) => {
  return axios.post(UPDATE_EMPLOYEE_STATUS, data)
}
export const deleteEmployees = (data: IAPIPayload) => {
  return axios.post(DELETE_EMPLOYEE, data)
}
export const getAllSearchUser = (data: IAPIPayload) => {
  return axios.post(GET_ALL_SEARCH_USER, data)
}

export const getAllReportingUser = (data: IAPIPayload) => {
  return axios.post(GET_ALL_REPORTING_USER, data)
}
