import { t } from 'i18next'
import {
  EMR_CLAIM_TYPE,
  EMR_EXPIRY_DATE,
  EMR_INSURANCE_COMPANY,
  EMR_INSURANCE_PLAN,
  EMR_POLICY_NO,
  EMR_REIMBURSEMENT_TYPE,
} from '../constants/constant'

export const insurancePlanValidators = {
  [EMR_INSURANCE_COMPANY]: {
    required: t('InsurancePlanValidators.InsCompany'),
  },
  [EMR_INSURANCE_PLAN]: {
    required: t('InsurancePlanValidators.InsPlan'),
  },
  [EMR_POLICY_NO]: {
    required: t('InsurancePlanValidators.Policy'),
  },
  [EMR_REIMBURSEMENT_TYPE]: {
    required: t('InsurancePlanValidators.Reimburesment'),
  },
  [EMR_CLAIM_TYPE]: {
    required: t('InsurancePlanValidators.ClaimType'),
  },
  [EMR_EXPIRY_DATE]: {
    required: t('InsurancePlanValidators.ExpiryDate'),
  },
}
