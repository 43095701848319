import axios from 'axios'
import { IAPIPayload } from '../../../interfaces/apiInterface'
import {
  ADD_LEAVE,
  DELETE_LEAVE,
  EDIT_LEAVE_BY_ID,
  GET_ALL_ATTACH,
  GET_ALL_LEAVE,
  GET_LEAVE_BY_ID,
  GET_LEAVE_CALCULATION,
  GET_LEAVE_TABLE,
  GET_SALARY,
  UPDATE_LEAVE_STATUS,
} from '../../../config/config'

export const getAllLeaveList = (data: IAPIPayload) => {
  return axios.post(GET_ALL_LEAVE, data)
}

export const getSalaryList = (data: IAPIPayload) => {
  return axios.post(GET_SALARY, data)
}

export const getAllAtach = (data: IAPIPayload) => {
  return axios.post(GET_ALL_ATTACH, data)
}

export const getLeaveTableList = (data: IAPIPayload) => {
  return axios.post(GET_LEAVE_TABLE, data)
}

export const getLeaveCalculationList = (data: IAPIPayload) => {
  return axios.post(GET_LEAVE_CALCULATION, data)
}

export const addLeaves = (data: IAPIPayload) => {
  return axios.post(ADD_LEAVE, data)
}

export const getLeaveByIds = (data: IAPIPayload) => {
  return axios.post(GET_LEAVE_BY_ID, data)
}

export const editLeaveByIds = (data: IAPIPayload) => {
  return axios.post(EDIT_LEAVE_BY_ID, data)
}

export const updateLeaveStatuss = (data: IAPIPayload) => {
  return axios.post(UPDATE_LEAVE_STATUS, data)
}

export const deleteLeaves = (data: IAPIPayload) => {
  return axios.post(DELETE_LEAVE, data)
}
