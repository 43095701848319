// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.paymentConfirmationModal_notesPopupContainer__yrjGN {
  width: 450px;
  margin: auto;
  background: var(--white1);
  border-radius: 10px;
  padding: 10px;
}
.paymentConfirmationModal_notesPopupContainer__yrjGN .paymentConfirmationModal_closeIconStyle__hZo6E {
  cursor: pointer;
  float: right;
}
.paymentConfirmationModal_notesPopupContainer__yrjGN .paymentConfirmationModal_notesContainer__XqPZO {
  margin-top: 50px;
}
.paymentConfirmationModal_notesPopupContainer__yrjGN .paymentConfirmationModal_notesContainer__XqPZO .paymentConfirmationModal_title__-j97\\+ {
  text-align: center;
  font-weight: var(--font-semibold);
  font-size: var(--font-24);
  line-height: 36px;
  color: var(--black2);
}
.paymentConfirmationModal_notesPopupContainer__yrjGN .paymentConfirmationModal_notesContainer__XqPZO .paymentConfirmationModal_dividerStyle__pydk5 {
  margin: 10px auto 30px auto;
  width: 80px;
  height: 3px;
  background: var(--green1);
}
.paymentConfirmationModal_notesPopupContainer__yrjGN .paymentConfirmationModal_notesContainer__XqPZO .paymentConfirmationModal_deleteText__OFAYA {
  font-weight: var(--font-normal);
  font-size: var(--font-16);
  text-align: center;
}
.paymentConfirmationModal_notesPopupContainer__yrjGN .paymentConfirmationModal_notesContainer__XqPZO .paymentConfirmationModal_btnContainer__fj42r {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 35px 0;
}
.paymentConfirmationModal_notesPopupContainer__yrjGN .paymentConfirmationModal_notesContainer__XqPZO .paymentConfirmationModal_btnContainer__fj42r .paymentConfirmationModal_yesButtonStyle__n3dyV {
  width: 100px;
}
.paymentConfirmationModal_notesPopupContainer__yrjGN .paymentConfirmationModal_notesContainer__XqPZO .paymentConfirmationModal_btnContainer__fj42r .paymentConfirmationModal_noButtonStyle__YH3Wq {
  color: var(--blue1);
  border: 1px solid var(--blue1);
  background: var(--white1);
  width: 100px;
}`, "",{"version":3,"sources":["webpack://./src/components/common/modal/payment-confirmation-modal/paymentConfirmationModal.module.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;EACA,YAAA;EACA,yBAAA;EACA,mBAAA;EACA,aAAA;AACF;AAAE;EACE,eAAA;EACA,YAAA;AAEJ;AAAE;EACE,gBAAA;AAEJ;AADI;EACE,kBAAA;EACA,iCAAA;EACA,yBAAA;EACA,iBAAA;EACA,oBAAA;AAGN;AADI;EACE,2BAAA;EACA,WAAA;EACA,WAAA;EACA,yBAAA;AAGN;AADI;EACE,+BAAA;EACA,yBAAA;EACA,kBAAA;AAGN;AADI;EACE,aAAA;EACA,mBAAA;EACA,mBAAA;EACA,uBAAA;EACA,cAAA;AAGN;AAFM;EACE,YAAA;AAIR;AAFM;EACE,mBAAA;EACA,8BAAA;EACA,yBAAA;EACA,YAAA;AAIR","sourcesContent":[".notesPopupContainer {\n  width: 450px;\n  margin: auto;\n  background: var(--white1);\n  border-radius: 10px;\n  padding: 10px;\n  .closeIconStyle {\n    cursor: pointer;\n    float: right;\n  }\n  .notesContainer {\n    margin-top: 50px;\n    .title {\n      text-align: center;\n      font-weight: var(--font-semibold);\n      font-size: var(--font-24);\n      line-height: 36px;\n      color: var(--black2);\n    }\n    .dividerStyle {\n      margin: 10px auto 30px auto;\n      width: 80px;\n      height: 3px;\n      background: var(--green1);\n    }\n    .deleteText {\n      font-weight: var(--font-normal);\n      font-size: var(--font-16);\n      text-align: center;\n    }\n    .btnContainer {\n      display: flex;\n      flex-direction: row;\n      align-items: center;\n      justify-content: center;\n      margin: 35px 0;\n      .yesButtonStyle {\n        width: 100px;\n      }\n      .noButtonStyle {\n        color: var(--blue1);\n        border: 1px solid var(--blue1);\n        background: var(--white1);\n        width: 100px;\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"notesPopupContainer": `paymentConfirmationModal_notesPopupContainer__yrjGN`,
	"closeIconStyle": `paymentConfirmationModal_closeIconStyle__hZo6E`,
	"notesContainer": `paymentConfirmationModal_notesContainer__XqPZO`,
	"title": `paymentConfirmationModal_title__-j97+`,
	"dividerStyle": `paymentConfirmationModal_dividerStyle__pydk5`,
	"deleteText": `paymentConfirmationModal_deleteText__OFAYA`,
	"btnContainer": `paymentConfirmationModal_btnContainer__fj42r`,
	"yesButtonStyle": `paymentConfirmationModal_yesButtonStyle__n3dyV`,
	"noButtonStyle": `paymentConfirmationModal_noButtonStyle__YH3Wq`
};
export default ___CSS_LOADER_EXPORT___;
