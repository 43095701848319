import { isBoldRow, isBoldRowiWithTotal } from '../../../constants/data'
import styles from './Balancesheet.module.scss'
import { t } from 'i18next'

// Balance Sheet Table Data
const currentDate = new Date()
const formattedDate = `AS AT ${currentDate.getDate()} ${currentDate.toLocaleString(
  'default',
  { month: 'short' }
)} ${currentDate.getFullYear()}`

// Liability
export const balanceSheetLiabilityTableHeaderData: any = [
  {
    Header: t('Reports.A/C_NO'),
    Cell: ({ row }: any) => {
      return (
        <p
          className={
            isBoldRowiWithTotal.includes(row?.original?.level)
              ? styles.boldTd
              : styles.normalStyleTd
          }
        >
          {row?.original?.account_no ? row?.original?.account_no : '-'}
        </p>
      )
    },
  },
  {
    Header: t('MobileAppConfiguration.DESCRIPTION'),
    Cell: ({ row }: any) => {
      return (
        <p
          className={
            isBoldRowiWithTotal.includes(row?.original?.level)
              ? styles.boldTd
              : styles.normalStyleTd
          }
        >
          {row?.original?.account_name ? row?.original?.account_name : '-'}
        </p>
      )
    },
  },
  {
    Header: t('LabJob.AMOUNT'),
    Cell: ({ row }: any) => {
      return (
        <p
          className={
            isBoldRowiWithTotal.includes(row?.original?.level)
              ? styles.boldTd
              : styles.normalStyleTd
          }
        >
          {row?.original?.close?.credit
            ? row?.original?.close?.credit
            : isBoldRow.includes(row?.original?.level)
            ? ''
            : 0}{' '}
        </p>
      )
    },
  },
]

// Assets
export const balanceSheetAssetsTableHeaderData: any = [
  {
    Header: t('Reports.A/C_NO'),
    Cell: ({ row }: any) => {
      return (
        <p
          className={
            isBoldRowiWithTotal.includes(row?.original?.level)
              ? styles.boldTd
              : styles.normalStyleTd
          }
        >
          {row?.original?.account_no ? row?.original?.account_no : '-'}
        </p>
      )
    },
  },
  {
    Header: t('MobileAppConfiguration.DESCRIPTION'),
    Cell: ({ row }: any) => {
      return (
        <p
          className={
            isBoldRowiWithTotal.includes(row?.original?.level)
              ? styles.boldTd
              : styles.normalStyleTd
          }
        >
          {row?.original?.account_name ? row?.original?.account_name : '-'}
        </p>
      )
    },
  },
  {
    Header: t('LabJob.AMOUNT'),
    Cell: ({ row }: any) => {
      return (
        <p
          className={
            isBoldRowiWithTotal.includes(row?.original?.level)
              ? styles.boldTd
              : styles.normalStyleTd
          }
        >
          {row?.original?.close?.debit
            ? row?.original?.close?.debit
            : isBoldRow.includes(row?.original?.level)
            ? ''
            : 0}{' '}
        </p>
      )
    },
  },
]
//View POPUP Liability TABLE
export const liabilityViewBalanceTableHeaderData: any = [
  {
    Header: t('Voucher.VOUCHER_NO'),
    accessor: (row: any) => {
      return row?.number ?? '-'
    },
  },
  {
    Header: t('Reports.DEBIT_BALANCE'),
    accessor: (row: any) => {
      return row?.debit_amount > 0 ? row?.debit_amount : '-'
    },
  },
  {
    Header: t('Reports.CREDIT_BALANCE'),
    accessor: (row: any) => {
      return row?.credit_amount > 0 ? row?.credit_amount : '-'
    },
  },
]
// View POPUP Assets TABLE
export const assetsViewBalanceTableHeaderData: any = [
  {
    Header: t('Voucher.VOUCHER_NO'),
    accessor: (row: any) => {
      return row?.number ?? '-'
    },
  },
  {
    Header: t('Reports.DEBIT_BALANCE'),
    accessor: (row: any) => {
      return row?.debit_amount ?? '-'
    },
  },
  {
    Header: t('Reports.CREDIT_BALANCE'),
    accessor: (row: any) => {
      return row?.credit_amount ?? '-'
    },
  },
]
