import { SearchIcon } from '../svg-components'
import { colors } from '../../../constants/color'
import styles from './style.module.scss'

interface IGlobalSearch {
  value: string
  onChange: any
  handleSearch: () => void
  placeholder?: string
  customClass?: string
  children?: React.ReactNode
  onKeyDown?: any
}

const GlobalSearch: React.FC<IGlobalSearch> = (props) => {
  const {
    value,
    onChange,
    handleSearch,
    placeholder,
    customClass,
    children,
    onKeyDown,
  } = props
  return (
    <div className={[styles.searchContainer, customClass]?.join(' ')}>
      <div className={styles.search}>
        <input
          className={styles.searchInput}
          type="text"
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          onKeyDown={onKeyDown}
        />
        <div className={styles.searchButton} onClick={handleSearch}>
          <SearchIcon fillColor={colors.white1} />
        </div>
      </div>
      {children}
    </div>
  )
}

export default GlobalSearch
