import { createSlice } from '@reduxjs/toolkit'
import { IAccountsState } from '../../../interfaces/apiInterface'
import {
  accountsLevel2,
  addAsset,
  addAttribute,
  getAllLevelData,
  getGenerateCode,
  updateAsset,
} from './accountsAsynActions'

export const initialState: IAccountsState = {
  loading: false,
  level2: {},
  addAsset: {},
  getAllData: {},
  addAttributeData: [],
  getGenerateData: null,
  isLoggedin: false,
  dropdownAttempt: '',
  backLevelId: '',
  selectedLevel: {},
  selectedOptionDropdown: {},
}

export const accountsSlice = createSlice({
  name: 'accountsSlice',
  initialState,
  reducers: {
    setDropdownAttempts: (state, action) => {
      state.dropdownAttempt = action.payload.payloadData.requestData
    },
    setBackId: (state, action) => {
      let id = action?.payload
      state.backLevelId = id ?? ''
    },
    selectedIdCode: (state, action) => {
      state.selectedLevel = action.payload
    },
    setGenerateCode: (state, action) => {
      state.getGenerateData = action.payload
    },
    setSelectedOptionDropdown: (state, action) => {
      state.selectedOptionDropdown = action.payload
    },
  },
  extraReducers: (builder) => {
    // dropdown data
    builder
      .addCase(accountsLevel2.pending, (state) => {
        // state.loading = true;
        state.level2 = {}
        state.isLoggedin = false
      })
      .addCase(accountsLevel2.fulfilled, (state, action) => {
        state.loading = false
        state.level2 = action.payload
        state.isLoggedin = true
      })
      .addCase(accountsLevel2.rejected, (state, action) => {
        state.loading = false
        state.level2 = {}
        state.isLoggedin = false
      })

    // Add Asset Data
    builder
      .addCase(addAsset.pending, (state) => {
        state.loading = true
        state.addAsset = {}
        state.isLoggedin = false
      })
      .addCase(addAsset.fulfilled, (state, action) => {
        state.loading = false
        state.addAsset = action.payload
        state.isLoggedin = true
      })
      .addCase(addAsset.rejected, (state, action) => {
        state.loading = false
        state.addAsset = {}
        state.isLoggedin = false
      })

    // Update Asset Data
    builder
      .addCase(updateAsset.pending, (state) => {
        state.loading = true
        state.addAsset = {}
        state.isLoggedin = false
      })
      .addCase(updateAsset.fulfilled, (state, action) => {
        state.loading = false
        state.addAsset = action.payload
        state.isLoggedin = true
      })
      .addCase(updateAsset.rejected, (state, action) => {
        state.loading = false
        state.addAsset = {}
        state.isLoggedin = false
      })
    // get all level data
    builder
      .addCase(getAllLevelData.pending, (state) => {
        state.loading = true
        state.getAllData = {}
        state.isLoggedin = false
      })
      .addCase(getAllLevelData.fulfilled, (state, action) => {
        state.loading = false
        state.getAllData = action?.payload?.data
        state.isLoggedin = true
      })
      .addCase(getAllLevelData.rejected, (state, action) => {
        state.loading = false
        state.getAllData = {}
        state.isLoggedin = false
      })
    // post dropdown id and name
    builder
      .addCase(addAttribute.pending, (state) => {
        state.loading = true
        state.addAttributeData = {}
        state.isLoggedin = false
      })
      .addCase(addAttribute.fulfilled, (state, action) => {
        state.loading = false
        state.addAttributeData = action?.payload
        state.isLoggedin = true
      })
      .addCase(addAttribute.rejected, (state, action) => {
        state.loading = false
        state.addAttributeData = {}
        state.isLoggedin = false
      })
    // get generate code
    builder
      .addCase(getGenerateCode.pending, (state) => {
        state.loading = true
        state.getGenerateData = {}
        state.isLoggedin = false
      })
      .addCase(getGenerateCode.fulfilled, (state, action) => {
        state.loading = false
        state.getGenerateData = action?.payload
        state.isLoggedin = true
      })
      .addCase(getGenerateCode.rejected, (state, action) => {
        state.loading = false
        state.getGenerateData = {}
        state.isLoggedin = false
      })
  },
})

export const {
  setDropdownAttempts,
  setBackId,
  selectedIdCode,
  setGenerateCode,
  setSelectedOptionDropdown,
} = accountsSlice.actions

export default accountsSlice.reducer
