// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pharmacyMedication_searchContainer__LfQJy {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.pharmacyMedication_searchContainer__LfQJy .pharmacyMedication_inputDate__GZetS {
  background: var(--white1);
  border: 1px solid var(--grey6);
  border-radius: 6px;
  padding-left: 10px;
  height: 42px;
  width: 200px;
  margin: 40px 20px 40px 0;
}

.pharmacyMedication_tableContainer__QiP-C {
  overflow: auto;
}
.pharmacyMedication_tableContainer__QiP-C::-webkit-scrollbar {
  display: block;
  width: 7px;
  height: 7px;
}
.pharmacyMedication_tableContainer__QiP-C::-webkit-scrollbar-track {
  background: transparent;
}
.pharmacyMedication_tableContainer__QiP-C::-webkit-scrollbar-thumb {
  background-color: var(--grey7);
  border-radius: 10px;
  border: 1px solid var(--grey7);
}`, "",{"version":3,"sources":["webpack://./src/components/common/modal/pharmacy-medication/pharmacyMedication.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,mBAAA;EACA,mBAAA;AACF;AAAE;EACE,yBAAA;EACA,8BAAA;EACA,kBAAA;EACA,kBAAA;EACA,YAAA;EACA,YAAA;EACA,wBAAA;AAEJ;;AACA;EACE,cAAA;AAEF;AADE;EACE,cAAA;EACA,UAAA;EACA,WAAA;AAGJ;AADE;EACE,uBAAA;AAGJ;AAAE;EACE,8BAAA;EACA,mBAAA;EACA,8BAAA;AAEJ","sourcesContent":[".searchContainer {\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  .inputDate {\n    background: var(--white1);\n    border: 1px solid var(--grey6);\n    border-radius: 6px;\n    padding-left: 10px;\n    height: 42px;\n    width: 200px;\n    margin: 40px 20px 40px 0;\n  }\n}\n.tableContainer {\n  overflow: auto;\n  &::-webkit-scrollbar {\n    display: block;\n    width: 7px;\n    height: 7px;\n  }\n  &::-webkit-scrollbar-track {\n    background: transparent;\n  }\n\n  &::-webkit-scrollbar-thumb {\n    background-color: var(--grey7);\n    border-radius: 10px;\n    border: 1px solid var(--grey7);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"searchContainer": `pharmacyMedication_searchContainer__LfQJy`,
	"inputDate": `pharmacyMedication_inputDate__GZetS`,
	"tableContainer": `pharmacyMedication_tableContainer__QiP-C`
};
export default ___CSS_LOADER_EXPORT___;
