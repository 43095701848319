// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ManageMasterNew_mainContainer__m9izM {
  background-color: var(--white1);
  box-shadow: 0px 10px 117px 14px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  padding: 25px;
  margin: 0 20px 50px 0;
}
.ManageMasterNew_mainContainer__m9izM .ManageMasterNew_searchContainer__zVxuw {
  display: flex;
  flex-direction: row;
  margin-bottom: 25px;
  justify-content: space-between;
}
.ManageMasterNew_mainContainer__m9izM .ManageMasterNew_searchContainer__zVxuw .ManageMasterNew_inputFieldContainer__f1ltb {
  position: relative;
  display: flex;
  gap: 10px;
  align-items: center;
}
.ManageMasterNew_mainContainer__m9izM .ManageMasterNew_searchContainer__zVxuw .ManageMasterNew_inputFieldContainer__f1ltb .ManageMasterNew_inputSearchContainer__4UdkD {
  width: 200px;
  height: 45px;
  border: none;
  padding: 15px;
  border-radius: 10px;
  background: rgba(255, 255, 255, 0.7);
  box-shadow: 0px 10px 117px 14px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
}
.ManageMasterNew_mainContainer__m9izM .ManageMasterNew_tableContainer__MMhtk {
  height: 100%;
  width: 100%;
  white-space: nowrap;
  overflow-x: auto;
}
.ManageMasterNew_mainContainer__m9izM .ManageMasterNew_tableContainer__MMhtk::-webkit-scrollbar {
  display: block !important;
  width: 7px !important;
  height: 7px !important;
}
.ManageMasterNew_mainContainer__m9izM .ManageMasterNew_tableContainer__MMhtk::-webkit-scrollbar-track {
  background: transparent !important;
}
.ManageMasterNew_mainContainer__m9izM .ManageMasterNew_tableContainer__MMhtk::-webkit-scrollbar-thumb {
  background-color: var(--grey7) !important;
  border-radius: 10px !important;
  border: 1px solid var(--grey7) !important;
}`, "",{"version":3,"sources":["webpack://./src/pages/manageMasterTable-New/manageMasterNew/ManageMasterNew.module.scss"],"names":[],"mappings":"AACA;EACE,+BAAA;EACA,mDAAA;EACA,mBAAA;EACA,aAAA;EACA,qBAAA;AAAF;AACE;EACE,aAAA;EACA,mBAAA;EACA,mBAAA;EACA,8BAAA;AACJ;AAAI;EACE,kBAAA;EACA,aAAA;EACA,SAAA;EACA,mBAAA;AAEN;AADM;EACE,YAAA;EACA,YAAA;EACA,YAAA;EACA,aAAA;EACA,mBAAA;EACA,oCAAA;EACA,mDAAA;EACA,mBAAA;AAGR;AACE;EACE,YAAA;EACA,WAAA;EACA,mBAAA;EACA,gBAAA;AACJ;AAAI;EACE,yBAAA;EACA,qBAAA;EACA,sBAAA;AAEN;AAAI;EACE,kCAAA;AAEN;AACI;EACE,yCAAA;EACA,8BAAA;EACA,yCAAA;AACN","sourcesContent":["@import '../../../styles/mixin.scss';\n.mainContainer {\n  background-color: var(--white1);\n  box-shadow: 0px 10px 117px 14px rgba(0, 0, 0, 0.05);\n  border-radius: 10px;\n  padding: 25px;\n  margin: 0 20px 50px 0;\n  .searchContainer {\n    display: flex;\n    flex-direction: row;\n    margin-bottom: 25px;\n    justify-content: space-between;\n    .inputFieldContainer {\n      position: relative;\n      display: flex;\n      gap: 10px;\n      align-items: center;\n      .inputSearchContainer {\n        width: 200px;\n        height: 45px;\n        border: none;\n        padding: 15px;\n        border-radius: 10px;\n        background: rgba(255, 255, 255, 0.7);\n        box-shadow: 0px 10px 117px 14px rgba(0, 0, 0, 0.05);\n        border-radius: 10px;\n      }\n    }\n  }\n  .tableContainer {\n    height: 100%;\n    width: 100%;\n    white-space: nowrap;\n    overflow-x: auto;\n    &::-webkit-scrollbar {\n      display: block !important;\n      width: 7px !important;\n      height: 7px !important;\n    }\n    &::-webkit-scrollbar-track {\n      background: transparent !important;\n    }\n\n    &::-webkit-scrollbar-thumb {\n      background-color: var(--grey7) !important;\n      border-radius: 10px !important;\n      border: 1px solid var(--grey7) !important;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"mainContainer": `ManageMasterNew_mainContainer__m9izM`,
	"searchContainer": `ManageMasterNew_searchContainer__zVxuw`,
	"inputFieldContainer": `ManageMasterNew_inputFieldContainer__f1ltb`,
	"inputSearchContainer": `ManageMasterNew_inputSearchContainer__4UdkD`,
	"tableContainer": `ManageMasterNew_tableContainer__MMhtk`
};
export default ___CSS_LOADER_EXPORT___;
