import styles from './style.module.scss'
import barcdeImage from '../../../../assets/images/barcode-generator.png'
import poImage from '../../../../assets/images/purchase-order.png'
import customerImage from '../../../../assets/images/customers.png'
import usersImage from '../../../../assets/images/users.png'
import addProducts from '../../../../assets/images/addproducts.png'
import adjustmentForm from '../../../../assets/images/adjustmentform.png'
import { useState } from 'react'
import { CustomModal } from '../../../../components/common/custom-modal/modal'
import AdjustmentForm from '../adjustment-form/page'
import { t } from 'i18next'

const QuickAccess = () => {
  const [adjustmentFormModal, setAdjustmentFormModal] = useState(false)
  return (
    <>
      <CustomModal
        showModal={adjustmentFormModal}
        closeModal={() => setAdjustmentFormModal(false)}
        title={t('Pharmacy.Adjustment_Form') || 'Adjustment Form'}
        width="60dvw"
        height="80dvh"
      >
        <AdjustmentForm closeModal={() => setAdjustmentFormModal(false)} />
      </CustomModal>
      <div className={styles.quickAcessContainer}>
        <h2 className={styles.heading}>{t('Pharmacy.Quick_Access')}</h2>
        <ul className={styles.inlineItemsContainer}>
          <li>
            <img src={barcdeImage} alt="" />
          </li>
          <li>
            <img src={poImage} alt="" />
          </li>
          <li>
            <img src={customerImage} alt="" />
          </li>
          <li>
            <img src={usersImage} alt="" />
          </li>
          <li>
            <img src={addProducts} alt="" />
          </li>
          <li onClick={() => setAdjustmentFormModal(true)}>
            <img src={adjustmentForm} alt="" />
          </li>
        </ul>
      </div>
    </>
  )
}

export default QuickAccess
