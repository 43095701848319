// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.loader_spinnerContainer__5FHqZ {
  justify-content: center;
  align-items: center;
  position: fixed;
  display: flex;
  min-height: 100%;
  width: 100%;
  z-index: 2;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.4);
  overflow-y: auto;
  margin: auto;
}
.loader_spinnerContainer__5FHqZ::-webkit-scrollbar {
  display: block !important;
  width: 7px !important;
  height: 7px !important;
}
.loader_spinnerContainer__5FHqZ::-webkit-scrollbar-track {
  background: transparent !important;
}
.loader_spinnerContainer__5FHqZ::-webkit-scrollbar-thumb {
  background-color: var(--grey7) !important;
  border-radius: 10px !important;
  border: 1px solid var(--grey7) !important;
}
.loader_spinnerContainer__5FHqZ .loader_loadingSpinner__pGfH4 {
  width: 55px;
  height: 55px;
  border: 10px solid var(--white1);
  border-top: 10px solid var(--grey4);
  border-radius: 50%;
  animation: loader_spinner__iZot7 0.9s linear infinite;
}
@keyframes loader_spinner__iZot7 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}`, "",{"version":3,"sources":["webpack://./src/components/common/spinner/loader.module.scss"],"names":[],"mappings":"AAAA;EACE,uBAAA;EACA,mBAAA;EACA,eAAA;EACA,aAAA;EACA,gBAAA;EACA,WAAA;EACA,UAAA;EACA,MAAA;EACA,OAAA;EACA,8BAAA;EACA,gBAAA;EAeA,YAAA;AAbF;AADE;EACE,yBAAA;EACA,qBAAA;EACA,sBAAA;AAGJ;AADE;EACE,kCAAA;AAGJ;AAAE;EACE,yCAAA;EACA,8BAAA;EACA,yCAAA;AAEJ;AACE;EACE,WAAA;EACA,YAAA;EACA,gCAAA;EACA,mCAAA;EACA,kBAAA;EACA,qDAAA;AACJ;AAAI;EACE;IACE,uBAAA;EAEN;EAAI;IACE,yBAAA;EAEN;AACF","sourcesContent":[".spinnerContainer {\n  justify-content: center;\n  align-items: center;\n  position: fixed;\n  display: flex;\n  min-height: 100%;\n  width: 100%;\n  z-index: 2;\n  top: 0;\n  left: 0;\n  background: rgba(0, 0, 0, 0.4);\n  overflow-y: auto;\n  &::-webkit-scrollbar {\n    display: block !important;\n    width: 7px !important;\n    height: 7px !important;\n  }\n  &::-webkit-scrollbar-track {\n    background: transparent !important;\n  }\n\n  &::-webkit-scrollbar-thumb {\n    background-color: var(--grey7) !important;\n    border-radius: 10px !important;\n    border: 1px solid var(--grey7) !important;\n  }\n  margin: auto;\n  .loadingSpinner {\n    width: 55px;\n    height: 55px;\n    border: 10px solid var(--white1);\n    border-top: 10px solid var(--grey4);\n    border-radius: 50%;\n    animation: spinner 0.9s linear infinite;\n    @keyframes spinner {\n      0% {\n        transform: rotate(0deg);\n      }\n      100% {\n        transform: rotate(360deg);\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"spinnerContainer": `loader_spinnerContainer__5FHqZ`,
	"loadingSpinner": `loader_loadingSpinner__pGfH4`,
	"spinner": `loader_spinner__iZot7`
};
export default ___CSS_LOADER_EXPORT___;
