import { t } from 'i18next'

export const SearchModalHeaderData: any = [
  {
    Header: t('RcptTableHeader.FILE NO'),
    accessor: 'emr_no',
    Cell: (props: any) => {
      return (
        <>
          <span
            style={{ color: '#0e26a3', cursor: 'pointer' }}
            onClick={() => props.onClick(props?.row?.original)}
          >
            {props?.row?.original?.emr_no}
          </span>
        </>
      )
    },
  },
  {
    Header: t('PatientHistoryFormHeader.PATIENT NAME'),
    accessor: 'patient_name',
  },

  {
    Header: t('PatientHistoryFormHeader.NATIONAL ID'),
    accessor: 'national_id',
  },
  {
    Header: t('RcptTableHeader.MOBILE NO'),
    accessor: 'phone',
  },
]
