import { createSlice } from '@reduxjs/toolkit'
import { getAllCounts, getAllDashboard } from './hrDashboardAsyncActions'

export interface IDashboard {
  isLoading: boolean
  allDashboardData: []
  allCountData: {}
  dashboardDetail: {}
  error: any
  isStatusUpdated: boolean
  getAllDashboardPaylod: {}
  branchData: []
}
export const initialState: IDashboard = {
  isLoading: false,
  allCountData: {},
  allDashboardData: [],
  dashboardDetail: {},
  isStatusUpdated: true,
  error: null,
  getAllDashboardPaylod: {},
  branchData: [],
}
export const dashboardSlice = createSlice({
  name: 'hrdashboard',
  initialState,
  reducers: {
    clearDashboardData: (state) => {
      state.allDashboardData = []
      state.dashboardDetail = {}
      state.getAllDashboardPaylod = {}
      state.allCountData = {}
      state.branchData = []
    },
    getAllDashboardPayloadData: (state, action) => {
      state.getAllDashboardPaylod = action.payload
    },
    clearBranchData: (state) => {
      state.branchData = []
    },
  },
  extraReducers(builder) {
    // GET ALL DASHBOARD
    builder
      .addCase(getAllDashboard.pending, (state) => {
        state.isLoading = true
        state.isStatusUpdated = false
      })
      .addCase(getAllDashboard.fulfilled, (state, action) => {
        state.isLoading = false
        state.isStatusUpdated = false
        state.allDashboardData = action.payload
      })
      .addCase(getAllDashboard.rejected, (state, error) => {
        state.isLoading = false
        state.error = error
        state.isStatusUpdated = false
      })

    //COUNTS
    builder
      .addCase(getAllCounts.pending, (state) => {
        // state.isLoading = false;
        state.isStatusUpdated = false
      })
      .addCase(getAllCounts.fulfilled, (state, action) => {
        // state.isLoading = false;
        state.isStatusUpdated = false
        state.allCountData = action.payload
      })
      .addCase(getAllCounts.rejected, (state, error) => {
        // state.isLoading = false;
        state.error = error
        state.isStatusUpdated = false
      })
  },
})
export const { clearDashboardData, getAllDashboardPayloadData } =
  dashboardSlice.actions
export default dashboardSlice.reducer
