import { FC } from 'react'
import { colors } from '../../../constants/color'
import Divider from '../../../components/common/divider/Divider'
import { CloseIcon } from '../../../components/common/svg-components/index'
import Button from '../../../components/common/button/Button'
import { IcostViewModalValidators } from '../voucherValidator'
import { COST_VOUCHER_NO } from '../voucherConstant'
import { SubmitHandler, useForm } from 'react-hook-form'
import { trimValue } from '../../../utils/utils'
import styles from './costViewModal.module.scss'
import { costCenterTableHeader } from '../vouchers/vouchersTableData'
import TableV2 from '../../../components/common/table/tableV2/TableV2'
import { t } from 'i18next'

interface IPropsData {
  handleClose: (e?: React.MouseEvent<HTMLElement>) => void
  isEditing?: boolean
  setIsEditing?: React.Dispatch<React.SetStateAction<boolean>>
  popData?: any
}
const CostViewModal: FC<IPropsData> = ({
  handleClose,
  setIsEditing,
  popData,
}) => {
  const {
    handleSubmit,
    formState: { errors },
  } = useForm<IcostViewModalValidators>({})
  const onSubmit: SubmitHandler<IcostViewModalValidators> = (data) => {
    handleClose()
    setIsEditing && setIsEditing(false)
  }
  return (
    <div
      className={styles.addVoucherModalContainer}
      onClick={(e) => e.stopPropagation()}
    >
      <CloseIcon
        customClass={styles.closeIconStyle}
        fillColor={colors.green1}
        handleClick={() => {
          handleClose()
          setIsEditing && setIsEditing(false)
        }}
      />
      <div className={styles.addVoucherContainer}>
        <p className={styles.title}>{t('Voucher.Cost_Center')}</p>
        <Divider customClass={styles.dividerStyle} />
        <form
          onSubmit={handleSubmit(onSubmit)}
          className={styles.formContainer}
        >
          <div className={styles.formFieldContainer}>
            <div className={styles.inputFieldContainer}>
              <label className={styles.formLabel}>
                {t('ManageCategory.Account_No')}
              </label>
              <input
                type="text"
                placeholder={t('ManageCategory.Account_No') || 'Account No'}
                className={styles.inputField}
                value={popData?.accounts_data?.code_id}
                disabled
                onChange={(e) => trimValue(e)}
              />
            </div>
            {errors[COST_VOUCHER_NO] && (
              <div className={styles.errorContainer}>
                <span className={styles.extraSpan}></span>
                <p className="dashboardFormError">
                  {errors[COST_VOUCHER_NO].message}
                </p>
              </div>
            )}
          </div>
          <div className={styles.formFieldContainer}>
            <div className={styles.inputFieldContainer}>
              <label className={styles.formLabel}>
                {t('Voucher.Account_Name')}
              </label>
              <input
                type="text"
                disabled
                placeholder={t('Voucher.Account_Name') || 'Account Name'}
                value={popData?.accounts_data?.name}
                className={styles.inputField}
                onChange={(e) => trimValue(e)}
              />
            </div>
          </div>

          <div className={styles.formFieldContainer}>
            <TableV2
              tableHeaderData={costCenterTableHeader}
              tableRowData={popData?.cost_center ?? []}
              active={false}
            />
          </div>
          <div className={styles.costViewModalBtnContainer}>
            <Button
              title={t('Voucher.Close') || 'Close'}
              type="submit"
              customClass={styles.closeBtn}
            />
          </div>
        </form>
      </div>
    </div>
  )
}

export default CostViewModal
