import { FC } from 'react'
import { colors } from '../../../constants/color'
import Divider from '../../../components/common/divider/Divider'
import { CloseIcon } from '../../../components/common/svg-components/index'
import Button from '../../../components/common/button/Button'
import {
  IAddVoucherModalValidators,
  addVoucherModalValidators,
} from '../voucherValidator'
import { ADD_VOUCHER_CODE, ADD_VOUCHER_NAME } from '../voucherConstant'
import { SubmitHandler, useForm } from 'react-hook-form'
import { trimValue } from '../../../utils/utils'
import {
  addVoucher,
  getVoucher,
} from '../../../redux/features/vouchers/vouchersAsynActions'
import { requestGenerator } from '../../../utils/payloadGenerator'
import { useAppDispatch } from '../../../hooks'
import styles from './addVoucherModal.module.scss'
import { t } from 'i18next'

interface IPropsData {
  handleClose: (e?: React.MouseEvent<HTMLElement>) => void
  setIsEditing?: React.Dispatch<React.SetStateAction<boolean>>
}
const AddVoucherModal: FC<IPropsData> = ({ handleClose, setIsEditing }) => {
  const {
    register,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm<IAddVoucherModalValidators>({})
  const dispatch = useAppDispatch()
  const onSubmit: SubmitHandler<IAddVoucherModalValidators> = (data) => {
    const init = {
      name: data.name,
      initials: data.initials,
    }
    dispatch(addVoucher(requestGenerator(init))).then((e) => {
      if (e.type === 'add-voucher/fulfilled') {
        dispatch(getVoucher(requestGenerator({})))
      }
    })
    handleClose()
    setIsEditing && setIsEditing(false)
  }
  const handleReset = () => {
    reset()
  }
  return (
    <>
      <div
        className={styles.addVoucherModalContainer}
        onClick={(e) => e.stopPropagation()}
      >
        <CloseIcon
          customClass={styles.closeIconStyle}
          fillColor={colors.green1}
          handleClick={() => {
            handleClose()
            setIsEditing && setIsEditing(false)
          }}
        />
        <div className={styles.addVoucherContainer}>
          <p className={styles.title}>{t('Voucher.Voucher')}</p>
          <Divider customClass={styles.dividerStyle} />
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className={styles.formFieldContainer}>
              <div className={styles.inputFieldContainer}>
                <label className={styles.formLabel}>
                  {t('PatientEMR.Name')}
                  <span className="asterick">*</span>
                </label>
                <input
                  type="text"
                  placeholder={
                    t('SupplierMaster.name_placeholder') || 'Enter Name'
                  }
                  className={styles.inputField}
                  {...register(
                    ADD_VOUCHER_NAME,
                    addVoucherModalValidators[ADD_VOUCHER_NAME]
                  )}
                  onChange={(e) => trimValue(e)}
                />
              </div>
              {errors[ADD_VOUCHER_NAME] && (
                <div className={styles.errorContainer}>
                  <span className={styles.extraSpan}></span>
                  <p className="dashboardFormError">
                    {errors[ADD_VOUCHER_NAME].message}
                  </p>
                </div>
              )}
            </div>
            <div className={styles.formFieldContainer}>
              <div className={styles.inputFieldContainer}>
                <label className={styles.formLabel}>
                  {t('Voucher.Initials')}
                  <span className="asterick">*</span>
                </label>
                <input
                  type="text"
                  placeholder={
                    t('Voucher.Initials_Placeholder') || 'Enter Code Initials'
                  }
                  className={styles.inputField}
                  {...register(
                    ADD_VOUCHER_CODE,
                    addVoucherModalValidators[ADD_VOUCHER_CODE]
                  )}
                  onChange={(e) => trimValue(e)}
                />
              </div>
              {errors[ADD_VOUCHER_CODE] && (
                <div className={styles.errorContainer}>
                  <span className={styles.extraSpan}></span>
                  <p className="dashboardFormError">
                    {errors[ADD_VOUCHER_CODE].message}
                  </p>
                </div>
              )}
            </div>
            <div className={styles.addVoucherBtnContainer}>
              <Button
                title={t('Common.Submit') || 'Submit'}
                type="submit"
                customClass={styles.submitBtn}
              />
              <Button
                title={t('BranchSetup.Reset') || 'Reset'}
                type="reset"
                customClass={styles.resetBtn}
                handleClick={() => handleReset()}
              />
            </div>
          </form>
        </div>
      </div>
    </>
  )
}

export default AddVoucherModal
