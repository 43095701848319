import { t } from 'i18next'
import {
  PATIENT_BRANCH_NAME,
  PATIENT_DOB,
  // PATIENT_EMAIL,
  PATIENT_FILE_NO,
  PATIENT_GENDER,
  PATIENT_MOBILE_NO_1,
  PATIENT_NAME,
  PATIENT_NATIONALITY,
  PATIENT_NATIONAL_ID,
  PATIENT_NATIONAL_ID_TYPE,
  PATIENT_PROFILE_PIC,
} from '../constants/constant'

export interface IaddPatientValidators {
  // [PATIENT_EMAIL]: {
  //   required: string;
  //   pattern: {
  //     value: RegExp;
  //     message: string;
  //   };
  // };
  [PATIENT_FILE_NO]: {
    required: string
  }
  [PATIENT_NAME]: {
    required: string
  }
  [PATIENT_NATIONALITY]: {
    required: string
  }
  [PATIENT_DOB]: {
    required: string
    validate: any
  }
  [PATIENT_GENDER]: {
    required: string
  }
  [PATIENT_MOBILE_NO_1]: {
    required: string
  }
  // [PATIENT_OPENED_BY]: {
  //   required: string;
  // };
  // [PATIENT_OPENED_ON]: {
  //   required: string;
  // };
  [PATIENT_BRANCH_NAME]: {
    required: string
  }
  // [PATIENT_AGE]: {
  //   required: string;
  // };
  [PATIENT_NATIONAL_ID_TYPE]: {
    required: string
  }
  [PATIENT_NATIONAL_ID]: {
    required: string
  }
  [PATIENT_PROFILE_PIC]: {
    required: string
  }
}
export const addPatientValidators: IaddPatientValidators = {
  // [PATIENT_EMAIL]: {
  //   required: "Please enter email",
  //   pattern: {
  //     value: /^([a-zA-Z0-9_.-])+@(([a-zA-Z0-9-])+.)+([a-zA-Z0-9]{2,4})+$/,
  //     message: "Please enter valid email",
  //   },
  // },
  [PATIENT_PROFILE_PIC]: {
    required: t('PatientEmrValidators.Profileimage'),
  },
  [PATIENT_FILE_NO]: {
    required: t('PatientEmrValidators.FileNo'),
  },
  [PATIENT_NAME]: {
    required: t('PatientEmrValidators.PatientName'),
  },
  [PATIENT_NATIONALITY]: {
    required: t('PatientEmrValidators.Nationality'),
  },
  [PATIENT_DOB]: {
    required: t('PatientEmrValidators.DOB'),
    validate: {
      futureDate: (value: any) => {
        const currentDate = new Date()
        const selectedDate = new Date(value)
        if (selectedDate > currentDate) {
          return t('PatientEmrValidators.DOBFuture')
        }
        return true
      },
    },
  },
  [PATIENT_GENDER]: {
    required: t('PatientEmrValidators.Gender'),
  },
  [PATIENT_MOBILE_NO_1]: {
    required: t('PatientEmrValidators.MOB1'),
  },
  // [PATIENT_OPENED_BY]: {
  //   required: "Please enter opened by",
  // },
  // [PATIENT_OPENED_ON]: {
  //   required: "Please select opened on",
  // },
  // [PATIENT_AGE]: {
  //   required: "Please enter age",
  // },
  [PATIENT_BRANCH_NAME]: {
    required: t('PatientEmrValidators.Branch'),
  },
  [PATIENT_NATIONAL_ID_TYPE]: {
    required: t('PatientEmrValidators.NationalID'),
  },
  [PATIENT_NATIONAL_ID]: {
    required: t('PatientEmrValidators.ID'),
  },
}
