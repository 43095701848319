import {
  GET_ALL_SEARCH_USER,
  ADD_PAYROLL,
  GET_PAYROLL_BY_ID,
  EDIT_PAYROLL_BY_ID,
  DELETE_PAYROLL,
  UPDATE_PAYROLL_STATUS,
  GET_ALL_PAYROLL,
  GET_SALARY,
  CREATE_PAYROLL,
} from '../../../constants/asyncActionsType'
import createAsyncThunkForSlice from '../../../utils/utils'
import {
  addPayroll,
  createPayrol,
  deletePayroll,
  editPayrollByIds,
  getAllPayrollList,
  getAllSearchUsers,
  getPayrollByIds,
  getSalaryList,
  updatePayrollStatuss,
} from './payrollCrud'
export const getAllPayroll = createAsyncThunkForSlice(
  GET_ALL_PAYROLL,
  getAllPayrollList,
  {
    isToast: false,
  }
)
export const getSalary = createAsyncThunkForSlice(GET_SALARY, getSalaryList, {
  isToast: false,
})

export const addPayrolls = createAsyncThunkForSlice(ADD_PAYROLL, addPayroll, {
  isToast: true,
})
export const getPayrollById = createAsyncThunkForSlice(
  GET_PAYROLL_BY_ID,
  getPayrollByIds,
  {
    isToast: true,
  }
)

export const editPayrollById = createAsyncThunkForSlice(
  EDIT_PAYROLL_BY_ID,
  editPayrollByIds,
  {
    isToast: true,
  }
)

export const updatePayrollStatus = createAsyncThunkForSlice(
  UPDATE_PAYROLL_STATUS,
  updatePayrollStatuss,
  {
    isToast: true,
  }
)

export const deletePayrolls = createAsyncThunkForSlice(
  DELETE_PAYROLL,
  deletePayroll,
  {
    isToast: true,
  }
)

export const getAllSearchUserList = createAsyncThunkForSlice(
  GET_ALL_SEARCH_USER,
  getAllSearchUsers
)

export const createPayroll = createAsyncThunkForSlice(
  CREATE_PAYROLL,
  createPayrol,
  {
    isToast: true,
  }
)
