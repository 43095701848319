import {
  PLAN_AMOUNT,
  PLAN_NAME,
  PLAN_TOTAL_AMOUNT,
} from '../constants/constant'
import { ICreateNewPlan } from '../interfaces/interfaces'
import { t } from 'i18next'

export const newPlanValidators = {
  [PLAN_AMOUNT]: {
    required: t('ServiceMaster.Plan_Amount_Validation'),
    // pattern: {
    //   value: /^[0-9]{0,6}(?:\.[0-9]{1,3})?$/,
    //   message: 'Please enter number upto 6 digit',
    // },
    validate: (planAmount: string, values: ICreateNewPlan) => {
      const totalAmount = values[PLAN_TOTAL_AMOUNT]
      if (Number(planAmount) > Number(totalAmount)) {
        return t('ServiceMaster.Plan_Amount_Value_Validation')
      }
      return undefined
    },
  },
  [PLAN_NAME]: {
    required: t('ServiceMaster.Plan_Name_Validation'),
  },
}
