import axios from 'axios'

import {
  ADD_VOUCHER,
  ADD_VOUCHER_DATA,
  DELETE_VOUCHER_DATA,
  GET_ACCOUNT_NO,
  GET_REFERENCE_DOC,
  GET_VOUCHER,
  GET_VOUCHER_DATA,
  GET_VOUCHER_DATA_BY_ID,
  SUBMIT_VOUCHER_DATA,
  UPDATE_VOUCHER_DATA,
  GET_VOUCHER_NO,
  GET_VOUCHER_LOG_DATA,
  GET_ALL_BRANCH,
  GET_ALL_DEPARTMENT,
} from '../../../config/config'
import { IAPIPayload } from '../../../interfaces/apiInterface'

// add voucher
export const addVoucherCrud = (data: IAPIPayload) => {
  return axios.post(ADD_VOUCHER, data)
}
export const addVoucherData = (data: IAPIPayload) => {
  return axios.post(ADD_VOUCHER_DATA, data)
}

export const deleteVoucherData = (data: IAPIPayload) => {
  return axios.post(DELETE_VOUCHER_DATA, data)
}

export const updateVoucherData = (data: IAPIPayload) => {
  return axios.post(UPDATE_VOUCHER_DATA, data)
}

// get voucher
export const getVoucherCrud = (data: IAPIPayload) => {
  return axios.post(GET_VOUCHER, data)
}
export const getVoucherData = (data: IAPIPayload) => {
  return axios.post(GET_VOUCHER_DATA, data)
}
export const getVoucherDataById = (data: IAPIPayload) => {
  return axios.post(GET_VOUCHER_DATA_BY_ID, data)
}

export const getAccountNo = (data: IAPIPayload) => {
  return axios.post(GET_ACCOUNT_NO, data)
}
export const getReferenceDoc = (data: IAPIPayload) => {
  return axios.post(GET_REFERENCE_DOC, data)
}

export const submitVouchersData = (data: IAPIPayload) => {
  return axios.post(SUBMIT_VOUCHER_DATA, data)
}

export const getVoucherNo = (data: IAPIPayload) => {
  return axios.post(GET_VOUCHER_NO, data)
}
export const getVoucherLogData = (data: IAPIPayload) => {
  return axios.post(GET_VOUCHER_LOG_DATA, data)
}
export const getAllBranchList = (data: IAPIPayload) => {
  return axios.post(GET_ALL_BRANCH, data)
}
export const getAllDepartmentList = (data: IAPIPayload) => {
  return axios.post(GET_ALL_DEPARTMENT, data)
}
