import { isBoldRow, isBoldRowiWithTotal } from '../../../constants/data'
import styles from './trialBalance.module.scss'
import { t } from 'i18next'

export const viewBalanceTableHeaderData: any = [
  {
    Header: t('Voucher.VOUCHER_NO'),
    accessor: (row: any) => {
      return row?.number ?? ''
    },
  },
  {
    Header: t('Reports.DEBIT_BALANCE'),
    accessor: (row: any) => {
      return row?.debit_amount ?? ''
    },
  },
  {
    Header: t('Reports.DEBIT_BALANCE'),
    accessor: (row: any) => {
      return row?.credit_amount ?? ''
    },
  },
]

// New Client Change

export const trialBalanceTableHeaderData: any = [
  {
    Header: t('Reports.A/C_NO'),
    Cell: ({ row }: any) => {
      return (
        <p
          className={
            isBoldRowiWithTotal.includes(row?.original?.level)
              ? styles.boldTd
              : styles.normalStyleTd
          }
        >
          {row?.original?.account_no ? row?.original?.account_no : '-'}
        </p>
      )
    },
  },
  {
    Header: t('MobileAppConfiguration.DESCRIPTION'),
    Cell: ({ row }: any) => {
      return (
        <p
          className={
            isBoldRowiWithTotal.includes(row?.original?.level)
              ? styles.boldTd
              : styles.normalStyleTd
          }
        >
          {row?.original?.account_name ? row?.original?.account_name : '-'}
        </p>
      )
    },
  },
  {
    Header: t('Reports.OPENING_BALANCE'),
    columns: [
      {
        Header: t('Reports.Opening_Dr'),
        Cell: ({ row }: any) => {
          return (
            <p
              className={
                isBoldRowiWithTotal.includes(row?.original?.level)
                  ? styles.boldTd
                  : styles.normalStyleTd
              }
            >
              {row?.original?.open?.debit
                ? row?.original?.open?.debit
                : isBoldRow.includes(row?.original?.level)
                ? ''
                : 0}
            </p>
          )
        },
      },
      {
        Header: t('Reports.Opening_Cr'),
        Cell: ({ row }: any) => {
          return (
            <p
              className={
                isBoldRowiWithTotal.includes(row?.original?.level)
                  ? styles.boldTd
                  : styles.normalStyleTd
              }
            >
              {row?.original?.open?.credit
                ? row?.original?.open?.credit
                : isBoldRow.includes(row?.original?.level)
                ? ''
                : 0}
            </p>
          )
        },
      },
    ],
  },
  {
    Header: t('Reports.CURRENT_BALANCE'),
    columns: [
      {
        Header: t('Reports.Dr'),
        accessor: 'current_debit',
        Cell: ({ row }: any) => {
          return (
            <p
              className={
                isBoldRowiWithTotal.includes(row?.original?.level)
                  ? styles.boldTd
                  : styles.normalStyleTd
              }
            >
              {row?.original?.current?.debit
                ? row?.original?.current?.debit
                : isBoldRow.includes(row?.original?.level)
                ? ''
                : 0}
            </p>
          )
        },
      },
      {
        Header: t('Reports.Cr'),
        accessor: 'current_credit',
        Cell: ({ row }: any) => {
          return (
            <p
              className={
                isBoldRowiWithTotal.includes(row?.original?.level)
                  ? styles.boldTd
                  : styles.normalStyleTd
              }
            >
              {row?.original?.current?.credit
                ? row?.original?.current?.credit
                : isBoldRow.includes(row?.original?.level)
                ? ''
                : 0}
            </p>
          )
        },
      },
    ],
  },

  {
    Header: t('Reports.CLOSING_BALANCE'),
    columns: [
      {
        Header: t('Reports.Closing_Dr'),
        Cell: ({ row }: any) => {
          return (
            <p
              className={
                isBoldRowiWithTotal.includes(row?.original?.level)
                  ? styles.boldTd
                  : styles.normalStyleTd
              }
            >
              {row?.original?.close?.debit
                ? row?.original?.close?.debit
                : isBoldRow.includes(row?.original?.level)
                ? ''
                : 0}
            </p>
          )
        },
      },
      {
        Header: t('Reports.Closing_Cr'),
        Cell: ({ row }: any) => {
          return (
            <p
              className={
                isBoldRowiWithTotal.includes(row?.original?.level)
                  ? styles.boldTd
                  : styles.normalStyleTd
              }
            >
              {row?.original?.close?.credit
                ? row?.original?.close?.credit
                : isBoldRow.includes(row?.original?.level)
                ? ''
                : 0}
            </p>
          )
        },
      },
    ],
  },
]
