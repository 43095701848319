// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.insurancePlanViewDocumentPopup_notesPopupContainer__dhpQh {
  width: 500px;
  margin: auto;
  background: var(--white1);
  border-radius: 10px;
  padding: 10px;
}
.insurancePlanViewDocumentPopup_notesPopupContainer__dhpQh .insurancePlanViewDocumentPopup_closeIconStyle__q1uuT {
  cursor: pointer;
  float: right;
}
.insurancePlanViewDocumentPopup_notesPopupContainer__dhpQh .insurancePlanViewDocumentPopup_notesContainer__JW2fa {
  margin-top: 50px;
}
.insurancePlanViewDocumentPopup_notesPopupContainer__dhpQh .insurancePlanViewDocumentPopup_notesContainer__JW2fa .insurancePlanViewDocumentPopup_title__K5wsy {
  text-align: center;
  font-weight: var(--font-semibold);
  font-size: var(--font-24);
  line-height: 36px;
  color: var(--black2);
}
.insurancePlanViewDocumentPopup_notesPopupContainer__dhpQh .insurancePlanViewDocumentPopup_notesContainer__JW2fa .insurancePlanViewDocumentPopup_dividerStyle__cKbPQ {
  margin: 10px auto 30px auto;
  width: 80px;
  height: 3px;
  background: var(--green1);
}
.insurancePlanViewDocumentPopup_notesPopupContainer__dhpQh .insurancePlanViewDocumentPopup_notesContainer__JW2fa .insurancePlanViewDocumentPopup_mainContainer__wY3fl {
  height: 300px;
  overflow: auto;
  margin: 20px auto;
}
.insurancePlanViewDocumentPopup_notesPopupContainer__dhpQh .insurancePlanViewDocumentPopup_notesContainer__JW2fa .insurancePlanViewDocumentPopup_mainContainer__wY3fl::-webkit-scrollbar {
  display: block;
  width: 7px;
  height: 7px;
}
.insurancePlanViewDocumentPopup_notesPopupContainer__dhpQh .insurancePlanViewDocumentPopup_notesContainer__JW2fa .insurancePlanViewDocumentPopup_mainContainer__wY3fl::-webkit-scrollbar-track {
  background: transparent;
}
.insurancePlanViewDocumentPopup_notesPopupContainer__dhpQh .insurancePlanViewDocumentPopup_notesContainer__JW2fa .insurancePlanViewDocumentPopup_mainContainer__wY3fl::-webkit-scrollbar-thumb {
  background-color: var(--grey7);
  border-radius: 10px;
  border: 1px solid var(--grey7);
}
.insurancePlanViewDocumentPopup_notesPopupContainer__dhpQh .insurancePlanViewDocumentPopup_notesContainer__JW2fa .insurancePlanViewDocumentPopup_mainContainer__wY3fl .insurancePlanViewDocumentPopup_documentViwerStyle__3qRuS {
  width: 100%;
  height: 100%;
  margin: 10px auto;
}
.insurancePlanViewDocumentPopup_notesPopupContainer__dhpQh .insurancePlanViewDocumentPopup_notesContainer__JW2fa .insurancePlanViewDocumentPopup_mainContainer__wY3fl .insurancePlanViewDocumentPopup_imageStyle__q6tPK {
  margin: 10px auto;
}`, "",{"version":3,"sources":["webpack://./src/components/common/modal/insurance-plan-view-document-popup/insurancePlanViewDocumentPopup.module.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;EAEA,YAAA;EACA,yBAAA;EACA,mBAAA;EACA,aAAA;AAAF;AACE;EACE,eAAA;EACA,YAAA;AACJ;AACE;EACE,gBAAA;AACJ;AAAI;EACE,kBAAA;EACA,iCAAA;EACA,yBAAA;EACA,iBAAA;EACA,oBAAA;AAEN;AAAI;EACE,2BAAA;EACA,WAAA;EACA,WAAA;EACA,yBAAA;AAEN;AAAI;EACE,aAAA;EACA,cAAA;EACA,iBAAA;AAEN;AADM;EACE,cAAA;EACA,UAAA;EACA,WAAA;AAGR;AADM;EACE,uBAAA;AAGR;AAAM;EACE,8BAAA;EACA,mBAAA;EACA,8BAAA;AAER;AAAM;EACE,WAAA;EACA,YAAA;EACA,iBAAA;AAER;AAAM;EACE,iBAAA;AAER","sourcesContent":[".notesPopupContainer {\n  width: 500px;\n  // height: 250px;\n  margin: auto;\n  background: var(--white1);\n  border-radius: 10px;\n  padding: 10px;\n  .closeIconStyle {\n    cursor: pointer;\n    float: right;\n  }\n  .notesContainer {\n    margin-top: 50px;\n    .title {\n      text-align: center;\n      font-weight: var(--font-semibold);\n      font-size: var(--font-24);\n      line-height: 36px;\n      color: var(--black2);\n    }\n    .dividerStyle {\n      margin: 10px auto 30px auto;\n      width: 80px;\n      height: 3px;\n      background: var(--green1);\n    }\n    .mainContainer {\n      height: 300px;\n      overflow: auto;\n      margin: 20px auto;\n      &::-webkit-scrollbar {\n        display: block;\n        width: 7px;\n        height: 7px;\n      }\n      &::-webkit-scrollbar-track {\n        background: transparent;\n      }\n\n      &::-webkit-scrollbar-thumb {\n        background-color: var(--grey7);\n        border-radius: 10px;\n        border: 1px solid var(--grey7);\n      }\n      .documentViwerStyle {\n        width: 100%;\n        height: 100%;\n        margin: 10px auto;\n      }\n      .imageStyle {\n        margin: 10px auto;\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"notesPopupContainer": `insurancePlanViewDocumentPopup_notesPopupContainer__dhpQh`,
	"closeIconStyle": `insurancePlanViewDocumentPopup_closeIconStyle__q1uuT`,
	"notesContainer": `insurancePlanViewDocumentPopup_notesContainer__JW2fa`,
	"title": `insurancePlanViewDocumentPopup_title__K5wsy`,
	"dividerStyle": `insurancePlanViewDocumentPopup_dividerStyle__cKbPQ`,
	"mainContainer": `insurancePlanViewDocumentPopup_mainContainer__wY3fl`,
	"documentViwerStyle": `insurancePlanViewDocumentPopup_documentViwerStyle__3qRuS`,
	"imageStyle": `insurancePlanViewDocumentPopup_imageStyle__q6tPK`
};
export default ___CSS_LOADER_EXPORT___;
