import { t } from 'i18next'

export const IpdRequestHeaderData: any = [
  {
    Header: t('RequestDiagnosis.TESTTYPE'),
    accessor: 'test_type',
  },
  {
    Header: t('RequestDiagnosis.TESTNAME'),
    accessor: 'test_name',
  },
  {
    Header: t('RequestDiagnosis.PRIORITY'),
    accessor: 'priority',
  },
  {
    Header: t('InsurancePlan_Form.PRICE'),
    accessor: 'price',
  },
  {
    Header: t('ServiceTable.BILLABLE'),
    Cell: (props: any) => {
      return (
        <>{props.row.original.is_billable ? t('Common.Yes') : t('Common.No')}</>
      )
    },
  },
  {
    Header: t('PatientHistoryTableHeader.NOTES'),
    Cell: (props: any) => {
      return (
        <>
          {props.row.original.test_notes?.length
            ? props.row.original.test_notes
            : '-'}
        </>
      )
    },
  },
]
