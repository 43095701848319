import { Suspense, lazy, useEffect, useState } from 'react'
import Select from 'react-select'
import makeAnimated from 'react-select/animated'
import Button from '../../../components/common/button/Button'
import { SearchIcon } from '../../../components/common/svg-components'
import { useNavigate } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from '../../../hooks'
import Pagination from '../../../components/common/pagination/Pagination'
import Popup from '../../../components/common/popup/Popup'
import { requestGenerator } from '../../../utils/payloadGenerator'
import { reactSelectStyles, trimValue } from '../../../utils/utils'
import { getAllEmployee } from '../../../redux/features/employee/employeeAsyncActions'
import Loader from '../../../components/common/spinner/Loader'
import EmployeeDeletePopup from './employee-delete-popup/EmployeeDeletePopup'
import { statusOptions } from '../../../constants/data'
import styles from './employees.module.scss'
import { Column, TableOptions } from 'react-table'
import { Cols } from '../employeeInterface'
import { employeeTableHeaderData } from './employeeTableData'
import { getAllDepartment } from '../../../redux/features/department/departmentAsyncActions'
import { getAllSpeciality } from '../../../redux/features/specialities/specialitiesAsyncActions'
import { useTranslation } from 'react-i18next'

const LazyEmployeeData = lazy(() => import('./employee-table'))

const Employees = () => {
  const navigate = useNavigate()
  const animatedComponent = makeAnimated()
  const { t } = useTranslation()
  const [employeeSearch, setEmployeeSearch] = useState<string>('')
  const [subCompanyFilter, setSubCompanyFilter] = useState<any>(null)
  const [departmentFilter, setDepartmentFilter] = useState<any>(null)
  const [specialtyFilter, setSpecialtyFilter] = useState<any>(null)
  const [statusFilter, setStatusFilter] = useState<any>(null)
  const [deletePopData, setDeletePopData] = useState({})
  const [showDelete, setShowDelete] = useState<boolean>(false)
  const [dataPerPage, setDataPerPage] = useState<number>(10)
  const [totalPage, setTotalPage] = useState<number>(0)
  const [pageIndex, setPageIndex] = useState<number>(1)
  const [total, setTotal] = useState(0)
  const dispatch = useAppDispatch()
  const { employeeLoader, allEmployeeData, isStatusUpdated } = useAppSelector(
    (state) => state.employee
  )
  const { departmentData } = useAppSelector((state) => state.department)
  const { specialityData } = useAppSelector((state) => state.speciality)
  const { masterValueData } = useAppSelector((state) => state.login)

  // getAllDepartment API calls
  useEffect(() => {
    let payloadData = {
      search: '',
      page: 1,
      pageSize: 1000,
    }
    dispatch(getAllDepartment(requestGenerator(payloadData)))
  }, [pageIndex, dataPerPage])

  //  API Calling for Get Data / getAllEmployee API calls
  useEffect(() => {
    const payloadData = {
      name: employeeSearch,
      department_id: departmentFilter?.value,
      sub_company: subCompanyFilter?.value,
      speciality_id: specialtyFilter?.value,
      status: statusFilter?.value,
      page: pageIndex,
      pageSize: dataPerPage,
    }
    dispatch(getAllEmployee(requestGenerator(payloadData))).then(
      (result: any) => {
        setTotalPage(result.payload.lastPage)
        setTotal(result.payload.total)
      }
    )
  }, [
    dispatch,
    departmentFilter,
    subCompanyFilter,
    statusFilter,
    specialtyFilter,
    isStatusUpdated,
    dataPerPage,
    showDelete,
    pageIndex,
  ])

  // Search and Filter
  const handleSearch = () => {
    setPageIndex(1)
    if (employeeSearch?.length > 0) {
      let payloadData = {
        name: employeeSearch,
        department_id: departmentFilter?.value,
        sub_company: subCompanyFilter?.value,
        speciality_id: specialtyFilter?.value,
        status: statusFilter?.value,
        page: 1,
        pageSize: dataPerPage,
      }
      dispatch(getAllEmployee(requestGenerator(payloadData))).then(
        (result: any) => {
          setTotalPage(result.payload.lastPage)
          setTotal(result.payload.total)
        }
      )
    }
  }
  // Delete modal open
  const handleDeleteModalOpen = (id: any) => {
    setDeletePopData(id)
    setShowDelete((prevState) => !prevState)
  }
  // Delete modal close
  const handleDeleteModalClose = () => {
    setShowDelete((prevState) => !prevState)
    dispatch(getAllEmployee(requestGenerator({ is_active: true })))
  }
  const handleReset = () => {
    setEmployeeSearch('')
    setDepartmentFilter('')
    setSubCompanyFilter('')
    setSpecialtyFilter('')
    setStatusFilter('')
  }

  // Pagination
  const pageIndexArray = () => {
    let pageIndexOptions = []
    for (let i = 1; i <= totalPage; i++) {
      pageIndexOptions.push(i)
    }
    return pageIndexOptions
  }
  const pageIndexOptions = pageIndexArray()
  return (
    <>
      {employeeLoader && <Loader />}
      {/* Delete Popup */}
      {showDelete && (
        <Popup
          Children={EmployeeDeletePopup}
          popData={deletePopData}
          handleClose={() => handleDeleteModalClose()}
        />
      )}
      <div className={styles.employeesMainContainer}>
        {/* search */}
        <div className={styles.searchMainContainer}>
          <div className={styles.searchContainer}>
            <input
              className={styles.searchInput}
              type="text"
              placeholder={t('LabReport.SearchName') || 'Search By Name'}
              value={employeeSearch}
              onChange={(e) => {
                trimValue(e)
                setEmployeeSearch(e.target.value)
                if (e.target.value.length === 0) {
                  let payloadData = {
                    page: pageIndex,
                    pageSize: dataPerPage,
                    search: '',
                  }
                  dispatch(getAllEmployee(requestGenerator(payloadData))).then(
                    (result: any) => {
                      setTotalPage(result.payload.lastPage)
                      setTotal(result.payload.total)
                    }
                  )
                }
              }}
              onKeyDown={(e: any) => {
                if (e?.key === 'Enter') {
                  handleSearch()
                }
              }}
            />
            <div className={styles.searchButton} onClick={() => handleSearch()}>
              <SearchIcon fillColor={'#ffffff'} />
            </div>
          </div>
          <div className={styles.searchBtnContainer}>
            <Button
              title={t('Employee.AddEmp') || 'Add New Employee'}
              customClass={styles.btnContainer}
              handleClick={() => {
                navigate('addEmployee')
              }}
            />
            <Button
              title={t('Employee.ImpEmp') || 'Import Employees'}
              customClass={styles.btnContainer}
              type="button"
              disable={true}
            />
          </div>
        </div>
        <form className={styles.employeeForm}>
          <div className={styles.dropDownContainer}>
            {/* Sub Company */}
            <div className={styles.formFieldContainer}>
              <div className={styles.inputFieldContainer}>
                <label htmlFor={'COMPANY'} className={styles.dropDownLabel}>
                  {t('Employee.SubCompany')}
                </label>
                <Select
                  className={styles.selectInputField}
                  placeholder={
                    <span className="selectPlaceholder">
                      {t('Employee.SelectSub')}
                    </span>
                  }
                  options={
                    Array.isArray(masterValueData) && masterValueData.length > 0
                      ? masterValueData
                          .find(
                            (item: any) => item.category_name === 'SUB-COMPANY'
                          )
                          ?.values?.map((item: any) => ({
                            label: item?.value,
                            value: item?._id,
                          }))
                      : []
                  }
                  value={subCompanyFilter || ''}
                  onChange={(e: any) => {
                    setSubCompanyFilter(e)
                  }}
                  isSearchable={true}
                  components={animatedComponent}
                  closeMenuOnSelect={true}
                  maxMenuHeight={130}
                  styles={reactSelectStyles}
                />
              </div>
            </div>
            {/* DEPARTMENT */}
            <div className={styles.formFieldContainer}>
              <div className={styles.inputFieldContainer}>
                <label htmlFor={'DEPARTMENT'} className={styles.dropDownLabel}>
                  {t('IPDBooking.Department')}
                </label>
                <Select
                  className={styles.selectInputField}
                  placeholder={
                    <span className="selectPlaceholder">
                      {t('IPDBooking.SelectDepartment')}
                    </span>
                  }
                  isSearchable={true}
                  isClearable={true}
                  options={departmentData
                    ?.filter((item: any) => item.is_active)
                    .map((item: any) => ({
                      label: item.name,
                      value: item._id,
                    }))}
                  value={departmentFilter || ''}
                  onChange={(e: any) => {
                    let data = {
                      search: '',
                      page: 1,
                      pageSize: 1000,
                      // exclude_image: true,
                      department_ids: [e.value],
                    }
                    dispatch(getAllSpeciality(requestGenerator(data)))
                    setDepartmentFilter(e)
                    // setSpecialtyFilter(e);
                  }}
                  components={animatedComponent}
                  closeMenuOnSelect={true}
                  maxMenuHeight={130}
                  styles={reactSelectStyles}
                />
              </div>
            </div>
            {/* Speciality */}
            <div className={styles.formFieldContainer}>
              <div className={styles.inputFieldContainer}>
                <label htmlFor={'SPECIALTY'} className={styles.dropDownLabel}>
                  {t('ManageStaff.Specialty')}
                </label>
                <Select
                  className={styles.selectInputField}
                  isSearchable={true}
                  options={specialityData
                    ?.filter((item: any) => item.is_active)
                    .map((item: any) => ({
                      label: item.name,
                      value: item._id,
                    }))}
                  value={specialtyFilter || ''}
                  onChange={(e: any) => {
                    setSpecialtyFilter(e)
                  }}
                  components={animatedComponent}
                  closeMenuOnSelect={true}
                  placeholder={
                    <span className="selectPlaceholder">
                      {t('ManageStaff.Specialty_placeholder')}
                    </span>
                  }
                  maxMenuHeight={120}
                  styles={reactSelectStyles}
                />
              </div>
            </div>
            {/* Status */}
            <div className={styles.formFieldContainer}>
              <div className={styles.inputFieldContainer}>
                <label htmlFor={'STATUS'} className={styles.dropDownLabel}>
                  {t('MobileAppointmentReq.Status')}
                </label>
                <Select
                  className={styles.selectInputField}
                  placeholder={
                    <span className="selectPlaceholder">
                      {t('DoctorDiagnosis.Select Status')}
                    </span>
                  }
                  options={statusOptions.map((item: any) => ({
                    label: item.label,
                    value: item.value,
                  }))}
                  value={statusFilter || ''}
                  onChange={(e: any) => {
                    setStatusFilter(e)
                  }}
                  isSearchable={true}
                  components={animatedComponent}
                  closeMenuOnSelect={true}
                  maxMenuHeight={130}
                  styles={reactSelectStyles}
                />
              </div>
            </div>
            <div className={styles.buttonContainer}>
              <Button
                title={t('BranchSetup.Reset') || 'Reset'}
                type="reset"
                customClass={styles.resetBtn}
                handleClick={() => handleReset()}
                disable={
                  employeeSearch == '' &&
                  departmentFilter == null &&
                  subCompanyFilter == null &&
                  specialtyFilter == null &&
                  statusFilter == null
                    ? true
                    : employeeSearch == '' &&
                      departmentFilter?.label == undefined &&
                      subCompanyFilter?.label == undefined &&
                      specialtyFilter?.label == undefined &&
                      statusFilter?.label == undefined
                    ? true
                    : false
                }
              />
            </div>
          </div>
        </form>
        {/* Table */}
        {/* setEmployeeSearch('') setDepartmentFilter('') setSubCompanyFilter('')
        setSpecialtyFilter('') setStatusFilter('') */}
        <Suspense
        //  fallback={<Loader />}
        >
          <LazyEmployeeData
            handleDeleteModalOpen={handleDeleteModalOpen}
            allEmployeeData={allEmployeeData}
          >
            {!!(total > 10) && (
              <Pagination
                setDataPerPage={setDataPerPage}
                pageIndexOptions={pageIndexOptions}
                pageIndex={pageIndex}
                setPageIndex={setPageIndex}
              />
            )}
          </LazyEmployeeData>
        </Suspense>
      </div>
    </>
  )
}

export default Employees
