import { FC, useState, useEffect } from 'react'
import styles from './external.module.scss'
import TableV2 from '../../../../components/common/table/tableV2/TableV2'
// import AttachFiles from '../../../../components/common/attach-files/Attachfiles';
import { useForm } from 'react-hook-form'
import { ExternalHeader } from './ExternalTableData'
import {
  EXTERNAL_ASSIGNED,
  EXTERNAL_UNASSIGNED,
  SOURCE_EXTERNAL,
  UPLOAD_CSV_EXTERNAL,
} from '../../../../constants/constant'
import Select from 'react-select'
import Button from '../../../../components/common/button/Button'
import { useAppDispatch, useAppSelector } from '../../../../hooks'
import { leadPoolCsvUploadValidators } from '../../../../form-validators/leadPoolCsvUploadValidators'
import {
  IUploadCsvExternalForm,
  fileType,
} from '../../../../interfaces/interfaces'
import {
  assignInternalLead,
  createExternalLead,
  getAllAssignee,
  getAllLeadCountInfo,
  getAllLeadPool,
} from '../../../../redux/features/lead-pool/callCenterAsyncActions'
import { requestGenerator } from '../../../../utils/payloadGenerator'
import Loader from '../../../../components/common/spinner/Loader'
import Pagination from '../../../../components/common/pagination/Pagination'
import AttachFiles from '../../../../components/common/attach-files/single-file/AttachSingleFile'
import { dataURI, downloadCSV } from '../../../../utils/utils'
import { clearAllLeadData } from '../../../../redux/features/lead-pool/callCenterSlice'
import { csvFileData } from '../../../../constants/data'
import { t } from 'i18next'
import SampleCSV from '../../../../components/sample-csv/SampleCSV'

const External = () => {
  const [dataPerPage, setDataPerPage] = useState<number>(10)
  const [totalPage, setTotalPage] = useState<number>(0)
  const [pageIndex, setPageIndex] = useState<number>(1)
  const [totalData, setTotalData] = useState(0)
  const { masterValueData } = useAppSelector((state) => state.login)
  const dispatch = useAppDispatch()
  const { isLoading, allLeadData, allLeadCountInfo } = useAppSelector(
    (state) => state.callCenter
  )
  const [assign, setAssign] = useState<boolean>()
  const [userPhoto, setuserPhoto] = useState({ name: '', data_uri: '' })

  let allExteralData =
    allLeadCountInfo?.length > 0 &&
    allLeadCountInfo?.filter((item: any) => item?._id?.type === 'EXTERNAL')

  const countData = (isAssigned: boolean) => {
    if (allExteralData?.length > 0) {
      let filterData = allExteralData?.filter(
        (item: any) => item?._id?.is_assigned === isAssigned
      )
      if (filterData?.length > 0) {
        return filterData[0]?.count
      } else {
        return 0
      }
    } else {
      return 0
    }
  }

  let assignCountData = countData(true)
  let unassignCountData = countData(false)

  const pageIndexArray = () => {
    let pageIndexOptions = []
    for (let i = 1; i <= totalPage; i++) {
      pageIndexOptions.push(i)
    }
    return pageIndexOptions
  }

  const pageIndexOptions = pageIndexArray()

  useEffect(() => {
    let data: any = {
      type: 'EXTERNAL',
      page: pageIndex,
      pageSize: dataPerPage,
    }
    let reqData: any = {
      type: 'EXTERNAL',
      is_assigned: assign,
      page: pageIndex,
      pageSize: dataPerPage,
    }

    dispatch(
      getAllLeadPool(requestGenerator(assign === undefined ? data : reqData))
    ).then((result) => {
      setTotalPage(result.payload.lastPage)
      setTotalData(result.payload.total)
    })
  }, [pageIndex, dataPerPage])

  useEffect(() => {
    let data: any = {
      type: 'EXTERNAL',
      is_assigned: assign,
      page: 1,
      pageSize: dataPerPage,
    }
    if (data?.is_assigned !== undefined) {
      dispatch(getAllLeadPool(requestGenerator(data))).then((result) => {
        setTotalPage(result.payload.lastPage)
        setTotalData(result.payload.total)
        setPageIndex(1)
      })
    }
  }, [assign])

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    trigger,
    reset,
    formState: { errors },
  } = useForm<IUploadCsvExternalForm>({})

  const formData = watch()

  useEffect(() => {
    if (formData[EXTERNAL_ASSIGNED] === 'EXTERNAL_ASSIGNED') {
      setValue(EXTERNAL_ASSIGNED, 'EXTERNAL_ASSIGNED')
      setValue(EXTERNAL_UNASSIGNED, '')
      setAssign(true)
    }
  }, [formData[EXTERNAL_ASSIGNED]])

  useEffect(() => {
    if (formData[EXTERNAL_UNASSIGNED] === 'EXTERNAL_UNASSIGNED') {
      setValue(EXTERNAL_ASSIGNED, '')
      setValue(EXTERNAL_UNASSIGNED, 'EXTERNAL_UNASSIGNED')
      setAssign(false)
    }
  }, [formData[EXTERNAL_UNASSIGNED]])

  const userPhotoField = watch(UPLOAD_CSV_EXTERNAL)
  const fileName = userPhotoField?.[0]

  useEffect(() => {
    const fileList: fileType = { name: '', data_uri: '' }
    const getDataURI = async (fileName: File) => {
      try {
        const result = await dataURI(fileName)
        fileList.data_uri = result
        fileList.name = fileName.name

        setuserPhoto(fileList)
      } catch (error) {
        console.log({ error })
      }
    }
    if (fileName) {
      getDataURI(fileName)
    }
  }, [fileName])

  const onSubmit = async (data: IUploadCsvExternalForm) => {
    let payloadData = {
      file: userPhoto,
      external_source: data?.source_id?.label,
    }
    dispatch(createExternalLead(requestGenerator(payloadData))).then((e) => {
      if (e.type === 'callCenter/createExternalLead/fulfilled') {
        reset()
        setuserPhoto({ name: '', data_uri: '' })
        setValue(SOURCE_EXTERNAL, [])
        let data: any = {
          type: 'EXTERNAL',
          page: pageIndex,
          pageSize: dataPerPage,
        }
        let reqData: any = {
          type: 'EXTERNAL',
          is_assigned: assign,
          page: pageIndex,
          pageSize: dataPerPage,
        }
        dispatch(getAllLeadCountInfo(requestGenerator({})))

        dispatch(
          getAllLeadPool(
            requestGenerator(assign === undefined ? data : reqData)
          )
        ).then((result) => {
          setTotalPage(result.payload.lastPage)
          setTotalData(result.payload.total)
        })
      }
    })
  }

  useEffect(() => {
    dispatch(getAllAssignee(requestGenerator({ search: 'CALL_CENTER_AGENT' })))
  }, [])

  const handleAssign = (item: any, userId: string) => {
    dispatch(
      assignInternalLead(requestGenerator({ id: item, userId: userId }))
    ).then((e) => {
      dispatch(clearAllLeadData())
      if (e.type === 'callCenter/assignInternalLead/fulfilled') {
        let data: any = {
          type: 'EXTERNAL',
          page: pageIndex,
          pageSize: dataPerPage,
        }
        let reqData: any = {
          type: 'EXTERNAL',
          is_assigned: assign,
          page: pageIndex,
          pageSize: dataPerPage,
        }

        dispatch(
          getAllLeadPool(
            requestGenerator(assign === undefined ? data : reqData)
          )
        ).then((result) => {
          dispatch(getAllLeadCountInfo(requestGenerator({})))
          setTotalPage(result.payload.lastPage)
          setTotalData(result.payload.total)
        })
      }
    })
  }

  useEffect(() => {
    dispatch(getAllLeadCountInfo(requestGenerator({})))
  }, [])

  return (
    <>
      {isLoading && <Loader />}
      <div className={styles.admin}>
        <div className={styles.headerContainer}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className={styles.leftContainer}>
              <label className={styles.importTitle}>
                {t('PatientEMR.Source')} <span className="asterick">*</span>
              </label>
              <div>
                <Select
                  className={styles.select}
                  placeholder={t('PatientEMRPlaceHolder.SelectResource')}
                  closeMenuOnSelect={true}
                  isSearchable={true}
                  {...register(
                    SOURCE_EXTERNAL,
                    leadPoolCsvUploadValidators[SOURCE_EXTERNAL]
                  )}
                  options={masterValueData
                    ?.filter(
                      (item: any) =>
                        item?.category_name === 'SOURCE_AD_CAMPAIGN'
                    )[0]
                    ?.values?.map((item: any) => ({
                      label: item?.value,
                      value: item?._id,
                    }))}
                  value={watch(SOURCE_EXTERNAL)}
                  onChange={(e: any) => {
                    setValue(SOURCE_EXTERNAL, e)
                    trigger(SOURCE_EXTERNAL)
                  }}
                  maxMenuHeight={200}
                />
                {errors[SOURCE_EXTERNAL] && (
                  <p className="errorText">
                    {errors[SOURCE_EXTERNAL].message as any}
                  </p>
                )}
              </div>
            </div>
            <div className={styles.leftContainer}>
              <label className={styles.importTitle}>
                {t('Common.Upload')} <span className="asterick">*</span>
              </label>
              <div>
                <AttachFiles
                  register={register}
                  fileKey={UPLOAD_CSV_EXTERNAL}
                  id={UPLOAD_CSV_EXTERNAL}
                  fileList={userPhoto}
                  validation={leadPoolCsvUploadValidators[UPLOAD_CSV_EXTERNAL]}
                  isName={true}
                  isDocument={true}
                  showCsv={true}
                />
                {errors[UPLOAD_CSV_EXTERNAL] && (
                  <p className="errorText">
                    {errors[UPLOAD_CSV_EXTERNAL].message as any}
                  </p>
                )}
              </div>
            </div>
            <div className={styles.leftContainer}>
              <Button
                title={t('Common.Submit') || 'Submit'}
                customClass={styles.btnStyle}
                type="submit"
              />
            </div>
          </form>

          <div className={styles.col}>
            <div className={styles.radioFieldContainer}>
              <p className={styles.titleText}>
                {t('InvoiceInfo.External')}:{' '}
                {assignCountData + unassignCountData}
              </p>

              <div className={styles.singlRadioBtnConatainer}>
                <label htmlFor="external_assign" className={styles.radioLabel}>
                  <input
                    className={styles.radioInput}
                    type="radio"
                    id="external_assign"
                    value="EXTERNAL_ASSIGNED"
                    {...register(EXTERNAL_ASSIGNED)}
                  />
                  <span className={styles.customRadio} />
                  <p
                    className={
                      formData[EXTERNAL_ASSIGNED] === 'EXTERNAL_ASSIGNED'
                        ? styles.radioLabelTxtActive
                        : styles.radioLabelTxt
                    }
                  >
                    {' '}
                    {t('HeaderPopup.Assigned')}: {assignCountData}
                  </p>
                </label>
              </div>
              <div className={styles.singlRadioBtnConatainer}>
                <label
                  htmlFor="external_unassign"
                  className={styles.radioLabel}
                >
                  <input
                    className={styles.radioInput}
                    type="radio"
                    id="external_unassign"
                    value="EXTERNAL_UNASSIGNED"
                    {...register(EXTERNAL_UNASSIGNED)}
                  />
                  <span className={styles.customRadio} />
                  <p
                    className={
                      formData[EXTERNAL_UNASSIGNED] === 'EXTERNAL_UNASSIGNED'
                        ? styles.radioLabelTxtActive
                        : styles.radioLabelTxt
                    }
                  >
                    {' '}
                    {t('LeadPool.Unassigned')}: {unassignCountData}
                  </p>
                </label>
              </div>
            </div>
          </div>
          <div className={styles.col}>
            <SampleCSV onExport={() => downloadCSV(csvFileData)} />
          </div>
        </div>

        <TableV2
          tableHeaderData={ExternalHeader}
          tableRowData={allLeadData}
          handleClick={handleAssign}
        />
        {totalData > 10 && (
          <Pagination
            setDataPerPage={setDataPerPage}
            pageIndexOptions={pageIndexOptions}
            pageIndex={pageIndex}
            setPageIndex={setPageIndex}
            pageSize={dataPerPage}
          />
        )}
      </div>
    </>
  )
}
export default External
