import {
  PHARMACY_ITEM_CODE,
  PHARMACY_PRODUCTS,
  PHARMACY_SELL_PRICE,
  PHARMACY_DISCOUNT,
  PHARMACY_QTY,
  PHARMACY_EXPIRY_DATE,
  PHARMACY_AVAILABLE_QTY,
} from '../constants/constant'
import { t } from 'i18next'

export interface IaddPharmacyCustomerInformationSecondValidators {
  [PHARMACY_ITEM_CODE]: {
    required: string
  }

  [PHARMACY_PRODUCTS]: {
    required: string
  }

  [PHARMACY_SELL_PRICE]: {
    required: string
  }

  [PHARMACY_DISCOUNT]: {
    required: string
  }

  [PHARMACY_QTY]: {
    required: string
    validate: any
  }

  [PHARMACY_EXPIRY_DATE]: {
    required: string
  }

  [PHARMACY_AVAILABLE_QTY]: {
    required: any
  }
}

export const addPharmacyCustomerSecondInformation: any = {
  [PHARMACY_ITEM_CODE]: {
    required: t('POS.Item_code_validation'),
  },
  [PHARMACY_PRODUCTS]: {
    required: t('POS.Product_Validation'),
  },
  [PHARMACY_SELL_PRICE]: {
    required: t('BedSetup.sell_price_placeholder'),
  },
  [PHARMACY_DISCOUNT]: {
    required: t('POS.discount_validation'),
  },
  [PHARMACY_QTY]: {
    required: t('ItemUnitMaster.Qty_Validation'),
    validate: {
      handleQty: (
        value: string,
        values: IaddPharmacyCustomerInformationSecondValidators
      ) => {
        const pharmacyAvailabelQty = values[PHARMACY_AVAILABLE_QTY]
        if (Number(pharmacyAvailabelQty) === 0 || Number(value) === 0) {
          return t('POS.qty_validation')
        } else if (Number(value) > Number(pharmacyAvailabelQty)) {
          return t('POS.qty_value_validation')
        }
      },
    },
  },
  [PHARMACY_EXPIRY_DATE]: {
    required: t('InsurancePlanValidators.ExpiryDate'),
  },
}
