import { FC } from 'react'
import styles from './contactUs.module.scss'
import { useNavigate } from 'react-router-dom'
import {
  EmailIcon,
  LocationIcon,
  MobileIcon,
} from '../../../components/common/svg-components'
import Button from '../../../components/common/button/Button'
import { useTranslation } from 'react-i18next'

const ContactUS: FC = () => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  return (
    <>
      <p className={styles.formTitle}>{t('ContactUs.Contact Us')}</p>
      <div className={styles.contactContainer}>
        <MobileIcon />
        <div className={styles.infoContainer}>
          <p className={styles.infoTitle}>{t('ContactUs.Phone')}</p>
          <p className={styles.infoText}>{t('ContactUs.+96560001692')}</p>
        </div>
      </div>
      <div className={styles.contactContainer}>
        <EmailIcon width={20} height={20} />
        <div className={styles.infoContainer}>
          <p className={styles.infoTitle}>{t('Auth.Email')}</p>
          <p className={styles.infoText}>info@proact-kw.com</p>
        </div>
      </div>
      <div className={styles.contactContainer}>
        <LocationIcon />
        <div className={styles.infoContainer}>
          <p className={styles.infoTitle}>{t('PatientEMR.Address')}</p>
          <p className={styles.infoText}>{t('ContactUs.MCAddress')}</p>
        </div>
      </div>
      <Button
        title={t('Auth.Go Back') || 'Go Back'}
        type="button"
        customClass={styles.goBackButton}
        handleClick={() => navigate('/')}
      />
    </>
  )
}

export default ContactUS
