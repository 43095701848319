import {
  ASSIGN_TAG_NAME,
  ASSIGN_TAG,
  MASTER_VALUE,
  MASTER_VALUE_LABEL,
  COUNTRY_CODE,
  ACCOUNT_NO,
  HOLIDAY_DATE,
} from '../constants/constant'
import { t } from 'i18next'

export interface IassignTag {
  [MASTER_VALUE_LABEL]: {
    required: any
  }
  [MASTER_VALUE]: {
    required: any
  }
  [COUNTRY_CODE]: {
    required: any
  }
  [ACCOUNT_NO]: {
    required: any
  }
  [HOLIDAY_DATE]: {
    required: any
  }
}

export const masterValuesValidators: IassignTag = {
  [MASTER_VALUE_LABEL]: {
    required: 'Please enter category',
  },
  [MASTER_VALUE]: {
    required: t('ManageCategory.Value_validation'),
  },
  [COUNTRY_CODE]: {
    required: t('ManageCategory.COUNTRY_CODE_PLACEHOLDER'),
  },
  [ACCOUNT_NO]: {
    required: t('ManageCategory.ACCOUNT_NO_VALIDATION'),
  },
  [HOLIDAY_DATE]: {
    required: t('BookingConfirmationValidators.BOOKING_DATE'),
  },
}
