import { CheckIcon, UncheckIcon } from '../../components/common/svg-components'
import { colors } from '../../constants/color'
import { failure } from '../../constants/data'
import { useAppDispatch, useAppSelector } from '../../hooks'
import {
  addChildTableColumn,
  removeChildTableColumn,
  updateChildTableColumn,
} from '../../redux/features/role/roleSlice'
import { setMessage } from '../../redux/features/toast/toastSlice'
import styles from '../../pages/reports-poc/childTableSelectColumnsModal.module.scss'

export const childTableSelectColumnsPopupHeaderData: any = [
  {
    Header: 'COLUMN NAME',
    accessor: (row: any) => {
      return row ?? '-'
    },
  },
  {
    Header: 'SELECT COLUMN',
    disableSortBy: true,
    Cell: (props: any) => {
      const dispatch = useAppDispatch()
      const { selctedChildTableColumns } = useAppSelector(
        (state) => state.roleUser
      )
      const handleMainStoreCheck = (checkType: string) => {
        if (checkType === 'REMOVE') {
          dispatch(removeChildTableColumn(props?.row?.original))
        } else if (checkType === 'ADD') {
          dispatch(addChildTableColumn(props?.row?.original))
        }
      }
      return (
        <>
          {selctedChildTableColumns?.some(
            (item: any) => Object.values(item)[0] === `$${props.row.original}`
          ) ? (
            <CheckIcon
              fillColor={colors.green1}
              handleClick={() => handleMainStoreCheck('REMOVE')}
            />
          ) : (
            <UncheckIcon
              fillColor={colors.grey1}
              handleClick={() => handleMainStoreCheck('ADD')}
            />
          )}
        </>
      )
    },
  },
  {
    Header: 'ALIAS',
    disableSortBy: true,
    Cell: (props: any) => {
      const dispatch = useAppDispatch()

      const { selctedChildTableColumns } = useAppSelector(
        (state) => state.roleUser
      )
      const findSelectedTableColumn = selctedChildTableColumns?.find(
        (selectedColumn: any) =>
          Object.values(selectedColumn)[0] === `$${props.row.original}`
      )
      const aliasText =
        findSelectedTableColumn === undefined
          ? ''
          : Object?.keys(findSelectedTableColumn)?.[0]

      const validateIssueQty = (columnAliasText: string, item: any) => {
        if (columnAliasText?.length === 0) {
          dispatch(
            setMessage({
              message: 'Please enter valid text',
              type: failure,
            })
          )
          return item
        } else {
          return {
            [columnAliasText]: `${Object.values(item)[0]}`,
          }
        }
      }

      const handleChange = (e: any) => {
        const columnAliasText = e.target.value
        let tempArr: any = []
        tempArr = selctedChildTableColumns?.map((item: any, index: number) => {
          if (Object.values(item)[0] === `$${props.row.original}`) {
            return validateIssueQty(columnAliasText, item)
          } else {
            return item
          }
        })
        dispatch(updateChildTableColumn(tempArr))
      }

      return (
        <input
          type="text"
          value={aliasText}
          placeholder="Text"
          className={styles.aliasTextField}
          onChange={handleChange}
        />
      )
    },
  },
]
