import axios from 'axios'
import { IAPIPayload } from '../../../interfaces/apiInterface'
import {
  CREATE_TEMPLATE_URL,
  DELETE_TEMPLATE_URL,
  GET_TEMPLATE_BY_ID_URL,
  GET_TEMPLATE_URL,
  UPDATE_TEMPLATE_URL,
} from '../../../config/config'

// manate dynamic templates

// get
export const getTemplates = (data: IAPIPayload) => {
  return axios.post(GET_TEMPLATE_URL, data)
}

// create
export const createTemplate = (data: IAPIPayload) => {
  return axios.post(CREATE_TEMPLATE_URL, data)
}

// update
export const updateTemplate = (data: IAPIPayload) => {
  return axios.post(UPDATE_TEMPLATE_URL, data)
}

// delete
export const deleteTemplate = (data: IAPIPayload) => {
  return axios.post(DELETE_TEMPLATE_URL, data)
}

export const getTemplateId = (data: IAPIPayload) => {
  return axios.post(GET_TEMPLATE_BY_ID_URL, data)
}
