import { FC, useEffect } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { Input } from '../../../components/common/input/input'
import {
  PATIENT_NAME,
  DEPARTMENT,
  WARDS,
  ROOM,
  BED,
  BOOKING_DATE,
  ADMITTED_DATE,
  DISCHARGE_DATE,
  CONSULTED_DOCTOR,
} from '../../../constants/ipdBookConstants'
import { IIpdBookForm } from '../../../interfaces/ipdBookInterfaces'
// import { receiptValidators } from "../../../form-validators/ipdBookValidators";
import moment from 'moment'
import Select from 'react-select'
import { DropdownIndicator } from '../../../components/common/dropdown-indicator/DropdownIndicator'
import { colors } from '../../../constants/color'
import { useAppSelector } from '../../../hooks/index'
import { CloseIcon } from '../../../components/common/svg-components'
import Button from '../../../components/common/button/Button'
import styles from './ipdBookPopup.module.scss'
import { useTranslation } from 'react-i18next'

interface IIpdBookPopup {
  handleClose?: any
  handleYes?: any
  popData?: any
}

const IpdBookPopup: FC<IIpdBookPopup> = ({
  handleClose,
  handleYes,
  popData,
}) => {
  const { ipdBookDoctorsData, ipdBookDetailsData } = useAppSelector(
    (state) => state.ipdBooking
  )
  const booking_date_formated = moment().format('YYYY-MM-DD')
  const { t } = useTranslation()
  // React Hook form for the form handling
  const {
    register,
    reset,
    handleSubmit,
    watch,
    setValue,
    // formState: { errors },
  } = useForm<IIpdBookForm>({})

  const onSubmit: SubmitHandler<IIpdBookForm> = (data) => {
    handleYes(data)
  }

  // Function for set intial value in form.
  useEffect(() => {
    let ipdBookData = {}
    if (popData?.discharge_date) {
      ipdBookData = {
        patient_name: popData?.patient_name,
        department: ipdBookDetailsData?.department_name,
        wards: ipdBookDetailsData?.ward_name,
        room: ipdBookDetailsData?.room_name,
        bed: ipdBookDetailsData?.bed_name,
        admitted_date: popData?.admissionDate,
        discharge_date: moment(popData?.discharge_date).format('YYYY-MM-DD'),
      }
    } else {
      ipdBookData = {
        patient_name: popData?.patient_name,
        department: ipdBookDetailsData?.department_name,
        wards: ipdBookDetailsData?.ward_name,
        room: ipdBookDetailsData?.room_name,
        bed: ipdBookDetailsData?.bed_name,
        admitted_date: popData?.admissionDate,
      }
    }
    reset(ipdBookData)
    setValue(BOOKING_DATE, booking_date_formated)
  }, [])

  // Function for set primary doctor
  useEffect(() => {
    if (ipdBookDetailsData?.primary_doctor_id) {
      const findPrimaryDoctor = ipdBookDoctorsData?.find(
        (item: any) => item?._id === ipdBookDetailsData?.primary_doctor_id
      )
      const convertPrimaryDoctorObject = {
        label: findPrimaryDoctor?.doctor_name,
        value: findPrimaryDoctor?._id,
      }
      setValue(CONSULTED_DOCTOR, convertPrimaryDoctorObject)
    }
  }, [setValue, ipdBookDoctorsData, ipdBookDetailsData])

  return (
    <>
      <div
        className={styles.ipdBookMainContainer}
        onClick={(e) => e.stopPropagation()}
      >
        <CloseIcon
          customClass={styles.closeIconStyle}
          fillColor={colors.green1}
          handleClick={() => handleClose()}
        />
        <h1 className={styles.ipdBookModalHeading}>
          {popData?.booked === true
            ? 'Common.View'
            : t('BookingConfirmation.Booking Confirmation')}
        </h1>
        <hr className={styles.ipdBookModalDivider} />
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className={styles.ipdBookModalContainer}>
            <div className={styles.formFields}>
              <div className={styles.formFieldStyle}>
                <Input
                  labelText={
                    t('ShareQuestionnaire.Patient Name') || 'Patient Name'
                  }
                  disabled
                  inlineClass={styles.disabledField}
                  {...register(PATIENT_NAME)}
                />
              </div>
              <div className={styles.formFieldStyle}>
                <Input
                  labelText={t('DepartmentSetup.Dept') || 'Dept'}
                  disabled
                  inlineClass={styles.disabledField}
                  {...register(DEPARTMENT)}
                />
              </div>
            </div>
            <div className={styles.formFields}>
              <div className={styles.formFieldStyle}>
                <Input
                  labelText={t('IPDBooking.Ward') || 'Ward'}
                  disabled
                  inlineClass={styles.disabledField}
                  {...register(WARDS)}
                />
              </div>
              <div className={styles.formFieldStyle}>
                <Input
                  labelText={t('IPDBooking.Room') || 'Room'}
                  disabled
                  inlineClass={styles.disabledField}
                  {...register(ROOM)}
                />
              </div>
            </div>
            <div className={styles.formFields}>
              <div className={styles.formFieldStyle}>
                <Input
                  labelText={t('IPDBooking.Bed') || 'Bed'}
                  disabled
                  inlineClass={styles.disabledField}
                  {...register(BED)}
                />
              </div>
              <div className={styles.formFieldStyle}>
                <Input
                  labelText={t('IPDBooking.Booking Date') || 'Booking Date'}
                  disabled
                  inlineClass={styles.disabledField}
                  type="date"
                  {...register(BOOKING_DATE)}
                />
              </div>
            </div>
            <div className={styles.formFields}>
              <div className={styles.formFieldStyle}>
                <Input
                  labelText={t('IPDBooking.Admitted Date') || 'Admitted Date'}
                  disabled
                  inlineClass={styles.disabledField}
                  type="date"
                  {...register(ADMITTED_DATE)}
                />
              </div>
              <div className={styles.formFieldStyle}>
                <Input
                  labelText={t('IPDBooking.Discharge Date') || 'Discharge Date'}
                  type="date"
                  {...register(DISCHARGE_DATE)}
                  min={new Date().toISOString().split('T')[0]}
                  // disabled={popData?.booked === true}
                  disabled={true}
                  inlineClass={styles.disabledField}
                  // inlineClass={
                  //   popData?.booked === true ? styles.disabledField : ""
                  // }
                />
              </div>
            </div>
            <div className={styles.formFields}>
              <div className={styles.formFieldStyle}>
                <div className={styles.inputFieldContainer}>
                  <label htmlFor={'ss'} className={styles.labelWrapper}>
                    {t('IPDBooking.Primary Doctor')}
                  </label>
                  <div className={styles.inlineItems}>
                    <Select
                      className={styles.selectInputField}
                      placeholder={
                        t('IPDBooking.Select Doctor') || 'Select Doctor'
                      }
                      closeMenuOnSelect={true}
                      components={{ DropdownIndicator }}
                      {...register(CONSULTED_DOCTOR)}
                      isSearchable={false}
                      isClearable={false}
                      isDisabled={popData?.booked === true}
                      value={watch(CONSULTED_DOCTOR) || ''}
                      options={ipdBookDoctorsData?.map((item: any) => {
                        return {
                          label: item?.doctor_name,
                          value: item?._id,
                        }
                      })}
                      onChange={(e: any) => {
                        setValue(CONSULTED_DOCTOR, e)
                      }}
                      maxMenuHeight={200}
                    />
                  </div>
                </div>
              </div>
              <div className={styles.formFieldStyle}></div>
            </div>
          </div>
          <div className={styles.btn}>
            {popData?.booked === false && (
              <Button title={t('Common.Confirm') || 'Confirm'} type="submit" />
            )}
          </div>
        </form>
      </div>
    </>
  )
}

export default IpdBookPopup
