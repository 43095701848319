

import {
  GET_ALL_PROFIT_LOSS_BALANCE,
} from "../../../constants/asyncActionsType";
import createAsyncThunkForSlice from "../../../utils/utils";
import { getAllProfitLossBalanceList,  } from "./profitLossCrud";

export const getAllProfitLossBalance = createAsyncThunkForSlice(
  GET_ALL_PROFIT_LOSS_BALANCE,
  getAllProfitLossBalanceList,
  {
    isToast: false,
  }
);


