import jsPDF from "jspdf";
import { breakTextIntoLines, utcToDate } from "../../utils/utils";
import styles from "./tableData.module.scss";
import { t } from "i18next";

export const viewHistoryPopupTableHeaderData: any = [
  {
    Header: t("RecentHistory.Date"),
    Cell: ({ row }: any) => {
      let date = utcToDate(row?.original?.updatedAt);
      return <p>{date}</p>;
    },
    canSort: true,
  },
  {
    Header: t("MedicalHistory.FORM"),
    Cell: ({ row }: any) => {
      let form = row?.original?.formbuilders;
      return <p>{form?.name ? form?.name : "-"}</p>;
    },
    canSort: true,
  },
  {
    Header: t("MedicalHistory.SUBMITTED QUESTIONNAIRE"),
    Cell: ({ row }: any) => {
      const handleClick = (id: any) => {
        // Create a new PDF document
        const doc = new jsPDF();
        let data = id?.questionnaire;
        // Set the document font size
        doc.setFontSize(12);
        // Loop through the data array and add each question and answer to the PDF document
        data.forEach((item: any, index: number) => {
          const { question, answer } = item;

          // old code
          // doc.text(`Question ${index + 1}: ${question}`, 10, 20 + index * 30)
          // doc.text(`Answer: ${answer}`, 10, 30 + index * 30)

          // new code from workd wrapping
          const questionChunks = breakTextIntoLines(
            doc,
            question,
            doc.internal.pageSize.getWidth()
          );
          const answerChunks = breakTextIntoLines(
            doc,
            answer,
            doc.internal.pageSize.getWidth()
          );

          questionChunks.forEach((chunk, chunkIndex) => {
            doc.text(chunk, 10, 20 + index * 30 + chunkIndex * 10); // Adjust vertical position
          });

          answerChunks.forEach((chunk, chunkIndex) => {
            doc.text(chunk, 10, 30 + index * 30 + chunkIndex * 10); // Adjust vertical position
          });
        });
        // Save the PDF document and open it in a new tab
        doc.save("questions-answers.pdf");
        window.open(doc.output("bloburl"), "_blank");
      };

      return (
        <>
          <button
            className={styles.buttonStyle}
            onClick={() => handleClick(row?.original)}
          >
            View Questionnaire
          </button>
        </>
      );
    },
  },
];
