import {
  ADD_VOUCHER,
  GET_ACCOUNT_NO,
  GET_VOUCHER,
  GET_REFERENCE_DOC,
  ADD_VOUCHER_DATA,
  GET_VOUCHER_DATA,
  DELETE_VOUCHER_DATA,
  UPDATE_VOUCHER_DATA,
  GET_VOUCHER_DATA_BY_ID,
  SUBMIT_VOUCHER_DATA,
  GET_VOUCER_NO,
  GET_VOUCHER_LOG_DATA,
  GET_ALL_DEPARTMENT,
  GET_ALL_BRANCH,
} from '../../../constants/asyncActionsType'
import createAsyncThunkForSlice from '../../../utils/utils'
import {
  addVoucherCrud,
  getAccountNo,
  getVoucherCrud,
  getReferenceDoc,
  addVoucherData,
  getVoucherData,
  deleteVoucherData,
  updateVoucherData,
  getVoucherDataById,
  getVoucherNo,
  submitVouchersData,
  getVoucherLogData,
  getAllDepartmentList,
  getAllBranchList,
} from './vouchersCrud'

// Add Voucher
export const addVoucher = createAsyncThunkForSlice(
  ADD_VOUCHER,
  addVoucherCrud,
  {
    isToast: true,
  }
)
export const addvoucherData = createAsyncThunkForSlice(
  ADD_VOUCHER_DATA,
  addVoucherData,
  {
    isToast: true,
  }
)

export const deletevoucherData = createAsyncThunkForSlice(
  DELETE_VOUCHER_DATA,
  deleteVoucherData,
  {
    isToast: true,
  }
)
export const updatevoucherData = createAsyncThunkForSlice(
  UPDATE_VOUCHER_DATA,
  updateVoucherData,
  {
    isToast: true,
  }
)
// Get Voucher
export const getVoucher = createAsyncThunkForSlice(
  GET_VOUCHER,
  getVoucherCrud,
  {
    // isToast: true,
  }
)
export const getvoucherData = createAsyncThunkForSlice(
  GET_VOUCHER_DATA,
  getVoucherData,
  {
    // isToast: true,
  }
)

export const getvoucherDataById = createAsyncThunkForSlice(
  GET_VOUCHER_DATA_BY_ID,
  getVoucherDataById,
  {
    isToast: true,
  }
)

export const getAccountNumber = createAsyncThunkForSlice(
  GET_ACCOUNT_NO,
  getAccountNo,
  {
    // isToast: true,
  }
)
export const getreferenceDoc = createAsyncThunkForSlice(
  GET_REFERENCE_DOC,
  getReferenceDoc,
  {
    // isToast: true,
  }
)
export const submitVoucherData = createAsyncThunkForSlice(
  SUBMIT_VOUCHER_DATA,
  submitVouchersData,
  {
    isToast: true,
  }
)
export const getvoucherNo = createAsyncThunkForSlice(
  GET_VOUCER_NO,
  getVoucherNo,
  {
    // isToast: true,
  }
)
export const getVoucherLog = createAsyncThunkForSlice(
  GET_VOUCHER_LOG_DATA,
  getVoucherLogData,
  {
    // isToast: true,
  }
)

export const getAllDepartment = createAsyncThunkForSlice(
  GET_ALL_DEPARTMENT,
  getAllDepartmentList
)

export const getAllBranch = createAsyncThunkForSlice(
  GET_ALL_BRANCH,
  getAllBranchList,
  {
    isToast: true,
  }
)
