import { GET_ALL_CRITERIA, GET_ALL_INTERNAL } from "../../../constants/asyncActionsType";
import createAsyncThunkForSlice from "../../../utils/utils";
import { getAllCriteriaList, getAllInternalList } from "./internalCrud";

export const getAllCriteria = createAsyncThunkForSlice(
    GET_ALL_CRITERIA,
    getAllCriteriaList,
    {
      isToast: false,
    }
  );

  export const getAllInternal = createAsyncThunkForSlice(
    GET_ALL_INTERNAL,
    getAllInternalList,
    {
      isToast : true,
    }
  )