import { createSlice } from "@reduxjs/toolkit";
import { ICrm } from "../../../interfaces/apiInterface";
import {
  crmAdminCriteriaAsync,
  crmAdminSearchAsync,
  crmAdminSentAsync,
} from "./crmAsyncActions";

export const initialState: ICrm = {
  isLoading: false,
  error: null,
  crmAdminData: [],
  crmAdminCheckStatus: [],
  crmAdminDatWithIds: [],
  crmSentData: [],
};

export const crmSlice = createSlice({
  name: "crm",
  initialState,
  reducers: {
    clearCrmAdminData: (state) => {
      state.crmAdminData = [];
    },
    setAdminCheckStatus: (state, action) => {
      const index = state.crmAdminCheckStatus.indexOf(action.payload);
      if (index > -1) {
        state.crmAdminCheckStatus.splice(index, 1);
      } else {
        state.crmAdminCheckStatus.push(action.payload);
      }
    },
    emptyAdminCheckStatus: (state) => {
      state.crmAdminCheckStatus = [];
    },
    getCrmAdminData: (state, action) => {
      state.crmAdminDatWithIds = [...state.crmAdminDatWithIds, action.payload];
    },
    removeCrmAdminData: (state, action) => {
      state.crmAdminDatWithIds.splice(action.payload, 1);
    },
    emptyCrmAdminData: (state) => {
      state.crmAdminDatWithIds = [];
    },
  },
  extraReducers(builder) {
    builder.addCase(crmAdminSearchAsync.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(crmAdminSearchAsync.fulfilled, (state, action) => {
      state.isLoading = false;
      state.crmAdminData = action.payload.data;
    });
    builder.addCase(crmAdminSearchAsync.rejected, (state, error) => {
      state.isLoading = false;
      state.error = error;
    });

    builder.addCase(crmAdminCriteriaAsync.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(crmAdminCriteriaAsync.fulfilled, (state) => {
      state.isLoading = false;
    });
    builder.addCase(crmAdminCriteriaAsync.rejected, (state, error) => {
      state.isLoading = false;
      state.error = error;
    });
    builder.addCase(crmAdminSentAsync.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(crmAdminSentAsync.fulfilled, (state, action) => {
      state.isLoading = false;
      state.crmSentData = action.payload.data;
    });
    builder.addCase(crmAdminSentAsync.rejected, (state, error) => {
      state.isLoading = false;
      state.error = error;
    });
  },
});

export const {
  clearCrmAdminData,
  setAdminCheckStatus,
  emptyCrmAdminData,
  emptyAdminCheckStatus,
  getCrmAdminData,
  removeCrmAdminData,
} = crmSlice.actions;
export default crmSlice.reducer;
