// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.viewHistoryPopup_popupContainer__V1YT7 {
  width: 400px;
  margin: auto;
  background: var(--white1);
  border-radius: 10px;
  padding: 10px;
}
.viewHistoryPopup_popupContainer__V1YT7 .viewHistoryPopup_closeIconStyle__H3dgl {
  cursor: pointer;
  float: right;
}
.viewHistoryPopup_popupContainer__V1YT7 .viewHistoryPopup_viewHistory__ibA\\+i {
  margin-top: 50px;
  text-align: center;
}
.viewHistoryPopup_popupContainer__V1YT7 .viewHistoryPopup_viewHistory__ibA\\+i .viewHistoryPopup_title__fqTrJ {
  text-align: center;
  font-weight: var(--font-semibold);
  font-size: var(--font-24);
  line-height: 36px;
  color: var(--black2);
}
.viewHistoryPopup_popupContainer__V1YT7 .viewHistoryPopup_viewHistory__ibA\\+i .viewHistoryPopup_dividerStyle__ylQVu {
  margin: 10px auto 30px auto;
  width: 80px;
  height: 3px;
  background: var(--green1);
}
.viewHistoryPopup_popupContainer__V1YT7 .viewHistoryPopup_viewHistory__ibA\\+i .viewHistoryPopup_tableContainer__L\\+fM5::-webkit-scrollbar {
  display: block !important;
  width: 7px !important;
  height: 7px !important;
}
.viewHistoryPopup_popupContainer__V1YT7 .viewHistoryPopup_viewHistory__ibA\\+i .viewHistoryPopup_tableContainer__L\\+fM5::-webkit-scrollbar-track {
  background: transparent !important;
}
.viewHistoryPopup_popupContainer__V1YT7 .viewHistoryPopup_viewHistory__ibA\\+i .viewHistoryPopup_tableContainer__L\\+fM5::-webkit-scrollbar-thumb {
  background-color: var(--grey7) !important;
  border-radius: 10px !important;
  border: 1px solid var(--grey7) !important;
}`, "",{"version":3,"sources":["webpack://./src/components/common/modal/view-history-popup/viewHistoryPopup.module.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;EAEA,YAAA;EACA,yBAAA;EACA,mBAAA;EACA,aAAA;AAAF;AACE;EACE,eAAA;EACA,YAAA;AACJ;AACE;EACE,gBAAA;EACA,kBAAA;AACJ;AAAI;EACE,kBAAA;EACA,iCAAA;EACA,yBAAA;EACA,iBAAA;EACA,oBAAA;AAEN;AAAI;EACE,2BAAA;EACA,WAAA;EACA,WAAA;EACA,yBAAA;AAEN;AACM;EACE,yBAAA;EACA,qBAAA;EACA,sBAAA;AACR;AACM;EACE,kCAAA;AACR;AAEM;EACE,yCAAA;EACA,8BAAA;EACA,yCAAA;AAAR","sourcesContent":[".popupContainer {\n  width: 400px;\n\n  margin: auto;\n  background: var(--white1);\n  border-radius: 10px;\n  padding: 10px;\n  .closeIconStyle {\n    cursor: pointer;\n    float: right;\n  }\n  .viewHistory {\n    margin-top: 50px;\n    text-align: center;\n    .title {\n      text-align: center;\n      font-weight: var(--font-semibold);\n      font-size: var(--font-24);\n      line-height: 36px;\n      color: var(--black2);\n    }\n    .dividerStyle {\n      margin: 10px auto 30px auto;\n      width: 80px;\n      height: 3px;\n      background: var(--green1);\n    }\n    .tableContainer {\n      &::-webkit-scrollbar {\n        display: block !important;\n        width: 7px !important;\n        height: 7px !important;\n      }\n      &::-webkit-scrollbar-track {\n        background: transparent !important;\n      }\n\n      &::-webkit-scrollbar-thumb {\n        background-color: var(--grey7) !important;\n        border-radius: 10px !important;\n        border: 1px solid var(--grey7) !important;\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"popupContainer": `viewHistoryPopup_popupContainer__V1YT7`,
	"closeIconStyle": `viewHistoryPopup_closeIconStyle__H3dgl`,
	"viewHistory": `viewHistoryPopup_viewHistory__ibA+i`,
	"title": `viewHistoryPopup_title__fqTrJ`,
	"dividerStyle": `viewHistoryPopup_dividerStyle__ylQVu`,
	"tableContainer": `viewHistoryPopup_tableContainer__L+fM5`
};
export default ___CSS_LOADER_EXPORT___;
