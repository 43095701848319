import { FC } from 'react'
import { Outlet } from 'react-router-dom'

interface ISubStoreLayoutLayout {}

const BranchStoreLayout: FC<ISubStoreLayoutLayout> = (props) => {
  return (
    <>
      <Outlet />
    </>
  )
}
export default BranchStoreLayout
