import { FC, useRef, useEffect } from 'react'
import { colors } from '../../../../constants/color'
import styles from './attachfiles.module.scss'
import { Attachments, CrossIcon } from '../../svg-components/index'
import { getDataUriFileSize, handleRefClick } from '../../../../utils/utils'
import { fileType } from '../../../../interfaces/interfaces'
import { UseFormSetValue } from 'react-hook-form'
import { t } from 'i18next'

interface IAttachFile {
  fileKey: any
  error?: string
  file: any
  setFile: any
  setValue: UseFormSetValue<any>
  isMultiSelect?: boolean
  defaultAttachments: fileType[]
  attachments: any
  setAttachments: any
  customClass?: string
  setErrors?: any
  clearErrors?: any
}

const AttachFile: FC<IAttachFile> = ({
  fileKey,
  setFile,
  setValue,
  isMultiSelect,
  attachments,
  setAttachments,
  setErrors,
  defaultAttachments,
  customClass,
  clearErrors,
}) => {
  const fileInputRef = useRef<HTMLInputElement>(null)

  const handleFileChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (event.target.files) {
      const newAttachments = [...defaultAttachments]
      for (let i = 0; i < event.target.files.length; i++) {
        const file: any = event.target.files[i]
        if (file?.size >= 2 * 1024 * 1024) {
          setErrors(fileKey, {
            type: 'custom',
            message: t('UploadImageValidators.UploadImgSize'),
          })
        }

        const getDataURI = () => {
          return new Promise<string>((resolve, reject) => {
            const reader = new FileReader()
            reader.onload = () => {
              const dataURI = reader.result as string
              resolve(dataURI)
            }
            reader.onerror = reject
            reader.readAsDataURL(file)
          })
        }

        try {
          const dataURI = await getDataURI()
          newAttachments.push({
            name: file.name,
            data_uri: dataURI,
          })
        } catch (error) {}
      }
      setAttachments(newAttachments)
      if (setValue && fileKey && newAttachments) {
        setValue(fileKey, newAttachments)
      }
    }
  }

  const resetFileInput = () => {
    if (fileInputRef.current) {
      fileInputRef.current.value = ''
    }
  }

  const handleRemoveAttachment = (index: number) => {
    const newAttachments = [...attachments]
    newAttachments.splice(index, 1)
    resetFileInput() // reset the file input value
    setAttachments(newAttachments)
    if (setValue && fileKey && newAttachments) {
      setValue(fileKey, newAttachments)
    }
  }

  const handleChange = (e: any) => {
    let file = e.target.files[0].name
    setFile(file)
    if (setValue && fileKey && file) {
      setValue(fileKey, file)
    }
  }
  useEffect(() => {
    setValue(fileKey, defaultAttachments || [])
  }, [defaultAttachments])

  useEffect(() => {
    const bigSizeFiles =
      defaultAttachments &&
      defaultAttachments.length > 0 &&
      defaultAttachments.find((item: any) => {
        if (item?.data_uri && item.data_uri?.length) {
          return getDataUriFileSize(item?.data_uri, true) >= 2 * 1024 * 1024
        }
      })
    if (bigSizeFiles) {
      setErrors(fileKey, {
        type: 'custom',
        message: t('UploadImageValidators.UploadImgSize'),
      })
    } else {
      clearErrors(fileKey)
    }
  }, [defaultAttachments])

  return (
    <div className={styles.attachmentsContainer}>
      <div className={styles.attachmentsIconListContainer}>
        <span onClick={() => handleRefClick(fileInputRef)}>
          <input
            type="file"
            ref={fileInputRef}
            accept="image/png, image/jpeg, image/jpg, application/pdf, application/msword"
            onChange={isMultiSelect ? handleFileChange : handleChange}
            style={{ display: 'none' }}
          />
          <Attachments
            fillColor={colors.white1}
            fillColor1={colors.green1}
            customClass={styles.attchmentsIcon}
          />
        </span>
        <div className={styles.attachedFileListsContainer}>
          {defaultAttachments &&
            defaultAttachments.length > 0 &&
            defaultAttachments.map((item: fileType, index: number) => {
              if (item.name.endsWith('.png')) {
                // Display images using img tag
                return (
                  <div className={styles.attachedFile} key={index}>
                    <img
                      src={item.data_uri}
                      alt={item.name}
                      className={styles.attachedImage}
                    />
                    <span
                      onClick={() => handleRemoveAttachment(index)}
                      className={styles.crossIcon}
                    >
                      <CrossIcon
                        fillColor={colors.white1}
                        fillColor1={colors.red1}
                      />
                    </span>
                  </div>
                )
              } else if (
                item.name.endsWith('.pdf') ||
                item.name.endsWith('.PDF')
              ) {
                // Display PDF files using iframe
                return (
                  <div className={styles.attachedFile} key={index}>
                    <iframe
                      title={item.name}
                      src={item.data_uri}
                      className={styles.attachedPDF}
                    />
                    <span
                      onClick={() => handleRemoveAttachment(index)}
                      className={styles.crossIcon}
                    >
                      <CrossIcon
                        fillColor={colors.white1}
                        fillColor1={colors.red1}
                      />
                    </span>
                  </div>
                )
              } else if (
                item.name.endsWith('.doc') ||
                item.name.endsWith('.docx') ||
                item.name.endsWith('.DOC') ||
                item.name.endsWith('.DOCX')
              ) {
                // Display Word documents using iframe
                return (
                  <div className={styles.attachedFile} key={index}>
                    <iframe
                      title={item.name}
                      src={item.data_uri}
                      className={styles.attachedWordDoc}
                    />
                    <span
                      onClick={() => handleRemoveAttachment(index)}
                      className={styles.crossIcon}
                    >
                      <CrossIcon
                        fillColor={colors.white1}
                        fillColor1={colors.red1}
                      />
                    </span>
                  </div>
                )
              } else if (
                item.name.endsWith('.jpg') ||
                item.name.endsWith('.jpg')
              ) {
                return (
                  <div className={styles.attachedFile} key={index}>
                    <img
                      src={item.data_uri}
                      alt={item.name}
                      className={styles.attachedImage}
                    />
                    <span
                      onClick={() => handleRemoveAttachment(index)}
                      className={styles.crossIcon}
                    >
                      <CrossIcon
                        fillColor={colors.white1}
                        fillColor1={colors.red1}
                      />
                    </span>
                  </div>
                )
              } else if (
                item.name.endsWith('.jpeg') ||
                item.name.endsWith('.jpeg')
              ) {
                return (
                  <div className={styles.attachedFile} key={index}>
                    <img
                      src={item.data_uri}
                      alt={item.name}
                      className={styles.attachedImage}
                    />
                    <span
                      onClick={() => handleRemoveAttachment(index)}
                      className={styles.crossIcon}
                    >
                      <CrossIcon
                        fillColor={colors.white1}
                        fillColor1={colors.red1}
                      />
                    </span>
                  </div>
                )
              } else {
                // For other file types, display their names
                return (
                  <div className={styles.attachedFile} key={index}>
                    <span
                      className={[styles.attachedFileName, customClass].join(
                        ' '
                      )}
                    >
                      {item.name}
                    </span>
                    <span
                      onClick={() => handleRemoveAttachment(index)}
                      className={styles.crossIcon}
                    >
                      <CrossIcon
                        fillColor={colors.white1}
                        fillColor1={colors.red1}
                      />
                    </span>
                  </div>
                )
              }
            })}
        </div>
      </div>
    </div>
  )
}

export default AttachFile
