import { useAppDispatch } from '../../hooks'
import Button from '../../components/common/button/Button'
import { setMessage } from '../../redux/features/toast/toastSlice'
import { warning } from '../../constants/data'
import moment from 'moment'
import styles from '../../pages/ipd/ipd-booking/ipdBooking.module.scss'
import { t } from 'i18next'

export const ipdBookingTableHeaderData: any = [
  {
    Header: t('IPDBookingHeader.BED NO'),
    accessor: 'bed_no',
    Cell: (props: any) => {
      return (
        <>
          {props?.row?.original?.bed_no ? (
            <span>{props?.row?.original?.bed_no}</span>
          ) : (
            '-'
          )}
        </>
      )
    },
  },
  {
    Header: t('RcptTableHeader.STATUS'),
    // accessor: "status",
    disableSortBy: true,
    Cell: (props: any) => {
      return (
        <>
          {props?.row?.original?.booked === false ? (
            <span className={styles.availableLabel}>
              {t('IPDBooking.Available')}
            </span>
          ) : props?.row?.original?.booked === true ? (
            <span className={styles.notAvailableLabel}>
              {t('IPDBooking.UnAvailable')}
            </span>
          ) : (
            '-'
          )}
        </>
      )
    },
  },
  {
    Header: t('IPDBookingHeader.CHARGE/DAY'),
    accessor: 'charges',
    Cell: (props: any) => {
      return (
        <>
          {props?.row?.original?.charges ? (
            <span>{props?.row?.original?.charges}</span>
          ) : (
            '-'
          )}
        </>
      )
    },
  },
  {
    Header: t('IPDBookingHeader.BOOK'),
    // accessor: "department",
    disableSortBy: true,
    Cell: (props: any) => {
      const dispatch = useAppDispatch()

      const handleBook = () => {
        if (
          props.getPropsData?.patient_name === undefined ||
          props.getPropsData?.admissionDate?.length === 0
        ) {
          dispatch(
            setMessage({
              message:
                t('IPDBooking.PleaseSelectPatientAdmission') ||
                'Please select patient and admission date',
              type: warning,
            })
          )
        } else {
          const bookModalData = {
            ...props.row.original,
            patient_id: props.getPropsData?.patient_id,
            patient_name: props.getPropsData?.patient_name,
            ipd_id: props.getPropsData?._id,
            admissionDate: props.getPropsData?.admissionDate,
          }
          props.onRowClick(bookModalData)
        }
      }

      return (
        <>
          {props?.row?.original?.booked === false ? (
            <Button
              title={t('Common.Book') || 'Book'}
              customClass={styles.buttonOutline}
              type="button"
              handleClick={handleBook}
            />
          ) : props?.row?.original?.booked === true ? (
            <Button
              title={t('Common.View') || 'View'}
              customClass={styles.buttonOutline}
              type="button"
              handleClick={handleBook}
            />
          ) : (
            '-'
          )}
        </>
      )
    },
  },
  {
    Header: t('IPDBookingHeader.ACTIVE DATE'),
    accessor: (row: any) => {
      return moment(row?.admission_date).format('DD MMM YYYY')
    },
    Cell: (props: any) => {
      const convertDate = moment(props?.row?.original?.admission_date).format(
        'DD MMM YYYY'
      )
      return (
        <>
          {props?.row?.original?.admission_date ? (
            <span>{convertDate}</span>
          ) : (
            '-'
          )}
        </>
      )
    },
  },
  {
    Header: t('IPDBookingHeader.INACTIVE DATE'),
    accessor: (row: any) => {
      return moment(row?.discharge_date).format('DD MMM YYYY')
    },
    Cell: (props: any) => {
      const convertDate = moment(props?.row?.original?.discharge_date).format(
        'DD MMM YYYY'
      )
      return (
        <>
          {props?.row?.original?.discharge_date ? (
            <span>{convertDate}</span>
          ) : (
            '-'
          )}
        </>
      )
    },
  },
  {
    Header: t('IPDBookingHeader.NOTE'),
    // accessor: "sr_no",
    Cell: (props: any) => {
      return (
        <>
          <span
            className={styles.viewLink}
            onClick={() => props.onPopClose(props?.row?.original)}
          >
            {t('Common.View')}
          </span>
        </>
      )
    },
  },
]
