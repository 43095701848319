import { FC, useState, useRef, useEffect } from 'react'
import {
  AbsentIcon,
  PresentIcon,
} from '../../../../components/common/svg-components'
import { useAppDispatch } from '../../../../hooks'
import styles from './attendanceList.module.scss'
import { setValueInAttendance } from '../../../../redux/features/attendance/attendanceSlice'
interface IAttendanceList {
  onOutSIdeClick?: (e: any) => void
  setOnDesh?: (boolean: boolean) => void
  customClassContainer?: any
  customClassContent?: any
  handlePresentChange?: (e: React.SyntheticEvent<EventTarget>) => void
  handleAbsentChange?: (e: React.SyntheticEvent<EventTarget>) => void
  style?: any
  handleChange: (e: React.SyntheticEvent<EventTarget>) => void
  onClick: (e: React.SyntheticEvent<EventTarget>) => void
}

const AttendanceList: FC<IAttendanceList> = ({ handleChange, onClick }) => {
  const [value, setValue] = useState()
  const dispatch = useAppDispatch()

  const handleValueChange = (e: any) => {
    setValue(e)
    handleChange(e)
    dispatch(setValueInAttendance(e))
  }

  return (
    <>
      <div
        className={styles.mainContainer}
        onClick={(e: any) => {
          e.stopPropagation()
        }}
      >
        <div className={styles.attendanceList} onClick={onClick}>
          <div
            className={styles.optionList}
            onClick={(e) => handleValueChange('PRESENT')}
          >
            <PresentIcon customClass={styles.iconStyle} />
            <p className={styles.content}>PRESENT</p>
          </div>
          <div
            className={styles.optionList}
            onClick={(e) => handleValueChange('ABSENT')}
          >
            <AbsentIcon customClass={styles.iconStyleAbsent} />
            <p className={styles.content}>ABSENT</p>
          </div>
        </div>
      </div>
    </>
  )
}

export default AttendanceList
