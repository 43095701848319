import styles from './addNewServices.module.scss'
import Button from '../../components/common/button/Button'
import { useAppDispatch, useAppSelector } from '../../hooks'

import {
  setTestAddNewText,
  handleAddedNewService,
  handleRemoveNewService,
} from '../../redux/features/invoice-module/invoiceSlice'

import {
  handleAddedService,
  handleRemoveAddedService,
  setTestAddText,
} from '../../redux/features/radiology/radiologySlice'
import {
  handleAddedNewServices,
  handleRemoveAddedNewService,
} from '../../redux/features/ipd-invoice/ipdInvoiceSlice'
import { t } from 'i18next'

// receptionist new service data
export const invoiceAddServiceHeaderData: any = [
  // {
  //   Header: "SR NO.",
  //   accessor: "discount",
  // },
  {
    Header: t('InvoiceService.SERVICE'),
    accessor: 'name',
  },
  {
    Header: t('InvoiceService.DESCRIPTION'),
    Cell: (props: any) => {
      return (
        <>
          {props?.row?.original?.notes?.length > 0 ? (
            <span
              className={styles.viewPopupLink}
              onClick={() => {
                props?.onClick(props?.row?.original)
              }}
            >
              {t('Common.View')}
            </span>
          ) : (
            '-'
          )}
        </>
      )
    },
  },

  {
    Header: t('InvoiceService.AMOUNT'),
    accessor: 'price',
  },

  {
    Header: t('InvoiceService.ADD TO INVOICE'),
    Cell: (props: any) => {
      const dispatch = useAppDispatch()
      const { popupServiceData, addTestText } = useAppSelector(
        (state) => state.invoice
      )

      const handleTestData = () => {
        const checkCommonIds = popupServiceData?.find(
          (s: any) => s._id === props.row.original._id
        )
        let profileTestData = {
          quantity: 1,
          unitPrice: props.row.original?.price,
          test_name: props.row.original?.name,
          discount: props.row.original?.discount
            ? props.row.original?.discount
            : 0,
          discountPercantage: props.row.original?.discount
            ? (props.row.original?.discount * 100) / props.row.original?.price
            : 0,
          isBillable: 'Yes',
          ...checkCommonIds,
        }
        dispatch(handleAddedNewService(profileTestData))

        const updateRow = {
          ...checkCommonIds,
          id: props.row.original?.service_no,
          name: props.row.original?.name,
          isBillable: 'Yes',
          price: props.row.original?.price,
          notes: props.row.original?.notes,
          quantity: 1,
          type: 'diagnosis',
          unitPrice: props.row.original?.price,
        }

        // IPD Invoice Add New Service Action
        dispatch(handleAddedNewServices(updateRow))
      }

      const handleRemove = () => {
        dispatch(handleRemoveNewService(props?.row?.original?._id))

        // IPD Invoice Remove New Service Action
        dispatch(handleRemoveAddedNewService(props?.row?.original?._id))
      }

      return (
        <>
          <div className={styles.addTestPopupAddToJob}>
            {!addTestText.includes(props.row.original._id) ? (
              <Button
                title={t('InvoiceService.Add') || 'Add'}
                customClass={styles.addedServiceButton}
                handleClick={(e) => {
                  dispatch(setTestAddNewText(props.row.original._id))
                  handleTestData()
                }}
              />
            ) : (
              <Button
                title={t('InvoiceService.Added') || 'Added'}
                customClass={styles.addServiceButton}
                handleClick={(e) => {
                  dispatch(setTestAddNewText(props.row.original._id))
                  handleRemove()
                }}
              />
            )}
          </div>
        </>
      )
    },
  },
]

// radiology new service data

export const radiologyAddServiceHeaderData: any = [
  {
    Header: t('InvoiceService.SERVICE'),
    accessor: 'name',
  },
  {
    Header: t('InvoiceService.DESCRIPTION'),
    Cell: (props: any) => {
      return (
        <>
          {props?.row?.original?._id ? (
            <span
              className={styles.viewPopupLink}
              onClick={() => {
                props?.onClick(props?.row?.original)
              }}
            >
              {t('Common.View')}
            </span>
          ) : (
            '-'
          )}
        </>
      )
    },
  },

  {
    Header: t('InvoiceService.AMOUNT'),
    accessor: 'sell_price',
  },

  {
    Header: t('InvoiceService.ADD TO INVOICE'),
    Cell: (props: any) => {
      const dispatch = useAppDispatch()
      const { radiologyTestDataList, addTestText } = useAppSelector(
        (state) => state.radiology
      )

      const handleTestData = () => {
        const checkCommonIds = radiologyTestDataList?.find(
          (s: any) => s._id === props.row.original._id
        )
        let profileTestData = {
          quantity: 1,
          unitPrice: props.row.original.sell_price,
          test_name: props.row.original.name,
          billable: 'Yes',
          ...checkCommonIds,
        }

        dispatch(handleAddedService(profileTestData))
      }

      const handleRemove = () => {
        dispatch(handleRemoveAddedService(props?.row?.original?._id))
      }

      return (
        <>
          <div className={styles.addTestPopupAddToJob}>
            {!addTestText.includes(props.row.original._id) ? (
              <Button
                title={t('InvoiceService.Add') || 'Add'}
                customClass={styles.addedServiceButton}
                handleClick={() => {
                  dispatch(setTestAddText(props.row.original._id))
                  handleTestData()
                }}
              />
            ) : (
              <Button
                title={t('InvoiceService.Added') || 'Added'}
                customClass={styles.addServiceButton}
                handleClick={() => {
                  dispatch(setTestAddText(props.row.original._id))
                  handleRemove()
                }}
              />
            )}
          </div>
        </>
      )
    },
  },
]
