import { useTranslation } from 'react-i18next'
import Button from '../../../components/common/button/Button'
import styles from './styles.module.scss'
import { Label } from '../../../components/common/label'
import Select from 'react-select'
import { useEffect, useState } from 'react'
import { DropdownIndicator } from '../../../components/common/dropdown-indicator/DropdownIndicator'
import { useAppDispatch, useAppSelector } from '../../../hooks'
import { IImportPatientEMR, fileType } from '../../../interfaces/interfaces'
import { useForm } from 'react-hook-form'
import { BRANCH_ID, EMR_FILE } from '../import-constant'
import { importValidation } from './validators'
import AttachFiles from '../../../components/common/attach-files/single-file/AttachSingleFile'
import { dataURI } from '../../../utils/utils'
import { requestGenerator } from '../../../utils/payloadGenerator'
import { importPatientEmrUtility } from '../../../redux/features/import-utility/importUtilityAsyncActions'
import { clearImportData } from '../../../redux/features/import-utility/importUtilitySlice'
import { getAllBranch } from '../../../redux/features/branch/branchAsyncActions'

const PatientEmrImportModule = () => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const [branchOptions, setBranchOptions] = useState([])
  const [emrFile, setEmrFile] = useState({ name: '', data_uri: '' })
  const { inValidData } = useAppSelector((state) => state.import)
  const { branchData } = useAppSelector((state) => state.branch)
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    trigger,
    formState: { errors },
  } = useForm<IImportPatientEMR>({})

  useEffect(() => {
    if (branchData && branchData) {
      const branches = branchData?.map((item: any) => {
        return {
          label: item?.name,
          value: item?._id,
        }
      })
      setBranchOptions(branches)
      setValue(BRANCH_ID, branches[0])
    }
  }, [branchData, setValue])

  const emrField = watch(EMR_FILE)
  const fileName = emrField?.[0]

  useEffect(() => {
    let data = {
      page: 1,
      pageSize: 1000,
    }
    dispatch(getAllBranch(requestGenerator(data)))
  }, [dispatch])

  useEffect(() => {
    const fileList: fileType = { name: '', data_uri: '' }
    const getDataURI = async (fileName: File) => {
      try {
        const result = await dataURI(fileName)
        fileList.data_uri = result
        fileList.name = fileName.name

        setEmrFile(fileList)
      } catch (error) {
        console.log({ error })
      }
    }
    if (fileName) {
      getDataURI(fileName)
    }
  }, [fileName])

  const onSubmit = (data: any) => {
    const payload = {
      [BRANCH_ID]: data[BRANCH_ID]?.value,
      [EMR_FILE]: emrFile,
    }
    dispatch(importPatientEmrUtility(requestGenerator(payload))).then((e) => {
      setValue(EMR_FILE, undefined)
      setEmrFile({ name: '', data_uri: '' })
    })
  }
  useEffect(() => {
    if (inValidData?.length !== 0 && emrFile.name) {
      dispatch(clearImportData())
    }
  }, [dispatch, emrFile])

  return (
    <form className={styles.container} onSubmit={handleSubmit(onSubmit)}>
      <div className={styles.importFieldContainer}>
        <div className={styles.inputFieldContainer}>
          <Label
            labelText={t('Header.Branch')}
            requiredField={true}
            flexBasis="30%"
          />
          <div className={styles.inlineItems}>
            <Select
              className={styles.selectInputField}
              placeholder={
                t('ManageStaff.Branch_placeholder') || `Select Branch`
              }
              closeMenuOnSelect={true}
              value={watch(BRANCH_ID)}
              {...register(BRANCH_ID, importValidation[BRANCH_ID])}
              onChange={(e: any) => {
                setValue(BRANCH_ID, e)
                trigger(BRANCH_ID)
              }}
              components={{ DropdownIndicator }}
              options={branchOptions}
              isSearchable={true}
              maxMenuHeight={200}
            />
            {errors[BRANCH_ID]?.message && (
              <p className="dashboardFormError">
                {errors[BRANCH_ID]?.message as any}
              </p>
            )}
          </div>
        </div>
      </div>
      <div className={styles.importFieldContainer}>
        <div className={styles.inputFieldContainer}>
          <Label
            labelText={t('Common.Upload')}
            requiredField={true}
            flexBasis="30%"
          />
          <div className={styles.inlineItems}>
            <AttachFiles
              register={register}
              fileKey={EMR_FILE}
              id={EMR_FILE}
              fileList={emrFile}
              validation={importValidation[EMR_FILE]}
              isName={true}
              isDocument={true}
              showCsv={true}
            />
            {errors[EMR_FILE] && (
              <p className="errorText">{errors[EMR_FILE].message as any}</p>
            )}
          </div>
        </div>
      </div>
      <div className={styles.buttonContainer}>
        <Button title={t('Common.Submit') || 'Submit'} />
      </div>
    </form>
  )
}

export default PatientEmrImportModule
