import { FC } from 'react'
import styles from './patientCard.module.scss'

interface IPatientCard {
  image: any
  label: any
  value: any
  graph: any
}

const PatientCard: FC<IPatientCard> = ({ image, label, value, graph }) => {
  return (
    <div className={styles.container}>
      <div className={styles.patientCount}>
        <img src={image} alt="patient" />
        <div className={styles.labelValue}>
          <span>{value}</span>
          <span>{label}</span>
        </div>
      </div>
      <img src={graph} alt="patientGraph" className={styles.graph} />
    </div>
  )
}

export default PatientCard
