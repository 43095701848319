import React from "react";
import styles from "./style.module.scss";

interface ITextAreaProps
  extends React.DetailedHTMLProps<
    React.TextareaHTMLAttributes<HTMLTextAreaElement>,
    HTMLTextAreaElement
  > {
  label: string;
  showErrors?: boolean;
  errorMessage?: string;
  requiredField?: boolean;
  customClass?: string;
}

export const TextArea = React.forwardRef<any, ITextAreaProps>(
  (
    { label, showErrors, errorMessage, requiredField, customClass, ...restTextAreaProps },
    ref
  ) => {
    return (
      <>
        <div className={[styles.formTextFields, customClass]?.join(' ')}>
          {label?.length && (
            <label className={styles.labelWrapper}>
              {label}
              {requiredField && <span className={styles.requiredField}>*</span>}
            </label>
          )}
          <div className={styles.textContainer}>
            <textarea ref={ref} {...restTextAreaProps} />
            {!!showErrors && (
              <div className={styles.errorContainer}>
                <span className={styles.extraSpan} />
                <p className="dashboardFormError">{errorMessage}</p>
              </div>
            )}
          </div>
        </div>
      </>
    );
  }
);
