import moment from 'moment'
import {
  ATTENDENCE,
  END_DATE,
  END_TIME,
  LEAVE_STATUS,
  MONTH,
  REASON_FOR_LEAVE,
  SALARY_TYPE,
  START_DATE,
  START_TIME,
  YEAR,
} from '../../../../constants/markAllDayConstants'
import { IMarkAllDaysModalForm } from '../../../../interfaces/markAllDayInterface'
import { isDateBetweenTwoDates } from '../../../../utils/utils'
import { t } from 'i18next'

export const markAllDayValidator = {
  [ATTENDENCE]: {
    required: t('Attendance.prsent_absent_validation'),
  },
  [MONTH]: {
    required: t('Attendance.month_validation'),
  },
  [YEAR]: {
    required: t('Attendance.year_validation'),
  },
  [START_TIME]: {
    required: t('BookingConfirmationValidators.START_TIME'),
  },
  [END_TIME]: {
    required: t('BookingConfirmationValidators.RECURRING_END_TIME'),
  },
  [START_DATE]: {
    required: t('BookingConfirmationValidators.RECURRING_START_DATE'),
    validate: {
      handleTime: (value: string, values: IMarkAllDaysModalForm) => {
        const startTime = values

        function getMonthFromString() {
          return new Date(Date.parse(values?.month + ' 1, 2012')).getMonth() + 1
        }

        const minDateValue = `${values?.year}-0${getMonthFromString()}-01`
        const firstDateOfMonth = (date = new Date()) =>
          new Date(date.getFullYear(), date.getMonth(), 1)

        const lastDateOfMonth = (date = new Date()) =>
          new Date(date.getFullYear(), date.getMonth() + 1, 0)

        const date1 = firstDateOfMonth(
          new Date(minDateValue)
        ).toLocaleDateString()
        const date2 = lastDateOfMonth(
          new Date(minDateValue)
        ).toLocaleDateString()
        const date3 = moment(startTime?.start_date).format('DD/MM/YYYY')

        if (!isDateBetweenTwoDates(date1, date2, date3)) {
          return t('Attendance.selected_date_of_month')
        }
      },
    },
  },
  [END_DATE]: {
    required: t('Campaigns.end_date_validation'),
    validate: {
      handleTime: (value: string, values: IMarkAllDaysModalForm) => {
        const startTime = values
        function getMonthFromString() {
          return new Date(Date.parse(values?.month + ' 1, 2024')).getMonth() + 1
        }

        const minDateValue = `${values?.year}-0${getMonthFromString()}-01`
        const firstDateOfMonth = (date = new Date()) =>
          new Date(date.getFullYear(), date.getMonth(), 1)

        const lastDateOfMonth = (date = new Date()) =>
          new Date(date.getFullYear(), date.getMonth() + 1, 0)

        const date1 = firstDateOfMonth(
          new Date(minDateValue)
        ).toLocaleDateString()
        const date2 = lastDateOfMonth(
          new Date(minDateValue)
        ).toLocaleDateString()
        const date3 = moment(startTime?.end_date).format('DD/MM/YYYY')

        if (!isDateBetweenTwoDates(date1, date2, date3)) {
          return t('Attendance.selected_date_of_month')
        }

        const startDate = startTime?.start_date
        const endDate = startTime?.end_date
        const ab = () => {
          return new Date(startDate) > new Date(endDate)
        }
        if (ab()) {
          return t('Attendance.end_date_value_validation')
        }
      },
    },
  },
  [SALARY_TYPE]: {
    // required: 'Please select leave type',
    validate: {
      handleTime: (value: any, values: IMarkAllDaysModalForm) => {
        if (values?.isPaid !== 'UNPAID' && value === undefined) {
          return t('Attendance.leave_type_validation')
        } else return
      },
    },
  },
  [LEAVE_STATUS]: {
    required: t('LeaveManagement.status_validation'),
  },
  [REASON_FOR_LEAVE]: {
    required: t('Attendance.reason_leave'),
  },
}
