import { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { monthOptions, reactSelectStyle } from '../../../constants/data'
import ButtonGroups from '../../../components/common/button-group/ButtonGroups'
import { utils, writeFile, WorkBook, WorkSheet } from 'xlsx'
import { SearchIcon } from '../../../components/common/svg-components'
import Pagination from '../../../components/common/pagination/Pagination'
import { useAppDispatch, useAppSelector } from '../../../hooks'
import { Cols } from '../../employees/employeeInterface'
import Select from 'react-select'
import {
  Column,
  TableOptions,
  useGlobalFilter,
  useSortBy,
  useTable,
} from 'react-table'
import { getAllPayroll } from '../../../redux/features/payroll/payrollAsyncActions'
import { requestGenerator } from '../../../utils/payloadGenerator'
import TableV3 from '../../../components/common/table/tableV3/TableV3'
import Loader from '../../../components/common/spinner/Loader'
import { getLastNYears, trimValue } from '../../../utils/utils'
import styles from './payroll.module.scss'
import { payrollTableHeaderData } from '../../../constants/table-data/payrollTableHeader'
import { getAllDepartment } from '../../../redux/features/department/departmentAsyncActions'
import Popup from '../../../components/common/popup/Popup'
import GeneratePayrollPopup from '../../../components/common/modal/generate-payroll-popup/GeneratePayrollPopup'
import Button from '../../../components/common/button/Button'
import jsPDF from 'jspdf'
import { t } from 'i18next'

const Payroll = () => {
  const dispatch = useAppDispatch()
  const [dataPerPage, setDataPerPage] = useState<number>(10)
  const [totalPage, setTotalPage] = useState<number>(0)
  const [pageIndex, setPageIndex] = useState<number>(1)
  const [editId, setEditId] = useState()

  const [deletePopData, setDeletePopData] = useState({})
  const [showDelete, setShowDelete] = useState<boolean>(false)
  const [payrollSearch, setPayrollSearch] = useState<string>('')

  const currentMonthInNum = new Date().getMonth() + 1

  const yearOption = [
    {
      value: new Date().getFullYear() - 1,
      label: new Date().getFullYear() - 1,
    },
    {
      value: new Date().getFullYear(),
      label: new Date().getFullYear(),
    },
  ]
  const [month, setMonth] = useState<any>({
    label: monthOptions?.[currentMonthInNum - 1]?.label,
    value: monthOptions?.[currentMonthInNum - 1]?.value,
  })
  const [year, setYear] = useState<any>({
    label: yearOption?.[1]?.label,
    value: yearOption?.[1]?.value,
  })
  const [totalData, setTotalData] = useState(0)
  const { isLoading, allPayrollData } = useAppSelector((state) => state.payroll)
  const data: Cols[] = allPayrollData
  const columns: Column<Cols>[] = payrollTableHeaderData
  const options: TableOptions<Cols> = { data, columns }
  const { departmentData, departmentLoader } = useAppSelector(
    (state) => state.department
  )
  const { userData } = useAppSelector((state) => state.login)
  const [showGenerate, setShowGenerate] = useState(false)
  const [departmentId, setDepartmentId] = useState<any>()

  const {
    state,
    // @ts-ignore
    setGlobalFilter,
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable(options, useGlobalFilter, useSortBy)

  // getAllDepartment API calls
  useEffect(() => {
    let payloadData = {
      search: '',
      page: 1,
      pageSize: 1000,
    }
    dispatch(getAllDepartment(requestGenerator(payloadData))).then((e: any) => {
      if (e?.type === 'department/getAllDepartment/fulfilled') {
        setDepartmentId({
          label: e?.payload?.data[0]?.name,
          value: e?.payload?.data[0]?._id,
        })
      }
    })
  }, [])

  useEffect(() => {
    let payloadData = {
      page: pageIndex,
      pageSize: dataPerPage,
      search: payrollSearch,
      department_id: departmentId?.value,
      month: month?.label ? month?.label : undefined,
      year: year?.value ? year?.value : undefined,
      is_active: true,
    }
    if (!showGenerate) {
      dispatch(getAllPayroll(requestGenerator(payloadData))).then(
        (result: any) => {
          setTotalPage(result.payload.lastPage)
          setTotalData(result.payload.total)
        }
      )
    }
  }, [dispatch, dataPerPage, pageIndex, showGenerate, month, year])
  const pageIndexArray = () => {
    let pageIndexOptions = []
    for (let i = 1; i <= totalPage; i++) {
      pageIndexOptions.push(i)
    }
    return pageIndexOptions
  }
  const pageIndexOptions = pageIndexArray()
  const handleDownloadExcel = () => {
    const trimmedData: any = []
    allPayrollData.forEach((element: any) => {
      trimmedData.push({
        emp_id: element.emp_id,
        name: element.name,
        month: element.month,
        year: element.year,
        salary_type: element.salary_type,
        net_salary: element.net_salary,
        hour_clocked: element.hour_clocked,
        basic_salary: element.basic_salary,
        hour_rate: element.hour_rate,
        expense_claim: element.expense_claim,
        is_active: element.is_active,
        status: element.status,
        status_type: element.status_type,
        total_allowance: element.total_allowance,
        total_deduction: element.total_deduction,
        total_salary: element.total_salary,
      })
    })
    const worksheet: WorkSheet = utils.json_to_sheet(trimmedData)
    const workbook: WorkBook = utils.book_new()
    utils.book_append_sheet(workbook, worksheet, 'Payroll')
    const excelBuffer = writeFile(workbook, 'Payroll.xlsx', { type: 'buffer' })
    const link = document.createElement('a')
    link.href = window.URL.createObjectURL(new Blob([excelBuffer]))
    link.download = 'payroll.xlsx'
    // link.click();
  }
  const handleEdit = (row: any) => {
    setEditId(row)
  }
  const handleDeleteModalOpen = (id: any) => {
    setDeletePopData(id)
    setShowDelete((prevState) => !prevState)
  }
  const handleDeleteModalClose = () => {
    setShowDelete((prevState) => !prevState)
  }

  useEffect(() => {
    setGlobalFilter('')
    setPageIndex(1)
    const requestData = {
      search: payrollSearch,
      department_id: departmentId?.value,
      month: month?.label ? month?.label : undefined,
      year: year?.value ? year?.value : undefined,
      is_active: true,
      page: 1,
      pageSize: dataPerPage,
    }
    dispatch(getAllPayroll(requestGenerator(requestData))).then(
      (result: any) => {
        setTotalPage(result.payload.lastPage)
        setTotalData(result.payload.total)
      }
    )
  }, [departmentId?.value])

  const handleSearch = () => {
    setPageIndex(1)
    let requestData = {
      search: payrollSearch,
      month: month?.label ? month?.label : undefined,
      year: year?.value ? year?.value : undefined,
      department_id: departmentId?.value,
      is_active: true,
      page: pageIndex,
      pageSize: dataPerPage,
    }
    dispatch(getAllPayroll(requestGenerator(requestData))).then((result: any) =>
      setTotalPage(result.payload.lastPage)
    )
  }

  // useEffect(() => {
  //   if ((month && year) || (!month && !year)) {
  //     let requestData = {
  //       search: payrollSearch,
  //       page: pageIndex,
  //       pageSize: dataPerPage,
  //       department_id: departmentId?.value,
  //       is_active: true,
  //       month: month?.label ? month?.label : undefined,
  //       year: year?.value ? year?.value : undefined,
  //       // range: {
  //       //   fromDate: searchDate,
  //       //   toDate: toSearchDate,
  //       // },
  //     }
  //     dispatch(getAllPayroll(requestGenerator(requestData))).then(
  //       (result: any) => {
  //         setTotalPage(result.payload.lastPage)
  //         setTotalData(result.payload.total)
  //       }
  //     )
  //   }
  // }, [month, year])

  const downloadPdf = () => {
    const doc: any = new jsPDF('p', 'pt', 'a4')
    const width = doc.internal.pageSize.getWidth()
    let tableData: any = []
    allPayrollData?.length > 0 &&
      allPayrollData?.forEach((items: any, index: number) => {
        tableData.push([
          items.name,
          items.basic_salary ?? '-',
          items.working_days,
          items.prasent_days,
          items.paid_leave ?? '-',
          items.hour_rate ?? '-',
          items.hour_clocked ?? '-',
          items.total_salary,
          items.total_allowance,
          items.total_deduction,
          items.net_salary,
        ])
      })
    let offsetY = 4.797777777777778
    let lineHeight = 6.49111111111111
    doc
      .text(`${userData?.mc_name}`, width / 2, 80, { align: 'center' })
      .setFontSize(10)
      .setFont(undefined, 'bold')
    doc
      .text(`Department:  ${departmentId?.label}`, 60, 120)
      .setFontSize(10)
      .setFont(undefined, 'bold')
    doc
      .text(`Month: ${month?.label}`, 60, 140)
      .setFontSize(10)
      .setFont(undefined, 'bold')
    doc
      .text(`Year: ${year?.label}`, 60, 160)
      .setFontSize(10)
      .setFont(undefined, 'bold')

    doc.autoTable({
      startY: 200,
      styles: {
        halign: 'center',
        textColor: '#000',
        fillColor: '#fefefe',
      },
      tableLineColor: '#000',
      tableLineWidth: 0.1,

      headStyles: { lineWidth: 0.1, lineColor: '#000' },
      bodyStyles: { fontSize: '8', lineWidth: 0.1, lineColor: '#000' },

      head: [
        [
          'Name',
          'Basic Salary',
          'Working Days',
          'Present Days',
          'Paid Leave',
          'Hourly Rate',
          'Hourly Clocked',
          'Gross Salary',
          'Total Allowance',
          'Total Deduction',
          'Net Salary',
        ],
      ],
      body: tableData,
    })

    doc.save('payroll.pdf')
    window.open(doc.output('bloburl'), '_blank')
  }

  return (
    <>
      {isLoading && <Loader />}
      {departmentLoader && <Loader />}
      {showGenerate && (
        <Popup
          Children={GeneratePayrollPopup}
          handleClose={() => setShowGenerate(false)}
          setModelOpenClose={setShowGenerate}
        />
      )}
      {/* {showDelete && (
        <Popup
          Children={DeletePopup}
          popData={deletePopData}
          handleClose={() => handleDeleteModalClose()}
        />
      )} */}
      <div className={styles.documentsContainer}>
        <div className={styles.searchContainer}>
          <div className={styles.searchMainContainer}>
            <div className={styles.searchContainers}>
              <input
                className={styles.searchInput}
                type="text"
                placeholder={t('Common.Search by name') || 'Search by name'}
                onChange={(e) => {
                  trimValue(e)
                  setPayrollSearch(e.target.value)
                  setGlobalFilter('')
                  trimValue(e)
                  if (payrollSearch !== '' && e.target.value === '') {
                    let payloadData = {
                      page: pageIndex,
                      pageSize: dataPerPage,
                      search: '',
                      department_id: departmentId?.value,
                      month: month?.label ? month?.label : undefined,
                      year: year?.value ? year?.value : undefined,
                      is_active: true,
                    }
                    dispatch(getAllPayroll(requestGenerator(payloadData))).then(
                      (result: any) => {
                        setTotalPage(result.payload.lastPage)
                        setTotalData(result.payload.total)
                      }
                    )
                  }
                }}
                onKeyDown={(e: any) => {
                  if (e?.key === 'Enter') {
                    handleSearch()
                  }
                }}
              />
              <div
                className={styles.searchButton}
                onClick={() => handleSearch()}
              >
                <SearchIcon fillColor={'#ffffff'} />
              </div>
            </div>
          </div>

          {/* <div className={styles.dateFilter}>
            <Input
              labelText="Date From"
              type="date"
              value={searchDate}
              onChange={(e) => {
                setSearchDate(e.target.value);
              }}
            />

            <Input
              labelText="Date To"
              type="date"
              value={toSearchDate}
              onChange={(e) => {
                setToSearchDate(e.target.value);
              }}
            />
          </div> */}
          <div className={styles.formFieldContainer}>
            <label>
              {' '}
              {t('IPDBooking.Department')}
              <span className="asterick">*</span>{' '}
            </label>
            <div className={styles.inputFieldContainer}>
              <Select
                className={styles.selectInputField}
                placeholder={t('IPDBooking.SelectDepartment')}
                options={departmentData?.map((item: any) => ({
                  label: item.name,
                  value: item._id,
                }))}
                value={departmentId}
                closeMenuOnSelect={true}
                maxMenuHeight={120}
                onChange={(e: any) => {
                  setDepartmentId(e)
                }}
                styles={reactSelectStyle}
              />
            </div>
          </div>
          <div className={styles.formFieldContainer}>
            <label>
              {' '}
              {t('KPI.Month')}
              <span className="asterick">*</span>{' '}
            </label>
            <div className={styles.inputFieldContainer}>
              <Select
                className={styles.selectInputField}
                placeholder={t('KPI.Select Month')}
                options={monthOptions}
                value={month}
                defaultValue={{
                  label: monthOptions?.[currentMonthInNum - 1]?.label,
                  value: monthOptions?.[currentMonthInNum - 1]?.value,
                }}
                closeMenuOnSelect={true}
                maxMenuHeight={120}
                onChange={(e: any) => {
                  setMonth(e)
                }}
                styles={reactSelectStyle}
              />
            </div>
          </div>

          <div className={styles.formFieldContainer}>
            <label>
              {' '}
              {t('KPI.Year')}
              <span className="asterick">*</span>{' '}
            </label>
            <div className={styles.inputFieldContainer}>
              <Select
                className={styles.selectInputField}
                placeholder={t('KPI.Select Year')}
                options={getLastNYears(4)}
                value={year}
                defaultValue={{
                  label: yearOption?.[1]?.label,
                  value: yearOption?.[1]?.value,
                }}
                closeMenuOnSelect={true}
                maxMenuHeight={120}
                onChange={(e: any) => {
                  setYear(e)
                }}
                styles={reactSelectStyle}
              />
            </div>
          </div>
          {/* <div className={styles.formFieldContainer}>
            <label>
              {' '}
              Days<span className="asterick">*</span>{' '}
            </label>
            <div className={styles.inputFieldContainer}>
              <input
                type="number"
                className={styles.inputFieldStyle}
                placeholder="Enter days"
              />
            </div>
          </div> */}
          <div className={styles.searchBtnContainer}>
            <ButtonGroups
              titleOne={t('Payroll.Generate_Payroll') || 'Generate Payroll'}
              titleTwo={t('LeaveManagement.Download_Excel') || 'Download Excel'}
              btnOneCustomClass={styles.btn1}
              btnTwoCustomClass={styles.btn2}
              handleClickOne={() => setShowGenerate(true)}
              handleClickTwo={handleDownloadExcel}
              dissableTwo={allPayrollData?.length > 0 ? false : true}
            />
            <Button
              title={t('Payroll.Download_PDF') || 'Download PDF'}
              handleClick={downloadPdf}
              disable={allPayrollData?.length > 0 ? false : true}
            />
          </div>
        </div>
        <div className={styles.tableContainer}>
          <TableV3
            getTableProps={getTableProps}
            getTableBodyProps={getTableBodyProps}
            headerGroups={headerGroups}
            rows={rows}
            prepareRow={prepareRow}
            handleAction={handleEdit}
            handleOpen={handleDeleteModalOpen}
          />
        </div>

        {totalData > 10 && (
          <Pagination
            setDataPerPage={setDataPerPage}
            pageIndexOptions={pageIndexOptions}
            pageIndex={pageIndex}
            setPageIndex={setPageIndex}
          />
        )}
      </div>
    </>
  )
}
export default Payroll
