import axios from 'axios'
import { IAPIPayload } from '../../../interfaces/apiInterface'
import {
  GET_ALL_DOCTERS_URL,
  GET_ALL_DOCTERS_APPOINTMENTS_URL,
  GET_ALL_AVAILABLE_SLOTS_URL,
  BOOKING_CONFIRMATION_URL,
  GET_ALL_RECURRING_AVAILABLE_SLOTS_URL,
  PHYSIO_APPOINTMENTS_URL,
  GET_ALL_ROOM,
  GET_DOC_UNAVAILABILITY_URL,
  ADD_UNAVAILABILITY_URL,
  UPDATE_UNAVAILABILITY_URL,
  DELETE_UNAVAILABILTY_URL,
} from '../../../config/config'

export const getAllDoctorsList = (data: IAPIPayload) => {
  return axios.post(GET_ALL_DOCTERS_URL, data)
}

export const getAllDoctorAppointmentsList = (data: IAPIPayload) => {
  return axios.post(GET_ALL_DOCTERS_APPOINTMENTS_URL, data)
}

export const getAllAvailableSlotsDoctor = (data: IAPIPayload) => {
  return axios.post(GET_ALL_AVAILABLE_SLOTS_URL, data)
}

export const getAllAvailableRecurringSlotsDoctor = (data: IAPIPayload) => {
  return axios.post(GET_ALL_RECURRING_AVAILABLE_SLOTS_URL, data)
}

export const addBookingConfirmation = (data: IAPIPayload) => {
  return axios.post(BOOKING_CONFIRMATION_URL, data)
}

export const physioAppointments = (data: IAPIPayload) => {
  return axios.post(PHYSIO_APPOINTMENTS_URL, data)
}

export const getAllRoomListForCal = (data: IAPIPayload) => {
  return axios.post(GET_ALL_ROOM, data)
}

// get doctor unavailability
export const getUnavailabiltyDOC = (data: IAPIPayload) => {
  return axios.post(GET_DOC_UNAVAILABILITY_URL, data)
}

// add unavailability

export const addUnavailability = (data: IAPIPayload) => {
  return axios.post(ADD_UNAVAILABILITY_URL, data)
}

// update unavailability

export const editUnavailability = (data: IAPIPayload) => {
  return axios.post(UPDATE_UNAVAILABILITY_URL, data)
}

// delete unavailablity

export const removeUnavailability = (data: IAPIPayload) => {
  return axios.post(DELETE_UNAVAILABILTY_URL, data)
}
