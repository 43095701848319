import { t } from 'i18next'
import styles from '../style.module.scss'

export const ipdMedicationData: any = [
  {
    Header: t('RcptTableHeader.DATE'),
    accessor: 'date',
  },
  {
    Header: t('PatientHistoryTableHeader.MEDICATION'),
    Cell: (props: any) => {
      return (
        <p
          className={styles.blueLinkText}
          onClick={() => props.onClick(props.row.original.medicines)}
        >
          {t('Common.View')}
        </p>
      )
    },
  },
  {
    Header: t('ViewAppointmentHeader.DOCTOR'),
    accessor: 'doctor_name',
  },
]

export const ipdMedicationInfoData: any = [
  {
    Header: t('PatientActivityheader.DATE & TIME'),
    accessor: 'time',
  },
  {
    Header: t('IPDHistory.MEDICATION NAME'),
    accessor: 'medicine_name',
  },
  {
    Header: t('MedicationHeader.QTY'),
    accessor: 'quantity',
  },
  {
    Header: t('MedicationHeader.DURATION'),
    accessor: 'duration',
  },
  {
    Header: t('MedicationHeader.FREQUENCY'),
    accessor: 'frequency',
  },
]
