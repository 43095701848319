import { colors } from '../../../../constants/color'
import { SearchIcon } from '../../../../components/common/svg-components'
import SmartSearch from '../../../../components/common/smart-search/SmartSearch'
import {
  Column,
  TableOptions,
  useGlobalFilter,
  useSortBy,
  useTable,
} from 'react-table'
import TableV3 from '../../../../components/common/table/tableV3/TableV3'
import { useAppDispatch, useAppSelector } from '../../../../hooks'
import { Cols } from '../../../../interfaces/interfaces'
import { requestGenerator } from '../../../../utils/payloadGenerator'
import { getAllAgent } from '../../../../redux/features/agents/agentAsyncActions'
import Loader from '../../../../components/common/spinner/Loader'
import { AgentHeader } from './AgentTableData'
import { useEffect, useState } from 'react'
import styles from './agents.module.scss'
import { CustomModal } from '../../../../components/common/custom-modal/modal'
import { trimValue } from '../../../../utils/utils'
import Pagination from '../../../../components/common/pagination/Pagination'
import AgentDetails from './AgentDetails'
import { t } from 'i18next'

const Agents = () => {
  const [detailsModal, setDetailsModal] = useState(false)
  const [agentDetails, setAgentDetails] = useState([])
  const [searchTest, setSearchTest] = useState('')
  const [dataPerPage, setDataPerPage] = useState<number>(10)
  const [pageIndex, setPageIndex] = useState<number>(1)
  const [totalPage, setTotalPage] = useState<number>(0)
  const [totalData, setTotalData] = useState(0)
  const [isSmartSearchDisable, setIsSmartSearchDisable] =
    useState<boolean>(true)
  const dispatch = useAppDispatch()
  const { isLoading, allAgentData } = useAppSelector((state) => state.agent)
  // For Table
  const data: Cols[] = allAgentData
  const columns: Column<Cols>[] = AgentHeader
  const options: TableOptions<Cols> = {
    data,
    columns,
  }
  const {
    state,
    // @ts-ignore
    setGlobalFilter,
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable(options, useGlobalFilter, useSortBy)
  // @ts-ignore
  const { globalFilter } = state

  const pageIndexArray = () => {
    let pageIndexOptions = []
    for (let i = 1; i <= totalPage; i++) {
      pageIndexOptions.push(i)
    }
    return pageIndexOptions
  }
  const pageIndexOptions = pageIndexArray()

  useEffect(() => {
    let payloadData = {
      search: searchTest,
      page: pageIndex,
      pageSize: dataPerPage,
    }
    dispatch(getAllAgent(requestGenerator(payloadData))).then((result) => {
      setTotalPage(result.payload.lastPage)
      setTotalData(result.payload.total)
    })
  }, [dataPerPage, dispatch, pageIndex])

  const handleDetails = (item: any) => {
    setAgentDetails(item)
    setDetailsModal(true)
  }

  const handleSearch = () => {
    setPageIndex(1)
    if (searchTest?.length) {
      let requestData = {
        search: searchTest,
        page: 1,
        pageSize: dataPerPage,
      }
      dispatch(getAllAgent(requestGenerator(requestData))).then((result) => {
        setIsSmartSearchDisable(false)
        setTotalPage(result.payload.lastPage)
        setTotalData(result.payload.total)
      })
    }
  }

  return (
    <>
      <CustomModal
        showModal={detailsModal}
        closeModal={() => setDetailsModal(false)}
        width="55%"
        height="550px"
        title={t('CallCenterAdmin.Details') || 'Details'}
      >
        <AgentDetails agentDetails={agentDetails} />
      </CustomModal>
      {isLoading && <Loader />}
      <div className={styles.agent}>
        <div className={styles.tableSearchContainer}>
          <div className={styles.search}>
            <input
              className={styles.searchInput}
              type="text"
              placeholder={t('Common.Search by name') || 'Search by name'}
              value={searchTest}
              onKeyDown={(e: any) => {
                if (e?.key === 'Enter') {
                  handleSearch()
                }
              }}
              onChange={(e) => {
                trimValue(e)
                setSearchTest(e.target.value)
                if (searchTest !== '' && e.target.value === '') {
                  setPageIndex(1)
                  setDataPerPage(10)
                  setIsSmartSearchDisable(true)
                  setGlobalFilter('')
                  let requestData = {
                    search: '',
                    page: 1,
                    pageSize: 10,
                  }
                  dispatch(getAllAgent(requestGenerator(requestData))).then(
                    (result) => {
                      setTotalPage(result.payload.lastPage)
                      setTotalData(result.payload.total)
                    }
                  )
                }
              }}
            />
            <div className={styles.searchButton} onClick={handleSearch}>
              <SearchIcon fillColor={colors.white1} />
            </div>
            <SmartSearch
              placeHolder={t('Common.Smart Search') || 'Smart Search'}
              globalFilter={globalFilter}
              setGlobalFilter={setGlobalFilter}
              isDisable={isSmartSearchDisable}
            />
          </div>
        </div>
        <TableV3
          getTableProps={getTableProps}
          getTableBodyProps={getTableBodyProps}
          headerGroups={headerGroups}
          rows={rows}
          prepareRow={prepareRow}
          handleClick={(items: any) => handleDetails(items)}
        />
        {totalData > 10 && (
          <Pagination
            setDataPerPage={setDataPerPage}
            pageIndexOptions={pageIndexOptions}
            pageIndex={pageIndex}
            setPageIndex={setPageIndex}
            pageSize={dataPerPage}
          />
        )}
      </div>
    </>
  )
}
export default Agents
