import {
  BASE_UNIT_TYPE,
  MAPPED_UNIT_TYPE,
  MASTER_UNIT_TYPE_QTY,
} from '../constants/constant'
import { t } from 'i18next'

export interface IUnitTypeValidators {
  [BASE_UNIT_TYPE]: {
    required: string
  }
  [MAPPED_UNIT_TYPE]: {
    required: string
  }

  [MASTER_UNIT_TYPE_QTY]: {
    required: string
  }
}

export const unitTypeValidators: any = {
  [BASE_UNIT_TYPE]: {
    required: t('ItemUnitMaster.Base_Unit_validation'),
  },
  [MAPPED_UNIT_TYPE]: {
    required: t('ItemUnitMaster.Conversational_Unit_Validation'),
  },

  [MASTER_UNIT_TYPE_QTY]: {
    required: t('ItemUnitMaster.Qty_Validation'),
  },
}
