import moment from 'moment'
import { PrintIcon } from '../../components/common/svg-components'
import { t } from 'i18next'

export const viewReceiptHeaderData: any = [
  {
    Header: t('RcptTableHeader.DATE'),
    accessor: 'createdAt', // Add an accessor for sorting
    sortType: (rowA: any, rowB: any, columnId: string, desc: boolean) => {
      // Custom sort function
      const dateA = rowA.original.generate_date || rowA.original.createdAt
      const dateB = rowB.original.generate_date || rowB.original.createdAt

      if (!dateA && !dateB) return 0 // Both dates are missing
      if (!dateA) return desc ? -1 : 1 // A is missing, B exists
      if (!dateB) return desc ? 1 : -1 // B is missing, A exists

      return desc
        ? new Date(dateB).getTime() - new Date(dateA).getTime()
        : new Date(dateA).getTime() - new Date(dateB).getTime()
    },
    Cell: ({ row }: any) => {
      const formattedDate = moment(row?.original?.createdAt).format(
        'DD MMM YYYY'
      )
      return <>{formattedDate}</>
    },
  },
  {
    Header: t('ViewReceipt.RECEIPT NO'),
    accessor: 'receipt_no',
  },
  {
    Header: t('Outstanding.OUTSTANDING AMOUNT'),
    Cell: ({ row }: any) => {
      return <span>{row?.original?.outstanding_amount ?? '-'}</span>
    },
  },
  {
    Header: t('ManageStaff.EMAIL'),
    Cell: ({ row, onRowClick }: any) => {
      return (
        <>
          <span
            onClick={() => {
              onRowClick({ ...row?.original, showEmail: true })
            }}
            style={{ cursor: 'pointer', color: '#0e26a3' }}
          >
            {t('Common.View')}
          </span>
        </>
      )
    },
  },
  {
    Header: t('SettledInvoice.PRINT'),
    Cell: ({ row, onRowClick }: any) => {
      return (
        <>
          <span
            style={{ cursor: 'pointer' }}
            onClick={() => {
              onRowClick({ ...row?.original, showEmail: false })
            }}
          >
            <PrintIcon />
          </span>
        </>
      )
    },
  },
]
