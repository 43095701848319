import {
  ADD_ASSETDATA,
  GET_GENERATE_CODE_TYPE,
  UPDATE_ASSETDATA,
  ADD_ATTRIBUTE_DATA_TYPE,
  GET_ALL_LEVEL_DATA_TYPE,
  LEVEL_2_TYPE,
} from './../../../constants/asyncActionsType'
// Import necessary dependencies and CRUD functions
import createAsyncThunkForSlice from '../../../utils/utils'
import {
  addAssetCrud,
  addAttributeCrud,
  getAllLevelDataCrud,
  getGenrateCodeCrud,
  level_2,
  updateAssetCrud,
} from './accountsCrud'

// Async action creator: accountsLevel2
export const accountsLevel2 = createAsyncThunkForSlice(LEVEL_2_TYPE, level_2, {
  // isToast: true,
})

// Async action creator: addAssest
export const addAsset = createAsyncThunkForSlice(ADD_ASSETDATA, addAssetCrud, {
  // isToast: true,
})

export const updateAsset = createAsyncThunkForSlice(
  UPDATE_ASSETDATA,
  updateAssetCrud,
  {
    // isToast: true,
  }
)

// Async action creator: getAllLevelData
export const getAllLevelData = createAsyncThunkForSlice(
  GET_ALL_LEVEL_DATA_TYPE,
  getAllLevelDataCrud,
  {
    // isToast: true,
  }
)

export const addAttribute = createAsyncThunkForSlice(
  ADD_ATTRIBUTE_DATA_TYPE,
  addAttributeCrud,
  {
    // isToast: true,
  }
)
// get generate code
export const getGenerateCode = createAsyncThunkForSlice(
  GET_GENERATE_CODE_TYPE,
  getGenrateCodeCrud,
  {
    // isToast: true,
  }
)
