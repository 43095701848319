// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.divider_divider__qIiCk {
  background: var(--grey5);
  width: 100%;
  height: 2px;
  margin: 0px;
  border-radius: 8px;
  border: 0px;
}`, "",{"version":3,"sources":["webpack://./src/components/common/divider/divider.module.scss"],"names":[],"mappings":"AAAA;EACE,wBAAA;EACA,WAAA;EACA,WAAA;EACA,WAAA;EACA,kBAAA;EACA,WAAA;AACF","sourcesContent":[".divider {\n  background: var(--grey5);\n  width: 100%;\n  height: 2px;\n  margin: 0px;\n  border-radius: 8px;\n  border: 0px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"divider": `divider_divider__qIiCk`
};
export default ___CSS_LOADER_EXPORT___;
