import { t } from 'i18next'
import { OTP } from '../constants/constant'
export const otpValidators = {
  [OTP]: {
    required: t('AuthValidator.OTP'),
    validate: {
      length: (value: string) => {
        if (value.length !== 6) {
          return t('AuthValidator.OTPLength')
        }
        return true
      },
    },
  },
}
