import { useEffect } from 'react'
import { CheckIcon, UncheckIcon } from '../../components/common/svg-components'
import { useAppDispatch, useAppSelector } from '../../hooks'
import { getReturnInvoiceData } from '../../redux/features/receipt/receiptSlice'
import { setMessage } from '../../redux/features/toast/toastSlice'
import { failure } from '../../constants/data'
import {
  addReturnInvoiceData,
  removeReturnInvoiceData,
} from '../../redux/features/receipt/receiptSlice'
import { disableArrowKey } from '../../utils/utils'
import { colors } from '../color'
import styles from '../../pages/receipt/return-invoice-modal/returnInvoiceModal.module.scss'
import { t } from 'i18next'

export const returnInvoiceModalTableData: any = [
  {
    Header: t('InsurancePlan_Form.SERVICE_NAME'),
    accessor: (row: any) => {
      if (row?.test_name) {
        return row?.test_name
      } else if (row?.name) {
        return row?.name
      }
    },
  },
  {
    Header: t('ReturnInvoice.SESSION NO'),
    accessor: 'session_no',
    Cell: () => {
      return <span>-</span>
    },
  },
  {
    Header: t('ReturnInvoice.SESSION AMT'),
    accessor: 'totalAmount',
  },
  {
    Header: t('AllAttachmentHeader.SELECT'),
    accessor: 'selectRaw',
    disableSortBy: true,
    Cell: (props: any) => {
      const { selectedReturnInvoiceData, returnInvoiceData } = useAppSelector(
        (state) => state.receipt
      )
      const dispatch = useAppDispatch()
      const handleReturnInvoiceCheck = (checkType: string) => {
        if (
          checkType === 'REMOVE' &&
          !returnInvoiceData?.patient_insurance_id
        ) {
          const { refund_amt, ...rest } = props?.row?.original
          const invoiceRefundAmtData =
            returnInvoiceData?.return_invoice_data?.map((item: any) => {
              if (
                item?.return_invoice_id ===
                props?.row?.original?.return_invoice_id
              ) {
                return { ...rest }
              } else {
                return item
              }
            })
          const updatedReturnInvoiceData = {
            ...returnInvoiceData,
            return_invoice_data: invoiceRefundAmtData,
          }
          dispatch(getReturnInvoiceData(updatedReturnInvoiceData))
          dispatch(removeReturnInvoiceData(props?.row?.original))
        } else if (
          checkType === 'ADD' &&
          !returnInvoiceData?.patient_insurance_id
        ) {
          let addReturnInvoicePayload = {}
          if (returnInvoiceData?.outstanding_amount === 0) {
            addReturnInvoicePayload = {
              ...props?.row?.original,
              refund_amt: props?.row?.original?.totalAmount,
            }
            const invoiceRefundAmtData =
              returnInvoiceData?.return_invoice_data?.map((item: any) => {
                if (
                  item?.return_invoice_id ===
                  props?.row?.original?.return_invoice_id
                ) {
                  return addReturnInvoicePayload
                } else {
                  return item
                }
              })
            const updatedReturnInvoiceData = {
              ...returnInvoiceData,
              return_invoice_data: invoiceRefundAmtData,
            }
            dispatch(getReturnInvoiceData(updatedReturnInvoiceData))
          } else {
            addReturnInvoicePayload = {
              ...props?.row?.original,
            }
          }
          dispatch(addReturnInvoiceData(addReturnInvoicePayload))
        }
      }
      return (
        <>
          {selectedReturnInvoiceData?.some(
            (item: any) =>
              item?.return_invoice_id ===
              props?.row?.original?.return_invoice_id
          ) ? (
            <CheckIcon
              fillColor={colors.green1}
              handleClick={() => handleReturnInvoiceCheck('REMOVE')}
            />
          ) : (
            <UncheckIcon
              fillColor={colors.grey1}
              handleClick={() => handleReturnInvoiceCheck('ADD')}
            />
          )}
        </>
      )
    },
  },
  {
    Header: t('ReturnInvoice.REFUND AMT'),
    disableSortBy: true,
    Cell: (props: any) => {
      const dispatch = useAppDispatch()
      const { returnInvoiceData, selectedReturnInvoiceData } = useAppSelector(
        (state) => state.receipt
      )
      const isInvoiceSelected = selectedReturnInvoiceData?.some(
        (item: any) =>
          item?.return_invoice_id === props?.row?.original?.return_invoice_id
      )
      const validateRefundAmount = (refundAmount: string, item: any) => {
        if (Number(refundAmount) === undefined) {
          dispatch(
            setMessage({
              message: t('ReturnInvoice.ValidRefundValidAMT'),
              type: failure,
            })
          )
          return {
            ...item,
            refund_amt: refundAmount,
          }
        } else if (refundAmount === '0') {
          dispatch(
            setMessage({
              message: t('ReturnInvoice.ValidRefundValidAMT'),
              type: failure,
            })
          )
          return item
        } else if (Number(refundAmount) < 0) {
          dispatch(
            setMessage({
              message: t('ReturnInvoice.ValidRefundValidAMT'),
              type: failure,
            })
          )
          return item
        } else if (
          Number(refundAmount) > Number(returnInvoiceData?.paid_amount)
        ) {
          dispatch(
            setMessage({
              message: t('ReturnInvoice.LessRefundAMT'),
              type: failure,
            })
          )
          return item
        } else if (
          Number(refundAmount) > Number(props?.row?.original?.totalAmount)
        ) {
          dispatch(
            setMessage({
              message: t('ReturnInvoice.LessRefundAMTSES'),
              type: failure,
            })
          )
          return item
        } else {
          return {
            ...item,
            refund_amt: refundAmount,
          }
        }
      }
      const handleChange = (e: any) => {
        const refundAmount = e.target.value
        let tempArr: any = []
        tempArr = returnInvoiceData?.return_invoice_data?.map(
          (item: any, index: number) => {
            if (
              item?.return_invoice_id ===
                props?.row?.original?.return_invoice_id &&
              isInvoiceSelected
            ) {
              return validateRefundAmount(refundAmount, item)
            } else {
              return item
            }
          }
        )
        const updatedReturnInvoiceData = { ...returnInvoiceData }
        updatedReturnInvoiceData.return_invoice_data = tempArr
        dispatch(getReturnInvoiceData(updatedReturnInvoiceData))
      }

      return (
        <input
          type="number"
          value={
            returnInvoiceData?.return_invoice_data?.[props.row.index]
              ?.refund_amt || ''
          }
          // value={inputValue}
          className={
            returnInvoiceData?.outstanding_amount === 0
              ? styles.refundAmtFieldDisable
              : styles.refundAmtField
          }
          onChange={handleChange}
          onKeyDown={(e: any) => disableArrowKey(e)}
          onWheel={(e: any) => {
            e.target.blur()
          }}
          disabled={
            returnInvoiceData?.outstanding_amount === 0 ||
            returnInvoiceData?.paid_amount === 0
          }
        />
      )
    },
  },
]

export const returnInvoiceModalTableDataReceiptionist: any = [
  {
    Header: t('InsurancePlan_Form.SERVICE_NAME'),
    accessor: (row: any) => {
      if (row?.test_name) {
        return row?.test_name
      } else if (row?.name) {
        return row?.name
      }
    },
  },
  {
    Header: t('ReturnInvoice.SESSION NO'),
    accessor: 'session_no',
    Cell: () => {
      return <span>-</span>
    },
  },
  {
    Header: t('ReturnInvoice.SESSION AMT'),
    accessor: 'price',
  },
  {
    Header: t('ReturnInvoice.DISCOUNT AMT'),
    accessor: 'discount',
  },
  {
    Header: t('AllAttachmentHeader.SELECT'),
    accessor: 'selectRaw',
    disableSortBy: true,
    Cell: (props: any) => {
      const { selectedReturnInvoiceData, returnInvoiceData } = useAppSelector(
        (state) => state.receipt
      )
      const dispatch = useAppDispatch()
      const handleReturnInvoiceCheck = (checkType: string) => {
        if (
          checkType === 'REMOVE' &&
          !returnInvoiceData?.patient_insurance_id
        ) {
          const { refund_amt, ...rest } = props?.row?.original
          const invoiceRefundAmtData =
            returnInvoiceData?.return_invoice_data?.map((item: any) => {
              if (
                item?.return_invoice_id ===
                props?.row?.original?.return_invoice_id
              ) {
                return { ...rest }
              } else {
                return item
              }
            })
          const updatedReturnInvoiceData = {
            ...returnInvoiceData,
            return_invoice_data: invoiceRefundAmtData,
          }
          dispatch(getReturnInvoiceData(updatedReturnInvoiceData))
          dispatch(removeReturnInvoiceData(props?.row?.original))
        } else if (
          checkType === 'ADD' &&
          !returnInvoiceData?.patient_insurance_id
        ) {
          let addReturnInvoicePayload = {}
          if (returnInvoiceData?.outstanding_amount === 0) {
            addReturnInvoicePayload = {
              ...props?.row?.original,
              refund_amt:
                props?.row?.original?.price - props?.row?.original?.discount ??
                0,
            }
            const invoiceRefundAmtData =
              returnInvoiceData?.return_invoice_data?.map((item: any) => {
                if (
                  item?.return_invoice_id ===
                  props?.row?.original?.return_invoice_id
                ) {
                  return addReturnInvoicePayload
                } else {
                  return item
                }
              })
            const updatedReturnInvoiceData = {
              ...returnInvoiceData,
              return_invoice_data: invoiceRefundAmtData,
            }
            dispatch(getReturnInvoiceData(updatedReturnInvoiceData))
          } else {
            addReturnInvoicePayload = {
              ...props?.row?.original,
            }
          }
          dispatch(addReturnInvoiceData(addReturnInvoicePayload))
        }
      }
      return (
        <>
          {selectedReturnInvoiceData?.some(
            (item: any) =>
              item?.return_invoice_id ===
              props?.row?.original?.return_invoice_id
          ) ? (
            <CheckIcon
              fillColor={colors.green1}
              handleClick={() => handleReturnInvoiceCheck('REMOVE')}
            />
          ) : (
            <UncheckIcon
              fillColor={colors.grey1}
              handleClick={() => handleReturnInvoiceCheck('ADD')}
            />
          )}
        </>
      )
    },
  },
  {
    Header: t('ReturnInvoice.REFUND AMT'),
    disableSortBy: true,
    Cell: (props: any) => {
      const dispatch = useAppDispatch()
      const { returnInvoiceData, selectedReturnInvoiceData } = useAppSelector(
        (state) => state.receipt
      )
      const isInvoiceSelected = selectedReturnInvoiceData?.some(
        (item: any) =>
          item?.return_invoice_id === props?.row?.original?.return_invoice_id
      )
      const validateRefundAmount = (refundAmount: string, item: any) => {
        if (Number(refundAmount) === undefined) {
          dispatch(
            setMessage({
              message: t('ReturnInvoice.ValidRefundValidAMT'),
              type: failure,
            })
          )
          return {
            ...item,
            refund_amt: refundAmount,
          }
        } else if (refundAmount === '0') {
          dispatch(
            setMessage({
              message: t('ReturnInvoice.ValidRefundValidAMT'),
              type: failure,
            })
          )
          return item
        } else if (Number(refundAmount) < 0) {
          dispatch(
            setMessage({
              message: t('ReturnInvoice.ValidRefundValidAMT'),
              type: failure,
            })
          )
          return item
        } else if (
          Number(refundAmount) > Number(returnInvoiceData?.paid_amount)
        ) {
          dispatch(
            setMessage({
              message: t('ReturnInvoice.LessRefundAMT'),
              type: failure,
            })
          )
          return item
        } else if (
          Number(refundAmount) >
          Number(props?.row?.original?.price) -
            Number(props?.row?.original?.discount ?? 0)
        ) {
          dispatch(
            setMessage({
              message: t('ReturnInvoice.LessRefundAMTDIS'),
              type: failure,
            })
          )
          return item
        } else {
          return {
            ...item,
            refund_amt: refundAmount,
          }
        }
      }
      const handleChange = (e: any) => {
        const refundAmount = e.target.value
        let tempArr: any = []
        tempArr = returnInvoiceData?.return_invoice_data?.map(
          (item: any, index: number) => {
            if (
              item?.return_invoice_id ===
                props?.row?.original?.return_invoice_id &&
              isInvoiceSelected
            ) {
              return validateRefundAmount(refundAmount, item)
            } else {
              return item
            }
          }
        )
        const updatedReturnInvoiceData = { ...returnInvoiceData }
        updatedReturnInvoiceData.return_invoice_data = tempArr
        dispatch(getReturnInvoiceData(updatedReturnInvoiceData))
      }

      return (
        <input
          type="number"
          value={
            returnInvoiceData?.return_invoice_data?.[props.row.index]
              ?.refund_amt || ''
          }
          // value={inputValue}
          className={
            returnInvoiceData?.outstanding_amount === 0
              ? styles.refundAmtFieldDisable
              : styles.refundAmtField
          }
          onChange={handleChange}
          onKeyDown={(e: any) => disableArrowKey(e)}
          onWheel={(e: any) => {
            e.target.blur()
          }}
          disabled={
            returnInvoiceData?.outstanding_amount === 0 ||
            returnInvoiceData?.paid_amount === 0
          }
        />
      )
    },
  },
]
