// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pagination_paginationSection__u7XUb {
  display: flex;
  align-items: center;
  margin-top: 25px;
}
.pagination_paginationSection__u7XUb .pagination_paginationButton__Romf7,
.pagination_paginationSection__u7XUb .pagination_selectedPage__7ICrH {
  background: var(--grey1);
  border: 1px solid var(--grey6);
  border-radius: 2px;
  font-weight: var(--font-medium);
  font-size: var(--font-14);
  letter-spacing: 0.01em;
  color: var(--grey4);
  padding: 4px 0px;
  width: 30px;
  text-align: center;
  margin-right: 7px;
  cursor: pointer;
}
.pagination_paginationSection__u7XUb .pagination_selectedPage__7ICrH {
  background: var(--green1);
  border: none;
  color: var(--white1);
}
.pagination_paginationSection__u7XUb .pagination_setPageSizeSection__bqjDn {
  display: flex;
  align-items: baseline;
  margin-left: 25px;
}
.pagination_paginationSection__u7XUb .pagination_setPageSizeSection__bqjDn .pagination_setPageSizeText__EfH4r {
  font-weight: var(--font-medium);
  font-size: var(--font-14);
  color: var(--grey4);
  margin-right: 5px;
}
.pagination_paginationSection__u7XUb .pagination_setPageSizeSection__bqjDn .pagination_selectPageSize__VY9fP {
  border: 1px solid var(--grey6);
  border-radius: 2px;
  font-weight: var(--font-medium);
  font-size: var(--font-14);
  color: var(--grey4);
  outline: none;
  width: 47px;
}`, "",{"version":3,"sources":["webpack://./src/components/common/pagination/pagination.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,mBAAA;EACA,gBAAA;AACF;AAAE;;EAEE,wBAAA;EACA,8BAAA;EACA,kBAAA;EACA,+BAAA;EACA,yBAAA;EACA,sBAAA;EACA,mBAAA;EACA,gBAAA;EACA,WAAA;EACA,kBAAA;EACA,iBAAA;EACA,eAAA;AAEJ;AACE;EACE,yBAAA;EACA,YAAA;EACA,oBAAA;AACJ;AACE;EACE,aAAA;EACA,qBAAA;EACA,iBAAA;AACJ;AAAI;EACE,+BAAA;EACA,yBAAA;EACA,mBAAA;EACA,iBAAA;AAEN;AACI;EACE,8BAAA;EACA,kBAAA;EACA,+BAAA;EACA,yBAAA;EACA,mBAAA;EACA,aAAA;EACA,WAAA;AACN","sourcesContent":[".paginationSection {\n  display: flex;\n  align-items: center;\n  margin-top: 25px;\n  .paginationButton,\n  .selectedPage {\n    background: var(--grey1);\n    border: 1px solid var(--grey6);\n    border-radius: 2px;\n    font-weight: var(--font-medium);\n    font-size: var(--font-14);\n    letter-spacing: 0.01em;\n    color: var(--grey4);\n    padding: 4px 0px;\n    width: 30px;\n    text-align: center;\n    margin-right: 7px;\n    cursor: pointer;\n  }\n\n  .selectedPage {\n    background: var(--green1);\n    border: none;\n    color: var(--white1);\n  }\n  .setPageSizeSection {\n    display: flex;\n    align-items: baseline;\n    margin-left: 25px;\n    .setPageSizeText {\n      font-weight: var(--font-medium);\n      font-size: var(--font-14);\n      color: var(--grey4);\n      margin-right: 5px;\n    }\n\n    .selectPageSize {\n      border: 1px solid var(--grey6);\n      border-radius: 2px;\n      font-weight: var(--font-medium);\n      font-size: var(--font-14);\n      color: var(--grey4);\n      outline: none;\n      width: 47px;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"paginationSection": `pagination_paginationSection__u7XUb`,
	"paginationButton": `pagination_paginationButton__Romf7`,
	"selectedPage": `pagination_selectedPage__7ICrH`,
	"setPageSizeSection": `pagination_setPageSizeSection__bqjDn`,
	"setPageSizeText": `pagination_setPageSizeText__EfH4r`,
	"selectPageSize": `pagination_selectPageSize__VY9fP`
};
export default ___CSS_LOADER_EXPORT___;
