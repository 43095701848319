// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.labInvoiceTabledata_inputFieldServiceTable__f4ZiY {
  border: 1px solid var(--grey6);
  border-radius: 6px;
  padding: 5px;
  width: 80px;
  text-align: center;
}

.labInvoiceTabledata_inactive__ar7Ze {
  color: var(--errorColor);
}

.labInvoiceTabledata_active__XuwTB {
  color: var(--black1);
}

.labInvoiceTabledata_emrFileNoActive__0nZJY {
  color: var(--blue1);
  cursor: pointer;
}

.labInvoiceTabledata_emrFileNoInactive__7pofk {
  color: var(--errorColor);
  cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/constants/table-data/labInvoiceTabledata.module.scss"],"names":[],"mappings":"AAAA;EACE,8BAAA;EACA,kBAAA;EACA,YAAA;EACA,WAAA;EACA,kBAAA;AACF;;AACA;EACE,wBAAA;AAEF;;AAAA;EACE,oBAAA;AAGF;;AADA;EACE,mBAAA;EACA,eAAA;AAIF;;AAFA;EACE,wBAAA;EACA,eAAA;AAKF","sourcesContent":[".inputFieldServiceTable {\n  border: 1px solid var(--grey6);\n  border-radius: 6px;\n  padding: 5px;\n  width: 80px;\n  text-align: center;\n}\n.inactive {\n  color: var(--errorColor);\n}\n.active {\n  color: var(--black1);\n}\n.emrFileNoActive {\n  color: var(--blue1);\n  cursor: pointer;\n}\n.emrFileNoInactive {\n  color: var(--errorColor);\n  cursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"inputFieldServiceTable": `labInvoiceTabledata_inputFieldServiceTable__f4ZiY`,
	"inactive": `labInvoiceTabledata_inactive__ar7Ze`,
	"active": `labInvoiceTabledata_active__XuwTB`,
	"emrFileNoActive": `labInvoiceTabledata_emrFileNoActive__0nZJY`,
	"emrFileNoInactive": `labInvoiceTabledata_emrFileNoInactive__7pofk`
};
export default ___CSS_LOADER_EXPORT___;
