import { FC, useState, useEffect } from 'react'
import Button from '../../../components/common/button/Button'
import { useNavigate } from 'react-router-dom'
import DeletePopup from './delete-popup/DeletePopup'
import Popup from '../../../components/common/popup/Popup'
import { leaveManagementTableHeaderData } from './leaveManagementTableData'
import { trimValue } from '../../../utils/utils'
import { SearchIcon } from '../../../components/common/svg-components'
import { requestGenerator } from '../../../utils/payloadGenerator'
import { useAppDispatch, useAppSelector } from '../../../hooks'
import Loader from '../../../components/common/spinner/Loader'
import TableV3 from '../../../components/common/table/tableV3/TableV3'
import { getAllLeave } from '../../../redux/features/leave/leaveAsyncActions'
import { Cols } from '../../employees/employeeInterface'
import {
  Column,
  TableOptions,
  useGlobalFilter,
  useSortBy,
  useTable,
} from 'react-table'
import FilePopup from '../leave-popup/FilenamePopup'
import RemarksPopup from '../leave-popup/RemarksPopuo'
import Pagination from '../../../components/common/pagination/Pagination'
import { utils, writeFile, WorkBook, WorkSheet } from 'xlsx'
import styles from './leaveManagement.module.scss'
import { clearLeaveData } from '../../../redux/features/leave/leaveSlice'
import { t } from 'i18next'

const LeaveManagement = () => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const [showDelete, setShowDelete] = useState<boolean>(false)
  const [dataPerPage, setDataPerPage] = useState<number>(10)
  const [totalPage, setTotalPage] = useState<number>(0)
  const [pageIndex, setPageIndex] = useState<number>(1)

  const [totalData, setTotalData] = useState(0)
  const [showRemarksPopup, setShowRemarksPopup] = useState<boolean>(false)
  const [notesPopData, setNotesPopData] = useState({})
  const [deletePopData, setDeletePopData] = useState({})
  const [showFilePopup, setShowFilePopup] = useState<boolean>(false)
  const [filesPopData, setFilesPopData] = useState({})
  const [searchValue, setSearchValue] = useState<string>('')
  const { isLoading, allLeaveData } = useAppSelector((state) => state.leave)

  const data: Cols[] = allLeaveData
  const columns: Column<Cols>[] = leaveManagementTableHeaderData
  const options: TableOptions<Cols> = {
    columns,
    data,
  }
  const {
    // @ts-ignore
    setGlobalFilter,
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable(options, useGlobalFilter, useSortBy)

  // Get All Leave Management
  useEffect(() => {
    if (!searchValue?.length) {
      let payloadData = {
        is_active: true,
        page: pageIndex,
        pageSize: dataPerPage,
      }
      dispatch(getAllLeave(requestGenerator(payloadData))).then(
        (result: any) => {
          setTotalPage(result.payload.lastPage)
          setTotalData(result.payload.total)
        }
      )
    }
  }, [dispatch, dataPerPage, pageIndex])

  // function for creating Page Index Array
  const pageIndexArray = () => {
    let pageIndexOptions = []
    for (let i = 1; i <= totalPage; i++) {
      pageIndexOptions.push(i)
    }
    return pageIndexOptions
  }
  const pageIndexOptions = pageIndexArray()
  const handleDeleteModalClose = () => {
    setShowDelete((prevState) => !prevState)
  }
  const handleremarks = () => {
    setShowRemarksPopup((prevState) => !prevState)
  }
  const handleremark = (notesObject: {}) => {
    setShowRemarksPopup((prevState) => !prevState)
    setNotesPopData(notesObject)
  }
  const handlefile = (notesObject: {}) => {
    setShowFilePopup((prevState) => !prevState)
    setFilesPopData(notesObject)
  }
  const handlefiles = () => {
    setShowFilePopup((prevState) => !prevState)
  }
  const handleDeleteModalOpen = (id: any) => {
    setDeletePopData(id)
    setShowDelete((prevState) => !prevState)
  }
  const handleDownloadExcel = () => {
    const trimmedData: any = []
    allLeaveData.forEach((element: any) => {
      trimmedData.push({
        emp_id: element.emp_id,
        name: element.name,
        leave_type: element.leave_type,
        status: element.status,
        leave_calculation: element.leave_calculation,
        selected_days: element.leave_calculation,
        is_active: true,
        is_approved: element.is_approved,
        reason: element.reason,
        applied_on: element.start_date,
      })
    })

    const worksheet: WorkSheet = utils.json_to_sheet(trimmedData)
    const workbook: WorkBook = utils.book_new()
    utils.book_append_sheet(workbook, worksheet, 'Leave')
    const excelBuffer = writeFile(workbook, 'Leave.xlsx', { type: 'buffer' })
    const link = document.createElement('a')
    link.href = window.URL.createObjectURL(new Blob([excelBuffer]))
    link.download = 'Leave.xlsx'
  }

  const handleSearch = () => {
    setPageIndex(1)
    let requestData = {
      search: searchValue,
      page: 1,
      pageSize: dataPerPage,
      is_active: true,
    }
    dispatch(getAllLeave(requestGenerator(requestData))).then((result: any) =>
      setTotalPage(result.payload.lastPage)
    )
  }

  return (
    <>
      {isLoading && <Loader />}
      {showDelete && (
        <Popup
          Children={DeletePopup}
          popData={deletePopData}
          handleClose={() => handleDeleteModalClose()}
        />
      )}
      {showFilePopup && (
        <Popup
          Children={FilePopup}
          popData={filesPopData}
          handleClose={() => handlefiles()}
        />
      )}
      {showRemarksPopup && (
        <Popup
          Children={RemarksPopup}
          popData={notesPopData}
          handleClose={() => handleremarks()}
        />
      )}
      <div className={styles.leaveManagementContainer}>
        <div className={styles.searchMainContainer}>
          <div className={styles.searchContainer}>
            <input
              className={styles.searchInput}
              type="text"
              placeholder={t('Common.Search by name') || 'Search by name'}
              value={searchValue}
              onChange={(e) => {
                setSearchValue(e.target.value)
                setGlobalFilter('')
                trimValue(e)
                if (e?.target?.value === '') {
                  let requestData = {
                    search: '',
                    page: pageIndex,
                    pageSize: dataPerPage,
                    is_active: true,
                  }
                  dispatch(getAllLeave(requestGenerator(requestData))).then(
                    (result: any) => setTotalPage(result.payload.lastPage)
                  )
                }
              }}
              onKeyDown={(e: any) => {
                if (e?.key === 'Enter') {
                  handleSearch()
                }
              }}
            />

            <div className={styles.searchButton} onClick={() => handleSearch()}>
              <SearchIcon fillColor={'#ffffff'} />
            </div>
          </div>
          <div className={styles.searchBtnContainer}>
            <Button
              title={t('LeaveManagement.Apply_Leave') || 'Apply Leave'}
              customClass={styles.applyLeaveBtn}
              handleClick={() => {
                navigate('apply-leave')
                dispatch(clearLeaveData())
              }}
            />
            <Button
              title={
                t('LeaveManagement.download_balance_sheet') ||
                'Download Leave Balance Report'
              }
              customClass={styles.leaveBalanceBtn}
              type="button"
              handleClick={handleDownloadExcel}
            />
          </div>
        </div>

        <div className={styles.tableContainer}>
          <TableV3
            getTableProps={getTableProps}
            getTableBodyProps={getTableBodyProps}
            headerGroups={headerGroups}
            rows={rows}
            prepareRow={prepareRow}
            handleOpen={handleDeleteModalOpen}
            handleClick={handleremark}
            handleRowClick={handlefile}
            handleAction={handleDeleteModalOpen}
          />
        </div>
        {totalData > 10 && (
          <Pagination
            setDataPerPage={setDataPerPage}
            pageIndexOptions={pageIndexOptions}
            pageIndex={pageIndex}
            setPageIndex={setPageIndex}
          />
        )}
      </div>
    </>
  )
}
export default LeaveManagement
