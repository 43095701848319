// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.viewDocumentPopup_notesPopupContainer__RrIRy {
  width: 500px;
  margin: auto;
  background: var(--white1);
  border-radius: 10px;
  padding: 10px;
}
.viewDocumentPopup_notesPopupContainer__RrIRy .viewDocumentPopup_closeIconStyle__eKzBN {
  cursor: pointer;
  float: right;
}
.viewDocumentPopup_notesPopupContainer__RrIRy .viewDocumentPopup_notesContainer__i7pZc {
  margin-top: 50px;
}
.viewDocumentPopup_notesPopupContainer__RrIRy .viewDocumentPopup_notesContainer__i7pZc .viewDocumentPopup_title__FhxeX {
  text-align: center;
  font-weight: var(--font-semibold);
  font-size: var(--font-24);
  line-height: 36px;
  color: var(--black2);
}
.viewDocumentPopup_notesPopupContainer__RrIRy .viewDocumentPopup_notesContainer__i7pZc .viewDocumentPopup_dividerStyle__mtH3G {
  margin: 10px auto 30px auto;
  width: 80px;
  height: 3px;
  background: var(--green1);
}
.viewDocumentPopup_notesPopupContainer__RrIRy .viewDocumentPopup_notesContainer__i7pZc .viewDocumentPopup_mainContainer__nbNDe {
  height: 300px;
  overflow: auto;
  margin: 20px auto;
}
.viewDocumentPopup_notesPopupContainer__RrIRy .viewDocumentPopup_notesContainer__i7pZc .viewDocumentPopup_mainContainer__nbNDe::-webkit-scrollbar {
  display: block;
  width: 7px;
  height: 7px;
}
.viewDocumentPopup_notesPopupContainer__RrIRy .viewDocumentPopup_notesContainer__i7pZc .viewDocumentPopup_mainContainer__nbNDe::-webkit-scrollbar-track {
  background: transparent;
}
.viewDocumentPopup_notesPopupContainer__RrIRy .viewDocumentPopup_notesContainer__i7pZc .viewDocumentPopup_mainContainer__nbNDe::-webkit-scrollbar-thumb {
  background-color: var(--grey7);
  border-radius: 10px;
  border: 1px solid var(--grey7);
}
.viewDocumentPopup_notesPopupContainer__RrIRy .viewDocumentPopup_notesContainer__i7pZc .viewDocumentPopup_mainContainer__nbNDe .viewDocumentPopup_documentViwerStyle__8cMs1 {
  width: 100%;
  height: 100%;
  margin: 10px auto;
}
.viewDocumentPopup_notesPopupContainer__RrIRy .viewDocumentPopup_notesContainer__i7pZc .viewDocumentPopup_mainContainer__nbNDe .viewDocumentPopup_imageStyle__FGJ-N {
  margin: 10px auto;
}`, "",{"version":3,"sources":["webpack://./src/components/common/modal/view-document-popup/viewDocumentPopup.module.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;EAEA,YAAA;EACA,yBAAA;EACA,mBAAA;EACA,aAAA;AAAF;AACE;EACE,eAAA;EACA,YAAA;AACJ;AACE;EACE,gBAAA;AACJ;AAAI;EACE,kBAAA;EACA,iCAAA;EACA,yBAAA;EACA,iBAAA;EACA,oBAAA;AAEN;AAAI;EACE,2BAAA;EACA,WAAA;EACA,WAAA;EACA,yBAAA;AAEN;AAAI;EACE,aAAA;EACA,cAAA;EACA,iBAAA;AAEN;AADM;EACE,cAAA;EACA,UAAA;EACA,WAAA;AAGR;AADM;EACE,uBAAA;AAGR;AAAM;EACE,8BAAA;EACA,mBAAA;EACA,8BAAA;AAER;AAAM;EACE,WAAA;EACA,YAAA;EACA,iBAAA;AAER;AAAM;EACE,iBAAA;AAER","sourcesContent":[".notesPopupContainer {\n  width: 500px;\n  // height: 250px;\n  margin: auto;\n  background: var(--white1);\n  border-radius: 10px;\n  padding: 10px;\n  .closeIconStyle {\n    cursor: pointer;\n    float: right;\n  }\n  .notesContainer {\n    margin-top: 50px;\n    .title {\n      text-align: center;\n      font-weight: var(--font-semibold);\n      font-size: var(--font-24);\n      line-height: 36px;\n      color: var(--black2);\n    }\n    .dividerStyle {\n      margin: 10px auto 30px auto;\n      width: 80px;\n      height: 3px;\n      background: var(--green1);\n    }\n    .mainContainer {\n      height: 300px;\n      overflow: auto;\n      margin: 20px auto;\n      &::-webkit-scrollbar {\n        display: block;\n        width: 7px;\n        height: 7px;\n      }\n      &::-webkit-scrollbar-track {\n        background: transparent;\n      }\n\n      &::-webkit-scrollbar-thumb {\n        background-color: var(--grey7);\n        border-radius: 10px;\n        border: 1px solid var(--grey7);\n      }\n      .documentViwerStyle {\n        width: 100%;\n        height: 100%;\n        margin: 10px auto;\n      }\n      .imageStyle {\n        margin: 10px auto;\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"notesPopupContainer": `viewDocumentPopup_notesPopupContainer__RrIRy`,
	"closeIconStyle": `viewDocumentPopup_closeIconStyle__eKzBN`,
	"notesContainer": `viewDocumentPopup_notesContainer__i7pZc`,
	"title": `viewDocumentPopup_title__FhxeX`,
	"dividerStyle": `viewDocumentPopup_dividerStyle__mtH3G`,
	"mainContainer": `viewDocumentPopup_mainContainer__nbNDe`,
	"documentViwerStyle": `viewDocumentPopup_documentViwerStyle__8cMs1`,
	"imageStyle": `viewDocumentPopup_imageStyle__FGJ-N`
};
export default ___CSS_LOADER_EXPORT___;
