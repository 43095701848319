import { createSlice } from '@reduxjs/toolkit'
import {
  addTemplate,
  editTemplate,
  getAllTemplates,
  getTemplateById,
  removeTemplate,
} from './dynamicTemplateAsyncActions'
import {
  invoiceNormalField,
  invoiceTableField,
} from '../../../pages/dynamic_template/templateConstant'

export interface Itemplate {
  isLoading: boolean
  templates: any[]
  tableField: { name: string; check: boolean }[]
  normalField: { name: string; check: boolean }[]
}
export const initialState: Itemplate = {
  isLoading: false,
  templates: [],
  normalField: invoiceNormalField,
  tableField: invoiceTableField,
}

export const templateSlice = createSlice({
  name: 'template',
  initialState,
  reducers: {
    setTableFields: (state, action) => {
      state.tableField = action.payload
    },
    setNormalFields: (state, action) => {
      state.normalField = action.payload
    },
    clearTemplateData: (state) => {
      state.isLoading = false
      state.templates = []
    },
  },
  extraReducers(builder) {
    // GET ALL TEMPLATES
    builder
      .addCase(getAllTemplates.pending, (state) => {
        state.isLoading = true
      })
      .addCase(getAllTemplates.fulfilled, (state, action) => {
        state.isLoading = false
        state.templates = action.payload?.data || []
      })
      .addCase(getAllTemplates.rejected, (state, error) => {
        state.isLoading = false
      })
    // ADD TEMPLATE
    builder
      .addCase(addTemplate.pending, (state) => {
        state.isLoading = true
      })
      .addCase(addTemplate.fulfilled, (state, action) => {
        state.isLoading = false
      })
      .addCase(addTemplate.rejected, (state, error) => {
        state.isLoading = false
      })
    //   EDIT TEMPLATE
    builder
      .addCase(editTemplate.pending, (state) => {
        state.isLoading = true
      })
      .addCase(editTemplate.fulfilled, (state, action) => {
        state.isLoading = false
      })
      .addCase(editTemplate.rejected, (state, error) => {
        state.isLoading = false
      })
    //   DELETE TEMPLATE
    builder
      .addCase(removeTemplate.pending, (state) => {
        state.isLoading = true
      })
      .addCase(removeTemplate.fulfilled, (state, action) => {
        state.isLoading = false
      })
      .addCase(removeTemplate.rejected, (state, error) => {
        state.isLoading = false
      })
    // get template by id
    builder
      .addCase(getTemplateById.pending, (state) => {
        state.isLoading = true
      })
      .addCase(getTemplateById.fulfilled, (state, action) => {
        state.isLoading = false
      })
      .addCase(getTemplateById.rejected, (state, error) => {
        state.isLoading = false
      })
  },
})
export const { setTableFields, setNormalFields, clearTemplateData } =
  templateSlice.actions
export default templateSlice.reducer
