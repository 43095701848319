// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_notesPopupContainer__I8lNO {
  width: 450px;
  margin: auto;
  background: var(--white1);
  border-radius: 10px;
  padding: 10px;
}
.styles_notesPopupContainer__I8lNO .styles_closeIconStyle__b86mU {
  cursor: pointer;
  float: right;
}
.styles_notesPopupContainer__I8lNO .styles_notesContainer__0CgY\\+ {
  margin-top: 50px;
}
.styles_notesPopupContainer__I8lNO .styles_notesContainer__0CgY\\+ .styles_title__CcuB9 {
  text-align: center;
  font-weight: var(--font-semibold);
  font-size: var(--font-24);
  line-height: 36px;
  color: var(--black2);
}
.styles_notesPopupContainer__I8lNO .styles_notesContainer__0CgY\\+ .styles_inputContainer__wn9Ct {
  padding: 0px 0px;
}
.styles_notesPopupContainer__I8lNO .styles_notesContainer__0CgY\\+ .styles_labelContainer__YLkLS {
  flex-basis: initial;
}
.styles_notesPopupContainer__I8lNO .styles_notesContainer__0CgY\\+ .styles_dividerStyle__1kTRU {
  margin: 10px auto 30px auto;
  width: 80px;
  height: 3px;
  background: var(--green1);
}
.styles_notesPopupContainer__I8lNO .styles_notesContainer__0CgY\\+ .styles_deleteText__XpusV {
  font-weight: var(--font-normal);
  font-size: var(--font-16);
  text-align: center;
}
.styles_notesPopupContainer__I8lNO .styles_notesContainer__0CgY\\+ .styles_btnContainer__g66H2 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 35px 0;
}
.styles_notesPopupContainer__I8lNO .styles_notesContainer__0CgY\\+ .styles_btnContainer__g66H2 .styles_yesButtonStyle__HjOL3 {
  width: 100px;
}
.styles_notesPopupContainer__I8lNO .styles_notesContainer__0CgY\\+ .styles_btnContainer__g66H2 .styles_noButtonStyle__bBNIK {
  color: var(--blue1);
  border: 1px solid var(--blue1);
  background: var(--white1);
  width: 100px;
}`, "",{"version":3,"sources":["webpack://./src/pages/doctor-diagnosis/treatment/save-treatment-plans-popup/styles.module.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;EAEA,YAAA;EACA,yBAAA;EACA,mBAAA;EACA,aAAA;AAAF;AACE;EACE,eAAA;EACA,YAAA;AACJ;AACE;EACE,gBAAA;AACJ;AAAI;EACE,kBAAA;EACA,iCAAA;EACA,yBAAA;EACA,iBAAA;EACA,oBAAA;AAEN;AACI;EACE,gBAAA;AACN;AAEI;EACE,mBAAA;AAAN;AAEI;EACE,2BAAA;EACA,WAAA;EACA,WAAA;EACA,yBAAA;AAAN;AAEI;EACE,+BAAA;EACA,yBAAA;EACA,kBAAA;AAAN;AAEI;EACE,aAAA;EACA,mBAAA;EACA,mBAAA;EACA,uBAAA;EACA,cAAA;AAAN;AACM;EACE,YAAA;AACR;AACM;EACE,mBAAA;EACA,8BAAA;EACA,yBAAA;EACA,YAAA;AACR","sourcesContent":[".notesPopupContainer {\n  width: 450px;\n  // height: 250px;\n  margin: auto;\n  background: var(--white1);\n  border-radius: 10px;\n  padding: 10px;\n  .closeIconStyle {\n    cursor: pointer;\n    float: right;\n  }\n  .notesContainer {\n    margin-top: 50px;\n    .title {\n      text-align: center;\n      font-weight: var(--font-semibold);\n      font-size: var(--font-24);\n      line-height: 36px;\n      color: var(--black2);\n    }\n\n    .inputContainer{\n      padding: 0px 0px;\n    }\n\n    .labelContainer {\n      flex-basis: initial;\n    }\n    .dividerStyle {\n      margin: 10px auto 30px auto;\n      width: 80px;\n      height: 3px;\n      background: var(--green1);\n    }\n    .deleteText {\n      font-weight: var(--font-normal);\n      font-size: var(--font-16);\n      text-align: center;\n    }\n    .btnContainer {\n      display: flex;\n      flex-direction: row;\n      align-items: center;\n      justify-content: center;\n      margin: 35px 0;\n      .yesButtonStyle {\n        width: 100px;\n      }\n      .noButtonStyle {\n        color: var(--blue1);\n        border: 1px solid var(--blue1);\n        background: var(--white1);\n        width: 100px;\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"notesPopupContainer": `styles_notesPopupContainer__I8lNO`,
	"closeIconStyle": `styles_closeIconStyle__b86mU`,
	"notesContainer": `styles_notesContainer__0CgY+`,
	"title": `styles_title__CcuB9`,
	"inputContainer": `styles_inputContainer__wn9Ct`,
	"labelContainer": `styles_labelContainer__YLkLS`,
	"dividerStyle": `styles_dividerStyle__1kTRU`,
	"deleteText": `styles_deleteText__XpusV`,
	"btnContainer": `styles_btnContainer__g66H2`,
	"yesButtonStyle": `styles_yesButtonStyle__HjOL3`,
	"noButtonStyle": `styles_noButtonStyle__bBNIK`
};
export default ___CSS_LOADER_EXPORT___;
