import { FC } from 'react'
import { colors } from '../../../../constants/color'
import { useAppDispatch } from '../../../../hooks'
import { useNavigate } from 'react-router-dom'
import { requestGenerator } from '../../../../utils/payloadGenerator'
import { deleteEmployee } from '../../../../redux/features/employee/employeeAsyncActions'
import { CloseIcon } from '../../../../components/common/svg-components'
import Divider from '../../../../components/common/divider/Divider'
import Button from '../../../../components/common/button/Button'
import { DELETE_EMPLOYEE } from '../../../../constants/asyncActionsType'
import styles from './employeeDeletePopup.module.scss'
import { useTranslation } from 'react-i18next'
interface IDeletePopup {
  popData?: any
  handleClose?: any
}
const EmployeeDeletePopup: FC<IDeletePopup> = ({ popData, handleClose }) => {
  const dispatch = useAppDispatch()
  const { t } = useTranslation()
  const navigate = useNavigate()
  const handleYes = () => {
    let deletePayload = {
      emp_id: popData.emp_id,
    }

    dispatch(
      deleteEmployee(
        requestGenerator({
          ...deletePayload,
        })
      )
    ).then((e: any) => {
      if (e.type === `${DELETE_EMPLOYEE}/fulfilled`) {
        navigate('/employees')
      }
    })

    handleClose()
  }
  return (
    <>
      <div
        className={styles.deletePopupContainer}
        onClick={(e) => {
          e.stopPropagation()
        }}
      >
        <CloseIcon
          customClass={styles.closeIconStyle}
          fillColor={colors.green1}
          handleClick={() => handleClose()}
        />
        <div className={styles.deleteContainer}>
          <p className={styles.title}>{t('Receptionist Dashboard.Delete')}</p>
          <Divider customClass={styles.dividerStyle} />

          <div className={styles.confirmationContainer}>
            <p className={styles.confirmationText}>
              {t('Common.SureWantDelete')}
            </p>
            <div className={styles.buttonContainer}>
              <Button
                title={t('Common.Yes') || 'Yes'}
                customClass={styles.yesBtn}
                handleClick={handleYes}
              />
              <Button
                title={t('Common.No') || 'No'}
                customClass={styles.noBtn}
                handleClick={() => handleClose()}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default EmployeeDeletePopup
