import { useState, useEffect, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import { DropDownIcon } from '../../components/common/svg-components/index'
import moment from 'moment'
import { getPatientEmrById } from '../../redux/features/patient-emr/patient/patientAsyncAction'
import { requestGenerator } from '../../utils/payloadGenerator'
import { useAppDispatch, useAppSelector } from '../../hooks/index'
import { setSingleMobileAppointmentRequestData } from '../../redux/features/mobile-appointment-request/mobileAppointmentRequestSlice'
import styles from './mobileAppointmentTable.module.scss'
import { t } from 'i18next'
import { filterAppointmentStatusList } from '../../utils/utils'
import { Pending } from '../data'

export const mobileAppointmentHeaderData: any = [
  {
    Header: t('RcptTableHeader.DATE'),
    accessor: 'apt_date',
    Cell: ({ row }: any) => {
      const convertDate = moment(row?.original?.apt_date).format('DD-MM-YYYY')
      return <>{row?.original?.apt_date ? <span>{convertDate}</span> : '-'}</>
    },
  },
  {
    Header: t('RcptTableHeader.FILE NO'),
    accessor: 'patient_emr',
    Cell: ({ row }: any) => {
      const dispatch = useAppDispatch()
      const navigate = useNavigate()
      const handleEmrRecord = (item: any) => {
        let dataPayload = {
          id: item,
        }
        dispatch(getPatientEmrById(requestGenerator(dataPayload))).then((e) => {
          if (e.type === 'patient/getPatientEmrById/fulfilled') {
            navigate('/patientemr')
          }
        })
      }
      return (
        <span
          onClick={() => {
            handleEmrRecord(row?.original?.patient_id)
          }}
          className={styles.viewEmrLink}
        >
          {row?.original?.patient_emr}
        </span>
      )
    },
  },
  {
    Header: t('RcptTableHeader.PATIENT'),
    accessor: 'patient_name',
  },
  {
    Header: t('RcptTableHeader.MOBILE NO'),
    accessor: 'patient_phone',
  },
  {
    Header: t('MobileAppointmentReq.APPOINTMENT TYPE'),
    accessor: 'apt_type',
    Cell: ({ row }: any) => {
      return (
        <>
          {row?.original?.apt_type ? (
            <span>{row?.original?.apt_type.toLowerCase()}</span>
          ) : (
            '-'
          )}
        </>
      )
    },
  },
  {
    Header: t('MobileAppointmentReq.APPOINTMENT TIME'),
    accessor: 'apt_time',
    Cell: ({ row }: any) => {
      return (
        <>
          {row?.original?.apt_time ? (
            <span>{row?.original?.apt_time}</span>
          ) : (
            '-'
          )}
        </>
      )
    },
  },
  {
    Header: t('ViewAppointmentHeader.DOCTOR'),
    accessor: 'doctor_name',
    Cell: ({ row }: any) => {
      return (
        <>
          {row?.original?.doctor_name ? (
            <span>Dr. {row?.original?.doctor_name}</span>
          ) : (
            '-'
          )}
        </>
      )
    },
  },
  {
    Header: t('RcptTableHeader.STATUS'),
    // accessor: "apt_status",
    Cell: ({ row, onClick, onOpen }: any) => {
      const dispatch = useAppDispatch()
      const { colorSchemeList } = useAppSelector((state) => state.login)
      const [showOption, setShowOption] = useState<boolean>(false)
      const statusRef = useRef<any>()
      const optionData = filterAppointmentStatusList(colorSchemeList, Pending)
      const [statusValue, setStatusValue] = useState<any>(optionData[0])

      // function for close dropdown
      useEffect(() => {
        const checkIfClickedOutside = (e: any) => {
          if (
            showOption &&
            statusRef.current &&
            !statusRef.current.contains(e.target)
          ) {
            setShowOption(false)
          }
        }

        document.addEventListener('mousedown', checkIfClickedOutside)

        return () => {
          document.removeEventListener('mousedown', checkIfClickedOutside)
        }
      }, [showOption])

      // show status selected option
      useEffect(() => {
        const selectedOption = optionData.find((option) => {
          return option.label === row?.original?.apt_status
        })
        setStatusValue(selectedOption)
      }, [row?.original?.apt_status, optionData])

      const handleStatus = (item: any) => {
        window.scrollTo(0, 0)

        const mobileBookAppointment = { ...row?.original }
        mobileBookAppointment.apt_status = item?.label

        if (item?.label === 'PENDING' || statusValue?.label === 'SCHEDULED') {
          setShowOption((prevState) => !prevState)
        } else if (item?.label === 'WAITINGLIST') {
          if (
            statusValue?.label === 'WAITINGLIST' ||
            statusValue?.label === 'CANCELLED'
          ) {
            setShowOption((prevState) => !prevState)
          } else {
            setShowOption((prevState) => !prevState)
            dispatch(
              setSingleMobileAppointmentRequestData(mobileBookAppointment)
            )
            onClick()
          }
        } else if (item?.label === 'SCHEDULED') {
          if (
            statusValue?.label === 'PENDING' ||
            statusValue?.label === 'WAITINGLIST'
          ) {
            setShowOption((prevState) => !prevState)
            mobileBookAppointment.previous_status = statusValue?.label
            dispatch(
              setSingleMobileAppointmentRequestData(mobileBookAppointment)
            )
            onClick()
          } else {
            setShowOption((prevState) => !prevState)
          }
        } else if (item?.label === 'CANCELLED') {
          if (statusValue?.label === 'CANCELLED') {
            setShowOption((prevState) => !prevState)
          } else {
            const payload = {
              appointment_id: row?.original?.apt_id,
              status: item?.label,
            }
            setShowOption((prevState) => !prevState)
            onOpen(payload)
          }
        } else if (statusValue?.label === 'CANCELLED') {
          setShowOption((prevState) => !prevState)
        } else {
          setShowOption((prevState) => !prevState)
          dispatch(setSingleMobileAppointmentRequestData(mobileBookAppointment))
          onClick()
        }
      }

      const handleDropdown = () => {
        setShowOption((prevState) => !prevState)
      }

      return (
        <>
          <div className={styles.dropdownContainer} ref={statusRef}>
            <div
              className={styles.status}
              onClick={handleDropdown}
              style={{
                color: statusValue?.colorCode,
                backgroundColor: statusValue?.bgColor,
              }}
            >
              <p>{statusValue?.title}</p>
              <DropDownIcon
                fillColor={statusValue?.colorCode}
                customClass={styles.iconStyle}
              />
            </div>
            {showOption && (
              <div className={styles.optionContainer}>
                {optionData.map((item: any, index) => {
                  return (
                    <div
                      className={styles.status}
                      style={{
                        color: item?.colorCode,
                        backgroundColor: item?.bgColor,
                      }}
                      onClick={() => handleStatus(item)}
                      key={index}
                    >
                      <p>{item.title}</p>
                      {/* <DropDownIcon
                        fillColor={item.color}
                        customClass={styles.iconStyle}
                      /> */}
                    </div>
                  )
                })}
              </div>
            )}
          </div>
        </>
      )
    },
  },
  {
    Header: t('MobileAppointmentReq.PRICING'),
    accessor: 'apt_pricing',
    Cell: ({ row }: any) => {
      return (
        <>
          {row?.original?.apt_pricing ? (
            <span>{row?.original?.apt_pricing}</span>
          ) : (
            '-'
          )}
        </>
      )
    },
  },
  {
    Header: t('MobileAppointmentReq.SESSION TIME(SLOT)'),
    accessor: 'apt_slot',
    Cell: ({ row }: any) => {
      return (
        <>
          {row?.original?.apt_slot ? (
            <span>
              {row?.original?.apt_slot} {t('')}
            </span>
          ) : (
            '-'
          )}
        </>
      )
    },
  },
]
