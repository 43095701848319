import { useTranslation } from 'react-i18next'
import Button from '../../../components/common/button/Button'
import styles from './styles.module.scss'
import { Label } from '../../../components/common/label'
import { useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../../hooks'
import { IImportInventoryItems, fileType } from '../../../interfaces/interfaces'
import { useForm } from 'react-hook-form'
import { ITEM_FILE } from '../import-constant'
import { importValidation } from './validators'
import AttachFiles from '../../../components/common/attach-files/single-file/AttachSingleFile'
import { dataURI } from '../../../utils/utils'
import { requestGenerator } from '../../../utils/payloadGenerator'
import { importInvItemsUtility } from '../../../redux/features/import-utility/importUtilityAsyncActions'
import { clearImportData } from '../../../redux/features/import-utility/importUtilitySlice'

const ImportInventoryItemForm = () => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const { inValidData } = useAppSelector((state) => state.import)
  const [itemField, setItemField] = useState({ name: '', data_uri: '' })

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm<IImportInventoryItems>({})

  const itemFieldValue = watch(ITEM_FILE)
  const fileName = itemFieldValue?.[0]

  useEffect(() => {
    const fileList: fileType = { name: '', data_uri: '' }
    const getDataURI = async (fileName: File) => {
      try {
        const result = await dataURI(fileName)
        fileList.data_uri = result
        fileList.name = fileName.name

        setItemField(fileList)
      } catch (error) {
        console.log({ error })
      }
    }
    if (fileName) {
      getDataURI(fileName)
    }
  }, [fileName])

  const onSubmit = (data: any) => {
    const payload = {
      [ITEM_FILE]: itemField,
    }
    dispatch(importInvItemsUtility(requestGenerator(payload))).then((e) => {
      setValue(ITEM_FILE, undefined)
      setItemField({ name: '', data_uri: '' })
    })
  }

  useEffect(() => {
    if (inValidData?.length !== 0 && itemField.name) {
      dispatch(clearImportData())
    }
  }, [dispatch, itemField])

  return (
    <form className={styles.container} onSubmit={handleSubmit(onSubmit)}>
      <div className={styles.importFieldContainer}>
        <div className={styles.inputFieldContainer}>
          <Label
            labelText={t('Common.Upload')}
            requiredField={true}
            flexBasis="30%"
          />
          <div className={styles.inlineItems}>
            <AttachFiles
              register={register}
              fileKey={ITEM_FILE}
              id={ITEM_FILE}
              fileList={itemField}
              validation={importValidation[ITEM_FILE]}
              isName={true}
              isDocument={true}
              showCsv={true}
            />
            {errors[ITEM_FILE] && (
              <p className="errorText">{errors[ITEM_FILE].message as any}</p>
            )}
          </div>
        </div>
      </div>
      <div className={styles.buttonContainer}>
        <Button title={t('Common.Submit') || 'Submit'} />
      </div>
    </form>
  )
}

export default ImportInventoryItemForm
