// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.inputTextfield_mainContainer__0pcm6 {
  display: flex;
  flex-direction: row;
  margin-bottom: 25px;
}
.inputTextfield_mainContainer__0pcm6 .inputTextfield_labelInputField__\\+QPqi {
  align-self: center;
  font-weight: var(--font-semibold);
  font-size: var(--font-16);
  color: var(--black2);
  width: 88px;
  border-radius: 6px;
  border: 1px solid var(--grey6);
  padding: 10px 5px;
  margin-right: 10px;
}
.inputTextfield_mainContainer__0pcm6 .inputTextfield_disabledlabelInputField__qaLvZ {
  align-self: center;
  font-weight: var(--font-semibold);
  font-size: var(--font-16);
  color: var(--black2);
  width: 88px;
  border-radius: 6px;
  border: 1px solid var(--grey6);
  padding: 10px 5px;
  margin-right: 10px;
}
.inputTextfield_mainContainer__0pcm6 .inputTextfield_inputField__C6X\\+w {
  background: var(--white1);
  width: 188px;
  border: 1px solid var(--grey6);
  border-radius: 6px;
  padding: 10px 15px;
}
.inputTextfield_mainContainer__0pcm6 .inputTextfield_crossIconStyle__peXj6 {
  align-self: center;
  margin: 0px 0px 0px 5px;
}`, "",{"version":3,"sources":["webpack://./src/components/common/input-textfield/inputTextfield.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,mBAAA;EACA,mBAAA;AACF;AAAE;EACE,kBAAA;EACA,iCAAA;EACA,yBAAA;EACA,oBAAA;EACA,WAAA;EACA,kBAAA;EACA,8BAAA;EACA,iBAAA;EACA,kBAAA;AAEJ;AAAE;EACE,kBAAA;EACA,iCAAA;EACA,yBAAA;EACA,oBAAA;EACA,WAAA;EACA,kBAAA;EACA,8BAAA;EACA,iBAAA;EACA,kBAAA;AAEJ;AAAE;EACE,yBAAA;EACA,YAAA;EACA,8BAAA;EACA,kBAAA;EACA,kBAAA;AAEJ;AAAE;EACE,kBAAA;EACA,uBAAA;AAEJ","sourcesContent":[".mainContainer {\n  display: flex;\n  flex-direction: row;\n  margin-bottom: 25px;\n  .labelInputField {\n    align-self: center;\n    font-weight: var(--font-semibold);\n    font-size: var(--font-16);\n    color: var(--black2);\n    width: 88px;\n    border-radius: 6px;\n    border: 1px solid var(--grey6);\n    padding: 10px 5px;\n    margin-right: 10px;\n  }\n  .disabledlabelInputField {\n    align-self: center;\n    font-weight: var(--font-semibold);\n    font-size: var(--font-16);\n    color: var(--black2);\n    width: 88px;\n    border-radius: 6px;\n    border: 1px solid var(--grey6);\n    padding: 10px 5px;\n    margin-right: 10px;\n  }\n  .inputField {\n    background: var(--white1);\n    width: 188px;\n    border: 1px solid var(--grey6);\n    border-radius: 6px;\n    padding: 10px 15px;\n  }\n  .crossIconStyle {\n    align-self: center;\n    margin: 0px 0px 0px 5px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"mainContainer": `inputTextfield_mainContainer__0pcm6`,
	"labelInputField": `inputTextfield_labelInputField__+QPqi`,
	"disabledlabelInputField": `inputTextfield_disabledlabelInputField__qaLvZ`,
	"inputField": `inputTextfield_inputField__C6X+w`,
	"crossIconStyle": `inputTextfield_crossIconStyle__peXj6`
};
export default ___CSS_LOADER_EXPORT___;
