import TableV2 from '../../../components/common/table/tableV2/TableV2'
import moment from 'moment'
import { useAppSelector } from '../../../hooks'
import styles from './styles.module.scss'
import jsPDF from 'jspdf'
import Loader from '../../../components/common/spinner/Loader'
import { t } from 'i18next'

const PoGrnHeaderData: any = [
  {
    Header: t('RcptTableHeader.DATE'),
    accessor: (row: any) => {
      return moment(row?.receive_date).format('DD-MMM-YYYY') ?? '-'
    },
    disableSortBy: true,
  },
  {
    Header: t('MainStore.GRN DOC ID'),
    // accessor: "doc_id",
    Cell: (props: any) => {
      const mainStoreRequestEditPopupOpen = () => {
        if (props?.row?.original?._id) {
          // Create a new PDF document
          const doc = new jsPDF()
          let data = props.row.original
          // Set the document font size
          doc.setFontSize(12)
          const { grn_no, supplier_name } = data
          doc.text(`Grn No: ${grn_no}`, 10, 10)
          doc.text(`Supplier Name: ${supplier_name}`, 10, 20)
          doc.save('document.pdf')
          window.open(doc.output('bloburl'), '_blank')
        }
      }

      return (
        <>
          {props?.row?.original?.doc_id ? (
            <span
              className={styles.viewPdfLink}
              onClick={mainStoreRequestEditPopupOpen}
            >
              {props?.row?.original?.doc_id}
            </span>
          ) : (
            '-'
          )}
        </>
      )
    },
  },
]

const PoGrnData = () => {
  const { grnList, isLoading } = useAppSelector(
    (state) => state.purchaseInvoice
  )
  return (
    <div style={{ marginTop: '30px', maxHeight: '250px', overflow: 'auto' }}>
      {isLoading && <Loader />}
      <TableV2 tableHeaderData={PoGrnHeaderData} tableRowData={grnList} />
    </div>
  )
}

export default PoGrnData
