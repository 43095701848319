import React, { FC, useCallback, useEffect, useState } from 'react'
import styles from './hrDashboard.module.scss'
import { useNavigate } from 'react-router-dom'
import {
  CalenderFileIcon,
  DepartmentsIcon,
  DocumentsIcon,
  EmployeesIcon,
} from '../../../components/common/svg-components'
import Divider from '../../../components/common/divider/Divider'
import { useAppDispatch, useAppSelector } from '../../../hooks'
import Loader from '../../../components/common/spinner/Loader'
import { requestGenerator } from '../../../utils/payloadGenerator'
import moment from 'moment'
import Select from 'react-select'
import makeAnimated from 'react-select/animated'
import { utcToDate } from '../../../utils/utils'
import {
  getAllCounts,
  getAllDashboard,
} from '../../../redux/features/hr-dashboard/hrDashboardAsyncActions'
import { getAllBranch } from '../../../redux/features/branch/branchAsyncActions'
import { useTranslation } from 'react-i18next'

interface IHrDashboard {}

const HrDashboard: FC<IHrDashboard> = () => {
  const dispatch = useAppDispatch()
  const { t } = useTranslation()
  // Redux state
  const [subCompanyFilter, setSubCompanyFilter] = useState<any>(null)
  const { isLoading, allDashboardData } = useAppSelector(
    (state) => state.hrdashboard
  )
  const { branchData } = useAppSelector((state) => state.branch)

  const totalDepartmentCount = useAppSelector(
    (state) =>
      state.hrdashboard?.allCountData?.dashboardCountObj?.totalDepartmentCount
  )
  const totalEmployeesCount = useAppSelector(
    (state) =>
      state.hrdashboard?.allCountData?.dashboardCountObj?.totalEmployeesCount
  )
  const totalDocumentCount = useAppSelector(
    (state) =>
      state.hrdashboard?.allCountData?.dashboardCountObj?.expiringDocumentsCount
  )
  // company_details
  const fetchDashboard = useCallback(() => {
    let payloadData = {
      branch_id: subCompanyFilter?.value,
    }
    dispatch(getAllDashboard(requestGenerator(payloadData)))
  }, [dispatch, subCompanyFilter])

  const fetchCount = useCallback(() => {
    let payloadData = {}
    dispatch(getAllCounts(requestGenerator(payloadData)))
  }, [dispatch])

  useEffect(() => {
    fetchDashboard()
  }, [fetchDashboard])

  useEffect(() => {
    fetchCount()
  }, [fetchCount])

  const navigate = useNavigate()
  const animatedComponent = makeAnimated()
  const customDropdownStyles = {
    control: (provided: any) => ({
      ...provided,
      boxShadow: 'none',
    }),
    input: (provided: any) => ({
      ...provided,
      cursor: 'pointer',
    }),
    option: (provided: any) => ({
      ...provided,
      padding: '8px',
      cursor: 'pointer',
    }),
    indicatorSeparator: () => ({
      display: 'none',
    }),
    dropdownIndicator: (provided: any, state: any) => ({
      ...provided,
      color: '#797979',
      transform: state.selectProps.menuIsOpen ? 'rotate(180deg)' : null,
    }),
    clearIndicator: (provided: any) => ({
      ...provided,
      display: 'none',
    }),
  }
  useEffect(() => {
    let data = {
      search: '',
      page: 1,
      pageSize: 1000,
    }
    dispatch(getAllBranch(requestGenerator(data)))
  }, [dispatch])

  const calculateYearBetweenDates = (selectedDate: any) => {
    let today = new Date()
    let dob = new Date(selectedDate)
    let diff = today.getTime() - dob.getTime()
    let years = Math.floor(diff / 31556736000)
    let workStr = years > 0 ? `${years} years` : ''
    return workStr
  }
  return (
    <>
      {isLoading && <Loader />}
      <div className={styles.top}>
        <div className={styles.tab}>
          <div className={styles.data} onClick={() => navigate('/employees')}>
            <div className={styles.left}>
              <EmployeesIcon />
            </div>
            <div className={styles.right}>
              <h4>{totalEmployeesCount}</h4>
              <p>{t('HrDashboard.Total Employees')}</p>
            </div>
          </div>
        </div>
        <div className={styles.tab}>
          <div className={styles.datas}>
            <div className={styles.left}>
              <DepartmentsIcon />
            </div>
            <div className={styles.right}>
              <h4>{totalDepartmentCount}</h4>
              <p>{t('HrDashboard.Total Departments')}</p>
            </div>
          </div>
        </div>
        <div className={styles.tab}>
          <div className={styles.data} onClick={() => navigate('/documents')}>
            <div className={styles.left}>
              <DocumentsIcon />
            </div>
            <div className={styles.right}>
              <h4>{totalDocumentCount}</h4>
              <p>{t('HrDashboard.Documents Expiring')}</p>
            </div>
          </div>
        </div>
        <div className={styles.select}>
          <p className={styles.branchTitle}>{t('Header.Branch')}</p>
          <Select
            className={styles.selectInputField}
            placeholder={
              <span className="selectPlaceholder">
                {t('ManageStaff.Branch_placeholder')}
              </span>
            }
            options={branchData?.map((item: any) => ({
              label: item?.name,
              value: item?._id,
            }))}
            value={subCompanyFilter || ''}
            onChange={(e: any) => {
              setSubCompanyFilter(e)
            }}
            isSearchable={true}
            components={animatedComponent}
            closeMenuOnSelect={true}
            maxMenuHeight={130}
            styles={customDropdownStyles}
          />
        </div>
      </div>

      <div className={styles.emmployeecontainer}>
        <div className={styles.birthdayContainer}>
          <div className={styles.birthdayHeaderContainer}>
            <p className={styles.birthdayTitle}>{t('HrDashboard.Birthday')}</p>
          </div>
          <div className={styles.mainbirthdaycontainer}>
            <div className={styles.container}>
              {allDashboardData?.dashboardDataObj?.birthdayList.length > 0 ? (
                allDashboardData?.dashboardDataObj?.birthdayList?.map(
                  (item: any, i: any) => {
                    const date_formated = moment(item?.birth_date).format(
                      'DD-MMM'
                    )
                    const map_date_formated = moment(item?.birth_date).format(
                      'DD-MM'
                    )
                    const today = moment().format('DD-MM')
                    return (
                      <React.Fragment key={i}>
                        <div className={styles.notifyContainer}>
                          <div className={styles.unReadStyle}>
                            <div className={styles.titleStatusContainer}>
                              <div className={styles.profile}>
                                {item?.name_en ? (
                                  <img src={item?.profile_pic} alt="" />
                                ) : (
                                  ''
                                )}
                              </div>
                              <h5 className={styles.titleStyle}>
                                {item?.name_en}
                              </h5>
                              <div
                                className={styles.priorityListStyleContainer}
                              >
                                <div className={styles.lowPriorityStyle}>
                                  {today === map_date_formated ? (
                                    <div
                                      className={styles.lowPriorityStyleGreen}
                                    >
                                      {t('HrDashboard.Today')}
                                    </div>
                                  ) : (
                                    <div className={styles.lowPriorityStyles}>
                                      {date_formated}
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                            <p className={styles.iconStyle}>
                              {item?.company_details?.designation}
                            </p>
                          </div>
                          <Divider customClass={styles.divider} />
                        </div>
                      </React.Fragment>
                    )
                  }
                )
              ) : (
                <h3>{t('Common.No records found')}</h3>
              )}
            </div>
          </div>
        </div>

        {/* Holiday Div */}
        {/* <div className={styles.holidayContainer}>
          <div className={styles.holidayHeaderContainer}>
            <p className={styles.holidayTitle}>Current Month: Holidays</p>
          </div>
          <div className={styles.holidaymaincontainer}>
            <div className={styles.container}>
              {allDashboardData?.dashboardDataObj?.holidayList.length > 0 ? (
                holidayList?.map((item: any, i: any) => {
                  const map_date_formated = moment(item?.date).format("DD MMMM YYYY");
                  const today = moment().format("DD-MM");
                  return (
                    <React.Fragment key={i}>
                      <div className={styles.notifyContainer}>
                        <div className={styles.unReadStyle}>
                          <div className={styles.titleStatusContainer}>
                            <h5 className={styles.titleStyle}>{item?.name}</h5>
                            <div className={styles.priorityListStyleContainer}>
                              {today === map_date_formated ? (
                                <div className={styles.lowPriorityStyle}>
                                  Today
                                </div>
                              ) : ("")
                              }
                            </div>
                          </div>
                          <p className={styles.iconStyle}>{map_date_formated}</p>
                        </div>
                        <Divider customClass={styles.divider} />
                      </div>
                    </React.Fragment>
                  );
                })
              ) : (
                <h3>No Record Found</h3>
              )}
            </div>
          </div>
        </div>  */}

        {/* Work Anivarsaries */}
        <div className={styles.anniversariesContainer}>
          <div className={styles.anniversariesHeaderContainer}>
            <p className={styles.anniversariesTitle}>
              {t('HrDashboard.Work Anniversaries')}
            </p>
          </div>
          <div className={styles.mainanniversariescontainer}>
            <div className={styles.container}>
              {allDashboardData?.dashboardDataObj?.anniversaryList.length >
              0 ? (
                allDashboardData?.dashboardDataObj?.anniversaryList?.map(
                  (item: any, i: any) => {
                    const join = moment(
                      item?.company_details?.joining_date
                    ).format('DD-MM')
                    const todays = moment().format('DD-MM')
                    const joinedate = moment(
                      item?.company_details?.joining_date
                    ).format('DD MMMM YYYY')
                    let employeeAtWork = joinedate
                    const formatData: any = employeeAtWork
                      ? utcToDate(employeeAtWork, true)
                      : ''
                    let getEmployeeAtWorkDuration =
                      calculateYearBetweenDates(formatData)
                    return (
                      <React.Fragment key={i}>
                        <div className={styles.notifyContainer}>
                          <div className={styles.unReadStyle}>
                            <div className={styles.titleStatusContainer}>
                              <div className={styles.profile}>
                                {item?.name_en ? (
                                  <img src={item?.profile_pic} alt="" />
                                ) : (
                                  ''
                                )}
                              </div>
                              <h5 className={styles.titleStyle}>
                                {item?.name_en}
                              </h5>
                              <div
                                className={styles.priorityListStyleContainer}
                              >
                                {todays === join ? (
                                  <div className={styles.lowPriorityStyle}>
                                    {t('HrDashboard.Today')}
                                  </div>
                                ) : (
                                  ''
                                )}
                              </div>
                            </div>
                            <p className={styles.iconStyle}>
                              {item?.company_details?.designation}
                            </p>
                            <p className={styles.iconStyles}>
                              <CalenderFileIcon />
                              {/* {getEmployeeAtWorkDuration?.length >1 ?()} */}
                              <span className={styles.text}>
                                {joinedate} | {getEmployeeAtWorkDuration}
                              </span>
                            </p>
                          </div>
                          <Divider customClass={styles.divider} />
                        </div>
                      </React.Fragment>
                    )
                  }
                )
              ) : (
                <h3>{t('Common.No records found')}</h3>
              )}
            </div>
          </div>
        </div>

        {/* People on Leave   */}
        <div className={styles.leaveContainer}>
          <div className={styles.leaveHeaderContainer}>
            <p className={styles.leaveTitle}>
              {t('HrDashboard.People on Leave')}
            </p>
          </div>
          <div className={styles.leavemaincontainer}>
            <div className={styles.container}>
              {allDashboardData?.dashboardDataObj?.leaveList.length > 0 ? (
                allDashboardData?.dashboardDataObj?.leaveList?.map(
                  (item: any, i: any) => {
                    const today = moment().format('DD-MM')
                    return (
                      <React.Fragment key={i}>
                        <div className={styles.notifyContainer}>
                          <div className={styles.unReadStyle}>
                            <div className={styles.titleStatusContainer}>
                              <div className={styles.profile}>
                                {item?.employeeData?.name_en ? (
                                  <img src={item?.profile_pic} alt="" />
                                ) : (
                                  ''
                                )}
                              </div>
                              <h5 className={styles.titleStyle}>
                                {item?.employeeData?.name_en}
                              </h5>
                              <div
                                className={styles.priorityListStyleContainer}
                              >
                                {today ? (
                                  <div className={styles.highPriorityStyle}>
                                    {t('HrDashboard.Leave')}
                                  </div>
                                ) : (
                                  ''
                                )}
                              </div>
                            </div>
                            <p className={styles.iconStyle}>
                              {item?.employeeData?.company_details?.designation}
                            </p>
                          </div>
                          <Divider customClass={styles.divider} />
                        </div>
                      </React.Fragment>
                    )
                  }
                )
              ) : (
                <h3>{t('Common.No records found')}</h3>
              )}
            </div>
          </div>
        </div>

        {/* Rejoining Today */}
        <div className={styles.rejoiningContainer}>
          <div className={styles.rejoiningHeaderContainer}>
            <p className={styles.rejoiningTitle}>
              {t('HrDashboard.Rejoining Today')}
            </p>
          </div>
          <div className={styles.rejoiningmaincontainer}>
            <div className={styles.container}>
              {allDashboardData?.dashboardDataObj?.leaveList.length > 0 ? (
                allDashboardData?.dashboardDataObj?.rejoinedList?.map(
                  (item: any, i: any) => {
                    const today = moment().format('DD-MM')
                    return (
                      <React.Fragment key={i}>
                        <div className={styles.notifyContainer}>
                          <div className={styles.unReadStyle}>
                            <div className={styles.titleStatusContainer}>
                              <div className={styles.profile}>
                                {item?.employeeData?.phone ? (
                                  <img src={item?.profile_pic} alt="" />
                                ) : item?.employeeData?.name_en ? (
                                  <img src={item?.file} alt="" />
                                ) : item?.employeeData?.name_en ? (
                                  <img src={item?.file} alt="" />
                                ) : item?.employeeData?.name_en ? (
                                  <img src={item?.file} alt="" />
                                ) : (
                                  ''
                                )}
                              </div>
                              <h5 className={styles.titleStyle}>
                                {item?.employeeData?.name_en}
                              </h5>
                              <div
                                className={styles.priorityListStyleContainer}
                              >
                                {today ? (
                                  <div className={styles.lowPriorityStyle}>
                                    {t('HrDashboard.Rejoined')}
                                  </div>
                                ) : (
                                  ''
                                )}
                              </div>
                            </div>
                            <p className={styles.iconStyle}>
                              {item?.employeeData?.company_details?.designation}
                            </p>
                          </div>
                          <Divider customClass={styles.divider} />
                        </div>
                      </React.Fragment>
                    )
                  }
                )
              ) : (
                <h3>{t('Common.No records found')}</h3>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
export default HrDashboard
