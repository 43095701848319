import { useEffect, useState } from 'react'
import styles from './mainstore.module.scss'
import Popup from '../../../components/common/popup/Popup'
import SubmittedPoPopup from '../submitted-po-popup/SubmittedPoPopup'
import PoSummaryPopup from '../po-summary-popup/PoSummaryPopup'
import Button from '../../../components/common/button/Button'
import MainStoreRequestPopup from '../mainstore-request-popup/MainStoreRequestPopup'
import SubmittedPobtnPopup from '../submitpo-popup/submitPobtnPopup'
import DirectPoPopup from '../direct-po-popup/DirectPoPopup'
import AddGrnPopup from '../add-grn-popup/AddGrnPopup'
import PendingPoPopup from '../pending-po-popup/PendingPoPopup'
import AdditionalItemsPoPopup from '../additional-items-popup/AdditionalItemsPopup'
import { issueMainStoreHeaderData } from './issueMainStoreTableData'
import { poMainStoreHeaderData } from './poMainStoreTableData'
import SmartSearch from '../../../components/common/smart-search/SmartSearch'
import {
  Column,
  TableOptions,
  useGlobalFilter,
  useSortBy,
  useTable,
} from 'react-table'
import TableV3 from '../../../components/common/table/tableV3/TableV3'
import { useAppDispatch, useAppSelector } from '../../../hooks'
import Select from 'react-select'
import { CustomModal } from '../../../components/common/custom-modal/modal'
import { requestGenerator } from '../../../utils/payloadGenerator'
import { addInventoryIssueDataAll } from '../../../redux/features/inventory-request/inventoryRequestAsyncActions'
import {
  clearInventoryIssueData,
  emptyAllPoData,
  emptyIssueCheckStatus,
  setInventoryUniquePoData,
  setIssueId,
  setRequestSourceEvent,
  setRequestSourceTypeEvent,
} from '../../../redux/features/inventory-request/inventoryRequestSlice'
import MainStoreIssueItem from './issue-items/page'
import { DropdownIndicator } from '../../../components/common/dropdown-indicator/DropdownIndicator'
import SearchDropDown from '../../../components/common/search-dropdown/SearchDropDown'
import { requestDropdownData } from '../../branchstore/branchStoreData'
import { getBranchStoreRoomsList } from '../../../redux/features/branch-store/branchStoreAsyncActions'
import { getAllManageUser } from '../../../redux/features/manage-user/ManageUserAsynActions'
import Loader from '../../../components/common/spinner/Loader'
import { useTranslation } from 'react-i18next'

const MainStore = () => {
  const [tabs, setTabs] = useState('Issue')
  const { t } = useTranslation()
  const { userData, branchData } = useAppSelector((state) => state.login)

  const {
    inventoryIssueData,
    inventoryPoData,
    inventoryUniquePoData,
    getIssueId,
    fetchPoId,
  } = useAppSelector((state) => state.inventoryRequest)
  const { branchStoreRoomsList, isLoading } = useAppSelector(
    (state) => state.branchStore
  )
  const { allUsersData, manageUserLoading } = useAppSelector(
    (state) => state.manageUser
  )
  const { inventoryLoader } = useAppSelector((state) => state.inventoryRequest)
  const dispatch = useAppDispatch()

  useEffect(() => {
    const uniqueItemIds = new Set()
    const generatedUniqueMainStoreData: any = []

    for (const item of inventoryPoData) {
      if (!uniqueItemIds.has(item?.item_id)) {
        uniqueItemIds.add(item?.item_id)
        generatedUniqueMainStoreData.push(item)
      }
    }
    dispatch(setInventoryUniquePoData(generatedUniqueMainStoreData))
  }, [dispatch, inventoryPoData])

  const data: any =
    tabs === 'Issue' ? inventoryIssueData : inventoryUniquePoData ?? []
  const columns: Column<any>[] =
    tabs === 'Issue' ? issueMainStoreHeaderData : poMainStoreHeaderData ?? []
  const options: TableOptions<any> = {
    data,
    columns,
  }

  const filterDataLength = inventoryUniquePoData?.filter(
    (s: any) =>
      !s?.hasOwnProperty('updatedMasterUnitType') ||
      !s?.hasOwnProperty('poQuantity') ||
      !s?.hasOwnProperty('supplier_id')
  )

  const {
    state,
    // @ts-ignore
    setGlobalFilter,
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable(options, useGlobalFilter, useSortBy)

  // @ts-ignore
  const { globalFilter } = state
  const [issueItemPopup, setIssueItemPopup] = useState(false)
  const [poSummaryPopup, setPoSummaryPopup] = useState(false)
  const [requestPopup, setRequestPopup] = useState(false)
  const [SubmitAllPopup, setSubmitAllPopup] = useState(false)
  const [directPoPopup, setDirectPoPopup] = useState(false)
  const [newGrnPopup, setNewGrnPopup] = useState(false)
  const [pendingPoPopup, setPendingPoPopup] = useState(false)
  const [additionalItemPopup, setAdditionalItemPopup] = useState(false)
  const [submitIssuedPopup, setSubmitIssuedPopup] = useState(false)
  const [poIdPopup, setpoIdPopup] = useState(false)
  const [issueItems, setIssueItems] = useState(false)
  const [roomSearchString, setRoomSearchString] = useState('')
  const [requestType, setRequestType] = useState<any>(null)
  const [userSearchString, setUserSearchString] = useState('')

  useEffect(() => {
    if (requestType?.value === 'ROOM') {
      let payloadData = {
        room_name: roomSearchString,
        page: 1,
        pageSize: 5,
      }
      dispatch(getBranchStoreRoomsList(requestGenerator(payloadData)))
    }
  }, [dispatch, roomSearchString, requestType])

  useEffect(() => {
    if (requestType?.value === 'INDIVIDUAL') {
      let payloadData = {
        search: userSearchString,
        selectedFields: '_id name email phone is_active',
        page: 1,
        pageSize: 5,
      }
      dispatch(getAllManageUser(requestGenerator(payloadData)))
    }
  }, [dispatch, userSearchString, requestType])

  const [popData, setPopData] = useState({
    supplier_id: '', // Initialize supplier_id as an empty string
    name: '',
  })

  const handleDirectPoModalClose = () => {
    setDirectPoPopup(!directPoPopup)
  }
  const handleNewGrnModalClose = () => {
    setNewGrnPopup(!newGrnPopup)
    setPopData({ ...popData, supplier_id: '', name: '' }) // Reset supplier_id when closing the "Add GRN" popup
  }
  const handleItemModalClose = () => {
    let data = {
      items: inventoryIssueData?.map((item: any) => {
        return {
          source: item?.request_source_type,
          destination: item?.destination,
          source_id:
            item?.request_source_type === 'BRANCH_STORE'
              ? item?.source_id
              : undefined,
          store_id: item?.store_id,
          req_id: item?.req_id,
          item_id: item?.item_id,
          qty: Number(item?.issueQuantity) ?? 0,
          name: item?.item_name,
          base_unit_type_id: item?.base_unit_type?._id,
          req_unit_type_id: item?.req_unit_type?._id,
        }
      }),
    }

    dispatch(addInventoryIssueDataAll(requestGenerator(data))).then(
      (response: any) => {
        dispatch(setIssueId(response.payload.doc_id))
        if (response.type === 'inventory/addInventoryIssueDataAll/fulfilled') {
          setSubmitIssuedPopup(!submitIssuedPopup)
          dispatch(clearInventoryIssueData())
          dispatch(emptyIssueCheckStatus())
        }
      }
    )
  }

  const handleDepartment = (supplierId: any, supplierName: string) => {
    setPendingPoPopup(true)
    setPopData({ ...popData, supplier_id: supplierId, name: supplierName }) // Update supplier_id in popData
  }

  return (
    <>
      {isLoading && <Loader />}
      {inventoryLoader && <Loader />}
      {manageUserLoading && <Loader />}
      <CustomModal
        showModal={issueItemPopup}
        closeModal={() => setIssueItemPopup(false)}
        title={t('MainStore.SubmittedPO') || 'Submitted PO'}
        width="90%"
        height="580px"
      >
        <SubmittedPoPopup />
      </CustomModal>
      <CustomModal
        showModal={poSummaryPopup}
        closeModal={() => setPoSummaryPopup(false)}
        title={t('MainStore.PO Summary') || 'PO Summary'}
        width="70%"
        height="580px"
      >
        <PoSummaryPopup />
      </CustomModal>

      <CustomModal
        showModal={requestPopup}
        closeModal={() => setRequestPopup(false)}
        title={t('INVRequest.Request') || 'Request'}
        width="90%"
        height="580px"
      >
        <MainStoreRequestPopup handleClose={() => setRequestPopup(false)} />
      </CustomModal>
      <CustomModal
        showModal={SubmitAllPopup}
        closeModal={() => setSubmitAllPopup(false)}
        width="80%"
        height="580px"
        title={t('MainStore.Confirm PO') || 'Confirm PO'}
      >
        <SubmittedPobtnPopup handleOpen={() => setpoIdPopup(true)} />
      </CustomModal>
      <CustomModal
        showModal={poIdPopup}
        closeModal={() => setpoIdPopup(false)}
        title={t('MainStore.PO ID') || 'PO ID'}
        width="374px"
      >
        <div className={styles.content}>
          {t('MainStore.PO ID Created')}: {fetchPoId}
        </div>
      </CustomModal>

      <CustomModal
        showModal={directPoPopup}
        closeModal={() => handleDirectPoModalClose()}
        width="90%"
        height="570px"
        title={t('MainStore.Direct PO') || 'Direct PO'}
      >
        <DirectPoPopup closeModal={() => handleDirectPoModalClose()} />
      </CustomModal>
      {newGrnPopup && (
        <Popup
          Children={AddGrnPopup}
          handleClose={() => {
            handleNewGrnModalClose()
            dispatch(emptyAllPoData())
          }}
          handleChildClick={() => setAdditionalItemPopup(true)}
          handleDepartment={handleDepartment}
          popData={popData} // Pass the popData prop to the "PendingPoPopup" component
        />
      )}
      {pendingPoPopup && (
        <Popup
          Children={PendingPoPopup}
          handleClose={() => setPendingPoPopup(false)}
          popData={popData} // Pass the popData prop to the "PendingPoPopup" component
        />
      )}
      {additionalItemPopup && (
        <Popup
          Children={AdditionalItemsPoPopup}
          handleClose={() => setAdditionalItemPopup(false)}
        />
      )}
      <CustomModal
        showModal={submitIssuedPopup}
        closeModal={() => setSubmitIssuedPopup(false)}
        title={t('BranchStore.Issued Items') || 'Issued Items'}
        width="35%"
      >
        <div className={styles.content}>
          <h1 className={styles.docId}>
            {t('INVTableHeader.DOCID')} :{' '}
            <span className={styles.docIdValue}>{getIssueId}</span>
          </h1>
        </div>
      </CustomModal>
      <CustomModal
        showModal={issueItems}
        closeModal={() => setIssueItems(false)}
        title={t('MainStore.Issue Items') || 'Issue Items'}
        width="85%"
        height="550px"
      >
        <MainStoreIssueItem />
      </CustomModal>
      <div className={styles.mainStoreMainContainer}>
        <div>
          <div className={styles.userInfoContainer}>
            {userData?.user_no?.length && (
              <div className={styles.userIdContainer}>
                <span className={styles.idText}>
                  {t('BranchStore.User ID')}
                </span>
                <p className={styles.text}>{userData?.user_no}</p>
              </div>
            )}
            <div className={styles.userIdContainer}>
              <span className={styles.idText}>{t('LabInvoice.User_Name')}</span>
              <p className={styles.text}>{userData?.name}</p>
            </div>
          </div>
          <div className={styles.dropDownContainer}>
            <div className={styles.dropDownField}>
              <span className={styles.dropDownLabel}>
                {t('BranchStore.Request Type')}
              </span>
              <Select
                className={styles.select}
                placeholder={t('BranchStore.SelectReq') || 'Select Request'}
                closeMenuOnSelect={true}
                isSearchable={false}
                isClearable={true}
                components={{ DropdownIndicator }}
                value={requestType}
                options={requestDropdownData}
                onChange={(selectedOption: any) => {
                  setRequestType(selectedOption)
                  dispatch(setRequestSourceTypeEvent(selectedOption?.value))
                  dispatch(setRequestSourceEvent(''))
                }}
                maxMenuHeight={200}
              />
            </div>

            {requestType?.value === 'DEPARTMENT' && (
              <>
                <div className={styles.dropDownField}>
                  <span className={styles.subDropDownLabel}>
                    {t('IPDBooking.Department')}
                  </span>
                  <Select
                    className={styles.select}
                    placeholder={
                      t('IPDBooking.SelectDepartment') || 'Select Department'
                    }
                    closeMenuOnSelect={true}
                    isSearchable={true}
                    components={{ DropdownIndicator }}
                    options={branchData?.departments?.map((item: any) => ({
                      label: item?.name,
                      value: item?._id,
                    }))}
                    onChange={(selectedOption: any) => {
                      // setDepartmentName(selectedOption);
                      dispatch(setRequestSourceEvent(selectedOption?.label))
                    }}
                    maxMenuHeight={200}
                  />
                </div>
              </>
            )}
            {requestType?.value === 'BRANCH_STORE' && (
              <>
                <div className={styles.dropDownField}>
                  <span className={styles.subDropDownLabel}>
                    {t('Header.Branch')}
                  </span>
                  <Select
                    className={styles.select}
                    placeholder={
                      t('ManageStaff.Branch_placeholder') || 'Select Branch'
                    }
                    closeMenuOnSelect={true}
                    isSearchable={true}
                    components={{ DropdownIndicator }}
                    options={branchData?.branch_store?.map((item: any) => ({
                      label: item?.name,
                      value: item?._id,
                    }))}
                    onChange={(selectedOption: any) => {
                      // setDepartmentName(selectedOption);
                      dispatch(setRequestSourceEvent(selectedOption?.label))
                    }}
                    maxMenuHeight={200}
                  />
                </div>
              </>
            )}
            {requestType?.value === 'ROOM' && (
              <>
                <div className={styles.dropDownField}>
                  <span className={styles.subDropDownLabel}>
                    {t('IPDBooking.Room')}
                  </span>
                  <SearchDropDown
                    searchString={roomSearchString}
                    setSearchString={setRoomSearchString}
                    dropdownDataToSee={branchStoreRoomsList}
                    dropDownKeyName="room_name"
                    placeholder={t('BranchStore.SearchRoom') || 'Search Room'}
                    customClassdata={styles.dropdownList}
                    customClassItem={styles.dropdownItem}
                    inputCustomClass={styles.dropdownItem}
                    handleClick={(
                      item: any,
                      setVal: any,
                      setShowDropdown: any
                    ) => {
                      setVal(item?.room_name)
                      setShowDropdown(false)
                      dispatch(setRequestSourceEvent(item?._id))
                    }}
                  />
                </div>
              </>
            )}
            {requestType?.value === 'INDIVIDUAL' && (
              <>
                <div className={styles.dropDownField}>
                  <span className={styles.subDropDownLabel}>
                    {t('Header.User')}
                  </span>
                  <SearchDropDown
                    searchString={userSearchString}
                    setSearchString={setUserSearchString}
                    dropdownDataToSee={allUsersData}
                    dropDownKeyName="name"
                    placeholder={t('BranchStore.SearchUser') || 'Search User'}
                    customClassdata={styles.dropdownList}
                    customClassItem={styles.dropdownItem}
                    inputCustomClass={styles.dropdownItem}
                    handleClick={(
                      item: any,
                      setVal: any,
                      setShowDropdown: any
                    ) => {
                      setVal(item?.name)
                      setShowDropdown(false)
                      dispatch(setRequestSourceEvent(item?._id))
                    }}
                  />
                </div>
              </>
            )}
          </div>
        </div>

        <div className={styles.buttonContainer}>
          <Button
            title={t('BranchStore.Requests') || 'Requests'}
            customClass={styles.button}
            handleClick={() => setRequestPopup(true)}
          />

          <Button
            title={t('MainStore.SubmittedPO') || 'Submitted PO'}
            customClass={styles.button}
            handleClick={() => setIssueItemPopup(true)}
          />
          <Button
            title={t('MainStore.PO Summary') || 'PO Summary'}
            customClass={styles.button}
            handleClick={() => setPoSummaryPopup(true)}
          />
          <Button
            title={t('MainStore.Direct PO') || 'Direct PO'}
            customClass={styles.button}
            handleClick={handleDirectPoModalClose}
          />
          <Button
            title={t('MainStore.Add GRN') || 'Add GRN'}
            customClass={styles.button}
            handleClick={handleNewGrnModalClose}
          />
          <Button
            title={t('MainStore.Issue Items') || 'Issue Items'}
            customClass={styles.button}
            handleClick={() => setIssueItems(true)}
          />
        </div>
      </div>
      <div style={{ marginTop: '40px' }}>
        <div className={styles.mainContainer}>
          <p
            className={tabs === 'Issue' ? styles.tabContent : styles.disableTab}
            onClick={() => setTabs('Issue')}
          >
            {t('BranchStore.Issue')}
          </p>
          <p
            className={tabs === 'PO' ? styles.tabContent : styles.disableTab}
            onClick={() => setTabs('PO')}
          >
            {t('MainStore.PO')}
          </p>
        </div>
      </div>
      {tabs === 'Issue' && (
        <div className={styles.tablestyle}>
          <div className={styles.searchContainer}>
            <SmartSearch
              placeHolder={t('ServiceMaster.Search') || 'Search'}
              globalFilter={globalFilter}
              setGlobalFilter={setGlobalFilter}
              isDisable={!inventoryIssueData?.length}
            />
          </div>
          <div style={{ overflow: 'auto', whiteSpace: 'nowrap' }}>
            <TableV3
              getTableProps={getTableProps}
              getTableBodyProps={getTableBodyProps}
              headerGroups={headerGroups}
              rows={rows}
              prepareRow={prepareRow}
            />
          </div>
          <div className={styles.btn}>
            <Button
              title={t('MainStore.IssueSelected') || 'Issue All Selected'}
              handleClick={() => handleItemModalClose()}
              disable={!inventoryIssueData?.length}
            />
          </div>
        </div>
      )}
      {tabs === 'PO' && (
        <div className={styles.tablestyle}>
          <div className={styles.searchContainer}>
            <SmartSearch
              placeHolder={t('ServiceMaster.Search') || 'Search'}
              globalFilter={globalFilter}
              setGlobalFilter={setGlobalFilter}
              isDisable={!inventoryUniquePoData?.length}
            />
          </div>
          <TableV3
            getTableProps={getTableProps}
            getTableBodyProps={getTableBodyProps}
            headerGroups={headerGroups}
            rows={rows}
            prepareRow={prepareRow}
          />

          <div className={styles.btn}>
            <Button
              title={t('MainStore.Submit PO') || 'Submit PO'}
              type="button"
              customClass={styles.reqSubmit}
              handleClick={() => setSubmitAllPopup(true)}
              disable={
                !(inventoryUniquePoData?.length > 0) || filterDataLength?.length
              }
            />
          </div>
        </div>
      )}
    </>
  )
}
export default MainStore
