

import {
  GET_ALL_TRIAL_BALANCE,
} from "../../../constants/asyncActionsType";
import createAsyncThunkForSlice from "../../../utils/utils";
import { getAllTrialBalanceList,  } from "./trialBalanceCrud";

export const getAllTrialBalance = createAsyncThunkForSlice(
  GET_ALL_TRIAL_BALANCE,
  getAllTrialBalanceList,
  {
    isToast: false,
  }
);


