import { t } from 'i18next'

export const searchModalHeaderData: any = [
  {
    Header: t('Employee.EMP ID'),
    accessor: 'emp_id',
    Cell: (props: any) => {
      return (
        <span
          onClick={() => {
            props.onRowClick(props?.row?.original)
          }}
          style={{ cursor: 'pointer', color: '#0e26a3' }}
        >
          {props?.row?.original?.emp_id}
        </span>
      )
    },
  },
  {
    Header: t('AllAttachmentHeader.NAME'),
    accessor: (row: any) => {
      return `${row?.name_en}`
    },
  },
  // {
  //   Header: 'Allowance',
  //   accessor: (row: any) => {
  //     return `${row?.company_details?.fixed_allownce[0]?.name}`
  //   }
  // },
  // {
  //   Header: 'Values',

  //   accessor: (row: any) => {
  //     return `${row?.company_details?.fixed_allownce[0]?.value}`
  //   }
  // },
  // {
  //   Header: 'Deduction ',
  //   accessor: (row: any) => {

  //     return `${row?.company_details?.fixed_deduction[0]?.name}`
  //   }
  // },
  // {
  //   Header: 'Value',
  //   accessor: (row: any) => {

  //     return `${row?.company_details?.fixed_deduction[0]?.value}`
  //   }
  // },
]
