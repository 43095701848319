import { Suspense, lazy, useEffect } from "react";
import Loader from "../../../components/common/spinner/Loader";
import { ErrorBoundary } from "../../../components/core/error-boundary/ErrorBoundary";
import {
  clearCrmAdminData,
  emptyAdminCheckStatus,
  emptyCrmAdminData,
} from "../../../redux/features/crm-admin-store/crmSlice";
import { useAppDispatch } from "../../../hooks";

const LazyLoadCrm = lazy(() => import("./crmNew"));

const CrmNew = () => {
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(clearCrmAdminData());
    dispatch(emptyAdminCheckStatus());
    dispatch(emptyCrmAdminData());
  }, [dispatch]);
  return (
    <ErrorBoundary fallback={<>error occured....</>}>
      <Suspense fallback={<Loader />}>
        <LazyLoadCrm />
      </Suspense>
    </ErrorBoundary>
  );
};

export default CrmNew;
