import { FC } from 'react'
import styles from './deleteMedicationPopup.module.scss'
import Divider from '../../divider/Divider'
import { CloseIcon } from '../../svg-components'
import { colors } from '../../../../constants/color'
import Button from '../../button/Button'
import Loader from '../../spinner/Loader'
import { useAppSelector } from '../../../../hooks'
import { useTranslation } from 'react-i18next'

interface IDeleteMedication {
  handleClose?: any
  handleYes?: any
  handleNo?: any
  heading?: string
  message?: string
}

const DeleteMedicationPopup: FC<IDeleteMedication> = ({
  handleClose,
  handleYes,
  handleNo,
  heading,
  message,
}) => {
  const { isLoading } = useAppSelector((state) => state.ipd)
  const { emrLoader } = useAppSelector((state) => state.patient)
  const { t } = useTranslation()
  return (
    <>
      {isLoading || emrLoader ? <Loader /> : ''}
      <div
        className={styles.notesPopupContainer}
        onClick={(e) => {
          e.stopPropagation()
        }}
      >
        <CloseIcon
          customClass={styles.closeIconStyle}
          fillColor={colors.green1}
          handleClick={handleClose}
        />
        <div className={styles.notesContainer}>
          <p className={styles.title}>
            {heading ? heading : t('Receptionist Dashboard.Delete')}
          </p>
          <Divider customClass={styles.dividerStyle} />
          <p className={styles.deleteText}>
            {message ? message : t('Common.SureWantDelete')}
          </p>
          <div className={styles.btnContainer}>
            <Button
              title={t('Common.Yes') || 'Yes'}
              customClass={styles.yesButtonStyle}
              handleClick={handleYes}
            />
            <Button
              title={t('Common.No') || 'No'}
              customClass={styles.noButtonStyle}
              handleClick={handleNo}
            />
          </div>
        </div>
      </div>
    </>
  )
}

export default DeleteMedicationPopup
