import { Outlet } from "react-router";

const MedicalCenterRoom = () => {
  return (
    <>
      <Outlet />
    </>
  );
};

export default MedicalCenterRoom;
