import axios from 'axios'
import { IAPIPayload } from '../../../interfaces/apiInterface'
import { DASHBARD_COUNT, GET_ALL_DASHBOARD } from '../../../config/config'

export const getAllDashboardList = (data: IAPIPayload) => {
  return axios.post(GET_ALL_DASHBOARD, data)
}

export const getAllCount = (data: IAPIPayload) => {
  return axios.post(DASHBARD_COUNT, data)
}
