import { FC, ReactNode } from "react";
import { ErrorBoundary } from "../../core/error-boundary/ErrorBoundary";
import { Error } from "../../common/error";

interface IErrorBoundaryHoc {
  children: ReactNode;
  errorMessage: string;
}
const ErrorBoundaryHoc: FC<IErrorBoundaryHoc> = (props) => {
  const { children, errorMessage } = props;
  return (
    <ErrorBoundary fallback={<Error message={errorMessage} />}>
      {children}
    </ErrorBoundary>
  );
};
export default ErrorBoundaryHoc;
