// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ipdTestlistPopup_notesPopupContainer__bWkTL {
  width: 600px;
  margin: auto;
  background: var(--white1);
  border-radius: 10px;
  padding: 10px;
}
.ipdTestlistPopup_notesPopupContainer__bWkTL .ipdTestlistPopup_closeIconStyle__GGq6k {
  cursor: pointer;
  float: right;
}
.ipdTestlistPopup_notesPopupContainer__bWkTL .ipdTestlistPopup_notesContainer__tOGSp {
  margin-top: 50px;
}
.ipdTestlistPopup_notesPopupContainer__bWkTL .ipdTestlistPopup_notesContainer__tOGSp .ipdTestlistPopup_title__bGJ0V {
  text-align: center;
  font-weight: var(--font-semibold);
  font-size: var(--font-24);
  line-height: 36px;
  color: var(--black2);
}
.ipdTestlistPopup_notesPopupContainer__bWkTL .ipdTestlistPopup_notesContainer__tOGSp .ipdTestlistPopup_dividerStyle__0QOOw {
  margin: 10px auto 30px auto;
  width: 80px;
  height: 3px;
  background: var(--green1);
}
.ipdTestlistPopup_notesPopupContainer__bWkTL .ipdTestlistPopup_notesContainer__tOGSp .ipdTestlistPopup_tabelContainer__7t0P2 {
  margin: 5px 0 20px 0;
  max-height: 250px;
  overflow-y: auto;
}
.ipdTestlistPopup_notesPopupContainer__bWkTL .ipdTestlistPopup_notesContainer__tOGSp .ipdTestlistPopup_tabelContainer__7t0P2::-webkit-scrollbar {
  display: block;
  width: 7px;
  height: 7px;
}
.ipdTestlistPopup_notesPopupContainer__bWkTL .ipdTestlistPopup_notesContainer__tOGSp .ipdTestlistPopup_tabelContainer__7t0P2::-webkit-scrollbar-track {
  background: transparent;
}
.ipdTestlistPopup_notesPopupContainer__bWkTL .ipdTestlistPopup_notesContainer__tOGSp .ipdTestlistPopup_tabelContainer__7t0P2::-webkit-scrollbar-thumb {
  background-color: var(--grey7);
  border-radius: 10px;
  border: 1px solid var(--grey7);
}`, "",{"version":3,"sources":["webpack://./src/components/common/modal/ipd-testlist-popup/ipdTestlistPopup.module.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;EACA,YAAA;EACA,yBAAA;EACA,mBAAA;EACA,aAAA;AACF;AAAE;EACE,eAAA;EACA,YAAA;AAEJ;AAAE;EACE,gBAAA;AAEJ;AADI;EACE,kBAAA;EACA,iCAAA;EACA,yBAAA;EACA,iBAAA;EACA,oBAAA;AAGN;AADI;EACE,2BAAA;EACA,WAAA;EACA,WAAA;EACA,yBAAA;AAGN;AADI;EACE,oBAAA;EACA,iBAAA;EACA,gBAAA;AAGN;AAFM;EACE,cAAA;EACA,UAAA;EACA,WAAA;AAIR;AAFM;EACE,uBAAA;AAIR;AADM;EACE,8BAAA;EACA,mBAAA;EACA,8BAAA;AAGR","sourcesContent":[".notesPopupContainer {\n  width: 600px;\n  margin: auto;\n  background: var(--white1);\n  border-radius: 10px;\n  padding: 10px;\n  .closeIconStyle {\n    cursor: pointer;\n    float: right;\n  }\n  .notesContainer {\n    margin-top: 50px;\n    .title {\n      text-align: center;\n      font-weight: var(--font-semibold);\n      font-size: var(--font-24);\n      line-height: 36px;\n      color: var(--black2);\n    }\n    .dividerStyle {\n      margin: 10px auto 30px auto;\n      width: 80px;\n      height: 3px;\n      background: var(--green1);\n    }\n    .tabelContainer {\n      margin: 5px 0 20px 0;\n      max-height: 250px;\n      overflow-y: auto;\n      &::-webkit-scrollbar {\n        display: block;\n        width: 7px;\n        height: 7px;\n      }\n      &::-webkit-scrollbar-track {\n        background: transparent;\n      }\n\n      &::-webkit-scrollbar-thumb {\n        background-color: var(--grey7);\n        border-radius: 10px;\n        border: 1px solid var(--grey7);\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"notesPopupContainer": `ipdTestlistPopup_notesPopupContainer__bWkTL`,
	"closeIconStyle": `ipdTestlistPopup_closeIconStyle__GGq6k`,
	"notesContainer": `ipdTestlistPopup_notesContainer__tOGSp`,
	"title": `ipdTestlistPopup_title__bGJ0V`,
	"dividerStyle": `ipdTestlistPopup_dividerStyle__0QOOw`,
	"tabelContainer": `ipdTestlistPopup_tabelContainer__7t0P2`
};
export default ___CSS_LOADER_EXPORT___;
