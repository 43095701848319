import { FC, useEffect, useState } from 'react'
import { colors } from '../../../../constants/color'
import {
  Column,
  TableOptions,
  useGlobalFilter,
  useSortBy,
  useTable,
} from 'react-table'
import { Cols } from '../../../../interfaces/interfaces'
import Divider from '../../../../components/common/divider/Divider'
import {
  CloseIcon,
  SearchIcon,
} from '../../../../components/common/svg-components'
import { trimValue } from '../../../../utils/utils'
import TableV3 from '../../../../components/common/table/tableV3/TableV3'
import { employeeSearchModalHeaderData } from './EmployeeSearchModalTableData'
import Pagination from '../../../../components/common/pagination/Pagination'
import { useAppDispatch, useAppSelector } from '../../../../hooks'
import Loader from '../../../../components/common/spinner/Loader'
import { getAllSearchUserList } from '../../../../redux/features/employee/employeeAsyncActions'
import { requestGenerator } from '../../../../utils/payloadGenerator'
import SmartSearch from '../../../../components/common/smart-search/SmartSearch'
import styles from './employeeSearchModal.module.scss'
import { useTranslation } from 'react-i18next'

interface ISearchModal {
  handleClose: (e?: React.MouseEvent<HTMLElement>) => void
  isEditing?: boolean
  setIsEditing?: React.Dispatch<React.SetStateAction<boolean>>
  setModelOpenClose?: any
  handleRowClick?: any
  handleSubmit: (e?: React.MouseEvent<HTMLElement>) => void
}
const EmployeeSearchModal: FC<ISearchModal> = ({
  handleClose,
  setIsEditing,
  setModelOpenClose,
  handleSubmit,
}) => {
  const dispatch = useAppDispatch()
  const { t } = useTranslation()
  const { isLoading, userListData } = useAppSelector((state) => state.employee)
  const [searchValue, setSearchValue] = useState<string>('')
  const [isSmartSearchDisable, setIsSmartSearchDisable] =
    useState<boolean>(true)
  const [totalData, setTotalData] = useState(0)
  const [dataPerPage, setDataPerPage] = useState<number>(10)
  const [totalPage, setTotalPage] = useState<number>(0)
  const [pageIndex, setPageIndex] = useState<number>(1)

  // React Table define
  const data: Cols[] = userListData
  const columns: Column<Cols>[] = employeeSearchModalHeaderData
  const options: TableOptions<Cols> = {
    data,
    columns,
  }
  const {
    state,
    // @ts-ignore
    setGlobalFilter,
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable(options, useGlobalFilter, useSortBy)
  // @ts-ignore
  const { globalFilter } = state
  // function for creating Page Index Array
  const pageIndexArray = () => {
    let pageIndexOptions = []
    for (let i = 1; i <= totalPage; i++) {
      pageIndexOptions.push(i)
    }
    return pageIndexOptions
  }
  const pageIndexOptions = pageIndexArray()
  // API Calling for initial data
  useEffect(() => {
    if (searchValue === '') {
      setGlobalFilter('')
      const requestData = {
        search: searchValue,
        page: pageIndex,
        pageSize: dataPerPage,
        excludeEmployees: true,
      }
      dispatch(getAllSearchUserList(requestGenerator(requestData))).then(
        (result) => {
          setTotalPage(result.payload.lastPage)
          setTotalData(result.payload.total)
        }
      )
    }
  }, [dispatch, searchValue, dataPerPage, pageIndex])
  // Search
  const handleInputSearch = () => {
    setPageIndex(1)
    if (searchValue?.length > 0) {
      const requestData = {
        search: searchValue,
        page: 1,
        pageSize: dataPerPage,
      }
      dispatch(getAllSearchUserList(requestGenerator(requestData))).then(
        (result) => {
          setIsSmartSearchDisable(false)
          setTotalPage(result.payload.lastPage)
          setTotalData(result.payload.total)
        }
      )
    }
  }
  return (
    <>
      {isLoading && <Loader />}
      <div
        className={styles.mainContainer}
        onClick={(e) => {
          e.stopPropagation()
        }}
      >
        <CloseIcon
          customClass={styles.closeIconStyle}
          fillColor={colors.green1}
          handleClick={() => {
            handleClose()
            setIsEditing && setIsEditing(false)
          }}
        />
        <div className={styles.employeeListContainer}>
          <p className={styles.title}>{t('Employee.Employee Search')}</p>
          <Divider customClass={styles.dividerStyle} />
          <div className={styles.searchFieldContainer}>
            <div className={styles.searchContainer}>
              <input
                className={styles.searchInput}
                type="text"
                placeholder={
                  t('Employee.SearchByEMP') || 'Search By Employee Name'
                }
                onChange={(e) => {
                  setSearchValue(e.target.value)
                  setGlobalFilter('')
                  trimValue(e)
                }}
                onKeyDown={(e: any) => {
                  if (e?.key === 'Enter') {
                    handleInputSearch()
                  }
                }}
              />
              <div
                className={styles.searchButton}
                onClick={() => {
                  if (!!searchValue) {
                    handleInputSearch()
                  }
                }}
              >
                <SearchIcon fillColor={'#ffffff'} />
              </div>
              <SmartSearch
                placeHolder={t('Common.Smart Search') || 'Smart Search'}
                globalFilter={globalFilter}
                setGlobalFilter={setGlobalFilter}
                // isDisable={!options?.data?.length}
                isDisable={isSmartSearchDisable}
              />
            </div>
          </div>
          <div className={styles.tableContainer}>
            <TableV3
              handleRowClick={handleSubmit}
              getTableProps={getTableProps}
              getTableBodyProps={getTableBodyProps}
              headerGroups={headerGroups}
              rows={rows}
              prepareRow={prepareRow}
              setModelOpenClose={setModelOpenClose}
            />
          </div>
          {/* Pagination */}
          {!!(totalData > 10) && (
            <Pagination
              setDataPerPage={setDataPerPage}
              pageIndexOptions={pageIndexOptions}
              pageIndex={pageIndex}
              setPageIndex={setPageIndex}
            />
          )}
        </div>
      </div>
    </>
  )
}

export default EmployeeSearchModal
