import { FC } from 'react'
import { Outlet } from 'react-router-dom'

interface IRequestLayout {}

const RequestLayout: FC<IRequestLayout> = (props) => {
  return (
    <>
      <Outlet />
    </>
  )
}
export default RequestLayout
