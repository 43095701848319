import styles from './patientIpdInvoiceTableData.module.scss'
import moment from 'moment'
import Select from 'react-select'
import Button from '../../components/common/button/Button'
import { useAppDispatch, useAppSelector } from '../../hooks'
import {
  handleAddedItems,
  handleRemoveAddedItem,
  setTestAddText,
  updatedNewPaymentAmountArray,
  updatedTreatmentBillStatus,
} from '../../redux/features/ipd-invoice/ipdInvoiceSlice'
import { useEffect, useState } from 'react'
import { disableArrowKey, disableScroll } from '../../utils/utils'
import { DeleteIcon } from '../../components/common/svg-components'
import { colors } from '../color'
import { t } from 'i18next'
// import { DropdownIndicator } from "../../../components/common/dropdown-indicator/DropdownIndicator";

export const patientIPDInfoHeaderData: any = [
  {
    Header: t('IPDInvoice.CASEID'),
    // accessor: 'case_no',
    Cell: ({ row, onPopClose, onRowClick, invoiceFlag }: any) => {
      return (
        <span style={{ cursor: 'pointer', color: '#0e26a3' }}>
          {row?.original?.case_no}
        </span>
      )
    },
  },
  {
    Header: t('PatientHistoryFormHeader.PATIENT NAME'),
    // accessor: 'insurance_plan',
    Cell: ({ row }: any) => {
      return <span>{row?.original?.user?.name}</span>
    },
  },
  {
    Header: t('RcptTableHeader.DOCTOR NAME'),
    // accessor: 'expiry_date',
    Cell: ({ row }: any) => {
      return <span>{row?.original?.doctor_detail?.doctor_name}</span>
    },
  },
  {
    Header: t('IPDHistory.ADMITTED DATE'),
    // accessor: 'policy_no',
    Cell: ({ row }: any) => {
      const AdmitDate = moment(
        row?.original?.booking_detail?.admission_date
      ).format('DD MMM YYYY')
      return <span>{AdmitDate}</span>
    },
  },
  {
    Header: t('IPDHistory.DISCHARGE DATE'),
    // accessor: 'details',
    Cell: ({ row }: any) => {
      const AdmitDate = moment(
        row?.original?.booking_detail?.discharge_date
      ).format('DD MMM YYYY')
      return <span>{AdmitDate}</span>
    },
  },
  {
    Header: t('IPDInvoice.MEDSIT'),
    // accessor: 'coPay',
    Cell: ({ row }: any) => {
      return (
        <span style={{ cursor: 'pointer', color: '#0e26a3' }}>
          {t('Common.View')}
        </span>
      )
    },
  },
  {
    Header: t('RcptTableHeader.STATUS'),
    // accessor: 'notes',
    Cell: ({ row }: any) => {
      return <span>{row?.original?.is_case_open ? 'Open' : 'Close'}</span>
    },
  },
]

export const PatientListHeaderData: any = [
  {
    Header: t('RcptTableHeader.FILE NO'),
    // accessor: 'emr_no',
    Cell: ({ row, onPopClose, onRowClick, invoiceFlag }: any) => {
      return (
        <span
          onClick={() => {
            onRowClick(row?.original)
          }}
          className={styles.caseId}
          style={{
            cursor: 'pointer',
            color: '#0e26a3',
          }}
        >
          {row?.original?.emr_no}
        </span>
      )
    },
  },

  {
    Header: t('PatientHistoryFormHeader.PATIENT NAME'),
    accessor: 'patient_name',
  },

  {
    Header: t('PatientHistoryFormHeader.NATIONAL ID'),
    accessor: 'national_id',
  },

  {
    Header: t('RcptTableHeader.MOBILE NO'),
    accessor: 'phone',
  },
]

export const patientConsumbleHeaderData: any = [
  {
    Header: t('RcptTableHeader.DATE'),
    // accessor: 'plan_id',
    Cell: ({ row }: any) => {
      const date = moment(new Date()).format('DD MMM YYYY')
      return <span>{date}</span>
    },
  },
  {
    Header: t('ServiceMaster.ITEM_ID'),
    accessor: 'item_no',
  },
  {
    Header: t('ServiceMaster.ITEM_NAME'),
    accessor: 'name',
  },
  // {
  //   Header: "Description",
  //   accessor: "policy_no",
  // },
  {
    Header: t('ServiceTable.BILLABLE'),
    Cell: (props: any) => {
      const billableTypes: any = [
        {
          label: 'Yes',
          value: true,
        },
        {
          label: 'No',
          value: false,
        },
      ]
      return (
        <div className={styles.billableContainer}>
          <Select
            // className={styles.selectInputField}
            isDisabled={true}
            closeMenuOnSelect={true}
            // components={{ DropdownIndicator }}
            isSearchable={false}
            // value={props?.row?.original?.billable}
            defaultValue={{
              label: billableTypes?.[0]?.label,
              value: billableTypes?.[0]?.value,
            }}
            options={billableTypes}
            // onChange={(e: any) => handleRequest(e)}
            maxMenuHeight={120}
            styles={{
              control: (provided) => ({
                ...provided,
                width: '100px',
                minHeight: '30px',
                cursor: 'pointer',
              }),
            }}
          />
        </div>
      )
    },
  },
  {
    Header: t('InsurancePlan_Form.PRICE'),
    accessor: 'sell_price',
  },
  {
    Header: t('PatientHistoryTableHeader.NOTES'),
    accessor: (row: any) => {
      return row?.notes ?? '-'
    },
  },
]

export const patientChargesHeaderData: any = [
  {
    Header: t('RcptTableHeader.DATE'),
    accessor: 'date',
    Cell: ({ row }: any) => {
      const date = moment(row?.original?.date).format('DD MMM YYYY')
      return <span>{date}</span>
    },
  },
  // {
  //   Header: "Service ID",
  //   Cell: (props: any) => {
  //     return <>{props.row.original.test_no || props.row.original.sessionId}</>;
  //   },
  // },
  {
    Header: t('InsurancePlan_Form.SERVICE_NAME'),
    Cell: (props: any) => {
      return (
        <>
          {props.row.original.test_name || props.row.original.service_id.name}
        </>
      )
    },
  },
  // {
  //   Header: "Description",
  //   Cell: (props: any) => {
  //     return <>{"-"}</>;
  //   },
  // },
  {
    Header: t('ServiceTable.BILLABLE'),
    accessor: 'billable',
    Cell: (props: any) => {
      const dispatch = useAppDispatch()
      const handleChange = (e: any, item: any) => {
        const requestObj = {
          _id: props.row.original?._id,
          billable: e?.value,
        }

        dispatch(updatedTreatmentBillStatus(requestObj))
      }

      const billableTypes: any = [
        {
          label: 'Yes',
          value: true,
        },
        {
          label: 'No',
          value: false,
        },
      ]
      return (
        <div className={styles.billableContainer}>
          <Select
            // className={styles.selectInputField}
            isDisabled={props.row.original?.type === 'job_item'}
            closeMenuOnSelect={true}
            // components={{ DropdownIndicator }}
            isSearchable={false}
            // value={props?.row?.original?.billable}
            defaultValue={{
              label: props.row.original?.isBillable
                ? billableTypes?.[0]?.label
                : billableTypes?.[1]?.label,
              value: props.row.original?.isBillable
                ? billableTypes?.[0]?.value
                : billableTypes?.[0]?.value,
            }}
            options={billableTypes}
            onChange={(e: any) => handleChange(e, props.row.original._id)}
            maxMenuHeight={120}
            styles={{
              control: (provided) => ({
                ...provided,
                width: '100px',
                minHeight: '30px',
                cursor: 'pointer',
              }),
            }}
          />
        </div>
      )
    },
  },
  {
    Header: t('InsurancePlan_Form.PRICE'),
    accessor: 'price',
  },
  {
    Header: t('PatientHistoryTableHeader.NOTES'),
    Cell: (props: any) => {
      return <>{props.row.original.notes ?? '-'}</>
    },
  },
]

export const inventoryItemsHeaderData: any = [
  {
    Header: t('ServiceMaster.ITEM_ID'),
    accessor: 'item_no',
  },
  {
    Header: t('ServiceMaster.ITEM_NAME'),
    accessor: 'name',
  },
  {
    Header: t('InvoiceService.DESCRIPTION'),
    Cell: (props: any) => {
      return <>{'-'}</>
    },
  },
  {
    Header: t('ServiceTable.BILLABLE'),
    accessor: 'billable',
    Cell: (props: any) => {
      const billableTypes: any = [
        {
          label: 'Yes',
          value: true,
        },
        {
          label: 'No',
          value: false,
        },
      ]

      return (
        <div className={styles.billableContainer}>
          <Select
            // className={styles.selectInputField}
            isDisabled={true}
            closeMenuOnSelect={true}
            // components={{ DropdownIndicator }}
            isSearchable={false}
            // value={{
            //     label: props?.row?.original?.billable,
            //     value: props?.row?.original?.billable,
            //   }}
            defaultValue={{
              label: billableTypes?.[0]?.label,
              value: billableTypes?.[0]?.value,
            }}
            options={billableTypes}
            // onChange={(e: any) => handleRequest(e)}
            maxMenuHeight={120}
            styles={{
              control: (provided) => ({
                ...provided,
                width: '100px',
                minHeight: '30px',
                cursor: 'pointer',
              }),
            }}
          />
        </div>
      )
    },
  },
  {
    Header: t('InsurancePlan_Form.PRICE'),
    accessor: 'sell_price',
  },

  {
    Header: t('IPDInvoice.ADD_ITEM'),
    accessor: 'Add item',
    Cell: (props: any) => {
      const { addTestText } = useAppSelector((state) => state.ipdInvoice)
      const dispatch = useAppDispatch()

      const handleTestData = (row: any) => {
        let updateRow = {
          ...row,
          id: row?.item_no,
          name: row?.name,
          isBillable: 'Yes',
          price: row?.sell_price,
          quantity: 1,
          type: 'inventory_item',
          unitPrice: row?.sell_price,
        }

        // delete updateRow._id;
        dispatch(handleAddedItems(updateRow))
      }

      const handleAdd = (id: any) => {
        dispatch(setTestAddText(id))
      }

      const handleRemove = (id: any) => {
        dispatch(handleRemoveAddedItem(id))
      }

      return (
        <>
          <div
            className={styles.addTestPopupAddToJob}
            onClick={() => handleAdd(props.row.original._id)}
          >
            {!addTestText.includes(props.row.original._id) ? (
              <Button
                title={t('InvoiceService.Add') || 'Add'}
                customClass={styles.addedServiceButton}
                handleClick={() => handleTestData(props?.row?.original)}
              />
            ) : (
              <Button
                title={t('InvoiceService.Added') || 'Added'}
                customClass={styles.addServiceButton}
                handleClick={() => handleRemove(props?.row?.original?._id)}
              />
            )}
          </div>
        </>
      )
    },
  },
]

export const patientServicesHeaderData: any = [
  // {
  //   Header: "ID",
  //   accessor: "id",
  // },
  {
    Header: t('AllAttachmentHeader.NAME'),
    accessor: 'name',
  },
  {
    Header: t('MobileAppConfiguration.DESCRIPTION'),
    Cell: (props: any) => {
      return <>{'-'}</>
    },
  },
  {
    Header: t('ServiceTable.BILLABLE'),
    accessor: 'billable',
    Cell: (props: any) => {
      const billableTypes: any = [
        {
          label: 'Yes',
          value: true,
        },
        {
          label: 'No',
          value: false,
        },
      ]
      return (
        <div className={styles.billableContainer}>
          <Select
            // className={styles.selectInputField}
            isDisabled={true}
            closeMenuOnSelect={true}
            // components={{ DropdownIndicator }}
            isSearchable={false}
            defaultValue={{
              label: props.row.original?.isBillable
                ? billableTypes?.[0]?.label
                : billableTypes?.[1]?.label,
              value: props.row.original?.isBillable
                ? billableTypes?.[0]?.value
                : billableTypes?.[0]?.value,
            }}
            options={billableTypes}
            // onChange={(e: any) => handleRequest(e)}
            maxMenuHeight={120}
            styles={{
              control: (provided) => ({
                ...provided,
                width: '100px',
                minHeight: '30px',
                cursor: 'pointer',
              }),
            }}
          />
        </div>
      )
    },
  },
  {
    Header: t('InsurancePlan_Form.PRICE'),
    accessor: 'price',
  },
]

export const ipdInvoicePaymentHeaderData: any = [
  {
    Header: t('InvoicePayment.PAYMENT MODE'),
    accessor: 'payment_label',
  },

  {
    Header: t('InvoicePayment.AMOUNT (E)'),
    Cell: ({ row }: any) => {
      const dispatch = useAppDispatch()
      const { patientPaymentModeList } = useAppSelector(
        (state) => state.ipdInvoice
      )
      const [error, setError] = useState('')

      const validateInput = (inputValue: any) => {
        const pattern = /^\d{1,6}(?:\.\d{1,3})?$/
        return pattern.test(inputValue)
      }

      const handleAmountChange = (event: any) => {
        const amount = event.target.value
        const isValid = validateInput(amount)
        if (isValid === false) {
          setError(
            t('OnlinePaymentValidator.ValidAMT') || 'Please enter valid amount'
          )
        }
        if (isValid === true) {
          setError('')
        }
        const updatedData = patientPaymentModeList?.map((item: any) => {
          if (item?._id === row?.original?._id) {
            return {
              ...item,
              amount: parseFloat(amount),
            }
          }
          return item
        })
        dispatch(updatedNewPaymentAmountArray(updatedData))
      }
      useEffect(() => {
        disableScroll()
      }, [])

      return (
        <>
          <input
            className={styles.inputFieldServiceTable}
            value={row?.original?.amount}
            type="number"
            key={row.original._id}
            onChange={handleAmountChange}
            onKeyDown={(e: any) => disableArrowKey(e)}
            onWheel={(e: any) => {
              e.target.blur()
            }}
            disabled={row?.original?.payment_mode === 'upay' ? true : false}
          />
          <p className="dashboardFormError">{error}</p>
        </>
      )
    },
  },

  {
    Header: t('InvoicePayment.APPROVAL NO.'),
    Cell: ({ row }: any) => {
      return (
        <>
          <span>-</span>
        </>
      )
    },
  },

  {
    Header: t('DocUploadHeader.ACTION'),
    Cell: ({ row }: any) => {
      const dispatch = useAppDispatch()
      const { patientPaymentModeList } = useAppSelector(
        (state) => state.ipdInvoice
      )

      const handleRemovePaymentMode = (paymentMode: any) => {
        let tempArr: any = []
        tempArr =
          patientPaymentModeList && patientPaymentModeList.length > 0
            ? patientPaymentModeList?.filter((item: any) => {
                return item?._id !== paymentMode?._id
              })
            : []
        dispatch(updatedNewPaymentAmountArray(tempArr))
      }
      return (
        <>
          <DeleteIcon
            fillColor={colors.grey4}
            handleClick={() => handleRemovePaymentMode(row?.original)}
          />
        </>
      )
    },
  },
]
