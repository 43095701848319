import { REPORT_NAME, REPORT_ROLE } from '../constants/reportBuilderConstant'
import { t } from 'i18next'

export const reportBuilderValidators = {
  [REPORT_NAME]: {
    required: t('ReportBuilder.Report_Name_Placeholder'),
  },
  [REPORT_ROLE]: {
    required: t('ManageUserRoles.Role_Name_validation'),
  },
}
