import Select from 'react-select'
import { DropdownIndicator } from '../../../components/common/dropdown-indicator/DropdownIndicator'
import styles from './style.module.scss'
import Button from '../../../components/common/button/Button'
import TableV2 from '../../../components/common/table/tableV2/TableV2'
import { IpdRequestHeaderData } from './ipdRequestHeaderData'
import {
  priority_type_array,
  test_type_array,
} from '../../doctor-diagnosis/request/requestDummyData'
import { useForm } from 'react-hook-form'
import { disableArrowKey } from '../../../utils/utils'
import { useAppDispatch, useAppSelector } from '../../../hooks'
import {
  clearIpdRequestData,
  setIpdRequestData,
} from '../../../redux/features/ipd/ipdSlice'
import { useEffect, useState } from 'react'
import {
  addPatientRequestList,
  getLabTestsList,
  getRadiologyTestsList,
  getTestsListByInsuranceNameList,
} from '../../../redux/features/request/requestAsyncActions'
import { requestGenerator } from '../../../utils/payloadGenerator'
import { CustomRadio } from '../../../components/common/custom-radio'
import { ADD_PATIENT_REQUESTS_TYPE } from '../../../constants/asyncActionsType'
import { useLocation } from 'react-router-dom'
import { reactSelectStyle } from '../../../constants/data'
import { useTranslation } from 'react-i18next'

export const billable_type_array = [
  { label: 'Yes', value: true },
  { label: 'No', value: false },
]

const IpdRequest = ({ closeModal }: any) => {
  const getPatiendId = useLocation().state
  const { t } = useTranslation()
  const { ipdRequestData, storeIpdCaseById } = useAppSelector(
    (state) => state.ipd
  )

  const [testNameOptions, setTestNameOptions] = useState<any>([])
  const [labTestsList, setLabTestsList] = useState<any>([])

  const [radiologyTestsList, setRadiologyTestsList] = useState<any>([])

  const radiologyRequestData = ipdRequestData?.filter(
    (s: any) => s?.test_type === 'RADIOLOGY'
  )

  const labRequestData = ipdRequestData?.filter(
    (s: any) => s?.test_type === 'LABORATORY'
  )

  const [type, setType] = useState('internal')

  const { labTestsData, radiologyTestsData, testListByInsuranceNameData } =
    useAppSelector((state) => state.request)

  const { userData } = useAppSelector((state) => state.login)
  const [patientInsurancePlanOptions, setPatientInsurancePlanOptions] =
    useState<any>([{ label: 'Select Plan', value: '', discount: 0 }])
  const dispatch = useAppDispatch()
  const [show, setShow] = useState<boolean>(false)

  const {
    register,
    handleSubmit,
    setValue,
    reset,
    watch,
    trigger,
    formState: { errors },
  } = useForm<any>()
  const watchTestType = watch('testType')
  const selectedInsuranceType = watch('insurance_type')
  const seletedTestName = watch('testName')
  const testPrice = watch('price')
  useEffect(() => {
    const payloadForTest = {
      search: '',
      page: 0,
      pageSize: 100,
      is_active: true,
      order_by: { name: 1 },
    }
    dispatch(getLabTestsList(requestGenerator(payloadForTest)))
    dispatch(getRadiologyTestsList(requestGenerator(payloadForTest)))
  }, [dispatch])

  useEffect(() => {
    const insurance = storeIpdCaseById?.insurance_plan_detail

    if (insurance?._id) {
      const obj = {
        value: insurance?._id,
        label: insurance?.insurance_plan,
        discount: insurance?.discount ? insurance?.discount : 0,
      }
      setPatientInsurancePlanOptions([{ label: 'Select Plan', value: '' }, obj])
    }
  }, [storeIpdCaseById])

  // function for creating lab and radiology tests data
  useEffect(() => {
    const labTestsFilteredData = labTestsData?.map((item: any) => {
      return {
        label: item?.name,
        value: item?._id,
        test_price: item?.sell_price,
      }
    })
    setLabTestsList(labTestsFilteredData)
  }, [labTestsData])

  useEffect(() => {
    const radiologyTestsFilteredData = radiologyTestsData?.map((item: any) => {
      return {
        label: item?.name,
        value: item?._id,
        test_price: item?.sell_price,
      }
    })
    setRadiologyTestsList(radiologyTestsFilteredData)
  }, [radiologyTestsData])

  // funtion for set test name options
  useEffect(() => {
    if (watchTestType?.value && selectedInsuranceType?.value) {
      const testsFilteredData = testListByInsuranceNameData?.map(
        (item: any) => {
          return {
            label: item?.name,
            value: item?._id,
            test_price: item?.sell_price,
          }
        }
      )
      setTestNameOptions(testsFilteredData)
    } else {
      if (watchTestType?.value === 'RADIOLOGY') {
        setTestNameOptions(radiologyTestsList)
      } else if (watchTestType?.value === 'LABORATORY') {
        setTestNameOptions(labTestsList)
      }
    }
    setValue('testName', '')
    setValue('price', '')
  }, [
    setValue,
    selectedInsuranceType,
    watchTestType,
    testListByInsuranceNameData,
    labTestsList,
    radiologyTestsList,
  ])
  // api call for test name by insurance name
  useEffect(() => {
    const payloadForTest = {
      department_name: watchTestType?.value,
      insurance_plan_id: selectedInsuranceType?.value,
      // insurance_plan_id: "64e5ddf34b2f0b1b030cfbd5",
    }
    if (watchTestType?.value && selectedInsuranceType?.value) {
      dispatch(
        getTestsListByInsuranceNameList(requestGenerator(payloadForTest))
      )
    }
  }, [dispatch, watchTestType, selectedInsuranceType])

  const onSubmit = (items: any) => {
    let data = {
      test_type: items?.testType?.value,
      test_name: items?.testName?.label,
      price: items?.price,
      priority: items?.priority?.value,
      test_notes: items?.notes,
      test_name_id: items?.testName?.value,
      is_billable: items?.billable?.value,
      insurance_plan_id: items?.insurance_type?.value,
    }
    dispatch(setIpdRequestData(data))
    reset()
    setValue('testType', '')
    setValue('testName', '')
    setValue('billable', '')
    setValue('priority', '')
    // setValue('insurance_type', '')
  }

  // const setTestNameData = () => {
  //   let data: any = []
  //   if (watchTestType?.value === 'RADIOLOGY') {
  //     data = radiologyTestsData
  //   } else if (watchTestType?.value === 'LABORATORY') {
  //     data = labTestsData
  //   }
  //   return data
  // }

  const submitRequestData = () => {
    let payload = {
      ipd_case_id: getPatiendId?.ipd_id,
      mc_name: userData?.mc_name,
      is_internal: type === 'internal' ? 'true' : 'false',
      laboratory_tests: labRequestData,
      radiology_tests: radiologyRequestData,
    }
    dispatch(addPatientRequestList(requestGenerator(payload))).then((e) => {
      if (e.type === `${ADD_PATIENT_REQUESTS_TYPE}/fulfilled`) {
        dispatch(clearIpdRequestData())
        // closeModal()
      }
    })
  }
  // set billable type
  useEffect(() => {
    if (
      selectedInsuranceType?.value &&
      watchTestType?.value &&
      seletedTestName?.value &&
      testPrice
    ) {
      setValue('billable', billable_type_array[0])
    }
  }, [
    setValue,
    selectedInsuranceType,
    watchTestType,
    seletedTestName,
    testPrice,
  ])
  return (
    <>
      <div className={styles.customRadio}>
        <CustomRadio
          label={t('InvoiceInfo.Internal') || 'Internal'}
          name="type"
          checked={type === 'internal'}
          onClick={() => setType('internal')}
        />
        <CustomRadio
          label={t('InvoiceInfo.External') || 'External'}
          name="type"
          checked={type === 'external'}
          onClick={() => setType('external')}
        />
      </div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className={styles.formFieldContainer}>
          <div
            className={[
              styles.inputFieldContainer,
              styles.singleFieldContainer,
            ].join(' ')}
          >
            <label htmlFor={'insurance_type'} className={styles.formLabel}>
              {t('RequestDiagnosis.InsuranceType')}
            </label>
            <div
              className={[
                styles.fieldAndErrorTxtContainerWithI,
                styles.insuranceFieldAndErrorTxtContainer,
              ].join(' ')}
            >
              <Select
                className={styles.selectInputField}
                placeholder={
                  t('InvoiceInfo.Select Insurance') || 'Select Insurance'
                }
                closeMenuOnSelect={true}
                components={{ DropdownIndicator }}
                {...register('insurance_type')}
                isSearchable={false}
                isClearable={true}
                value={watch('insurance_type') || ''}
                options={patientInsurancePlanOptions}
                onChange={(e: any) => {
                  setValue('insurance_type', e)
                  trigger('insurance_type')
                  dispatch(clearIpdRequestData())
                }}
                maxMenuHeight={200}
                styles={reactSelectStyle}
              />
              {/* <div className={styles.information}>
                <InfoIcon
                  fillColor={colors.grey2}
                  mouseEnter={() => setShow(true)}
                  mouseLeave={() => setShow(false)}
                />
                {show && (
                  <span className={styles.infoMessage}>
                    On Changing of Insurance Request Test will be clear
                  </span>
                )}
              </div> */}
            </div>
          </div>
          <div className={styles.inputFieldContainer}>
            <label className={styles.formLabel}>
              {t('RequestDiagnosis.TestType')}
              <span className="asterick">*</span>
            </label>
            <div className={styles.fieldAndErrorTxtContainer}>
              <Select
                className={styles.selectInputField}
                placeholder={
                  t('IPDRequest.SelectTestType') || 'Select test type'
                }
                closeMenuOnSelect={true}
                value={watchTestType}
                components={{ DropdownIndicator }}
                options={test_type_array}
                {...register('testType', { required: true })}
                isSearchable={false}
                onChange={(e: any) => {
                  setValue('testType', e)
                }}
                maxMenuHeight={200}
              />
              {errors?.testType?.type === 'required' && (
                <p className={styles.formError}>
                  {t('IPDRequest.SelectTypeValidation')}
                </p>
              )}
            </div>
          </div>
          <div className={styles.inputFieldContainer}>
            <label className={styles.formLabel}>
              {t('RequestDiagnosis.TestName')}
              <span className="asterick">*</span>
            </label>
            <div className={styles.fieldAndErrorTxtContainer}>
              <Select
                className={styles.selectInputField}
                placeholder={
                  t('IPDRequest.SelectTestName') || 'Select test name'
                }
                value={watch('testName')}
                closeMenuOnSelect={true}
                components={{ DropdownIndicator }}
                options={testNameOptions}
                {...register('testName', { required: true })}
                isSearchable={false}
                onChange={(e: any) => {
                  setValue('testName', e)
                  setValue('price', e.test_price)
                }}
                maxMenuHeight={200}
                styles={reactSelectStyle}
              />
              {errors?.testName?.type === 'required' && (
                <p className={styles.formError}>
                  {t('IPDRequest.SelectTestNameValidation')}
                </p>
              )}
            </div>
          </div>
          <div className={styles.inputFieldContainer}>
            <label className={styles.formLabel}>
              {t('RequestDiagnosis.Price')}
              <span className="asterick">*</span>
            </label>
            <div className={styles.fieldAndErrorTxtContainer}>
              <input
                type="number"
                placeholder={t('IPDRequest.AddPrice') || 'Add Price'}
                className={styles.inputField}
                {...register('price', { required: true })}
                onKeyDown={(e: any) => disableArrowKey(e)}
                disabled
              />
              {errors?.price?.type === 'required' && (
                <p className={styles.formError}>
                  {t('IPDRequest.AddPriceValidation')}
                </p>
              )}
            </div>
          </div>
          <div className={styles.inputFieldContainer}>
            <label className={styles.formLabel}>
              {t('RequestDiagnosis.Billable')}
              <span className="asterick">*</span>
            </label>
            <div className={styles.fieldAndErrorTxtContainer}>
              <Select
                className={styles.selectInputField}
                placeholder={t('ReportGenerator.Select') || 'Select'}
                closeMenuOnSelect={true}
                value={watch('billable')}
                components={{ DropdownIndicator }}
                options={billable_type_array}
                {...register('billable', { required: true })}
                isSearchable={false}
                onChange={(e: any) => {
                  setValue('billable', e)
                }}
                maxMenuHeight={200}
              />
              {errors?.billable?.type === 'required' && (
                <p className={styles.formError}>
                  {t('IPDRequest.SelectBillable')}
                </p>
              )}
            </div>
          </div>
          <div className={styles.inputFieldContainer}>
            <label className={styles.formLabel}>
              {t('RequestDiagnosis.Priority')}
              <span className="asterick">*</span>
            </label>
            <div className={styles.fieldAndErrorTxtContainer}>
              <Select
                className={styles.selectInputField}
                placeholder={t('ReportGenerator.Select') || 'Select'}
                closeMenuOnSelect={true}
                value={watch('priority')}
                components={{ DropdownIndicator }}
                options={priority_type_array}
                {...register('priority', { required: true })}
                isSearchable={false}
                onChange={(e: any) => {
                  setValue('priority', e)
                }}
                maxMenuHeight={200}
              />
              {errors?.priority?.type === 'required' && (
                <p className={styles.formError}>
                  {t('IPDRequest.SelectPriority')}
                </p>
              )}
            </div>
          </div>
          <div className={styles.formTextFields}>
            <label>{t('Common.Notes')}</label>
            <textarea rows={1} cols={20} {...register('notes')} />
          </div>
        </div>
        <div className={styles.formBtnContainer}>
          <Button title={t('InvoiceService.Add') || 'Add'} type="submit" />
        </div>
      </form>
      <div className={styles.tableContainer}>
        <TableV2
          tableHeaderData={IpdRequestHeaderData}
          tableRowData={
            ipdRequestData && ipdRequestData?.length ? ipdRequestData : []
          }
          active={false}
        />
      </div>
      <div className={styles.formBtnContainer}>
        <Button
          title={t('Common.Save') || 'Save'}
          type="button"
          handleClick={submitRequestData}
          disable={!ipdRequestData?.length}
        />
      </div>
    </>
  )
}

export default IpdRequest
