import { FC } from 'react'
import styles from './previewFormDialog.module.scss'
import { CloseIcon } from '../../../components/common/svg-components'
import { colors } from '../../../constants/color'
import Divider from '../../../components/common/divider/Divider'

import { Form } from 'react-formio/lib/components'
import { useTranslation } from 'react-i18next'

interface IPreviewFormDialog {
  handleClose?: any
  selectedFormDetails?: any
  open: boolean
  submit?: boolean
  handleSubmit: any
  formData?: any
  readOnly?: boolean
}

const PreviewFormDialog: FC<IPreviewFormDialog> = ({
  handleClose,
  selectedFormDetails,
  open,
  submit = false,
  handleSubmit,
  formData,
  readOnly = true,
}) => {
  const handleSubmitForm = (data: any) => {
    if (submit) {
      handleSubmit({ formData: data, form: selectedFormDetails })
    }
  }
  const { t } = useTranslation()
  return (
    <>
      {/* Form Preview Dialog */}
      {open && (
        <div className="dialog" onClick={(e) => e.stopPropagation()}>
          <div
            className={styles.mainContainer}
            onClick={(e) => e.stopPropagation()}
          >
            <div className={styles.iconContainer}>
              <CloseIcon
                customClass={styles.closeIconStyle}
                fillColor={colors.green1}
                handleClick={handleClose}
              />
            </div>

            <header className={styles.headerContainer}>
              <span className={styles.title}>{t('Common.Form Preview')}</span>
              <Divider customClass={styles.dividerStyle} />
            </header>

            <section className={styles.sectionContainer}>
              <Form
                form={JSON.parse(selectedFormDetails?.form)}
                onSubmit={(e: any) => handleSubmitForm(e)}
                submission={formData ?? {}}
                options={{ readOnly: readOnly }}
              />
            </section>
          </div>
        </div>
      )}
      {/* Form Preview Dialog */}
    </>
  )
}
export default PreviewFormDialog
