import { FC, useEffect } from 'react'
import { colors } from '../../../../../constants/color'
import { appointmentLogData } from '../../../../../constants/table-data/appointmentLogPopupData'
import { useAppDispatch, useAppSelector } from '../../../../../hooks'
import { getAppointmentLog } from '../../../../../redux/features/appointment/appointmentAsyncActions'
import { requestGenerator } from '../../../../../utils/payloadGenerator'
import Divider from '../../../divider/Divider'
import { CloseIcon } from '../../../svg-components'
import TableV2 from '../../../table/tableV2/TableV2'
import styles from './appointmentLogPopup.module.scss'
import Loader from '../../../spinner/Loader'
import { useTranslation } from 'react-i18next'

interface IAppointmentLog {
  handleClick?: any
  popData?: any
}

const AppointmentLogPopup: FC<IAppointmentLog> = ({ handleClick, popData }) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const { isLoading, appointmentLog } = useAppSelector(
    (state) => state.appointment
  )

  useEffect(() => {
    let reqData = {
      id: popData,
    }
    dispatch(getAppointmentLog(requestGenerator(reqData)))
  }, [])

  return (
    <>
      {isLoading && <Loader />}
      <div className={styles.popupContainer}>
        <CloseIcon
          customClass={styles.closeIconStyle}
          fillColor={colors.green1}
          handleClick={handleClick}
        />
        <div
          className={styles.viewAppointment}
          onClick={(e) => {
            e.stopPropagation()
          }}
        >
          <p className={styles.title}>{t('ViewAppointment.Appointment Log')}</p>
          <Divider customClass={styles.dividerStyle} />
          <div className={styles.tableBlock}>
            <TableV2
              tableHeaderData={appointmentLogData}
              tableRowData={appointmentLog}
              active={false}
            />
          </div>
        </div>
      </div>
    </>
  )
}

export default AppointmentLogPopup
