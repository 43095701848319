import styles from './profitLoss.module.scss'
import { isBoldRow, isBoldRowiWithTotal } from '../../../constants/data'
import { t } from 'i18next'

// BalanceSheet Table Data
const currentDate = new Date()
const formattedDate = `AS AT ${currentDate.getDate()} ${currentDate.toLocaleString(
  'default',
  { month: 'short' }
)} ${currentDate.getFullYear()}`
// Expense
export const profitLossTableHeaderData: any = [
  {
    Header: t('Reports.A/C_NO'),
    Cell: ({ row }: any) => {
      return (
        <p
          className={
            isBoldRowiWithTotal.includes(row?.original?.level)
              ? styles.boldTd
              : styles.normalStyleTd
          }
        >
          {row?.original?.account_no ? row?.original?.account_no : '-'}
        </p>
      )
    },
  },
  {
    Header: t('MobileAppConfiguration.DESCRIPTION'),
    Cell: ({ row }: any) => {
      return (
        <p
          className={
            isBoldRowiWithTotal.includes(row?.original?.level)
              ? styles.boldTd
              : styles.normalStyleTd
          }
        >
          {row?.original?.account_name ? row?.original?.account_name : '-'}
        </p>
      )
    },
  },
  {
    Header: t('InvoiceService.AMOUNT'),
    Cell: ({ row }: any) => {
      return (
        <p
          className={
            isBoldRowiWithTotal.includes(row?.original?.level)
              ? styles.boldTd
              : styles.normalStyleTd
          }
        >
          {row?.original?.amount
            ? row?.original?.amount
            : isBoldRow.includes(row?.original?.level)
            ? ''
            : 0}{' '}
        </p>
      )
    },
  },
]

export const expenseBalanceTableHeaderData: any = [
  {
    Header: t('Voucher.VOUCHER_NO'),
    accessor: (row: any) => {
      return row?.number ?? ''
    },
  },
  {
    Header: t('Reports.CREDIT_BALANCE'),
    accessor: (row: any) => {
      return row?.credit_amount > 0 ? row?.credit_amount : '-'
    },
  },
  {
    Header: t('Reports.DEBIT_BALANCE'),
    accessor: (row: any) => {
      return row?.debit_amount > 0 ? row?.debit_amount : '-'
    },
  },
]
export const incomeBalanceTableHeaderData: any = [
  {
    Header: t('Voucher.VOUCHER_NO'),
    accessor: (row: any) => {
      return row?.number ?? ''
    },
  },
  {
    Header: t('Reports.CREDIT_BALANCE'),
    accessor: (row: any) => {
      return row?.credit_amount > 0 ? row?.credit_amount : '-'
    },
  },
  {
    Header: t('Reports.DEBIT_BALANCE'),
    accessor: (row: any) => {
      return row?.debit_amount > 0 ? row?.debit_amount : '-'
    },
  },
]
