import { FC, useState, useEffect } from 'react'
import styles from './PatientListModal.module.scss'
import TableV3 from '../../table/tableV3/TableV3'
import Divider from '../../divider/Divider'
import { CloseIcon, SearchButton } from '../../svg-components'
import { colors } from '../../../../constants/color'
import {
  Column,
  TableOptions,
  useGlobalFilter,
  useSortBy,
  useTable,
} from 'react-table'
import { Cols } from '../../../../interfaces/interfaces'
import { trimValue } from '../../../../utils/utils'
import Pagination from '../../pagination/Pagination'
import { useTranslation } from 'react-i18next'

interface IPatientSearchModal {
  tableHeaderData: any
  tableRowData: any[]
  handleRowClick?: any
  handlePatientList?: any
  searchValue?: any
  setSearchValue?: any
  activateSmartSearch?: any
  setActivateSmartSearch?: any
  handleInputSearch?: any
  dataPerPage?: any
  totalPage?: any
  pageIndex?: any
  setDataPerPage?: any
  pageIndexOptions?: any
  setPageIndex?: any
  handleSearch?: any
}
const PatientListModal: FC<IPatientSearchModal> = ({
  tableHeaderData,
  tableRowData,
  handlePatientList,
  handleRowClick,
  searchValue,
  setSearchValue,
  activateSmartSearch,
  setActivateSmartSearch,
  handleInputSearch,
  dataPerPage,
  totalPage,
  pageIndex,
  setDataPerPage,
  pageIndexOptions,
  setPageIndex,
  handleSearch,
}) => {
  // React Table define
  const data: Cols[] = tableRowData
  const columns: Column<Cols>[] = tableHeaderData
  const options: TableOptions<Cols> = {
    data,
    columns,
  }
  const { t } = useTranslation()
  const {
    state,
    // @ts-ignore
    setGlobalFilter,
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable(options, useGlobalFilter, useSortBy)

  useEffect(() => {
    handlePatientList()
  }, [searchValue, dataPerPage, pageIndex])

  return (
    <>
      <div
        className={styles.mainContainer}
        onClick={(e) => {
          e.stopPropagation()
        }}
      >
        <div className={styles.searchFieldContainer}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              marginBottom: '25px',
            }}
          >
            <div
              style={{
                position: 'relative',
                display: 'flex',
                flexDirection: 'row',
              }}
            >
              <input
                type="text"
                className={styles.inputSearchContainer}
                placeholder={t('ServiceMaster.Search') || 'Search'}
                onKeyDown={(e: any) => {
                  if (e?.key === 'Enter') {
                    handleSearch()
                  }
                }}
                onChange={(e) => {
                  trimValue(e)
                  setSearchValue(e.target.value)
                  // if ( e.target.value === "") {
                  // handleSearch();
                  // }
                  setGlobalFilter('')
                }}
              />

              <SearchButton
                handleClick={() => {
                  if (!!searchValue) {
                    handleSearch()
                  }
                }}
                customClass={styles.inputSearchButton}
              />
            </div>

            <div
              style={{
                position: 'relative',
                display: 'flex',
                flexDirection: 'row',
                marginLeft: '18px',
              }}
            >
              <input
                type="text"
                className={
                  !activateSmartSearch
                    ? styles.inputSmartSearchContainer
                    : styles.inputSearchContainer
                }
                placeholder={t('Common.Smart Search') || 'Smart Search'}
                disabled={!activateSmartSearch}
                onChange={(e) => {
                  trimValue(e)
                  setGlobalFilter(e.target.value)
                }}
                // value={searchValue === '' ? searchValue : globalFilter}
              />
            </div>
          </div>

          <Divider customClass={styles.dividerStyling} />
          <div className={styles.tableContainer}>
            <TableV3
              handleRowClick={(item: any) =>
                handleRowClick && handleRowClick(item)
              }
              //   invoiceFlag={invoiceFlag}
              getTableProps={getTableProps}
              getTableBodyProps={getTableBodyProps}
              headerGroups={headerGroups}
              rows={rows}
              prepareRow={prepareRow}
              //   setModelOpenClose={setModelOpenClose}
            />
          </div>

          {/* {tableRowData?.data.length < 9 &&
          tableRowData?.lastPage === 1 &&
          tableRowData?.nextPage === 0 &&
          tableRowData?.previousPage === 0 ? (
            " "
          ) : ( */}
          <Pagination
            setDataPerPage={setDataPerPage}
            pageIndexOptions={pageIndexOptions}
            pageIndex={pageIndex}
            setPageIndex={setPageIndex}
          />
          {/* )} */}
        </div>
      </div>
    </>
  )
}

export default PatientListModal
