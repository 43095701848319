import moment from 'moment'
import { EditIcon } from '../../components/common/svg-components'
import { colors } from '../color'
import { useNavigate } from 'react-router-dom'
import { t } from 'i18next'

export const OnGoingCampaignsHeaderData: any = [
  {
    Header: t('CallCenterAgent.CAMPAIGN_NAME'),
    accessor: (row: any) => {
      return row?.camp_name ? row?.camp_name : '-'
    },
  },
  {
    Header: t('CallCenterAgent.START_DATE'),
    Cell: ({ row }: any) => {
      const originalDate = row?.original?.start_date
      const formattedDate = moment(originalDate).format('DD MMM YYYY')
      return <>{formattedDate}</>
    },
  },
  {
    Header: t('CallCenterAgent.END_DATE'),
    Cell: ({ row }: any) => {
      const originalDate = row?.original?.end_date
      const formattedDate = moment(originalDate).format('DD MMM YYYY')
      return <>{formattedDate}</>
    },
  },
  {
    Header: t('Campaigns.TOTAL_LEADS'),
    accessor: 'total_leads',
    Cell: ({ row }: any) => {
      const totalLeads = row?.original?.total_leads
      return totalLeads ? totalLeads : 0
    },
  },
  {
    Header: t('Campaigns.ASSIGNED'),
    accessor: 'assigned',
    Cell: ({ row }: any) => {
      const assignedCampaign = row?.original?.assigned_leads
      return assignedCampaign ? assignedCampaign : 0
    },
  },
  {
    Header: t('Campaigns.UNASSIGNED'),
    accessor: 'unassigned',
    Cell: ({ row }: any) => {
      const unassignedCampaign = row?.original?.unassigned_leads
      return unassignedCampaign ? unassignedCampaign : 0
    },
  },
  {
    Header: t('Campaigns.ASSIGNED_AGENT'),
    accessor: 'assigned_agents',
    Cell: ({ row }: any) => {
      const assignedAgents = row?.original?.total_agents
      return assignedAgents ? assignedAgents : 0
    },
  },
  {
    Header: `${t('Campaigns.CONVERSION_RATE')} %`,
    accessor: 'conversion_rate',
    Cell: ({ row }: any) => {
      const conversionRate = row?.original?.conversion_rate
      return conversionRate ? `${conversionRate}%` : '-'
    },
  },
  {
    Header: t('RequestDiagnosis.ACTIONS'),
    Cell: ({ row, invoiceFlag }: any) => {
      const navigate = useNavigate()
      const handleEdit = (item: any) => {
        navigate('/campaigns/createCampaigns', {
          state: { user: item },
        })
      }
      return !invoiceFlag ? (
        <EditIcon
          fillColor={colors.grey4}
          handleClick={(e) => {
            e.stopPropagation()
            handleEdit(row?.original)
          }}
        />
      ) : (
        '-'
      )
    },
  },
]
