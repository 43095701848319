//voucher
export const VOUCHERS_TYPE = 'voucher_type'
export const VOUCHER_STATUS = 'voucher_status'
export const VOUCHER_NO = 'voucher_number'
export const DATE = 'date'
export const VOUCHER_REMARKS = 'voucher_remarks'
export const REF_DOC_CATEGORY = 'reference_doc_category'
export const REFERENCE_NO = 'reference_number'
export const ACCOUNT = 'accounts_data'
export const ACCOUNT1 = 'account1'
export const CREDIT_AMOUNT = 'credit_amount'
export const DEBIT_AMOUNT = 'debit_amount'
export const COST_CENTER = 'cost_center'
export const COST_CENTER_VALUE = 'cost_center_value'
export const ENTRY_REMARKS = 'entry_remarks'
export const ENTRY_DATE = 'entry_date'
export const ADD_VOUCHER_NAME = 'name'
export const ADD_VOUCHER_CODE = 'initials'
export const COST_VOUCHER_NO = 'cost_voucher_no'
export const ACCOUNT_NAME = 'voucher_name'
