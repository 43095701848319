import { t } from 'i18next'
import { DeleteIcon, EditIcon } from '../../components/common/svg-components'
import styles from './tableData.module.scss'

export const consultationHeaderData: any = [
  {
    Header: t('RcptTableHeader.DATE'),
    accessor: 'date',
    Cell: ({ row }: any) => {
      return <p>{row?.original?.date ? row?.original?.date : '-'}</p>
    },
  },
  {
    Header: t('ViewAppointmentHeader.DOCTOR'),
    accessor: 'primary_doctor_name',
  },
  {
    Header: t('PatientHistoryTableHeader.NOTES'),
    accessor: 'notes',
    Cell: (props: any) => {
      return (
        <p
          className={styles.blueLinkText}
          onClick={() => {
            props?.onRowClick(props?.row?.original)
          }}
        >
          {t('RequestDiagnosis.Notes')}
        </p>
      )
    },
  },
]

export const consultationPopupNotesHeaderData: any = [
  {
    Header: t('IPDHistory.NOTES TIME'),
    accessor: 'time',
  },
  {
    Header: t('PatientHistoryTableHeader.NOTES'),
    accessor: 'notes',
    Cell: (props: any) => {
      return (
        <p
          className={styles.blueLinkText}
          onClick={() => {
            props?.onRowClick(props?.row?.original?.note)
          }}
        >
          {t('Common.View')}
        </p>
      )
    },
  },
  {
    Header: t('DocUploadHeader.ACTION'),
    accessor: 'nos',
    Cell: (props: any) => {
      return (
        <div className={styles.actionIconContainer}>
          <EditIcon
            handleClick={() => {
              props?.onClick(props?.row?.original)
            }}
          />
          <DeleteIcon
            fillColor="#CDD4D8"
            customClass={styles.deleteIconStyle}
            handleClick={() => {
              props?.onPopClose(props?.row?.original?._id)
            }}
          />
        </div>
      )
    },
  },
]
