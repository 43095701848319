// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ipdTimelineMainLayout_consultationContainer__EJIaZ {
  background-color: var(--white1);
  box-shadow: 0px 10px 117px 14px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  padding: 25px;
  margin: 0 20px 50px 0;
}
.ipdTimelineMainLayout_consultationContainer__EJIaZ .ipdTimelineMainLayout_title__6Ox2X {
  text-align: center;
  font-size: var(--font-20);
  font-weight: var(--font-semibold);
}
.ipdTimelineMainLayout_consultationContainer__EJIaZ .ipdTimelineMainLayout_dividerStyle__yz1H\\+ {
  margin: 10px auto 30px auto;
  width: 80px;
  height: 3px;
  background: var(--green1);
}`, "",{"version":3,"sources":["webpack://./src/pages/ipd/create-ipd-main-layout/ipd-timeline/ipd-timeline-main-layout/ipdTimelineMainLayout.module.scss"],"names":[],"mappings":"AAAA;EACE,+BAAA;EACA,mDAAA;EACA,mBAAA;EACA,aAAA;EACA,qBAAA;AACF;AAAE;EACE,kBAAA;EACA,yBAAA;EACA,iCAAA;AAEJ;AAAE;EACE,2BAAA;EACA,WAAA;EACA,WAAA;EACA,yBAAA;AAEJ","sourcesContent":[".consultationContainer {\n  background-color: var(--white1);\n  box-shadow: 0px 10px 117px 14px rgba(0, 0, 0, 0.05);\n  border-radius: 10px;\n  padding: 25px;\n  margin: 0 20px 50px 0;\n  .title {\n    text-align: center;\n    font-size: var(--font-20);\n    font-weight: var(--font-semibold);\n  }\n  .dividerStyle {\n    margin: 10px auto 30px auto;\n    width: 80px;\n    height: 3px;\n    background: var(--green1);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"consultationContainer": `ipdTimelineMainLayout_consultationContainer__EJIaZ`,
	"title": `ipdTimelineMainLayout_title__6Ox2X`,
	"dividerStyle": `ipdTimelineMainLayout_dividerStyle__yz1H+`
};
export default ___CSS_LOADER_EXPORT___;
