import { t } from 'i18next'
import {
  ACCESS_KEY,
  IBAN,
  MERCHANT_ID,
  UPAY_PASSWORD,
  USERNAME,
} from '../pages/upay/upayConstant'

export const upayValidators: any = {
  [MERCHANT_ID]: {
    required: t('upay.MerchantId'),
  },
  [USERNAME]: {
    required: t('upay.UserName'),
  },
  [UPAY_PASSWORD]: {
    required: t('upay.Password'),
  },
  [ACCESS_KEY]: {
    required: t('upay.AccessKey'),
  },
  [IBAN]: {
    required: t('upay.IBAN'),
  },
}
