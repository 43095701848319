import { FC, useEffect } from 'react'
import { CloseIcon } from '../../../components/common/svg-components'
import { colors } from '../../../constants/color'
import styles from './doctorUnavailability.module.scss'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Label } from '../../../components/common/label'
import Select from 'react-select'
import makeAnimated from 'react-select/animated'
import { IAddDocUnavailablity } from '../../../interfaces/interfaces'
import {
  DOCTOR_ID,
  END_DATE,
  END_TIME,
  NOTES,
  ROOM_ID,
  START_DATE,
  START_TIME,
} from '../unavailabilityConstant'
import {
  hourDropdown,
  minDropdown,
  reactSelectStyle,
} from '../../../constants/data'
import { unavailabilityValidators } from '../unavailabilityValidators'
import {
  END_TIME_HR,
  END_TIME_MIN,
  START_TIME_HR,
  START_TIME_MIN,
} from '../../../constants/bookingConfirmationConstatnt'
import Button from '../../../components/common/button/Button'
import { trimValue } from '../../../utils/utils'

interface IDoctorPopup {
  handleClose: any
  headerData: any
  handleYes: any
  popData: any
  isDefault: any
}

const DoctorPopup: FC<IDoctorPopup> = ({
  handleClose,
  headerData,
  handleYes,
  popData,
  isDefault,
}) => {
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    trigger,
    reset,
    formState: { errors },
  } = useForm<IAddDocUnavailablity>({})
  const { t } = useTranslation()
  const animatedComponent = makeAnimated()

  const onsubmit = (data: any) => {
    handleYes({
      ...data,
      isEdit: popData?.type === 'edit',
      _id: popData?._id,
    })
  }

  const startTimeHr = watch(START_TIME_HR)
  const startTimeMin = watch(START_TIME_MIN)
  const endTimeHr = watch(END_TIME_HR)
  const endTimeMin = watch(END_TIME_MIN)
  const watchStartDate = watch(START_DATE)
  const watchEndDate = watch(END_DATE)
  useEffect(() => {
    if (popData?.type === 'edit') {
      const doctor = headerData?.find(
        (item: { label: string; value: string }) =>
          isDefault
            ? item?.value === popData?.room_id
            : item?.value === popData?.doctor_id
      )
      const startTime = popData?.start_time
        ? popData.start_time?.split(':')
        : []
      const endTime = popData?.end_time ? popData?.end_time?.split(':') : []
      const formData = {
        [DOCTOR_ID]: !isDefault ? doctor : undefined,
        [ROOM_ID]: isDefault ? doctor : undefined,
        [START_DATE]: popData?.[START_DATE],
        [END_DATE]: popData?.[END_DATE],
        [START_TIME_HR]:
          startTime?.length > 0
            ? { label: startTime[0], value: startTime[0] }
            : undefined,
        [START_TIME_MIN]:
          startTime?.length > 0
            ? { label: startTime[1], value: startTime[1] }
            : undefined,
        [END_TIME_HR]:
          endTime?.length > 0
            ? {
                label: endTime[0],
                value: endTime[0],
              }
            : undefined,
        [END_TIME_MIN]:
          endTime?.length > 0
            ? {
                label: endTime[1],
                value: endTime[1],
              }
            : undefined,
        [START_TIME]: popData?.[START_TIME] || '',
        [END_TIME]: popData?.[END_TIME] || '',
        [NOTES]: popData?.[NOTES],
      }
      reset(formData)
    } else {
      reset()
    }
  }, [popData, headerData, reset, isDefault])

  return (
    <div
      className={styles.PopupContainer}
      onClick={(e) => {
        e.stopPropagation()
      }}
    >
      <CloseIcon
        customClass={styles.closeIconStyle}
        fillColor={colors.green1}
        handleClick={handleClose}
      />
      <div className={styles.addContainer}>
        <div className={styles.titleContainer}>
          <h2 className={styles.addHeading}>
            {isDefault
              ? t('Unavailability.RoomUnavailability')
              : t('Unavailability.DoctoUnavailability')}
          </h2>
          <span className={styles.textUnderline} />
        </div>
        <form onSubmit={handleSubmit(onsubmit)}>
          <div className={styles.formWrapper}>
            <div className={styles.inputWrapper}>
              <Label
                htmlFor=""
                labelText={
                  isDefault
                    ? t('IPDBooking.Room') || 'Room'
                    : t('Receptionist Dashboard.Doctor') || 'Doctor'
                }
                requiredField={true}
              />
              <div className={styles.inputContainer}>
                <Select
                  className={styles.selectField}
                  isSearchable={true}
                  options={headerData}
                  value={isDefault ? watch(ROOM_ID) : watch(DOCTOR_ID)}
                  components={animatedComponent}
                  closeMenuOnSelect={true}
                  placeholder={
                    isDefault
                      ? t('IPDBooking.SelectRoom')
                      : t('IPDBooking.Select Doctor')
                  }
                  {...register(
                    isDefault ? ROOM_ID : DOCTOR_ID,
                    isDefault
                      ? unavailabilityValidators[ROOM_ID]
                      : unavailabilityValidators[DOCTOR_ID]
                  )}
                  onChange={(e: any) => {
                    if (isDefault) {
                      setValue(ROOM_ID, e)
                      trigger(ROOM_ID)
                    } else {
                      setValue(DOCTOR_ID, e)
                      trigger(DOCTOR_ID)
                    }
                  }}
                  styles={reactSelectStyle}
                  isDisabled={popData?._id}
                  maxMenuHeight={195}
                />
                {isDefault
                  ? errors[ROOM_ID] && (
                      <p className="dashboardFormError">
                        {errors[ROOM_ID].message}
                      </p>
                    )
                  : errors[DOCTOR_ID] && (
                      <p className="dashboardFormError">
                        {errors[DOCTOR_ID].message}
                      </p>
                    )}
                {}
              </div>
            </div>
            <div className={styles.inputWrapper}>
              <Label
                htmlFor=""
                labelText={t('BookingConfirmation.Start Date') || 'Start Date'}
                requiredField={true}
              />
              <div className={styles.inputContainer}>
                <input
                  type="date"
                  className={styles.inputField}
                  {...register(
                    START_DATE,
                    unavailabilityValidators[START_DATE]
                  )}
                  min={new Date()?.toISOString()?.split?.('T')?.[0]}
                  max={
                    watchEndDate &&
                    (new Date(watchEndDate)?.toISOString()?.split?.('T')[0] ??
                      '')
                  }
                />
                {errors[START_DATE] && (
                  <p className="dashboardFormError">
                    {errors[START_DATE].message}
                  </p>
                )}
              </div>
            </div>
            <div className={styles.inputWrapper}>
              <Label
                htmlFor=""
                labelText={t('ReportBuilder.End_Date') || 'End Date'}
                requiredField={true}
              />
              <div className={styles.inputContainer}>
                <input
                  type="date"
                  className={styles.inputField}
                  {...register(END_DATE, unavailabilityValidators[END_DATE])}
                  min={
                    watchStartDate &&
                    new Date(watchStartDate)?.toISOString()?.split?.('T')?.[0]
                  }
                />
                {errors[END_DATE] && (
                  <p className="dashboardFormError">
                    {errors[END_DATE].message}
                  </p>
                )}
              </div>
            </div>
            <div className={styles.inputWrapper}>
              <div className={styles.formFieldContainer}>
                <div className={styles.inputFieldContainer}>
                  <Label
                    htmlFor=""
                    labelText={t('BookingConfirmation.Time') || 'Time'}
                  />
                  <input
                    type="time"
                    {...register(START_TIME)}
                    style={{ display: 'none' }}
                    // disabled
                  />
                  <Select
                    className={styles.selectTimeInputField}
                    {...register(START_TIME_HR)}
                    isSearchable={true}
                    isClearable={true}
                    options={hourDropdown}
                    maxMenuHeight={200}
                    value={watch(START_TIME_HR) || []}
                    components={animatedComponent}
                    placeholder={t('BookingConfirmation.Hour') || 'Hour'}
                    onChange={(e) => {
                      setValue(START_TIME_HR, e)
                      trigger(START_TIME_HR)
                      if (startTimeMin && e.value) {
                        setValue(START_TIME, `${e.value}:${startTimeMin.value}`)
                      } else {
                        setValue(START_TIME, '')
                      }
                    }}
                    styles={reactSelectStyle}
                  />
                  <Select
                    className={styles.selectTimeInputField}
                    {...register(START_TIME_MIN)}
                    isSearchable={true}
                    isClearable={true}
                    options={minDropdown}
                    maxMenuHeight={200}
                    value={watch(START_TIME_MIN) || []}
                    components={animatedComponent}
                    placeholder={t('BookingConfirmation.Min') || 'Min'}
                    onChange={(e) => {
                      setValue(START_TIME_MIN, e)
                      trigger(START_TIME_MIN)
                      if (startTimeHr && e.value) {
                        setValue(START_TIME, `${startTimeHr.value}:${e.value}`)
                      } else {
                        setValue(START_TIME, '')
                      }
                    }}
                    styles={reactSelectStyle}
                  />
                  <p>{t('BookingConfirmation.to')}</p>
                  <input
                    type="time"
                    {...register(END_TIME)}
                    style={{ display: 'none' }}
                    // min="12:00"
                    // max="18:00" // disabled
                  />
                  <Select
                    className={styles.selectTimeInputField}
                    {...register(END_TIME_HR)}
                    isSearchable={true}
                    isClearable={true}
                    options={hourDropdown}
                    maxMenuHeight={200}
                    value={watch(END_TIME_HR) || []}
                    components={animatedComponent}
                    placeholder={t('BookingConfirmation.Hour') || 'Hour'}
                    onChange={(e) => {
                      setValue(END_TIME_HR, e)
                      trigger(END_TIME_HR)
                      if (endTimeMin && e.value) {
                        setValue(END_TIME, `${e.value}:${endTimeMin.value}`)
                      } else {
                        setValue(END_TIME, '')
                      }
                    }}
                    styles={reactSelectStyle}
                  />
                  <Select
                    className={styles.selectTimeInputField}
                    {...register(END_TIME_MIN)}
                    isSearchable={true}
                    isClearable={true}
                    options={minDropdown}
                    maxMenuHeight={200}
                    value={watch(END_TIME_MIN) || []}
                    components={animatedComponent}
                    placeholder={t('BookingConfirmation.Min') || 'Min'}
                    onChange={(e: any) => {
                      setValue(END_TIME_MIN, e)
                      trigger(END_TIME_MIN)
                      if (endTimeHr && e.value) {
                        setValue(END_TIME, `${endTimeHr.value}:${e.value}`)
                      } else {
                        setValue(END_TIME, '')
                      }
                    }}
                    styles={reactSelectStyle}
                  />
                </div>
              </div>
            </div>
            <div className={styles.inputWrapper}>
              <Label
                htmlFor=""
                labelText={t('Common.Notes') || 'Notes'}
                requiredField={true}
              />
              <div className={styles.inputContainer}>
                <textarea
                  placeholder={
                    t('BookingConfirmation.EnterNotes') || 'Enter Notes'
                  }
                  className={styles.textareaInputField}
                  {...register(NOTES, unavailabilityValidators[NOTES])}
                  onChange={(e: any) => {
                    trimValue(e)
                  }}
                />
                {errors[NOTES] && (
                  <p className="dashboardFormError">{errors[NOTES].message}</p>
                )}
              </div>
            </div>
          </div>
          <div style={{ textAlign: 'center' }}>
            <Button title={t('Common.Submit') || 'Submit'} type="submit" />
          </div>
        </form>
      </div>
    </div>
  )
}

export default DoctorPopup
