// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.imageViewerModal_imageViewerModalContainer__Vawu- {
  width: 700px;
  height: 450px;
  margin: auto;
  background: var(--white1);
  box-shadow: 0px 10px 117px 14px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  position: relative;
}
.imageViewerModal_imageViewerModalContainer__Vawu- .imageViewerModal_closeIconStyle__a4uXH {
  position: absolute;
  top: 10px;
  right: 8px;
  cursor: pointer;
  z-index: 10;
}
.imageViewerModal_imageViewerModalContainer__Vawu- .imageViewerModal_imageViwerStyle__Ho40k {
  width: 700px;
  height: 450px;
  object-fit: contain;
  border-radius: 10px;
}`, "",{"version":3,"sources":["webpack://./src/components/common/modal/image-viewer-modal/imageViewerModal.module.scss"],"names":[],"mappings":"AAEA;EACE,YAAA;EACA,aAAA;EAEA,YAAA;EACA,yBAAA;EACA,mDAAA;EAEA,mBAAA;EAEA,kBAAA;AAJF;AAKE;EACE,kBAAA;EACA,SAAA;EACA,UAAA;EACA,eAAA;EACA,WAAA;AAHJ;AAKE;EACE,YAAA;EACA,aAAA;EACA,mBAAA;EACA,mBAAA;AAHJ","sourcesContent":["@import '../../../../styles/mixin.scss';\n\n.imageViewerModalContainer {\n  width: 700px;\n  height: 450px;\n  // overflow: auto;\n  margin: auto;\n  background: var(--white1);\n  box-shadow: 0px 10px 117px 14px rgba(0, 0, 0, 0.05);\n  // backdrop-filter: blur(0.5px);\n  border-radius: 10px;\n  //   padding: 0px 20px 40px 30px;\n  position: relative;\n  .closeIconStyle {\n    position: absolute;\n    top: 10px;\n    right: 8px;\n    cursor: pointer;\n    z-index: 10;\n  }\n  .imageViwerStyle {\n    width: 700px;\n    height: 450px;\n    object-fit: contain;\n    border-radius: 10px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"imageViewerModalContainer": `imageViewerModal_imageViewerModalContainer__Vawu-`,
	"closeIconStyle": `imageViewerModal_closeIconStyle__a4uXH`,
	"imageViwerStyle": `imageViewerModal_imageViwerStyle__Ho40k`
};
export default ___CSS_LOADER_EXPORT___;
