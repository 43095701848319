// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.readOnlyLabelField_labelFieldContainer__acI4e {
  width: 100%;
  margin: 5px 10px 0px 0px;
}
.readOnlyLabelField_labelFieldContainer__acI4e .readOnlyLabelField_label__TJn9r {
  margin-right: 10px;
  color: var(--black2);
  font-size: var(--font-16);
  font-weight: var(--font-semibold);
}
.readOnlyLabelField_labelFieldContainer__acI4e .readOnlyLabelField_value__aSV5M {
  color: var(--grey4);
  font-size: var(--font-16);
  font-weight: var(--font-normal);
}`, "",{"version":3,"sources":["webpack://./src/components/common/readonly-labelfield/readOnlyLabelField.module.scss","webpack://./src/styles/mixin.scss"],"names":[],"mappings":"AACA;EACE,WAAA;EACA,wBAAA;AAAF;AACE;EACE,kBAAA;ECKF,oBDJgB;ECKhB,yBDL+B;ECM/B,iCDN+C;AAGjD;AADE;ECEA,mBDDgB;ECEhB,yBDF8B;ECG9B,+BDH8C;AAKhD","sourcesContent":["@import '../../../styles/mixin.scss';\n.labelFieldContainer {\n  width: 100%;\n  margin: 5px 10px 0px 0px;\n  .label {\n    margin-right: 10px;\n    @include font(var(--black2), var(--font-16), var(--font-semibold));\n  }\n  .value {\n    @include font(var(--grey4), var(--font-16), var(--font-normal));\n  }\n}\n","@mixin pageWrapper($padding) {\n  background: var(--white2);\n  // backdrop-filter: blur(6px);\n  box-shadow: 0px 10px 117px 14px rgba(0, 0, 0, 0.05);\n  border-radius: 10px;\n  padding: $padding;\n  margin: 20px 20px 30px 0px;\n}\n\n@mixin font($color, $size, $weight) {\n  color: $color;\n  font-size: $size;\n  font-weight: $weight;\n}\n\n@mixin flexCenter {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}\n\n@mixin flexVertical {\n  display: flex;\n  align-items: center;\n}\n\n@mixin flexColumn {\n  display: flex;\n  flex-direction: column;\n}\n\n@mixin responsive($width) {\n  @media (max-width: $width) {\n    @content;\n  }\n}\n\n@mixin animation($name) {\n  @keyframes #{$name} {\n    @content;\n  }\n}\n\n@mixin iconHoverText($content) {\n  cursor: pointer;\n\n  &:hover::after {\n    @include font(var(--black2), var(--font-12), var(--font-semibold));\n    @include flexCenter;\n    position: absolute;\n    content: $content;\n    background-color: var(--white1);\n    box-shadow: 0px 4px 34px rgba(0, 0, 0, 0.1);\n    border-radius: 6px;\n    width: 116px;\n    height: 36px;\n    z-index: 1;\n    opacity: 1;\n    margin: -25px 0 0 25px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"labelFieldContainer": `readOnlyLabelField_labelFieldContainer__acI4e`,
	"label": `readOnlyLabelField_label__TJn9r`,
	"value": `readOnlyLabelField_value__aSV5M`
};
export default ___CSS_LOADER_EXPORT___;
