// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.actionLogPopup_popupContainer__HXWJT {
  width: 730px;
  position: absolute;
  margin: auto;
  background: var(--white1);
  border-radius: 10px;
  padding: 10px;
}
.actionLogPopup_popupContainer__HXWJT .actionLogPopup_closeIconStyle__q4kID {
  cursor: pointer;
  float: right;
}
.actionLogPopup_popupContainer__HXWJT .actionLogPopup_viewAppointment__7jpOc {
  margin-top: 50px;
}
.actionLogPopup_popupContainer__HXWJT .actionLogPopup_viewAppointment__7jpOc .actionLogPopup_title__N7RUr {
  text-align: center;
  font-weight: var(--font-semibold);
  font-size: var(--font-24);
  line-height: 36px;
  color: var(--black2);
}
.actionLogPopup_popupContainer__HXWJT .actionLogPopup_viewAppointment__7jpOc .actionLogPopup_dividerStyle__jicOL {
  margin: 10px auto 30px auto;
  width: 80px;
  height: 3px;
  background: var(--green1);
}
.actionLogPopup_popupContainer__HXWJT .actionLogPopup_viewAppointment__7jpOc .actionLogPopup_tableBlock__9LaOa {
  height: 100%;
  max-height: 350px;
  overflow: scroll;
  width: 100%;
  white-space: nowrap;
}
.actionLogPopup_popupContainer__HXWJT .actionLogPopup_viewAppointment__7jpOc .actionLogPopup_tableBlock__9LaOa::-webkit-scrollbar {
  display: block;
  width: 7px;
  height: 7px;
}
.actionLogPopup_popupContainer__HXWJT .actionLogPopup_viewAppointment__7jpOc .actionLogPopup_tableBlock__9LaOa::-webkit-scrollbar-track {
  background: transparent;
}
.actionLogPopup_popupContainer__HXWJT .actionLogPopup_viewAppointment__7jpOc .actionLogPopup_tableBlock__9LaOa::-webkit-scrollbar-thumb {
  background-color: var(--grey7);
  border-radius: 10px;
  border: 1px solid var(--grey7);
}`, "",{"version":3,"sources":["webpack://./src/components/common/modal/view-appointment-popup/action-log-popup/actionLogPopup.module.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;EACA,kBAAA;EAEA,YAAA;EACA,yBAAA;EACA,mBAAA;EACA,aAAA;AAAF;AACE;EACE,eAAA;EACA,YAAA;AACJ;AACE;EACE,gBAAA;AACJ;AAAI;EACE,kBAAA;EACA,iCAAA;EACA,yBAAA;EACA,iBAAA;EACA,oBAAA;AAEN;AAAI;EACE,2BAAA;EACA,WAAA;EACA,WAAA;EACA,yBAAA;AAEN;AAAI;EACE,YAAA;EACA,iBAAA;EACA,gBAAA;EACA,WAAA;EACA,mBAAA;AAEN;AADM;EACE,cAAA;EACA,UAAA;EACA,WAAA;AAGR;AADM;EACE,uBAAA;AAGR;AAAM;EACE,8BAAA;EACA,mBAAA;EACA,8BAAA;AAER","sourcesContent":[".popupContainer {\n  width: 730px;\n  position: absolute;\n  // top: 90px;\n  margin: auto;\n  background: var(--white1);\n  border-radius: 10px;\n  padding: 10px;\n  .closeIconStyle {\n    cursor: pointer;\n    float: right;\n  }\n  .viewAppointment {\n    margin-top: 50px;\n    .title {\n      text-align: center;\n      font-weight: var(--font-semibold);\n      font-size: var(--font-24);\n      line-height: 36px;\n      color: var(--black2);\n    }\n    .dividerStyle {\n      margin: 10px auto 30px auto;\n      width: 80px;\n      height: 3px;\n      background: var(--green1);\n    }\n    .tableBlock {\n      height: 100%;\n      max-height: 350px;\n      overflow: scroll;\n      width: 100%;\n      white-space: nowrap;\n      &::-webkit-scrollbar {\n        display: block;\n        width: 7px;\n        height: 7px;\n      }\n      &::-webkit-scrollbar-track {\n        background: transparent;\n      }\n\n      &::-webkit-scrollbar-thumb {\n        background-color: var(--grey7);\n        border-radius: 10px;\n        border: 1px solid var(--grey7);\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"popupContainer": `actionLogPopup_popupContainer__HXWJT`,
	"closeIconStyle": `actionLogPopup_closeIconStyle__q4kID`,
	"viewAppointment": `actionLogPopup_viewAppointment__7jpOc`,
	"title": `actionLogPopup_title__N7RUr`,
	"dividerStyle": `actionLogPopup_dividerStyle__jicOL`,
	"tableBlock": `actionLogPopup_tableBlock__9LaOa`
};
export default ___CSS_LOADER_EXPORT___;
