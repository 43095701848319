import React, { useEffect, useState, FC } from 'react'
import styles from './generatePayrollPopup.module.scss'
import Button from '../../button/Button'
import { monthOptions, reactSelectStyle } from '../../../../constants/data'
import { useAppDispatch, useAppSelector } from '../../../../hooks'
import { requestGenerator } from '../../../../utils/payloadGenerator'
import { getAllDepartment } from '../../../../redux/features/department/departmentAsyncActions'
import Loader from '../../spinner/Loader'
import { CloseIcon } from '../../svg-components'
import { colors } from '../../../../constants/color'
import Divider from '../../divider/Divider'
import Select from 'react-select'
import { useForm } from 'react-hook-form'
import { getLastNYears } from '../../../../utils/utils'
import { generatePayrollValidators } from '../../../../form-validators/generatePayrollValidators'
import { IGeneratePayrollForm } from '../../../../interfaces/interfaces'
import {
  DEPARTMENT_PAYROL,
  MONTH_PAYROL,
  YEAR_PAYROL,
} from '../../../../constants/constant'
import { createPayroll } from '../../../../redux/features/payroll/payrollAsyncActions'
import { t } from 'i18next'

interface IGeneratePayrollPopup {
  handleClose?: any
  setModelOpenClose?: any
}

const GeneratePayrollPopup: FC<IGeneratePayrollPopup> = ({
  handleClose,
  setModelOpenClose,
}) => {
  const dispatch = useAppDispatch()
  const { departmentData, isLoading } = useAppSelector(
    (state) => state.department
  )
  const { payrollLoader } = useAppSelector((state) => state.payroll)

  // getAllDepartment API calls
  useEffect(() => {
    let payloadData = {
      search: '',
      page: 1,
      pageSize: 1000,
    }
    dispatch(getAllDepartment(requestGenerator(payloadData)))
  }, [])

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    trigger,
    formState: { errors },
  } = useForm<IGeneratePayrollForm>()

  const onSubmit = async (data: IGeneratePayrollForm) => {
    dispatch(createPayroll(requestGenerator(data))).then((e: any) => {
      if (e?.type === 'payroll/createPayroll/fulfilled') {
        setModelOpenClose(false)
      }
    })
  }

  return (
    <>
      {isLoading && <Loader />}
      {payrollLoader && <Loader />}
      <div
        className={styles.mainContainer}
        onClick={(e) => e.stopPropagation()}
      >
        <CloseIcon
          customClass={styles.closeIconStyle}
          fillColor={colors.green1}
          handleClick={handleClose}
        />
        <div className={styles.uploadContainer}>
          <p className={styles.title}>{t('Payroll.Generate_Payroll')}</p>
          <Divider customClass={styles.dividerStyle} />
          <form
            className={styles.formContainer}
            onSubmit={handleSubmit(onSubmit)}
          >
            <div className={styles.fieldContainer}>
              <label className={styles.labelText}>
                {t('IPDBooking.Department')}
              </label>
              <div className={styles.inputContainer}>
                <Select
                  className={styles.selectStyle}
                  placeholder={t('IPDBooking.SelectDepartment')}
                  closeMenuOnSelect={true}
                  {...register(
                    DEPARTMENT_PAYROL,
                    generatePayrollValidators[DEPARTMENT_PAYROL]
                  )}
                  isSearchable={true}
                  options={departmentData?.map((item: any) => ({
                    label: item.name,
                    value: item._id,
                  }))}
                  onChange={(e: any) => {
                    setValue(DEPARTMENT_PAYROL, e.value)
                    trigger(DEPARTMENT_PAYROL)
                  }}
                  maxMenuHeight={200}
                  styles={reactSelectStyle}
                />
                {errors[DEPARTMENT_PAYROL] && (
                  <p className="errorText">
                    {errors[DEPARTMENT_PAYROL].message}
                  </p>
                )}
              </div>
            </div>
            <div className={styles.fieldContainer}>
              <label className={styles.labelText}>{t('KPI.Month')}</label>
              <div className={styles.inputContainer}>
                <Select
                  className={styles.selectStyle}
                  placeholder={t('KPI.Select Month')}
                  closeMenuOnSelect={true}
                  {...register(
                    MONTH_PAYROL,
                    generatePayrollValidators[MONTH_PAYROL]
                  )}
                  isSearchable={true}
                  options={monthOptions}
                  onChange={(e: any) => {
                    setValue(MONTH_PAYROL, e.label)
                    trigger(MONTH_PAYROL)
                  }}
                  maxMenuHeight={200}
                  styles={reactSelectStyle}
                />
                {errors[MONTH_PAYROL] && (
                  <p className="errorText">{errors[MONTH_PAYROL].message}</p>
                )}
              </div>
            </div>
            <div className={styles.fieldContainer}>
              <label className={styles.labelText}>{t('KPI.Year')}</label>
              <div className={styles.inputContainer}>
                <Select
                  className={styles.selectStyle}
                  placeholder={t('KPI.Select Year')}
                  closeMenuOnSelect={true}
                  {...register(
                    YEAR_PAYROL,
                    generatePayrollValidators[YEAR_PAYROL]
                  )}
                  isSearchable={true}
                  options={getLastNYears(4)}
                  onChange={(e: any) => {
                    setValue(YEAR_PAYROL, e.label)
                    trigger(YEAR_PAYROL)
                  }}
                  maxMenuHeight={200}
                  styles={reactSelectStyle}
                />
                {errors[YEAR_PAYROL] && (
                  <p className="errorText">{errors[YEAR_PAYROL].message}</p>
                )}
              </div>
            </div>
            <Button
              title={t('Common.Submit') || 'Submit'}
              customClass={styles.buttonStyle}
              type="submit"
            />
          </form>
        </div>
      </div>
    </>
  )
}

export default GeneratePayrollPopup
