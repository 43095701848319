
//Add employee Personal Details

export const BRANCH = "branch";
export const EMPLOYEES_ID = "employee_id";
export const NAME_EN = "name_en";
export const NAME_AR = "name_ar";
export const BIRTH_DATE = "birth_date";
export const GENDER = "gender";
export const PHONE = "employee_phone";
export const NATIONALITY = "nationality";
export const EMPLOYEE_NATIONAL_ID = "employee_national_id";
export const CIVIL_ID = "civil_id";
export const LOCAL_ADDRESS = "local_address";
export const EMPLOYEE_PROFILE_PIC = "profile_pic";

//Add employee Company Details
export const DEPARTMENT = "department";
export const DESIGNATION = "designation";
export const REPORTING_TO = "reporting_to";
export const OVERTIME_TYPE = "overtime_type";
export const SHIFT_TYPE = "shift_type";
export const SUB_COMPANY = "sub_company";
export const ANNUAL_LEAVES = "annual_leaves";
export const DATE_OF_JOINING = "date_of_joining";
export const PROBATION_PERIOD = "probation_period";
export const PROBATION_END_DATE = "probation_end_date";
export const WEEKEND = "weekend";
export const SALARY_TYPE = "salary_type";
export const BASIC_SALARY = "basic_salary";
export const HOURLY_SALARY = "hourly_salary";
export const PAYMENT_MEDIUM = "payment_medium";
export const ALLOWANCE_NAME = "allowance_name";
export const ALLOWANCE_VALUE = "allowance_value";
export const DEDUCTION_NAME = "deduction_name";
export const DEDEUCTION_VALUE = "deduction_value";

//Add employee Bank Details
export const ACCOUNT_HOLDER_NAME = "account_holder_name";
export const  ACCOUNT_NUMBER= "account_number";
export const BANK_NAME = "bank_name";
export const  BANK_IDENTIFIER_CODE= "bank_identifier_code";
export const BRANCH_LOCATION = "branch_location";
export const TAX_PAYER_ID = "tax_payer_id";
