import { DEPT_NAME, NOTES } from '../constants/constant'
import { t } from 'i18next'

export interface IDepartmentValidators {
  [DEPT_NAME]: {
    required: string
  }
  [NOTES]: {
    required: string
  }
}

export const departmentValidators: IDepartmentValidators = {
  [DEPT_NAME]: {
    required: t('DepartmentSetup.Department Validation'),
  },
  [NOTES]: {
    required: 'Please enter notes',
  },
}
