import moment from 'moment'
import { DeleteIcon, EditIcon } from '../../components/common/svg-components'
import { colors } from '../../constants/color'
import { t } from 'i18next'

// Create Jobs Table
export const doctorUnavailabilityData: any = [
  {
    Header: t('CallCenterAgent.START_DATE'),
    accessor: 'start_date',
    Cell: (props: any) => {
      const date = props.row.original?.start_date
      return <>{moment(date).format('DD-MMM-yyyy')}</>
    },
  },
  {
    Header: t('CallCenterAgent.END_DATE'),
    accessor: 'end_date',
    Cell: (props: any) => {
      const date = props.row.original?.end_date
      return <>{moment(date).format('DD-MMM-yyyy')}</>
    },
  },
  {
    Header: t('Unavailability.Start_time'),
    accessor: 'start_time',
    Cell: (props: any) => {
      const time = props.row.original?.start_time
      return <>{time ? time : '-'}</>
    },
  },
  {
    Header: t('Unavailability.End_time'),
    accessor: 'end_time',
    Cell: (props: any) => {
      const time = props.row.original?.end_time
      return <>{time ? time : '-'}</>
    },
  },
  {
    Header: t('PatientHistoryTableHeader.NOTES'),
    accessor: 'notes',
    Cell: (props: any) => {
      const row = props.row.original
      return (
        <>
          {row?.note ? (
            <span
              style={{ color: '#0e26a3', cursor: 'pointer' }}
              onClick={() => props.onClicks({ ...row, notes: row?.note })}
            >
              {t('Common.View')}
            </span>
          ) : (
            '-'
          )}
        </>
      )
    },
  },
  {
    Header: t('RequestDiagnosis.ACTIONS'),
    accessor: '',
    Cell: (props: any) => {
      const row = props.row.original
      return (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            gap: '10px',
            justifyContent: 'center',
          }}
        >
          <EditIcon
            handleClick={() => props.onClick({ ...row, type: 'edit' })}
          />
          <DeleteIcon
            fillColor={colors.grey4}
            handleClick={() => props.onClick({ ...row, type: 'delete' })}
          />
        </div>
      )
    },
  },
]
