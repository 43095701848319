import { FC, useState, useEffect } from 'react'
import { CloseIcon } from '../../svg-components'
import { colors } from '../../../../constants/color'
import Divider from '../../divider/Divider'
import styles from './rangePopup.module.scss'
import { disableArrowKey, trimValue } from '../../../../utils/utils'
import { IRangeForm } from '../../../../interfaces/interfaces'
import { useForm } from 'react-hook-form'
import Select from 'react-select'
import Button from '../../button/Button'
import TableV2 from '../../table/tableV2/TableV2'
import { genderData } from '../../../../constants/data'
import {
  AGE_FROM,
  AGE_TO,
  GENDER_RANGE,
  PERIOD,
  RANGE_FROM,
  RANGE_TO,
} from '../../../../constants/constant'
import { rangePopupValidators } from '../../../../form-validators/rangePopupValidators'
import { useAppDispatch, useAppSelector } from '../../../../hooks'
import { getAllRangeData } from '../../../../redux/features/lab/labSlice'
import { t } from 'i18next'

interface IRange {
  handleClose?: any
  handleSubmitData?: any
  setModelOpenClose?: any
  popData?: any
}
const RangePopup: FC<IRange> = ({
  handleClose,
  handleSubmitData,
  setModelOpenClose,
  popData,
}) => {
  const { rangeTableData, componentData } = useAppSelector((state) => state.lab)
  const [tableData, setTableData] = useState<any>(rangeTableData)

  const dispatch = useAppDispatch()
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    trigger,
    reset,
    formState: { errors },
  } = useForm<IRangeForm>()

  const headerData: any = [
    {
      Header: t('LabConfiguration.GENDER'),
      accessor: 'gender',
    },
    {
      Header: t('LabConfiguration.PERIOD'),
      accessor: 'age_type',
    },
    {
      Header: t('LabConfiguration.AGE_FROM'),
      accessor: 'age_from',
    },
    {
      Header: t('LabConfiguration.AGE_TO'),
      accessor: 'age_to',
    },
    {
      Header: t('LabConfiguration.RANGE_FROM'),
      accessor: 'range_from',
    },
    {
      Header: t('LabConfiguration.RANGE_TO'),
      accessor: 'range_to',
    },
  ]

  const onSubmit = async (data: IRangeForm) => {
    const payload = {
      ...data,
      [GENDER_RANGE]: data[GENDER_RANGE]?.value,
      [PERIOD]: data[PERIOD]?.value,
    }
    setTableData((prevTableData: any) => [...prevTableData, payload])
    setTimeout(() => {
      reset()
      setValue(GENDER_RANGE, '')
      setValue(PERIOD, '')
    }, 300)
  }

  useEffect(() => {
    if (componentData?._id !== undefined && rangeTableData?.length === 0) {
      const newData = componentData?.ranges?.map(
        ({ _id, ...rest }: any) => rest
      )
      setTableData(newData)
    } else {
      setTableData(rangeTableData)
    }
  }, [componentData?._id])

  const blockInvalidChar = (e: any) =>
    ['e', 'E', '+', '-'].includes(e.key) && e.preventDefault()

  const ageFormatData: any = [
    {
      value: 'DAY',
      label: 'Day',
    },
    {
      value: 'YEAR',
      label: 'Year',
    },
  ]

  return (
    <>
      <div
        className={styles.notesPopupContainer}
        onClick={(e) => e.stopPropagation()}
      >
        <CloseIcon
          customClass={styles.closeIconStyle}
          fillColor={colors.green1}
          handleClick={() => handleClose()}
        />
        <div className={styles.notesContainer}>
          <p className={styles.title}>{t('LabConfiguration.Range')}</p>
          <Divider customClass={styles.dividerStyle} />
          <div className={styles.tableForm}>
            {popData === undefined && popData !== '1' && (
              <form
                onSubmit={handleSubmit(onSubmit)}
                className={styles.formContainer}
              >
                <div className={styles.form}>
                  <div className={styles.labelField}>
                    <label className={styles.labelText}>
                      {t('PatientEMR.Gender')}
                      <span className="asterick">*</span>
                    </label>
                    <div className={styles.fieldErrorContainer}>
                      <Select
                        className={styles.select}
                        placeholder={t('PatientEMR.Gender')}
                        closeMenuOnSelect={true}
                        isSearchable={true}
                        value={watch(GENDER_RANGE)}
                        {...register(
                          GENDER_RANGE,
                          rangePopupValidators[GENDER_RANGE]
                        )}
                        options={genderData}
                        onChange={(e: any) => {
                          setValue(GENDER_RANGE, e)
                          // setGender(e)
                          trigger(GENDER_RANGE)
                        }}
                        maxMenuHeight={200}
                      />

                      {errors[GENDER_RANGE] && (
                        <p className={styles.errorText}>
                          {errors[GENDER_RANGE].message as any}
                        </p>
                      )}
                    </div>
                  </div>
                  <div className={styles.labelField}>
                    <label className={styles.labelText}>
                      {t('ViewAppointment.Period')}
                      <span className="asterick">*</span>
                    </label>
                    <div className={styles.fieldErrorContainer}>
                      <Select
                        className={styles.select}
                        placeholder={t('ViewAppointment.Period')}
                        closeMenuOnSelect={true}
                        isSearchable={true}
                        value={watch(PERIOD)}
                        {...register(PERIOD, rangePopupValidators[PERIOD])}
                        options={ageFormatData}
                        onChange={(e: any) => {
                          setValue(PERIOD, e)
                          trigger(PERIOD)
                        }}
                        maxMenuHeight={200}
                      />

                      {errors[PERIOD] && (
                        <p className={styles.errorText}>
                          {errors[PERIOD].message as any}
                        </p>
                      )}
                    </div>
                  </div>
                  <div className={styles.labelField}>
                    <label className={styles.labelText}>
                      {t('LabConfiguration.Age_From')}{' '}
                      <span className="asterick">*</span>
                    </label>
                    <div className={styles.fieldErrorContainer}>
                      <input
                        type="number"
                        className={styles.inputField}
                        placeholder={
                          t('LabConfiguration.Age_From_placeholder') ||
                          'Enter Age From'
                        }
                        {...register(AGE_FROM, rangePopupValidators[AGE_FROM])}
                        onChange={(e: any) => {
                          trimValue(e)
                          disableArrowKey(e)
                        }}
                        onKeyDown={(e: any) => {
                          disableArrowKey(e)
                          blockInvalidChar(e)
                        }}
                        onWheel={(e: any) => {
                          e.target.blur()
                        }}
                      />
                      {errors[AGE_FROM] && (
                        <p className={styles.errorText}>
                          {errors[AGE_FROM].message}
                        </p>
                      )}
                    </div>
                  </div>
                  <div className={styles.labelField}>
                    <label className={styles.labelText}>
                      {t('LabConfiguration.Age_To')}{' '}
                      <span className="asterick">*</span>
                    </label>
                    <div className={styles.fieldErrorContainer}>
                      <input
                        type="number"
                        className={styles.inputField}
                        placeholder={
                          t('LabConfiguration.Age_To_placeholder') ||
                          'Enter Age To'
                        }
                        {...register(AGE_TO, rangePopupValidators[AGE_TO])}
                        onChange={(e: any) => {
                          trimValue(e)
                          disableArrowKey(e)
                        }}
                        onWheel={(e: any) => {
                          e.target.blur()
                        }}
                        onKeyDown={(e: any) => {
                          disableArrowKey(e)
                          blockInvalidChar(e)
                        }}
                      />
                      {errors[AGE_TO] && (
                        <p className={styles.errorText}>
                          {errors[AGE_TO].message}
                        </p>
                      )}
                    </div>
                  </div>
                  <div className={styles.labelField}>
                    <label className={styles.labelText}>
                      {t('LabConfiguration.Range_From')}{' '}
                      <span className="asterick">*</span>
                    </label>
                    <div className={styles.fieldErrorContainer}>
                      <input
                        type="text"
                        className={styles.inputField}
                        placeholder={
                          t('LabConfiguration.Range_From_placeholder') ||
                          'Enter Range From'
                        }
                        {...register(
                          RANGE_FROM,
                          rangePopupValidators[RANGE_FROM]
                        )}
                        onChange={(e: any) => {
                          trimValue(e)
                          disableArrowKey(e)
                        }}
                        onKeyDown={(e: any) => {
                          disableArrowKey(e)
                          blockInvalidChar(e)
                        }}
                        onWheel={(e: any) => {
                          e.target.blur()
                        }}
                      />
                      {errors[RANGE_FROM] && (
                        <p className={styles.errorText}>
                          {errors[RANGE_FROM].message}
                        </p>
                      )}
                    </div>
                  </div>
                  <div className={styles.labelField}>
                    <label className={styles.labelText}>
                      {t('LabConfiguration.Range_To')}{' '}
                      <span className="asterick">*</span>
                    </label>
                    <div className={styles.fieldErrorContainer}>
                      <input
                        type="text"
                        className={styles.inputField}
                        placeholder={
                          t('LabConfiguration.Range_To_placeholder') ||
                          'Enter Range To'
                        }
                        {...register(RANGE_TO, rangePopupValidators[RANGE_TO])}
                        onChange={(e: any) => {
                          trimValue(e)
                          disableArrowKey(e)
                        }}
                        onWheel={(e: any) => {
                          e.target.blur()
                        }}
                        onKeyDown={(e: any) => {
                          disableArrowKey(e)
                          blockInvalidChar(e)
                        }}
                      />
                      {errors[RANGE_TO] && (
                        <p className={styles.errorText}>
                          {errors[RANGE_TO].message}
                        </p>
                      )}
                    </div>
                  </div>
                </div>
                <div className={styles.btnContainer}>
                  <Button title={t('InvoiceService.Add') || 'Add'} />
                </div>
              </form>
            )}
            <TableV2
              tableHeaderData={headerData}
              tableRowData={
                popData ? (popData === '1' ? [] : popData) : tableData
              }
              active={false}
            />
            {popData === undefined && popData !== '1' && (
              <div className={styles.btnContainer}>
                <Button
                  title={t('Common.Submit') || 'Submit'}
                  handleClick={() => {
                    handleSubmitData(tableData)
                    setModelOpenClose(false)
                    dispatch(getAllRangeData(tableData))
                  }}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  )
}

export default RangePopup
