import React from "react";
import styles from "./chatloader.module.scss"; // Define your CSS styles here

const ChatLoader = () => {
  return (
    


    <div className={styles.chatLoaderContainer}>
      {/* Add your loader content here */}
      {/* Loading chat messages... */}
    </div>
  );
};

export default ChatLoader;
