import {
    REPORT_START_DATE,
    REPORT_END_DATE,
    REPORT_BRANCH,
    REPORT_DOCTOR,
    REPORT_PATIENT,
} from '../../constants/reportBuilderConstant'

// export const dynamic_pipeline_for_invoice: any = [
//   {
//     base_table: "invoices",
//     column: [
//       "type",
//       "paid_amount",
//       "total_amount",
//       "outstanding_amount",
//       "refund_amount",
//       "generate_date",
//       "insurance_claim_amount",
//       "insurance_commission_amount",
//       "diagnosis_services",
//       "lab_tests",
//       "radiology_tests",
//       "pharmacy_services",
//       "invoice_no",
//       "dentaldiagnosis_detail",
//       "receipt_detail",
//       "diagnosis_detail",
//     ],
//     children: [
//       {
//         name: "receipts",
//         column: [
//           "type",
//           "outstanding_amount",
//           "advance_amount",
//           "patient_id",
//           "receipt_no",
//           "paid_amount",
//           "invoice_ids",
//           "invoice_crr_status",
//         ],
//         aggregator: (columns: any) => {
//           //   columns = { 1: 1, 2: 2 };
//           const jsonnew = [
//             {
//               $lookup: {
//                 from: "receipts",
//                 let: { ids: "$_id" },
//                 pipeline: [
//                   { $match: { $expr: { $in: ["$$ids", "$invoice_ids"] } } },
//                   {
//                     $project: columns,
//                   },
//                 ],
//                 as: "receipt_detail",
//               },
//             },
//           ];
//           return jsonnew;
//         },
//         ask_for_unwind: true,
//         ask_for_unwind_res: (res: boolean) => {
//           return `{$unwind:{path:"$receipt_detail",preserveNullAndEmptyArrays:${res}}}`;
//         },
//       },
//       {
//         name: "diagnoses",
//         column: [
//           "appointment_id",
//           "patient_id",
//           "doctor_id",
//           "diagnosis_description",
//           "invoice_status",
//           "medicine_prescription",
//           "main_complaint",
//         ],
//         aggregator: (columns: any) => {
//           let json: any = [
//             {
//               $lookup: {
//                 from: "diagnoses",
//                 localField: "diagnosis_id",
//                 foreignField: "_id",
//                 pipeline: [{ $project: columns }],
//                 as: "diagnosis_detail",
//               },
//             },
//             {
//               $unwind: {
//                 path: "$diagnosis_detail",
//                 preserveNullAndEmptyArrays: true,
//               },
//             },
//           ];
//           //          {
//           //
//           // }
//           return json;
//         },
//       },
//       {
//         name: "dentaldiagnosis",
//         column: [
//           "appointment_id",
//           "patient_id",
//           "doctor_id",
//           "diagnosis_description",
//           "invoice_status",
//           "medicine_prescription",
//           "main_complaint",
//         ],
//         aggregator: (columns: any) => {
//           let jsonFordenttal = [
//             {
//               $lookup: {
//                 from: "dentaldiagnosis",
//                 localField: "dental_diagnosis_id",
//                 foreignField: "_id",
//                 pipeline: [{ $project: columns }],
//                 as: "dentaldiagnosis_detail",
//               },
//             },
//             {
//               $unwind: {
//                 path: "$dentaldiagnosis_detail",
//                 preserveNullAndEmptyArrays: true,
//               },
//             },
//           ];

//           return jsonFordenttal;
//         },
//       },
//     ],
//     group_by: [
//       {
//         name: "default_doctor_id",
//         aggregator: `{ $group:{_id:"$default_doctor_id",data:{$push:"$$ROOT"}}}`,
//       },
//     ],
//   },
// ];

// export const dynamic_pipeline_for_invoice: any = [
//   {
//       "base_table": "invoices",
//       "column": [
//           "type",
//           "paid_amount",
//           "total_amount",
//           "outstanding_amount",
//           "refund_amount",
//           "generate_date",
//           "insurance_claim_amount",
//           "insurance_commission_amount",
//           "diagnosis_services",
//           "lab_tests",
//           "radiology_tests",
//           "pharmacy_services",
//           "invoice_no",
//           "dentaldiagnosis_detail",
//           "receipt_detail",
//           "diagnosis_detail",
//           "transaction_detail"
//       ],
//       "children": [
//           {
//               "name": "transactions",
//               "column": [
//                   "payment_mode",
//                   "amount",
//                   "status"
//               ],
//               "aggregator": [
//                   {
//                       "$lookup": {
//                           "from": "transactions",
//                           "let": {
//                               "ids": "$_id"
//                           },
//                           "pipeline": [
//                               {
//                                   "$match": {
//                                       "$expr": {
//                                           "$eq": [
//                                               "$$ids",
//                                               "$receipt_id"
//                                           ]
//                                       }
//                                   }
//                               }
//                           ],
//                           "as": "transaction_detail"
//                       }
//                   }
//               ]
//           },
//           {
//               "name": "receipts",
//               "column": [
//                   "type",
//                   "outstanding_amount",
//                   "advance_amount",
//                   "patient_id",
//                   "receipt_no",
//                   "paid_amount",
//                   "invoice_ids",
//                   "invoice_crr_status",
//                   "transaction_detail"
//               ],
//               "children": [
//                   {
//                       "name": "transactions",
//                       "column": [
//                           "payment_mode",
//                           "amount",
//                           "status"
//                       ],
//                       "aggregator": [
//                           {
//                               "$lookup": {
//                                   "from": "transactions",
//                                   "let": {
//                                       "ids": "$_id"
//                                   },
//                                   "pipeline": [
//                                       {
//                                           "$match": {
//                                               "$expr": {
//                                                   "$eq": [
//                                                       "$$ids",
//                                                       "$receipt_id"
//                                                   ]
//                                               }
//                                           }
//                                       }
//                                   ],
//                                   "as": "transaction_detail"
//                               }
//                           },
//                           {
//                               "$unwind": {
//                                   "path": "$transaction_detail",
//                                   "preserveNullAndEmptyArrays": false
//                               }
//                           }
//                       ]
//                   }
//               ],
//               "aggregator": [
//                   {
//                       "$lookup": {
//                           "from": "receipts",
//                           "let": {
//                               "ids": "$_id"
//                           },
//                           "pipeline": [
//                               {
//                                   "$match": {
//                                       "$expr": {
//                                           "$in": [
//                                               "$$ids",
//                                               "$invoice_ids"
//                                           ]
//                                       }
//                                   }
//                               }
//                           ],
//                           "as": "receipt_detail"
//                       }
//                   }
//               ],
//               "ask_for_unwind": true
//           },
//           {
//               "name": "diagnoses",
//               "column": [
//                   "appointment_id",
//                   "patient_id",
//                   "doctor_id",
//                   "diagnosis_description",
//                   "invoice_status",
//                   "medicine_prescription",
//                   "main_complaint",
//                   "patient_name",
//                   "appointment_detail"
//               ],
//               "children": [
//                   {
//                       "name": "appointments",
//                       "column": [
//                           "appointment_mode_id",
//                           "patient_id",
//                           "doctor_id",
//                           "phone",
//                           "appointment_date",
//                           "appointment_time",
//                           "appointment_duration",
//                           "gender",
//                           "problem_description",
//                           "appointment_type",
//                           "status"
//                       ],
//                       "aggregator": [
//                           {
//                               "$lookup": {
//                                   "from": "appointments",
//                                   "localField": "appointment_id",
//                                   "foreignField": "_id",
//                                   "pipeline": [],
//                                   "as": "appointment_detail"
//                               }
//                           },
//                           {
//                               "$unwind": {
//                                   "path": "$appointment_detail",
//                                   "preserveNullAndEmptyArrays": true
//                               }
//                           }
//                       ]
//                   }
//               ],
//               "aggregator": [
//                   {
//                       "$lookup": {
//                           "from": "diagnoses",
//                           "localField": "diagnosis_id",
//                           "foreignField": "_id",
//                           "pipeline": [],
//                           "as": "diagnosis_detail"
//                       }
//                   },
//                   {
//                       "$unwind": {
//                           "path": "$diagnosis_detail",
//                           "preserveNullAndEmptyArrays": true
//                       }
//                   }
//               ]
//           },
//           {
//               "name": "dentaldiagnosis",
//               "column": [
//                   "appointment_id",
//                   "patient_id",
//                   "doctor_id",
//                   "diagnosis_description",
//                   "invoice_status",
//                   "medicine_prescription",
//                   "main_complaint",
//                   "patient_name",
//                   "appointment_detail"
//               ],
//               "children": [
//                   {
//                       "name": "appointments",
//                       "column": [
//                           "appointment_mode_id",
//                           "patient_id",
//                           "doctor_id",
//                           "phone",
//                           "appointment_date",
//                           "appointment_time",
//                           "appointment_duration",
//                           "gender",
//                           "problem_description",
//                           "appointment_type",
//                           "status"
//                       ],
//                       "aggregator": [
//                           {
//                               "$lookup": {
//                                   "from": "appointments",
//                                   "localField": "appointment_id",
//                                   "foreignField": "_id",
//                                   "pipeline": [],
//                                   "as": "appointment_detail"
//                               }
//                           },
//                           {
//                               "$unwind": {
//                                   "path": "$appointment_detail",
//                                   "preserveNullAndEmptyArrays": true
//                               }
//                           }
//                       ]
//                   }
//               ],
//               "aggregator": [
//                   {
//                       "$lookup": {
//                           "from": "dentaldiagnosis",
//                           "localField": "dental_diagnosis_id",
//                           "foreignField": "_id",
//                           "pipeline": [],
//                           "as": "dentaldiagnosis_detail"
//                       }
//                   },
//                   {
//                       "$unwind": {
//                           "path": "$dentaldiagnosis_detail",
//                           "preserveNullAndEmptyArrays": true
//                       }
//                   }
//               ]
//           },
//           {
//               "name": "patients",
//               "column": [
//                   "user_id",
//                   "emr_no",
//                   "nationality",
//                   "allergies",
//                   "advance_amount"
//               ],
//               "children": [
//                   {
//                       "name": "user",
//                       "column": [
//                           "name",
//                           "gender",
//                           "dob",
//                           "is_email_verified",
//                           "is_phone_verified",
//                           "national_id",
//                           "national_id_type",
//                           "user_no"
//                       ],
//                       "aggregator": [
//                           {
//                               "$lookup": {
//                                   "from": "users",
//                                   "localField": "user_id",
//                                   "foreignField": "_id",
//                                   "as": "user_detail",
//                                   "pipeline": []
//                               }
//                           },
//                           {
//                               "$unwind": {
//                                   "path": "$user_detail",
//                                   "preserveNullAndEmptyArrays": true
//                               }
//                           }
//                       ]
//                   }
//               ],
//               "aggregator": [
//                   {
//                       "$lookup": {
//                           "from": "patients",
//                           "let": {
//                               "patient_id": "$patient_id"
//                           },
//                           "pipeline": [
//                               {
//                                   "$match": {
//                                       "$expr": {
//                                           "$eq": [
//                                               "$_id",
//                                               "$$patient_id"
//                                           ]
//                                       }
//                                   }
//                               }
//                           ],
//                           "as": "patient_detail"
//                       }
//                   },
//                   {
//                       "$unwind": {
//                           "path": "$patient_detail"
//                       }
//                   }
//               ]
//           }
//       ]
//   },
//   {
//       "base_table": "appointments",
//       "column": [
//           "patient_id",
//           "doctor_id",
//           "branch_id",
//           "appointment_mode_id",
//           "diagnosis_id",
//           "appointment_type",
//           "phone",
//           "patient_name",
//           "appointment_timestamp",
//           "appointment_date",
//           "appointment_time",
//           "appointment_duration",
//           "gender",
//           "problem_description",
//           "status",
//           "patient_booked",
//           "patient_status",
//           "insurance",
//           "prescription",
//           "invoice",
//           "cancelled_by",
//           "booked_by",
//           "diagnosis_detail"
//       ],
//       "children": [
//           {
//               "name": "diagnoses",
//               "column": [
//                   "appointment_id",
//                   "patient_id",
//                   "doctor_id",
//                   "diagnosis_description",
//                   "invoice_status",
//                   "medicine_prescription",
//                   "main_complaint",
//                   "invoices_detail"
//               ],
//               "selected": {
//                   "appointment_id": 1,
//                   "invoices_detail": 1
//               },
//               "children": [
//                   {
//                       "name": "invoices",
//                       "column": [
//                           "type",
//                           "paid_amount",
//                           "total_amount",
//                           "outstanding_amount",
//                           "refund_amount",
//                           "generate_date",
//                           "insurance_claim_amount",
//                           "insurance_commission_amount",
//                           "diagnosis_services",
//                           "invoice_no",
//                           "receipt_detail"
//                       ],
//                       "children": [
//                           {
//                               "name": "receipts",
//                               "column": [
//                                   "type",
//                                   "outstanding_amount",
//                                   "advance_amount",
//                                   "patient_id",
//                                   "receipt_no",
//                                   "paid_amount",
//                                   "invoice_ids",
//                                   "invoice_crr_status"
//                               ],
//                               "aggregator": [
//                                   {
//                                       "$lookup": {
//                                           "from": "receipts",
//                                           "let": {
//                                               "ids": "$_id"
//                                           },
//                                           "pipeline": [
//                                               {
//                                                   "$match": {
//                                                       "$expr": {
//                                                           "$in": [
//                                                               "$$ids",
//                                                               "$invoice_ids"
//                                                           ]
//                                                       }
//                                                   }
//                                               }
//                                           ],
//                                           "as": "receipt_detail"
//                                       }
//                                   },
//                                   {
//                                       "$unwind": {
//                                           "path": "$receipt_detail",
//                                           "preserveNullAndEmptyArrays": true
//                                       }
//                                   }
//                               ]
//                           }
//                       ],
//                       "aggregator": [
//                           {
//                               "$lookup": {
//                                   "from": "invoices",
//                                   "localField": "_id",
//                                   "foreignField": "diagnosis_id",
//                                   "pipeline": [],
//                                   "as": "invoices_detail"
//                               }
//                           },
//                           {
//                               "$unwind": {
//                                   "path": "$invoices_detail",
//                                   "preserveNullAndEmptyArrays": true
//                               }
//                           }
//                       ]
//                   }
//               ],
//               "aggregator": [
//                   {
//                       "$lookup": {
//                           "from": "diagnoses",
//                           "localField": "diagnosis_id",
//                           "foreignField": "_id",
//                           "pipeline": [],
//                           "as": "diagnosis_detail"
//                       }
//                   },
//                   {
//                       "$unwind": {
//                           "path": "$diagnosis_detail",
//                           "preserveNullAndEmptyArrays": true
//                       }
//                   }
//               ]
//           }
//       ]
//   }
// ]

// export const dynamic_pipeline_for_invoice: any = [
//     {
//         "base_table": "invoices",
//         "column": [
//             "type",
//             "paid_amount",
//             "total_amount",
//             "outstanding_amount",
//             "refund_amount",
//             "generate_date",
//             "insurance_claim_amount",
//             "insurance_commission_amount",
//             "diagnosis_services",
//             "lab_tests",
//             "radiology_tests",
//             "pharmacy_services",
//             "invoice_no",
//             "dentaldiagnosis_detail",
//             "receipt_detail",
//             "diagnosis_detail",
//             "transaction_detail"
//         ],
//         "children": [
//             {
//                 "name": "transactions",
//                 "column": [
//                     "payment_mode",
//                     "amount",
//                     "status"
//                 ],
//                 "aggregator": [
//                     {
//                         "$lookup": {
//                             "from": "transactions",
//                             "let": {
//                                 "ids": "$_id"
//                             },
//                             "pipeline": [
//                                 {
//                                     "$match": {
//                                         "$expr": {
//                                             "$eq": [
//                                                 "$$ids",
//                                                 "$receipt_id"
//                                             ]
//                                         }
//                                     }
//                                 }
//                             ],
//                             "as": "transaction_detail"
//                         }
//                     }
//                 ]
//             },
//             {
//                 "name": "receipts",
//                 "column": [
//                     "type",
//                     "outstanding_amount",
//                     "advance_amount",
//                     "patient_id",
//                     "receipt_no",
//                     "paid_amount",
//                     "invoice_ids",
//                     "invoice_crr_status",
//                     "transaction_detail"
//                 ],
//                 "children": [
//                     {
//                         "name": "transactions",
//                         "column": [
//                             "payment_mode",
//                             "amount",
//                             "status"
//                         ],
//                         "aggregator": [
//                             {
//                                 "$lookup": {
//                                     "from": "transactions",
//                                     "let": {
//                                         "ids": "$_id"
//                                     },
//                                     "pipeline": [
//                                         {
//                                             "$match": {
//                                                 "$expr": {
//                                                     "$eq": [
//                                                         "$$ids",
//                                                         "$receipt_id"
//                                                     ]
//                                                 }
//                                             }
//                                         }
//                                     ],
//                                     "as": "transaction_detail"
//                                 }
//                             },
//                             {
//                                 "$unwind": {
//                                     "path": "$transaction_detail",
//                                     "preserveNullAndEmptyArrays": false
//                                 }
//                             }
//                         ],
//                         "children":[
//                             {
//                                 "name": "debit",
//                                 "column": [
//                                     "payment_mode",
//                                     "amount",
//                                     "status"
//                                 ],
//                                 "aggregator": [
//                                     {
//                                         "$lookup": {
//                                             "from": "transactions",
//                                             "let": {
//                                                 "ids": "$_id"
//                                             },
//                                             "pipeline": [
//                                                 {
//                                                     "$match": {
//                                                         "$expr": {
//                                                             "$eq": [
//                                                                 "$$ids",
//                                                                 "$receipt_id"
//                                                             ]
//                                                         }
//                                                     }
//                                                 }
//                                             ],
//                                             "as": "transaction_detail"
//                                         }
//                                     },
//                                     {
//                                         "$unwind": {
//                                             "path": "$transaction_detail",
//                                             "preserveNullAndEmptyArrays": false
//                                         }
//                                     }
//                                 ]
//                             },
//                             {
//                                 "name": "credit",
//                                 "column": [
//                                     "payment_mode",
//                                     "amount",
//                                     "status"
//                                 ],
//                                 "aggregator": [
//                                     {
//                                         "$lookup": {
//                                             "from": "transactions",
//                                             "let": {
//                                                 "ids": "$_id"
//                                             },
//                                             "pipeline": [
//                                                 {
//                                                     "$match": {
//                                                         "$expr": {
//                                                             "$eq": [
//                                                                 "$$ids",
//                                                                 "$receipt_id"
//                                                             ]
//                                                         }
//                                                     }
//                                                 }
//                                             ],
//                                             "as": "transaction_detail"
//                                         }
//                                     },
//                                     {
//                                         "$unwind": {
//                                             "path": "$transaction_detail",
//                                             "preserveNullAndEmptyArrays": false
//                                         }
//                                     }
//                                 ]
//                             }
//                         ]
//                     }
//                 ],
//                 "aggregator": [
//                     {
//                         "$lookup": {
//                             "from": "receipts",
//                             "let": {
//                                 "ids": "$_id"
//                             },
//                             "pipeline": [
//                                 {
//                                     "$match": {
//                                         "$expr": {
//                                             "$in": [
//                                                 "$$ids",
//                                                 "$invoice_ids"
//                                             ]
//                                         }
//                                     }
//                                 }
//                             ],
//                             "as": "receipt_detail"
//                         }
//                     }
//                 ],
//                 "ask_for_unwind": true
//             },
//             {
//                 "name": "diagnoses",
//                 "column": [
//                     "appointment_id",
//                     "patient_id",
//                     "doctor_id",
//                     "diagnosis_description",
//                     "invoice_status",
//                     "medicine_prescription",
//                     "main_complaint",
//                     "patient_name",
//                     "appointment_detail"
//                 ],
//                 "children": [
//                     {
//                         "name": "appointments",
//                         "column": [
//                             "appointment_mode_id",
//                             "patient_id",
//                             "doctor_id",
//                             "phone",
//                             "appointment_date",
//                             "appointment_time",
//                             "appointment_duration",
//                             "gender",
//                             "problem_description",
//                             "appointment_type",
//                             "status"
//                         ],
//                         "aggregator": [
//                             {
//                                 "$lookup": {
//                                     "from": "appointments",
//                                     "localField": "appointment_id",
//                                     "foreignField": "_id",
//                                     "pipeline": [],
//                                     "as": "appointment_detail"
//                                 }
//                             },
//                             {
//                                 "$unwind": {
//                                     "path": "$appointment_detail",
//                                     "preserveNullAndEmptyArrays": true
//                                 }
//                             }
//                         ],
//                         "children": [
//                             {
//                                 "name": "Outstanding",
//                                 "column": [
//                                     "appointment_mode_id",
//                                     "patient_id",
//                                     "doctor_id",
//                                     "phone",
//                                     "appointment_date",
//                                     "appointment_time",
//                                     "appointment_duration",
//                                     "gender",
//                                     "problem_description",
//                                     "appointment_type",
//                                     "status"
//                                 ],
//                                 "aggregator": [
//                                     {
//                                         "$lookup": {
//                                             "from": "appointments",
//                                             "localField": "appointment_id",
//                                             "foreignField": "_id",
//                                             "pipeline": [],
//                                             "as": "appointment_detail"
//                                         }
//                                     },
//                                     {
//                                         "$unwind": {
//                                             "path": "$appointment_detail",
//                                             "preserveNullAndEmptyArrays": true
//                                         }
//                                     }
//                                 ]
//                             },
//                             {
//                                 "name": "Advance",
//                                 "column": [
//                                     "appointment_mode_id",
//                                     "patient_id",
//                                     "doctor_id",
//                                     "phone",
//                                     "appointment_date",
//                                     "appointment_time",
//                                     "appointment_duration",
//                                     "gender",
//                                     "problem_description",
//                                     "appointment_type",
//                                     "status"
//                                 ],
//                                 "aggregator": [
//                                     {
//                                         "$lookup": {
//                                             "from": "appointments",
//                                             "localField": "appointment_id",
//                                             "foreignField": "_id",
//                                             "pipeline": [],
//                                             "as": "appointment_detail"
//                                         }
//                                     },
//                                     {
//                                         "$unwind": {
//                                             "path": "$appointment_detail",
//                                             "preserveNullAndEmptyArrays": true
//                                         }
//                                     }
//                                 ]
//                             },
//                             {
//                                 "name": "Refund",
//                                 "column": [
//                                     "appointment_mode_id",
//                                     "patient_id",
//                                     "doctor_id",
//                                     "phone",
//                                     "appointment_date",
//                                     "appointment_time",
//                                     "appointment_duration",
//                                     "gender",
//                                     "problem_description",
//                                     "appointment_type",
//                                     "status"
//                                 ],
//                                 "aggregator": [
//                                     {
//                                         "$lookup": {
//                                             "from": "appointments",
//                                             "localField": "appointment_id",
//                                             "foreignField": "_id",
//                                             "pipeline": [],
//                                             "as": "appointment_detail"
//                                         }
//                                     },
//                                     {
//                                         "$unwind": {
//                                             "path": "$appointment_detail",
//                                             "preserveNullAndEmptyArrays": true
//                                         }
//                                     }
//                                 ]
//                             }
//                         ]
//                     }
//                 ],
//                 "aggregator": [
//                     {
//                         "$lookup": {
//                             "from": "diagnoses",
//                             "localField": "diagnosis_id",
//                             "foreignField": "_id",
//                             "pipeline": [],
//                             "as": "diagnosis_detail"
//                         }
//                     },
//                     {
//                         "$unwind": {
//                             "path": "$diagnosis_detail",
//                             "preserveNullAndEmptyArrays": true
//                         }
//                     }
//                 ]
//             },
//             {
//                 "name": "dentaldiagnosis",
//                 "column": [
//                     "appointment_id",
//                     "patient_id",
//                     "doctor_id",
//                     "diagnosis_description",
//                     "invoice_status",
//                     "medicine_prescription",
//                     "main_complaint",
//                     "patient_name",
//                     "appointment_detail"
//                 ],
//                 "children": [
//                     {
//                         "name": "appointments",
//                         "column": [
//                             "appointment_mode_id",
//                             "patient_id",
//                             "doctor_id",
//                             "phone",
//                             "appointment_date",
//                             "appointment_time",
//                             "appointment_duration",
//                             "gender",
//                             "problem_description",
//                             "appointment_type",
//                             "status"
//                         ],
//                         "aggregator": [
//                             {
//                                 "$lookup": {
//                                     "from": "appointments",
//                                     "localField": "appointment_id",
//                                     "foreignField": "_id",
//                                     "pipeline": [],
//                                     "as": "appointment_detail"
//                                 }
//                             },
//                             {
//                                 "$unwind": {
//                                     "path": "$appointment_detail",
//                                     "preserveNullAndEmptyArrays": true
//                                 }
//                             }
//                         ]
//                     }
//                 ],
//                 "aggregator": [
//                     {
//                         "$lookup": {
//                             "from": "dentaldiagnosis",
//                             "localField": "dental_diagnosis_id",
//                             "foreignField": "_id",
//                             "pipeline": [],
//                             "as": "dentaldiagnosis_detail"
//                         }
//                     },
//                     {
//                         "$unwind": {
//                             "path": "$dentaldiagnosis_detail",
//                             "preserveNullAndEmptyArrays": true
//                         }
//                     }
//                 ]
//             },
//             {
//                 "name": "patients",
//                 "column": [
//                     "user_id",
//                     "emr_no",
//                     "nationality",
//                     "allergies",
//                     "advance_amount"
//                 ],
//                 "children": [
//                     {
//                         "name": "user",
//                         "column": [
//                             "name",
//                             "gender",
//                             "dob",
//                             "is_email_verified",
//                             "is_phone_verified",
//                             "national_id",
//                             "national_id_type",
//                             "user_no"
//                         ],
//                         "aggregator": [
//                             {
//                                 "$lookup": {
//                                     "from": "users",
//                                     "localField": "user_id",
//                                     "foreignField": "_id",
//                                     "as": "user_detail",
//                                     "pipeline": []
//                                 }
//                             },
//                             {
//                                 "$unwind": {
//                                     "path": "$user_detail",
//                                     "preserveNullAndEmptyArrays": true
//                                 }
//                             }
//                         ]
//                     }
//                 ],
//                 "aggregator": [
//                     {
//                         "$lookup": {
//                             "from": "patients",
//                             "let": {
//                                 "patient_id": "$patient_id"
//                             },
//                             "pipeline": [
//                                 {
//                                     "$match": {
//                                         "$expr": {
//                                             "$eq": [
//                                                 "$_id",
//                                                 "$$patient_id"
//                                             ]
//                                         }
//                                     }
//                                 }
//                             ],
//                             "as": "patient_detail"
//                         }
//                     },
//                     {
//                         "$unwind": {
//                             "path": "$patient_detail"
//                         }
//                     }
//                 ]
//             }
//         ]
//     },
//     {
//         "base_table": "appointments",
//         "column": [
//             "patient_id",
//             "doctor_id",
//             "branch_id",
//             "appointment_mode_id",
//             "diagnosis_id",
//             "appointment_type",
//             "phone",
//             "patient_name",
//             "appointment_timestamp",
//             "appointment_date",
//             "appointment_time",
//             "appointment_duration",
//             "gender",
//             "problem_description",
//             "status",
//             "patient_booked",
//             "patient_status",
//             "insurance",
//             "prescription",
//             "invoice",
//             "cancelled_by",
//             "booked_by",
//             "diagnosis_detail"
//         ],
//         "children": [
//             {
//                 "name": "diagnoses",
//                 "column": [
//                     "appointment_id",
//                     "patient_id",
//                     "doctor_id",
//                     "diagnosis_description",
//                     "invoice_status",
//                     "medicine_prescription",
//                     "main_complaint",
//                     "invoices_detail"
//                 ],
//                 "selected": {
//                     "appointment_id": 1,
//                     "invoices_detail": 1
//                 },
//                 "children": [
//                     {
//                         "name": "invoices",
//                         "column": [
//                             "type",
//                             "paid_amount",
//                             "total_amount",
//                             "outstanding_amount",
//                             "refund_amount",
//                             "generate_date",
//                             "insurance_claim_amount",
//                             "insurance_commission_amount",
//                             "diagnosis_services",
//                             "invoice_no",
//                             "receipt_detail"
//                         ],
//                         "children": [
//                             {
//                                 "name": "receipts",
//                                 "column": [
//                                     "type",
//                                     "outstanding_amount",
//                                     "advance_amount",
//                                     "patient_id",
//                                     "receipt_no",
//                                     "paid_amount",
//                                     "invoice_ids",
//                                     "invoice_crr_status"
//                                 ],
//                                 "aggregator": [
//                                     {
//                                         "$lookup": {
//                                             "from": "receipts",
//                                             "let": {
//                                                 "ids": "$_id"
//                                             },
//                                             "pipeline": [
//                                                 {
//                                                     "$match": {
//                                                         "$expr": {
//                                                             "$in": [
//                                                                 "$$ids",
//                                                                 "$invoice_ids"
//                                                             ]
//                                                         }
//                                                     }
//                                                 }
//                                             ],
//                                             "as": "receipt_detail"
//                                         }
//                                     },
//                                     {
//                                         "$unwind": {
//                                             "path": "$receipt_detail",
//                                             "preserveNullAndEmptyArrays": true
//                                         }
//                                     }
//                                 ]
//                             }
//                         ],
//                         "aggregator": [
//                             {
//                                 "$lookup": {
//                                     "from": "invoices",
//                                     "localField": "_id",
//                                     "foreignField": "diagnosis_id",
//                                     "pipeline": [],
//                                     "as": "invoices_detail"
//                                 }
//                             },
//                             {
//                                 "$unwind": {
//                                     "path": "$invoices_detail",
//                                     "preserveNullAndEmptyArrays": true
//                                 }
//                             }
//                         ]
//                     }
//                 ],
//                 "aggregator": [
//                     {
//                         "$lookup": {
//                             "from": "diagnoses",
//                             "localField": "diagnosis_id",
//                             "foreignField": "_id",
//                             "pipeline": [],
//                             "as": "diagnosis_detail"
//                         }
//                     },
//                     {
//                         "$unwind": {
//                             "path": "$diagnosis_detail",
//                             "preserveNullAndEmptyArrays": true
//                         }
//                     }
//                 ]
//             }
//         ]
//     }
//   ]

export const dynamic_pipeline_for_invoice: any =[
    {
        "base_table": "invoices",
        "column": [
            "type",
            "paid_amount",
            "total_amount",
            "outstanding_amount",
            "refund_amount",
            "generate_date",
            "insurance_claim_amount",
            "insurance_commission_amount",
            "diagnosis_services",
            "lab_tests",
            "radiology_tests",
            "pharmacy_services",
            "invoice_no",
            "dentaldiagnosis_detail",
            "receipt_detail",
            "diagnosis_detail",
            "transaction_detail"
        ],
        "children": [
            {
                "name": "transactions",
                "_parent": "invoices",
                "column": [
                    "payment_mode",
                    "amount",
                    "status"
                ],
                "aggregator": [
                    {
                        "$lookup": {
                            "from": "transactions",
                            "let": {
                                "ids": "$_id"
                            },
                            "pipeline": [
                                {
                                    "$match": {
                                        "$expr": {
                                            "$eq": [
                                                "$$ids",
                                                "$receipt_id"
                                            ]
                                        }
                                    }
                                }
                            ],
                            "as": "transaction_detail"
                        }
                    }
                ]
            },
            {
                "name": "receipts",
                "_parent": "invoices",
                "column": [
                    "type",
                    "outstanding_amount",
                    "advance_amount",
                    "patient_id",
                    "receipt_no",
                    "paid_amount",
                    "invoice_ids",
                    "invoice_crr_status",
                    "transaction_detail"
                ],
                "children": [
                    {
                        "name": "transactions",
                        "_parent": "receipts",
                        "column": [
                            "payment_mode",
                            "amount",
                            "status"
                        ],
                        "aggregator": [
                            {
                                "$lookup": {
                                    "from": "transactions",
                                    "let": {
                                        "ids": "$_id"
                                    },
                                    "pipeline": [
                                        {
                                            "$match": {
                                                "$expr": {
                                                    "$eq": [
                                                        "$$ids",
                                                        "$receipt_id"
                                                    ]
                                                }
                                            }
                                        }
                                    ],
                                    "as": "transaction_detail"
                                }
                            },
                            {
                                "$unwind": {
                                    "path": "$transaction_detail",
                                    "preserveNullAndEmptyArrays": false
                                }
                            }
                        ]
                    }
                ],
                "aggregator": [
                    {
                        "$lookup": {
                            "from": "receipts",
                            "let": {
                                "ids": "$_id"
                            },
                            "pipeline": [
                                {
                                    "$match": {
                                        "$expr": {
                                            "$in": [
                                                "$$ids",
                                                "$invoice_ids"
                                            ]
                                        }
                                    }
                                }
                            ],
                            "as": "receipt_detail"
                        }
                    }
                ],
                "ask_for_unwind": true
            },
            {
                "name": "diagnoses",
                "_parent": "invoices",
                "column": [
                    "appointment_id",
                    "patient_id",
                    "doctor_id",
                    "diagnosis_description",
                    "invoice_status",
                    "medicine_prescription",
                    "main_complaint",
                    "patient_name",
                    "appointment_detail"
                ],
                "children": [
                    {
                        "name": "appointments",
                        "_parent": "diagnoses",
                        "column": [
                            "appointment_mode_id",
                            "patient_id",
                            "doctor_id",
                            "phone",
                            "appointment_date",
                            "appointment_time",
                            "appointment_duration",
                            "gender",
                            "problem_description",
                            "appointment_type",
                            "status"
                        ],
                        "aggregator": [
                            {
                                "$lookup": {
                                    "from": "appointments",
                                    "localField": "appointment_id",
                                    "foreignField": "_id",
                                    "pipeline": [],
                                    "as": "appointment_detail"
                                }
                            },
                            {
                                "$unwind": {
                                    "path": "$appointment_detail",
                                    "preserveNullAndEmptyArrays": true
                                }
                            }
                        ]
                    }
                ],
                "aggregator": [
                    {
                        "$lookup": {
                            "from": "diagnoses",
                            "localField": "diagnosis_id",
                            "foreignField": "_id",
                            "pipeline": [],
                            "as": "diagnosis_detail"
                        }
                    },
                    {
                        "$unwind": {
                            "path": "$diagnosis_detail",
                            "preserveNullAndEmptyArrays": true
                        }
                    }
                ]
            },
            {
                "name": "dentaldiagnosis",
                "_parent": "invoices",
                "column": [
                    "appointment_id",
                    "patient_id",
                    "doctor_id",
                    "diagnosis_description",
                    "invoice_status",
                    "medicine_prescription",
                    "main_complaint",
                    "patient_name",
                    "appointment_detail"
                ],
                "children": [
                    {
                        "name": "appointments",
                        "_parent": "dentaldiagnosis",
                        "column": [
                            "appointment_mode_id",
                            "patient_id",
                            "doctor_id",
                            "phone",
                            "appointment_date",
                            "appointment_time",
                            "appointment_duration",
                            "gender",
                            "problem_description",
                            "appointment_type",
                            "status"
                        ],
                        "aggregator": [
                            {
                                "$lookup": {
                                    "from": "appointments",
                                    "localField": "appointment_id",
                                    "foreignField": "_id",
                                    "pipeline": [],
                                    "as": "appointment_detail"
                                }
                            },
                            {
                                "$unwind": {
                                    "path": "$appointment_detail",
                                    "preserveNullAndEmptyArrays": true
                                }
                            }
                        ]
                    }
                ],
                "aggregator": [
                    {
                        "$lookup": {
                            "from": "dentaldiagnosis",
                            "localField": "dental_diagnosis_id",
                            "foreignField": "_id",
                            "pipeline": [],
                            "as": "dentaldiagnosis_detail"
                        }
                    },
                    {
                        "$unwind": {
                            "path": "$dentaldiagnosis_detail",
                            "preserveNullAndEmptyArrays": true
                        }
                    }
                ]
            },
            {
                "name": "patients",
                "_parent": "invoices",
                "column": [
                    "user_id",
                    "emr_no",
                    "nationality",
                    "allergies",
                    "advance_amount"
                ],
                "children": [
                    {
                        "name": "user",
                        "_parent":"patients",
                        "column": [
                            "name",
                            "gender",
                            "dob",
                            "is_email_verified",
                            "is_phone_verified",
                            "national_id",
                            "national_id_type",
                            "user_no"
                        ],
                        "aggregator": [
                            {
                                "$lookup": {
                                    "from": "users",
                                    "localField": "user_id",
                                    "foreignField": "_id",
                                    "as": "user_detail",
                                    "pipeline": []
                                }
                            },
                            {
                                "$unwind": {
                                    "path": "$user_detail",
                                    "preserveNullAndEmptyArrays": true
                                }
                            }
                        ]
                    }
                ],
                "aggregator": [
                    {
                        "$lookup": {
                            "from": "patients",
                            "let": {
                                "patient_id": "$patient_id"
                            },
                            "pipeline": [
                                {
                                    "$match": {
                                        "$expr": {
                                            "$eq": [
                                                "$_id",
                                                "$$patient_id"
                                            ]
                                        }
                                    }
                                }
                            ],
                            "as": "patient_detail"
                        }
                    },
                    {
                        "$unwind": {
                            "path": "$patient_detail"
                        }
                    }
                ]
            }
        ]
    },
    {
        "base_table": "appointments",
        "column": [
            "patient_id",
            "doctor_id",
            "branch_id",
            "appointment_mode_id",
            "diagnosis_id",
            "appointment_type",
            "phone",
            "patient_name",
            "appointment_timestamp",
            "appointment_date",
            "appointment_time",
            "appointment_duration",
            "gender",
            "problem_description",
            "status",
            "patient_booked",
            "patient_status",
            "insurance",
            "prescription",
            "invoice",
            "cancelled_by",
            "booked_by",
            "diagnosis_detail"
        ],
        "children": [
            {
                "name": "diagnoses",
                "_parent": "appointments",
                "column": [
                    "appointment_id",
                    "patient_id",
                    "doctor_id",
                    "diagnosis_description",
                    "invoice_status",
                    "medicine_prescription",
                    "main_complaint",
                    "invoices_detail"
                ],
                "selected": {
                    "appointment_id": 1,
                    "invoices_detail": 1
                },
                "children": [
                    {
                        "name": "invoices",
                        "_parent": "diagnoses",
                        "column": [
                            "type",
                            "paid_amount",
                            "total_amount",
                            "outstanding_amount",
                            "refund_amount",
                            "generate_date",
                            "insurance_claim_amount",
                            "insurance_commission_amount",
                            "diagnosis_services",
                            "invoice_no",
                            "receipt_detail"
                        ],
                        "children": [
                            {
                                "name": "receipts",
                                "_parent": "invoices",
                                "column": [
                                    "type",
                                    "outstanding_amount",
                                    "advance_amount",
                                    "patient_id",
                                    "receipt_no",
                                    "paid_amount",
                                    "invoice_ids",
                                    "invoice_crr_status"
                                ],
                                "aggregator": [
                                    {
                                        "$lookup": {
                                            "from": "receipts",
                                            "let": {
                                                "ids": "$_id"
                                            },
                                            "pipeline": [
                                                {
                                                    "$match": {
                                                        "$expr": {
                                                            "$in": [
                                                                "$$ids",
                                                                "$invoice_ids"
                                                            ]
                                                        }
                                                    }
                                                }
                                            ],
                                            "as": "receipt_detail"
                                        }
                                    },
                                    {
                                        "$unwind": {
                                            "path": "$receipt_detail",
                                            "preserveNullAndEmptyArrays": true
                                        }
                                    }
                                ]
                            }
                        ],
                        "aggregator": [
                            {
                                "$lookup": {
                                    "from": "invoices",
                                    "localField": "_id",
                                    "foreignField": "diagnosis_id",
                                    "pipeline": [],
                                    "as": "invoices_detail"
                                }
                            },
                            {
                                "$unwind": {
                                    "path": "$invoices_detail",
                                    "preserveNullAndEmptyArrays": true
                                }
                            }
                        ]
                    }
                ],
                "aggregator": [
                    {
                        "$lookup": {
                            "from": "diagnoses",
                            "localField": "diagnosis_id",
                            "foreignField": "_id",
                            "pipeline": [],
                            "as": "diagnosis_detail"
                        }
                    },
                    {
                        "$unwind": {
                            "path": "$diagnosis_detail",
                            "preserveNullAndEmptyArrays": true
                        }
                    }
                ]
            }
        ]
    }
]

export const tableData:any = [{
    "_id": "659279ee3ac18967fc534e87",
    "type": "DIAGNOSIS",
    "paid_amount": 0,
    "total_amount": 0,
    "outstanding_amount": 0
},
{
    "_id": "65927c813d9440efee35002a",
    "type": "DIAGNOSIS",
    "paid_amount": 0,
    "total_amount": 5120,
    "outstanding_amount": 5120
},
{
    "_id": "65927cf63d9440efee350050",
    "type": "DIAGNOSIS",
    "paid_amount": 10,
    "total_amount": 5040,
    "outstanding_amount": 5030
}] 

export const branchList:any = [
    {
        label:'Alkapuri',
        value:"645343c431c9547f4b0344cc"
    },
    {
        label : "Gotri",
        value : "6453436f31c9547f4b0344c0",
    },
    {
        label : "Nizampura",
        value : "6482b4e2d4e0147f4c519869",
    },
    {
        label : "TER",
        value : "64cc765743b7abb00368f0c9",
    }
]


export const doctorList:any = [
    {
        "value": "644c05f5342d789257dcdb6c",
        "label": "Rajesh Nandaniya"
    },
    {
        "value": "648c5c432b72404a9c0c11f6",
        "label": "jake  rayan"
    },
    {
        "value": "64c8acec8d371986a1c92075",
        "label": "Dentist Demo"
    },
    {
        "value": "6440c1d6f5baa851cd18a2fb",
        "label": "john doe"
    }
]

export const patientList:any = [
    {
        "value": "6454b1e0ddf2093946625e71",
        "label": "Anjali M Mehta"
    },
    {
        "value": "64561f931ea3550a83c8dc93",
        "label": "sanjay"
    },
    {
        "value": "6454c2a4ddf2093946625f21",
        "label": "Rahul Patel"
    }
]

export const filterList:any = [
    {
        filterType:'DATE',
        stateName: [REPORT_START_DATE, REPORT_END_DATE],
        isMultiState: true,
        stateValue: ''
    },
    {
        filterType:'Branch',
        stateName: REPORT_BRANCH,
        isMultiState: false,
        stateValue: null
    },
    {
        filterType:'Doctor',
        stateName: REPORT_DOCTOR,
        isMultiState: false,
        stateValue: null
    },
    {
        filterType:'Patient',
        stateName: REPORT_PATIENT,
        isMultiState: false,
        stateValue: null
    }
]