import { FC } from 'react'
import { colors } from '../../constants/color'
import { CloseIcon } from '../../components/common/svg-components'
import TableV2 from '../../components/common/table/tableV2/TableV2'
import { baseTableSelectColumnsPopupHeaderData } from '../../constants/table-data/baseTableSelectColumnsModalHeaderData'
import styles from './baseTableSelectColumnsModal.module.scss'
import { t } from 'i18next'

interface IBaseTableSelectColumnsModal {
  handleClose: any
  handleSubmitData: any
  popData: any
  handleNo: any
}

const BaseTableSelectColumnsModal: FC<IBaseTableSelectColumnsModal> = ({
  handleClose,
  handleSubmitData,
  popData,
  handleNo,
}) => {
  return (
    <>
      <div
        className={styles.baseTableSelectColumnsModalContainer}
        onClick={(e) => e.stopPropagation()}
      >
        <CloseIcon
          customClass={styles.closeIconStyle}
          fillColor={colors.green1}
          handleClick={() => handleClose()}
        />
        <h1 className={styles.baseTableSelectColumnsModalHeading}>
          {popData?.base_table} {t('ReportBuilder.Table_Columns')}
        </h1>
        <hr className={styles.baseTableSelectColumnsModalDivider} />
        {popData?.column?.length > 0 && (
          <div className={styles.tableContainer}>
            <TableV2
              tableHeaderData={baseTableSelectColumnsPopupHeaderData}
              tableRowData={popData?.column?.length ? popData?.column : []}
              active={false}
              handleClick={(item: any) => handleNo(item)}
            />
          </div>
        )}
      </div>
    </>
  )
}

export default BaseTableSelectColumnsModal
