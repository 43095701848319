import { Label } from '../../components/common/label'
import styles from './importUtility.module.scss'
import Select from 'react-select'
import { t } from 'i18next'
import { DropdownIndicator } from '../../components/common/dropdown-indicator/DropdownIndicator'
import { useEffect, useState } from 'react'
import {
  csvINVOptions,
  csvOptions,
  importForm,
  importTypeOptions,
  inValidaHeaderData,
  inventoryTypeOptions,
  unitTypeHeaderData,
} from './data'
import SampleCSV from '../../components/sample-csv/SampleCSV'
import { downloadCSV } from '../../utils/utils'
import { useAppDispatch, useAppSelector } from '../../hooks'
import Loader from '../../components/common/spinner/Loader'
import TableV2 from '../../components/common/table/tableV2/TableV2'
import Popup from '../../components/common/popup/Popup'
import UnitTypePopup from './unittype-popup/UnitTypePopup'
import Button from '../../components/common/button/Button'
import { clearImportData } from '../../redux/features/import-utility/importUtilitySlice'
import PaginationV2 from '../../components/common/pagination/paginationv2/PaginationV2'

const ImportUtility = () => {
  const [importType, setImportType] = useState(importTypeOptions[0])
  const [itemType, setItemType] = useState(inventoryTypeOptions[0])
  const [unitTypePopup, setUnitTypePopup] = useState<boolean>(false)
  const [invalidPageCount, setInvalidPageCount] = useState(0)
  const { isLoading, inValidData } = useAppSelector((state) => state.import)
  const { masterValueData } = useAppSelector((state) => state.login)
  const [unitTypeData, setUnitTypeData] = useState<any[]>([])
  const dispatch = useAppDispatch()
  useEffect(() => {
    const unitType = masterValueData?.find(
      (item: any) => item?.category_name === 'INVENTORY_UNIT_TYPE'
    )?.values
    setUnitTypeData(unitType)
  }, [masterValueData])
  useEffect(() => {
    return () => {
      dispatch(clearImportData())
    }
  }, [dispatch])
  return (
    <>
      {isLoading ? <Loader /> : ''}
      {unitTypePopup && (
        <Popup
          Children={UnitTypePopup}
          handleClose={() => setUnitTypePopup(false)}
          popData={unitTypeData}
          headerData={unitTypeHeaderData}
          heading={
            t('ImportUtility.UnitTypeRef') || 'Unit Type Reference for CSV'
          }
        />
      )}
      <div className={styles.mainContainer}>
        <div className={styles.importFieldContainer}>
          <div className={styles.inputFieldContainer}>
            <Label
              labelText={t('ImportUtility.Import_Type')}
              requiredField={true}
              flexBasis="30%"
            />
            <div className={styles.inlineItems}>
              <Select
                className={styles.selectInputField}
                placeholder={
                  t('MobileAppConfiguration.Type_placeholder') || `Select Type`
                }
                closeMenuOnSelect={true}
                value={importType}
                onChange={(e: any) => {
                  setImportType(e)
                  if (inValidData?.length !== 0) {
                    dispatch(clearImportData())
                  }
                }}
                components={{ DropdownIndicator }}
                options={importTypeOptions}
                isSearchable={true}
                maxMenuHeight={200}
              />
            </div>
          </div>
        </div>
        {importType.value === 'INV_ITEMS' && (
          <>
            <div className={styles.importFieldContainer}>
              <div className={styles.inputFieldContainer}>
                <Label
                  labelText={t('ImportUtility.Item Type')}
                  requiredField={true}
                  flexBasis="30%"
                />
                <div className={styles.inlineItems}>
                  <Select
                    className={styles.selectInputField}
                    placeholder={
                      t('ImportUtility.Select Item Type') || `Select Item Type`
                    }
                    closeMenuOnSelect={true}
                    value={itemType}
                    onChange={(e: any) => {
                      setItemType(e)
                      if (inValidData?.length !== 0) {
                        dispatch(clearImportData())
                      }
                    }}
                    components={{ DropdownIndicator }}
                    options={inventoryTypeOptions}
                    isSearchable={true}
                    maxMenuHeight={200}
                  />
                </div>
              </div>
            </div>
            <div className={styles.importFieldContainer}>
              <div className={styles.inputFieldContainer}>
                <Label
                  labelText={
                    t('ImportUtility.UnitTypeRef') ||
                    'Unit Type Reference for CSV'
                  }
                  requiredField={true}
                  flexBasis="30%"
                />
                <div className={styles.inlineItems}>
                  <Button
                    title={
                      t('ImportUtility.UnitTypeRef') ||
                      'Unit Type Reference for CSV'
                    }
                    handleClick={() => setUnitTypePopup(true)}
                    customClass={styles.refButton}
                  />
                </div>
              </div>
            </div>
          </>
        )}

        <div className={styles.importFieldContainer}>
          <div className={styles.inputFieldContainer}>
            <Label labelText={t('LeadPool.Sample_CSV')} flexBasis="30%" />
            <div className={styles.inlineItems}>
              <SampleCSV
                onExport={() =>
                  downloadCSV(
                    importType.value === 'INV_ITEMS'
                      ? csvINVOptions[itemType.value]
                      : csvOptions[importType.value],
                    importType.value === 'INV_ITEMS'
                      ? `${itemType.label
                          .toLocaleLowerCase()
                          .replace(/ /g, '_')}_inventory_items_sample.csv`
                      : `${importType.label
                          .toLocaleLowerCase()
                          .replace(/ /g, '_')}_sample.csv`
                  )
                }
              />
            </div>
          </div>
        </div>

        {/*  form */}
        {importForm[importType.value]}
        {/* invalid records */}
        {inValidData?.length > 0 ? (
          <div className={styles.inValidDataContainer}>
            <span className={styles.title}>
              {t('ImportUtility.InVaidData')}
            </span>
            <TableV2
              tableHeaderData={inValidaHeaderData[importType.value]}
              // tableRowData={inValidData}
              tableRowData={
                inValidData?.slice(invalidPageCount, invalidPageCount + 15) ||
                []
              }
              active={false}
            />
            {inValidData?.length > 15 && (
              <PaginationV2
                handlePrevious={() => {
                  if (invalidPageCount >= 15) {
                    setInvalidPageCount(invalidPageCount - 15)
                  }
                }}
                handleReset={() => {
                  setInvalidPageCount(0)
                }}
                handleNext={() => {
                  if (inValidData?.length > invalidPageCount + 15) {
                    setInvalidPageCount(invalidPageCount + 15)
                  }
                }}
                nextPage={inValidData?.length > invalidPageCount + 15}
                previousPage={!invalidPageCount ? true : false}
                customClass={styles.paginationContainer}
              />
            )}
          </div>
        ) : (
          ''
        )}
      </div>
    </>
  )
}

export default ImportUtility
