// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.submittedpopopup_tableContainer__jpZSU {
  margin: 20px 15px;
  max-height: 270px;
  background-color: var(--white1);
  overflow: auto;
  scroll-behavior: smooth;
}
.submittedpopopup_tableContainer__jpZSU::-webkit-scrollbar {
  display: block !important;
  width: 7px !important;
  height: 7px !important;
}
.submittedpopopup_tableContainer__jpZSU::-webkit-scrollbar-track {
  background: transparent !important;
}
.submittedpopopup_tableContainer__jpZSU::-webkit-scrollbar-thumb {
  background-color: var(--grey7) !important;
  border-radius: 10px !important;
  border: 1px solid var(--grey7) !important;
}
.submittedpopopup_tableContainer__jpZSU td:nth-last-child(2) {
  color: var(--yellow1);
}`, "",{"version":3,"sources":["webpack://./src/pages/mainstore/submitted-po-popup/submittedpopopup.module.scss"],"names":[],"mappings":"AAEA;EACE,iBAAA;EACA,iBAAA;EACA,+BAAA;EACA,cAAA;EACA,uBAAA;AADF;AAEE;EACE,yBAAA;EACA,qBAAA;EACA,sBAAA;AAAJ;AAEE;EACE,kCAAA;AAAJ;AAGE;EACE,yCAAA;EACA,8BAAA;EACA,yCAAA;AADJ;AAGE;EACE,qBAAA;AADJ","sourcesContent":["@import '../../../styles/mixin.scss';\n\n.tableContainer {\n  margin: 20px 15px;\n  max-height: 270px;\n  background-color: var(--white1);\n  overflow: auto;\n  scroll-behavior: smooth;\n  &::-webkit-scrollbar {\n    display: block !important;\n    width: 7px !important;\n    height: 7px !important;\n  }\n  &::-webkit-scrollbar-track {\n    background: transparent !important;\n  }\n\n  &::-webkit-scrollbar-thumb {\n    background-color: var(--grey7) !important;\n    border-radius: 10px !important;\n    border: 1px solid var(--grey7) !important;\n  }\n  td:nth-last-child(2) {\n    color: var(--yellow1);\n  }\n  // td:nth-last-child(1) {\n  //   color: var(--green1);\n  // }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tableContainer": `submittedpopopup_tableContainer__jpZSU`
};
export default ___CSS_LOADER_EXPORT___;
