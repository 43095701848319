import styles from './external.module.scss'
import Select from 'react-select'
import moment from 'moment'
import { useAppSelector } from '../../../../hooks'
import { useState, useEffect } from 'react'
import { reactSelectStyle } from '../../../../constants/data'
import { t } from 'i18next'

export const ExternalHeader: any[] = [
  {
    Header: t('LeadPool.SR_NO'),
    accessor: 'sr_no',
    Cell: ({ row }: any) => {
      return <p>{row?.original?.sr_no ? row?.original?.sr_no : '-'}</p>
    },
  },
  {
    Header: t('LeadPool.ADDED_ON'),
    accessor: 'createdAt',
    Cell: ({ row }: any) => {
      const originalDate = row?.original?.createdAt
      const formattedDate = moment(originalDate).format('DD MMM YYYY')
      return <>{formattedDate}</>
    },
  },
  {
    Header: t('CallCenterAdmin.LEAD_NAME'),
    accessor: 'name',
    Cell: ({ row }: any) => {
      return (
        <p>{row?.original?.name?.length > 0 ? row?.original?.name : '-'}</p>
      )
    },
  },
  {
    Header: t('CreateIPDFrom.Contact No.'),
    accessor: 'phone',
  },
  {
    Header: t('LabConfiguration.GENDER'),
    accessor: 'gender',
    Cell: ({ row }: any) => {
      return (
        <p>{row?.original?.gender?.length > 0 ? row?.original?.gender : '-'}</p>
      )
    },
  },
  {
    Header: t('LeadPool.NATIONALITY'),
    accessor: 'nationality',
    Cell: ({ row }: any) => {
      return (
        <p>
          {row?.original?.nationality?.length > 0
            ? row?.original?.nationality
            : '-'}
        </p>
      )
    },
  },
  {
    Header: t('LeadPool.AGE'),
    accessor: 'age',
    Cell: ({ row }: any) => {
      return <p>{row?.original?.age ? row?.original?.age : '-'}</p>
    },
  },
  {
    Header: t('LeadPool.SOURCE'),
    accessor: 'external_source',
    Cell: ({ row }: any) => {
      return (
        <p>
          {row?.original?.external_source != ''
            ? row?.original?.external_source
            : '-'}
        </p>
      )
    },
  },
  {
    Header: t('RcptTableHeader.STATUS'),
    Cell: ({ row }: any) => {
      return (
        <>
          <p
            className={
              row?.original?.is_assigned ? styles.greenStyle : styles.redStyle
            }
          >
            {' '}
            {row?.original?.is_assigned ? 'Assigned' : 'Unassigned'}
          </p>
        </>
      )
    },
  },
  {
    Header: t('CallCenterAdmin.ASSIGN'),
    Cell: (props: any) => {
      const { allUserData } = useAppSelector((state) => state.callCenter)

      let filterData = allUserData?.filter(
        (item: any) => item?._id === props?.row?.original?.assigned_to_id
      )

      const [selectUser, setSelectUser] = useState<any>(undefined)

      useEffect(() => {
        if (filterData[0]?._id !== undefined) {
          setSelectUser({
            label: filterData[0]?.name,
            value: filterData[0]?._id,
          })
        } else {
          setSelectUser(undefined)
        }
      }, [filterData[0]?.name])

      return (
        <>
          <Select
            className={styles.select}
            placeholder={t('LeadPool.Select_assignee')}
            closeMenuOnSelect={true}
            isSearchable={true}
            options={allUserData?.map((item: any) => ({
              label: item?.name,
              value: item?._id,
            }))}
            onChange={(e: any) => {
              props?.onClick(props?.row?.original?._id, e?.value)
              setSelectUser({
                label: e?.label,
                value: e?.value,
              })
            }}
            value={selectUser}
            maxMenuHeight={200}
            styles={reactSelectStyle}
          />
        </>
      )
    },
  },
]
