import { t } from 'i18next'
import MasterAppointmentStatusDropdown from '../../components/common/status-dropdown/master-appointment-status-dropdown/MasterAppoinmentStatusDropdown'

export const statusSummaryData: any = [
  {
    Header: t('RcptTableHeader.STATUS'),
    Cell: ({ row }: any) => {
      let status = row?.original?._id
      return (
        <MasterAppointmentStatusDropdown
          appointmentStatus={status}
          isDisable={true}
        />
      )
    },
  },
  {
    Header: t('ViewAppointmentHeader.TOTAL PATIENT'),
    accessor: 'count',
  },
]
