import { EditIcon } from '../../components/common/svg-components'
import { colors } from '../color'
import styles from './tableData.module.scss'
import { t } from 'i18next'

export const reportMappingTableHeaderData: any = [
  {
    Header: t('PatientHistoryFormHeader.ROLE'),
    accessor: 'name',
  },
  {
    Header: t('ReportMapping.REPORT_TYPE'),
    aaccessor: 'report_typr',
    Cell: ({ row }: any) => {
      const reports_access: any = row?.original?.reports_access
      return reports_access && reports_access?.length > 0
        ? reports_access?.map((item: any, index: number) => {
            return (
              <div key={`report-${index}`} className={styles.columnFlex}>
                <span>
                  {index + 1}. {item}
                </span>
              </div>
            )
          })
        : '-'
    },
  },
  {
    Header: t('DocUploadHeader.ACTION'),
    Cell: (props: any) => {
      const handleEdit = (item: any) => {
        props.onClick(item)
      }
      return (
        <EditIcon
          fillColor={colors.grey4}
          handleClick={() => handleEdit(props?.row?.original)}
        />
      )
    },
  },
]
