import { useState, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { SubmitHandler, useForm } from 'react-hook-form'
import Select from 'react-select'
import { DropdownIndicator } from '../../components/common/dropdown-indicator/DropdownIndicator'
import { Label } from '../../components/common/label'
import { Input } from '../../components/common/input/input'
import Button from '../../components/common/button/Button'
import { useAppDispatch, useAppSelector } from '../../hooks'
import { groupBy, reverse, cloneDeep } from 'lodash'
import {
  getUserRole,
  getReportBuilderConfigList,
  reportPocAsync,
  addReportQuery,
  updateReportQuery,
} from '../../redux/features/role/roleAsynActions'
import { getAllDoctors } from '../../redux/features/appointments/bookingAppointmentAsyncActions'
import { getAllPatientList } from '../../redux/features/patient-emr/patient/patientAsyncAction'
import { getAllBranch } from '../../redux/features/branch/branchAsyncActions'
import {
  updateBaseTableColumn,
  updateChildTableColumn,
  updateBaseTableOrder,
  reportBuilderFormData,
  addBaseTableColumn,
  addBaseTableOrder,
} from '../../redux/features/role/roleSlice'
import { requestGenerator } from '../../utils/payloadGenerator'
import ReportsNotesModal from './ReportsNotesModal'
import BaseTableSelectColumnsModal from './BaseTableSelectColumnsModal'
import TableV2 from '../../components/common/table/tableV2/TableV2'
import Popup from '../../components/common/popup/Popup'
import Loader from '../../components/common/spinner/Loader'
import {
  REPORT_NAME,
  REPORT_ROLE,
  REPORT_BASE_TABLE,
  REPORT_FILTER,
  REPORT_START_DATE,
  REPORT_END_DATE,
  REPORT_DATE_FILTER_BY,
  REPORT_BRANCH,
  REPORT_DOCTOR,
  REPORT_PATIENT,
  REPORT_SORT,
  REPORT_SORTING_TYPE,
  REPORT_SUM_COLUMN,
} from '../../constants/reportBuilderConstant'
import { IReportBuilderForm } from '../../interfaces/reportBuilderInterfaces'
import { reportBuilderValidators } from '../../form-validators/reportBuilderValidators'
import { filterList } from './data'
import CreateReportChildLevelComponent from './CreateReportChildLevelComponent'
import { reactSelectStyle } from '../../constants/data'
import styles from './styles.module.scss'
import { removeDollarSign } from '../../utils/utils'
import { CheckIcon, UncheckIcon } from '../../components/common/svg-components'
import { colors } from '../../constants/color'
import { utils, writeFile, WorkBook, WorkSheet } from 'xlsx'
import { t } from 'i18next'

const CreateReport = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const {
    isLoading,
    userRole,
    reportBuilderConfigData,
    selctedBaseTableColumns,
    selctedBaseTableOrders,
    reportBuilderFormValues,
  } = useAppSelector((state) => state.roleUser)
  const { branchData } = useAppSelector((state) => state.branch)
  const { doctorData } = useAppSelector((state) => state.appointments)
  const { patientListData } = useAppSelector((state) => state.patient)
  const [generateReportPayload, setGenerateReportPayload] = useState<any>({})
  const [selectedLevels, setSelectedLevels] = useState<any>({})
  const [teableHeaderData, setTableHeaderData] = useState<any>([])
  const [tableData, setTableData] = useState<any>([])
  const [selectedRowData, setSelectedRowData] = useState<any>({})
  const [showReportsNotesModal, setShowReportsNotesModal] = useState<any>(false)
  const [showBaseTableSelectColumnsModal, setShowBaseTableSelectColumnsModal] =
    useState<boolean>(false)
  const [isCount, setIscount] = useState<boolean>(false)

  const location = useLocation()

  useEffect(() => {
    if (location?.state !== null) {
      setIscount(location?.state?.is_count)
    }
  }, [location?.state])

  // React Hook form for the form handling
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    trigger,
    reset,
    formState: { errors },
  } = useForm<IReportBuilderForm>({})

  // watch form state
  const reportBuilderFormWatch = watch()

  // useEffect for fill report form data
  useEffect(() => {
    const { generateReportPayload, reportBuilderFormWatch, selectedLevels } =
      reportBuilderFormValues
    if (Object?.keys(reportBuilderFormValues)?.length > 0) {
      setGenerateReportPayload(generateReportPayload)
      setSelectedLevels(selectedLevels)
      reset(reportBuilderFormWatch)
    }
    return () => {
      dispatch(reportBuilderFormData({}))
      dispatch(updateBaseTableColumn([]))
      dispatch(updateBaseTableOrder([]))
      dispatch(updateChildTableColumn([]))
    }
  }, [dispatch, reset, reportBuilderFormValues])

  // useEffect for get list
  useEffect(() => {
    let requestDataForRoleList = {
      search: '',
      page: 1,
      pageSize: 100,
    }
    let requestDataForBranchList = {
      search: '',
      page: 1,
      pageSize: 100,
      is_active: true,
    }
    const requestDataForDoctorsList = {
      is_active: true,
      page: 1,
      pageSize: 1000,
    }
    const requestDataForPatientList = {
      search: '',
      page: 1,
      pageSize: 1000,
      is_active: true,
    }
    dispatch(getUserRole(requestGenerator(requestDataForRoleList)))
    dispatch(getAllBranch(requestGenerator(requestDataForBranchList)))
    dispatch(getReportBuilderConfigList(requestGenerator({})))
    dispatch(getAllDoctors(requestGenerator(requestDataForDoctorsList)))
    dispatch(getAllPatientList(requestGenerator(requestDataForPatientList)))
  }, [dispatch])

  // useEffect for generate payload
  // useEffect(() => {
  //   if (Object?.keys(selectedLevels)?.length > 0) {
  //     const reverseArray = reverse(Object.keys(selectedLevels))

  //     reverseArray?.filter((i: any, ix) => {
  //       const byParent = groupBy(selectedLevels[i], (item: any) => item._parent)
  //       Object.keys(byParent)?.filter((j: any) => {
  //         let parent: string = reverseArray[ix + 1]
  //         if (selectedLevels[parent]) {
  //           selectedLevels[parent].map((k: any) => {
  //             if (k?.name === j) {
  //               k.children = byParent[j]
  //             }
  //           })
  //         }
  //       })
  //     })
  //   }
  // }, [generateReportPayload, selectedLevels])

  const childLevelMerged = () => {
    const copyOfSelectedLevels = cloneDeep(selectedLevels)
    if (Object.keys(copyOfSelectedLevels).length > 0) {
      const reverseArray = Object.keys(copyOfSelectedLevels).reverse()

      reverseArray?.forEach((key, index) => {
        const byParent = groupBy(
          copyOfSelectedLevels[key],
          (item) => item._parent
        )
        const parentKey = reverseArray[index + 1]

        if (copyOfSelectedLevels[parentKey]) {
          copyOfSelectedLevels[parentKey]?.forEach((parentItem: any) => {
            if (byParent[parentItem.name]) {
              parentItem.children = [...byParent[parentItem.name]]
            }
          })
        }
      })
    }
    return copyOfSelectedLevels
  }

  // useEffect for table
  useEffect(() => {
    if (tableData?.hasOwnProperty('table_headers')) {
      const genrateHeader = tableData?.table_headers?.map((headerKey: any) => {
        return {
          Header: headerKey,
          Cell: ({ row }: any) => {
            let rowData = row?.original?.[headerKey]
            if (typeof rowData === 'string' || typeof rowData === 'number') {
              return <p>{rowData ? rowData : '-'}</p>
            } else if (typeof rowData === 'object') {
              return (
                <p
                  style={{ color: '#0e26a3', textDecoration: 'underline' }}
                  onClick={() => {
                    setSelectedRowData(rowData)
                    setShowReportsNotesModal((prevState: any) => !prevState)
                  }}
                >
                  View
                </p>
              )
            } else {
              return <p>{'-'}</p>
            }
          },
        }
      })
      setTableHeaderData(genrateHeader)
    }
  }, [tableData])

  // useEffect for checked sorting type
  useEffect(() => {
    if (reportBuilderFormWatch?.report_sort?.hasOwnProperty('order')) {
      setValue(
        REPORT_SORTING_TYPE,
        String(reportBuilderFormWatch?.report_sort?.order)
      )
    }
  }, [reportBuilderFormWatch?.report_sort])

  // function for notes modal close
  const reportsNotesModalClose = () => {
    setSelectedRowData({})
    setShowReportsNotesModal((prevState: any) => !prevState)
  }
  // function for test report query
  const handleTestQuery: any = async () => {
    const sum_column = generateSumColumnArray()
    const { selected, _headers } = await handleSetHeaderandSelected()
    const mergedLevelSelected = childLevelMerged()
    const { base_table } = generateReportPayload
    const { level1Selected } = mergedLevelSelected
    let selectedBranchIds: any = []
    if (reportBuilderFormWatch?.report_branch?.length > 0) {
      selectedBranchIds = reportBuilderFormWatch?.report_branch?.map(
        (selectedBranchObject: any) => selectedBranchObject?.value
      )
    } else {
      selectedBranchIds = null
    }
    let selectedDoctorIds: any = []
    if (reportBuilderFormWatch?.report_doctor?.length > 0) {
      selectedDoctorIds = reportBuilderFormWatch?.report_doctor?.map(
        (selectedDoctorObject: any) => selectedDoctorObject?.value
      )
    } else {
      selectedDoctorIds = null
    }
    let selectedPatientIds: any = []
    if (reportBuilderFormWatch?.report_patient?.length > 0) {
      selectedPatientIds = reportBuilderFormWatch?.report_patient?.map(
        (selectedPatientObject: any) => selectedPatientObject?.value
      )
    } else {
      selectedPatientIds = null
    }

    const genrateReportPayload = {
      base_name: base_table,
      pipeline: [
        {
          base_table: base_table,
          children: level1Selected,
          selected,
        },
      ],
      filter: {
        fromDate: reportBuilderFormWatch?.report_start_date,
        toDate: reportBuilderFormWatch?.report_end_date,
        branch_id: selectedBranchIds,
        doctor_id: selectedDoctorIds,
        patient_id: selectedPatientIds,
      },
      _filter: reportBuilderFormWatch?.report_filter,
      sorting: reportBuilderFormWatch?.report_sort,
      _headers,
      sum_column,
      is_count: isCount,
    }
    dispatch(reportPocAsync(requestGenerator(genrateReportPayload))).then(
      (res) => {
        if (res.type === 'role/reportPocAsync/fulfilled') {
          setTableData(res.payload)
        }
      }
    )
  }

  const onSubmit: SubmitHandler<IReportBuilderForm> = async (data) => {
    const mergedLevelSelected = childLevelMerged()
    const { selected, _headers } = await handleSetHeaderandSelected()

    const roleIdsList = data?.report_role?.map((role: any) => role.value)
    const { base_table } = generateReportPayload
    const { level1Selected } = mergedLevelSelected
    const sum_column = generateSumColumnArray()

    const genrateSubmitReportPayload = {
      name: data?.report_name,
      role_ids: roleIdsList,
      base_name: base_table,
      aggregate: [
        {
          base_table: base_table,
          children: level1Selected,
          selected,
        },
      ],
      filter: data?.report_filter,
      sorting: reportBuilderFormWatch?.report_sort,
      _headers,
      raw_aggregator_json: {
        reportBuilderFormWatch,
        generateReportPayload,
        selectedLevels,
        selctedBaseTableColumns,
        selctedBaseTableOrders,
      },
      sum_column,
      is_count: isCount,
    }

    if (reportBuilderFormValues?.id) {
      const generateUpadateReportPayload = {
        id: reportBuilderFormValues.id,
        data: genrateSubmitReportPayload,
      }
      dispatch(
        updateReportQuery(requestGenerator(generateUpadateReportPayload))
      ).then((res) => {
        if (res.type === 'role/updateReportQuery/fulfilled') {
          navigate('/reportbuilder')
        }
      })
    } else {
      dispatch(
        addReportQuery(requestGenerator(genrateSubmitReportPayload))
      ).then((res) => {
        if (res.type === 'role/createReportQuery/fulfilled') {
          navigate('/reportbuilder')
        }
      })
    }
  }

  // funtion for reset report query
  const handleReset: any = () => {
    const resetObject = {
      report_name: '',
      report_role: null,
      report_base_table: null,
      report_filter: null,
    }
    setGenerateReportPayload({})
    setSelectedLevels({})
    reset(resetObject)
    setTableData([])
    dispatch(updateBaseTableColumn([]))
    dispatch(updateBaseTableOrder([]))
    dispatch(updateChildTableColumn([]))
    setIscount(false)
  }

  // function for open base table select column modal
  const handleBaseTableColmunModalOpen = () => {
    setShowBaseTableSelectColumnsModal((prevState) => !prevState)
  }

  // function for close base table select column modal
  const handleSetHeaderandSelected = async () => {
    let convertSelectedBaseTableColumns: any = {}
    let convertSelectedBaseTableOrders: any = {}

    selctedBaseTableColumns?.map(
      (selectedColumns: any) =>
        (convertSelectedBaseTableColumns = {
          ...selectedColumns,
          ...convertSelectedBaseTableColumns,
        })
    )

    selctedBaseTableOrders?.map(
      (selectedOrder: any) =>
        (convertSelectedBaseTableOrders = {
          [Object.keys(selectedOrder)[0]]: Number(
            Object.values(selectedOrder)[0]
          ),
          ...convertSelectedBaseTableOrders,
        })
    )

    setGenerateReportPayload({
      ...generateReportPayload,
      selected: convertSelectedBaseTableColumns,
      _headers: convertSelectedBaseTableOrders,
    })
    return {
      selected: convertSelectedBaseTableColumns,
      _headers: convertSelectedBaseTableOrders,
    }
  }
  const handleBaseTableColmunModalClose = async () => {
    await handleSetHeaderandSelected()
    setShowBaseTableSelectColumnsModal((prevState) => !prevState)
  }

  // handle sorting radio type
  const handleSortingRadioType = (e: any) => {
    setValue(REPORT_SORTING_TYPE, e.target.value)
    const currentSelectedSortingObject = reportBuilderFormWatch?.report_sort
    currentSelectedSortingObject.order = Number(e.target.value)
    setValue(REPORT_SORT, currentSelectedSortingObject)
  }

  const generateSumColumnArray = () => {
    let tempArr: any[] = []
    const { selected } = generateReportPayload
    const valueArray = reportBuilderFormWatch[REPORT_SUM_COLUMN]?.map(
      (item: any) => item.value
    )
    if (selected) {
      const aliasArray = Object.keys(selected)
      const columnArray = Object.values(selected)
      tempArr = valueArray.map((item: any, index: number) => {
        const matchItem = columnArray.findIndex(
          (columnItem: any) => removeDollarSign(columnItem) === item
        )

        if (matchItem !== -1) {
          return aliasArray[matchItem]
        } else {
          return item
        }
      })
      return tempArr
    } else {
      return valueArray
    }
  }

  const result =
    tableData?.countData !== null
      ? Object.keys(
          tableData?.countData !== undefined && tableData?.countData
        ).map((key) => [key, tableData?.countData[key]])
      : []

  let evenDataArray: any = []
  let oddDataArray: any = []
  const oddData = () => {
    if (result?.length > 0) {
      for (var i = 0; i < result.length; i += 2) {
        oddDataArray.push(result[i])
      }
    } else return
  }
  const evenData = () => {
    if (result?.length > 0) {
      for (var i = 1; i < result.length; i += 2) {
        evenDataArray.push(result[i])
      }
    } else return
  }

  oddData()
  evenData()

  const handleDownloadExcel = () => {
    const trimmedData: any = tableData?.data
    const name = `${watch(REPORT_NAME) || 'Report'}_${new Date().getTime()}`

    trimmedData.push({})
    if (tableData?.countData !== null) {
      let countObject: any = {}
      let totalCountData
      if (tableData?.countData?.total_count) {
        totalCountData = tableData?.countData?.total_count
        countObject = tableData?.countData
        delete countObject['total_count']
        trimmedData.push({ ...tableData?.countData, '': 'Total' })
        trimmedData.push({ '': `Total Records Count: ${totalCountData}` })
      } else {
        trimmedData.push({ ...tableData?.countData, '': 'Total' })
      }
    }
    const resultData = trimmedData?.map((item: any) => {
      delete item['_id']
      return item
    })
    const worksheet: WorkSheet = utils.json_to_sheet(resultData)
    const workbook: WorkBook = utils.book_new()
    utils.book_append_sheet(workbook, worksheet, 'Report')
    const excelBuffer = writeFile(workbook, `${name}.xlsx`, { type: 'buffer' })
    const link = document.createElement('a')
    link.href = window.URL.createObjectURL(new Blob([excelBuffer]))
    link.download = `${name}.xlsx`
  }

  return (
    <>
      {isLoading && <Loader />}
      {showReportsNotesModal && (
        <Popup
          Children={ReportsNotesModal}
          handleClose={reportsNotesModalClose}
          popData={selectedRowData}
        />
      )}
      {showBaseTableSelectColumnsModal && (
        <Popup
          Children={BaseTableSelectColumnsModal}
          handleClose={handleBaseTableColmunModalClose}
          handleNo={(column: any) => {
            const formData = watch(REPORT_SUM_COLUMN)
            let tempArr: any[] = []
            if (formData?.length > 0) {
              tempArr = formData.filter((item: any) => item.value !== column)
              setValue(REPORT_SUM_COLUMN, tempArr)
            }
          }}
          popData={generateReportPayload}
        />
      )}
      <form
        className={styles.reportsContainer}
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className={styles.formFields}>
          <div style={{ flexBasis: '31%' }}>
            <div className={styles.inputFieldContainer}>
              <Label
                labelText={t('ReportBuilder.Report_Name')}
                requiredField={true}
                flexBasis="45%"
              />
              <div className={styles.inputField}>
                <input
                  className={styles.inputTextField}
                  type="text"
                  placeholder={
                    t('ReportBuilder.Report_Name_Placeholder') ||
                    'Enter Report Name'
                  }
                  {...register(
                    REPORT_NAME,
                    reportBuilderValidators[REPORT_NAME]
                  )}
                />
                {errors[REPORT_NAME] && (
                  <p className={styles.formError}>
                    {errors[REPORT_NAME].message as any}
                  </p>
                )}
              </div>
            </div>
          </div>
          <div style={{ flexBasis: '30%' }}>
            <div className={styles.inputFieldContainer}>
              <Label
                labelText={t('ManageUserRoles.Role')}
                requiredField={true}
                flexBasis="31%"
              />
              <div className={styles.inlineItems}>
                <Select
                  className={styles.selectInputField}
                  placeholder={t('ManageStaff.role_placeholder')}
                  closeMenuOnSelect={false}
                  components={{ DropdownIndicator }}
                  value={watch(REPORT_ROLE)}
                  {...register(
                    REPORT_ROLE,
                    reportBuilderValidators[REPORT_ROLE]
                  )}
                  options={userRole
                    ?.filter((item: any) => item?.role_type !== 'secondary')
                    ?.map((item: any) => ({
                      label: item.name,
                      value: item._id,
                    }))}
                  onChange={(e: any) => {
                    setValue(REPORT_ROLE, e)
                    trigger(REPORT_ROLE)
                  }}
                  isSearchable={false}
                  maxMenuHeight={200}
                  isMulti
                  styles={reactSelectStyle}
                />
                {errors[REPORT_ROLE] && (
                  <p className={styles.formError}>
                    {errors[REPORT_ROLE].message as any}
                  </p>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className={styles.formFields}>
          <div style={{ flexBasis: '30%' }}>
            <div className={styles.inputFieldContainer}>
              <Label
                labelText={t('ReportBuilder.Base_Table')}
                requiredField={true}
                flexBasis="31%"
              />
              <div className={styles.inlineItems}>
                <Select
                  className={styles.selectInputField}
                  placeholder={t('ReportBuilder.Base_Table_Placeholder')}
                  closeMenuOnSelect={true}
                  components={{ DropdownIndicator }}
                  {...register(REPORT_BASE_TABLE)}
                  value={watch(REPORT_BASE_TABLE)}
                  options={reportBuilderConfigData?.map((item: any) => {
                    return {
                      label: item?.base_table,
                      value: item?.base_table,
                      ...item,
                    }
                  })}
                  isSearchable={false}
                  onChange={(e: any) => {
                    const { label, value, children, ...rest } = e
                    setGenerateReportPayload({ 0: children, ...rest })
                    const resetObject = {
                      report_name: reportBuilderFormWatch?.report_name,
                      report_role: reportBuilderFormWatch?.report_role,
                      report_base_table: e,
                    }
                    reset(resetObject)
                    setSelectedLevels({})
                    setTableData([])
                    dispatch(updateBaseTableColumn([]))
                    dispatch(updateBaseTableOrder([]))
                    dispatch(updateChildTableColumn([]))
                    setValue(REPORT_SUM_COLUMN, [])
                  }}
                  maxMenuHeight={200}
                  styles={reactSelectStyle}
                />
              </div>
            </div>
          </div>
          {generateReportPayload?.column?.length > 0 && (
            <div style={{ flexBasis: '15%' }}>
              <Button
                type="button"
                title={t('ReportBuilder.Select_Column') || 'Select Column'}
                handleClick={handleBaseTableColmunModalOpen}
              />
            </div>
          )}
          {generateReportPayload?.sum_column?.length > 0 && (
            <div style={{ flexBasis: '30%' }}>
              <div className={styles.inputFieldContainer}>
                <Label
                  labelText={t('ReportBuilder.Sum_Column')}
                  flexBasis="31%"
                />
                <div className={styles.inlineItems}>
                  <Select
                    className={styles.selectInputField}
                    placeholder={
                      t('ReportBuilder.Sum_Column_Placeholder') ||
                      'Select sum column'
                    }
                    closeMenuOnSelect={true}
                    components={{ DropdownIndicator }}
                    {...register(REPORT_SUM_COLUMN)}
                    value={watch(REPORT_SUM_COLUMN)}
                    options={generateReportPayload?.sum_column?.map(
                      (item: any) => {
                        return {
                          label: item,
                          value: item,
                        }
                      }
                    )}
                    isSearchable={false}
                    onChange={(e: any) => {
                      setValue(REPORT_SUM_COLUMN, e)
                      const foundObj = generateReportPayload?.column?.find(
                        (item: any) => item === e[e.length - 1]?.value
                      )
                      if (foundObj) {
                        dispatch(addBaseTableColumn(foundObj))
                        dispatch(addBaseTableOrder({ original: foundObj }))
                      }
                    }}
                    maxMenuHeight={200}
                    styles={reactSelectStyle}
                    isMulti
                  />
                </div>
              </div>
            </div>
          )}
          <div style={{ flexBasis: '30%' }}>
            <div className={styles.inputFieldContainer}>
              <Label
                labelText={t('ReportBuilder.Is_Count') + '?'}
                flexBasis="31%"
              />
              <div className={styles.inlineItems}>
                {isCount ? (
                  <CheckIcon
                    fillColor={colors.green1}
                    handleClick={() => {
                      setIscount(false)
                    }}
                  />
                ) : (
                  <UncheckIcon
                    fillColor={colors.grey1}
                    handleClick={() => {
                      setIscount(true)
                    }}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
        <div className={styles.columnFields}>
          {generateReportPayload?.hasOwnProperty(0) &&
            selctedBaseTableColumns?.length > 0 && (
              <CreateReportChildLevelComponent
                data={generateReportPayload}
                setData={setGenerateReportPayload}
                selectedLevels={selectedLevels}
                setSelectedLevels={setSelectedLevels}
                register={register}
                setValue={setValue}
                watch={watch}
                reportBuilderFormWatch={reportBuilderFormWatch}
              />
            )}
        </div>
        {selectedLevels?.hasOwnProperty(`level1Selected`) &&
          selectedLevels[`level1Selected`].some((item: any) =>
            item.hasOwnProperty(`selected`)
          ) && (
            <div className={styles.nameFilterContainer}>
              <div className={styles.inputFieldContainer}>
                <Label
                  labelText={t('ReportBuilder.Filter')}
                  requiredField={false}
                  flexBasis="31%"
                />
                <div className={styles.inlineItems}>
                  <Select
                    className={styles.selectInputField}
                    placeholder={t('ReportBuilder.Filter_Placeholder')}
                    closeMenuOnSelect={false}
                    value={watch(REPORT_FILTER)}
                    {...register(REPORT_FILTER)}
                    onChange={(e: any) => {
                      setValue(REPORT_FILTER, e)
                      for (const filterObject of filterList) {
                        if (
                          !e?.some(
                            (item: any) =>
                              item?.name === filterObject?.filterType
                          )
                        ) {
                          if (filterObject.isMultiState === true) {
                            setValue(
                              filterObject.stateName[0],
                              filterObject.stateValue
                            )
                            setValue(
                              filterObject.stateName[1],
                              filterObject.stateValue
                            )
                          } else {
                            setValue(
                              filterObject.stateName,
                              filterObject.stateValue
                            )
                          }
                        }
                      }
                    }}
                    components={{ DropdownIndicator }}
                    options={generateReportPayload?.filter?.map(
                      (filter: any) => {
                        return {
                          label: filter?.name,
                          value: filter?.name,
                          ...filter,
                        }
                      }
                    )}
                    isSearchable={false}
                    maxMenuHeight={200}
                    isMulti
                    styles={reactSelectStyle}
                  />
                </div>
              </div>
            </div>
          )}
        {reportBuilderFormWatch?.report_filter?.length > 0 &&
          reportBuilderFormWatch?.report_filter?.some(
            (item: any) => item?.name === 'DATE'
          ) && (
            <div className={styles.dateFieldsContainer}>
              <div style={{ flexBasis: '30%' }}>
                <Input
                  type="date"
                  labelText={t('ReportBuilder.Start_Date') || 'Start Date'}
                  {...register(REPORT_START_DATE)}
                  requiredField={false}
                />
              </div>
              <div style={{ flexBasis: '30%' }}>
                <Input
                  type="date"
                  labelText={t('ReportBuilder.End_Date') || 'End Date'}
                  {...register(REPORT_END_DATE)}
                  requiredField={false}
                />
              </div>
              {reportBuilderFormWatch?.report_filter?.find(
                (item: any) => item?.name === 'DATE'
              ).canChangeFieldName === true && (
                <div className={styles.inputFieldContainer}>
                  <Label
                    labelText={t('ReportBuilder.Filter_By') || 'Filter Date'}
                    requiredField={false}
                    flexBasis="31%"
                  />
                  <div className={styles.inlineItems}>
                    <Select
                      className={styles.selectInputField}
                      placeholder={t('ReportBuilder.Select_Filter_Column')}
                      closeMenuOnSelect={true}
                      value={watch(REPORT_DATE_FILTER_BY)}
                      {...register(REPORT_DATE_FILTER_BY)}
                      options={reportBuilderFormWatch?.report_filter
                        ?.find((item: any) => item?.name === 'DATE')
                        .columns.map((column: any) => {
                          return {
                            label: column,
                            value: column,
                          }
                        })}
                      onChange={(e: any) => {
                        setValue(REPORT_DATE_FILTER_BY, e)
                        const currentSelectedReportQueryFilter =
                          reportBuilderFormWatch?.report_filter
                        const dateFileterIndex =
                          currentSelectedReportQueryFilter?.findIndex(
                            (item: any) => item?.name === 'DATE'
                          )
                        const dateFileterObject =
                          currentSelectedReportQueryFilter?.find(
                            (item: any) => item?.name === 'DATE'
                          )
                        dateFileterObject.field = e.value
                        currentSelectedReportQueryFilter[dateFileterIndex] =
                          dateFileterObject
                        setValue(
                          REPORT_FILTER,
                          currentSelectedReportQueryFilter
                        )
                      }}
                      components={{ DropdownIndicator }}
                      isSearchable={false}
                      maxMenuHeight={200}
                      styles={reactSelectStyle}
                    />
                  </div>
                </div>
              )}
            </div>
          )}
        <div className={styles.nameFilterContainer}>
          {reportBuilderFormWatch?.report_filter?.length > 0 &&
            reportBuilderFormWatch?.report_filter?.some(
              (item: any) => item?.name === 'Branch'
            ) && (
              <div className={styles.inputFieldContainer}>
                <Label
                  labelText={t('Header.Branch') || 'Branch'}
                  requiredField={false}
                  flexBasis="31%"
                />
                <div className={styles.inlineItems}>
                  <Select
                    className={styles.selectInputField}
                    placeholder={t('ManageStaff.Branch_placeholder')}
                    closeMenuOnSelect={false}
                    value={watch(REPORT_BRANCH)}
                    {...register(REPORT_BRANCH)}
                    onChange={(e: any) => {
                      setValue(REPORT_BRANCH, e)
                    }}
                    components={{ DropdownIndicator }}
                    options={branchData?.map((branchDetail: any) => {
                      return {
                        label: branchDetail?.name,
                        value: branchDetail?._id,
                      }
                    })}
                    isSearchable={false}
                    maxMenuHeight={200}
                    isMulti
                    styles={reactSelectStyle}
                  />
                </div>
              </div>
            )}
          {reportBuilderFormWatch?.report_filter?.length > 0 &&
            reportBuilderFormWatch?.report_filter?.some(
              (item: any) => item?.name === 'Doctor'
            ) && (
              <div className={styles.inputFieldContainer}>
                <Label
                  labelText={t('Receptionist Dashboard.Doctor') || 'Doctor'}
                  requiredField={false}
                  flexBasis="31%"
                />
                <div className={styles.inlineItems}>
                  <Select
                    className={styles.selectInputField}
                    placeholder={t('IPDBooking.Select Doctor')}
                    closeMenuOnSelect={false}
                    value={watch(REPORT_DOCTOR)}
                    {...register(REPORT_DOCTOR)}
                    onChange={(e: any) => {
                      setValue(REPORT_DOCTOR, e)
                    }}
                    components={{ DropdownIndicator }}
                    options={doctorData?.map((item: any) => {
                      return {
                        label: item?.doctor_name,
                        value: item?._id,
                      }
                    })}
                    isSearchable={false}
                    maxMenuHeight={200}
                    isMulti
                    styles={reactSelectStyle}
                  />
                </div>
              </div>
            )}
          {reportBuilderFormWatch?.report_filter?.length > 0 &&
            reportBuilderFormWatch?.report_filter?.some(
              (item: any) => item?.name === 'Patient'
            ) && (
              <div className={styles.inputFieldContainer}>
                <Label
                  labelText={t('ViewAppointment.Patient')}
                  requiredField={false}
                  flexBasis="31%"
                />
                <div className={styles.inlineItems}>
                  <Select
                    className={styles.selectInputField}
                    placeholder={t('IPDBooking.SelectPatient')}
                    closeMenuOnSelect={false}
                    value={watch(REPORT_PATIENT)}
                    {...register(REPORT_PATIENT)}
                    onChange={(e: any) => {
                      setValue(REPORT_PATIENT, e)
                    }}
                    components={{ DropdownIndicator }}
                    options={patientListData?.map((item: any) => {
                      return {
                        label: item?.patient_name,
                        value: item?._id,
                      }
                    })}
                    isSearchable={false}
                    maxMenuHeight={200}
                    isMulti
                    styles={reactSelectStyle}
                  />
                </div>
              </div>
            )}
        </div>
        {selectedLevels?.hasOwnProperty(`level1Selected`) &&
          selectedLevels[`level1Selected`].some((item: any) =>
            item.hasOwnProperty(`selected`)
          ) && (
            <div className={styles.nameFilterContainer}>
              <div className={styles.inputFieldContainer}>
                <Label
                  labelText={t('ReportBuilder.Sorting')}
                  requiredField={false}
                  flexBasis="31%"
                />
                <div className={styles.inlineItems}>
                  <Select
                    className={styles.selectInputField}
                    placeholder={t('ReportBuilder.Sorting_Placeholder')}
                    closeMenuOnSelect={true}
                    value={watch(REPORT_SORT)}
                    {...register(REPORT_SORT)}
                    onChange={(e: any) => {
                      setValue(REPORT_SORT, e)
                    }}
                    components={{ DropdownIndicator }}
                    options={generateReportPayload?.sorting?.map(
                      (sortingColumn: any) => {
                        return {
                          label: sortingColumn?.name,
                          value: sortingColumn?.name,
                          ...sortingColumn,
                        }
                      }
                    )}
                    isSearchable={false}
                    isClearable={false}
                    maxMenuHeight={200}
                    styles={reactSelectStyle}
                  />
                </div>
              </div>
              {reportBuilderFormWatch?.report_sort?.hasOwnProperty('order') && (
                <div className={styles.radioBtnContainer}>
                  <div className={styles.singlRadioBtnConatainer}>
                    <label htmlFor="ascending" className={styles.radioLabel}>
                      <input
                        className={styles.radioInput}
                        type="radio"
                        id="ascending"
                        value="1"
                        {...register(REPORT_SORTING_TYPE)}
                        onChange={handleSortingRadioType}
                      />
                      <span className={styles.customRadio} />
                    </label>
                    <p className={styles.radioLabelTxt}>
                      {t('ReportBuilder.Ascending')}
                    </p>
                  </div>
                  <div className={styles.singlRadioBtnConatainer}>
                    <label htmlFor="descending" className={styles.radioLabel}>
                      <input
                        className={styles.radioInput}
                        type="radio"
                        id="descending"
                        value="-1"
                        {...register(REPORT_SORTING_TYPE)}
                        onChange={handleSortingRadioType}
                      />
                      <span className={styles.customRadio} />
                    </label>
                    <p className={styles.radioLabelTxt}>
                      {t('ReportBuilder.Descending')}
                    </p>
                  </div>
                </div>
              )}
            </div>
          )}
        {selectedLevels?.hasOwnProperty(`level1Selected`) &&
          selectedLevels[`level1Selected`].some((item: any) =>
            item.hasOwnProperty(`selected`)
          ) && (
            <div className={styles.btnContainer}>
              <Button
                type="button"
                title={t('ReportBuilder.Test_Query') || 'Test Query'}
                handleClick={handleTestQuery}
              />
              <Button
                type="submit"
                title={
                  !(Object.keys(reportBuilderFormValues)?.length > 0)
                    ? t('ReportBuilder.Save_Config') || 'Save Config'
                    : t('ReportBuilder.Edit_Config') || 'Edit Config'
                }
              />
              <Button
                type="button"
                title={t('BranchSetup.Reset') || 'Reset'}
                handleClick={handleReset}
              />
              <Button
                type="button"
                title={t('ReportGenerator.Export_Data') || 'Export Data'}
                disable={tableData?.data?.length > 0 ? false : true}
                handleClick={handleDownloadExcel}
              />
            </div>
          )}
        {result?.length > 0 && (
          <>
            <p className={styles.totalCountHeading}>
              {t('ReportGenerator.Report_Column_Count/Sum_Summary')}
            </p>
            <div className={styles.countContainer}>
              <div className={styles.containerOne}>
                {evenDataArray?.map((item: any) => {
                  return (
                    <p className={styles.headingText}>
                      {item[0]?.split('_')?.join(' ').charAt(0).toUpperCase() +
                        item[0]?.split('_')?.join(' ').slice(1)}
                      <span className={styles.totalCountText}>
                        {' '}
                        : {item[1]}
                      </span>
                    </p>
                  )
                })}
              </div>
              <div className={styles.containerOne}>
                {oddDataArray?.slice(1)?.map((item: any) => {
                  return (
                    <p className={styles.headingText}>
                      {item[0]?.split('_')?.join(' ').charAt(0).toUpperCase() +
                        item[0]?.split('_')?.join(' ').slice(1)}
                      <span className={styles.totalCountText}>
                        {' '}
                        : {item[1]}
                      </span>
                    </p>
                  )
                })}
              </div>
            </div>
          </>
        )}

        {tableData?.data?.length > 0 &&
          teableHeaderData?.length > 0 &&
          selectedLevels?.hasOwnProperty(`level1Selected`) &&
          selectedLevels[`level1Selected`].some((item: any) =>
            item.hasOwnProperty(`selected`)
          ) && (
            <div className={styles.tableContainer}>
              <TableV2
                tableHeaderData={teableHeaderData}
                tableRowData={tableData?.data?.length ? tableData?.data : []}
                active={false}
              />
            </div>
          )}
      </form>
    </>
  )
}

export default CreateReport
