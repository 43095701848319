// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.createNewFormList_mainContainer__JOLrx {
  background: rgba(255, 255, 255, 0.7);
  box-shadow: 0px 10px 117px 14px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  margin-right: 20px;
}

.createNewFormList_headerContainer__0QIdq {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.25rem 2.625rem;
  border-bottom: 1px solid #e2e8f0;
}
.createNewFormList_headerContainer__0QIdq .createNewFormList_headerTitle__IQqme {
  font-family: "Poppins";
  font-style: normal;
  font-weight: var(--font-semibold);
  font-size: var(--font-18);
}

.createNewFormList_tableContainer__pAJ7X {
  padding: 2rem 2.625rem;
}

.createNewFormList_customToggleStyle__LVCUY {
  display: table-cell;
}

/* Filter component styles */
.createNewFormList_filterContainer__cd0MI {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  padding: 1.25rem 2.625rem;
  gap: 1rem;
}

/* Filter component styles */
@media (width < 600px) {
  .createNewFormList_filterContainer__cd0MI {
    grid-template-columns: 1fr;
  }
  .createNewFormList_filterContainer__cd0MI .createNewFormList_inputContainer__e6Uwn {
    align-items: flex-start;
    flex-direction: column;
  }
  .createNewFormList_filterContainer__cd0MI .createNewFormList_inputContainer__e6Uwn .createNewFormList_label__qRxnW {
    text-align: left;
    margin-left: 12px;
  }
}`, "",{"version":3,"sources":["webpack://./src/pages/create-new-forms/create-new-form-list/createNewFormList.module.scss"],"names":[],"mappings":"AAAA;EAEE,oCAAA;EACA,mDAAA;EAEA,mBAAA;EACA,kBAAA;AADF;;AAIA;EACE,aAAA;EACA,8BAAA;EACA,mBAAA;EACA,yBAAA;EACA,gCAAA;AADF;AAGE;EACE,sBAAA;EACA,kBAAA;EACA,iCAAA;EACA,yBAAA;AADJ;;AAKA;EACE,sBAAA;AAFF;;AAKA;EACE,mBAAA;AAFF;;AAKA,4BAAA;AACA;EACE,aAAA;EACA,qCAAA;EACA,yBAAA;EACA,SAAA;AAFF;;AAKA,4BAAA;AAEA;EACE;IACE,0BAAA;EAHF;EAKE;IACE,uBAAA;IACA,sBAAA;EAHJ;EAKI;IACE,gBAAA;IACA,iBAAA;EAHN;AACF","sourcesContent":[".mainContainer {\n  // max-width: 1200px;\n  background: rgba(255, 255, 255, 0.7);\n  box-shadow: 0px 10px 117px 14px rgba(0, 0, 0, 0.05);\n  // backdrop-filter: blur(97px);\n  border-radius: 10px;\n  margin-right: 20px;\n}\n\n.headerContainer {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  padding: 1.25rem 2.625rem;\n  border-bottom: 1px solid #e2e8f0;\n\n  .headerTitle {\n    font-family: 'Poppins';\n    font-style: normal;\n    font-weight: var(--font-semibold);\n    font-size: var(--font-18);\n  }\n}\n\n.tableContainer {\n  padding: 2rem 2.625rem;\n}\n\n.customToggleStyle {\n  display: table-cell;\n}\n\n/* Filter component styles */\n.filterContainer {\n  display: grid;\n  grid-template-columns: repeat(3, 1fr);\n  padding: 1.25rem 2.625rem;\n  gap: 1rem;\n}\n\n/* Filter component styles */\n\n@media (width < 600px) {\n  .filterContainer {\n    grid-template-columns: 1fr;\n\n    .inputContainer {\n      align-items: flex-start;\n      flex-direction: column;\n\n      .label {\n        text-align: left;\n        margin-left: 12px;\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"mainContainer": `createNewFormList_mainContainer__JOLrx`,
	"headerContainer": `createNewFormList_headerContainer__0QIdq`,
	"headerTitle": `createNewFormList_headerTitle__IQqme`,
	"tableContainer": `createNewFormList_tableContainer__pAJ7X`,
	"customToggleStyle": `createNewFormList_customToggleStyle__LVCUY`,
	"filterContainer": `createNewFormList_filterContainer__cd0MI`,
	"inputContainer": `createNewFormList_inputContainer__e6Uwn`,
	"label": `createNewFormList_label__qRxnW`
};
export default ___CSS_LOADER_EXPORT___;
