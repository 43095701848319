import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router'
import Select from 'react-select'
import ButtonGroups from '../../../../components/common/button-group/ButtonGroups'
import StatusConfirmationPopup from '../../../../components/common/modal/status-confirmation-popup/StatusConfirmationPopup'
import UserLicensePopup from '../../../../components/common/modal/user-license-popup/UserLicensePopup'
import Pagination from '../../../../components/common/pagination/Pagination'
import Popup from '../../../../components/common/popup/Popup'
import Loader from '../../../../components/common/spinner/Loader'
import {
  InfoIcon,
  SearchButton,
} from '../../../../components/common/svg-components'
import Table from '../../../../components/common/table/Table'
import { colors } from '../../../../constants/color'
import { medicalCenterBranchTableHeader } from '../../../../constants/data'
import { useAppDispatch, useAppSelector } from '../../../../hooks'
import {
  getAllBranch,
  getAllBranchDropDownData,
  updateBranchStatus,
} from '../../../../redux/features/branch/branchAsyncActions'
import { requestGenerator } from '../../../../utils/payloadGenerator'
import makeAnimated from 'react-select/animated'
import {
  clearBranchInfo,
  clearBranchData,
} from '../../../../redux/features/branch/branchSlice'
import DefaultBranchPopup from '../../../../components/common/modal/default-branch-popup/DefaultBranchPopup'
import { getPatientBranchList } from '../../../../redux/features/patient-emr/patient/patientAsyncAction'
import { trimValue } from '../../../../utils/utils'
import styles from './branchList.module.scss'
import StatusConfirmationPopupV4 from '../../../../components/common/modal/status-confirmation-popup/statusConfirmationPopupV4'
import { UPDATE_BRANCH_STATUS } from '../../../../constants/asyncActionsType'
import { useTranslation } from 'react-i18next'
import { t } from 'i18next'

const BranchList = () => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const { isLoading, branchData, allBranchDropDownData } = useAppSelector(
    (state) => state.branch
  )

  /* Pagination Dependency */
  const [dataPerPage, setDataPerPage] = useState<number>(10)
  const [totalPage, setTotalPage] = useState<number>(0)
  const [pageIndex, setPageIndex] = useState<number>(1)
  const [totalData, setTotalData] = useState(0)
  /* Pagination Dependency */
  const filterIsActiveDeptData = allBranchDropDownData?.filter(
    (items: any) => items.is_active === true
  )

  const [showUserLicense, setShowUserLicense] = useState<boolean>(false)
  const [toggle, setToggle] = useState<boolean>(false)
  const [toggleValue, setToggleValue] = useState()
  const [toggleData, setToggleData] = useState<any>({})
  const [confirm, setConfirm] = useState<boolean>(false)
  const [searchBranch, setSearchBranch] = useState<string>('')
  const animatedComponents = makeAnimated()
  const [isDefaultN, setIsDefault] = useState<boolean>(false)
  const [showInfo, setShowInfo] = useState<boolean>(false)
  const [showDefault, setShowDefault] = useState<boolean>(false)
  const [defaultData, setDefaultData] = useState({ label: '', value: '' })
  const [isBranchDefault, setIsBranchDefault] = useState<boolean>(false)
  const { patientBranchList } = useAppSelector((state) => state.patient)

  let filterData = branchData.filter((item: any) => item.is_active === true)

  const branchListData = filterData.map((item: any) => {
    if (item.is_active == true) {
      return { name: item?.name, id: item?._id }
    }
  })

  useEffect(() => {
    let data = {
      // search: searchBranch,
      page: 1,
      pageSize: 1000,
      order_by: { is_default: -1 },
    }
    dispatch(getAllBranchDropDownData(requestGenerator(data)))
  }, [dispatch])

  useEffect(() => {
    dispatch(getPatientBranchList(requestGenerator({})))
  }, [dispatch])

  const pageIndexArray = () => {
    let pageIndexOptions = []
    for (let i = 1; i <= totalPage; i++) {
      pageIndexOptions.push(i)
    }
    return pageIndexOptions
  }

  const pageIndexOptions = pageIndexArray()

  useEffect(() => {
    let data = {
      search: searchBranch,
      page: pageIndex,
      pageSize: dataPerPage,
      order_by: { is_default: -1 },
    }
    dispatch(getAllBranch(requestGenerator(data))).then((result) => {
      setTotalPage(result.payload.lastPage)
      setTotalData(result.payload.total)
    })
    setIsBranchDefault(branchData[0]?.is_default)
    return () => {
      dispatch(clearBranchData())
    }
  }, [dispatch, pageIndex, dataPerPage])

  const handleSearch = () => {
    setPageIndex(1)
    if (searchBranch?.length > 0) {
      let data = {
        search: searchBranch,
        page: 1,
        pageSize: dataPerPage,
        order_by: { is_default: -1 },
      }
      dispatch(getAllBranch(requestGenerator(data))).then((result) => {
        setTotalPage(result.payload.lastPage)
        setTotalData(result.payload.total)
      })
    }
    setIsBranchDefault(branchData[0]?.is_default)
  }

  const handleEdit = (item: any) => {
    navigate('managebranch', {
      state: { id: item?._id },
    })
  }

  const handleActiveBranch = (item: any) => {
    setConfirm(!confirm)
    setToggleData(item)
    setToggle(item.is_active)
    setToggleValue(item?._id)
  }

  const handleUserLicense = () => {
    setShowUserLicense(!showUserLicense)
  }

  const handleDefaultBranch = (item: any) => {
    setShowDefault(!showDefault)
    setDefaultData(item)
    setSearchBranch('')
  }

  const customStyles = {
    option: (provided: any) => ({
      ...provided,
      padding: '10px',
    }),
    optionHover: (provided: any) => ({
      ...provided,
      margin: '0px !important',
    }),
    dropdownIndicator: (base: any) => ({
      ...base,
      cursor: 'pointer',
    }),
  }

  const handleStatus = () => {
    const statusPayload = {
      id: toggleData?._id,
      data: {
        is_active: !toggleData?.is_active,
      },
    }
    let data = {
      search: searchBranch,
      page: pageIndex,
      pageSize: dataPerPage,
      order_by: { is_default: -1 },
    }
    dispatch(updateBranchStatus(requestGenerator(statusPayload))).then((e) => {
      if ((e.type = `${UPDATE_BRANCH_STATUS}/fulfilled`)) {
        setConfirm(false)
        dispatch(getAllBranch(requestGenerator(data))).then((result) => {
          setTotalPage(result.payload.lastPage)
          setTotalData(result.payload.total)
        })
      }
    })
  }

  return (
    <>
      {isLoading && <Loader />}
      {showUserLicense && (
        <Popup
          Children={UserLicensePopup}
          handleClose={() => setShowUserLicense(!showUserLicense)}
        />
      )}
      {confirm && (
        <Popup
          popData="Branch"
          Children={StatusConfirmationPopupV4}
          handleClose={() => {
            setConfirm(false)
          }}
          handleYes={handleStatus}
        />
      )}
      {showDefault && (
        <Popup
          popData={defaultData}
          Children={DefaultBranchPopup}
          handleClose={() => {
            setShowDefault(false)
          }}
          handleNo={() => {
            setDefaultData({ label: '', value: '' })
          }}
          isDefault={isDefaultN}
          setIsDefault={setIsDefault}
        />
      )}

      <div className={styles.branchListContainer}>
        <div className={styles.searchFilter}>
          <div className={styles.searchContainer}>
            <div className={styles.inputFieldContainer}>
              <input
                type="text"
                className={styles.inputSearchContainer}
                placeholder={
                  t('BranchSetup.Search by branch name') ||
                  'Search by branch name'
                }
                onKeyDown={(e: any) => {
                  if (e?.key === 'Enter') {
                    handleSearch()
                  }
                }}
                onChange={(e) => {
                  trimValue(e)
                  setSearchBranch(e.target.value)
                  if (searchBranch !== '' && e.target.value.length === 0) {
                    setPageIndex(1)
                    setDataPerPage(10)
                    let requestData = {
                      search: '',
                      page: 1,
                      pageSize: 10,
                      order_by: { is_default: -1 },
                    }
                    dispatch(getAllBranch(requestGenerator(requestData))).then(
                      (result) => {
                        setTotalPage(result.payload.lastPage)
                        setTotalData(result.payload.total)
                      }
                    )
                  }
                }}
                value={searchBranch}
              />
              <SearchButton
                handleClick={() => {
                  handleSearch()
                }}
                customClass={styles.inputSearchButton}
              />
            </div>
          </div>
          <div className={styles.dropDownContainer}>
            <p className={styles.dropDownTitle}>
              {t('BranchSetup.Default Branch')}
            </p>
            {patientBranchList?.defaultBranch?.name === undefined ? (
              <>
                <Select
                  options={filterIsActiveDeptData?.map((item: any) => ({
                    label: item?.name,
                    value: item?._id,
                  }))}
                  value={defaultData.label !== '' ? [defaultData] : []}
                  className={styles.selectInputField}
                  isSearchable={true}
                  placeholder={t('BranchSetup.Select')}
                  components={animatedComponents}
                  onChange={handleDefaultBranch}
                  onFocus={() => {
                    if (searchBranch.length > 0) {
                      setSearchBranch('')
                    }
                  }}
                  isDisabled={isDefaultN}
                  maxMenuHeight={200}
                  styles={customStyles}
                />
                <div className={styles.infoContainer}>
                  <InfoIcon
                    fillColor={colors.grey2}
                    mouseEnter={() => setShowInfo(true)}
                    mouseLeave={() => setShowInfo(false)}
                    customClass={styles.iconStyle}
                  />
                  {showInfo && (
                    <p className={styles.infoText}>
                      {t('BranchSetup.Default Branch Info')}
                    </p>
                  )}
                </div>
              </>
            ) : (
              <>
                <p className={styles.defaultBranch}>
                  {patientBranchList?.defaultBranch?.name}
                </p>
                <div className={styles.infoContainer}>
                  <InfoIcon
                    fillColor={colors.grey2}
                    mouseEnter={() => setShowInfo(true)}
                    mouseLeave={() => setShowInfo(false)}
                    customClass={styles.iconStyle}
                  />
                  {showInfo && (
                    <p className={styles.infoText}>
                      {t('BranchSetup.Default Branch Info')}
                    </p>
                  )}
                </div>
              </>
            )}
          </div>

          <ButtonGroups
            titleOne={t('BranchSetup.Add Branch')}
            titleTwo={t('BranchSetup.User License')}
            handleClickOne={() => {
              navigate('managebranch')
              dispatch(clearBranchInfo())
            }}
            handleClickTwo={() => handleUserLicense()}
          />
        </div>
        <Table
          tableHeaderData={medicalCenterBranchTableHeader}
          tableRowData={branchData}
          handleAction={handleEdit}
          handleActiveMC={handleActiveBranch}
          toogleValue={toggleValue}
        />
        {totalData > 10 && (
          <Pagination
            setDataPerPage={setDataPerPage}
            pageIndexOptions={pageIndexOptions}
            pageIndex={pageIndex}
            setPageIndex={setPageIndex}
            pageSize={dataPerPage}
          />
        )}
      </div>
    </>
  )
}

export default BranchList
