import { EditIcon } from '../../components/common/svg-components'
import styles from './tableData.module.scss'
import { t } from 'i18next'

export const radiologyViewTestProfileHeaderData: any = [
  {
    Header: t('LabJob.PROFILE_ID'),
    accessor: (row: any) => {
      return row?.profile_no ? row?.profile_no : '-'
    },
  },
  {
    Header: t('LabJob.PROFILE_NAME'),
    accessor: 'name',
  },
  {
    Header: t('LabJob.PROFILE_TEST'),
    Cell: (props: any) => {
      return (
        <>
          {props?.row?.original?.radiologytest_ids?.length > 0 ? (
            <span
              className={styles.blueLinkText}
              onClick={() => {
                props?.onOpen(props?.row?.original)
              }}
            >
              {t('Common.View')}
            </span>
          ) : (
            '-'
          )}
        </>
      )
    },
  },
  {
    Header: t('LabConfiguration.PROFILE_AMOUNT'),
    accessor: 'package_amount',
  },
  {
    Header: t('DocUploadHeader.ACTION'),
    Cell: (props: any) => {
      return (
        <EditIcon
          handleClick={() => props?.onRowClick(props?.row?.original?._id)}
        />
      )
    },
  },
]

export const testProfileDummyData = [
  {
    _id: '-',
    name: '-',
    package_amount: '-',
  },
]
