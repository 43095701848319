import { useAppDispatch } from '../../hooks'
import { useNavigate } from 'react-router-dom'
import Button from '../../components/common/button/Button'
import {
  addIpdTimelineNote,
  ipdHistory,
} from '../../redux/features/ipd/ipdAsyncActions'
import { requestGenerator } from '../../utils/payloadGenerator'
import moment from 'moment'
import styles from '../../pages/ipd/ipd-dashboard/ipdDashboard.module.scss'
import { t } from 'i18next'

export const openCaseIpdDashboardTableHeaderData: any = [
  {
    Header: t('RcptTableHeader.FILE NO'),
    accessor: (row: any) => {
      return row?.emr_no ?? '-'
    },
  },
  {
    Header: t('IPDHistory.CASE NO'),
    accessor: (row: any) => {
      return row?.case_no ?? '-'
    },
  },
  {
    Header: t('PatientHistoryFormHeader.PATIENT NAME'),
    accessor: (row: any) => {
      return row?.patient_name ?? '-'
    },
  },
  {
    Header: t('IPDDashboard.CASE DESCRIPTION'),
    disableSortBy: true,
    Cell: (props: any) => {
      return (
        <>
          {props?.row?.original?.case_description ? (
            <span
              className={styles.viewLink}
              onClick={() => props.onClick(props?.row?.original)}
            >
              {t('Common.View')}
            </span>
          ) : (
            '-'
          )}
        </>
      )
    },
  },
  {
    Header: t('InsuranceHeader.DEPARTMENT'),
    accessor: (row: any) => {
      return row?.department_name ?? '-'
    },
  },
  {
    Header: t('IPDDashboard.WARD'),
    accessor: (row: any) => {
      return row?.ward_name ?? '-'
    },
  },
  {
    Header: t('IPDDashboard.ROOM'),
    accessor: (row: any) => {
      return row?.room_name ?? '-'
    },
  },
  {
    Header: t('IPDDashboard.BED'),
    accessor: (row: any) => {
      return row?.bed_name ?? '-'
    },
  },
  {
    Header: t('IPDDashboard.ATTEND'),
    Cell: (props: any) => {
      const dispatch = useAppDispatch()
      const handleNotesDispatch = () => {
        let payloadData = {
          ipd_id: props?.row?.original?._id,
          patient_id: props?.row?.original?.patient_id,
          date: moment().format('DD/MM/YYYY'),
          notes: [],
        }

        dispatch(addIpdTimelineNote(requestGenerator(payloadData))).then(
          (res) => {
            if (res.type === 'ipd/addIpdTimelineNotes/fulfilled') {
              navigate('/ipd/createipdmainlayout/consultation', {
                state: {
                  patient_id: props?.row?.original?.patient_id,
                  ipd_id: props?.row?.original?._id,
                },
              })
            }
          }
        )
      }

      const navigate = useNavigate()
      return (
        <Button
          title={t('IPDDashboard.Attend') || 'Attend'}
          customClass={styles.buttonOutline}
          type="button"
          handleClick={() => handleNotesDispatch()}
        />
      )
    },
  },
  {
    Header: t('IPDDashboard.DISCHARGE'),
    Cell: (props: any) => {
      return (
        <>
          {props?.row?.original?.discharged === true ? (
            <span>{t('Common.Yes')}</span>
          ) : props?.row?.original?.discharged === false ? (
            <span>{t('Common.No')}</span>
          ) : (
            '-'
          )}
        </>
      )
    },
  },
  {
    Header: t('IPDDashboard.IPD HISTORY'),
    disableSortBy: true,
    Cell: (props: any) => {
      const dispatch = useAppDispatch()
      const handleIpdHistory = () => {
        let data = {
          ipd_case_id: props?.row?.original?._id,
        }
        dispatch(ipdHistory(requestGenerator(data)))
      }
      return (
        <span
          className={styles.viewLink}
          onClick={() => (handleIpdHistory(), props.onRowClick())}
        >
          {t('Common.View')}
        </span>
      )
    },
  },
]

export const closeCaseIpdDashboardTableHeaderData: any = [
  {
    Header: t('RcptTableHeader.FILE NO'),
    Cell: (props: any) => {
      return (
        <>
          {props?.row?.original?.emr_no ? (
            <span>{props?.row?.original?.emr_no}</span>
          ) : (
            '-'
          )}
        </>
      )
    },
  },
  {
    Header: t('IPDHistory.CASE NO'),
    Cell: (props: any) => {
      return (
        <>
          {props?.row?.original?.case_no ? (
            <span>{props?.row?.original?.case_no}</span>
          ) : (
            '-'
          )}
        </>
      )
    },
  },
  {
    Header: t('PatientHistoryFormHeader.PATIENT NAME'),
    Cell: (props: any) => {
      return (
        <>
          {props?.row?.original?.patient_name ? (
            <span>{props?.row?.original?.patient_name}</span>
          ) : (
            '-'
          )}
        </>
      )
    },
  },
  {
    Header: t('IPDDashboard.CASE DESCRIPTION'),
    disableSortBy: true,
    Cell: (props: any) => {
      return (
        <>
          {props?.row?.original?.case_description ? (
            <span
              className={styles.viewLink}
              onClick={() => props.onClick(props?.row?.original)}
            >
              {t('Common.View')}
            </span>
          ) : (
            '-'
          )}
        </>
      )
    },
  },
  {
    Header: t('InsuranceHeader.DEPARTMENT'),
    Cell: (props: any) => {
      return (
        <>
          {props?.row?.original?.department_name ? (
            <span>{props?.row?.original?.department_name}</span>
          ) : (
            '-'
          )}
        </>
      )
    },
  },
  {
    Header: t('IPDDashboard.WARD'),
    Cell: (props: any) => {
      return (
        <>
          {props?.row?.original?.ward_name ? (
            <span>{props?.row?.original?.ward_name}</span>
          ) : (
            '-'
          )}
        </>
      )
    },
  },
  {
    Header: t('IPDDashboard.ROOM'),
    Cell: (props: any) => {
      return (
        <>
          {props?.row?.original?.room_name ? (
            <span>{props?.row?.original?.room_name}</span>
          ) : (
            '-'
          )}
        </>
      )
    },
  },
  {
    Header: t('IPDDashboard.BED'),
    Cell: (props: any) => {
      return (
        <>
          {props?.row?.original?.bed_name ? (
            <span>{props?.row?.original?.bed_name}</span>
          ) : (
            '-'
          )}
        </>
      )
    },
  },
  {
    Header: t('IPDDashboard.DISCHARGE'),
    Cell: (props: any) => {
      return (
        <>
          {props?.row?.original?.discharged === true ? (
            <span>{t('Common.Yes')}</span>
          ) : props?.row?.original?.discharged === false ? (
            <span>{t('Common.No')}</span>
          ) : (
            '-'
          )}
        </>
      )
    },
  },
  {
    Header: t('IPDDashboard.IPD HISTORY'),
    disableSortBy: true,
    Cell: (props: any) => {
      const dispatch = useAppDispatch()

      const handleIpdHistory = () => {
        let data = {
          ipd_case_id: props?.row?.original?._id,
        }
        dispatch(ipdHistory(requestGenerator(data)))
      }
      return (
        <span
          className={styles.viewLink}
          onClick={() => (handleIpdHistory(), props.onRowClick())}
        >
          {t('Common.View')}
        </span>
      )
    },
  },
]
