import { t } from 'i18next'
import {
  ITEM_NAME,
  ITEM_QTY,
  REQUEST_SOURCE,
  SOURCE_BRANCH,
  SOURCE_DEPT,
  STORE,
  UNIT_TYPE,
} from '../constants/constant'

export const addInventoryRequestValidators = {
  [ITEM_NAME]: {
    required: t('INVRequest.SelectItem'),
  },
  [ITEM_QTY]: {
    required: t('INVRequest.Enterqty'),
    validate: {
      handleValue: (value: any) => {
        if (Number(value) < 0) {
          return t('INVRequest.EnterPositive')
        } else if (Number(value) === 0) {
          return t('INVRequest.EnterGreater')
        }
      },
    },
  },
  [UNIT_TYPE]: {
    required: t('INVRequest.SelectUnitType'),
  },
  [STORE]: {
    required: t('INVRequest.SelectStore'),
  },
  [REQUEST_SOURCE]: {
    required: t('INVRequest.SelectSource'),
  },
  [SOURCE_DEPT]: {
    required: t('DepartmentSetup.Dept validation'),
  },
  [SOURCE_BRANCH]: {
    required: t('INVRequest.SelectBranchStore'),
  },
}
