import { useNavigate } from "react-router-dom";

import styles from "./tableData.module.scss";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { getPatientEmrById } from "../../redux/features/patient-emr/patient/patientAsyncAction";
import { requestGenerator } from "../../utils/payloadGenerator";
import { getLastInvoice } from "../../redux/features/invoice-module/invoiceAsynActions";
import { setPatientData } from "../../redux/features/invoice-module/invoiceSlice";
import agentAptImg from "../../assets/images/agentAptimg.png";
import moment from "moment";
import {
  createDentistInvoiceDraftStatus,
  createInvoiceDraftStatus,
} from "../../redux/features/receptionist/receptionistAsyncActions";
import {
  CREATE_DENTIST_INVOICE_DRAFT_TYPE,
  CREATE_INVOICE_DRAFT_TYPE,
} from "../asyncActionsType";
// import { translate } from '../../i18n/i18Config'
import { t } from "i18next";
import MasterAppointmentStatusDropdown from "../../components/common/status-dropdown/master-appointment-status-dropdown/MasterAppoinmentStatusDropdown";
import { useState } from "react";
import {
  getAllTodayPatient,
  updateAppointmentStatus,
} from "../../redux/features/appointment/appointmentAsyncActions";

export const todayAppointmentHeaderData: any = [
  {
    Header: " ",
    Cell: ({ row }: any) => {
      let isAgent = row?.original?.booked_by_agent;
      return isAgent ? (
        <img
          src={agentAptImg}
          alt="agent appointmetn"
          style={{ display: "inline-block", cursor: "pointer" }}
          title={row?.original?.booked_by_user_name}
        />
      ) : (
        ""
      );
    },
  },
  {
    Header: t("RcptTableHeader.FILE NO"),
    accessor: "patient_emr_no",
    Cell: ({ row }: any) => {
      const objectId = row?.original;
      const dispatch = useAppDispatch();
      const navigate = useNavigate();
      const handleEmrRecord = (item: any) => {
        let dataPayload = {
          id: item,
        };
        dispatch(getPatientEmrById(requestGenerator(dataPayload))).then((e) => {
          if (e.type === "patient/getPatientEmrById/fulfilled") {
            navigate("/patientemr");
          }
        });
      };
      return (
        <span
          onClick={() =>
            objectId?.patient_emr_no
              ? handleEmrRecord(row?.original?.patient_id)
              : {}
          }
          style={{ cursor: "pointer", color: "#0e26a3" }}
        >
          {objectId?.patient_emr_no ? objectId?.patient_emr_no : "-"}
        </span>
      );
    },
  },
  {
    Header: t("RcptTableHeader.DATE"),
    Cell: ({ row }: any) => {
      const originalDate = row?.original?.appointment_timestamp;
      const formattedDate = moment(originalDate).format("DD MMM YYYY");
      return <>{formattedDate}</>;
    },
  },
  {
    Header: t("RcptTableHeader.TYPE"),
    accessor: "appointment_type",
  },

  {
    Header: t("RcptTableHeader.PATIENT"),
    accessor: "patient_name",
  },
  {
    Header: t("RcptTableHeader.MOBILE NO"),
    accessor: "patinet_phone",
  },
  {
    Header: t("RcptTableHeader.DOCTOR NAME"),
    accessor: "doctor_name",
  },
  {
    Header: t("RcptTableHeader.TIME"),
    Cell: ({ row }: any) => {
      let start_time = row?.original?.appointment_time;
      let duration = row?.original?.appointment_duration;
      const end_time = moment(start_time, "HH:mm")
        .add(duration, "minutes")
        .format("HH:mm");
      return <>{`${start_time} to ${end_time}`}</>;
    },
  },
  {
    Header: t("RcptTableHeader.STATUS"),
    Cell: ({ row }: any) => {
      let appointment_id = row?.original?.appointment_id;
      const [showOption, setShowOption] = useState<boolean>(false);
      const { todayAppointmentReq } = useAppSelector((state) => state.patient);

      const dispatch = useAppDispatch();
      const handleStatus = (item: any) => {
        let status = item.label.toUpperCase();
        if (
          status === "RESCHEDULED" ||
          status === "INPROGRESS" ||
          status === "COMPLETED"
        ) {
          return;
        } else {
          setShowOption(!showOption);
          let reqData = {
            appointment_id: appointment_id,
            status: status.replace(/\s/g, ""),
          };
          dispatch(updateAppointmentStatus(requestGenerator(reqData))).then(
            (e) => {
              if (e.type === "appointment/updateAppointmentStatus/fulfilled") {
                dispatch(
                  getAllTodayPatient(requestGenerator(todayAppointmentReq))
                );
              }
            }
          );
        }
      };
      return (
        <div className={styles.actionIconContainer}>
          <MasterAppointmentStatusDropdown
            appointmentStatus={row?.original?.status}
            isTodayAppointment={true}
            showOption={showOption}
            setShowOption={setShowOption}
            handleStatus={(item: any) => handleStatus(item)}
            isDisable={
              row?.original?.status === "NOSHOW" || 
              row?.original?.status === "CANCELLED"
            }
          />
        </div>
      );
    },
  },
  {
    Header: t("RcptTableHeader.INSURANCE"),
    Cell: ({ row }: any) => {
      const dispatch = useAppDispatch();
      const navigate = useNavigate();
      const handleInvoice = (patientAppt: any) => {
        let invoiceDataPayload = {
          patient_id: patientAppt?.patient_id,
          type: "DIAGNOSIS",
          diagnosis_id: patientAppt?.diagnosis_id
            ? patientAppt?.diagnosis_id
            : null,
        };
        dispatch(getLastInvoice(requestGenerator(invoiceDataPayload))).then(
          (e) => {
            if (e.type === "invoice/getLastInvoice/fulfilled") {
              navigate("/invoice/information");

              if (e.payload) {
                dispatch(setPatientData(e.payload));
              } else {
                handlePatientEmrData(patientAppt);
              }
            }
          }
        );
      };
      const handlePatientEmrData = (item: any) => {
        let dataPayload = {
          id: item?.patient_id,
        };

        dispatch(getPatientEmrById(requestGenerator(dataPayload))).then((e) => {
          if (e.type === "patient/getPatientEmrById/fulfilled") {
            const patientObject = {
              _id: null,
              status: "DRAFT",
              diagnosis_services: [],
              patient_id: e?.payload?._id,
              paid_amount: 0,
              total_amount: 0,
              outstanding_amount: 0,
              discount: 0,
              insurance_claim_amount: 0,
              lab_tests: [],
              radiology_tests: [],
              pharmacy_services: [],
              patient_default_branch_id: e?.payload?.patient_default_branch_id
                ? e?.payload?.patient_default_branch_id
                : "",
              emr_no: e?.payload?.emr_no,
              name: e?.payload?.name,
              advance_amount: e?.payload?.advance_amount
                ? e?.payload?.advance_amount
                : 0,
              patient_primary_Doctor: "",
              phone: e?.payload?.phone,
              profile_pic: e?.payload?.patient_pic
                ? e?.payload?.patient_pic
                : "",
              national_id: e?.payload?.national_id
                ? e?.payload?.national_id
                : "",
              email: e?.payload?.email ? e?.payload?.email : "",
            };
            dispatch(setPatientData(patientObject));
          }
        });
      };
      return row?.original?.insurance_status ? (
        <span
          className={styles[row?.original?.insurance_status]}
          onClick={() =>
            row?.original?.insurance_status === "PENDING"
              ? handleInvoice(row?.original)
              : {}
          }
        >
          {row?.original?.insurance_status}
        </span>
      ) : (
        "-"
      );
    },
  },
  {
    Header: t("RcptTableHeader.PRESCRIPTION"),
    Cell: (props: any) => {
      return <p>-</p>;
    },
  },
  {
    Header: t("RcptTableHeader.INVOICE"),
    Cell: ({ row }: any) => {
      const navigate = useNavigate();
      const dispatch = useAppDispatch();
      const handleToBeGenerate = (patientAppt: any) => {
        if (patientAppt?.diagnosis_id) {
          dispatch(
            createInvoiceDraftStatus(
              requestGenerator({
                diagnosis_id: patientAppt?.diagnosis_id,
              })
            )
          ).then((e) => {
            if (e.type === `${CREATE_INVOICE_DRAFT_TYPE}/fulfilled`) {
              handleInvoice(patientAppt);
            }
          });
        } else if (patientAppt?.dental_diagnosis_id) {
          dispatch(
            createDentistInvoiceDraftStatus(
              requestGenerator({
                diagnosis_id: patientAppt?.dental_diagnosis_id,
              })
            )
          ).then((e) => {
            if (e.type === `${CREATE_DENTIST_INVOICE_DRAFT_TYPE}/fulfilled`) {
              handleInvoice(patientAppt);
            }
          });
        }
      };
      const handleInvoice = (patientAppt: any) => {
        let invoiceDataPayload = {
          patient_id: patientAppt?.patient_id,
          type: patientAppt?.diagnosis_id ? "DIAGNOSIS" : "DENTAL_DIAGNOSIS",
          diagnosis_id: patientAppt?.diagnosis_id
            ? patientAppt?.diagnosis_id
            : undefined,
          dental_diagnosis_id: patientAppt?.dental_diagnosis_id
            ? patientAppt?.dental_diagnosis_id
            : undefined,
        };
        dispatch(getLastInvoice(requestGenerator(invoiceDataPayload))).then(
          (e) => {
            if (e.type === "invoice/getLastInvoice/fulfilled") {
              // setModelOpenClose(false)
              navigate("/invoice/information");
              if (e.payload) {
                dispatch(
                  setPatientData({
                    ...e.payload,
                    type: patientAppt?.diagnosis_id
                      ? "DIAGNOSIS"
                      : "DENTAL_DIAGNOSIS",
                  })
                );
              } else {
                handlePatientEmrData(patientAppt);
              }
            }
          }
        );
      };
      const handlePatientEmrData = (item: any) => {
        let dataPayload = {
          id: item?.patient_id,
        };

        dispatch(getPatientEmrById(requestGenerator(dataPayload))).then((e) => {
          if (e.type === "patient/getPatientEmrById/fulfilled") {
            const patientObject = {
              _id: null,
              status: "DRAFT",
              diagnosis_services: [],
              patient_id: e?.payload?._id,
              paid_amount: 0,
              total_amount: 0,
              outstanding_amount: 0,
              discount: 0,
              insurance_claim_amount: 0,
              lab_tests: [],
              radiology_tests: [],
              pharmacy_services: [],
              patient_default_branch_id: e?.payload?.patient_default_branch_id
                ? e?.payload?.patient_default_branch_id
                : "",
              emr_no: e?.payload?.emr_no,
              name: e?.payload?.name,
              advance_amount: e?.payload?.advance_amount
                ? e?.payload?.advance_amount
                : 0,
              patient_primary_Doctor: "",
              phone: e?.payload?.phone,
              profile_pic: e?.payload?.patient_pic
                ? e?.payload?.patient_pic
                : "",
              national_id: e?.payload?.national_id
                ? e?.payload?.national_id
                : "",
              email: e?.payload?.email ? e?.payload?.email : "",
            };
            dispatch(setPatientData(patientObject));
          }
        });
      };
      return row?.original?.invoice_status ? (
        <span
          className={
            row?.original?.invoice_status === "TO BE GENERATE"
              ? styles.toBeGenerated
              : styles[row?.original?.invoice_status]
          }
          onClick={() =>
            row?.original?.invoice_status === "TO BE GENERATE"
              ? handleToBeGenerate(row?.original)
              : row?.original?.invoice_status === "PENDING"
              ? handleInvoice(row?.original)
              : {}
          }
        >
          {row?.original?.invoice_status}
        </span>
      ) : (
        "-"
      );
    },
  },
];
