// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* / Box sizing rules / */
*,
*::before,
*::after {
  box-sizing: border-box;
}

/* / Remove default padding / */
ul[class],
ol[class] {
  padding: 0;
}

/* / Remove default margin / */
body,
h1,
h2,
h3,
h4,
p,
ul[class],
ol[class],
li,
figure,
figcaption,
blockquote,
dl,
dd {
  margin: 0;
}

/* / Set core body defaults / */
body {
  min-height: 100vh;
  scroll-behavior: smooth;
  text-rendering: optimizeSpeed;
  line-height: 1.5;
}

/* / Remove list styles on ul, ol elements with a class attribute / */
ul[class],
ol[class] {
  list-style: none;
}

/* / A elements that don't have a class get default styles / */
a:not([class]) {
  text-decoration-skip-ink: auto;
}

/* / Make images easier to work with / */
img {
  max-width: 100%;
  display: block;
}

/* / Natural flow and rhythm in articles by default / */
article > * + * {
  margin-top: 1em;
}

/* / Remove all animations and transitions for people that prefer not to see them / */
@media (prefers-reduced-motion: reduce) {
  * {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
}

a {
  text-decoration: none;
}
a:hover {
  text-decoration: none;
}
`, "",{"version":3,"sources":["webpack://./src/styles/reset-css.css"],"names":[],"mappings":"AAAA,yBAAyB;AACzB;;;EAGE,sBAAsB;AACxB;;AAEA,+BAA+B;AAC/B;;EAEE,UAAU;AACZ;;AAEA,8BAA8B;AAC9B;;;;;;;;;;;;;;EAcE,SAAS;AACX;;AAEA,+BAA+B;AAC/B;EACE,iBAAiB;EACjB,uBAAuB;EACvB,6BAA6B;EAC7B,gBAAgB;AAClB;;AAEA,qEAAqE;AACrE;;EAEE,gBAAgB;AAClB;;AAEA,8DAA8D;AAC9D;EACE,8BAA8B;AAChC;;AAEA,wCAAwC;AACxC;EACE,eAAe;EACf,cAAc;AAChB;;AAEA,uDAAuD;AACvD;EACE,eAAe;AACjB;;AAEA,qFAAqF;AACrF;EACE;IACE,qCAAqC;IACrC,uCAAuC;IACvC,sCAAsC;IACtC,gCAAgC;EAClC;AACF;;AAEA;EACE,qBAAqB;AACvB;AACA;EACE,qBAAqB;AACvB","sourcesContent":["/* / Box sizing rules / */\n*,\n*::before,\n*::after {\n  box-sizing: border-box;\n}\n\n/* / Remove default padding / */\nul[class],\nol[class] {\n  padding: 0;\n}\n\n/* / Remove default margin / */\nbody,\nh1,\nh2,\nh3,\nh4,\np,\nul[class],\nol[class],\nli,\nfigure,\nfigcaption,\nblockquote,\ndl,\ndd {\n  margin: 0;\n}\n\n/* / Set core body defaults / */\nbody {\n  min-height: 100vh;\n  scroll-behavior: smooth;\n  text-rendering: optimizeSpeed;\n  line-height: 1.5;\n}\n\n/* / Remove list styles on ul, ol elements with a class attribute / */\nul[class],\nol[class] {\n  list-style: none;\n}\n\n/* / A elements that don't have a class get default styles / */\na:not([class]) {\n  text-decoration-skip-ink: auto;\n}\n\n/* / Make images easier to work with / */\nimg {\n  max-width: 100%;\n  display: block;\n}\n\n/* / Natural flow and rhythm in articles by default / */\narticle > * + * {\n  margin-top: 1em;\n}\n\n/* / Remove all animations and transitions for people that prefer not to see them / */\n@media (prefers-reduced-motion: reduce) {\n  * {\n    animation-duration: 0.01ms !important;\n    animation-iteration-count: 1 !important;\n    transition-duration: 0.01ms !important;\n    scroll-behavior: auto !important;\n  }\n}\n\na {\n  text-decoration: none;\n}\na:hover {\n  text-decoration: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
