import { createSlice } from '@reduxjs/toolkit'
import {
  addPayrolls,
  deletePayrolls,
  getAllPayroll,
  getPayrollById,
  updatePayrollStatus,
  getAllSearchUserList,
  getSalary,
  createPayroll,
} from './payrollAsyncActions'

export interface IPayroll {
  isLoading: boolean
  allPayrollData: []
  allSalary: {}
  payrollDetail: {}
  addPayrollData: ''
  editPayrollData: {}
  error: any
  isStatusUpdated: boolean
  getAllPayrollPaylod: {}
  employeeData: []
  allPayrollListDataObject: {}
  payrollLoader: boolean
}

export const initialState: IPayroll = {
  isLoading: false,
  allPayrollData: [],
  allSalary: [],
  payrollDetail: {},
  addPayrollData: '',
  editPayrollData: {},
  isStatusUpdated: true,
  error: null,
  getAllPayrollPaylod: {},
  employeeData: [],
  allPayrollListDataObject: {},
  payrollLoader: false,
}

export const payrollSlice = createSlice({
  name: 'payroll',
  initialState,
  reducers: {
    clearPayrollData: (state) => {
      state.allPayrollData = []
      state.allSalary = []
      state.payrollDetail = {}
      state.editPayrollData = {}
      state.getAllPayrollPaylod = {}
    },
    getEditPayrollData: (state, action) => {
      state.editPayrollData = action.payload
    },
    clearEditPayrollData: (state) => {
      state.editPayrollData = {}
    },
    getAllPayrollPayloadData: (state, action) => {
      state.getAllPayrollPaylod = action.payload?.data
    },
  },

  extraReducers(builder) {
    // GET ALL Payroll
    builder
      .addCase(getAllPayroll.pending, (state) => {
        state.isLoading = true
        state.isStatusUpdated = false
      })
      .addCase(getAllPayroll.fulfilled, (state, action) => {
        state.isLoading = false
        state.isStatusUpdated = false
        state.allPayrollData = action.payload?.data
        state.allPayrollListDataObject = action?.payload
      })
      .addCase(getAllPayroll.rejected, (state, error) => {
        state.isLoading = false
        state.error = error
        state.isStatusUpdated = false
      })
    // GET SALARY
    builder
      .addCase(getSalary.pending, (state) => {
        state.isLoading = true
        state.isStatusUpdated = false
      })
      .addCase(getSalary.fulfilled, (state, action) => {
        state.isLoading = false
        state.isStatusUpdated = false
        state.allSalary = action.payload
      })
      .addCase(getSalary.rejected, (state, error) => {
        state.isLoading = false
        state.error = error
        state.isStatusUpdated = false
      })

    // ADD
    builder
      .addCase(addPayrolls.pending, (state) => {
        state.isLoading = true
      })
      .addCase(addPayrolls.fulfilled, (state, action) => {
        state.isLoading = false
        state.addPayrollData = action.payload
      })
      .addCase(addPayrolls.rejected, (state, error) => {
        state.isLoading = false
        state.error = error
      })
    //Edit
    builder
      .addCase(getPayrollById.pending, (state) => {
        state.isLoading = true
      })
      .addCase(getPayrollById.fulfilled, (state, action) => {
        state.isLoading = false
        state.payrollDetail = action.payload
      })
      .addCase(getPayrollById.rejected, (state, error) => {
        state.isLoading = false
        state.error = error
      })
    // UPDATE Payroll STATUS
    builder
      .addCase(updatePayrollStatus.pending, (state) => {
        state.isLoading = true
        state.isStatusUpdated = false
      })
      .addCase(updatePayrollStatus.fulfilled, (state) => {
        state.isLoading = false
        state.isStatusUpdated = true
      })
      .addCase(updatePayrollStatus.rejected, (state, error) => {
        state.isLoading = false
        state.isStatusUpdated = false
        state.error = error
      })
    //delete payroll
    builder
      .addCase(deletePayrolls.pending, (state) => {
        state.isLoading = true
      })
      .addCase(deletePayrolls.fulfilled, (state) => {
        state.isLoading = false
      })
      .addCase(deletePayrolls.rejected, (state, error) => {
        state.isLoading = false
        state.error = error
      })

    // GET ALL EMPLOYEE
    builder.addCase(getAllSearchUserList.pending, (state) => {
      state.isLoading = true
      state.employeeData = []
    })
    builder.addCase(getAllSearchUserList.fulfilled, (state, action) => {
      state.isLoading = false
      state.employeeData = action.payload?.data
    })
    builder.addCase(getAllSearchUserList.rejected, (state, error) => {
      state.isLoading = false
      state.error = error
    })

    // CREATE PAYROLL

    builder.addCase(createPayroll.pending, (state) => {
      state.payrollLoader = true
    })
    builder.addCase(createPayroll.fulfilled, (state) => {
      state.payrollLoader = false
    })
    builder.addCase(createPayroll.rejected, (state, error) => {
      state.payrollLoader = false
      state.error = error
    })
  },
})
export const {
  clearPayrollData,
  getEditPayrollData,
  clearEditPayrollData,
  getAllPayrollPayloadData,
} = payrollSlice.actions
export default payrollSlice.reducer
