import { IcomparepopupTableData } from '../../../../interfaces/interfaces'
import styles from './comparePopup.module.scss'
import { t } from 'i18next'

// View Jobs Compare Popup Table
export const comparePopupTableHeaderData: any = [
  {
    Header: t('RcptTableHeader.DATE'),
    accessor: '_date',
  },
  {
    Header: t('RadiologyConfiguration.TEST_GROUP'),
    accessor: 'test_group',
  },
  {
    Header: t('LabJob.TEST'),
    accessor: '_test',
  },
  {
    Header: t('RcptTableHeader.RESULTS'),
    accessor: '_results',
    Cell: (props: any) => {
      return (
        <>
          <div className={styles.resultViewContainer}>
            <span className={styles.resultView} onClick={() => props.onClick()}>
              {' '}
              {t('Common.View')}
            </span>
            <label className={styles.checkboxContainer}>
              <input type="checkbox" className={styles.checkboxField} />
              <span className={styles.checkboxLabel}></span>
            </label>
          </div>
        </>
      )
    },
  },
]

export const comparePopupTableDummyData: any[] = [
  {
    _date: '29-Jun-2022',
    test_group: 'Test Group',
    _test: 'WBC',
    _results: 'view',
  },
  {
    _date: '29-Jun-2022',
    test_group: '-',
    _test: 'RBC',
    _results: 'view',
  },
  {
    _date: '29-Jun-2022',
    test_group: '-',
    _test: 'Hb',
    _results: 'view',
  },
  {
    _date: '29-Jun-2022',
    test_group: '-',
    _test: 'X-ray',
    _results: 'view',
  },
  {
    _date: '29-Jun-2022',
    test_group: 'Test Group',
    _test: 'WBC',
    _results: 'view',
  },
  {
    _date: '29-Jun-2022',
    test_group: '-',
    _test: 'RBC',
    _results: 'view',
  },
]
