// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.callcenterlayout_mainContainer__VgNgw {
  display: flex;
}
.callcenterlayout_mainContainer__VgNgw .callcenterlayout_tabContainer__KyvTN {
  width: 95%;
}
.callcenterlayout_mainContainer__VgNgw .callcenterlayout_floatingBarContainer__Zrdtp {
  position: sticky;
  top: 30px;
}`, "",{"version":3,"sources":["webpack://./src/pages/call-center-admin/admin/callcenterlayout.module.scss"],"names":[],"mappings":"AACA;EACE,aAAA;AAAF;AACE;EACE,UAAA;AACJ;AACE;EACE,gBAAA;EACA,SAAA;AACJ","sourcesContent":["@import \"../../../styles/mixin.scss\";\n.mainContainer {\n  display: flex;\n  .tabContainer {\n    width: 95%;\n  }\n  .floatingBarContainer {\n    position: sticky;\n    top: 30px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"mainContainer": `callcenterlayout_mainContainer__VgNgw`,
	"tabContainer": `callcenterlayout_tabContainer__KyvTN`,
	"floatingBarContainer": `callcenterlayout_floatingBarContainer__Zrdtp`
};
export default ___CSS_LOADER_EXPORT___;
