import { FC } from 'react'
import ToolTip from '../../../common/toolTip/ToolTip'
import { CloseIcon, QrcodeImgDot, CopyIcon } from '../../svg-components'
import { colors } from '../../../../constants/color'
import styles from './QrCodeModal.module.scss'
import { useAppSelector } from '../../../../hooks'
import { hanleCopy } from '../../../../utils/utils'
import { useTranslation } from 'react-i18next'
// import Qr from './Qr'
interface IQrcodeModalProps {
  heading?: string
  message?: string
  handleClose?: (e?: React.MouseEvent<HTMLElement>) => void
  popData?: string | any
}

const QrCodeModal: FC<IQrcodeModalProps> = ({
  heading,
  message,
  handleClose,
  popData,
}) => {
  const { userData } = useAppSelector((state) => state.login)
  const { t } = useTranslation()
  const { toolTipMessage } = useAppSelector((state) => state.toast)
  const mcInfoText = `{"mc_id":"${userData.mc_id}","mc_name":"${userData.mc_name}"}`
  return (
    <div
      className={styles.qrcodeModalContainer}
      onClick={(e) => {
        e.stopPropagation()
      }}
    >
      <CloseIcon
        customClass={styles.closeIconStyle}
        fillColor={colors.green1}
        handleClick={() => {
          handleClose && handleClose()
        }}
      />
      <h1 className={styles.qrcodeModalHeading}>{t('QrCode.Get QR code')}</h1>
      <hr className={styles.descriptionDivider} />
      {/* <Qr data={mcInfoText} /> */}
      <div className={styles.qrcodeDescriptionMain}>
        <div className={styles.qrcodeDot}>
          <QrcodeImgDot />
        </div>
        <div className={styles.qrcodeText}>
          <p>{t('QrCode.CopyText')}</p>
        </div>
      </div>

      <div className={styles.qrcodeDescriptionMain}>
        {/* <div className={styles.qrcodeDot}>
          <QrcodeImgDot />
        </div> */}
        <div className={styles.qrcodeTextCopy}>
          <p className={styles.linkTag}>{mcInfoText}</p>
          <CopyIcon
            fillColor={colors.grey2}
            handleClick={() => {
              hanleCopy(mcInfoText, t('QrCode.Copied'))
            }}
            width={18}
            height={18}
          />
          {toolTipMessage && <ToolTip customClass={styles.qrCodeCopyToolTip} />}
        </div>
      </div>

      <div className={styles.qrcodeDescriptionMain}>
        <div className={styles.qrcodeDot}>
          <QrcodeImgDot />
        </div>
        <div className={styles.qrcodeText}>
          <p>
            {t('QrCode.OnlineToolRef')}{' '}
            <a
              href="https://www.the-qrcode-generator.com"
              target="_blank"
              style={{ textDecoration: 'underline' }}
            >
              https://www.the-qrcode-generator.com
            </a>{' '}
            {t('QrCode.QrCodeGenerator')}
          </p>
        </div>
      </div>

      <div className={styles.qrcodeDescriptionMain}>
        <div className={styles.qrcodeDot}>
          <QrcodeImgDot />
        </div>
        <div className={styles.qrcodeText}>
          <p>{t('QrCode.FreeText')}</p>
        </div>
      </div>

      <div className={styles.qrcodeDescriptionMain}>
        <div className={styles.qrcodeDot}>
          <QrcodeImgDot />
        </div>
        <div className={styles.qrcodeText}>
          <p>
            {t('QrCode.CopyTextValue')}
            <span className={styles.linkTag}>
              {`{"mc_id":"${userData.mc_id}","mc_name":"${userData.mc_name}"}`}
            </span>
          </p>
        </div>
      </div>

      <div className={styles.qrcodeDescriptionMain}>
        <div className={styles.qrcodeDot}>
          <QrcodeImgDot />
        </div>
        <div className={styles.qrcodeText}>
          <p>{t('QrCode.StaticQrcode')}</p>
        </div>
      </div>

      <div className={styles.qrcodeDescriptionMain}>
        <div className={styles.qrcodeDot}>
          <QrcodeImgDot />
        </div>
        <div className={styles.qrcodeText}>
          <p>{t('QrCode.DownloadQRCode')}</p>
        </div>
      </div>

      <div className={styles.qrcodeDescriptionMain}>
        <div className={styles.qrcodeDot}>
          <QrcodeImgDot />
        </div>
        <div className={styles.qrcodeText}>
          <p>{t('QrCode.FileName')}</p>
        </div>
      </div>

      <div className={styles.qrcodeDescriptionMain}>
        <div className={styles.qrcodeDot}>
          <QrcodeImgDot />
        </div>
        <div className={styles.qrcodeText}>
          <p>{t('QrCode.FileFormat')}</p>
        </div>
      </div>

      <div className={styles.qrcodeDescriptionMain}>
        <div className={styles.qrcodeDot}>
          <QrcodeImgDot />
        </div>
        <div className={styles.qrcodeText}>
          <p>{t('QrCode.ImageSize')}</p>
        </div>
      </div>

      <div className={styles.qrcodeDescriptionMain}>
        <div className={styles.qrcodeDot}>
          <QrcodeImgDot />
        </div>
        <div className={styles.qrcodeText}>
          <p>{t('QrCode.Download')}</p>
        </div>
      </div>
    </div>
  )
}

export default QrCodeModal
