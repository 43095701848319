import { FC } from 'react'
import styles from './detail.module.scss'
import { CloseIcon } from '../../../../../components/common/svg-components'
import { colors } from '../../../../../constants/color'
import TableV2 from '../../../../../components/common/table/tableV2/TableV2'
import { DetailHeader } from './DetailTableData'
import { t } from 'i18next'

interface IDetailsPopup {
  popData?: string | any
  children?: any
  agentData?: any
}
const DetailsPopup: FC<IDetailsPopup> = ({ popData, children, agentData }) => {
  return (
    <>
      <div
        className={styles.actionSavePopupContainer}
        onClick={(e) => e.stopPropagation()}
      >
        {/* <CloseIcon
                    customClass={styles.closeIconStyle}
                    fillColor={colors.green1}
                    handleClick={() => handleClose()} /> */}
        <div className={styles.actionSaveContainer}>
          {/* <p className={styles.title}>Details</p> */}
          <div className={styles.data}>
            <div className={styles.agent}>
              <b>{t('CallCenterAdmin.Agent')}:</b>
              <p>{agentData?.name}</p>
            </div>

            <div className={styles.tableContainer}>
              <TableV2
                tableHeaderData={DetailHeader}
                tableRowData={popData?.data?.length ? popData?.data : []}
                // handleRowClick={handleassign}
              />
            </div>

            {children}
          </div>
        </div>
      </div>
    </>
  )
}
export default DetailsPopup
