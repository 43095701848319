import { useState, useEffect, useCallback } from 'react'
import Loader from '../../../components/common/spinner/Loader'
import TableV2 from '../../../components/common/table/tableV2/TableV2'
import Pagination from '../../../components/common/pagination/Pagination'
import PatientHistoryNotesModal from '../../../components/common/modal/patient-history-notes-modal/PatientHistoryNotesModal'
import {
  openCaseIpdDashboardTableHeaderData,
  closeCaseIpdDashboardTableHeaderData,
} from '../../../constants/table-data/ipdDashboardTabledData'
import { getIpdDashboardCaseList } from '../../../redux/features/ipd-dashboard/ipdDashBoardAsyncActions'
import { clearIpdDashboardData } from '../../../redux/features/ipd-dashboard/ipdDashBoardSlice'
import { requestGenerator } from '../../../utils/payloadGenerator'
import { useAppDispatch, useAppSelector } from '../../../hooks/index'
import { SearchIcon } from '../../../components/common/svg-components'
import { colors } from '../../../constants/color'
import { trimValue } from '../../../utils/utils'
import Popup from '../../../components/common/popup/Popup'
import { CustomModal } from '../../../components/common/custom-modal/modal'
import IpdCaseViewAll from '../create-ipd-main-layout/view-all-modal/page'
import styles from './ipdDashboard.module.scss'
import { useTranslation } from 'react-i18next'

const IpdDashboard = () => {
  const dispatch = useAppDispatch()
  const { t } = useTranslation()
  const { isLoading, ipdDashboardCaseData } = useAppSelector(
    (state) => state.ipdDashboard
  )
  //Define State Variables
  const [tabs, setTabs] = useState('openCases')
  const [dataPerPage, setDataPerPage] = useState<number>(10)
  const [totalPage, setTotalPage] = useState<number>(0)
  const [pageIndex, setPageIndex] = useState<number>(1)
  const [totalData, setTotalData] = useState(0)
  const [patientSearch, setPatientSearch] = useState<string>('')
  const [caseNoSearch, setCaseNoSearch] = useState<string>('')
  const [ipdHistoryModal, setIpdHistoryModal] = useState(false)
  const [
    showIpdDashboardDescriptionModal,
    setShowIpdDashboardDescriptionModal,
  ] = useState<boolean>(false)
  const [
    ipdDashboardDescriptionPopupData,
    setIpdDashboardDescriptionPopupData,
  ] = useState<any>({})

  // function for creating Page Index Array
  const pageIndexArray = () => {
    let pageIndexOptions = []
    for (let i = 1; i <= totalPage; i++) {
      pageIndexOptions.push(i)
    }
    return pageIndexOptions
  }
  const pageIndexOptions = pageIndexArray()

  // function for genrating payload
  const genrateGetPayload = useCallback(
    (patientSearch?: string, caseSearch?: string) => {
      if (tabs === 'openCases') {
        return {
          open_case: true,
          patient_name: patientSearch,
          case_no: caseSearch,
          page: pageIndex,
          pageSize: dataPerPage,
        }
      } else if (tabs === 'closedCases') {
        return {
          open_case: false,
          patient_name: patientSearch,
          case_no: caseSearch,
          page: pageIndex,
          pageSize: dataPerPage,
        }
      }
    },
    [pageIndex, dataPerPage, tabs]
  )

  // API call for getting ipd dashboard data
  useEffect(() => {
    const requestData = genrateGetPayload(patientSearch, caseNoSearch)
    dispatch(getIpdDashboardCaseList(requestGenerator(requestData))).then(
      (result) => {
        setTotalPage(result.payload.lastPage)
        setTotalData(result.payload.total)
      }
    )
  }, [dispatch, genrateGetPayload, tabs, dataPerPage, pageIndex])

  // Function for search branch store request
  const handlePatientSearch = () => {
    setPageIndex(1)
    if (patientSearch?.length > 0) {
      const genratePaylaodData = genrateGetPayload(patientSearch, caseNoSearch)
      const requestData = { ...genratePaylaodData, page: 1 }
      dispatch(getIpdDashboardCaseList(requestGenerator(requestData))).then(
        (result) => {
          setTotalPage(result.payload.lastPage)
          setTotalData(result.payload.total)
        }
      )
    }
  }

  const handleCaseNoSearch = () => {
    setPageIndex(1)
    if (caseNoSearch?.length > 0) {
      const genratePaylaodData = genrateGetPayload(patientSearch, caseNoSearch)
      const requestData = { ...genratePaylaodData, page: 1 }
      dispatch(getIpdDashboardCaseList(requestGenerator(requestData))).then(
        (result) => {
          setTotalPage(result.payload.lastPage)
          setTotalData(result.payload.total)
        }
      )
    }
  }

  // Set PageIndex and DataPerPage on tabs change
  useEffect(() => {
    setPageIndex(1)
    setDataPerPage(10)
  }, [tabs])

  // hadle ipd description description modal
  const handleIpdDashboardDescriptionModalOpen = (descriptionData: any) => {
    const ipdNotesData = {
      diag_note: descriptionData?.case_description,
      diag_apt_date: new Date(),
    }
    setIpdDashboardDescriptionPopupData(ipdNotesData)
    setShowIpdDashboardDescriptionModal((prevState) => !prevState)
  }
  const handleIpdDashboardDescriptionModalClose = () => {
    setIpdDashboardDescriptionPopupData({})
    setShowIpdDashboardDescriptionModal((prevState) => !prevState)
  }

  // clear ipd dashboard data on component remove
  useEffect(() => {
    return () => {
      dispatch(clearIpdDashboardData())
    }
  }, [dispatch])

  return (
    <>
      {isLoading && <Loader />}
      <CustomModal
        showModal={ipdHistoryModal}
        closeModal={() => setIpdHistoryModal(false)}
        width="75%"
        height="580px"
        title={t('IPDHistory.Ipd History') || 'Ipd History'}
      >
        <IpdCaseViewAll />
      </CustomModal>
      {showIpdDashboardDescriptionModal && (
        <Popup
          Children={PatientHistoryNotesModal}
          handleClose={handleIpdDashboardDescriptionModalClose}
          popData={ipdDashboardDescriptionPopupData}
          heading={t('MobileAppConfiguration.Description') || 'Description'}
        />
      )}
      <div className={styles.ipdDashboardWrapper}>
        <div className={styles.searchContainer}>
          <div className={styles.search}>
            <label htmlFor={'patientName'} className={styles.searchLabel}>
              {t('ShareQuestionnaire.Patient Name')}
            </label>
            <input
              className={styles.searchInput}
              type="text"
              placeholder={
                t('ViewAppointment.PatientSearch') || 'Search by Patient'
              }
              value={patientSearch}
              onKeyDown={(e: any) => {
                if (e?.key === 'Enter') {
                  handlePatientSearch()
                }
              }}
              onChange={(e) => {
                trimValue(e)
                setPatientSearch(e.target.value)
                if (patientSearch !== '' && e.target.value.length === 0) {
                  setPageIndex(1)
                  setDataPerPage(10)
                  const genratePaylaodData = genrateGetPayload(
                    e.target.value,
                    caseNoSearch
                  )
                  const requestData = {
                    ...genratePaylaodData,
                    page: 1,
                    pageSize: 10,
                  }
                  dispatch(
                    getIpdDashboardCaseList(requestGenerator(requestData))
                  ).then((result) => {
                    setTotalPage(result.payload.lastPage)
                    setTotalData(result.payload.total)
                  })
                }
              }}
            />
            <div className={styles.searchButton} onClick={handlePatientSearch}>
              <SearchIcon fillColor={colors.white1} />
            </div>
          </div>
          <div className={styles.search}>
            <label htmlFor={'caseID'} className={styles.searchLabel}>
              {t('IPDDashboard.Case ID')}
            </label>
            <input
              className={styles.searchInput}
              type="text"
              placeholder={t('IPDDashboard.SearchCase') || 'Search by Case ID'}
              value={caseNoSearch}
              onKeyDown={(e: any) => {
                if (e?.key === 'Enter') {
                  handleCaseNoSearch()
                }
              }}
              onChange={(e) => {
                trimValue(e)
                setCaseNoSearch(e.target.value)
                if (caseNoSearch !== '' && e.target.value.length === 0) {
                  setPageIndex(1)
                  setDataPerPage(10)
                  const genratePaylaodData = genrateGetPayload(
                    patientSearch,
                    e.target.value
                  )
                  const requestData = {
                    ...genratePaylaodData,
                    page: 1,
                    pageSize: 10,
                  }
                  dispatch(
                    getIpdDashboardCaseList(requestGenerator(requestData))
                  ).then((result) => {
                    setTotalPage(result.payload.lastPage)
                    setTotalData(result.payload.total)
                  })
                }
              }}
            />
            <div className={styles.searchButton} onClick={handleCaseNoSearch}>
              <SearchIcon fillColor={colors.white1} />
            </div>
          </div>
        </div>
        <div className={styles.mainContainer}>
          <p
            className={
              tabs === 'openCases' ? styles.tabContent : styles.disableTab
            }
            onClick={() => setTabs('openCases')}
          >
            {t('IPDDashboard.Open Cases')}
          </p>
          <p
            className={
              tabs === 'closedCases' ? styles.tabContent : styles.disableTab
            }
            onClick={() => setTabs('closedCases')}
          >
            {t('IPDDashboard.Closed Cases')}
          </p>
        </div>
        <div className={styles.tableContainer}>
          <TableV2
            tableHeaderData={
              tabs === 'openCases'
                ? openCaseIpdDashboardTableHeaderData
                : closeCaseIpdDashboardTableHeaderData
            }
            tableRowData={ipdDashboardCaseData}
            handleClick={handleIpdDashboardDescriptionModalOpen}
            active={false}
            handleRowClick={() => setIpdHistoryModal(true)}
          />
        </div>
        {ipdDashboardCaseData?.length > 0 && totalData > 10 && (
          <Pagination
            pageSize={dataPerPage}
            setDataPerPage={setDataPerPage}
            pageIndexOptions={pageIndexOptions}
            pageIndex={pageIndex}
            setPageIndex={setPageIndex}
          />
        )}
      </div>
    </>
  )
}

export default IpdDashboard
