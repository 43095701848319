// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.viewAllImagesTable_viewPopupLink__AA2X4 {
  color: var(--blue1);
  font-size: var(--font-12);
  font-weight: var(--font-medium);
  cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/constants/table-data/viewAllImagesTable.module.scss","webpack://./src/styles/mixin.scss"],"names":[],"mappings":"AAEA;ECQE,mBDPc;ECQd,yBDR4B;ECS5B,+BDT4C;EAC5C,eAAA;AACF","sourcesContent":["@import \"../../styles/mixin.scss\";\n\n.viewPopupLink {\n  @include font(var(--blue1), var(--font-12), var(--font-medium));\n  cursor: pointer;\n}\n","@mixin pageWrapper($padding) {\n  background: var(--white2);\n  // backdrop-filter: blur(6px);\n  box-shadow: 0px 10px 117px 14px rgba(0, 0, 0, 0.05);\n  border-radius: 10px;\n  padding: $padding;\n  margin: 20px 20px 30px 0px;\n}\n\n@mixin font($color, $size, $weight) {\n  color: $color;\n  font-size: $size;\n  font-weight: $weight;\n}\n\n@mixin flexCenter {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}\n\n@mixin flexVertical {\n  display: flex;\n  align-items: center;\n}\n\n@mixin flexColumn {\n  display: flex;\n  flex-direction: column;\n}\n\n@mixin responsive($width) {\n  @media (max-width: $width) {\n    @content;\n  }\n}\n\n@mixin animation($name) {\n  @keyframes #{$name} {\n    @content;\n  }\n}\n\n@mixin iconHoverText($content) {\n  cursor: pointer;\n\n  &:hover::after {\n    @include font(var(--black2), var(--font-12), var(--font-semibold));\n    @include flexCenter;\n    position: absolute;\n    content: $content;\n    background-color: var(--white1);\n    box-shadow: 0px 4px 34px rgba(0, 0, 0, 0.1);\n    border-radius: 6px;\n    width: 116px;\n    height: 36px;\n    z-index: 1;\n    opacity: 1;\n    margin: -25px 0 0 25px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"viewPopupLink": `viewAllImagesTable_viewPopupLink__AA2X4`
};
export default ___CSS_LOADER_EXPORT___;
