import { t } from 'i18next'
import styles from './employeeSearchModal.module.scss'
export const employeeSearchModalHeaderData: any = [
  {
    Header: t('AllAttachmentHeader.NAME'),
    Cell: (props: any) => {
      return (
        <span
          onClick={() => {
            props.onRowClick(props?.row?.original)
          }}
          style={{ cursor: 'pointer', color: '#0e26a3' }}
        >
          {props?.row?.original?.name}
        </span>
      )
    },
  },
  {
    Header: t('Employee.SPECIALITY'),
    accessor: (row: any) => {
      const specialitiesValue = row?.specialities[0]?.name
      return specialitiesValue ? specialitiesValue : '-'
    },
  },
  {
    Header: t('InsuranceHeader.DEPARTMENT'),
    Cell: ({ row }: any) => {
      const departments: any = row?.original?.departments
      return departments && departments.length > 0
        ? departments.map((item: any, index: number) => {
            return (
              <div key={`dept-${index}`} className={styles.columnFlex}>
                <span>
                  {index + 1}. {item?.name}
                </span>
              </div>
            )
          })
        : '-'
    },
  },
  {
    Header: t('RcptTableHeader.MOBILE NO'),
    accessor: (row: any) => {
      const phoneValue = row?.phone
      return phoneValue ? phoneValue : '-'
    },
  },
]
