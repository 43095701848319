import {
  ADD_CAMPAIGN_TYPE,
  ASSIGN_AGENT_TO_LEADS_TYPE,
  GET_ALL_CAMPAIGN_TYPE,
  GET_CAMPAIGNS_LEADS_TYPE,
  SEARCH_CRITERIA_TYPE,
  UPDATE_CAMPAIGN_TYPE,
} from '../../../constants/asyncActionsType'
import createAsyncThunkForSlice from '../../../utils/utils'
import {
  addCampaigns,
  assignAgenttoLead,
  getAllCampaignList,
  getCampaignLeads,
  searchCriterias,
  updateCampaigns,
} from './campaignCrud'

export const addCampaign = createAsyncThunkForSlice(
  ADD_CAMPAIGN_TYPE,
  addCampaigns,
  {
    isToast: true,
  }
)
export const searchCriteria = createAsyncThunkForSlice(
  SEARCH_CRITERIA_TYPE,
  searchCriterias
)

export const getAllCampaign = createAsyncThunkForSlice(
  GET_ALL_CAMPAIGN_TYPE,
  getAllCampaignList,
  {
    isToast: false,
  }
)
export const updateCampaign = createAsyncThunkForSlice(
  UPDATE_CAMPAIGN_TYPE,
  updateCampaigns,
  {
    isToast: true,
  }
)

export const getLeadsForCampaign = createAsyncThunkForSlice(
  GET_CAMPAIGNS_LEADS_TYPE,
  getCampaignLeads
)

export const assignAgentToLeads = createAsyncThunkForSlice(
  ASSIGN_AGENT_TO_LEADS_TYPE,
  assignAgenttoLead,
  {
    isToast: true,
  }
)
