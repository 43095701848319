import {
  INSURANCE__PLAN_CLAIM_TYPE,
  INSURANCE_PLAN,
  INSURANCE_PLAN_REIMBURSEMENT_TYPE,
  INSURANCE_PLAN_COMPANY,
  CO_PAY,
  CO_PAY_VALUE,
  SERVICES,
  INSURANCE_DISCOUNT,
  INSURANCE_COPAY,
} from '../constants/constant'
import { t } from 'i18next'

export const addInsurancePlanValidators = {
  [INSURANCE_PLAN_COMPANY]: {
    required: 'Please select insurance company',
  },
  [INSURANCE_PLAN]: {
    required: t('InsurancePlan_Form.plan_validation'),
  },
  [INSURANCE__PLAN_CLAIM_TYPE]: {
    required: t('InsurancePlanValidators.ClaimType'),
  },
  [INSURANCE_PLAN_REIMBURSEMENT_TYPE]: {
    required: 'Please select reimbursement type',
  },
  [CO_PAY]: {
    required: t('InsurancePlan_Form.Co_Pay_validation'),
  },
  [CO_PAY_VALUE]: {
    required: 'Please enter co-pay%',
    validate: {
      handleValue: (value: any) => {},
    },
  },
  [SERVICES]: {
    required: 'Please select services',
  },
}

export const departmentInsuranceConfigValidators = {
  [INSURANCE_DISCOUNT]: {
    required: 'Please enter discount',
  },
  [INSURANCE_COPAY]: {
    required: 'Please enter copay',
  },
}
