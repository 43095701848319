// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_modalOverlay__mKeUc {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.8);
  overflow-y: auto;
}

.styles_modalWrapper__xOMM6 {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: white;
  padding: 25px;
  border-radius: 15px;
  overflow: auto;
}
.styles_modalWrapper__xOMM6::-webkit-scrollbar {
  display: block;
  width: 7px;
  height: 7px;
}
.styles_modalWrapper__xOMM6::-webkit-scrollbar-track {
  background: transparent;
}
.styles_modalWrapper__xOMM6::-webkit-scrollbar-thumb {
  background-color: var(--grey7);
  border-radius: 10px;
  border: 1px solid var(--grey7);
}

.styles_closeModal__DPspx {
  position: absolute;
  right: 3%;
  top: 4%;
}

.styles_titleContainer__PLWBE {
  position: relative;
  margin-block: 10px;
}

.styles_textUnderline__6cMwZ {
  position: absolute;
  bottom: -15px;
  left: 50%;
  background: #02bf90;
  width: 62px;
  height: 3px;
  transform: translate(-50%, -50%);
}

.styles_addTestHeading__-xiZA {
  text-align: center;
  font-size: 24px;
  font-weight: 800;
  margin-top: 3%;
}`, "",{"version":3,"sources":["webpack://./src/components/common/custom-modal/styles.module.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,MAAA;EACA,OAAA;EACA,SAAA;EACA,QAAA;EACA,8BAAA;EACA,gBAAA;AACF;;AAEA;EACE,eAAA;EACA,QAAA;EACA,SAAA;EACA,gCAAA;EACA,iBAAA;EACA,aAAA;EACA,mBAAA;EACA,cAAA;AACF;AAAE;EACE,cAAA;EACA,UAAA;EACA,WAAA;AAEJ;AAAE;EACE,uBAAA;AAEJ;AACE;EACE,8BAAA;EACA,mBAAA;EACA,8BAAA;AACJ;;AAOA;EACE,kBAAA;EACA,SAAA;EACA,OAAA;AAJF;;AAOA;EACE,kBAAA;EACA,kBAAA;AAJF;;AAOA;EACE,kBAAA;EACA,aAAA;EACA,SAAA;EACA,mBAAA;EACA,WAAA;EACA,WAAA;EACA,gCAAA;AAJF;;AAOA;EACE,kBAAA;EACA,eAAA;EACA,gBAAA;EACA,cAAA;AAJF","sourcesContent":[".modalOverlay {\n  position: absolute;\n  top: 0;\n  left: 0;\n  bottom: 0;\n  right: 0;\n  background: rgba(0, 0, 0, 0.8);\n  overflow-y: auto;\n}\n\n.modalWrapper {\n  position: fixed;\n  top: 50%;\n  left: 50%;\n  transform: translate(-50%, -50%);\n  background: white;\n  padding: 25px;\n  border-radius: 15px;\n  overflow: auto;\n  &::-webkit-scrollbar {\n    display: block;\n    width: 7px;\n    height: 7px;\n  }\n  &::-webkit-scrollbar-track {\n    background: transparent;\n  }\n\n  &::-webkit-scrollbar-thumb {\n    background-color: var(--grey7);\n    border-radius: 10px;\n    border: 1px solid var(--grey7);\n  }\n}\n\n// .modalContainer {\n//   position: relative;\n// }\n\n.closeModal {\n  position: absolute;\n  right: 3%;\n  top: 4%;\n}\n\n.titleContainer {\n  position: relative;\n  margin-block: 10px;\n}\n\n.textUnderline {\n  position: absolute;\n  bottom: -15px;\n  left: 50%;\n  background: #02bf90;\n  width: 62px;\n  height: 3px;\n  transform: translate(-50%, -50%);\n}\n\n.addTestHeading {\n  text-align: center;\n  font-size: 24px;\n  font-weight: 800;\n  margin-top: 3%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modalOverlay": `styles_modalOverlay__mKeUc`,
	"modalWrapper": `styles_modalWrapper__xOMM6`,
	"closeModal": `styles_closeModal__DPspx`,
	"titleContainer": `styles_titleContainer__PLWBE`,
	"textUnderline": `styles_textUnderline__6cMwZ`,
	"addTestHeading": `styles_addTestHeading__-xiZA`
};
export default ___CSS_LOADER_EXPORT___;
