import { useState } from 'react'
import Select from 'react-select'
import { useAppDispatch, useAppSelector } from '../../hooks'
import {
  REPORT_LEVEL,
  REPORT_LEVEL_TABLE,
} from '../../constants/reportBuilderConstant'
import { updateChildTableColumn } from '../../redux/features/role/roleSlice'
import Popup from '../../components/common/popup/Popup'
import { DropdownIndicator } from '../../components/common/dropdown-indicator/DropdownIndicator'
import { Label } from '../../components/common/label'
import Button from '../../components/common/button/Button'
import ChildTableSelectColumnsModal from './ChildTableSelectColumnsModal'
import { reactSelectStyle } from '../../constants/data'
import styles from './styles.module.scss'
import { t } from 'i18next'

const CreateReportChildLevelComponent = ({
  data,
  setData,
  selectedLevels,
  setSelectedLevels,
  register,
  setValue,
  watch,
  reportBuilderFormWatch,
}: any) => {
  const dispatch = useAppDispatch()
  const { selctedChildTableColumns } = useAppSelector((state) => state.roleUser)
  const {
    base_table,
    column,
    selected,
    filter,
    sorting,
    _headers,
    sum_column,
    ...rest
  } = data
  const [
    showChildTableSelectColumnsModal,
    setShowChildTableSelectColumnsModal,
  ] = useState<boolean>(false)
  const [levelSelected, setLevelSelected] = useState<any>('')
  const [selectedTableIndex, setSelectedTableIndex] = useState<any>(null)

  // function for open child table select column modal
  const handleChildTableColmunModalOpen = (
    levelSelected: any,
    tableIndex: any
  ) => {
    if (
      reportBuilderFormWatch[
        `${REPORT_LEVEL_TABLE}_${tableIndex}`
      ]?.hasOwnProperty('selected')
    ) {
      const currentSelectedTableData =
        reportBuilderFormWatch[`${REPORT_LEVEL_TABLE}_${tableIndex}`]
      const genrateSelectedChildTableData = Object.keys(
        currentSelectedTableData?.selected
      ).map((selectedData: any) => {
        return {
          [selectedData]: currentSelectedTableData?.selected[selectedData],
        }
      })
      dispatch(updateChildTableColumn(genrateSelectedChildTableData))
    }
    setLevelSelected(levelSelected)
    setSelectedTableIndex(tableIndex)
    setShowChildTableSelectColumnsModal((prevState) => !prevState)
  }

  // function for close child table select column modal
  const handleChildTableColmunModalClose = () => {
    let convertSelectedChildTableColumns: any = {}
    selctedChildTableColumns?.map(
      (selectedColumns: any) =>
        (convertSelectedChildTableColumns = {
          ...selectedColumns,
          ...convertSelectedChildTableColumns,
        })
    )
    const tablesData = [...selectedLevels[levelSelected]]
    const currentSelectedTable = {
      ...reportBuilderFormWatch[`${REPORT_LEVEL_TABLE}_${selectedTableIndex}`],
      selected: convertSelectedChildTableColumns,
    }
    const currentSelectedTableIndex = tablesData.findIndex(
      (item: any) => item.name === currentSelectedTable?.name
    )
    tablesData[currentSelectedTableIndex] = currentSelectedTable
    const selectedLevelsWithoutChildren: any = []
    tablesData.forEach((item: any) => {
      const { children, ...rest } = item
      selectedLevelsWithoutChildren.push({ ...rest })
    })
    setSelectedLevels({
      ...selectedLevels,
      [levelSelected]: selectedLevelsWithoutChildren,
    })
    setValue(`${REPORT_LEVEL}_${selectedTableIndex}`, tablesData)
    setValue(`${REPORT_LEVEL_TABLE}_${selectedTableIndex}`, null)
    setSelectedTableIndex(null)
    setShowChildTableSelectColumnsModal((prevState) => !prevState)
  }

  return (
    <>
      {Object?.keys(rest)?.map((item, index) => (
        <div key={index}>
          {showChildTableSelectColumnsModal && (
            <Popup
              Children={ChildTableSelectColumnsModal}
              handleClose={handleChildTableColmunModalClose}
              popData={
                reportBuilderFormWatch[
                  `${REPORT_LEVEL_TABLE}_${selectedTableIndex}`
                ]
              }
            />
          )}
          <div className={styles.levelFields}>
            <div className={styles.inputFieldContainer}>
              <Label
                labelText={`Level ${Number(item) + 1}`}
                requiredField={false}
                flexBasis="31%"
              />
              <div className={styles.inlineItems}>
                <Select
                  className={styles.selectInputField}
                  placeholder={`Select Level ${Number(item) + 1}`}
                  closeMenuOnSelect={false}
                  components={{ DropdownIndicator }}
                  {...register(`${REPORT_LEVEL}_${Number(item) + 1}`)}
                  value={watch(`${REPORT_LEVEL}_${Number(item) + 1}`)}
                  options={data[item].map((item: any) => {
                    return {
                      label: item.name,
                      value: item.name,
                      ...item,
                    }
                  })}
                  isSearchable={false}
                  onChange={(e: any) => {
                    const tempArray: any = []
                    const selectedLevelsWithoutChildren: any = []
                    e.forEach((item: any) => {
                      if (item?.hasOwnProperty('children')) {
                        tempArray.push(...item.children)
                      }
                    })
                    e.forEach((item: any) => {
                      const { children, ...rest } = item
                      selectedLevelsWithoutChildren.push({ ...rest })
                    })
                    const childrenIndex = Number(item) + 1
                    if (tempArray?.length > 0) {
                      setData({
                        ...data,
                        [childrenIndex]: tempArray,
                      })
                    }
                    setValue(`${REPORT_LEVEL}_${Number(item) + 1}`, e)
                    setSelectedLevels({
                      ...selectedLevels,
                      [`level${childrenIndex}Selected`]:
                        selectedLevelsWithoutChildren,
                    })
                  }}
                  maxMenuHeight={200}
                  isMulti
                  styles={reactSelectStyle}
                />
              </div>
            </div>
            {reportBuilderFormWatch[`${REPORT_LEVEL}_${Number(item) + 1}`]
              ?.length > 0 && (
              <div className={styles.levelItems}>
                <div>
                  <Select
                    className={styles.selectInputField}
                    placeholder={t('ReportBuilder.Select_Table')}
                    closeMenuOnSelect={true}
                    components={{ DropdownIndicator }}
                    {...register(`${REPORT_LEVEL_TABLE}_${Number(item) + 1}`)}
                    value={watch(`${REPORT_LEVEL_TABLE}_${Number(item) + 1}`)}
                    options={
                      reportBuilderFormWatch[
                        `${REPORT_LEVEL}_${Number(item) + 1}`
                      ]
                    }
                    isSearchable={false}
                    onChange={(e: any) => {
                      setValue(`${REPORT_LEVEL_TABLE}_${Number(item) + 1}`, e)
                      dispatch(updateChildTableColumn([]))
                      setLevelSelected('')
                    }}
                    isClearable
                    maxMenuHeight={200}
                    styles={reactSelectStyle}
                  />
                </div>
                {reportBuilderFormWatch[
                  `${REPORT_LEVEL_TABLE}_${Number(item) + 1}`
                ] && (
                  <div>
                    <Button
                      type="button"
                      title="Select Column"
                      handleClick={() =>
                        handleChildTableColmunModalOpen(
                          `level${Number(item) + 1}Selected`,
                          Number(item) + 1
                        )
                      }
                    />
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      ))}
    </>
  )
}

export default CreateReportChildLevelComponent
