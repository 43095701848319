import moment from 'moment'
import styles from './patientHistoryTable.module.scss'
import { t } from 'i18next'

export const patientHistoryHeaderData: any = [
  {
    Header: t('PatientHistoryTableHeader.APPOINTMENT DT.'),
    // accessor: "diag_apt_date",
    Cell: ({ row }: any) => {
      const convertDate = moment(row?.original?.diag_apt_date).format(
        'DD-MMM-YYYY'
      )
      return (
        <>
          {row?.original?.diag_apt_date ? (
            <span className={styles.appointmentDate}>
              {row?.original?.diag_apt_time} {convertDate}
            </span>
          ) : (
            '-'
          )}
        </>
      )
    },
  },
  {
    Header: t('PatientHistoryTableHeader.DIAGNOSIS'),
    Cell: (props: any) => {
      const convertDate = moment(props?.row?.original?.diag_apt_date).format(
        'DD-MMM-YYYY'
      )
      const rowData = { ...props?.row?.original, convertDate }
      rowData.headerName = 'DIAGNOSIS'
      return (
        <>
          {props?.row?.original?.diag_apt_date ? (
            <span
              className={styles.viewPopupLink}
              onClick={() => {
                props?.onClick(rowData)
              }}
            >
              {t('Common.View')}
            </span>
          ) : (
            '-'
          )}
        </>
      )
    },
  },
  {
    Header: t('PatientHistoryTableHeader.SYMPTOMS'),
    Cell: (props: any) => {
      const convertDate = moment(props?.row?.original?.diag_apt_date).format(
        'DD-MMM-YYYY'
      )
      const rowData = { ...props?.row?.original, convertDate }
      rowData.headerName = 'SYMPTOMS'
      return (
        <>
          {props?.row?.original?.diag_apt_date ? (
            <span
              className={styles.viewPopupLink}
              onClick={() => {
                props?.onClick(rowData)
              }}
            >
              {t('Common.View')}
            </span>
          ) : (
            '-'
          )}
        </>
      )
    },
  },
  {
    Header: t('PatientHistoryTableHeader.MEDICATION'),
    Cell: (props: any) => {
      const convertDate = moment(props?.row?.original?.diag_apt_date).format(
        'DD-MMM-YYYY'
      )
      const rowData = { ...props?.row?.original, convertDate }
      rowData.headerName = 'MEDICATION'
      return (
        <>
          {props?.row?.original?.diag_apt_date ? (
            <span
              className={styles.viewPopupLink}
              onClick={() => {
                props?.onClick(rowData)
              }}
            >
              {t('Common.View')}
            </span>
          ) : (
            '-'
          )}
        </>
      )
    },
  },
  {
    Header: t('PatientHistoryTableHeader.NOTES'),
    Cell: (props: any) => {
      const convertDate = moment(props?.row?.original?.diag_apt_date).format(
        'DD-MMM-YYYY'
      )
      const rowData = { ...props?.row?.original, convertDate }
      rowData.headerName = 'NOTES'
      return (
        <>
          {props?.row?.original?.diag_apt_date ? (
            <span
              className={styles.viewPopupLink}
              onClick={() => {
                props?.onClick(rowData)
              }}
            >
              {t('Common.View')}
            </span>
          ) : (
            '-'
          )}
        </>
      )
    },
  },
  {
    Header: (props: any) => {
      const headerData = {
        headerName: 'ATTACHMENTS_HEADER',
      }
      return (
        <div className={styles.attachmentsHeaderContainer}>
          <p className={styles.attachmentsHeaderText}>
            {t('PatientHistoryTableHeader.ATTACHMENTS')}
          </p>
          <button
            className={styles.attachmentsHeaderBtn}
            disabled={props?.data?.length > 0 ? false : true}
            onClick={() => {
              props.onClick(headerData)
            }}
          >
            {t('Common.View All')}
          </button>
        </div>
      )
    },
    accessor: 'attachment',
    disableSortBy: true,
    Cell: (props: any) => {
      const convertDate = moment(props?.row?.original?.diag_apt_date).format(
        'DD-MMM-YYYY'
      )
      const rowData = { ...props?.row?.original, convertDate }
      rowData.headerName = 'ATTACHMENTS'
      return (
        <>
          {props?.row?.original?.diag_apt_date ? (
            <span
              className={styles.viewPopupLink}
              onClick={() => {
                props?.onClick(rowData)
              }}
            >
              {t('Common.View')}
            </span>
          ) : (
            '-'
          )}
        </>
      )
    },
  },
  {
    Header: (props: any) => {
      const headerData = {
        headerName: 'IMAGES_HEADER',
      }
      return (
        <div className={styles.imagesHeaderContainer}>
          <p className={styles.imagesHeaderText}>
            {t('PatientHistoryTableHeader.IMAGES')}
          </p>
          <button
            className={styles.imagesHeaderBtn}
            disabled={props?.data?.length > 0 ? false : true}
            onClick={() => {
              props?.onClick(headerData)
            }}
          >
            {t('Common.View All')}
          </button>
        </div>
      )
    },
    accessor: 'image',
    disableSortBy: true,
    Cell: (props: any) => {
      const convertDate = moment(props?.row?.original?.diag_apt_date).format(
        'DD-MMM-YYYY'
      )
      const rowData = { ...props?.row?.original, convertDate }
      rowData.headerName = 'IMAGES'
      return (
        <>
          {props?.row?.original?.diag_apt_date ? (
            <span
              className={styles.viewPopupLink}
              onClick={() => {
                props?.onClick(rowData)
              }}
            >
              {t('Common.View')}
            </span>
          ) : (
            '-'
          )}
        </>
      )
    },
  },
  {
    Header: t('PatientHistoryTableHeader.FORMS'),
    Cell: (props: any) => {
      const forms = props?.row?.original?.diag_forms_submitted
      const convertDate = moment(props?.row?.original?.diag_apt_date).format(
        'DD-MMM-YYYY'
      )
      const rowData = {
        ...props?.row?.original,
        headerName: 'FORMS',
        convertDate,
      }
      return (
        <>
          {forms && forms?.length > 0 ? (
            <span
              className={styles.viewPopupLink}
              onClick={() => {
                props?.onClick(rowData)
              }}
            >
              {t('Common.View')}
            </span>
          ) : (
            '-'
          )}
        </>
      )
    },
  },
]
