import {
  APPOINTMENT_TYPE,
  TITLE,
  DESCRIPTION,
  PRICE,
  ICON,
  NEWS_TITLE,
  NEWS_DESCRIPTION,
} from '../constants/mobileAppConfigurationConstant'
import { t } from 'i18next'

export const mobileAppAppointmentValidators = {
  [APPOINTMENT_TYPE]: {
    required: t('BookingConfirmationValidators.TYPE'),
  },
  [TITLE]: {
    required: t('MobileAppConfiguration.Title_validation'),
  },
  [DESCRIPTION]: {
    required: t('MobileAppConfiguration.DESCRIPTION_VALIDATION'),
  },
  [PRICE]: {
    required: t('MobileAppConfiguration.Price_Listing_Validation'),
    validate: {
      handleZero: (value: any) => {
        if (value <= 0) {
          return t('MobileAppConfiguration.valid_price')
        }
      },
    },
  },
  [ICON]: {
    required: t('MobileAppConfiguration.Icon_validation'),
    validate: {
      fileSize: (file: FileList) => {
        return (
          file[0].size <= 2 * 1024 * 1024 ||
          t('MobileAppConfiguration.icon_valid_size')
        )
      },
    },
  },
}

export const medicalCenterNewsValidators = {
  [NEWS_TITLE]: {
    required: t('MobileAppConfiguration.Title_validation'),
  },
  [NEWS_DESCRIPTION]: {
    required: t('MobileAppConfiguration.DESCRIPTION_VALIDATION'),
  },
}
