import {
  IMAGE_LABEL_NAME,
  CATEGORY_LABEL_NAME,
  MAIN_COMPLAINT_LABEL_NAME,
  DIAGNOSIS_LABEL_NAME,
  SESSIONS_LABEL_NAME,
  SERVICE_LABEL_NAME,
  DOCTOR_LABEL_NAME,
} from "../constants/constant";
import { t } from "i18next";

export const doctorDiagnosisValidators = {
  [IMAGE_LABEL_NAME]: {
    required: t("RadiologyInvoice.Image_Validation"),
  },
  [CATEGORY_LABEL_NAME]: {
    required: t("DentistDiagnosis.SelectCategory_Validation"),
  },
  [MAIN_COMPLAINT_LABEL_NAME]: {
    required: t("DentistDiagnosis.MainComplaint_Validation"),
  },
  [SERVICE_LABEL_NAME]: {
    required: t("InvoiceService.SelectService"),
  },
  [DOCTOR_LABEL_NAME]: {
    required: t("RoomBooking.SelectDoctorValidation"),
  },
  [SESSIONS_LABEL_NAME]: {
    required: t("BookingConfirmationValidators.SESSION"),
    validate: {
      handleMaxNumberValidation: (value: any) => {
        const tempVal = value * 1;
        if (tempVal <= 0 || tempVal > 99) {
          return "Please enter session no between 1 to 99";
        }
      },
    },
  },
};
