import { t } from 'i18next'
import styles from './tableData.module.scss'
import moment from 'moment'

export const laboratoryReportHeaderData: any = [
  {
    Header: t('LabReport.JOB ID'),
    accessor: 'job_no',
  },
  {
    Header: t('PatientHistoryFormHeader.PATIENT NAME'),
    accessor: 'name',
  },
  {
    Header: t('RcptTableHeader.DATE'),
    accessor: (row: any) => {
      return moment(row?.createdAt).format('DD-MMM-YYYY') ?? '-'
    },
  },
  {
    Header: t('LabReport.VIEW REPORT'),
    Cell: (props: any) => {
      return (
        <>
          {props?.row?.original ? (
            <span
              className={styles.blueLinkText}
              onClick={() => {
                props?.onOpen(props?.row?.original)
              }}
            >
              {t('Common.View')}
            </span>
          ) : (
            '-'
          )}
        </>
      )
    },
  },
]
