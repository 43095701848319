import { FC } from 'react'
import styles from './style.module.scss'

interface IErrorProps {
  message: string
  customClassSpan?: string
}

export const ErrorMessage: FC<IErrorProps> = (props) => {
  const { message, customClassSpan } = props
  return (
    <div className={styles.errorContainer}>
      <span className={[styles.extraSpan, customClassSpan].join(' ')} />
      <p className="dashboardFormError">{message}</p>
    </div>
  )
}
