import styles from './tableData.module.scss'
import { t } from 'i18next'

export const CriteriaHeader: any[] = [
  {
    Header: t('CallCenterAdmin.LEAD_NAME'),
    accessor: (row: any) => {
      return `${row.name}`
    },
  },
  {
    Header: t('LabConfiguration.GENDER'),
    accessor: (row: any) => {
      return `${row.gender}`
    },
  },
  {
    Header: t('LeadPool.AGE'),
    accessor: (row: any) => {
      return `${row.age}`
    },
  },
  {
    Header: t('LeadPool.NATIONALITY'),
    accessor: (row: any) => {
      return <p>{row?.nationality?.length > 0 ? row?.nationality : '-'}</p>
    },
  },
  {
    Header: t('Marketplace_Form.PIN_CODE'),
    accessor: (row: any) => {
      return <p>{row?.pincode?.length > 0 ? row?.pincode : '-'}</p>
    },
  },
  {
    Header: t('LeadPool.TAGS'),
    Cell: (props: any) => {
      const tags = props?.row?.original?.assign_tag
      return (
        <>
          {/* {props?.row?.original?.assign_tag?.length > 0 ? (
            <p
              className={styles.blueLinkText}
              onClick={() => {
                if (props?.row?.original?.assign_tag?.length > 0) {
                  props?.onClick(props?.row?.original?.assign_tag)
                } else {
                  return
                }
              }}
            >
              View
            </p>
          ) : (
            <p>-</p>
          )} */}
          {tags && tags?.length > 0
            ? tags?.map((item: string, index: number) => {
                return item ? (
                  <div key={item}>
                    {index + 1}. {item}
                  </div>
                ) : (
                  ''
                )
              })
            : '-'}
        </>
      )
    },
  },
  {
    Header: t('RcptTableHeader.STATUS'),
    Cell: ({ row }: any) => {
      return (
        <>
          <p
            className={
              row?.original?.is_active ? styles.completed : styles.cancelled
            }
          >
            {' '}
            {row?.original?.is_active ? 'ACTIVE' : 'INACTIVE'}
          </p>
        </>
      )
    },
  },
]
