import {
  CrossIconWithoutStyle,
  EditIconWithoutStyle,
} from '../../components/common/svg-components'
import moment from 'moment'
import AuthorizedStatusDropdown from '../../components/common/status-dropdown/authorized-status-dropdown/AuthorizedStatusDropdown'
import { useState, useEffect, useRef } from 'react'
import jsPDF from 'jspdf'
import 'jspdf-autotable'
import image from '../../assets/images/proactLogo.png'
import styles from './tableData.module.scss'
import { t } from 'i18next'

export const submitRequestPopupHeaderData: any = [
  {
    Header: t('RcptTableHeader.DATE'),
    accessor: 'createdAt',
    Cell: ({ row }: any) => {
      const originalDate = row?.original?.createdAt
      const formattedDate = moment(originalDate).format('DD MMM YYYY')
      return <>{formattedDate}</>
    },
  },
  {
    Header: t('INVTableHeader.DOCID'),
    accessor: 'document_id',
    Cell: (props: any) => {
      const generatePdf = () => {
        const doc: any = new jsPDF('p', 'pt', 'a4')
        const width = doc.internal.pageSize.getWidth()
        let tableData: any = []
        props.row.original?.invitemrequests?.forEach((items: any) => {
          tableData.push([
            items.items.item_code,
            items.item_name,
            props.row.original.requested_name,
            items.req_unit_type.value,
            items.requested_qty,
          ])
        })
        let offsetY = 4.797777777777778
        let lineHeight = 6.49111111111111

        const imgData = image
        doc.addImage(imgData, 'JPEG', width / 2, 20, 40, 40, {
          align: 'center',
        })
        doc
          .text('General Request Report', width / 2, 80, { align: 'center' })
          .setFontSize(10)
          .setFont(undefined, 'bold')
        doc
          .text(`Doc. No.:  ${props.row.original.document_id}`, 60, 120)
          .setFontSize(8)
          .setFont(undefined, 'bold')
        doc
          .text(`Request To:${props.row.original.store_name}`, 60, 140)
          .setFontSize(8)
          .setFont(undefined, 'bold')
        // doc
        //   .text(`Remarks: `, 400, 120)
        //   .setFontSize(8)
        //   .setFont(undefined, "bold");
        doc
          .text(`Date: ${props.row.original.dateString}`, 400, 140)
          .setFontSize(8)
          .setFont(undefined, 'bold')

        doc.autoTable({
          startY: 155,
          styles: { halign: 'center', textColor: '#000', fillColor: '#fefefe' },
          tableLineColor: '#000',
          tableLineWidth: 0.1,

          headStyles: { lineWidth: 0.1, lineColor: '#000' },
          bodyStyles: { fontSize: '8', lineWidth: 0.1, lineColor: '#000' },

          head: [['Code', 'Name', 'Creator', 'unit', 'Quantity']],
          body: tableData,
        })
        const dynamicHeight = doc?.autoTable?.previous?.finalY

        doc
          .text('Signature :', 20, dynamicHeight + lineHeight * 5 + offsetY)
          .setFontSize(8)
          .setFont(undefined, 'bold')

        doc
          .text('Date :', 100, dynamicHeight + lineHeight * 5 + offsetY)
          .setFontSize(8)
          .setFont(undefined, 'bold')
        doc
          .text('Dept. Head', 200, dynamicHeight + lineHeight * 5 + offsetY)
          .setFontSize(8)
          .setFont(undefined, 'bold')
        doc
          .text('Incharge :', 300, dynamicHeight + lineHeight * 5 + offsetY)
          .setFontSize(8)
          .setFont(undefined, 'bold')
        doc
          .text(
            'Department Head :',
            400,
            dynamicHeight + lineHeight * 5 + offsetY
          )
          .setFontSize(8)
          .setFont(undefined, 'bold')
        doc
          .text('Accounts :', 500, dynamicHeight + lineHeight * 5 + offsetY)
          .setFontSize(8)
          .setFont(undefined, 'bold')
        doc
          .text('CEO', 600, dynamicHeight + lineHeight * 5 + offsetY)
          .setFontSize(8)
          .setFont(undefined, 'bold')

        doc.save('request.pdf')
        window.open(doc.output('bloburl'), '_blank')
      }
      return (
        <p
          className={
            props?.row?.original?.authorization_status === 'APPROVED'
              ? styles.blueLinkText
              : ''
          }
          onClick={() => {
            if (props?.row?.original?.authorization_status === 'APPROVED') {
              generatePdf()
            } else return
          }}
        >
          {props?.row?.original?.document_id}
        </p>
      )
    },
  },
  {
    Header: t('INVTableHeader.SOURCE'),
    accessor: 'request_source_type',
    Cell: ({ row }: any) => {
      let source = (row?.original?.request_source_type).replace('_', ' ')
      return <>{source}</>
    },
  },
  {
    Header: t('INVTableHeader.SUB_SOURCE'),
    accessor: 'request_source',
  },
  {
    Header: t('INVRequest.DESTINATION'),
    accessor: 'store_name',
  },
  {
    Header: t('RcptTableHeader.STATUS'),
    accessor: 'status',
  },
  {
    Header: t('INVRequest.AuthrizedStatus'),
    accessor: 'is_approved',
    Cell: ({ row }: any) => {
      let appointment_id = row?.original?._id
      const [showOption, setShowOption] = useState<boolean>(false)
      const statusRef = useRef<any>()

      // function for close dropdown
      useEffect(() => {
        const checkIfClickedOutside = (e: any) => {
          if (
            showOption &&
            statusRef.current &&
            !statusRef.current.contains(e.target)
          ) {
            setShowOption(false)
          }
        }

        document.addEventListener('mousedown', checkIfClickedOutside)

        return () => {
          document.removeEventListener('mousedown', checkIfClickedOutside)
        }
      }, [showOption])

      return (
        <>
          <AuthorizedStatusDropdown
            appointmentStatus={
              row?.original?.authorization_status === 'ENTERED'
                ? 'Entered'
                : row?.original?.authorization_status === 'APPROVED'
                ? 'Approved'
                : 'Rejected'
            }
            appointment_id={appointment_id}
            setShowOption={setShowOption}
            showOption={showOption}
          />
        </>
      )
    },
  },
  {
    Header: t('DocUploadHeader.ACTION'),
    accessor: '_action',
    Cell: (props: any) => {
      return (
        <>
          <div
            style={{
              display: 'flex',
              columnGap: '10px',
              justifyContent: 'center',
            }}
          >
            <EditIconWithoutStyle
              handleClick={() => {
                if (props?.row?.original?.authorization_status === 'ENTERED') {
                  props?.onRowClick(props?.row?.original?._id)
                } else return
              }}
              customClass={
                props?.row?.original?.authorization_status === 'ENTERED'
                  ? styles.editIconStyle
                  : styles.editIconStyleDisable
              }
            />

            <p
              style={{
                background: '#CDD4D8',
                borderRadius: '50%',
                padding: '2px',
              }}
            >
              <CrossIconWithoutStyle
                fillColor="#FFFF"
                handleClick={() => {
                  if (
                    props?.row?.original?.authorization_status === 'ENTERED'
                  ) {
                    props?.onPopClose(props?.row?.original?._id)
                  }
                }}
                customClass={
                  props?.row?.original?.authorization_status === 'ENTERED'
                    ? styles.editIconStyle
                    : styles.editIconStyleDisable
                }
              />
            </p>
          </div>
        </>
      )
    },
  },
]
