import {
  CALL_CENTER_ADMIN_AGENT_DETAILS_LIST,
  CALL_CENTER_ADMIN_AGENT_STATUS_UPDATE,
  GET_CALL_CENTER_ADMIN_ALL_LEADS,
  GET_CALL_CENTER_ADMIN_ASSIGN_LEAD,
  GET_CALL_CENTER_ADMIN_DASHBORD_AGENT,
  GET_CALL_CENTER_ADMIN_DASHBORD_COUNT,
} from "../../../constants/asyncActionsType";
import createAsyncThunkForSlice from "../../../utils/utils";
import {
  callCenterAdminDashborAgent,
  callCenterAdminDashborCount,
  callCenterAdminDashbordAllLeads,
  callCenterAdminDashbordAssignLead,
  callCenterAdminDashbordUpdateStatus,
} from "./callCenterDashbordCrud";

export const getCallCenterDashbordCount = createAsyncThunkForSlice(
  GET_CALL_CENTER_ADMIN_DASHBORD_COUNT,
  callCenterAdminDashborCount
);

export const getCallCenterDashbordAgent = createAsyncThunkForSlice(
  GET_CALL_CENTER_ADMIN_DASHBORD_AGENT,
  callCenterAdminDashborAgent
);

export const getCallCenterDashbordAllLeads = createAsyncThunkForSlice(
  GET_CALL_CENTER_ADMIN_ALL_LEADS,
  callCenterAdminDashbordAllLeads
);

export const getCallCenterDashbordAssignLeads = createAsyncThunkForSlice(
  GET_CALL_CENTER_ADMIN_ASSIGN_LEAD,
  callCenterAdminDashbordAssignLead
);

export const adminDashbordAgentStatusUpdate = createAsyncThunkForSlice(
  CALL_CENTER_ADMIN_AGENT_STATUS_UPDATE,
  callCenterAdminDashbordUpdateStatus
);

export const adminDashbordAgentDetails = createAsyncThunkForSlice(
  CALL_CENTER_ADMIN_AGENT_DETAILS_LIST,
  callCenterAdminDashbordAllLeads
);
