import axios from 'axios';
import { IAPIPayload } from '../../../interfaces/apiInterface';
import {
  ASSIGN_INTERNAL_LEAD,
  CREATE_EXTERNAL_LEAD,
  CREATE_INTERNAL_LEAD,
  GET_ALL_ASSIGNEE,
  GET_ALL_LEAD_COUNT_INFO,
  GET_ALL_LEAD_POOL,
} from '../../../config/config';

export const getAllLeadPools = (data: IAPIPayload) => {
  return axios.post(GET_ALL_LEAD_POOL, data);
};

export const createInternalLeads = (data: IAPIPayload) => {
  return axios.post(CREATE_INTERNAL_LEAD, data);
};

export const assignInternalLeads = (data: IAPIPayload) => {
  return axios.post(ASSIGN_INTERNAL_LEAD, data);
};

export const createExternalLeads = (data: IAPIPayload) => {
  return axios.post(CREATE_EXTERNAL_LEAD, data);
};

export const getAllAssignees = (data: IAPIPayload) => {
  return axios.post(GET_ALL_ASSIGNEE, data);
};

export const getAllLeadCountsInfo = (data: IAPIPayload) => {
  return axios.post(GET_ALL_LEAD_COUNT_INFO, data);
};
