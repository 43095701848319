import {
  ACCOUNT,
  ACCOUNT1,
  ACCOUNT_NAME,
  ADD_VOUCHER_CODE,
  ADD_VOUCHER_NAME,
  COST_CENTER,
  COST_VOUCHER_NO,
  CREDIT_AMOUNT,
  DATE,
  DEBIT_AMOUNT,
  ENTRY_DATE,
  ENTRY_REMARKS,
  REFERENCE_NO,
  REF_DOC_CATEGORY,
  VOUCHERS_TYPE,
  VOUCHER_NO,
  VOUCHER_REMARKS,
  COST_CENTER_VALUE,
  VOUCHER_STATUS,
} from './voucherConstant'
import { IAddVoucherEntries } from './voucherInterface'
import { t } from 'i18next'

export const vouchersValidators = {
  [VOUCHERS_TYPE]: {
    required: t('Voucher.Voucher_validation'),
  },
  [VOUCHER_STATUS]: {
    required: t('Voucher.Voucher_validation'),
  },
  [VOUCHER_NO]: {},
  [DATE]: {
    required: t('BookingConfirmationValidators.BOOKING_DATE'),
  },
  [VOUCHER_REMARKS]: {
    required: t('Voucher.Voucher_remark_validation'),
  },
  [REF_DOC_CATEGORY]: {
    required: t('Voucher.doc_category_validation'),
  },
  [REFERENCE_NO]: {
    required: t('Voucher.reference_no_validation'),
    pattern: {
      value: /^\d+$/,
      message: t('Voucher.only_number'),
    },
    validate: {
      handleZero: (value: any) => {
        if (value <= 0) {
          return t('Voucher.valid_number_validation')
        }
      },
    },
  },
  [ACCOUNT]: {
    required: t('Voucher.select_search_account'),
  },
  [ACCOUNT1]: {
    required: t('Voucher.search_account'),
  },
  [DEBIT_AMOUNT]: {
    validate: {
      handleAmount: (value: any, values: IAddVoucherEntries) => {
        const credit_amount = values[CREDIT_AMOUNT]
        if (!value && !credit_amount) {
          return t('Voucher.debit_value_validation')
        } else if (!value && credit_amount) {
          return undefined
        } else if (Number(value) < 0) {
          return t('LabConfiguration.positive_value')
        }
      },
    },
  },
  [CREDIT_AMOUNT]: {
    validate: {
      handleAmount: (value: any, values: IAddVoucherEntries) => {
        const debit_amount = values[DEBIT_AMOUNT]
        if (!value && !debit_amount) {
          return t('Voucher.debit_value_validation')
        } else if (!value && debit_amount) {
          return undefined
        } else if (Number(value) < 0) {
          return t('LabConfiguration.positive_value')
        }
      },
    },
  },

  [ENTRY_DATE]: {
    required: t('Voucher.entry_date_validation'),
  },
  [COST_CENTER]: {
    required: t('Voucher.cost_center_validation'),
  },
  [COST_CENTER_VALUE]: {
    required: t('Voucher.cost_value_validation'),
  },
  [ENTRY_REMARKS]: {
    required: t('Voucher.remark_entry_validation'),
  },
}
// add voucher no
export interface IAddVoucherModalValidators {
  [ADD_VOUCHER_NAME]: {
    required: string
  }
  [ADD_VOUCHER_CODE]: {
    required: string
    pattern: string
  }
}
export const addVoucherModalValidators = {
  [ADD_VOUCHER_NAME]: {
    required: t('SupplierMaster.name_validation'),
  },
  [ADD_VOUCHER_CODE]: {
    required: t('Voucher.Initials_Validation'),
    pattern: {
      value: /^[a-zA-Z\s]*$/,
      message: t('AddEmpValidator.AccHolder'),
    },
  },
}

// cost view modal
export interface IcostViewModalValidators {
  [COST_VOUCHER_NO]: {
    required: string
  }
  [ACCOUNT_NAME]: {
    required: string
  }
}
export const costViewModalValidators: IcostViewModalValidators = {
  [COST_VOUCHER_NO]: {
    required: t('Voucher.voucher_no_validation'),
  },
  [ACCOUNT_NAME]: {
    required: t('Voucher.account_name_validation'),
  },
}
