import { t } from 'i18next'

export const medicineCompositionHeaderData: any = [
  // {
  //   Header: "MEDICINE ID",
  //   accessor: "_id",
  // },
  {
    Header: t('MedicationHeader.MEDICINE NAME'),
    accessor: (row: any) => {
      return row?.name ?? '-'
    },
  },
  {
    Header: t('MedCompoisition.BRAND NAME'),
    accessor: (row: any) => {
      return row?.brand ?? '-'
    },
  },
  {
    Header: t('MedCompoisition.DRUG CLASS'),
    accessor: (row: any) => {
      return row?.drug_class ?? '-'
    },
  },
  {
    Header: t('MedCompoisition.COMPOSITION'),
    accessor: (row: any) => {
      return row?.medicines_compositions ?? '-'
    },
  },
  {
    Header: t('PatientHistoryTableHeader.NOTES'),
    Cell: (props: any) => {
      return <p>{props.row.original.notes ?? '-'}</p>
    },
  },
]

export const meicineCompositionDummyData: any = [
  {
    _id: '-',
    medicine_name: '-',
    MRP: 48,
    category: '-',
    manufacturer: '-',
    composition: '-',
    createdAt: '-',
    updatedAt: '-',
    __v: 0,
  },
]
