import axios from 'axios'
import {
  ADD_ASSET,
  ADD_ATTRIBUTE,
  GET_ALL_LEVEL_DATA,
  LEVEL_2,
  GET_GENERATE_CODE,
  UPDATE_ASSET,
} from '../../../config/config'
import { IAPIPayload } from '../../../interfaces/apiInterface'

// level1 dropdown
export const level_2 = (data: IAPIPayload) => {
  return axios.post(LEVEL_2, data)
}

// add assest
export const addAssetCrud = (data: IAPIPayload) => {
  return axios.post(ADD_ASSET, data)
}

// update assest
export const updateAssetCrud = (data: IAPIPayload) => {
  return axios.post(UPDATE_ASSET, data)
}

// get all data in table
export const getAllLevelDataCrud = (data: IAPIPayload) => {
  return axios.post(GET_ALL_LEVEL_DATA, data)
}

// post dropdown id and name
export const addAttributeCrud = (data: IAPIPayload) => {
  return axios.post(ADD_ATTRIBUTE, data)
}

// Get generate code
export const getGenrateCodeCrud = (data: IAPIPayload) => {
  return axios.post(GET_GENERATE_CODE, data)
}
