import moment from 'moment'
import { DropdownIndicator } from '../../components/common/dropdown-indicator/DropdownIndicator'
import Select from 'react-select'
import { useAppDispatch } from '../../hooks'
import {
  getInventoryAllPo,
  poInventoryUpdate,
} from '../../redux/features/inventory-request/inventoryRequestAsyncActions'
import { requestGenerator } from '../../utils/payloadGenerator'
import jsPDF from 'jspdf'
import 'jspdf-autotable'
import { numberToWordConverter } from '../../utils/utils'
import image from '../../assets/images/proactLogo.png'
import styles from './tableData.module.scss'
import { t } from 'i18next'

const resultStatusData = [
  { label: 'ENTERED' },
  { label: 'APPROVED' },
  { label: 'REJECTED' },
]

export const SubmittedpoHeaderData: any = [
  {
    Header: t('RcptTableHeader.DATE'),
    accessor: (row: any) => {
      return moment(row?.requested_date).format('DD-MMM-YYYY') ?? '-'
    },
    disableSortBy: true,
  },
  {
    Header: t('INVTableHeader.DOCID'),
    Cell: (props: any) => {
      const totalqty = props.row.original.items?.reduce(
        (acc: any, obj: any) => {
          return acc + obj.requested_qty
        },
        0
      )
      const generatePdf = () => {
        const doc: any = new jsPDF('p', 'pt', 'a4')

        const width = doc.internal.pageSize.getWidth()
        let tableData: any = []
        props.row.original.items.forEach((items: any, index: number) => {
          const totalAmount = items.requested_price * items.requested_qty
          tableData.push([
            index + 1,
            items.name,
            items.req_unit_type.value,
            items.requested_price,
            items.requested_qty,
            totalAmount?.toLocaleString(),
          ])
        })
        let offsetY = 4.797777777777778
        let lineHeight = 6.49111111111111

        const imgData = image
        doc.addImage(imgData, 'JPEG', width / 2, 20, 40, 40, {
          align: 'center',
        })
        doc
          .text('Purchase Order', width / 2, 80, { align: 'center' })
          .setFontSize(10)
          .setFont(undefined, 'bold')
        doc
          .text(`Order: ${props.row.original.po_no}`, 60, 120)
          .setFontSize(8)
          .setFont(undefined, 'bold')
        doc
          .text(`Supplier: ${props.row.original?.suppliers}`, 60, 140)
          .setFontSize(8)
          .setFont(undefined, 'bold')
        // doc
        //   .text(`Ref No: QAR849`, 400, 120)
        //   .setFontSize(8)
        //   .setFont(undefined, "bold");
        doc
          .text(
            `Date: ${moment(props.row.original.requested_date).format(
              'DD/MM/YYYY'
            )}`,
            400,
            140
          )
          .setFontSize(8)
          .setFont(undefined, 'bold')

        doc.autoTable({
          startY: 155,
          styles: { halign: 'center', textColor: '#000', fillColor: '#fefefe' },
          tableLineColor: '#000',
          tableLineWidth: 0.1,

          headStyles: { lineWidth: 0.1, lineColor: '#000' },
          bodyStyles: { fontSize: '8', lineWidth: 0.1, lineColor: '#000' },
          // footStyles: { fontSize: "10", halign: 'center' },
          footStyles: {
            fontSize: '8',
            halign: 'left',
            lineWidth: 0.1,
            lineColor: '#000',
          },

          head: [['Sr', 'Item', 'Unit', 'Price', 'Qty', 'Amount']],
          body: tableData,
          foot: [
            [
              '',
              '',
              `${numberToWordConverter(props.row.original?.total_amount)}`,
              `Total: ${props.row.original?.total_amount?.toLocaleString()}`,
              `Total Qty: ${totalqty}`,
            ],
          ],
        })
        const dynamicHeight = doc?.autoTable?.previous?.finalY

        doc
          .text(
            'Purchase Officer',
            60,
            dynamicHeight + lineHeight * 5 + offsetY
          )
          .setFontSize(8)
          .setFont(undefined, 'bold')

        doc
          .text('Incharge', 200, dynamicHeight + lineHeight * 5 + offsetY)
          .setFontSize(8)
          .setFont(undefined, 'bold')
        doc
          .text('Dept. Head', 300, dynamicHeight + lineHeight * 5 + offsetY)
          .setFontSize(8)
          .setFont(undefined, 'bold')
        doc
          .text('Accounts', 400, dynamicHeight + lineHeight * 5 + offsetY)
          .setFontSize(8)
          .setFont(undefined, 'bold')
        doc
          .text(
            'CEO for signature',
            500,
            dynamicHeight + lineHeight * 5 + offsetY
          )
          .setFontSize(8)
          .setFont(undefined, 'bold')
        doc.save('PO.pdf')
        window.open(doc.output('bloburl'), '_blank')
      }

      return (
        <>
          {props?.row?.original?.doc_id ? (
            <span
              className={
                props?.row?.original?.authorization_status === 'APPROVED'
                  ? styles.viewPdfLink
                  : styles.notViewPdfLink
              }
              onClick={generatePdf}
            >
              {props?.row?.original?.doc_id}
            </span>
          ) : (
            '-'
          )}
        </>
      )
    },
  },
  {
    Header: t('MainStore.PO NO'),
    accessor: 'po_no',
  },
  {
    Header: t('MainStore.SUPPLIER NAME'),
    accessor: 'suppliers',
  },
  {
    Header: t('RcptTableHeader.STATUS'),
    accessor: 'status',
  },
  {
    Header: t('INVRequest.AuthrizedStatus'),
    Cell: (props: any) => {
      const dispatch = useAppDispatch()
      const handlePo = (item: any) => {
        let data = {
          po_id: props.row.original._id,
          authorization_status: item.label,
        }
        dispatch(poInventoryUpdate(requestGenerator(data))).then((e: any) => {
          if (e.type === 'inventory/poInventoryUpdate/fulfilled') {
            let requestData = {
              page: 1,
              pageSize: 10,
              order_by: { name: 1 },
            }
            dispatch(getInventoryAllPo(requestGenerator(requestData)))
          }
        })
      }
      return (
        <Select
          className={styles.select}
          isDisabled={props.row.original?.authorization_status === 'APPROVED'}
          components={{ DropdownIndicator }}
          isSearchable={false}
          value={{
            label: props.row.original.authorization_status,
            value: props.row.original.authorization_status,
          }}
          options={resultStatusData?.map((item: any) => ({
            label: item.label,
            value: item.label,
          }))}
          onChange={(e: any) => handlePo(e)}
          maxMenuHeight={120}
          styles={{
            control: (provided) => ({
              ...provided,
              minWidth: '50px',
              minHeight: '30px',
              cursor: 'pointer',
            }),
          }}
        />
      )
    },
  },
]
