import axios from 'axios'
import {
  ADD_IPD_TIMELINE_MEDICATION,
  ADD_IPD_TIMELINE_NOTES,
  ADD_IPD_TREATMENT_PLAN,
  CREATE_IPD_CASE,
  DELETE_IPD_TIMELINE_NOTES,
  EDIT_IPD_TIMELINE_NOTES,
  GET_ALL_IPD_MEDICATION,
  GET_ALL_IPD_TEST,
  GET_BOOKING_IPD_CASE_DETAILS,
  GET_IPD_CASE,
  GET_IPD_TREATMENT_PLAN,
  IPD_ADD_INS_CASE_URL,
  IPD_CASE_HISTORY,
  IPD_DISCHARGE_FORM,
  UPDATE_IPD_CASE,
} from '../../../config/config'
import { IAPIPayload } from '../../../interfaces/apiInterface'

export const getAllIpdMedications = (data: IAPIPayload) => {
  return axios.post(GET_ALL_IPD_MEDICATION, data)
}

export const addIpdTimelineNotes = (data: IAPIPayload) => {
  return axios.post(ADD_IPD_TIMELINE_NOTES, data)
}

export const editIpdTimelineNotes = (data: IAPIPayload) => {
  return axios.post(EDIT_IPD_TIMELINE_NOTES, data)
}

export const deleteIpdTimelineNotes = (data: IAPIPayload) => {
  return axios.post(DELETE_IPD_TIMELINE_NOTES, data)
}

export const getAllIpdTests = (data: IAPIPayload) => {
  return axios.post(GET_ALL_IPD_TEST, data)
}

export const createIpdCase = (data: IAPIPayload) => {
  return axios.post(CREATE_IPD_CASE, data)
}

export const addIpdTimelineMedications = (data: IAPIPayload) => {
  return axios.post(ADD_IPD_TIMELINE_MEDICATION, data)
}

export const getIpdCase = (data: IAPIPayload) => {
  return axios.post(GET_IPD_CASE, data)
}

export const updateIpdCase = (data: IAPIPayload) => {
  return axios.post(UPDATE_IPD_CASE, data)
}

export const addIpdTreatmentPlan = (data: IAPIPayload) => {
  return axios.post(ADD_IPD_TREATMENT_PLAN, data)
}

export const getIpdTreatmentPlan = (data: IAPIPayload) => {
  return axios.post(GET_IPD_TREATMENT_PLAN, data)
}

export const ipdDischargeForm = (data: IAPIPayload) => {
  return axios.post(IPD_DISCHARGE_FORM, data)
}

export const viewIpdHistory = (data: IAPIPayload) => {
  return axios.post(IPD_CASE_HISTORY, data)
}

export const addInsurancetoCase = (data: IAPIPayload) => {
  return axios.post(IPD_ADD_INS_CASE_URL, data)
}

export const getIpdBookingCaseById = (data: IAPIPayload) => {
  return axios.post(GET_BOOKING_IPD_CASE_DETAILS, data)
}
