import { FC } from 'react'
import { colors } from '../../../../constants/color'
import Button from '../../button/Button'
import Divider from '../../divider/Divider'
import { CloseIcon } from '../../svg-components'
import styles from './statusConfirmationPopup.module.scss'
import { t } from 'i18next'

interface IStatusConfirm {
  handleYes?: any
  handleClose?: any
  popData?: any
}

const StatusConfirmationPopupV4: FC<IStatusConfirm> = ({
  handleYes,
  handleClose,
  popData,
}) => {
  return (
    <>
      <div
        className={styles.popupContainer}
        onClick={(e) => {
          e.stopPropagation()
        }}
      >
        <CloseIcon
          customClass={styles.closeIconStyle}
          fillColor={colors.green1}
          handleClick={() => handleClose()}
        />
        <div className={styles.statusContainer}>
          <p className={styles.title}>{t('MobileAppointmentReq.Status')}</p>
          <Divider customClass={styles.dividerStyle} />
          <p className={styles.userText}>
            {t('BranchSetup.Status Confirmation')} {popData}{' '}
            {t('MobileAppointmentReq.Status')} ?
          </p>
          <div className={styles.btnContainer}>
            <Button
              title={t('Common.Yes') || 'Yes'}
              customClass={styles.btnStyle}
              handleClick={handleYes}
            />
            <Button
              title={t('Common.No') || 'No'}
              customClass={styles.yesButton}
              handleClick={() => handleClose()}
            />
          </div>
        </div>
      </div>
    </>
  )
}

export default StatusConfirmationPopupV4
