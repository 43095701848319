import { t } from 'i18next'
import styles from './style.module.scss'
import moment from 'moment'

export const ipdViewAllData: any = [
  {
    Header: t('IPDHistory.ADMITTED DATE'),
    accessor: (row: any) => {
      return moment(row?.admit_date).format('DD-MMM-YYYY') ?? '-'
    },
  },
  {
    Header: t('IPDHistory.CASE NO'),
    accessor: 'case_no',
  },
  {
    Header: t('IPDHistory.DISCHARGE DATE'),
    accessor: (row: any) => {
      return moment(row?.discharge_date).format('DD-MMM-YYYY') ?? '-'
    },
  },
  {
    Header: t('IPDHistory.CONSULTATION'),
    Cell: (props: any) => {
      return (
        <p
          className={styles.blueLinkText}
          onClick={() => props.onRowClick(props.row.original?.conclution)}
        >
          {t('Common.View')}
        </p>
      )
    },
  },
  {
    Header: t('LabJob.TESTS'),
    Cell: (props: any) => {
      return (
        <p
          className={styles.blueLinkText}
          onClick={() => props.onClick(props.row.original.tests)}
        >
          {t('Common.View')}
        </p>
      )
    },
  },
  {
    Header: t('PatientHistoryTableHeader.MEDICATION'),
    Cell: (props: any) => {
      return (
        <p
          className={styles.blueLinkText}
          onClick={() => props.onPopClose(props.row.original.medication)}
        >
          {t('Common.View')}
        </p>
      )
    },
  },
]
