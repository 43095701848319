// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.style_tableContainer__\\+r\\+ks {
  height: 40dvh;
  overflow: auto;
}
.style_tableContainer__\\+r\\+ks::-webkit-scrollbar {
  display: block !important;
  width: 7px !important;
  height: 7px !important;
}
.style_tableContainer__\\+r\\+ks::-webkit-scrollbar-track {
  background: transparent !important;
}
.style_tableContainer__\\+r\\+ks::-webkit-scrollbar-thumb {
  background-color: var(--grey7) !important;
  border-radius: 10px !important;
  border: 1px solid var(--grey7) !important;
}

.style_link__jGafd {
  color: var(--blue1);
  font-size: var(--font-12);
  font-weight: var(--font-medium);
  cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/pages/purchase-invoice/submitted-purchase-invoice/style.module.scss"],"names":[],"mappings":"AAEA;EACE,aAAA;EACA,cAAA;AADF;AAEE;EACE,yBAAA;EACA,qBAAA;EACA,sBAAA;AAAJ;AAEE;EACE,kCAAA;AAAJ;AAGE;EACE,yCAAA;EACA,8BAAA;EACA,yCAAA;AADJ;;AAKA;EACE,mBAAA;EACA,yBAAA;EACA,+BAAA;EACA,eAAA;AAFF","sourcesContent":["@import '../../../styles/mixin.scss';\n\n.tableContainer {\n  height: 40dvh;\n  overflow: auto;\n  &::-webkit-scrollbar {\n    display: block !important;\n    width: 7px !important;\n    height: 7px !important;\n  }\n  &::-webkit-scrollbar-track {\n    background: transparent !important;\n  }\n\n  &::-webkit-scrollbar-thumb {\n    background-color: var(--grey7) !important;\n    border-radius: 10px !important;\n    border: 1px solid var(--grey7) !important;\n  }\n}\n\n.link {\n  color: var(--blue1);\n  font-size: var(--font-12);\n  font-weight: var(--font-medium);\n  cursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tableContainer": `style_tableContainer__+r+ks`,
	"link": `style_link__jGafd`
};
export default ___CSS_LOADER_EXPORT___;
