import { DeleteIcon, CrossIcon2 } from '../../components/common/svg-components'
import {
  updateIssueData,
  updateMainStoreData,
  removeIssueData,
} from '../../redux/features/branch-store/branchStoreSlice'
import { useAppDispatch, useAppSelector } from '../../hooks'
import { setMessage } from '../../redux/features/toast/toastSlice'
import { disableArrowKey, blockInvalidCharacter } from '../../utils/utils'
import { failure } from '../../constants/data'
import { colors } from '../color'
import styles from '../../pages/branchstore/branchstore/branchstore.module.scss'
import { t } from 'i18next'

export const requestIssueTableHeaderData: any = [
  {
    Header: t('InventoryItemTable.ITEM_CODE'),
    accessor: 'item_no',
    // Cell: (props: any) => {
    //   return (
    //     <>
    //       {props?.row?.original?.item_id ? (
    //         <span>{props?.row?.original?.item_id}</span>
    //       ) : (
    //         "-"
    //       )}
    //     </>
    //   );
    // },
  },
  {
    Header: t('ViewInventory.ITEM NAME'),
    accessor: 'item_name',
  },
  {
    Header: t('InventoryItemTable.UNIT_TYPE'),
    accessor: 'req_unit_type.value',
  },
  {
    Header: t('INVTableHeader.ReqStockQTY'),
    accessor: 'pending_qty',
  },
  {
    Header: t('INVTableHeader.AvailStockQTY'),
    accessor: 'available_qty',
  },
  {
    Header: t('INVTableHeader.IssueQTY'),
    disableSortBy: true,
    Cell: (props: any) => {
      const dispatch = useAppDispatch()
      const { selectedIssueData } = useAppSelector((state) => state.branchStore)
      const validateIssueQty = (issueQty: string, item: any) => {
        if (Number(issueQty) === undefined) {
          dispatch(
            setMessage({
              message: t('BranchStore.ValidQTY'),
              type: failure,
            })
          )
          return {
            ...item,
            issue_qty: Number(issueQty),
          }
        } else if (issueQty === '0') {
          dispatch(
            setMessage({
              message: t('BranchStore.ValidQTY'),
              type: failure,
            })
          )
          return item
        } else if (Number(issueQty) < 0) {
          dispatch(
            setMessage({
              message: t('BranchStore.ValidQTY'),
              type: failure,
            })
          )
          return item
        } else if (
          Number(issueQty) > Number(props?.row?.original?.pending_qty)
        ) {
          dispatch(
            setMessage({
              message: t('BranchStore.LessQTY'),
              type: failure,
            })
          )
          return item
        } else if (
          Number(issueQty) > Number(props?.row?.original?.available_qty)
        ) {
          dispatch(
            setMessage({
              message: t('BranchStore.LessAvailQTY'),
              type: failure,
            })
          )
          return item
        } else {
          return {
            ...item,
            issue_qty: Number(issueQty),
          }
        }
      }
      const handleChange = (e: any) => {
        const issueQty = e.target.value
        let tempArr: any = []
        tempArr = selectedIssueData?.map((item: any, index: number) => {
          if (item?._id === props?.row?.original?._id) {
            return validateIssueQty(issueQty, item)
          } else {
            return item
          }
        })
        dispatch(updateIssueData(tempArr))
      }
      return (
        <input
          type="number"
          value={selectedIssueData[props.row.index]?.issue_qty || ''}
          // placeholder="Text"
          className={styles.branchStoreQtyField}
          onChange={handleChange}
          onKeyDown={(e: any) => {
            disableArrowKey(e)
            blockInvalidCharacter(e)
          }}
          onWheel={(e: any) => {
            e.target.blur()
          }}
        />
      )
    },
  },
  {
    Header: t('INVTableHeader.QTYCLOSURE'),
    accessor: (row: any) => {
      return Number(row?.pending_qty) - Number(row?.issue_qty)
    },
    Cell: (props: any) => {
      return (
        <>
          {Number(props?.row?.original?.issue_qty) > 0 ? (
            <span>
              {Number(props?.row?.original?.pending_qty) -
                Number(props?.row?.original?.issue_qty)}
            </span>
          ) : (
            '-'
          )}
        </>
      )
    },
  },
  {
    Header: t('INVTableHeader.MINBAL'),
    accessor: 'qty_to_maintain',
  },
  {
    Header: t('INVTableHeader.POSTBAL'),
    accessor: (row: any) => {
      return Number(row?.available_qty) - Number(row?.issue_qty)
    },
    Cell: (props: any) => {
      return (
        <>
          {Number(props?.row?.original?.issue_qty) > 0 ? (
            <span>
              {Number(props?.row?.original?.available_qty) -
                Number(props?.row?.original?.issue_qty)}
            </span>
          ) : (
            '-'
          )}
        </>
      )
    },
  },
  {
    Header: ' ',
    Cell: (props: any) => {
      const dispatch = useAppDispatch()
      return (
        <>
          <div>
            <CrossIcon2
              fillColor1={colors.red1}
              handleClick={() => {
                dispatch(removeIssueData(props?.row?.original))
              }}
            />
          </div>
        </>
      )
    },
  },
]
