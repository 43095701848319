// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.reportBuilderList_reportPocListContainer__56y9r {
  background: var(--white2);
  box-shadow: 0px 10px 117px 14px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  padding: 20px;
  margin: 20px 20px 30px 0px;
}
.reportBuilderList_reportPocListContainer__56y9r .reportBuilderList_reportsBtnContainer__3mqID {
  display: flex;
  justify-content: flex-end;
}
.reportBuilderList_reportPocListContainer__56y9r .reportBuilderList_tableContainer__SePWJ {
  margin: 30px 0px 20px 0px;
}`, "",{"version":3,"sources":["webpack://./src/pages/reports-poc/reportBuilderList.module.scss","webpack://./src/styles/mixin.scss"],"names":[],"mappings":"AAEA;ECDE,yBAAA;EAEA,mDAAA;EACA,mBAAA;EACA,aDFqB;ECGrB,0BAAA;ADAF;AADE;EACE,aAAA;EACA,yBAAA;AAGJ;AADE;EACE,yBAAA;AAGJ","sourcesContent":["@import '../../styles/mixin.scss';\n\n.reportPocListContainer {\n  @include pageWrapper(20px);\n  // height: 70dvh;\n  .reportsBtnContainer {\n    display: flex;\n    justify-content: flex-end;\n  }\n  .tableContainer {\n    margin: 30px 0px 20px 0px;\n  }\n}\n","@mixin pageWrapper($padding) {\n  background: var(--white2);\n  // backdrop-filter: blur(6px);\n  box-shadow: 0px 10px 117px 14px rgba(0, 0, 0, 0.05);\n  border-radius: 10px;\n  padding: $padding;\n  margin: 20px 20px 30px 0px;\n}\n\n@mixin font($color, $size, $weight) {\n  color: $color;\n  font-size: $size;\n  font-weight: $weight;\n}\n\n@mixin flexCenter {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}\n\n@mixin flexVertical {\n  display: flex;\n  align-items: center;\n}\n\n@mixin flexColumn {\n  display: flex;\n  flex-direction: column;\n}\n\n@mixin responsive($width) {\n  @media (max-width: $width) {\n    @content;\n  }\n}\n\n@mixin animation($name) {\n  @keyframes #{$name} {\n    @content;\n  }\n}\n\n@mixin iconHoverText($content) {\n  cursor: pointer;\n\n  &:hover::after {\n    @include font(var(--black2), var(--font-12), var(--font-semibold));\n    @include flexCenter;\n    position: absolute;\n    content: $content;\n    background-color: var(--white1);\n    box-shadow: 0px 4px 34px rgba(0, 0, 0, 0.1);\n    border-radius: 6px;\n    width: 116px;\n    height: 36px;\n    z-index: 1;\n    opacity: 1;\n    margin: -25px 0 0 25px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"reportPocListContainer": `reportBuilderList_reportPocListContainer__56y9r`,
	"reportsBtnContainer": `reportBuilderList_reportsBtnContainer__3mqID`,
	"tableContainer": `reportBuilderList_tableContainer__SePWJ`
};
export default ___CSS_LOADER_EXPORT___;
