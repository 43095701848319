// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.campaignsLayout_mainContainer__6eu1N {
  display: flex;
  position: relative;
}
.campaignsLayout_mainContainer__6eu1N .campaignsLayout_tabContainer__q32Kv {
  width: 95%;
}
.campaignsLayout_mainContainer__6eu1N .campaignsLayout_floatingBarContainer__zSRwE {
  position: sticky;
  top: 60px;
}`, "",{"version":3,"sources":["webpack://./src/pages/call-center-admin/campaign/campaignsLayout.module.scss"],"names":[],"mappings":"AACA;EACE,aAAA;EACA,kBAAA;AAAF;AACE;EACE,UAAA;AACJ;AACE;EACE,gBAAA;EACA,SAAA;AACJ","sourcesContent":["@import \"../../../styles/mixin.scss\";\n.mainContainer {\n  display: flex;\n  position: relative;\n  .tabContainer {\n    width: 95%;\n  }\n  .floatingBarContainer {                                                                    \n    position: sticky;\n    top: 60px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"mainContainer": `campaignsLayout_mainContainer__6eu1N`,
	"tabContainer": `campaignsLayout_tabContainer__q32Kv`,
	"floatingBarContainer": `campaignsLayout_floatingBarContainer__zSRwE`
};
export default ___CSS_LOADER_EXPORT___;
