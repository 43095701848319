//Create Salary Slip
export const _id = '_id'
export const emp_id = 'emp_id'
export const MONTH = 'month'
export const YEAR = 'year'
export const SALARY_TYPE = 'salary_type'
export const BASIC_SALARY = 'basic_salary'
export const HOUR_RATE = 'hour_rate'
export const HOUR_CLOCKED = 'hour_clocked'
export const TOTAL_SALARY = 'total_salary'
export const EXPENSE_CLAIM = 'expense_claim'
export const Status = 'status'
export const STATUS_TYPE = 'status_type'
export const BONUS_TYPE = 'bonus_type'
export const BONUS_VALUE = 'bonus_value'
export const DEDUCTION_TYPE = 'deduction_type'
export const DEDUCTION_VALUE = 'deduction_value'
export const TOTAL_ALLOWANCE = 'total_allowance'
export const TOTAL_DEDUCTION = 'total_deduction'
export const NET_SALARY = 'net_salary'
export const ALLOWANCE_NAME1 = 'allowance_name1'
export const ALLOWANCE_VALUE1 = 'allowance_value1'
export const ALLOWANCE_VALUE = 'allowance_value'
export const DEDUCTION_NAME1 = 'deduction_name1'
export const DEDUCTION_VALUE1 = 'deduction_value1'
export const name_en = 'name_en'
export const STATUS = 'status'
export const WORKING_DAYS = 'working_days'
export const PRESENT_DAYS = 'prasent_days'
export const FIXED_ALLOWANCE = 'fixed_allowance'
export const FIXED_DEDUCTION = 'fixed_deduction'
