import { t } from 'i18next'
import {
  AMOUNT,
  DAYS,
  LEAVES_AVAILABLE,
  PERIOD,
  SALARY,
  SELECT_EMPLOYEE,
  SELECT_PAID_DATE,
  STATUS,
} from '../leave-encashment/leaveEncashmentConstant'

export const leaveEncashmentValidators = {
  [SELECT_EMPLOYEE]: {
    required: t('HrDocument.EmpVal'),
  },
  [SALARY]: {
    required: t('LeaveManagement.Salary_Validation'),
  },
  [PERIOD]: {
    required: t('LeaveManagement.period_validation'),
  },
  [LEAVES_AVAILABLE]: {
    required: t('LeaveManagement.available_leave'),
  },
  [DAYS]: {
    required: t('LeaveManagement.Days_validation'),
  },
  [AMOUNT]: {
    required: t('OnlinePaymentValidator.AMT'),
  },
  [SELECT_PAID_DATE]: {
    required: t('LeaveManagement.paid_date_validation'),
  },
  [STATUS]: {
    required: t('LeaveManagement.status_validation'),
  },
}
