// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.confirmpurchaseinvoicepopup_PurchaseInvoicePopupMainContainer__AqdWd {
  width: 1000px;
  margin: auto;
  background: var(--white1);
  border-radius: 10px;
  padding: 12px 12px 12px 0px;
}
.confirmpurchaseinvoicepopup_PurchaseInvoicePopupMainContainer__AqdWd .confirmpurchaseinvoicepopup_closeIconStyle__UAc1F {
  cursor: pointer;
  float: right;
}
.confirmpurchaseinvoicepopup_PurchaseInvoicePopupMainContainer__AqdWd .confirmpurchaseinvoicepopup_paymentContainer__CSMOb .confirmpurchaseinvoicepopup_title__-e93U {
  text-align: center;
  font-weight: var(--font-semibold);
  font-size: var(--font-24);
  line-height: 36px;
  color: var(--black2);
  margin-top: 10px;
}
.confirmpurchaseinvoicepopup_PurchaseInvoicePopupMainContainer__AqdWd .confirmpurchaseinvoicepopup_paymentContainer__CSMOb .confirmpurchaseinvoicepopup_dividerStyle__sVzPv {
  margin: 10px auto 30px auto;
  width: 80px;
  height: 3px;
  background: var(--green1);
}
.confirmpurchaseinvoicepopup_PurchaseInvoicePopupMainContainer__AqdWd .confirmpurchaseinvoicepopup_button__hd2ff {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
}
.confirmpurchaseinvoicepopup_PurchaseInvoicePopupMainContainer__AqdWd .confirmpurchaseinvoicepopup_tableContainer__WIAIy {
  margin-top: 42px;
  padding: 0px 24px 0px 24px;
}`, "",{"version":3,"sources":["webpack://./src/pages/purchase-invoice/confirm-purchase-invoice-popup/confirmpurchaseinvoicepopup.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,YAAA;EACA,yBAAA;EACA,mBAAA;EACA,2BAAA;AACF;AACE;EACE,eAAA;EACA,YAAA;AACJ;AAII;EACE,kBAAA;EACA,iCAAA;EACA,yBAAA;EACA,iBAAA;EACA,oBAAA;EACA,gBAAA;AAFN;AAKI;EACE,2BAAA;EACA,WAAA;EACA,WAAA;EACA,yBAAA;AAHN;AAOE;EACE,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,gBAAA;AALJ;AAQE;EACE,gBAAA;EACA,0BAAA;AANJ","sourcesContent":[".PurchaseInvoicePopupMainContainer {\n  width: 1000px;\n  margin: auto;\n  background: var(--white1);\n  border-radius: 10px;\n  padding: 12px 12px 12px 0px;\n\n  .closeIconStyle {\n    cursor: pointer;\n    float: right;\n  }\n\n  .paymentContainer {\n\n    .title {\n      text-align: center;\n      font-weight: var(--font-semibold);\n      font-size: var(--font-24);\n      line-height: 36px;\n      color: var(--black2);\n      margin-top: 10px;\n    }\n\n    .dividerStyle {\n      margin: 10px auto 30px auto;\n      width: 80px;\n      height: 3px;\n      background: var(--green1);\n    }\n  }\n\n  .button {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    margin-top: 30px;\n  }\n\n  .tableContainer {\n    margin-top: 42px;\n    padding: 0px 24px 0px 24px;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"PurchaseInvoicePopupMainContainer": `confirmpurchaseinvoicepopup_PurchaseInvoicePopupMainContainer__AqdWd`,
	"closeIconStyle": `confirmpurchaseinvoicepopup_closeIconStyle__UAc1F`,
	"paymentContainer": `confirmpurchaseinvoicepopup_paymentContainer__CSMOb`,
	"title": `confirmpurchaseinvoicepopup_title__-e93U`,
	"dividerStyle": `confirmpurchaseinvoicepopup_dividerStyle__sVzPv`,
	"button": `confirmpurchaseinvoicepopup_button__hd2ff`,
	"tableContainer": `confirmpurchaseinvoicepopup_tableContainer__WIAIy`
};
export default ___CSS_LOADER_EXPORT___;
