// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.unittypePopup_popupContainer__H72C8 {
  width: 500px;
  position: absolute;
  margin: auto;
  background: var(--white1);
  border-radius: 10px;
  padding: 10px;
}
.unittypePopup_popupContainer__H72C8 .unittypePopup_closeIconStyle__Gkl9J {
  cursor: pointer;
  float: right;
}
.unittypePopup_popupContainer__H72C8 .unittypePopup_viewAppointment__0aKzl {
  margin-top: 50px;
}
.unittypePopup_popupContainer__H72C8 .unittypePopup_viewAppointment__0aKzl .unittypePopup_title__eQwlK {
  text-align: center;
  font-weight: var(--font-semibold);
  font-size: var(--font-24);
  line-height: 36px;
  color: var(--black2);
}
.unittypePopup_popupContainer__H72C8 .unittypePopup_viewAppointment__0aKzl .unittypePopup_dividerStyle__liRkG {
  margin: 10px auto 30px auto;
  width: 250px;
  height: 3px;
  background: var(--green1);
}
.unittypePopup_popupContainer__H72C8 .unittypePopup_viewAppointment__0aKzl .unittypePopup_tableBlock__qj2TK {
  height: 100%;
  max-height: 350px;
  overflow-y: scroll;
}
.unittypePopup_popupContainer__H72C8 .unittypePopup_viewAppointment__0aKzl .unittypePopup_tableBlock__qj2TK::-webkit-scrollbar {
  display: block;
  width: 7px;
  height: 7px;
}
.unittypePopup_popupContainer__H72C8 .unittypePopup_viewAppointment__0aKzl .unittypePopup_tableBlock__qj2TK::-webkit-scrollbar-track {
  background: transparent;
}
.unittypePopup_popupContainer__H72C8 .unittypePopup_viewAppointment__0aKzl .unittypePopup_tableBlock__qj2TK::-webkit-scrollbar-thumb {
  background-color: var(--grey7);
  border-radius: 10px;
  border: 1px solid var(--grey7);
}`, "",{"version":3,"sources":["webpack://./src/pages/import-utility/unittype-popup/unittypePopup.module.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;EAEA,kBAAA;EAEA,YAAA;EACA,yBAAA;EACA,mBAAA;EACA,aAAA;AADF;AAEE;EACE,eAAA;EACA,YAAA;AAAJ;AAEE;EACE,gBAAA;AAAJ;AACI;EACE,kBAAA;EACA,iCAAA;EACA,yBAAA;EACA,iBAAA;EACA,oBAAA;AACN;AACI;EACE,2BAAA;EACA,YAAA;EACA,WAAA;EACA,yBAAA;AACN;AACI;EACE,YAAA;EACA,iBAAA;EACA,kBAAA;AACN;AAAM;EACE,cAAA;EACA,UAAA;EACA,WAAA;AAER;AAAM;EACE,uBAAA;AAER;AACM;EACE,8BAAA;EACA,mBAAA;EACA,8BAAA;AACR","sourcesContent":[".popupContainer {\n  width: 500px;\n  //   height: 0px;\n  position: absolute;\n  // top: 90px;\n  margin: auto;\n  background: var(--white1);\n  border-radius: 10px;\n  padding: 10px;\n  .closeIconStyle {\n    cursor: pointer;\n    float: right;\n  }\n  .viewAppointment {\n    margin-top: 50px;\n    .title {\n      text-align: center;\n      font-weight: var(--font-semibold);\n      font-size: var(--font-24);\n      line-height: 36px;\n      color: var(--black2);\n    }\n    .dividerStyle {\n      margin: 10px auto 30px auto;\n      width: 250px;\n      height: 3px;\n      background: var(--green1);\n    }\n    .tableBlock {\n      height: 100%;\n      max-height: 350px;\n      overflow-y: scroll;\n      &::-webkit-scrollbar {\n        display: block;\n        width: 7px;\n        height: 7px;\n      }\n      &::-webkit-scrollbar-track {\n        background: transparent;\n      }\n\n      &::-webkit-scrollbar-thumb {\n        background-color: var(--grey7);\n        border-radius: 10px;\n        border: 1px solid var(--grey7);\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"popupContainer": `unittypePopup_popupContainer__H72C8`,
	"closeIconStyle": `unittypePopup_closeIconStyle__Gkl9J`,
	"viewAppointment": `unittypePopup_viewAppointment__0aKzl`,
	"title": `unittypePopup_title__eQwlK`,
	"dividerStyle": `unittypePopup_dividerStyle__liRkG`,
	"tableBlock": `unittypePopup_tableBlock__qj2TK`
};
export default ___CSS_LOADER_EXPORT___;
