import { EditIcon } from '../../components/common/svg-components'
import ToggleSwitchV2 from '../../components/common/toggle-switch/ToggleSwitchV2'
import styles from './tableData.module.scss'
import { t } from 'i18next'

export const labComponentHeaderData: any = [
  {
    Header: t('LabConfiguration.COMPONENT'),
    accessor: 'name',
  },
  {
    Header: t('ViewReports.RANGE'),
    Cell: (props: any) => {
      return (
        <p
          className={styles.blueLinkText}
          onClick={() => {
            props?.onRowClick(props?.row?.original?.ranges)
          }}
        >
          {t('Common.View')}
        </p>
      )
    },
  },
  {
    Header: t('DocUploadHeader.ACTION'),
    Cell: (props: any) => {
      return (
        <EditIcon
          handleClick={() => props?.onClick(props?.row?.original?._id)}
        />
      )
    },
  },
  {
    Header: t('TreatmentTableHeader.STATUS'),
    Cell: (props: any) => {
      const is_active = props?.row?.original?.is_active
      return (
        <ToggleSwitchV2
          isToggled={is_active}
          handleToggle={() => props?.onPopClose(props?.row?.original)}
        />
      )
    },
  },
]
