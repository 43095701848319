import { t } from 'i18next'
import styles from '../style.module.scss'

export const ipdConsultationData: any = [
  {
    Header: t('RcptTableHeader.DATE'),
    accessor: 'date',
  },
  {
    Header: t('RcptTableHeader.DOCTOR NAME'),
    accessor: 'doctor_name',
  },
  {
    Header: t('PatientHistoryTableHeader.NOTES'),
    Cell: (props: any) => {
      return (
        <p
          className={styles.blueLinkText}
          onClick={() => props.onClick(props.row.original.notes)}
        >
          {t('Common.View')}
        </p>
      )
    },
  },
]

export const notesData: any = [
  {
    Header: t('IPDHistory.NOTES TIME'),
    accessor: 'time',
  },
  {
    Header: t('PatientHistoryTableHeader.NOTES'),
    accessor: 'note',
  },
]
