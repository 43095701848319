import { t } from 'i18next'
import { CrossIcon2 } from '../../components/common/svg-components'
import { colors } from '../color'
import styles from '../table-data/tableData.module.scss'

export const inventoryRequestTableHeaderData: any = [
  {
    Header: t('InventoryItemTable.ITEM_CODE'),
    Cell: ({ row }: any) => {
      return <p>{row.original?.id?.item_code}</p>
    },
  },
  {
    Header: t('ViewInventory.ITEM NAME'),
    accessor: 'requested_item',
    Cell: ({ row }: any) => {
      return <p>{row.original?.id?.name}</p>
    },
  },
  {
    Header: t('INVTableHeader.REQ_QTY'),
    accessor: 'requested_qty',
  },

  {
    Header: t('InventoryItemTable.UNIT_TYPE'),
    accessor: 'qty_type',
  },

  {
    Header: t('INVRequest.BRANCH_STORE'),
    accessor: 'request_destination',
  },

  {
    Header: t('INVTableHeader.Status'),
    Cell: ({ row }: any) => {
      return <p className={styles.PENDING}>{t('OngoingClaims.Initiated')}</p>
    },
  },
  {
    Header: t('INVTableHeader.AuthStatus'),
    Cell: ({ row }: any) => {
      return <p className={styles.GENERATED}>{t('INVRequest.Entered')}</p>
    },
  },

  {
    Header: ' ',
    Cell: (props: any) => {
      return (
        <>
          <div>
            <CrossIcon2
              fillColor1={colors.red1}
              handleClick={() => {
                props?.onRowClick(props?.row?.original?.id?.id)
              }}
            />
          </div>
        </>
      )
    },
  },
]
