import { useTranslation } from 'react-i18next'
import { CustomModal } from '../../../../../components/common/custom-modal/modal'
import TableV2 from '../../../../../components/common/table/tableV2/TableV2'
import {
  ipdTestNameData,
  ipdTestResultsData,
  ipdTestsData,
} from './tests-table'
import { useState } from 'react'

const IpdTestsModal = ({ popData }: any) => {
  const { t } = useTranslation()
  const [testModal, setTestModal] = useState(false)
  const [testData, setTtestData] = useState([])
  const [resultModal, setResultModal] = useState(false)
  const [resultData, setResultData] = useState([])
  const flatTestData = testData?.flatMap((s: any) => s.tests)
  const flatResultData = resultData?.flatMap((s: any) => s.tests)

  const handleClick = (items: any) => {
    setTestModal(true)
    setTtestData(items)
  }

  const handleResultsClick = (item: any) => {
    setResultModal(true)
    setResultData(item?.profile)
  }
  return (
    <>
      <CustomModal
        showModal={testModal}
        closeModal={() => setTestModal(false)}
        width="50%"
        height="400px"
        title={t('IPDHistory.Test Name') || 'Test Name'}
      >
        <div style={{ marginTop: '40px' }}>
          <TableV2
            tableHeaderData={ipdTestNameData}
            tableRowData={flatTestData}
            active={false}
          />
        </div>
      </CustomModal>
      <CustomModal
        showModal={resultModal}
        closeModal={() => setResultModal(false)}
        width="50%"
        height="400px"
        title={t('IPDHistory.Test Results') || 'Test Results'}
      >
        <div style={{ marginTop: '40px' }}>
          <TableV2
            tableHeaderData={ipdTestResultsData}
            tableRowData={flatResultData}
            active={false}
          />
        </div>
      </CustomModal>
      <div style={{ marginTop: '40px' }}>
        <TableV2
          tableHeaderData={ipdTestsData}
          tableRowData={popData}
          active={false}
          handleClick={(item: any) => handleClick(item)}
          handleRowClick={(items: any) => handleResultsClick(items)}
        />
      </div>
    </>
  )
}

export default IpdTestsModal
