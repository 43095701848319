import { t } from 'i18next'
import styles from './imagesModalTable.module.scss'

export const imagesModalHeaderData: any = [
  {
    Header: t('AllAttachmentHeader.NAME'),
    accessor: 'img_name',
    disableSortBy: true,
  },
  {
    Header: t('PatientHistoryTableHeader.IMAGES'),
    Cell: (props: any) => {
      return (
        <>
          {props?.row?.original?.img_url ? (
            <span
              className={styles.viewPopupLink}
              onClick={() => {
                props?.onClick(props?.row?.original)
              }}
            >
              {t('Common.View')}
            </span>
          ) : (
            '-'
          )}
        </>
      )
    },
  },
]
