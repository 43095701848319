import { FC } from 'react'
import styles from './insurancePlanViewDocumentPopup.module.scss'
import { CloseIcon } from '../../svg-components'
import { colors } from '../../../../constants/color'
import Divider from '../../divider/Divider'
import { useTranslation } from 'react-i18next'

interface IViewDocument {
  popData?: any
  headerData?: any
}

const InsurancePlanViewDocumentPopup: FC<IViewDocument> = ({
  popData,
  headerData,
}) => {
  let docData =
    popData?.length > 0
      ? popData?.map((item: any) => {
          return {
            uri: item,
            type: item
              ?.substring(item?.indexOf(':') + 1, item?.indexOf(';'))
              .split('/')?.[1],
          }
        })
      : []
  const { t } = useTranslation()
  return (
    <>
      <div className={styles.notesPopupContainer}>
        <CloseIcon
          customClass={styles.closeIconStyle}
          fillColor={colors.green1}
        />
        <div className={styles.notesContainer}>
          <p className={styles.title}>
            {headerData ? headerData : t('Common.Documents')}
          </p>
          <Divider customClass={styles.dividerStyle} />
          <div className={styles.mainContainer}>
            {docData?.length > 0 ? (
              docData?.map((item: any, index: any) => {
                return item.type === 'pdf' ? (
                  <iframe
                    className={styles.documentViwerStyle}
                    src={item.uri}
                    title="document_pdf"
                    key={index}
                  />
                ) : (
                  <img
                    className={styles.imageStyle}
                    src={item.uri}
                    alt="img"
                    key={index}
                  />
                )
              })
            ) : (
              <p style={{ textAlign: 'center' }}>
                {t('BranchSetup.No records found')}
              </p>
            )}
          </div>
        </div>
      </div>
    </>
  )
}

export default InsurancePlanViewDocumentPopup
