import {
  Level3_CHECK,
  Level3_CODE,
  Level3_LIST,
  Level3_NAME,
} from '../constants/constant'
import { t } from 'i18next'
export interface IaddLevel3Validators {
  [Level3_NAME]: {
    required: string
  }
  [Level3_CODE]: {}
  [Level3_CHECK]: {}
  [Level3_LIST]: {
    required: string
  }
}
export const addLevel3Validators: IaddLevel3Validators = {
  [Level3_NAME]: {
    required: t('SupplierMaster.name_validation'),
  },
  [Level3_CODE]: {},
  [Level3_CHECK]: {},
  [Level3_LIST]: {
    required: t('ViewChartOfAccount.card_validation'),
  },
}
