import {
  PUNCH_IN,
  PUNCH_OUT,
  SELECT_MONTH,
  SELECT_YEAR,
  SUB_COMPANY,
  LEAVE_TYPE,
  REASON,
  HALFDAY,
  DEPARTMENT,
  LEAVE_STATUS,
} from '../../../constants/manageAttendanceConstants'
import { IManageAttendanceModalForm } from '../../../interfaces/manageAttendanceInterface'
import { t } from 'i18next'

export const manageAttendanceValidator = {
  [SUB_COMPANY]: {
    required: '',
  },
  [SELECT_MONTH]: {
    required: '',
  },
  [SELECT_YEAR]: {
    required: '',
  },
  [DEPARTMENT]: {
    required: '',
  },
}

export const manageAllDaysAttendanceValidator = {
  [PUNCH_IN]: {
    required: t('Attendance.Punch_In_validation'),
  },
  [PUNCH_OUT]: {
    required: t('Attendance.Punch_Out_validation'),
  },
  [LEAVE_TYPE]: {
    // required: 'Leave type is Required ',
    validate: {
      handleTime: (value: any, values: IManageAttendanceModalForm) => {
        if (values?.isPaid !== 'UNPAID' && value === undefined) {
          return t('Attendance.leave_type_validation')
        } else return
      },
    },
  },
  [LEAVE_STATUS]: {
    required: t('Attendance.status_validation'),
  },
  [REASON]: {
    required: t('LeaveManagement.reason_validation'),
  },
  [HALFDAY]: {},
}
