import axios from 'axios'
import { IAPIPayload } from '../../../interfaces/apiInterface'
import {
  GET_ALL_RECEPTIONIST_WITH_BRANCH_URL,
  IMPORT_APPOINTMENT_URL,
  IMPORT_INV_ITEMS_URL,
  IMPORT_INVOICE_URL,
  IMPORT_PATIENT_EMR_URL,
  IMPORT_RECEIPT_URL,
  IMPORT_TREATMENT_SERVICES_URL,
} from '../../../config/config'

export const importPatientEmr = (data: IAPIPayload) => {
  return axios.post(IMPORT_PATIENT_EMR_URL, data)
}

export const importTreatmentSerives = (data: IAPIPayload) => {
  return axios.post(IMPORT_TREATMENT_SERVICES_URL, data)
}

export const importInvItems = (data: IAPIPayload) => {
  return axios.post(IMPORT_INV_ITEMS_URL, data)
}

export const importAppointment = (data: IAPIPayload) => {
  return axios.post(IMPORT_APPOINTMENT_URL, data)
}

export const importReceipt = (data: IAPIPayload) => {
  return axios.post(IMPORT_RECEIPT_URL, data)
}

export const importInvoice = (data: IAPIPayload) => {
  return axios.post(IMPORT_INVOICE_URL, data)
}

export const getReceptionistWithBranch = (data: IAPIPayload) => {
  return axios.post(GET_ALL_RECEPTIONIST_WITH_BRANCH_URL, data)
}
