import { useEffect, useState } from 'react'
import TableV2 from '../../../../components/common/table/tableV2/TableV2'
import styles from './agents.module.scss'
import { AgentDetailsHeader } from './AgentTableData'
import { useAppDispatch, useAppSelector } from '../../../../hooks'
import { agentAdminGetData } from '../../../../redux/features/agents/agentAsyncActions'
import { requestGenerator } from '../../../../utils/payloadGenerator'
import Pagination from '../../../../components/common/pagination/Pagination'

const AgentDetails = ({ agentDetails }: any) => {
  const { adminAgentDetails } = useAppSelector((state) => state.agent)
  const [dataPerPage, setDataPerPage] = useState<number>(10)
  const [pageIndex, setPageIndex] = useState<number>(1)
  const [totalPage, setTotalPage] = useState<number>(0)
  const [totalData, setTotalData] = useState(0)
  const pageIndexArray = () => {
    let pageIndexOptions = []
    for (let i = 1; i <= totalPage; i++) {
      pageIndexOptions.push(i)
    }
    return pageIndexOptions
  }
  const pageIndexOptions = pageIndexArray()
  const dispatch = useAppDispatch()
  useEffect(() => {
    let data = {
      page: pageIndex,
      pageSize: dataPerPage,
      agent_id: agentDetails,
    }
    dispatch(agentAdminGetData(requestGenerator(data))).then((result) => {
      setTotalPage(result.payload.lastPage)
      setTotalData(result.payload.total)
    })
  }, [agentDetails, dataPerPage, dispatch, pageIndex])
  return (
    <>
      <div className={styles.tableContainer}>
        <TableV2
          tableHeaderData={AgentDetailsHeader}
          tableRowData={adminAgentDetails}
          active={false}
        />
      </div>
      {totalData > 10 && (
        <Pagination
          setDataPerPage={setDataPerPage}
          pageIndexOptions={pageIndexOptions}
          pageIndex={pageIndex}
          setPageIndex={setPageIndex}
        />
      )}
    </>
  )
}

export default AgentDetails
