import moment from 'moment'
import { handlePadStart } from '../../utils/utils'
import { t } from 'i18next'

export const itemRequestedHeaderData: any = [
  {
    Header: t('RcptTableHeader.DATE'),
    Cell: ({ row }: any) => {
      const originalDate = row?.original?.req_date
      const formattedDate = moment(originalDate).format('DD MMM YYYY')
      return <>{formattedDate}</>
    },
  },
  {
    Header: t('INVTableHeader.DOCID'),
    accessor: 'doc_id',
  },
  {
    Header: t('INVTableHeader.REQID'),
    accessor: 'req_no',
    Cell: ({ row }: any) => {
      const originalId = row?.original?.req_no
      const reqId = handlePadStart(originalId)
      return <p>{reqId}</p>
    },
  },
  {
    Header: t('INVTableHeader.SOURCE'),
    accessor: 'source',
  },
  {
    Header: t('INVTableHeader.SUB_SOURCE'),
    accessor: 'subsource',
    Cell: (props: any) => {
      return (
        <>
          {props?.row?.original?.subsource ? (
            <span>{props?.row?.original?.subsource}</span>
          ) : (
            '-'
          )}
        </>
      )
    },
  },
  {
    Header: t('ViewInventory.ITEM NAME'),
    accessor: 'item_name',
  },
  {
    Header: t('InventoryItemTable.UNIT_TYPE'),
    accessor: 'unit_type',
  },
  {
    Header: t('INVTableHeader.REQ_QTY'),
    accessor: 'requested_qty',
  },
  {
    Header: t('INVRequest.ISSUE_QTY'),
    accessor: 'fulfilled_qty',
  },
  {
    Header: t('INVRequest.Remaining_QTY'),
    accessor: 'remaining_qty',
  },
]
