import { createSlice } from '@reduxjs/toolkit'
import {
  addLeave,
  deleteLeave,
  getAllAtachs,
  getAllLeave,
  getLeaveById,
  getLeaveCalcuations,
  getLeaveTable,
  getSalary,
  updateLeaveStatus,
} from './leaveAsyncActions'
export interface ILeave {
  isLoading: boolean
  allLeaveData: {}
  allSalary: {}
  allLeaveTable: []
  allCalculation: []
  leaveDetail: {}
  addLeaveData: []
  editLeaveData: {}
  error: any
  isStatusUpdated: boolean
  getAllLeavePaylod: {}
  getAllAtachsPaylod: {}
  allAtchsData: []
  employeeData: []
  selectedObj: {}
  leaveDataInfo: {}
}
export const initialState: ILeave = {
  isLoading: false,
  allLeaveData: [],
  allSalary: [],
  allLeaveTable: [],
  allCalculation: [],
  allAtchsData: [],
  leaveDetail: {},
  addLeaveData: [],
  editLeaveData: {},
  isStatusUpdated: true,
  error: null,
  getAllLeavePaylod: {},
  getAllAtachsPaylod: [],
  employeeData: [],
  selectedObj: {},
  leaveDataInfo: {},
}

export const leaveSlice = createSlice({
  name: 'leave',
  initialState,
  reducers: {
    clearLeaveData: (state) => {
      state.allLeaveData = []
      state.allSalary = []
      state.allLeaveTable = []
      state.allCalculation = []
      state.leaveDetail = {}
      state.editLeaveData = {}
      state.getAllLeavePaylod = {}
      state.getAllAtachsPaylod = []
      state.allAtchsData = []
      state.leaveDataInfo = {}
    },
    getEditLeaveData: (state, action) => {
      state.editLeaveData = action.payload
    },
    clearEditLeaveData: (state) => {
      state.editLeaveData = {}
    },
    getAllLeavePayloadData: (state, action) => {
      state.getAllLeavePaylod = action.payload
    },
    getAllAttachsPayloadData: (state, action) => {
      state.getAllAtachsPaylod = action.payload
    },
    clearallLeaveTable: (state) => {
      state.allLeaveTable = []
      state.leaveDataInfo = {}
    },
    clearAllCalculation: (state) => {
      state.allCalculation = []
    },
  },
  extraReducers(builder) {
    // GET ALL LEAVE
    builder
      .addCase(getAllLeave.pending, (state) => {
        state.isLoading = true
        state.isStatusUpdated = false
      })
      .addCase(getAllLeave.fulfilled, (state, action) => {
        state.isLoading = false
        state.isStatusUpdated = false
        state.allLeaveData = action.payload?.data
      })
      .addCase(getAllLeave.rejected, (state, error) => {
        state.isLoading = false
        state.error = error
        state.isStatusUpdated = false
      })
    // GET SALARY
    builder
      .addCase(getSalary.pending, (state) => {
        state.isLoading = true
        state.isStatusUpdated = false
      })
      .addCase(getSalary.fulfilled, (state, action) => {
        state.isLoading = false
        state.isStatusUpdated = false
        state.allSalary = action.payload
      })
      .addCase(getSalary.rejected, (state, error) => {
        state.isLoading = false
        state.error = error
        state.isStatusUpdated = false
      })
    //ATTACH
    builder
      .addCase(getAllAtachs.pending, (state) => {
        state.isLoading = true
        state.isStatusUpdated = false
      })
      .addCase(getAllAtachs.fulfilled, (state, action) => {
        state.isLoading = false
        state.isStatusUpdated = false
        state.allAtchsData = action.payload
      })
      .addCase(getAllAtachs.rejected, (state, error) => {
        state.isLoading = false
        state.error = error
        state.isStatusUpdated = false
      })
    // Leave Table Data
    builder
      .addCase(getLeaveTable.pending, (state) => {
        state.isLoading = true
        state.isStatusUpdated = false
      })
      .addCase(getLeaveTable.fulfilled, (state, action) => {
        state.isLoading = false
        state.isStatusUpdated = false
        state.allLeaveTable = action.payload?.employee_data
        state.leaveDataInfo = action.payload
      })
      .addCase(getLeaveTable.rejected, (state, error) => {
        state.isLoading = false
        state.error = error
        state.isStatusUpdated = false
      })
    //Get Total Days
    builder
      .addCase(getLeaveCalcuations.pending, (state) => {
        state.isLoading = true
        state.isStatusUpdated = false
      })
      .addCase(getLeaveCalcuations.fulfilled, (state, action) => {
        state.isLoading = false
        state.isStatusUpdated = false
        state.allCalculation = action.payload
      })
      .addCase(getLeaveCalcuations.rejected, (state, error) => {
        state.isLoading = false
        state.error = error
        state.isStatusUpdated = false
      })

    // ADD LEAVE
    builder
      .addCase(addLeave.pending, (state) => {
        state.isLoading = true
      })
      .addCase(addLeave.fulfilled, (state, action) => {
        state.isLoading = false
        state.addLeaveData = action.payload
      })
      .addCase(addLeave.rejected, (state, error) => {
        state.isLoading = false
        state.error = error
      })
    //EDIT LEAVE
    builder
      .addCase(getLeaveById.pending, (state) => {
        state.isLoading = true
      })
      .addCase(getLeaveById.fulfilled, (state, action) => {
        state.isLoading = false
        state.leaveDetail = action.payload
      })
      .addCase(getLeaveById.rejected, (state, error) => {
        state.isLoading = false
        state.error = error
      })
    // UPDATE LEAVE STATUS
    builder
      .addCase(updateLeaveStatus.pending, (state) => {
        state.isLoading = true
        state.isStatusUpdated = false
      })
      .addCase(updateLeaveStatus.fulfilled, (state) => {
        state.isLoading = false
        state.isStatusUpdated = true
      })
      .addCase(updateLeaveStatus.rejected, (state, error) => {
        state.isLoading = false
        state.isStatusUpdated = false
        state.error = error
      })
    //DELETE LEAVE
    builder
      .addCase(deleteLeave.pending, (state) => {
        state.isLoading = true
      })
      .addCase(deleteLeave.fulfilled, (state) => {
        state.isLoading = false
      })
      .addCase(deleteLeave.rejected, (state, error) => {
        state.isLoading = false
        state.error = error
      })
  },
})
export const {
  clearLeaveData,
  getEditLeaveData,
  clearEditLeaveData,
  getAllLeavePayloadData,
  clearallLeaveTable,
  clearAllCalculation,
} = leaveSlice.actions
export default leaveSlice.reducer
