import { useNavigate } from 'react-router-dom'
import ToggleSwitchV2 from '../../components/common/toggle-switch/ToggleSwitchV2'
import { useAppDispatch, useAppSelector } from '../../hooks'
import { requestGenerator } from '../../utils/payloadGenerator'
import jsPDF from 'jspdf'
import { EditIcon, EyeIcon, Pdf } from '../../components/common/svg-components'
import { colors } from '../color'
import styles from './tableCustom.module.scss'
import {
  getAllPayroll,
  updatePayrollStatus,
} from '../../redux/features/payroll/payrollAsyncActions'
import { UPDATE_PAYROLL_STATUS } from '../asyncActionsType'
import { t } from 'i18next'

export const payrollTableHeaderData = [
  {
    Header: t('AllAttachmentHeader.NAME'),
    accessor: (row: any) => {
      return `${row?.name}`
    },
  },
  {
    Header: t('Payroll.BASIC_SALARY'),
    accessor: (row: any) => {
      return `${row?.basic_salary ? row?.basic_salary : '-'}`
    },
  },
  {
    Header: t('Payroll.WORKING_DAYS'),
    accessor: (row: any) => {
      return `${row.working_days}`
    },
  },
  {
    Header: t('Payroll.PRESENT_DAYS'),
    accessor: (row: any) => {
      return `${row.prasent_days}`
    },
  },
  {
    Header: t('Payroll.PAID_LEAVE'),
    accessor: (row: any) => {
      return `${row.paid_leave}`
    },
  },
  {
    Header: t('Payroll.HOURLY_RATE'),
    accessor: (row: any) => {
      return `${row.hour_rate ? row.hour_rate : '-'}`
    },
  },
  {
    Header: t('Payroll.HOURLY_CLOCKED'),
    accessor: (row: any) => {
      return `${row.hour_clocked ? row.hour_clocked : '-'}`
    },
  },
  {
    Header: t('Payroll.GROSS_SALARY'),
    accessor: (row: any) => {
      return `${row.total_salary}`
    },
  },
  {
    Header: t('Payroll.TOTAL_ALLOWANCE'),
    accessor: (row: any) => {
      return `${row.total_allowance}`
    },
  },
  {
    Header: t('Payroll.TOTAL_DEDUCTION'),
    accessor: (row: any) => {
      return `${row.total_deduction}`
    },
  },
  {
    Header: t('Payroll.NET_SALARY'),
    accessor: (row: any) => {
      return `${row.net_salary}`
    },
  },
  {
    Header: t('RcptTableHeader.STATUS'),
    disableSortBy: true,
    accessor: 'status',
    Cell: ({ row }: any) => {
      const is_active = row?.original?.status
      const dispatch = useAppDispatch()
      const handleToggle = (item: any) => {
        const payload = {
          id: item._id,
          status: !item.status,
        }
        dispatch(updatePayrollStatus(requestGenerator(payload))).then((e) => {
          if (e.type === `${UPDATE_PAYROLL_STATUS}/fulfilled`) {
            dispatch(getAllPayroll(requestGenerator({})))
          }
        })
      }
      return (
        <ToggleSwitchV2
          isToggled={is_active}
          handleToggle={() => handleToggle(row?.original)}
        />
      )
    },
  },
  {
    Header: t('RequestDiagnosis.ACTIONS'),
    Cell: ({ row, onOpen, props }: any) => {
      const { userData } = useAppSelector((state) => state.login)

      const handleDelete = () => {
        onOpen({
          isDeleteDialogOpen: true,
          doc_id: row?.original?._id,
        })
      }
      const navigate = useNavigate()
      const handleEdit = (item: any) => {
        if (item.status) {
          navigate('create', {
            state: { user: item },
          })
        } else {
        }
      }

      const handlePdf = () => {
        const doc: any = new jsPDF('p', 'pt', 'a4')
        const width = doc.internal.pageSize.getWidth()
        let maxValueArray: any = []
        let minValueArray: any = []
        let allownceDeductionData: any = []
        if (
          row?.original?.fixed_allowance.length >
          row?.original?.fixed_deduction.length
        ) {
          maxValueArray = row?.original?.fixed_allowance
          minValueArray = row?.original?.fixed_deduction
        } else {
          maxValueArray = row?.original?.fixed_deduction
          minValueArray = row?.original?.fixed_allowance
        }
        for (let ii = 0; ii < maxValueArray.length; ii++) {
          allownceDeductionData.push({
            ...maxValueArray[ii],
            ...minValueArray[ii],
          })
        }
        let totalAmount =
          Number(row?.original?.total_allowance ?? 0) +
          Number(row?.original?.basic_salary ?? 0)
        let tableData: any = [
          ['Basic Salary', `${row?.original?.basic_salary ?? '0'}`],
        ]
        allownceDeductionData?.forEach((item: any) => {
          tableData.push([
            item?.allowance_name,
            item?.allowance_value,
            item?.deduction_name,
            item?.deduction_value,
          ])
        })

        doc
          .text(`${userData?.mc_name}`, width / 2, 80, {
            align: 'center',
          })
          .setFontSize(10)
          .setFont(undefined, 'bold')
        doc
          .text(`Employee ID:  ${row.original.emp_id}`, 60, 120)
          .setFontSize(10)
          .setFont(undefined, 'bold')
        doc
          .text(`Working Days: ${row?.original?.working_days}`, 60, 140)
          .setFontSize(10)
          .setFont(undefined, 'bold')
        doc
          .text(`Paid Leave: ${row?.original?.paid_leave}`, 60, 160)
          .setFontSize(10)
          .setFont(undefined, 'bold')
        doc
          .text(`Employee Name: ${row?.original?.name}`, 400, 120)
          .setFontSize(10)
          .setFont(undefined, 'bold')
        doc
          .text(`Present Days: ${row?.original?.prasent_days}`, 400, 140)
          .setFontSize(10)
          .setFont(undefined, 'bold')
        doc
          .text(`Month: ${row?.original?.month}`, 400, 160)
          .setFontSize(10)
          .setFont(undefined, 'bold')

        doc.autoTable({
          startY: 200,
          styles: {
            halign: 'center',
            textColor: '#000',
            fillColor: '#fefefe',
          },
          rowStyles: {
            0: { textColor: '#87778' },
          },
          tableLineColor: '#000',
          tableLineWidth: 0.1,

          headStyles: { lineWidth: 0.1, lineColor: '#000' },
          bodyStyles: {
            fontSize: '8',
            lineWidth: 0.1,
            lineColor: '#000',
          },
          footStyles: {
            textColor: '#000',
            lineWidth: 0.1,
            lineColor: '#000',
          },

          head: [['Earning', 'Amount', 'Deduction', 'Amount']],
          body: tableData,
          foot: [
            [
              'Total',
              `${totalAmount}`,
              'Total',
              `${row?.original?.total_deduction}`,
            ],
          ],
        })
        doc
          .text(`Net Salary: ${row?.original?.net_salary}`, 60, 460)
          .setFontSize(10)
          .setFont(undefined, 'bold')
        doc
          .text(`Signature: `, 400, 460)
          .setFontSize(10)
          .setFont(undefined, 'bold')

        doc.save('payroll.pdf')
        window.open(doc.output('bloburl'), '_blank')
      }

      return (
        <>
          <div className={styles.docTableActions}>
            {/* <OpenEyeIcon fillColor={colors.grey4}
              customClass={styles.deleteIcon} /> */}
            <EditIcon
              fillColor={colors.grey4}
              handleClick={() => {
                if (row?.original?.isEdit) {
                  handleEdit(row?.original)
                } else return
              }}
              customClass={
                row?.original?.isEdit ? styles.editStyle : styles.notEditStyle
              }
            />
            <Pdf
              fillColor={colors.grey4}
              customClass={styles.deleteIcon}
              handleClick={handlePdf}
            />
            {/* <DeleteIcon fillColor={colors.grey4} customClass={styles.deleteIcon} handleClick={handleDelete}/> */}
          </div>
        </>
      )
    },
  },
]

export const endservicesTableHeaderData: any = [
  {
    Header: t('InsuranceHeader.ID'),
    accessor: 'id',
  },
  {
    Header: t('LeaveManagement.EMPLOYEE_ID'),
    accessor: 'employee',
  },
  {
    Header: t('AllAttachmentHeader.NAME'),
    accessor: 'name',
  },
  {
    Header: t('Payroll.EOS_DATE'),
    accessor: 'eos',
  },
  {
    Header: t('Payroll.SEPARATION_TYPE'),
    accessor: 'separation',
  },
  {
    Header: t('Payroll.ON'),
    accessor: 'on',
  },
  {
    Header: t('RcptTableHeader.STATUS'),
    accessor: 'status',
    Cell: ({ row }: any) => {
      return (
        <>
          {row?.original?.status ? (
            <span
              className={
                row?.original?.status === 'Paid'
                  ? styles.statusGreen
                  : row?.original?.status === 'Unpaid'
                  ? styles.statusRed
                  : row?.original?.status === 'Posted'
                  ? styles.activityStatusGreen
                  : ''
              }
            >
              {row?.original?.status}
            </span>
          ) : (
            '-'
          )}
        </>
      )
    },
  },
  {
    Header: t('RequestDiagnosis.ACTIONS'),
    Cell: () => {
      return (
        <>
          <div className={styles.voucherTableActions}>
            <EyeIcon fillColor={colors.grey4} customClass={styles.open} />
            <EditIcon
              fillColor={colors.grey4}
              customClass={styles.deleteIcon}
            />
          </div>
        </>
      )
    },
  },
]

export const endservcesRowData: any = [
  {
    id: '1',
    employee: 'EMP 101',
    name: 'Linda Metzmai',
    eos: 'March 2022',
    separation: '40000',
    on: '1 Mar 2024',
    status: 'Paid',
  },
  {
    id: '2',
    employee: 'EMP 102',
    name: 'Bradford Hill',
    eos: 'Jan 2022',
    separation: '20000',
    on: '1 Mar 2024',
    status: 'Unpaid',
  },
  {
    id: '3',
    employee: 'EMP 102',
    name: 'Priya Rajdive',
    eos: 'Feb 2022',
    separation: '20000',
    on: '1 Mar 2024',
    status: 'Paid',
  },
  {
    id: '3',
    employee: 'EMP 102',
    name: 'Priya Rajdive',
    eos: 'Feb 2022',
    separation: '20000',
    on: '1 Mar 2024',
    status: 'Paid',
  },
  {
    id: '4',
    employee: 'EMP 104',
    name: 'General Mertz',
    eos: 'Feb 2022',
    separation: '50000',
    on: '1 Mar 2024',
    status: 'Paid',
  },
  {
    id: '5',
    employee: 'EMP 105',
    name: 'Clayd Olson',
    eos: 'Jun 2022',
    separation: '30000',
    on: '1 Mar 2024',
    status: 'Unpaid',
  },
  {
    id: '6',
    employee: 'EMP 106',
    name: 'Giovanni Batz',
    eos: 'Aug 2022',
    separation: '25000',
    on: '1 Mar 2024',
    status: 'Unpaid',
  },
]
