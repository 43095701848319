import { t } from 'i18next'
import moment from 'moment'

export const addGrnHeaderData: any = [
  {
    Header: t('RcptTableHeader.DATE'),
    accessor: (row: any) => {
      return moment(row?.requested_date).format('DD-MMM-YYYY') ?? '-'
    },
  },
  {
    Header: t('MainStore.PO NO'),
    accessor: (row: any) => {
      return row.po_no ?? '-'
    },
  },
  {
    Header: t('ViewInventory.ITEM NAME'),
    accessor: 'name',
  },

  {
    Header: t('INVTableHeader.REQ_QTY'),
    accessor: (row: any) => {
      return row.requested_qty ?? '-'
    },
  },
  {
    Header: t('InsurancePlan_Form.PRICE'),
    accessor: (row: any) => {
      return row.requested_price ?? '-'
    },
  },
  {
    Header: t('MainStore.DELIVERED QTY'),
    Cell: (props: any) => {
      return <>{props?.row?.original?.delievered_qty ?? '-'}</>
    },
  },
]
