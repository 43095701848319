import { FC, useEffect } from 'react'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import Tabs from '../../../components/core/tab/Tabs'
import { campaignsFloatingBarData, leadTabData } from '../../../constants/data'
import FloatingBar from '../../../components/common/floatingbar/FloatingBar'
import styles from './leadlayout.module.scss'
interface ILeadLayout {}
const LeadLayout: FC<ILeadLayout> = () => {
  const navigate = useNavigate()
  const location = useLocation().pathname
  useEffect(() => {
    if (location === '/lead') {
      navigate('/lead/leads')
    }
  })
  return (
    <>
      <div className={styles.mainContainer}>
        <div className={styles.tabContainer}>
          <Tabs tabData={leadTabData} />
          <Outlet />
        </div>
        {/* <div className={styles.floatingBarContainer}>
          <FloatingBar floatingBarData={campaignsFloatingBarData}/>
        </div> */}
      </div>
    </>
  )
}
export default LeadLayout
