import {
  SERVICE_DEPARTMENT,
  SERVICE_CODE,
  SERVICE_NAME,
  SERVICE_PRICE,
  SERVICE_COST,
  SERVICE_QTY,
  SERVICE_UNIT_TYPE,
  SERVICE_BALANCE,
  SERVICE_SOURCE,
  SERVICE_GROUP,
  SERVICE_LOCATION,
  SERVICE_SESSION,
  SERVICE_STATUS,
  SERVICE_NUMBER_SESSION,
} from '../../../constants/constant'
import { t } from 'i18next'

export const addServiceValidator = {
  [SERVICE_DEPARTMENT]: {
    required: t('DepartmentSetup.Dept validation'),
  },
  [SERVICE_CODE]: {
    required: t('DepartmentSetup.Dept validation'),
  },
  [SERVICE_NAME]: {
    required: t('ServiceMaster.service_validation'),
  },
  [SERVICE_PRICE]: {
    required: t('BedSetup.sell_price_placeholder'),
  },
  [SERVICE_COST]: {
    required: t('BedSetup.cost_price_placeholder'),
  },
  [SERVICE_QTY]: {
    required: 'Please Enter Qty',
  },
  [SERVICE_UNIT_TYPE]: {
    required: 'Please select Unit Type',
  },
  [SERVICE_BALANCE]: {
    required: 'Please Enter Mininum Qty Balance',
  },
  [SERVICE_SOURCE]: {
    required: 'Please select salary type',
  },
  [SERVICE_GROUP]: {
    required: 'Please Select Group ',
  },
  [SERVICE_LOCATION]: {
    required: 'Please Enter Locationb',
  },
  [SERVICE_NUMBER_SESSION]: {
    required: t('ServiceMaster.No_of_Sessions_validation'),
    validate: {
      handleValue: (value: any) => {
        if (value <= 0 || value > 99) {
          return t('BookingConfirmationValidators.SESSIONNO')
        }
      },
    },
  },
  [SERVICE_SESSION]: {
    required: 'Please Enter Session Time',
  },
  [SERVICE_STATUS]: {
    required: 'Please Select Status ',
  },
}
