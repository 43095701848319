// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.prescriptionPopup_notesPopupContainer__NJMxO {
  width: 400px;
  margin: auto;
  background: var(--white1);
  border-radius: 10px;
  padding: 10px;
}
.prescriptionPopup_notesPopupContainer__NJMxO .prescriptionPopup_closeIconStyle__TxHXA {
  cursor: pointer;
  float: right;
}
.prescriptionPopup_notesPopupContainer__NJMxO .prescriptionPopup_notesContainer__PG1YP {
  margin-top: 50px;
}
.prescriptionPopup_notesPopupContainer__NJMxO .prescriptionPopup_notesContainer__PG1YP .prescriptionPopup_title__G4\\+Cz {
  text-align: center;
  font-weight: var(--font-semibold);
  font-size: var(--font-24);
  line-height: 36px;
  color: var(--black2);
}
.prescriptionPopup_notesPopupContainer__NJMxO .prescriptionPopup_notesContainer__PG1YP .prescriptionPopup_dividerStyle__F7lYI {
  margin: 10px auto 30px auto;
  width: 80px;
  height: 3px;
  background: var(--green1);
}
.prescriptionPopup_notesPopupContainer__NJMxO .prescriptionPopup_notesContainer__PG1YP .prescriptionPopup_descriptionText__e14Zp {
  margin: 0px 0 20px 20px;
  font-weight: var(--font-normal);
  font-size: var(--font-18);
  color: var(--grey4);
  max-height: 180px;
  overflow-x: hidden;
  text-align: left;
}
.prescriptionPopup_notesPopupContainer__NJMxO .prescriptionPopup_notesContainer__PG1YP .prescriptionPopup_descriptionText__e14Zp::-webkit-scrollbar {
  display: block;
  width: 7px;
  height: 7px;
}
.prescriptionPopup_notesPopupContainer__NJMxO .prescriptionPopup_notesContainer__PG1YP .prescriptionPopup_descriptionText__e14Zp::-webkit-scrollbar-track {
  background: transparent;
}
.prescriptionPopup_notesPopupContainer__NJMxO .prescriptionPopup_notesContainer__PG1YP .prescriptionPopup_descriptionText__e14Zp::-webkit-scrollbar-thumb {
  background-color: var(--grey7);
  border-radius: 10px;
  border: 1px solid var(--grey7);
}`, "",{"version":3,"sources":["webpack://./src/components/common/modal/prescription-popup/prescriptionPopup.module.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;EAEA,YAAA;EACA,yBAAA;EACA,mBAAA;EACA,aAAA;AAAF;AAEE;EACE,eAAA;EACA,YAAA;AAAJ;AAGE;EACE,gBAAA;AADJ;AAGI;EACE,kBAAA;EACA,iCAAA;EACA,yBAAA;EACA,iBAAA;EACA,oBAAA;AADN;AAII;EACE,2BAAA;EACA,WAAA;EACA,WAAA;EACA,yBAAA;AAFN;AAKI;EACE,uBAAA;EACA,+BAAA;EACA,yBAAA;EACA,mBAAA;EACA,iBAAA;EAEA,kBAAA;EACA,gBAAA;AAJN;AAMM;EACE,cAAA;EACA,UAAA;EACA,WAAA;AAJR;AAOM;EACE,uBAAA;AALR;AAQM;EACE,8BAAA;EACA,mBAAA;EACA,8BAAA;AANR","sourcesContent":[".notesPopupContainer {\n  width: 400px;\n  // height: 250px;\n  margin: auto;\n  background: var(--white1);\n  border-radius: 10px;\n  padding: 10px;\n\n  .closeIconStyle {\n    cursor: pointer;\n    float: right;\n  }\n\n  .notesContainer {\n    margin-top: 50px;\n\n    .title {\n      text-align: center;\n      font-weight: var(--font-semibold);\n      font-size: var(--font-24);\n      line-height: 36px;\n      color: var(--black2);\n    }\n\n    .dividerStyle {\n      margin: 10px auto 30px auto;\n      width: 80px;\n      height: 3px;\n      background: var(--green1);\n    }\n\n    .descriptionText {\n      margin: 0px 0 20px 20px;\n      font-weight: var(--font-normal);\n      font-size: var(--font-18);\n      color: var(--grey4);\n      max-height: 180px;\n      // overflow-y: scroll;\n      overflow-x: hidden;\n      text-align: left;\n\n      &::-webkit-scrollbar {\n        display: block;\n        width: 7px;\n        height: 7px;\n      }\n\n      &::-webkit-scrollbar-track {\n        background: transparent;\n      }\n\n      &::-webkit-scrollbar-thumb {\n        background-color: var(--grey7);\n        border-radius: 10px;\n        border: 1px solid var(--grey7);\n      }\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"notesPopupContainer": `prescriptionPopup_notesPopupContainer__NJMxO`,
	"closeIconStyle": `prescriptionPopup_closeIconStyle__TxHXA`,
	"notesContainer": `prescriptionPopup_notesContainer__PG1YP`,
	"title": `prescriptionPopup_title__G4+Cz`,
	"dividerStyle": `prescriptionPopup_dividerStyle__F7lYI`,
	"descriptionText": `prescriptionPopup_descriptionText__e14Zp`
};
export default ___CSS_LOADER_EXPORT___;
