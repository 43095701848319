import {
  AGENT_NAME,
  AGE_FROM,
  AGE_TO,
  CAMPAIGN_NAME,
  END_DATE,
  EXTERNAL_SOURCE,
  GENDER,
  NATIONALITY,
  NO_OF_LEADS,
  NO_OF_TARGET_AUDIENCE,
  PIN_CODE,
  START_DATE,
  TAGS_SOURCE,
} from './createCampaignsConstant'
import { ICreateCampaignForm } from './createCampaignsInterface'
import { t } from 'i18next'

export const createCampaignsValidators = {
  [CAMPAIGN_NAME]: {
    required: t('Campaigns.Create_Campaign_Validation'),
  },

  [START_DATE]: {
    required: t('BookingConfirmationValidators.RECURRING_START_DATE'),
  },
  [END_DATE]: {
    required: t('Campaigns.end_date_validation'),
    validate: {
      handleTime: (value: string, values: ICreateCampaignForm) => {
        const startDate = values[START_DATE]
        if (value <= startDate) {
          return t('Campaigns.date_range_validation')
        }
      },
    },
  },
  // [TARGET_AUDIENCE]: {
  //   required: 'Please enter target audience',
  // },
  [NO_OF_TARGET_AUDIENCE]: {
    // required: 'Please enter no of target audience',
    validate: {
      handleZero: (value: any) => {
        if (value <= 0) {
          return t('Campaigns.target_audience_validation')
        }
      },
    },
  },
  [GENDER]: {
    required: 'Please select gender',
  },
  [AGE_FROM]: {
    required: 'Please enter age from',
    validate: {
      handleValue: (value: any) => {
        if (Number(value) < 0) {
          return 'Please enter positive value'
        }
      },
    },
  },
  [AGE_TO]: {
    required: 'Please enter age to',
    validate: {
      handleValue: (value: any, data: any) => {
        if (Number(value) < 0) {
          return 'Please enter positive value'
        } else if (Number(value) <= Number(data.age_from)) {
          return 'Age to should greater than Age from'
        }
      },
    },
  },
  [NATIONALITY]: {
    required: 'Please select nationality',
  },
  [PIN_CODE]: {
    required: 'Please enter pincode',
    pattern: {
      value: /^\d{4,10}$/,
      message: 'Please enter valid 4 to 10 digit pin code',
    },
  },
  [TAGS_SOURCE]: {
    required: 'Please enter tags source',
  },
  [EXTERNAL_SOURCE]: {
    required: 'Please enter external source',
  },
}

export const assignAgentValidators = {
  [AGENT_NAME]: {
    required: 'Please select agent',
  },
  [NO_OF_LEADS]: {
    required: 'Please enter no of leads',
    validate: {
      handleValue: (value: any) => {
        if (value <= 0) {
          return 'Please enter positive values'
        }
      },
    },
  },
}
