import { FC } from 'react'
import { colors } from '../../../../constants/color'
import { utcToDate } from '../../../../utils/utils'
import Divider from '../../divider/Divider'
import { CloseIcon } from '../../svg-components'
import styles from './notesPopup.module.scss'
import { t } from 'i18next'

interface IPropsData {
  popData?: any
  handleClose: any
}
const NotesPopup: FC<IPropsData> = ({ popData, handleClose }) => {
  return (
    <>
      <div
        className={styles.notesPopupContainer}
        onClick={(e) => {
          e.stopPropagation()
        }}
      >
        <CloseIcon
          customClass={styles.closeIconStyle}
          fillColor={colors.green1}
          handleClick={() => handleClose()}
        />
        <div className={styles.notesContainer}>
          <p className={styles.title}>{t('Common.Notes')}</p>
          <Divider customClass={styles.dividerStyle} />
          <div className={styles.dateInfo}>
            {popData?.updatedAt && (
              <>
                <p className={styles.dateText}>
                  {t('DepartmentSetup.Latest Updated')}
                </p>
                <p className={styles.spanText}>
                  {utcToDate(popData?.updatedAt) ?? ''}
                </p>
              </>
            )}

            <p className={styles.descriptionText}>{popData?.notes}</p>
          </div>
        </div>
      </div>
    </>
  )
}

export default NotesPopup
