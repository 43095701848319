import { t } from 'i18next'

export const medicationModalHeaderData: any = [
  {
    Header: t('MedicationHeader.MEDICINE NAME'),
    accessor: 'med_name',
    disableSortBy: true,
  },
  {
    Header: t('MedicationHeader.QTY'),
    accessor: 'med_qty',
    disableSortBy: true,
  },
  {
    Header: t('MedicationHeader.DURATION'),
    accessor: 'med_duration',
    disableSortBy: true,
  },
  {
    Header: t('MedicationHeader.FREQUENCY'),
    accessor: 'med_frequency',
    disableSortBy: true,
  },
]
