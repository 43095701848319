import { useEffect, useState } from 'react'
import {
  Column,
  TableOptions,
  useGlobalFilter,
  useSortBy,
  useTable,
} from 'react-table'
import { trimValue } from '../../../../utils/utils'
import {
  InfoIcon,
  SearchIcon,
} from '../../../../components/common/svg-components'
import { colors } from '../../../../constants/color'
import SmartSearch from '../../../../components/common/smart-search/SmartSearch'
import TableV3 from '../../../../components/common/table/tableV3/TableV3'
import Pagination from '../../../../components/common/pagination/Pagination'
import { CustomRadio } from '../../../../components/common/custom-radio'
import { useAppDispatch, useAppSelector } from '../../../../hooks'
import { getAllCampaign } from '../../../../redux/features/call-center-campaign/campaignAsyncActions'
import { requestGenerator } from '../../../../utils/payloadGenerator'
import Loader from '../../../../components/common/spinner/Loader'
import campaignSvg from '../../../../assets/images/campaign.png'
import styles from './onGoingCampaigns.module.scss'
import { OnGoingCampaignsHeaderData } from '../../../../constants/table-data/onGoingCampaignsTableData'
import { CustomModal } from '../../../../components/common/custom-modal/modal'
import AssignAgentPopup from '../../../../components/common/modal/assignagentsmodal/AssignAgents'
import { campaignLabel } from '../../../../constants/data'
import { t } from 'i18next'

const OnGoingCampaigns = () => {
  const dispatch = useAppDispatch()

  const { isLoading, allCampaignData, total } = useAppSelector(
    (state) => state.campaign
  )
  const [campaignSearch, setCampaignSearch] = useState<string>('')
  const [showTagesType, setTagesType] = useState<string>('NEW')
  const [showNewText, setShowNewText] = useState(false)
  const [showCompletedText, setShowCompletedText] = useState(false)
  const [isSmartSearchDisable, setIsSmartSearchDisable] =
    useState<boolean>(true)
  const [dataPerPage, setDataPerPage] = useState<number>(10)
  const [totalPage, setTotalPage] = useState<number>(0)
  const [pageIndex, setPageIndex] = useState<number>(1)
  const [totalData, setTotalData] = useState(0)
  const [assignAgentPopup, setAssignAgentPopup] = useState<any>({
    open: false,
    data: {},
  })

  // React table define
  const data: any = allCampaignData
  const columns: Column<any>[] = OnGoingCampaignsHeaderData
  const options: TableOptions<any> = {
    data,
    columns,
  }

  const {
    state,
    // @ts-ignore
    setGlobalFilter,
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable(options, useGlobalFilter, useSortBy)
  // @ts-ignore
  const { globalFilter } = state

  //  API Calling for Get Data / getAllCampaign API calls
  const getLeadsData = () => {
    const payloadData = {
      search: campaignSearch,
      page: pageIndex,
      pageSize: dataPerPage,
      campaign_status: showTagesType,
    }
    dispatch(getAllCampaign(requestGenerator(payloadData))).then(
      (result: any) => {
        setTotalPage(result.payload.lastPage)
        setTotalData(result.payload.total)
      }
    )
  }
  useEffect(() => {
    if (campaignSearch === '') {
      setGlobalFilter('')
      setIsSmartSearchDisable(true)
      getLeadsData()
    }
  }, [dispatch, campaignSearch, dataPerPage, pageIndex, showTagesType])
  // Search
  const handleSearch = () => {
    setPageIndex(1)
    if (campaignSearch?.length > 0) {
      let payloadData = {
        search: campaignSearch,
        page: 1,
        pageSize: dataPerPage,
        campaign_status: showTagesType,
      }
      dispatch(getAllCampaign(requestGenerator(payloadData))).then((result) => {
        setIsSmartSearchDisable(false)
        setTotalPage(result.payload.lastPage)
        setTotalData(result.payload.total)
      })
    }
  }
  //  Radio Type Buttons
  const onTagsTypeChange = (e: any) => {
    setTagesType(e.target.value)
  }
  // function for creating Page Index Array
  const pageIndexArray = () => {
    let pageIndexOptions = []
    for (let i = 1; i <= totalPage; i++) {
      pageIndexOptions.push(i)
    }
    return pageIndexOptions
  }
  const pageIndexOptions = pageIndexArray()
  return (
    <>
      {isLoading && <Loader />}
      {assignAgentPopup?.open && (
        <CustomModal
          showModal={assignAgentPopup?.open}
          closeModal={() => setAssignAgentPopup({ open: false, data: {} })}
          title={t('Campaigns.Assign_Agents') || 'Assign Agents'}
          width="60%"
          height="600px"
        >
          <AssignAgentPopup
            popupData={assignAgentPopup?.data}
            handleClose={() => setAssignAgentPopup({ open: false, data: {} })}
            getData={() => getLeadsData()}
          />
        </CustomModal>
      )}
      <div className={styles.onGoingMainContainer}>
        <div className={styles.onGoingFilterContainer}>
          <div className={styles.radioFieldMainContainer}>
            <div className={styles.radioFieldContainer}>
              <CustomRadio
                id="Up-Coming"
                label={t('Campaigns.Up-Coming') || 'Up-Coming'}
                value="NEW"
                name="campaign"
                checked={showTagesType === 'NEW'}
                customLabel={styles.customRadioLabel}
                onChange={onTagsTypeChange}
              />
              <div className={styles.iconStyle}>
                <InfoIcon
                  fillColor={colors.grey2}
                  mouseEnter={() => setShowNewText(true)}
                  mouseLeave={() => setShowNewText(false)}
                />
                {showNewText && (
                  <p className={styles.infoText}>
                    100% {t('LeadPool.Unassigned')}
                  </p>
                )}
              </div>
            </div>
            <div className={styles.radioFieldContainer}>
              <CustomRadio
                id="ongoing"
                label={t('Campaigns.On-Going') || 'On-Going'}
                value="ON-GOING"
                name="campaign"
                checked={showTagesType === 'ON-GOING'}
                customLabel={styles.customRadioLabel}
                onChange={onTagsTypeChange}
              />
              <div className={styles.hiddeniconStyle}></div>
            </div>
            <div className={styles.radioFieldContainer}>
              <CustomRadio
                id="completed"
                label={t('OngoingClaims.Completed') || 'Completed'}
                value="COMPLETED"
                name="campaign"
                checked={showTagesType === 'COMPLETED'}
                customLabel={styles.customRadioLabel}
                onChange={onTagsTypeChange}
              />
              <div className={styles.iconStyle}>
                <InfoIcon
                  fillColor={colors.grey2}
                  mouseEnter={() => setShowCompletedText(true)}
                  mouseLeave={() => setShowCompletedText(false)}
                />
                {showCompletedText && (
                  <p className={styles.infoText}>{t('Campaigns.Ended')}</p>
                )}
              </div>
            </div>
          </div>
          <div className={styles.campaignSvgContainer}>
            <img src={campaignSvg} className={styles.imgStyle} alt="" />
            <div className={styles.campaignContainer}>
              <h4 className={styles.campaignNumber}>{total ? total : 0}</h4>
              <p className={styles.campaignName}>
                {campaignLabel[showTagesType]} {t('Sidebar.Campaigns')}
              </p>
            </div>
          </div>
        </div>
        <div className={styles.onGoingMainTableContainer}>
          {/* Search */}
          <div className={styles.tableSearchContainer}>
            <div className={styles.search}>
              <input
                className={styles.searchInput}
                type="text"
                placeholder={t('Campaigns.Search') || 'Search by Campaign'}
                onKeyDown={(e: any) => {
                  if (e?.key === 'Enter') {
                    handleSearch()
                  }
                }}
                onChange={(e) => {
                  trimValue(e)
                  setCampaignSearch(e.target.value)
                  if (campaignSearch !== '' && e.target.value.length === 0) {
                    setPageIndex(1)
                    setDataPerPage(10)
                    setIsSmartSearchDisable(true)
                    setGlobalFilter('')
                    const requestData = {
                      search: '',
                      page: 1,
                      pageSize: 10,
                      campaign_status: showTagesType,
                    }
                    dispatch(
                      getAllCampaign(requestGenerator(requestData))
                    ).then((result) => {
                      setTotalPage(result.payload.lastPage)
                      setTotalData(result.payload.total)
                    })
                  }
                }}
              />
              {/* Search Button */}
              <div
                className={styles.searchButton}
                onClick={() => handleSearch()}
              >
                <SearchIcon fillColor={colors.white1} />
              </div>
              {/* Smart Search */}
              <SmartSearch
                placeHolder={t('Common.Smart Search') || 'Smart Search'}
                globalFilter={globalFilter}
                setGlobalFilter={setGlobalFilter}
                isDisable={isSmartSearchDisable}
              />
            </div>
          </div>
          {/* Table */}
          <div className={styles.onGoingTableContainer}>
            <div className={styles.tableContainer}>
              <TableV3
                getTableProps={getTableProps}
                getTableBodyProps={getTableBodyProps}
                headerGroups={headerGroups}
                rows={rows}
                prepareRow={prepareRow}
                handleRow={(item: any) =>
                  setAssignAgentPopup({ open: true, data: item })
                }
                customClasssForTrBody={styles.tableRow}
                invoiceFlag={showTagesType === 'COMPLETED' ? true : false}
              />
            </div>
            {/* Pagination */}
            {allCampaignData &&
            allCampaignData.length !== 0 &&
            totalData > 10 ? (
              <Pagination
                pageSize={dataPerPage}
                setDataPerPage={setDataPerPage}
                pageIndexOptions={pageIndexOptions}
                pageIndex={pageIndex}
                setPageIndex={setPageIndex}
              />
            ) : (
              ''
            )}
          </div>
        </div>
      </div>
    </>
  )
}

export default OnGoingCampaigns
