import axios from 'axios'
import { IAPIPayload } from '../../../interfaces/apiInterface'
import {
  ADD_DOCUMENT,
  DELETE_DOCUMNET,
  EDIT_DOCUMENT_BY_ID,
  GET_ALL_ATTACH,
  GET_ALL_DOCUMENT,
  GET_ALL_SEARCH_USERS,
  UPDATE_DOCUMENT_STATUS,
  GET_DOCUMENT_BY_ID,
} from '../../../config/config'

export const getAllDocumentList = (data: IAPIPayload) => {
  return axios.post(GET_ALL_DOCUMENT, data)
}
export const addDocuments = (data: IAPIPayload) => {
  return axios.post(ADD_DOCUMENT, data)
}
export const getDocumentByIds = (data: IAPIPayload) => {
  return axios.post(GET_DOCUMENT_BY_ID, data)
}

export const editDocumentByIds = (data: IAPIPayload) => {
  return axios.post(EDIT_DOCUMENT_BY_ID, data)
}

export const updateDocumentStatuss = (data: IAPIPayload) => {
  return axios.post(UPDATE_DOCUMENT_STATUS, data)
}
export const deleteDocuments = (data: IAPIPayload) => {
  return axios.post(DELETE_DOCUMNET, data)
}
export const getAllSearchUsers = (data: IAPIPayload) => {
  return axios.post(GET_ALL_SEARCH_USERS, data)
}
export const getAllAttach = (data: IAPIPayload) => {
  return axios.post(GET_ALL_ATTACH, data)
}
