import { t } from 'i18next'
import {
  INVOICE_ONLINE_PAYMENT_AMOUNT,
  INVOICE_ONLINE_PAYMENT_BRANCH,
  INVOICE_ONLINE_PAYMENT_MOBILE_NO,
  INVOICE_ONLINE_PAYMENT_NAME,
  INVOICE_ONLINE_PAYMENT_REF_NO,
  INVOICE_ONLINE_PAYMENT_URL,
} from '../constants/constant'

export interface IonlinePaymentValidator {
  [INVOICE_ONLINE_PAYMENT_BRANCH]: {
    required: string
  }
  [INVOICE_ONLINE_PAYMENT_NAME]: {
    required: string
  }
  [INVOICE_ONLINE_PAYMENT_MOBILE_NO]: {
    required: string
  }
  [INVOICE_ONLINE_PAYMENT_REF_NO]: {
    required: string
  }
  [INVOICE_ONLINE_PAYMENT_AMOUNT]: {
    required: string
  }
  [INVOICE_ONLINE_PAYMENT_URL]: {
    required: string
  }
}
export const onlinePaymentValidator: IonlinePaymentValidator = {
  [INVOICE_ONLINE_PAYMENT_BRANCH]: {
    required: t('OnlinePaymentValidator.SelectBranch'),
  },
  [INVOICE_ONLINE_PAYMENT_NAME]: {
    required: t('OnlinePaymentValidator.Customer'),
  },
  [INVOICE_ONLINE_PAYMENT_MOBILE_NO]: {
    required: t('OnlinePaymentValidator.MOB'),
  },
  [INVOICE_ONLINE_PAYMENT_REF_NO]: {
    required: t('OnlinePaymentValidator.REF'),
  },
  [INVOICE_ONLINE_PAYMENT_AMOUNT]: {
    required: t('OnlinePaymentValidator.AMT'),
  },
  [INVOICE_ONLINE_PAYMENT_URL]: {
    required: t('OnlinePaymentValidator.URL'),
  },
}
