import {
  APPOINTMENT_KEY,
  PHYSIO_SLOTS_KEY,
  PHYSIO_SLOTS_RECURRING_KEY,
} from '../../../constants/asyncActionsType'
import createAsyncThunkForSlice from '../../../utils/utils'
import {
  appointmentDataById,
  physioViewSlotsApi,
  physioViewSlotsApiRecurring,
} from './physioCrud'

//non-recurring
export const getAllPhysioSlots = createAsyncThunkForSlice(
  PHYSIO_SLOTS_KEY,
  physioViewSlotsApi,
  { isToast: true }
)

//recurring
export const getAllPhysioSlotsRecurring = createAsyncThunkForSlice(
  PHYSIO_SLOTS_RECURRING_KEY,
  physioViewSlotsApiRecurring,
  { isToast: true }
)

export const appointmentDataByIdAsync = createAsyncThunkForSlice(
  APPOINTMENT_KEY,
  appointmentDataById
)
