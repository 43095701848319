import { t } from 'i18next'
import {
  DOCUMENT_CATEGORY,
  DOCUMENT_NAME,
  UPLOAD_DOCUMENT,
} from '../constants/constant'

export const uploadDocumentValidators = {
  [DOCUMENT_CATEGORY]: {
    required: t('UploadDocValidators.DocCategory'),
  },
  [DOCUMENT_NAME]: {
    required: t('UploadDocValidators.DocName'),
  },
  [UPLOAD_DOCUMENT]: {
    required: t('UploadDocValidators.UploadDoc'),
    validate: {
      fileSize: (file: FileList) => {
        return (
          file[0].size <= 2 * 1024 * 1024 ||
          t('UploadDocValidators.UploadDocSize')
        )
      },
      // fileType: (file: FileList) =>
      //   /pdf|docx|txt/.test(file[0].type) ||
      //   "Only PDF/DOCX/TXT files are allowed",
      fileType: (file: FileList) => {
        const allowedExtensions = ['pdf']
        const fileName: any = file[0].name
        const fileExtension = fileName.split('.').pop().toLowerCase()

        if (!allowedExtensions.includes(fileExtension)) {
          return t('UploadDocValidators.UploadDocType')
        }

        return true
      },
    },
  },
}
