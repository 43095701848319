import { t } from 'i18next'
import { IMAGE_CATEGORY, IMAGE_NAME, UPLOAD_IMAGE } from '../constants/constant'

export const uploadImageValidators = {
  [IMAGE_CATEGORY]: {
    required: t('UploadImageValidators.ImgCategory'),
  },
  [IMAGE_NAME]: {
    required: t('UploadImageValidators.ImgName'),
  },
  [UPLOAD_IMAGE]: {
    required: t('UploadImageValidators.UploadImg'),
    validate: {
      fileSize: (file: FileList) => {
        return (
          file[0].size <= 2 * 1024 * 1024 ||
          t('UploadImageValidators.UploadImgSize')
        )
      },
      fileType: (file: FileList) =>
        /jpeg|png/.test(file[0].type) ||
        t('UploadImageValidators.UploadImgType'),
    },
  },
}
