// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.statusConfirmationPopupV2_popupContainer__PIifr {
  width: 400px;
  margin: auto;
  background: var(--white1);
  border-radius: 10px;
  padding: 10px;
}
.statusConfirmationPopupV2_popupContainer__PIifr .statusConfirmationPopupV2_closeIconStyle__ydDcE {
  cursor: pointer;
  float: right;
}
.statusConfirmationPopupV2_popupContainer__PIifr .statusConfirmationPopupV2_statusContainer__LPIc1 {
  margin-top: 50px;
  text-align: center;
}
.statusConfirmationPopupV2_popupContainer__PIifr .statusConfirmationPopupV2_statusContainer__LPIc1 .statusConfirmationPopupV2_title__6k8ex {
  text-align: center;
  font-weight: var(--font-semibold);
  font-size: var(--font-24);
  line-height: 36px;
  color: var(--black2);
}
.statusConfirmationPopupV2_popupContainer__PIifr .statusConfirmationPopupV2_statusContainer__LPIc1 .statusConfirmationPopupV2_dividerStyle__\\+BQ4n {
  margin: 10px auto 20px auto;
  width: 80px;
  height: 3px;
  background: var(--green1);
}
.statusConfirmationPopupV2_popupContainer__PIifr .statusConfirmationPopupV2_statusContainer__LPIc1 .statusConfirmationPopupV2_userText__Foqjq {
  font-weight: var(--font-normal);
  font-size: var(--font-16);
  color: var(--black2);
  margin: 0 20px 20px 20px;
  text-align: center;
}
.statusConfirmationPopupV2_popupContainer__PIifr .statusConfirmationPopupV2_statusContainer__LPIc1 .statusConfirmationPopupV2_btnContainer__6N4ZV {
  margin: 20px 0 30px 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.statusConfirmationPopupV2_popupContainer__PIifr .statusConfirmationPopupV2_statusContainer__LPIc1 .statusConfirmationPopupV2_btnContainer__6N4ZV .statusConfirmationPopupV2_btnStyle__47wbB,
.statusConfirmationPopupV2_popupContainer__PIifr .statusConfirmationPopupV2_statusContainer__LPIc1 .statusConfirmationPopupV2_btnContainer__6N4ZV .statusConfirmationPopupV2_yesButton__nVMEc {
  width: 100px;
}
.statusConfirmationPopupV2_popupContainer__PIifr .statusConfirmationPopupV2_statusContainer__LPIc1 .statusConfirmationPopupV2_btnContainer__6N4ZV .statusConfirmationPopupV2_yesButton__nVMEc {
  margin-left: 15px;
  border: 1px solid var(--blue1);
  background: var(--white1);
  color: var(--blue1);
}`, "",{"version":3,"sources":["webpack://./src/components/common/modal/status-confirmation-popup/status-confirmation-popupV2/statusConfirmationPopupV2.module.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;EAEA,YAAA;EACA,yBAAA;EACA,mBAAA;EACA,aAAA;AAAF;AACE;EACE,eAAA;EACA,YAAA;AACJ;AACE;EACE,gBAAA;EACA,kBAAA;AACJ;AAAI;EACE,kBAAA;EACA,iCAAA;EACA,yBAAA;EACA,iBAAA;EACA,oBAAA;AAEN;AAAI;EACE,2BAAA;EACA,WAAA;EACA,WAAA;EACA,yBAAA;AAEN;AAAI;EACE,+BAAA;EACA,yBAAA;EACA,oBAAA;EAEA,wBAAA;EACA,kBAAA;AACN;AACI;EACE,qBAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;AACN;AAAM;;EAEE,YAAA;AAER;AAAM;EACE,iBAAA;EACA,8BAAA;EACA,yBAAA;EACA,mBAAA;AAER","sourcesContent":[".popupContainer {\n  width: 400px;\n  //   height: 0px;\n  margin: auto;\n  background: var(--white1);\n  border-radius: 10px;\n  padding: 10px;\n  .closeIconStyle {\n    cursor: pointer;\n    float: right;\n  }\n  .statusContainer {\n    margin-top: 50px;\n    text-align: center;\n    .title {\n      text-align: center;\n      font-weight: var(--font-semibold);\n      font-size: var(--font-24);\n      line-height: 36px;\n      color: var(--black2);\n    }\n    .dividerStyle {\n      margin: 10px auto 20px auto;\n      width: 80px;\n      height: 3px;\n      background: var(--green1);\n    }\n    .userText {\n      font-weight: var(--font-normal);\n      font-size: var(--font-16);\n      color: var(--black2);\n      // margin-bottom: 20px;\n      margin: 0 20px 20px 20px;\n      text-align: center;\n    }\n    .btnContainer {\n      margin: 20px 0 30px 0;\n      display: flex;\n      flex-direction: row;\n      justify-content: center;\n      .btnStyle,\n      .yesButton {\n        width: 100px;\n      }\n      .yesButton {\n        margin-left: 15px;\n        border: 1px solid var(--blue1);\n        background: var(--white1);\n        color: var(--blue1);\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"popupContainer": `statusConfirmationPopupV2_popupContainer__PIifr`,
	"closeIconStyle": `statusConfirmationPopupV2_closeIconStyle__ydDcE`,
	"statusContainer": `statusConfirmationPopupV2_statusContainer__LPIc1`,
	"title": `statusConfirmationPopupV2_title__6k8ex`,
	"dividerStyle": `statusConfirmationPopupV2_dividerStyle__+BQ4n`,
	"userText": `statusConfirmationPopupV2_userText__Foqjq`,
	"btnContainer": `statusConfirmationPopupV2_btnContainer__6N4ZV`,
	"btnStyle": `statusConfirmationPopupV2_btnStyle__47wbB`,
	"yesButton": `statusConfirmationPopupV2_yesButton__nVMEc`
};
export default ___CSS_LOADER_EXPORT___;
