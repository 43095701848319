import moment from 'moment'
import { t } from 'i18next'

// ledger Table Data
export const ledgerTableHeaderData: any = [
  {
    Header: t('RcptTableHeader.DATE'),
    accessor: (row: any) => {
      return moment(row?.date).format('DD MMM YYYY') ?? '-'
    },
  },
  {
    Header: t('Voucher.VOUCHER_NO'),
    accessor: (row: any) => {
      return row?.number ?? '-'
    },
  },

  {
    Header: t('Reports.REFERENCE_NUMBER'),
    accessor: (row: any) => {
      return row?.reference_number ? row?.reference_number : '-'
    },
  },

  {
    Header: t('Voucher.VOUCHER_REMARKS'),
    accessor: (row: any) => {
      return row?.remarks ? row?.remarks : '-'
    },
  },
  {
    Header: t('Reports.Dr'),
    accessor: (row: any) => {
      return row?.debit_amount ?? '-'
    },
  },
  {
    Header: t('Reports.Cr'),
    accessor: (row: any) => {
      return row?.credit_amount ?? '-'
    },
  },
]

export const viewBalanceTableHeaderData: any = [
  {
    Header: t('Voucher.VOUCHER_NO'),
    accessor: (row: any) => {
      return row?.number ?? ''
    },
  },
  {
    Header: t('Voucher.ACCOUNT_NAME'),
    accessor: (row: any) => {
      return row?.accounts_data?.name ?? ''
    },
  },
  {
    Header: t('Reports.DEBIT_BALANCE'),
    accessor: (row: any) => {
      return row?.debit_amount ?? ''
    },
  },
  {
    Header: t('Reports.CREDIT_BALANCE'),
    accessor: (row: any) => {
      return row?.credit_amount ?? ''
    },
  },
]
