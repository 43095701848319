import { t } from 'i18next'
import {
  CLAIM_TYPE,
  COMPANY_NAME,
  REIMBURSEMENT_TYPE,
} from '../constants/constant'

export const addMarketPlaceValidators = {
  [COMPANY_NAME]: {
    required: t('Marketplace_Form.company_validation'),
  },
  [REIMBURSEMENT_TYPE]: {
    required: t('Marketplace_Form.Reimbursement_Type'),
  },
  [CLAIM_TYPE]: {
    required: t('InsurancePlanValidators.ClaimType'),
  },
}
