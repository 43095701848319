import {
  CRM_ADMIN_KEY,
  CRM_ADMIN_SENT_KEY,
  CRM_ADMIN__CRITERIA_KEY,
} from '../../../constants/asyncActionsType'
import createAsyncThunkForSlice from '../../../utils/utils'
import { crmAdminCriteria, crmAdminSearch, crmAdminSent } from './crmCrud'

export const crmAdminSearchAsync = createAsyncThunkForSlice(
  CRM_ADMIN_KEY,
  crmAdminSearch
)

export const crmAdminCriteriaAsync = createAsyncThunkForSlice(
  CRM_ADMIN__CRITERIA_KEY,
  crmAdminCriteria,
  {
    isToast: true,
  }
)

export const crmAdminSentAsync = createAsyncThunkForSlice(
  CRM_ADMIN_SENT_KEY,
  crmAdminSent,
  {
    isToast: true,
  }
)
