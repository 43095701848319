import {
  PRIMARY_FIRST_NAME,
  PRIMARY_LAST_NAME,
  PRIMARY_DESIGNATION,
  PRIMARY_BRANCH,
  PRIMARY_SPECIALITY,
  PRIMARY_DEPARTMENT,
  PRIMARY_PHONE_NUMBER,
  PRIMARY_EMAIL_ID,
  PRIMARY_USER_GROUPS,
  PRIMARY_EXPIRY_DATE,
  PRIMARY_USER_PHOTO_ATTACHMENT,
  PRIMARY_ROLE,
} from '../constants/constant'
import { t } from 'i18next'

export interface ICreatePrimaryValidators {
  [PRIMARY_FIRST_NAME]: {
    required: string
  }
  [PRIMARY_LAST_NAME]: {
    required: string
  }
  [PRIMARY_DESIGNATION]: {
    required: string
  }
  [PRIMARY_BRANCH]: {
    required: string
  }
  // [PRIMARY_SPECIALITY]: {
  //   required: string
  // }
  [PRIMARY_ROLE]: {
    required: string
  }
  [PRIMARY_DEPARTMENT]: {
    required: string
  }
  [PRIMARY_PHONE_NUMBER]: {
    required: string
  }
  [PRIMARY_EMAIL_ID]: {
    required: string
    pattern: {
      value: RegExp
      message: string
    }
  }
  [PRIMARY_USER_GROUPS]: {
    required: string
  }
  [PRIMARY_EXPIRY_DATE]: {
    required: string
    validate: (value: any) => boolean | string
  }
  [PRIMARY_USER_PHOTO_ATTACHMENT]: {
    required: string
    validate: any
  }
}

export const createPrimaryValidators: ICreatePrimaryValidators = {
  [PRIMARY_FIRST_NAME]: {
    required: t('ManageStaff.First_Name_validation'),
  },
  [PRIMARY_LAST_NAME]: {
    required: t('ManageStaff.Last_Name_validation'),
  },
  [PRIMARY_DESIGNATION]: {
    required: t('ManageStaff.Designation_validation'),
  },
  [PRIMARY_BRANCH]: {
    required: t('PatientEmrValidators.Branch'),
  },
  // [PRIMARY_SPECIALITY]: {
  //   required: 'Please select speciality',
  // },
  [PRIMARY_DEPARTMENT]: {
    required: t('DepartmentSetup.Dept validation'),
  },
  [PRIMARY_PHONE_NUMBER]: {
    required: t('ManageStaff.Phone No_validation'),
  },
  [PRIMARY_EMAIL_ID]: {
    required: t('AuthValidator.Email'),
    pattern: {
      value: /^([a-zA-Z0-9_.-])+@(([a-zA-Z0-9-])+.)+([a-zA-Z0-9]{2,4})+$/,
      message: t('AuthValidator.EmailRegex'),
    },
  },
  [PRIMARY_USER_GROUPS]: {
    required: t('ManageStaff.role_validation'),
  },
  [PRIMARY_ROLE]: {
    required: t('ManageStaff.role_validation'),
  },
  [PRIMARY_EXPIRY_DATE]: {
    required: t('ManageStaff.date_validation'),
    validate: (value: string) => {
      const selectedDate = new Date(value)
      const currentDate = new Date()
      if (selectedDate <= currentDate) {
        return (
          t('ManageStaff.expiry_date_validation') ||
          'Expiry date must be greater than  to today'
        )
      }
      return true
    },
  },
  [PRIMARY_USER_PHOTO_ATTACHMENT]: {
    required: t('ManageStaff.User_Photo_validation'),
    validate: {
      fileSize: (file: FileList) => {
        return (
          file[0].size <= 2 * 1024 * 1024 ||
          t('UploadImageValidators.UploadImgSize')
        )
      },
      fileType: (file: FileList) =>
        /jpeg|png/.test(file[0].type) ||
        t('UploadImageValidators.UploadImgType'),
    },
  },
}
