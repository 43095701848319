import axios from 'axios'
import { IAPIPayload } from '../../../interfaces/apiInterface'
import {
  ADD_PAYROLL,
  CREATE_PAYROLL,
  DELETE_PAYROLL,
  EDIT_PAYROLL_BY_ID,
  GET_ALL_PAYROLL,
  GET_ALL_SEARCH_USERS,
  GET_PAYROLL_BY_ID,
  GET_SALARY,
  UPDATE_PAYROLL_STATUS,
} from '../../../config/config'

export const getAllPayrollList = (data: IAPIPayload) => {
  return axios.post(GET_ALL_PAYROLL, data)
}

export const getSalaryList = (data: IAPIPayload) => {
  return axios.post(GET_SALARY, data)
}

export const addPayroll = (data: IAPIPayload) => {
  return axios.post(ADD_PAYROLL, data)
}

export const getPayrollByIds = (data: IAPIPayload) => {
  return axios.post(GET_PAYROLL_BY_ID, data)
}

export const editPayrollByIds = (data: IAPIPayload) => {
  return axios.post(EDIT_PAYROLL_BY_ID, data)
}

export const updatePayrollStatuss = (data: IAPIPayload) => {
  return axios.post(UPDATE_PAYROLL_STATUS, data)
}

export const deletePayroll = (data: IAPIPayload) => {
  return axios.post(DELETE_PAYROLL, data)
}

export const getAllSearchUsers = (data: IAPIPayload) => {
  return axios.post(GET_ALL_SEARCH_USERS, data)
}

export const createPayrol = (data: IAPIPayload) => {
  return axios.post(CREATE_PAYROLL, data)
}
