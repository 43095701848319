import {
  ALLOWANCE_NAME1,
  ALLOWANCE_VALUE,
  ALLOWANCE_VALUE1,
  BASIC_SALARY,
  DEDUCTION_NAME1,
  DEDUCTION_VALUE1,
  EXPENSE_CLAIM,
  HOUR_CLOCKED,
  HOUR_RATE,
  MONTH,
  NET_SALARY,
  PRESENT_DAYS,
  STATUS,
  STATUS_TYPE,
  TOTAL_ALLOWANCE,
  TOTAL_DEDUCTION,
  TOTAL_SALARY,
  WORKING_DAYS,
  YEAR,
  _id,
  emp_id,
  name_en,
} from '../pages/payroll/creare-salary-slip/createSalaryConstant'
import { t } from 'i18next'

export const createsalaryValidators = {
  [_id]: {
    required: t('PatientEmrValidators.NationalID'),
  },
  [STATUS_TYPE]: {
    required: '',
  },
  [BASIC_SALARY]: {
    required: t('PatientEmrValidators.NationalID'),
  },

  [emp_id]: {
    required: t('PatientEmrValidators.NationalID'),
  },

  [MONTH]: {
    required: t('Attendance.month_validation'),
  },
  [YEAR]: {
    required: t('Attendance.year_validation'),
  },
  [TOTAL_ALLOWANCE]: {
    required: t('CreateSalary.allowance_validation'),
  },
  [TOTAL_DEDUCTION]: {
    required: t('CreateSalary.deduction_validation'),
  },
  [NET_SALARY]: {
    required: t('LeaveManagement.Salary_Validation'),
  },
  [TOTAL_SALARY]: {
    required: t('CreateSalary.payment_validation'),
  },
  [HOUR_RATE]: {
    required: t('CreateSalary.rate_validation'),
  },
  [EXPENSE_CLAIM]: {
    required: '',
  },
  [STATUS]: {
    required: t('LeaveManagement.status_validation'),
  },
  [HOUR_CLOCKED]: {
    required: t('CreateSalary.hour_validation'),
  },
  [name_en]: {
    required: t('ServiceMaster.service_validation'),
  },
  [ALLOWANCE_NAME1]: {
    required: '',
  },

  [ALLOWANCE_VALUE1]: {
    required: '',
  },
  [ALLOWANCE_VALUE]: {
    required: '',
  },

  [DEDUCTION_NAME1]: {
    required: '',
  },

  [DEDUCTION_VALUE1]: {
    required: '',
  },
  [PRESENT_DAYS]: {
    required: t('CreateSalary.present_day_validation'),
  },
  [WORKING_DAYS]: {
    required: t('CreateSalary.working_days_validation'),
  },
}
