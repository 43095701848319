import { FC } from "react";
import styles from "./style.module.scss";

interface IErrorProps {
  message: string;
}

export const Error: FC<IErrorProps> = (props) => {
  const { message } = props;
  return (
    <div className={styles.errorContainer}>
      <p className={styles.errorText}>{message}</p>
    </div>
  );
};
