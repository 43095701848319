// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.userLicensePopup_popupContainer__WP9c5 {
  width: 400px;
  margin: auto;
  background: var(--white1);
  border-radius: 10px;
  padding: 10px;
}
.userLicensePopup_popupContainer__WP9c5 .userLicensePopup_closeIconStyle__7xP2z {
  cursor: pointer;
  float: right;
}
.userLicensePopup_popupContainer__WP9c5 .userLicensePopup_userLicenseContent__dSa1Y {
  margin-top: 50px;
  text-align: center;
}
.userLicensePopup_popupContainer__WP9c5 .userLicensePopup_userLicenseContent__dSa1Y .userLicensePopup_title__Q9m6e {
  text-align: center;
  font-weight: var(--font-semibold);
  font-size: var(--font-24);
  line-height: 36px;
  color: var(--black2);
}
.userLicensePopup_popupContainer__WP9c5 .userLicensePopup_userLicenseContent__dSa1Y .userLicensePopup_dividerStyle__M7R7F {
  margin: 10px auto 30px auto;
  width: 80px;
  height: 3px;
  background: var(--green1);
}
.userLicensePopup_popupContainer__WP9c5 .userLicensePopup_userLicenseContent__dSa1Y .userLicensePopup_userText__tdXGp {
  font-weight: var(--font-semibold);
  font-size: var(--font-16);
  color: var(--black2);
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 20px;
}
.userLicensePopup_popupContainer__WP9c5 .userLicensePopup_userLicenseContent__dSa1Y .userLicensePopup_userText__tdXGp .userLicensePopup_spanText__clZsD {
  color: var(--grey4);
  margin-left: 8px;
}
.userLicensePopup_popupContainer__WP9c5 .userLicensePopup_userLicenseContent__dSa1Y .userLicensePopup_btnStyle__2ZcWT {
  margin: 20px 0 30px 0;
}`, "",{"version":3,"sources":["webpack://./src/components/common/modal/user-license-popup/userLicensePopup.module.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;EAEA,YAAA;EACA,yBAAA;EACA,mBAAA;EACA,aAAA;AAAF;AACE;EACE,eAAA;EACA,YAAA;AACJ;AACE;EACE,gBAAA;EACA,kBAAA;AACJ;AAAI;EACE,kBAAA;EACA,iCAAA;EACA,yBAAA;EACA,iBAAA;EACA,oBAAA;AAEN;AAAI;EACE,2BAAA;EACA,WAAA;EACA,WAAA;EACA,yBAAA;AAEN;AAAI;EACE,iCAAA;EACA,yBAAA;EACA,oBAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,mBAAA;AAEN;AADM;EACE,mBAAA;EACA,gBAAA;AAGR;AAAI;EACE,qBAAA;AAEN","sourcesContent":[".popupContainer {\n  width: 400px;\n  //   height: 0px;\n  margin: auto;\n  background: var(--white1);\n  border-radius: 10px;\n  padding: 10px;\n  .closeIconStyle {\n    cursor: pointer;\n    float: right;\n  }\n  .userLicenseContent {\n    margin-top: 50px;\n    text-align: center;\n    .title {\n      text-align: center;\n      font-weight: var(--font-semibold);\n      font-size: var(--font-24);\n      line-height: 36px;\n      color: var(--black2);\n    }\n    .dividerStyle {\n      margin: 10px auto 30px auto;\n      width: 80px;\n      height: 3px;\n      background: var(--green1);\n    }\n    .userText {\n      font-weight: var(--font-semibold);\n      font-size: var(--font-16);\n      color: var(--black2);\n      display: flex;\n      flex-direction: row;\n      justify-content: center;\n      margin-bottom: 20px;\n      .spanText {\n        color: var(--grey4);\n        margin-left: 8px;\n      }\n    }\n    .btnStyle {\n      margin: 20px 0 30px 0;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"popupContainer": `userLicensePopup_popupContainer__WP9c5`,
	"closeIconStyle": `userLicensePopup_closeIconStyle__7xP2z`,
	"userLicenseContent": `userLicensePopup_userLicenseContent__dSa1Y`,
	"title": `userLicensePopup_title__Q9m6e`,
	"dividerStyle": `userLicensePopup_dividerStyle__M7R7F`,
	"userText": `userLicensePopup_userText__tdXGp`,
	"spanText": `userLicensePopup_spanText__clZsD`,
	"btnStyle": `userLicensePopup_btnStyle__2ZcWT`
};
export default ___CSS_LOADER_EXPORT___;
