import * as React from 'react'
import { Outlet } from 'react-router-dom'

interface IDynamicTemplateLayout {}

const DynamicTemplateLayout: React.FunctionComponent<IDynamicTemplateLayout> = (
  props
) => {
  return (
    <>
      <Outlet />
    </>
  )
}

export default DynamicTemplateLayout
