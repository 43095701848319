import { useState } from 'react'
import {
  CheckIcon,
  UncheckIcon,
} from '../../../../../components/common/svg-components'
import { colors } from '../../../../../constants/color'
import { useAppDispatch, useAppSelector } from '../../../../../hooks'
import {
  removeFromAssignList,
  setAssignList,
} from '../../../../../redux/features/call-center-admin-dashbord/callCenterDashbordSlice'
import { t } from 'i18next'

export const AssignHeader: any[] = [
  {
    Header: t('CallCenterAdmin.LEAD_NAME'),
    accessor: 'name',
  },
  {
    Header: t('CallCenterAdmin.DELEGATE'),
    Cell: ({ row }: any) => {
      const { assignLeadList } = useAppSelector(
        (state) => state.callCenterAdminDashbord
      )
      const dispatch = useAppDispatch()

      const handleDelegate = (item: any, itemStatus: any) => {
        if (itemStatus === 'unChecked') {
          dispatch(setAssignList(item))
        } else {
          dispatch(removeFromAssignList(item))
        }
      }

      function checkDelegate(item: any) {
        return item?._id === row?.original?._id
      }

      return (
        <>
          {assignLeadList.some(checkDelegate) ? (
            <CheckIcon
              fillColor={colors.green1}
              handleClick={() => handleDelegate(row?.original?._id, 'checked')}
            />
          ) : (
            <UncheckIcon
              fillColor={colors.grey2}
              handleClick={() => handleDelegate(row?.original, 'unChecked')}
            />
          )}
        </>
      )
    },
  },
]
// export const AssignRow: any[] = [
//   {
//     leadname: "-",
//     department: "-",

//   },
//   {
//     leadname: "-",
//     department: "-",

//   },
//   {
//     leadname: "-",
//     department: "-",

//   },
//   {
//     leadname: "-",
//     department: "-",
//   },
// ]
