import { t } from 'i18next'
import { UPLOAD_JSON_FILE } from '../constants/constant'

export interface IUploadModalValidators {
  [UPLOAD_JSON_FILE]: {
    required: string
    validate: any
  }
}

export const uploadModalValidators: IUploadModalValidators = {
  [UPLOAD_JSON_FILE]: {
    required: t('UploadJsonValidators.JsonFile'),
    validate: {
      fileSize: (file: FileList) => {
        return (
          file[0].size <= 2 * 1024 * 1024 ||
          t('UploadDocValidators.UploadDocSize')
        )
      },
      fileType: (file: FileList) =>
        /json/.test(file[0].type) || t('UploadJsonValidators.JsonFileType'),
    },
  },
}
