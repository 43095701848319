// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_patientSearchContainer__9e6GM {
  margin-top: 15px;
  overflow: auto;
}

.styles_tableContainer__Z\\+Vzn {
  height: 33dvh;
  overflow: auto;
  margin-block: 10px;
}
.styles_tableContainer__Z\\+Vzn::-webkit-scrollbar {
  display: block;
  width: 7px;
  height: 7px;
}
.styles_tableContainer__Z\\+Vzn::-webkit-scrollbar-track {
  background: transparent;
}
.styles_tableContainer__Z\\+Vzn::-webkit-scrollbar-thumb {
  background-color: var(--grey7);
  border-radius: 10px;
  border: 1px solid var(--grey7);
}`, "",{"version":3,"sources":["webpack://./src/pages/physio/patient-search/styles.module.scss"],"names":[],"mappings":"AAEA;EACE,gBAAA;EACA,cAAA;AADF;;AAKA;EACE,aAAA;EACA,cAAA;EACA,kBAAA;AAFF;AAGE;EACE,cAAA;EACA,UAAA;EACA,WAAA;AADJ;AAGE;EACE,uBAAA;AADJ;AAIE;EACE,8BAAA;EACA,mBAAA;EACA,8BAAA;AAFJ","sourcesContent":["@import '../../../styles/mixin.scss';\n\n.patientSearchContainer {\n  margin-top: 15px;\n  overflow: auto;\n}\n\n// Table Container\n.tableContainer {\n  height: 33dvh;\n  overflow: auto;\n  margin-block: 10px;\n  &::-webkit-scrollbar {\n    display: block;\n    width: 7px;\n    height: 7px;\n  }\n  &::-webkit-scrollbar-track {\n    background: transparent;\n  }\n\n  &::-webkit-scrollbar-thumb {\n    background-color: var(--grey7);\n    border-radius: 10px;\n    border: 1px solid var(--grey7);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"patientSearchContainer": `styles_patientSearchContainer__9e6GM`,
	"tableContainer": `styles_tableContainer__Z+Vzn`
};
export default ___CSS_LOADER_EXPORT___;
