import { FC } from 'react'
import { CloseIcon } from '../../components/common/svg-components/index'
import { colors } from '../../constants/color'
import { JSONTree } from 'react-json-tree'
import styles from './reportsNotesModal.module.scss'

interface IReportsNotesModal {
  handleClose: (e?: React.MouseEvent<HTMLElement>) => void
  popData: any
}

const ReportsNotesModal: FC<IReportsNotesModal> = ({
  handleClose,
  popData,
}) => {
  return (
    <>
      <div
        className={styles.patientHistoryNotesModalContainer}
        onClick={(e) => {
          e.stopPropagation()
        }}
      >
        <CloseIcon
          customClass={styles.closeIconStyle}
          fillColor={colors.green1}
          handleClick={() => {
            handleClose()
          }}
        />
        <div className={styles.patientHistoryNotesContainer}>
          <JSONTree data={popData} />
        </div>
      </div>
    </>
  )
}

export default ReportsNotesModal
