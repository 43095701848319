// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.endDiagnosisPopup_notesPopupContainer__l9wR7 {
  width: 450px;
  margin: auto;
  background: var(--white1);
  border-radius: 10px;
  padding: 10px;
}
.endDiagnosisPopup_notesPopupContainer__l9wR7 .endDiagnosisPopup_closeIconStyle__rlBCo {
  cursor: pointer;
  float: right;
}
.endDiagnosisPopup_notesPopupContainer__l9wR7 .endDiagnosisPopup_notesContainer__BrNMH {
  margin-top: 50px;
}
.endDiagnosisPopup_notesPopupContainer__l9wR7 .endDiagnosisPopup_notesContainer__BrNMH .endDiagnosisPopup_title__6VVoO {
  text-align: center;
  font-weight: var(--font-semibold);
  font-size: var(--font-24);
  line-height: 36px;
  color: var(--black2);
}
.endDiagnosisPopup_notesPopupContainer__l9wR7 .endDiagnosisPopup_notesContainer__BrNMH .endDiagnosisPopup_dividerStyle__Nnuoy {
  margin: 10px auto 30px auto;
  width: 80px;
  height: 3px;
  background: var(--green1);
}
.endDiagnosisPopup_notesPopupContainer__l9wR7 .endDiagnosisPopup_notesContainer__BrNMH .endDiagnosisPopup_deleteText__IRXJl {
  font-weight: var(--font-normal);
  font-size: var(--font-16);
  text-align: center;
}
.endDiagnosisPopup_notesPopupContainer__l9wR7 .endDiagnosisPopup_notesContainer__BrNMH .endDiagnosisPopup_btnContainer__wMPS\\+ {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 35px 0;
}
.endDiagnosisPopup_notesPopupContainer__l9wR7 .endDiagnosisPopup_notesContainer__BrNMH .endDiagnosisPopup_btnContainer__wMPS\\+ .endDiagnosisPopup_yesButtonStyle__sc5Yo {
  width: 100px;
}
.endDiagnosisPopup_notesPopupContainer__l9wR7 .endDiagnosisPopup_notesContainer__BrNMH .endDiagnosisPopup_btnContainer__wMPS\\+ .endDiagnosisPopup_noButtonStyle__5SJ7A {
  color: var(--blue1);
  border: 1px solid var(--blue1);
  background: var(--white1);
  width: 100px;
}`, "",{"version":3,"sources":["webpack://./src/components/common/modal/end-diagnosis-popup/endDiagnosisPopup.module.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;EAEA,YAAA;EACA,yBAAA;EACA,mBAAA;EACA,aAAA;AAAF;AACE;EACE,eAAA;EACA,YAAA;AACJ;AACE;EACE,gBAAA;AACJ;AAAI;EACE,kBAAA;EACA,iCAAA;EACA,yBAAA;EACA,iBAAA;EACA,oBAAA;AAEN;AAAI;EACE,2BAAA;EACA,WAAA;EACA,WAAA;EACA,yBAAA;AAEN;AAAI;EACE,+BAAA;EACA,yBAAA;EACA,kBAAA;AAEN;AAAI;EACE,aAAA;EACA,mBAAA;EACA,mBAAA;EACA,uBAAA;EACA,cAAA;AAEN;AADM;EACE,YAAA;AAGR;AADM;EACE,mBAAA;EACA,8BAAA;EACA,yBAAA;EACA,YAAA;AAGR","sourcesContent":[".notesPopupContainer {\n  width: 450px;\n  // height: 250px;\n  margin: auto;\n  background: var(--white1);\n  border-radius: 10px;\n  padding: 10px;\n  .closeIconStyle {\n    cursor: pointer;\n    float: right;\n  }\n  .notesContainer {\n    margin-top: 50px;\n    .title {\n      text-align: center;\n      font-weight: var(--font-semibold);\n      font-size: var(--font-24);\n      line-height: 36px;\n      color: var(--black2);\n    }\n    .dividerStyle {\n      margin: 10px auto 30px auto;\n      width: 80px;\n      height: 3px;\n      background: var(--green1);\n    }\n    .deleteText {\n      font-weight: var(--font-normal);\n      font-size: var(--font-16);\n      text-align: center;\n    }\n    .btnContainer {\n      display: flex;\n      flex-direction: row;\n      align-items: center;\n      justify-content: center;\n      margin: 35px 0;\n      .yesButtonStyle {\n        width: 100px;\n      }\n      .noButtonStyle {\n        color: var(--blue1);\n        border: 1px solid var(--blue1);\n        background: var(--white1);\n        width: 100px;\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"notesPopupContainer": `endDiagnosisPopup_notesPopupContainer__l9wR7`,
	"closeIconStyle": `endDiagnosisPopup_closeIconStyle__rlBCo`,
	"notesContainer": `endDiagnosisPopup_notesContainer__BrNMH`,
	"title": `endDiagnosisPopup_title__6VVoO`,
	"dividerStyle": `endDiagnosisPopup_dividerStyle__Nnuoy`,
	"deleteText": `endDiagnosisPopup_deleteText__IRXJl`,
	"btnContainer": `endDiagnosisPopup_btnContainer__wMPS+`,
	"yesButtonStyle": `endDiagnosisPopup_yesButtonStyle__sc5Yo`,
	"noButtonStyle": `endDiagnosisPopup_noButtonStyle__5SJ7A`
};
export default ___CSS_LOADER_EXPORT___;
