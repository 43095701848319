// Mark All Day
export const ATTENDENCE = 'attendence'
export const MONTH = 'month'
export const YEAR = 'year'
export const START_TIME = 'start_time'
export const END_TIME = 'end_time'
export const START_DATE = 'start_date'
export const END_DATE = 'end_date'
export const REASON_FOR_LEAVE = 'reason_for_leave'
export const SALARY_TYPE = 'salary_type'
export const LEAVE_STATUS = 'isPaid'
