import { FC } from 'react'
import { Outlet } from 'react-router-dom'

interface IPurchaseInvoiceLayout {}

const PurchaseInvoiceLayout: FC<IPurchaseInvoiceLayout> = (props) => {
  return (
    <>
      <Outlet />
    </>
  )
}
export default PurchaseInvoiceLayout
