import { FC } from 'react'
import { CloseIcon } from '../../svg-components'
import { colors } from '../../../../constants/color'
import styles from './descriptionDataModal.module.scss'
import Divider from '../../divider/Divider'

interface IDescriptionDataModalProps {
  heading?: string
  message?: string
  handleClose?: any
  popData?: string | any
}

const DescriptionDataModal: FC<IDescriptionDataModalProps> = ({
  heading,
  message,
  handleClose,
  popData,
}) => {
  return (
    <div className={styles.mainContainer}>
      <CloseIcon
        customClass={styles.closeIconStyle}
        fillColor={colors.green1}
      />
      <div
        className={styles.addCategoryContainer}
        onClick={(e) => {
          e.stopPropagation()
        }}
      >
        <p className={styles.title}>{heading}</p>
        <Divider customClass={styles.dividerStyle} />
        <div className={styles.mcUpdatecontainer}>
          <div className={styles.updatenewscontainer}>
            <p className={styles.description}>{popData?.description}</p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default DescriptionDataModal
