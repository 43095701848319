import { createSlice } from '@reduxjs/toolkit';
import { IIpd } from '../../../interfaces/apiInterface';
import {
  addIpdTimelineMedication,
  addIpdTimelineNote,
  applyInsurancetoCase,
  deleteIpdTimelineNote,
  editIpdTimelineNote,
  getAllIpdMedication,
  getAllIpdTest,
  getCaseByIdIpdBooking,
  getIpdCaseById,
  ipdHistory,
  updateCaseIpd,
} from './ipdAsyncActions';

export const initialState: IIpd = {
  isLoading: false,
  error: null,
  ipdMedicationData: [],
  ipdTimelineNotesInfo: {},
  ipdTestData: [],
  ipdRequestData: [],
  ipdMedicationInfo: {},
  storeIpdCaseById: {},
  isIpdCaseLoading: false,
  showIpdTreatmentModal: false,
  ipdViewHistory: [],
  consultationIpdData: [],
  ipdLoading: false,
};

export const IpdSlice = createSlice({
  name: 'ipd',
  initialState,
  reducers: {
    setIpdRequestData: (state, action) => {
      state.ipdRequestData = [...state.ipdRequestData, action.payload];
    },
    clearIpdRequestData: (state) => {
      state.ipdRequestData = [];
    },
    setShowIpdTreatmentModal: (state, action) => {
      state.showIpdTreatmentModal = action.payload;
    },
    clearIpdStoreData: (state) => {
      state.storeIpdCaseById = {};
    },
  },
  extraReducers(builder) {
    // ADD IPD TIMELINE NOTES

    builder.addCase(addIpdTimelineNote.pending, (state) => {
      state.isLoading = true;
      state.ipdLoading = true;
    });
    builder.addCase(addIpdTimelineNote.fulfilled, (state, action) => {
      state.isLoading = false;
      state.ipdLoading = false;
      state.ipdTimelineNotesInfo = action.payload;
    });
    builder.addCase(addIpdTimelineNote.rejected, (state) => {
      state.isLoading = false;
      state.ipdLoading = false;
    });

    // EDIT IPD TIMELINE NOTES

    builder.addCase(editIpdTimelineNote.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(editIpdTimelineNote.fulfilled, (state, action) => {
      state.isLoading = false;
      state.ipdTimelineNotesInfo = action.payload;
    });
    builder.addCase(editIpdTimelineNote.rejected, (state) => {
      state.isLoading = false;
    });

    // DELETE IPD TIMELINE NOTES

    builder.addCase(deleteIpdTimelineNote.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(deleteIpdTimelineNote.fulfilled, (state) => {
      state.isLoading = false;
    });
    builder.addCase(deleteIpdTimelineNote.rejected, (state) => {
      state.isLoading = false;
    });

    // GET ALL IPD MEDICATION

    builder.addCase(getAllIpdMedication.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getAllIpdMedication.fulfilled, (state, action) => {
      state.isLoading = false;
      state.ipdMedicationData = action.payload;
    });
    builder.addCase(getAllIpdMedication.rejected, (state) => {
      state.isLoading = false;
    });

    // GET ALL IPD TEST

    builder.addCase(getAllIpdTest.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getAllIpdTest.fulfilled, (state, action) => {
      state.isLoading = false;
      state.ipdTestData = action.payload?.data;
    });
    builder.addCase(getAllIpdTest.rejected, (state) => {
      state.isLoading = false;
    });

    // ADD IPD TIMELINE MEDICATION

    builder.addCase(addIpdTimelineMedication.pending, (state) => {
      state.isLoading = true;
      state.ipdLoading = true;
    });
    builder.addCase(addIpdTimelineMedication.fulfilled, (state, action) => {
      state.isLoading = false;
      state.ipdLoading = false;
      state.ipdMedicationInfo = action.payload;
    });
    builder.addCase(addIpdTimelineMedication.rejected, (state) => {
      state.isLoading = false;
      state.isLoading = false;
    });

    // GET IPD CASE BY ID

    builder.addCase(getIpdCaseById.pending, (state) => {
      state.isIpdCaseLoading = true;
    });
    builder.addCase(getIpdCaseById.fulfilled, (state, action) => {
      state.isIpdCaseLoading = false;
      state.storeIpdCaseById = action.payload;
    });
    builder.addCase(getIpdCaseById.rejected, (state) => {
      state.isIpdCaseLoading = false;
    });

    // GET IPD CASE BY ID FROM BOOKING

    builder.addCase(getCaseByIdIpdBooking.pending, (state) => {
      state.isIpdCaseLoading = true;
    });
    builder.addCase(getCaseByIdIpdBooking.fulfilled, (state, action) => {
      state.isIpdCaseLoading = false;
      state.storeIpdCaseById = action.payload;
    });
    builder.addCase(getCaseByIdIpdBooking.rejected, (state) => {
      state.isIpdCaseLoading = false;
    });

    // UPDATE IPD CASE

    builder.addCase(updateCaseIpd.pending, (state) => {
      state.isIpdCaseLoading = true;
    });
    builder.addCase(updateCaseIpd.fulfilled, (state) => {
      state.isIpdCaseLoading = false;
    });
    builder.addCase(updateCaseIpd.rejected, (state) => {
      state.isIpdCaseLoading = false;
    });

    // VIEW IPD HISTORY

    builder.addCase(ipdHistory.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(ipdHistory.fulfilled, (state, action) => {
      state.isLoading = false;
      state.ipdViewHistory = action.payload;
    });
    builder.addCase(ipdHistory.rejected, (state) => {
      state.isLoading = false;
    });

    // VIEW IPD HISTORY

    builder.addCase(applyInsurancetoCase.pending, (state) => {
      state.isIpdCaseLoading = true;
    });
    builder.addCase(applyInsurancetoCase.fulfilled, (state, action) => {
      state.isIpdCaseLoading = false;
    });
    builder.addCase(applyInsurancetoCase.rejected, (state) => {
      state.isIpdCaseLoading = false;
    });
  },
});

export const {
  setIpdRequestData,
  clearIpdRequestData,
  setShowIpdTreatmentModal,
  clearIpdStoreData,
} = IpdSlice.actions;

export default IpdSlice.reducer;
