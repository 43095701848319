import React, { FC } from 'react';
import { CloseIcon } from '../../svg-components';
import { colors } from '../../../../constants/color';
import Divider from '../../divider/Divider';
import styles from './tagListPopup.module.scss';

interface IDepartmentList {
  handleClose?: any;
  popData?: any;
  headerData?: any;
}
const TagListPopup: FC<IDepartmentList> = ({
  handleClose,
  popData,
  headerData,
}) => {
  return (
    <>
      <div className={styles.notesPopupContainer}>
        <CloseIcon
          customClass={styles.closeIconStyle}
          fillColor={colors.green1}
          handleClick={handleClose}
        />
        <div className={styles.notesContainer}>
          <p className={styles.title}>{headerData}</p>
          <Divider customClass={styles.dividerStyle} />
          <div className={styles.listContainer}>
            {popData.length > 0 &&
              popData.map((item: any, index: number) => {
                return (
                  <li key={index} className={styles.deptText}>
                    {item}
                  </li>
                );
              })}
          </div>
        </div>
      </div>
    </>
  );
};

export default TagListPopup;
