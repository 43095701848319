import { t } from 'i18next'
import {
  APPT_FILE,
  BRANCH_ID,
  DEPT_ID,
  EMR_FILE,
  INVOICE_FILE,
  ITEM_FILE,
  RECEIPT_FILE,
  RECEPT_ID,
  SERVICE_FILE,
} from '../import-constant'

export const importValidation: any = {
  [BRANCH_ID]: {
    required: t('ImportUtility.Select_branch_val'),
  },
  [RECEPT_ID]: {
    required: t('ImportUtility.ReceptVal'),
  },
  [DEPT_ID]: {
    required: t('ImportUtility.Select_dept_val'),
  },
  [EMR_FILE]: {
    required: t('ImportUtility.CSV_Val'),
    validate: {
      fileType: (file: FileList) =>
        /csv/.test(file[0].type) || t('ImportUtility.CSV_Val'),
    },
  },
  [SERVICE_FILE]: {
    required: t('ImportUtility.CSV_Val'),
    validate: {
      fileType: (file: FileList) =>
        /csv/.test(file[0].type) || t('ImportUtility.CSV_Val'),
    },
  },
  [ITEM_FILE]: {
    required: t('ImportUtility.CSV_Val'),
    validate: {
      fileType: (file: FileList) =>
        /csv/.test(file[0].type) || t('ImportUtility.CSV_Val'),
    },
  },
  [APPT_FILE]: {
    required: t('ImportUtility.CSV_Val'),
    validate: {
      fileType: (file: FileList) =>
        /csv/.test(file[0].type) || t('ImportUtility.CSV_Val'),
    },
  },
  [RECEIPT_FILE]: {
    required: t('ImportUtility.CSV_Val'),
    validate: {
      fileType: (file: FileList) =>
        /csv/.test(file[0].type) || t('ImportUtility.CSV_Val'),
    },
  },
  [INVOICE_FILE]: {
    required: t('ImportUtility.CSV_Val'),
    validate: {
      fileType: (file: FileList) =>
        /csv/.test(file[0].type) || t('ImportUtility.CSV_Val'),
    },
  },
}
