import { PAYMENT_MODE_ICON, PAYMENT_MODE_NAME } from '../constants/constant'

export const paymentModeValidators: any = {
  [PAYMENT_MODE_NAME]: {
    required: 'Please enter payment mode',
  },
  [PAYMENT_MODE_ICON]: {
    required: 'Please select icon',
  },
}
