import { useTranslation } from 'react-i18next'
import Button from '../../../components/common/button/Button'
import styles from './styles.module.scss'
import { Label } from '../../../components/common/label'
import Select from 'react-select'
import { useEffect, useState } from 'react'
import { DropdownIndicator } from '../../../components/common/dropdown-indicator/DropdownIndicator'
import { useAppDispatch, useAppSelector } from '../../../hooks'
import { fileType, IImportAppointment } from '../../../interfaces/interfaces'
import { useForm } from 'react-hook-form'
import { APPT_FILE, BRANCH_ID, RECEPT_ID } from '../import-constant'
import { importValidation } from './validators'
import AttachFiles from '../../../components/common/attach-files/single-file/AttachSingleFile'
import { dataURI } from '../../../utils/utils'
import { requestGenerator } from '../../../utils/payloadGenerator'
import {
  getAllReceptionistWithBranches,
  importAppointmentUtility,
} from '../../../redux/features/import-utility/importUtilityAsyncActions'
import { clearImportData } from '../../../redux/features/import-utility/importUtilitySlice'
import { GET_RECEPTIONIST_TYPE } from '../../../constants/asyncActionsType'

const ImportAppointment = () => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const [branchOptions, setBranchOptions] = useState<any>([])
  const [receptionistOptions, setReceptionistOptions] = useState<any>([])
  const [apptFile, setApptFile] = useState({ name: '', data_uri: '' })
  const { inValidData } = useAppSelector((state) => state.import)
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    trigger,
    formState: { errors },
  } = useForm<IImportAppointment>({})

  const aptField = watch(APPT_FILE)
  const fileName = aptField?.[0]

  useEffect(() => {
    dispatch(getAllReceptionistWithBranches(requestGenerator({}))).then((e) => {
      if (e.type === `${GET_RECEPTIONIST_TYPE}/fulfilled`) {
        if (e.payload?.data && e.payload?.data?.length > 0) {
          let tempArr: any[] = []
          tempArr = e.payload?.data?.map((item: any) => {
            return {
              label: item?.name,
              value: item?._id,
              branches: item?.branches,
            }
          })
          setReceptionistOptions(tempArr)
        }
      }
    })
  }, [dispatch])

  useEffect(() => {
    const fileList: fileType = { name: '', data_uri: '' }
    const getDataURI = async (fileName: File) => {
      try {
        const result = await dataURI(fileName)
        fileList.data_uri = result
        fileList.name = fileName.name

        setApptFile(fileList)
      } catch (error) {
        console.log({ error })
      }
    }
    if (fileName) {
      getDataURI(fileName)
    }
  }, [fileName])

  const onSubmit = (data: any) => {
    const payload = {
      [BRANCH_ID]: data[BRANCH_ID]?.value,
      [RECEPT_ID]: data[RECEPT_ID]?.value,
      [APPT_FILE]: apptFile,
    }
    dispatch(importAppointmentUtility(requestGenerator(payload))).then((e) => {
      setValue(APPT_FILE, undefined)
      setApptFile({ name: '', data_uri: '' })
    })
  }
  useEffect(() => {
    if (inValidData?.length !== 0 && apptFile.name) {
      dispatch(clearImportData())
    }
  }, [dispatch, apptFile])

  return (
    <form className={styles.container} onSubmit={handleSubmit(onSubmit)}>
      <div className={styles.importFieldContainer}>
        <div className={styles.inputFieldContainer}>
          <Label
            labelText={t('ImportUtility.Receptionist')}
            requiredField={true}
            flexBasis="30%"
          />
          <div className={styles.inlineItems}>
            <Select
              className={styles.selectInputField}
              placeholder={
                t('ImportUtility.SelectRecept') || `Select Receptionist`
              }
              closeMenuOnSelect={true}
              value={watch(RECEPT_ID)}
              {...register(BRANCH_ID, importValidation[RECEPT_ID])}
              onChange={(e: any) => {
                setValue(RECEPT_ID, e)
                trigger(RECEPT_ID)

                let tempArr: any[] = []
                if (e?.branches && e?.branches?.length > 0) {
                  tempArr = e.branches.map((item: any) => {
                    return {
                      label: item?.name,
                      value: item?._id,
                    }
                  })
                }
                setValue(BRANCH_ID, tempArr[0])
                setBranchOptions(tempArr || [])
              }}
              components={{ DropdownIndicator }}
              options={receptionistOptions}
              isSearchable={true}
              maxMenuHeight={200}
            />
            {errors[RECEPT_ID]?.message && (
              <p className="dashboardFormError">
                {errors[RECEPT_ID]?.message as any}
              </p>
            )}
          </div>
        </div>
      </div>
      <div className={styles.importFieldContainer}>
        <div className={styles.inputFieldContainer}>
          <Label
            labelText={t('Header.Branch')}
            requiredField={true}
            flexBasis="30%"
          />
          <div className={styles.inlineItems}>
            <Select
              className={styles.selectInputField}
              placeholder={
                t('ManageStaff.Branch_placeholder') || `Select Branch`
              }
              closeMenuOnSelect={true}
              value={watch(BRANCH_ID)}
              {...register(BRANCH_ID, importValidation[BRANCH_ID])}
              onChange={(e: any) => {
                setValue(BRANCH_ID, e)
                trigger(BRANCH_ID)
              }}
              components={{ DropdownIndicator }}
              options={branchOptions}
              isSearchable={true}
              maxMenuHeight={200}
            />
            {errors[BRANCH_ID]?.message && (
              <p className="dashboardFormError">
                {errors[BRANCH_ID]?.message as any}
              </p>
            )}
          </div>
        </div>
      </div>
      <div className={styles.importFieldContainer}>
        <div className={styles.inputFieldContainer}>
          <Label
            labelText={t('Common.Upload')}
            requiredField={true}
            flexBasis="30%"
          />
          <div className={styles.inlineItems}>
            <AttachFiles
              register={register}
              fileKey={APPT_FILE}
              id={APPT_FILE}
              fileList={apptFile}
              validation={importValidation[APPT_FILE]}
              isName={true}
              isDocument={true}
              showCsv={true}
            />
            {errors[APPT_FILE] && (
              <p className="errorText">{errors[APPT_FILE].message as any}</p>
            )}
          </div>
        </div>
      </div>
      <div className={styles.buttonContainer}>
        <Button title={t('Common.Submit') || 'Submit'} />
      </div>
    </form>
  )
}

export default ImportAppointment
