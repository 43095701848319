import styles from './createJobs.module.scss'
import { useAppDispatch, useAppSelector } from '../../../hooks'
import { setAllTestQuantityData } from '../../../redux/features/jobs/jobsSlice'
import { t } from 'i18next'

// Create Jobs Table
export const createJobsHeaderData: any = [
  {
    Header: t('LabJob.PROFILE_ID'),
    accessor: (row: any) => {
      return row?.profile_test_id ? row?.profile_test_id : '-'
    },
  },
  {
    Header: t('LabJob.PROFILE_NAME'),
    accessor: (row: any) => {
      return row?.profile_test_name?.length ? row?.profile_test_name : '-'
    },
  },
  {
    Header: t('LabJob.TEST_ID'),
    accessor: (row: any) => {
      return row?.test_id ? row?.test_id : '-'
    },
  },
  {
    Header: t('ViewReports.TEST NAME'),
    accessor: (row: any) => {
      return row?.test_name?.length ? row?.test_name : '-'
    },
  },
  {
    Header: t('ViewInventory.QTY'),
    Cell: (props: any) => {
      const { allTestData } = useAppSelector((state) => state.labsJob)
      const dispatch = useAppDispatch()
      const handleQuantityChange = (
        event: React.ChangeEvent<HTMLInputElement>
      ) => {
        const quantity = event.target.value
        const updatedData = allTestData?.map((item: any) => {
          if (item?._id === props?.row?.original?._id) {
            return {
              ...item,
              quantity: parseInt(quantity),
            }
          } else {
            return item
          }
        })
        dispatch(setAllTestQuantityData(updatedData))
      }
      return (
        <input
          type="number"
          className={styles.inputField}
          value={props?.row?.original?.quantity}
          defaultValue={1}
          key={props?.row?.original?._id}
          onChange={handleQuantityChange}
          disabled
        />
      )
    },
  },
  {
    Header: t('InsurancePlan_Form.PRICE'),
    Cell: (props: any) => {
      return (
        <>
          {props.row.original?.sell_price
            ? `${props.row.original?.sell_price?.toLocaleString()}`
            : `${props.row.original?.total_amount?.toLocaleString()}`}
        </>
      )
    },
  },
  {
    Header: t('LabJob.AMOUNT'),
    Cell: (props: any) => {
      const totalQtyPrice: any =
        (props.row.original?.sell_price
          ? props.row.original?.sell_price
          : props.row.original?.total_amount) *
        (typeof props.row?.original?.quantity === 'undefined'
          ? 1
          : props.row?.original?.quantity)

      return (
        <>
          {isNaN(totalQtyPrice) ? (
            '-'
          ) : (
            <p>{totalQtyPrice?.toLocaleString()}</p>
          )}
        </>
      )
    },
  },
  {
    Header: t('LabJob.BILLABLE'),
    Cell: () => {
      return (
        <select className={styles.inputField} disabled={true}>
          <option value="">YES</option>
          <option value="">NO</option>
        </select>
      )
    },
  },
]
