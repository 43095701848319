import { useTranslation } from 'react-i18next'
import { CustomModal } from '../../../../../components/common/custom-modal/modal'
import TableV2 from '../../../../../components/common/table/tableV2/TableV2'
import { ipdMedicationData, ipdMedicationInfoData } from './medication-table'
import { useState } from 'react'

const MedicationModal = ({ popData }: any) => {
  const [medicationModal, setMedicationModal] = useState(false)
  const [medicinesData, setMedicinesData] = useState([])
  const { t } = useTranslation()
  const handleClick = (item: any) => {
    setMedicationModal(true)
    setMedicinesData(item)
  }
  return (
    <>
      <CustomModal
        showModal={medicationModal}
        closeModal={() => setMedicationModal(false)}
        width="50%"
        height="400px"
        title={t('IPDHistory.Medication Info') || 'Medication Info'}
      >
        <div style={{ marginTop: '40px' }}>
          <TableV2
            tableHeaderData={ipdMedicationInfoData}
            tableRowData={medicinesData}
            active={false}
          />
        </div>
      </CustomModal>
      <div style={{ marginTop: '40px' }}>
        <TableV2
          tableHeaderData={ipdMedicationData}
          tableRowData={popData}
          active={false}
          handleClick={(items: any) => handleClick(items)}
        />
      </div>
    </>
  )
}

export default MedicationModal
