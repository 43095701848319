import { useAppSelector } from '../../../../../hooks'
import Loader from '../../../spinner/Loader'
import styles from './style.module.scss'
import TableV2 from '../../../table/tableV2/TableV2'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import { t } from 'i18next'

const AttachmentsHeaderData: any = [
  {
    Header: t('RadioReport.Attachment Name'),
    accessor: 'name',
  },
  {
    Header: t('RadioReport.Download Attachment'),
    Cell: (props: any) => {
      const { getAllRadiologyTestData } = useAppSelector(
        (state) => state.radiologyJobs
      )
      return (
        <a
          download={`${moment(getAllRadiologyTestData?.createdAt).format(
            'DD-MMM-YYYY'
          )}_${getAllRadiologyTestData?.name?.replaceAll(' ', '_')}_${
            getAllRadiologyTestData?.job_no
          }_${props.row.original?.name}.${
            props.row.original?.url.split('.')[1]
          }`}
          href={props.row.original?.data_uri}
          className={styles.downloadButton}
        >
          {t('RadioReport.Download')}
        </a>
      )
    },
  },
]

const DownloadAttachments = () => {
  const { isLoading, LoadFiles } = useAppSelector(
    (state) => state.radiologyJobs
  )
  const { t } = useTranslation()
  return (
    <>
      {isLoading && <Loader />}
      <div className={styles.tableContainer}>
        <TableV2
          tableHeaderData={AttachmentsHeaderData}
          tableRowData={LoadFiles?.length ? LoadFiles : []}
        />
      </div>
      <p className={styles.note}>
        <strong> {t('RadioReport.Note')}</strong> {t('RadioReport.DiacomNote')}
      </p>
    </>
  )
}

export default DownloadAttachments
