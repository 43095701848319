import { t } from 'i18next'
import { UPLOAD_DOC } from '../constants/constant'

export interface IUploadDocModalValidators {
  [UPLOAD_DOC]: {
    required: string
    validate: any
  }
}

export const uploadDocModalValidators: IUploadDocModalValidators = {
  [UPLOAD_DOC]: {
    required: t('UploadDocValidators.SelectFile'),
    validate: {
      fileSize: (file: FileList) => {
        return (
          file[0].size <= 2 * 1024 * 1024 ||
          t('UploadDocValidators.UploadDocSize')
        )
      },
      // fileType: (file: FileList) =>
      //   /jpeg|png|jpg|doc|docx|pdf|txt/.test(file[0].type) ||
      //   'Only .jpeg,png,jpeg,docx files are allowed',
    },
  },
}
