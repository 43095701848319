import {
  INVENTORY_ITEM_NAME,
  INVENTORY_ITEM_CODE,
  INVENTORY_BASE_UNIT_TYPE,
  INVENTORY_GROUP,
  INVENTORY_QUANTITY,
  INVENTORY_CHARGABLE,
  INVENTORY_COST_PRICE,
  INVENTORY_SELL_PRICE,
  INVENTORY_EXPIRY_DAYS,
} from '../constants/constant'
import { t } from 'i18next'

export interface IinventoryItemTableValidators {
  [INVENTORY_ITEM_NAME]: {
    required: any
  }
  [INVENTORY_ITEM_CODE]: {
    required: any
  }
  [INVENTORY_BASE_UNIT_TYPE]: {
    required: any
  }
  [INVENTORY_GROUP]: {
    required: any
  }
  [INVENTORY_QUANTITY]: {
    required: any
    pattern: {
      value: any
      message: any
    }
  }
  [INVENTORY_CHARGABLE]: {
    required: any
  }
  [INVENTORY_COST_PRICE]: {
    required: any
    pattern: {
      value: any
      message: any
    }
  }
  [INVENTORY_SELL_PRICE]: {
    required: any
    pattern: {
      value: any
      message: any
    }
  }
  [INVENTORY_EXPIRY_DAYS]: {
    required: any
    pattern: {
      value: any
      message: any
    }
  }
}

export const inventoryItemTableValidators: IinventoryItemTableValidators = {
  [INVENTORY_ITEM_NAME]: {
    required: t('SupplierMaster.name_validation'),
  },
  [INVENTORY_ITEM_CODE]: {
    required: t('InventoryItemTable.Item_Code_Validation'),
  },
  [INVENTORY_BASE_UNIT_TYPE]: {
    required: t('InventoryItemTable.Base_Unit_Type_Validation'),
  },
  [INVENTORY_GROUP]: {
    required: t('InventoryItemTable.Group_validation'),
  },
  [INVENTORY_QUANTITY]: {
    required: 'Please enter quantity',
    pattern: {
      value: /^[0-9]{0,6}(?:\.[0-9]{1,3})?$/,
      message: t('InventoryItemTable.Quantity_Validation'),
    },
  },
  [INVENTORY_CHARGABLE]: {
    required: t('InventoryItemTable.Chargeable_Validation'),
  },
  [INVENTORY_COST_PRICE]: {
    required: t('BedSetup.cost_price_placeholder'),
    pattern: {
      value: /^[0-9]{0,6}(?:\.[0-9]{1,3})?$/,
      message: t('InventoryItemTable.cost_price_placeholder'),
    },
  },
  [INVENTORY_SELL_PRICE]: {
    required: t('BedSetup.sell_price_placeholder'),
    pattern: {
      value: /^[0-9]{0,6}(?:\.[0-9]{1,3})?$/,
      message: t('InventoryItemTable.Quantity_Value_Validation'),
    },
  },
  [INVENTORY_EXPIRY_DAYS]: {
    required: 'Please enter expiry days',
    pattern: {
      value: /^[0-9]{0,6}(?:\.[0-9]{1,3})?$/,
      message: t('InventoryItemTable.Expiry_Days_Validation'),
    },
  },
}
