import { t } from 'i18next'
import {
  PATIENT_NAME,
  DOCTOR_NAME,
  BOOKING_DATE,
  START_TIME,
  END_TIME,
  MOBILE_NO,
  RECURRING_DOCTOR_NAME,
  SESSION,
  RECURRING_START_DATE,
  TYPE,
  RECURRING_START_TIME,
  RECURRING_END_TIME,
  AVAILABLE_SLOT,
  PATINET_MOBILE_NO,
  SESSION_TIME,
  INTERVAL,
  STATUS_NAME,
} from '../constants/bookingConfirmationConstatnt'
import { IBookingConfirmationForm } from '../interfaces/bookingConfirmationModalInterfaces'

export const bookingConfirmationValidators: any = {
  [PATIENT_NAME]: {
    required: t('BookingConfirmationValidators.PATIENT_NAME'),
  },
  [DOCTOR_NAME]: {
    required: t('BookingConfirmationValidators.DOCTOR_NAME'),
  },
  [MOBILE_NO]: {
    required: t('BookingConfirmationValidators.MOBILE_NO'),
  },
  [PATINET_MOBILE_NO]: {
    required: t('BookingConfirmationValidators.PATINET_MOBILE_NO'),
  },
  [RECURRING_DOCTOR_NAME]: {},
  [STATUS_NAME]: {
    required: t('BookingConfirmationValidators.STATUS_NAME'),
  },
  [SESSION]: {
    required: t('BookingConfirmationValidators.SESSION'),
    validate: {
      handleValue: (value: any) => {
        if (value <= 0 || value > 90) {
          return t('BookingConfirmationValidators.SESSION_NO')
        }
      },
    },
  },
  [TYPE]: {
    required: t('BookingConfirmationValidators.TYPE'),
  },
  [SESSION_TIME]: {
    required: t('BookingConfirmationValidators.SESSION_TIME'),
  },
  [START_TIME]: {
    required: t('BookingConfirmationValidators.START_TIME'),
  },
  [END_TIME]: {
    required: t('BookingConfirmationValidators.END_TIME'),
    validate: {
      handleTime: (value: string, values: IBookingConfirmationForm) => {
        const startTime = values[START_TIME]
        if (value <= startTime) {
          return t('BookingConfirmationValidators.END_TIME_Greater')
        }
      },
    },
  },
  [RECURRING_START_TIME]: {
    required: t('BookingConfirmationValidators.RECURRING_START_TIME'),
  },
  [RECURRING_END_TIME]: {
    required: t('RECURRING_END_TIME'),
    validate: {
      handleTime: (value: string, values: IBookingConfirmationForm) => {
        const startTime = values[RECURRING_START_TIME]
        if (value <= startTime) {
          return t('BookingConfirmationValidators.RECURRING_END_TIME_Greater')
        }
      },
    },
  },
  [BOOKING_DATE]: {
    required: t('BookingConfirmationValidators.BOOKING_DATE'),
  },
  [RECURRING_START_DATE]: {
    required: t('BookingConfirmationValidators.RECURRING_START_DATE'),
  },
  // [DAY]: {
  //   required: 'Please Select Day',
  // },
  [AVAILABLE_SLOT]: {
    required: t('BookingConfirmationValidators.AVAILABLE_SLOT'),
  },
  // [NOTES]: {
  //   required: 'Please Enter Notes',
  // },
  [INTERVAL]: {
    required: t('BookingConfirmationValidators.INTERVAL'),
  },
}
