import { useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../hooks/index'
import jsPDF from 'jspdf'
import {
  getBranchStoreMainStoreRequestList,
  getMainStoreRequestItemsList,
} from '../../redux/features/branch-store/branchStoreAsyncActions'
import { requestGenerator } from '../../utils/payloadGenerator'
import { colors } from '../../constants/color'
import { CrossIcon, EditIcon } from '../../components/common/svg-components'
import moment from 'moment'
import AuthorizedStatusDropdown from '../../components/common/status-dropdown/authorized-status-dropdown/AuthorizedStatusDropdown'
import { markInventoryReqsAuthorize } from '../../redux/features/inventory-request/inventoryRequestAsyncActions'
import 'jspdf-autotable'
import { GET_MAIN_STORE_REQUEST_ITEMS_TYPE } from '../asyncActionsType'
import styles from '../../pages/branchstore/mainstore-request-item-popup/mainstorerequestitempopup.module.scss'
import { t } from 'i18next'

export const mainstoreHeaderData: any = [
  {
    Header: t('RcptTableHeader.DATE'),
    accessor: (row: any) => {
      return moment(row?.date)?.format('DD MMM YYYY')
    },
    Cell: (props: any) => {
      const convertDate = moment(props?.row?.original?.date).format(
        'DD MMM YYYY'
      )
      return (
        <>{props?.row?.original?.date ? <span>{convertDate}</span> : '-'}</>
      )
    },
  },
  {
    Header: t('INVTableHeader.DOCID'),
    Cell: (props: any) => {
      const dispatch = useAppDispatch()
      const mainStoreRequestEditPopupOpen = () => {
        const payloadData = {
          request_id: props?.row?.original?._id,
        }
        if (
          props?.row?.original?._id &&
          props?.row?.original?.authorization_status === 'APPROVED'
        ) {
          dispatch(
            getMainStoreRequestItemsList(requestGenerator(payloadData))
          ).then((response: any) => {
            if (
              response.type === `${GET_MAIN_STORE_REQUEST_ITEMS_TYPE}/fulfilled`
            ) {
              const doc: any = new jsPDF('p', 'pt', 'a4')
              const width = doc.internal.pageSize.getWidth()
              let tableData: any = []
              response.payload?.forEach((items: any, index: number) => {
                tableData.push([
                  items.item_id.item_no,
                  items.item_name,
                  index + 1,
                  items.req_unit_type_id.value,
                  items.requested_qty,
                ])
              })
              let offsetY = 4.797777777777778
              let lineHeight = 6.49111111111111
              doc
                .text('General Request Report', width / 2, 80, {
                  align: 'center',
                })
                .setFontSize(10)
                .setFont(undefined, 'bold')
              doc
                .text(`Doc. No.:  ${props.row.original.doc_id}`, 60, 120)
                .setFontSize(8)
                .setFont(undefined, 'bold')
              doc
                .text(`Branch:${'LB'}`, 60, 140)
                .setFontSize(8)
                .setFont(undefined, 'bold')
              doc
                .text(`Remarks: `, 400, 120)
                .setFontSize(8)
                .setFont(undefined, 'bold')
              doc
                .text(
                  `Date: ${moment(props.row.original.createdAt).format(
                    'DD-MMM-YYYY'
                  )}`,
                  400,
                  140
                )
                .setFontSize(8)
                .setFont(undefined, 'bold')

              doc.autoTable({
                startY: 155,
                styles: {
                  halign: 'center',
                  textColor: '#000',
                  fillColor: '#fefefe',
                },
                tableLineColor: '#000',
                tableLineWidth: 0.1,

                headStyles: { lineWidth: 0.1, lineColor: '#000' },
                bodyStyles: {
                  fontSize: '8',
                  lineWidth: 0.1,
                  lineColor: '#000',
                },

                head: [['Code', 'Name', 'Creator', 'Unit', 'Quantity']],
                body: tableData,
              })
              const dynamicHeight = doc?.autoTable?.previous?.finalY

              doc
                .text(
                  'Signature :',
                  20,
                  dynamicHeight + lineHeight * 5 + offsetY
                )
                .setFontSize(8)
                .setFont(undefined, 'bold')

              doc
                .text('Date :', 100, dynamicHeight + lineHeight * 5 + offsetY)
                .setFontSize(8)
                .setFont(undefined, 'bold')
              doc
                .text(
                  'Dept. Head',
                  200,
                  dynamicHeight + lineHeight * 5 + offsetY
                )
                .setFontSize(8)
                .setFont(undefined, 'bold')
              doc
                .text(
                  'Incharge :',
                  300,
                  dynamicHeight + lineHeight * 5 + offsetY
                )
                .setFontSize(8)
                .setFont(undefined, 'bold')
              doc
                .text(
                  'Department Head :',
                  400,
                  dynamicHeight + lineHeight * 5 + offsetY
                )
                .setFontSize(8)
                .setFont(undefined, 'bold')
              doc
                .text(
                  'Accounts :',
                  500,
                  dynamicHeight + lineHeight * 5 + offsetY
                )
                .setFontSize(8)
                .setFont(undefined, 'bold')
              doc
                .text('CEO', 600, dynamicHeight + lineHeight * 5 + offsetY)
                .setFontSize(8)
                .setFont(undefined, 'bold')

              doc.save('request.pdf')
              window.open(doc.output('bloburl'), '_blank')
            }
          })
        }
      }

      return (
        <>
          {props?.row?.original?.doc_id ? (
            <span
              className={
                props?.row?.original?.authorization_status === 'APPROVED'
                  ? styles.viewPdfLink
                  : styles.notViewPdfLink
              }
              onClick={mainStoreRequestEditPopupOpen}
            >
              {props?.row?.original?.doc_id}
            </span>
          ) : (
            '-'
          )}
        </>
      )
    },
  },
  {
    Header: t('INVTableHeader.ReqStatus'),
    accessor: 'request_status',
    disableSortBy: true,
    Cell: (props: any) => {
      return (
        <>
          {props?.row?.original?.request_status === 'INITIATED' ? (
            <p className={styles.requestStatusInitiate}>Initiated</p>
          ) : props?.row?.original?.request_status === 'PARTIAL' ? (
            <p className={styles.requestStatusPartial}>Partial</p>
          ) : (
            <p className={styles.requestStatusComplete}>Completed</p>
          )}
        </>
      )
    },
  },
  {
    Header: t('INVTableHeader.AuthStatus'),
    accessor: 'is_approved',
    disableSortBy: true,
    Cell: ({ row }: any) => {
      let appointment_id = row?.original?._id
      const dispatch = useAppDispatch()
      const { getBranchStoreMainStoreRequestPayload } = useAppSelector(
        (state) => state.branchStore
      )
      const [option, setOption] = useState(false)
      const handleStatus = (item: any) => {
        setOption(false)
        dispatch(
          markInventoryReqsAuthorize(
            requestGenerator({
              request_id: appointment_id,
              authorization_status: item?.enum,
            })
          )
        ).then((e: any) => {
          if (e.type === 'inventory/markRequestAuthorize/fulfilled') {
            dispatch(
              getBranchStoreMainStoreRequestList(
                requestGenerator(getBranchStoreMainStoreRequestPayload)
              )
            )
          }
        })
      }
      return (
        <>
          <AuthorizedStatusDropdown
            customClass={styles.optionContainerStyle}
            appointmentStatus={
              row?.original?.authorization_status === 'ENTERED'
                ? 'Entered'
                : row?.original?.authorization_status === 'APPROVED'
                ? 'Approved'
                : 'Rejected'
            }
            appointment_id={appointment_id}
            handleStatusClick={handleStatus}
            setShowOption={setOption}
            showOption={option}
          />
        </>
      )
    },
  },
  {
    Header: t('DocUploadHeader.ACTION'),
    accessor: 'Action',
    disableSortBy: true,
    Cell: (props: any) => {
      const handleEditItem = () => {
        if (props?.row?.original?.authorization_status === 'ENTERED') {
          props.onClick(props?.row?.original)
        } else {
          return
        }
      }
      const handleDeleteItem = () => {
        if (props?.row?.original?.authorization_status === 'ENTERED') {
          props.onOpen(props?.row?.original)
        } else {
          return
        }
      }
      return (
        <div className={styles.mainStoreActionIconContainer}>
          <EditIcon handleClick={handleEditItem} />
          <CrossIcon
            width={25}
            height={25}
            fillColor={colors.white1}
            fillColor1={colors.grey4}
            handleClick={handleDeleteItem}
          />
        </div>
      )
    },
  },
]
