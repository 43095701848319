// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.createNewFormBuilder_mainFormBuilderContainer__I7WIE {
  max-width: 1292px;
  padding-bottom: 20px;
  margin-right: 20px;
}

.createNewFormBuilder_headerContainer__rXIZD {
  background: rgba(255, 255, 255, 0.7);
  box-shadow: 0px 10px 117px 14px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  max-width: 1257px;
  padding: 10px;
  margin-bottom: 10px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}
.createNewFormBuilder_headerContainer__rXIZD .createNewFormBuilder_headerTitle__u10xS {
  grid-column-start: 1;
  grid-column-end: 3;
  display: flex;
  flex-direction: row;
  gap: 3rem;
}
.createNewFormBuilder_headerContainer__rXIZD .createNewFormBuilder_label_data__roF8y {
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  align-items: center;
}
.createNewFormBuilder_headerContainer__rXIZD .createNewFormBuilder_label_data__roF8y > div:nth-child(1) {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 14px;
  text-align: right;
  color: #181818;
}
.createNewFormBuilder_headerContainer__rXIZD .createNewFormBuilder_label_data__roF8y > div:nth-child(3) {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 14px;
  color: #0e26a3;
}
.createNewFormBuilder_headerContainer__rXIZD .createNewFormBuilder_buttonContainer__TQUAq {
  text-align: end;
}`, "",{"version":3,"sources":["webpack://./src/pages/create-new-forms/create-new-form-builder/createNewFormBuilder.module.scss"],"names":[],"mappings":"AAAA;EACE,iBAAA;EACA,oBAAA;EACA,kBAAA;AACF;;AAEA;EACE,oCAAA;EACA,mDAAA;EAEA,mBAAA;EACA,iBAAA;EACA,aAAA;EACA,mBAAA;EACA,aAAA;EACA,qCAAA;AAAF;AAEE;EACE,oBAAA;EACA,kBAAA;EACA,aAAA;EACA,mBAAA;EACA,SAAA;AAAJ;AAGE;EACE,aAAA;EACA,mBAAA;EACA,WAAA;EACA,mBAAA;AADJ;AAIE;EACE,sBAAA;EACA,kBAAA;EACA,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,iBAAA;EACA,cAAA;AAFJ;AAKE;EACE,sBAAA;EACA,kBAAA;EACA,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,cAAA;AAHJ;AAME;EACE,eAAA;AAJJ","sourcesContent":[".mainFormBuilderContainer {\n  max-width: calc(1125px + 167px);\n  padding-bottom: 20px;\n  margin-right: 20px;\n}\n\n.headerContainer {\n  background: rgba(255, 255, 255, 0.7);\n  box-shadow: 0px 10px 117px 14px rgba(0, 0, 0, 0.05);\n  // backdrop-filter: blur(97px);\n  border-radius: 10px;\n  max-width: 1257px;\n  padding: 10px;\n  margin-bottom: 10px;\n  display: grid;\n  grid-template-columns: repeat(3, 1fr);\n\n  .headerTitle {\n    grid-column-start: 1;\n    grid-column-end: 3;\n    display: flex;\n    flex-direction: row;\n    gap: 3rem;\n  }\n\n  .label_data {\n    display: flex;\n    flex-direction: row;\n    gap: 0.5rem;\n    align-items: center;\n  }\n\n  .label_data > div:nth-child(1) {\n    font-family: 'Poppins';\n    font-style: normal;\n    font-weight: 600;\n    font-size: 14px;\n    line-height: 14px;\n    text-align: right;\n    color: #181818;\n  }\n\n  .label_data > div:nth-child(3) {\n    font-family: 'Poppins';\n    font-style: normal;\n    font-weight: 600;\n    font-size: 14px;\n    line-height: 14px;\n    color: #0e26a3;\n  }\n\n  .buttonContainer {\n    text-align: end;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"mainFormBuilderContainer": `createNewFormBuilder_mainFormBuilderContainer__I7WIE`,
	"headerContainer": `createNewFormBuilder_headerContainer__rXIZD`,
	"headerTitle": `createNewFormBuilder_headerTitle__u10xS`,
	"label_data": `createNewFormBuilder_label_data__roF8y`,
	"buttonContainer": `createNewFormBuilder_buttonContainer__TQUAq`
};
export default ___CSS_LOADER_EXPORT___;
