import { t } from 'i18next'
import { CONFIRM_NEW_PASSWORD, NEW_PASSWORD } from '../constants/constant'

export interface IRecoveryPasswordValidators {
  [NEW_PASSWORD]: {
    required: string
    pattern: {
      value: RegExp
      message: string
    }
  }
  [CONFIRM_NEW_PASSWORD]: {
    required: any
    validate?: {
      matchesPassword: any
    }
  }
}

export const recoveryPasswordValidators: IRecoveryPasswordValidators = {
  [NEW_PASSWORD]: {
    required: t('AuthValidator.NewPassword'),
    pattern: {
      value:
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]).{6,16}$/,
      message: t('AuthValidator.NewPassRegex'),
    },
  },
  [CONFIRM_NEW_PASSWORD]: {
    required: t('AuthValidator.ConfirmPassword'),
    validate: {
      matchesPassword: (value: string, formValues: any) => {
        const password = formValues[NEW_PASSWORD]
        if (password !== value) {
          return t('AuthValidator.ConfirmPasswordMatch')
        }
        return true
      },
    },
  },
}
