import { FC } from 'react'
import styles from './readOnlyLabelField.module.scss'

interface IReadOnlyField {
  label: string
  field: string
}

const ReadOnlyLabelField: FC<IReadOnlyField> = ({ label, field }) => {
  return (
    <>
      <div className={styles.labelFieldContainer}>
        <span className={styles.label}>{label} :</span>
        <span className={styles.value}>{field}</span>
      </div>
    </>
  )
}

export default ReadOnlyLabelField
