import { FC } from 'react'
import styles from './TranslationMenu.module.scss'
import { languageType } from '../../../redux/features/toast/toastSlice'
import useLanguage from '../../../hooks/useLanguage'
import i18n from 'i18next'

interface ITranslationMenu {
  translationRef: any
  handleClose: any
  customClass?: string
}
const TranslationMenu: FC<ITranslationMenu> = ({
  translationRef,
  handleClose,
  customClass,
}) => {
  const { language } = useLanguage()
  const langueages = [
    {
      label: 'English',
      lan: 'en',
      dir: 'ltr',
    },
    {
      label: 'العربية',
      lan: 'ar',
      dir: 'ltr',
    },
  ]

  const handleLanguage = (item: languageType) => {
    i18n.changeLanguage(item.lan)
    localStorage.setItem('i18nextLng', item.lan)
    // document.body.dir = i18next.dir()
    window.location.reload()
    handleClose()
  }

  return (
    <>
      <div
        className={[styles.mainContainer, customClass].join(' ')}
        ref={translationRef}
      >
        {langueages?.map((item: languageType, index: number) => {
          return (
            <span
              className={
                language === item.lan
                  ? styles.languageActive
                  : styles.languageName
              }
              key={`${item.label}-${index}`}
              onClick={() => handleLanguage(item)}
            >
              {item?.label}
            </span>
          )
        })}
      </div>
    </>
  )
}

export default TranslationMenu
