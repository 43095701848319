import { t } from 'i18next'
import {
  INVOICE_TYPE,
  INVOICE_PATIENT_BRANCH_NAME,
  INVOICE_PATIENT_MOBILE_NO,
  INVOICE_PATIENT_NAME,
  INVOICE_PATIENT_PRIMARY_DOCTOR,
  INVOICE_PATIENT_TYPE,
} from '../constants/constant'

export interface IreceptionistInvoiceValidators {
  [INVOICE_TYPE]: {
    required: string
  }
  [INVOICE_PATIENT_BRANCH_NAME]: {
    required: string
  }
  [INVOICE_PATIENT_TYPE]: {
    required: string
  }
  [INVOICE_PATIENT_NAME]: {
    required: string
  }
  [INVOICE_PATIENT_PRIMARY_DOCTOR]: {
    required: string
  }
  [INVOICE_PATIENT_MOBILE_NO]: {
    required: string
  }
}
export const receptionistInvoiceValidators: IreceptionistInvoiceValidators = {
  [INVOICE_TYPE]: {
    required: t('InvoiceInfo.InvoiceType'),
  },
  [INVOICE_PATIENT_BRANCH_NAME]: {
    required: t('InvoiceInfo.BranchVal'),
  },
  [INVOICE_PATIENT_TYPE]: {
    required: t('InvoiceInfo.PatientType'),
  },
  [INVOICE_PATIENT_NAME]: {
    required: t('InvoiceInfo.PatientName'),
  },
  [INVOICE_PATIENT_PRIMARY_DOCTOR]: {
    required: t('InvoiceInfo.PrimaryDoctor'),
  },
  [INVOICE_PATIENT_MOBILE_NO]: {
    required: t('PatientEmrValidators.MOB1'),
  },
}
