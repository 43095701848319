import { FC, useState, useEffect } from 'react'
import TableV2 from '../../../../components/common/table/tableV2/TableV2'
import styles from './internal.module.scss'
import Button from '../../../../components/common/button/Button'
import Popup from '../../../../components/common/popup/Popup'
import Criteria from './criteria-popup/Criteria'

import { ExternalHeader } from './InternalTableData'
import {
  INTERNAL_ASSIGNED,
  INTERNAL_UNASSIGNED,
  TOTAL_ASSIGNED,
} from '../../../../constants/constant'
import { ILeadPoolForm } from '../../../../interfaces/interfaces'
import { useForm } from 'react-hook-form'
import { useAppDispatch, useAppSelector } from '../../../../hooks'
import {
  assignInternalLead,
  getAllAssignee,
  getAllLeadCountInfo,
  getAllLeadPool,
} from '../../../../redux/features/lead-pool/callCenterAsyncActions'
import { requestGenerator } from '../../../../utils/payloadGenerator'
import Loader from '../../../../components/common/spinner/Loader'
import Pagination from '../../../../components/common/pagination/Pagination'
import { clearAllLeadData } from '../../../../redux/features/lead-pool/callCenterSlice'
import TagListPopup from '../../../../components/common/modal/tag-list-popup/TagListPopup'
import { t } from 'i18next'

interface IInternal {}

const Internal: FC<IInternal> = () => {
  const [dataPerPage, setDataPerPage] = useState<number>(10)
  const [totalPage, setTotalPage] = useState<number>(0)
  const [pageIndex, setPageIndex] = useState<number>(1)
  const [totalData, setTotalData] = useState(0)
  const [showRemarksPopup, setShowRemarksPopup] = useState<boolean>(false)
  const [notesPopData, setNotesPopData] = useState({})
  const [showTagList, setShowTagList] = useState(false)
  const [taglistData, setTaglistData] = useState()
  const dispatch = useAppDispatch()
  const { isLoading, allLeadData, allLeadCountInfo } = useAppSelector(
    (state) => state.callCenter
  )
  const [assign, setAssign] = useState<boolean>()

  let allInteralData =
    allLeadCountInfo?.length > 0 &&
    allLeadCountInfo?.filter((item: any) => item?._id?.type === 'INTERNAL')

  // let assignedData =
  //   allInteralData?.length > 0 &&
  //   allInteralData?.filter((item: any) => item?._id?.is_assigned);

  // let unassignedData =
  //   allInteralData?.length > 0 &&
  //   allInteralData?.filter((item: any) => !item?._id?.is_assigned);

  const countData = (isAssigned: boolean) => {
    if (allInteralData?.length > 0) {
      let filterData = allInteralData?.filter(
        (item: any) => item?._id?.is_assigned === isAssigned
      )
      if (filterData?.length > 0) {
        return filterData[0]?.count
      } else {
        return 0
      }
    } else {
      return 0
    }
  }

  let assignCountData = countData(true)
  let unassignCountData = countData(false)

  const pageIndexArray = () => {
    let pageIndexOptions = []
    for (let i = 1; i <= totalPage; i++) {
      pageIndexOptions.push(i)
    }
    return pageIndexOptions
  }

  const pageIndexOptions = pageIndexArray()

  const handleremarks = () => {
    setShowRemarksPopup((prevState) => !prevState)
  }
  const handleremark = (notesObject: {}) => {
    setShowRemarksPopup((prevState) => !prevState)
    setNotesPopData(notesObject)
  }

  useEffect(() => {
    let data: any = {
      type: 'INTERNAL',
      page: pageIndex,
      pageSize: dataPerPage,
    }
    let reqData: any = {
      type: 'INTERNAL',
      is_assigned: assign,
      page: pageIndex,
      pageSize: dataPerPage,
    }
    if (showRemarksPopup === false) {
      dispatch(
        getAllLeadPool(requestGenerator(assign === undefined ? data : reqData))
      ).then((result) => {
        setTotalPage(result.payload.lastPage)
        setTotalData(result.payload.total)
      })
    }
  }, [pageIndex, dataPerPage, showRemarksPopup])

  useEffect(() => {
    let data: any = {
      type: 'INTERNAL',
      is_assigned: assign,
      page: 1,
      pageSize: dataPerPage,
    }
    if (data?.is_assigned !== undefined) {
      dispatch(getAllLeadPool(requestGenerator(data))).then((result) => {
        setTotalPage(result.payload.lastPage)
        setTotalData(result.payload.total)
        setPageIndex(1)
      })
    }
  }, [assign])

  const {
    register,
    watch,
    setValue,
    formState: { errors },
  } = useForm<ILeadPoolForm>({})

  const formData = watch()

  useEffect(() => {
    if (formData[INTERNAL_ASSIGNED] === 'INTERNAL_ASSIGNED') {
      setValue(INTERNAL_ASSIGNED, 'INTERNAL_ASSIGNED')
      setValue(INTERNAL_UNASSIGNED, '')
      setAssign(true)
    }
  }, [formData[INTERNAL_ASSIGNED]])

  useEffect(() => {
    if (formData[INTERNAL_UNASSIGNED] === 'INTERNAL_UNASSIGNED') {
      setValue(INTERNAL_ASSIGNED, '')
      setValue(INTERNAL_UNASSIGNED, 'INTERNAL_UNASSIGNED')
      setAssign(false)
    }
  }, [formData[INTERNAL_UNASSIGNED]])

  useEffect(() => {
    dispatch(getAllAssignee(requestGenerator({ search: 'CALL_CENTER_AGENT' })))
  }, [])

  const handleAssign = (item: any, userId: string) => {
    dispatch(
      assignInternalLead(requestGenerator({ id: item, userId: userId }))
    ).then((e) => {
      dispatch(clearAllLeadData())
      if (e.type === 'callCenter/assignInternalLead/fulfilled') {
        let data: any = {
          type: 'INTERNAL',
          page: pageIndex,
          pageSize: dataPerPage,
        }
        let reqData: any = {
          type: 'INTERNAL',
          is_assigned: assign,
          page: pageIndex,
          pageSize: dataPerPage,
        }
        dispatch(getAllLeadCountInfo(requestGenerator({})))

        dispatch(
          getAllLeadPool(
            requestGenerator(assign === undefined ? data : reqData)
          )
        ).then((result) => {
          setTotalPage(result.payload.lastPage)
          setTotalData(result.payload.total)
        })
      }
    })
  }

  useEffect(() => {
    if (showRemarksPopup === false) {
      setValue(INTERNAL_ASSIGNED, '')
      setValue(INTERNAL_UNASSIGNED, '')
      setAssign(undefined)
    }
  }, [showRemarksPopup])

  useEffect(() => {
    dispatch(getAllLeadCountInfo(requestGenerator({})))
  }, [])

  return (
    <>
      {isLoading && <Loader />}

      {showRemarksPopup && (
        <Popup
          Children={Criteria}
          popData={notesPopData}
          handleClose={() => handleremarks()}
          handleSubmitData={(item: any) => {
            setShowTagList(true)
            setTaglistData(item)
          }}
        />
      )}
      {showTagList && (
        <Popup
          Children={TagListPopup}
          handleClose={() => {
            setShowTagList(false)
          }}
          popData={taglistData}
          headerData={t('LeadPool.Tag_List')}
        />
      )}
      <div className={styles.admin}>
        <div className={styles.headerContainer}>
          <div className={styles.leftContainer}>
            <label className={styles.importTitle}>
              {t('LeadPool.Internal_Leads')}
            </label>
            <Button
              title={t('LeadPool.Import') || 'Import'}
              handleClick={handleremark}
              customClass={styles.btnStyle}
            />
          </div>
          <div className={styles.rightContainer}>
            <div className={styles.radioFieldContainer}>
              <p className={styles.titleText}>
                {t('InvoiceInfo.Internal')}:{' '}
                {assignCountData + unassignCountData}
                {/* {assignedData[0]?.count !== undefined
                  ? assignedData[0]?.count
                  : 0 + unassignedData[0]?.count !== undefined
                  ? unassignedData[0]?.count
                  : 0} */}
              </p>

              <div className={styles.singlRadioBtnConatainer}>
                <label htmlFor="internal_assign" className={styles.radioLabel}>
                  <input
                    className={styles.radioInput}
                    type="radio"
                    id="internal_assign"
                    value="INTERNAL_ASSIGNED"
                    {...register(INTERNAL_ASSIGNED)}
                  />
                  <span className={styles.customRadio} />
                  <p
                    className={
                      formData[INTERNAL_ASSIGNED] === 'INTERNAL_ASSIGNED'
                        ? styles.radioLabelTxtActive
                        : styles.radioLabelTxt
                    }
                  >
                    {t('HeaderPopup.Assigned')}: {assignCountData}
                    {/* {
                     allInteralData?.length > 0 && allInteralData?.filter(
                        (item: any) => item?._id?.is_assigned
                      )?.count
                    } */}
                  </p>
                </label>
              </div>
              <div className={styles.singlRadioBtnConatainer}>
                <label
                  htmlFor="internal_unassign"
                  className={styles.radioLabel}
                >
                  <input
                    className={styles.radioInput}
                    type="radio"
                    id="internal_unassign"
                    value="INTERNAL_UNASSIGNED"
                    {...register(INTERNAL_UNASSIGNED)}
                  />
                  <span className={styles.customRadio} />
                  <p
                    className={
                      formData[INTERNAL_UNASSIGNED] === 'INTERNAL_UNASSIGNED'
                        ? styles.radioLabelTxtActive
                        : styles.radioLabelTxt
                    }
                  >
                    {' '}
                    {t('LeadPool.Unassigned')}: {unassignCountData}
                  </p>
                </label>
              </div>
            </div>
          </div>
        </div>
        <TableV2
          tableHeaderData={ExternalHeader}
          tableRowData={allLeadData}
          handleClick={handleAssign}
        />
        {totalData > 10 && (
          <Pagination
            setDataPerPage={setDataPerPage}
            pageIndexOptions={pageIndexOptions}
            pageIndex={pageIndex}
            setPageIndex={setPageIndex}
            pageSize={dataPerPage}
          />
        )}
      </div>
    </>
  )
}
export default Internal
