// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.createNewFormNotesDetailDialog_mainContainer__mGzPc {
  width: 484px;
  min-height: 381px;
  margin: auto;
  background: var(--white1);
  border-radius: 10px;
  padding: 10px;
}
.createNewFormNotesDetailDialog_mainContainer__mGzPc .createNewFormNotesDetailDialog_iconContainer__XCYGy {
  display: block;
  text-align: end;
}
.createNewFormNotesDetailDialog_mainContainer__mGzPc .createNewFormNotesDetailDialog_closeIconStyle__D3bTr {
  cursor: pointer;
}
.createNewFormNotesDetailDialog_mainContainer__mGzPc .createNewFormNotesDetailDialog_headerContainer__QGYC6 {
  text-align: center;
}
.createNewFormNotesDetailDialog_mainContainer__mGzPc .createNewFormNotesDetailDialog_headerContainer__QGYC6 .createNewFormNotesDetailDialog_title__iWn5j {
  font-weight: var(--font-semibold);
  font-size: var(--font-24);
  line-height: 36px;
  color: var(--black2);
}
.createNewFormNotesDetailDialog_mainContainer__mGzPc .createNewFormNotesDetailDialog_headerContainer__QGYC6 .createNewFormNotesDetailDialog_dividerStyle__GysUa {
  margin: 10px auto 30px auto;
  width: 80px;
  height: 3px;
  background: var(--green1);
}
.createNewFormNotesDetailDialog_mainContainer__mGzPc .createNewFormNotesDetailDialog_sectionContainer__tI3Iv {
  margin: 44px 0px 0px 0px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
  justify-content: center;
}
.createNewFormNotesDetailDialog_mainContainer__mGzPc .createNewFormNotesDetailDialog_buttonContainer__5W9sZ {
  margin: 33px 0px;
}`, "",{"version":3,"sources":["webpack://./src/pages/create-new-forms/create-new-form-notes-detail-dialog/createNewFormNotesDetailDialog.module.scss"],"names":[],"mappings":"AAGA;EACI,YAAA;EACA,iBAAA;EACA,YAAA;EACA,yBAAA;EACA,mBAAA;EACA,aAAA;AAFJ;AAII;EACE,cAAA;EACA,eAAA;AAFN;AAII;EACE,eAAA;AAFN;AAII;EACE,kBAAA;AAFN;AAGM;EACE,iCAAA;EACA,yBAAA;EACA,iBAAA;EACA,oBAAA;AADR;AAGM;EACE,2BAAA;EACA,WAAA;EACA,WAAA;EACA,yBAAA;AADR;AAKI;EACE,wBAAA;EACA,WAAA;EACA,aAAA;EACA,sBAAA;EACA,SAAA;EACA,mBAAA;EACA,uBAAA;AAHN;AAMI;EACE,gBAAA;AAJN","sourcesContent":["\n\n\n.mainContainer {\n    width: 484px;\n    min-height: 381px;\n    margin: auto;\n    background: var(--white1);\n    border-radius: 10px;\n    padding: 10px;\n\n    .iconContainer{\n      display: block;\n      text-align: end;\n    }\n    .closeIconStyle {\n      cursor: pointer;\n    }\n    .headerContainer {\n      text-align: center;\n      .title {\n        font-weight: var(--font-semibold);\n        font-size: var(--font-24);\n        line-height: 36px;\n        color: var(--black2);\n      }\n      .dividerStyle {\n        margin: 10px auto 30px auto;\n        width: 80px;\n        height: 3px;\n        background: var(--green1);\n      }\n    }\n\n    .sectionContainer{\n      margin: 44px 0px 0px 0px;\n      width: 100%;\n      display: flex;\n      flex-direction: column;\n      gap: 1rem;\n      align-items: center;\n      justify-content: center;\n    }\n\n    .buttonContainer{\n      margin: 33px 0px;\n    }\n  \n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"mainContainer": `createNewFormNotesDetailDialog_mainContainer__mGzPc`,
	"iconContainer": `createNewFormNotesDetailDialog_iconContainer__XCYGy`,
	"closeIconStyle": `createNewFormNotesDetailDialog_closeIconStyle__D3bTr`,
	"headerContainer": `createNewFormNotesDetailDialog_headerContainer__QGYC6`,
	"title": `createNewFormNotesDetailDialog_title__iWn5j`,
	"dividerStyle": `createNewFormNotesDetailDialog_dividerStyle__GysUa`,
	"sectionContainer": `createNewFormNotesDetailDialog_sectionContainer__tI3Iv`,
	"buttonContainer": `createNewFormNotesDetailDialog_buttonContainer__5W9sZ`
};
export default ___CSS_LOADER_EXPORT___;
