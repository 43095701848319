import { FC, useEffect, useState } from 'react'
import TableV2 from '../../../../components/common/table/tableV2/TableV2'
import styles from './lead.module.scss'
import { LeadHeader } from './LeadsTableData'
import {
  EXTERNAL_ASSIGNED,
  EXTERNAL_UNASSIGNED,
  INTERNAL_ASSIGNED,
  INTERNAL_UNASSIGNED,
  TOTAL_ASSIGNED,
  TOTAL_UNASSIGNED,
} from '../../../../constants/constant'
import { ILeadPoolForm } from '../../../../interfaces/interfaces'
import { useForm } from 'react-hook-form'
import Pagination from '../../../../components/common/pagination/Pagination'
import { requestGenerator } from '../../../../utils/payloadGenerator'
import {
  getAllLeadCountInfo,
  getAllLeadPool,
} from '../../../../redux/features/lead-pool/callCenterAsyncActions'
import { useAppDispatch, useAppSelector } from '../../../../hooks'
import Loader from '../../../../components/common/spinner/Loader'
import { t } from 'i18next'

interface ILeads {}
const Leads: FC<ILeads> = () => {
  const [dataPerPage, setDataPerPage] = useState<number>(10)
  const [totalPage, setTotalPage] = useState<number>(0)
  const [pageIndex, setPageIndex] = useState<number>(1)
  const [totalData, setTotalData] = useState(0)
  const dispatch = useAppDispatch()
  const { isLoading, allLeadData, allLeadCountInfo } = useAppSelector(
    (state) => state.callCenter
  )
  const [type, setType] = useState('')
  const [assign, setAssign] = useState<boolean>()

  let allInteralData =
    allLeadCountInfo?.length > 0 &&
    allLeadCountInfo?.filter((item: any) => item?._id?.type === 'INTERNAL')

  let assignedInternalData =
    allInteralData?.length > 0 &&
    allInteralData?.filter((item: any) => item?._id?.is_assigned)

  let unassignedInternalData =
    allInteralData?.length > 0 &&
    allInteralData?.filter((item: any) => !item?._id?.is_assigned)

  let allExteralData =
    allLeadCountInfo?.length > 0 &&
    allLeadCountInfo?.filter((item: any) => item?._id?.type === 'EXTERNAL')

  let assignedData =
    allExteralData?.length > 0 &&
    allExteralData?.filter((item: any) => item?._id?.is_assigned)

  let unassignedData =
    allExteralData?.length > 0 &&
    allExteralData?.filter((item: any) => !item?._id?.is_assigned)

  const pageIndexArray = () => {
    let pageIndexOptions = []
    for (let i = 1; i <= totalPage; i++) {
      pageIndexOptions.push(i)
    }
    return pageIndexOptions
  }

  const pageIndexOptions = pageIndexArray()

  useEffect(() => {
    let data: any = {
      type: type,
      page: pageIndex,
      pageSize: dataPerPage,
    }

    let reqData = {
      type: type,
      is_assigned: assign,
      page: pageIndex,
      pageSize: dataPerPage,
    }

    dispatch(
      getAllLeadPool(requestGenerator(assign === undefined ? data : reqData))
    ).then((result) => {
      setTotalPage(result.payload.lastPage)
      setTotalData(result.payload.total)
    })
  }, [pageIndex, dataPerPage])

  useEffect(() => {
    let data: any = {
      type: type,
      is_assigned: assign,
      page: 1,
      pageSize: dataPerPage,
    }
    if (data?.is_assigned !== undefined) {
      dispatch(getAllLeadPool(requestGenerator(data))).then((result) => {
        setTotalPage(result.payload.lastPage)
        setTotalData(result.payload.total)
        setPageIndex(1)
      })
    }
  }, [type, assign])

  const { register, watch, setValue } = useForm<ILeadPoolForm>({})

  const formData = watch()

  useEffect(() => {
    if (formData[TOTAL_ASSIGNED] === 'TOTAL_ASSIGNED') {
      setValue(TOTAL_ASSIGNED, 'TOTAL_ASSIGNED')
      setValue(TOTAL_UNASSIGNED, '')
      setValue(INTERNAL_ASSIGNED, '')
      setValue(INTERNAL_UNASSIGNED, '')
      setValue(EXTERNAL_ASSIGNED, '')
      setValue(EXTERNAL_UNASSIGNED, '')
      setType('')
      setAssign(true)
    }
  }, [formData[TOTAL_ASSIGNED]])

  useEffect(() => {
    if (formData[TOTAL_UNASSIGNED] === 'TOTAL_UNASSIGNED') {
      setValue(TOTAL_ASSIGNED, '')
      setValue(TOTAL_UNASSIGNED, 'TOTAL_UNASSIGNED')
      setValue(INTERNAL_ASSIGNED, '')
      setValue(INTERNAL_UNASSIGNED, '')
      setValue(EXTERNAL_ASSIGNED, '')
      setValue(EXTERNAL_UNASSIGNED, '')
      setType('')
      setAssign(false)
    }
  }, [formData[TOTAL_UNASSIGNED]])

  useEffect(() => {
    if (formData[INTERNAL_ASSIGNED] === 'INTERNAL_ASSIGNED') {
      setValue(TOTAL_ASSIGNED, '')
      setValue(TOTAL_UNASSIGNED, '')
      setValue(INTERNAL_ASSIGNED, 'INTERNAL_ASSIGNED')
      setValue(INTERNAL_UNASSIGNED, '')
      setValue(EXTERNAL_ASSIGNED, '')
      setValue(EXTERNAL_UNASSIGNED, '')
      setType('INTERNAL')
      setAssign(true)
    }
  }, [formData[INTERNAL_ASSIGNED]])

  useEffect(() => {
    if (formData[INTERNAL_UNASSIGNED] === 'INTERNAL_UNASSIGNED') {
      setValue(TOTAL_ASSIGNED, '')
      setValue(TOTAL_UNASSIGNED, '')
      setValue(INTERNAL_ASSIGNED, '')
      setValue(INTERNAL_UNASSIGNED, 'INTERNAL_UNASSIGNED')
      setValue(EXTERNAL_ASSIGNED, '')
      setValue(EXTERNAL_UNASSIGNED, '')
      setType('INTERNAL')
      setAssign(false)
    }
  }, [formData[INTERNAL_UNASSIGNED]])

  useEffect(() => {
    if (formData[EXTERNAL_ASSIGNED] === 'EXTERNAL_ASSIGNED') {
      setValue(TOTAL_ASSIGNED, '')
      setValue(TOTAL_UNASSIGNED, '')
      setValue(INTERNAL_ASSIGNED, '')
      setValue(INTERNAL_UNASSIGNED, '')
      setValue(EXTERNAL_ASSIGNED, 'EXTERNAL_ASSIGNED')
      setValue(EXTERNAL_UNASSIGNED, '')
      setType('EXTERNAL')
      setAssign(true)
    }
  }, [formData[EXTERNAL_ASSIGNED]])

  useEffect(() => {
    if (formData[EXTERNAL_UNASSIGNED] === 'EXTERNAL_UNASSIGNED') {
      setValue(TOTAL_ASSIGNED, '')
      setValue(TOTAL_UNASSIGNED, '')
      setValue(INTERNAL_ASSIGNED, '')
      setValue(INTERNAL_UNASSIGNED, '')
      setValue(EXTERNAL_ASSIGNED, '')
      setValue(EXTERNAL_UNASSIGNED, 'EXTERNAL_UNASSIGNED')
      setType('EXTERNAL')
      setAssign(false)
    }
  }, [formData[EXTERNAL_UNASSIGNED]])

  useEffect(() => {
    dispatch(getAllLeadCountInfo(requestGenerator({})))
  }, [])

  let assignedInternalCount =
    assignedInternalData[0]?.count !== undefined
      ? assignedInternalData[0]?.count
      : 0
  let assignedExternalCount =
    assignedData[0]?.count !== undefined ? assignedData[0]?.count : 0
  let unassignedInternalCount =
    unassignedInternalData[0]?.count !== undefined
      ? unassignedInternalData[0]?.count
      : 0
  let unassignedExternalCount =
    unassignedData[0]?.count !== undefined ? unassignedData[0]?.count : 0

  return (
    <>
      {isLoading && <Loader />}
      <div className={styles.admin}>
        <div className={styles.row}>
          <div className={styles.col}>
            <h1>
              {t('HeaderPopup.Total Leads')}:{' '}
              {assignedInternalCount +
                assignedExternalCount +
                unassignedInternalCount +
                unassignedExternalCount}
            </h1>
            <div className={styles.radioFieldContainer}>
              <div className={styles.singlRadioBtnConatainer}>
                <label htmlFor="total_assign" className={styles.radioLabel}>
                  <input
                    className={styles.radioInput}
                    type="radio"
                    id="total_assign"
                    value="TOTAL_ASSIGNED"
                    {...register(TOTAL_ASSIGNED)}
                  />
                  <span className={styles.customRadio} />
                  <p
                    className={
                      formData[TOTAL_ASSIGNED] === 'TOTAL_ASSIGNED'
                        ? styles.radioLabelTxtActive
                        : styles.radioLabelTxt
                    }
                  >
                    {t('HeaderPopup.Assigned')}:{' '}
                    {assignedInternalCount + assignedExternalCount}
                  </p>
                </label>
              </div>
              <div className={styles.singlRadioBtnConatainer}>
                <label htmlFor="total_unassign" className={styles.radioLabel}>
                  <input
                    className={styles.radioInput}
                    type="radio"
                    id="total_unassign"
                    value="TOTAL_UNASSIGNED"
                    {...register(TOTAL_UNASSIGNED)}
                  />
                  <span className={styles.customRadio} />
                  <p
                    className={
                      formData[TOTAL_UNASSIGNED] === 'TOTAL_UNASSIGNED'
                        ? styles.radioLabelTxtActive
                        : styles.radioLabelTxt
                    }
                  >
                    {' '}
                    {t('LeadPool.Unassigned')}:{' '}
                    {unassignedInternalCount + unassignedExternalCount}
                  </p>
                </label>
              </div>
            </div>
          </div>
          <div className={styles.col}>
            <h1>
              {t('InvoiceInfo.Internal')}:{' '}
              {assignedInternalCount + unassignedInternalCount}
            </h1>
            <div className={styles.radioFieldContainer}>
              <div className={styles.singlRadioBtnConatainer}>
                <label htmlFor="internal_assign" className={styles.radioLabel}>
                  <input
                    className={styles.radioInput}
                    type="radio"
                    id="internal_assign"
                    value="INTERNAL_ASSIGNED"
                    {...register(INTERNAL_ASSIGNED)}
                  />
                  <span className={styles.customRadio} />
                  <p
                    className={
                      formData[INTERNAL_ASSIGNED] === 'INTERNAL_ASSIGNED'
                        ? styles.radioLabelTxtActive
                        : styles.radioLabelTxt
                    }
                  >
                    {' '}
                    {t('HeaderPopup.Assigned')}: {assignedInternalCount}
                  </p>
                </label>
              </div>
              <div className={styles.singlRadioBtnConatainer}>
                <label
                  htmlFor="internal_unassign"
                  className={styles.radioLabel}
                >
                  <input
                    className={styles.radioInput}
                    type="radio"
                    id="internal_unassign"
                    value="INTERNAL_UNASSIGNED"
                    {...register(INTERNAL_UNASSIGNED)}
                  />
                  <span className={styles.customRadio} />
                  <p
                    className={
                      formData[INTERNAL_UNASSIGNED] === 'INTERNAL_UNASSIGNED'
                        ? styles.radioLabelTxtActive
                        : styles.radioLabelTxt
                    }
                  >
                    {' '}
                    {t('LeadPool.Unassigned')}: {unassignedInternalCount}
                  </p>
                </label>
              </div>
            </div>
          </div>
          <div className={styles.col}>
            <h1>
              {' '}
              {t('InvoiceInfo.External')}:{' '}
              {assignedExternalCount + unassignedExternalCount}
            </h1>
            <div className={styles.radioFieldContainer}>
              <div className={styles.singlRadioBtnConatainer}>
                <label htmlFor="external_assign" className={styles.radioLabel}>
                  <input
                    className={styles.radioInput}
                    type="radio"
                    id="external_assign"
                    value="EXTERNAL_ASSIGNED"
                    {...register(EXTERNAL_ASSIGNED)}
                  />
                  <span className={styles.customRadio} />
                  <p
                    className={
                      formData[EXTERNAL_ASSIGNED] === 'EXTERNAL_ASSIGNED'
                        ? styles.radioLabelTxtActive
                        : styles.radioLabelTxt
                    }
                  >
                    {' '}
                    {t('HeaderPopup.Assigned')}: {assignedExternalCount}
                  </p>
                </label>
              </div>
              <div className={styles.singlRadioBtnConatainer}>
                <label
                  htmlFor="external_unassign"
                  className={styles.radioLabel}
                >
                  <input
                    className={styles.radioInput}
                    type="radio"
                    id="external_unassign"
                    value="EXTERNAL_UNASSIGNED"
                    {...register(EXTERNAL_UNASSIGNED)}
                  />
                  <span className={styles.customRadio} />
                  <p
                    className={
                      formData[EXTERNAL_UNASSIGNED] === 'EXTERNAL_UNASSIGNED'
                        ? styles.radioLabelTxtActive
                        : styles.radioLabelTxt
                    }
                  >
                    {' '}
                    {t('LeadPool.Unassigned')}: {unassignedExternalCount}
                  </p>
                </label>
              </div>
            </div>
          </div>
        </div>
        <TableV2 tableHeaderData={LeadHeader} tableRowData={allLeadData} />
        {totalData > 10 && (
          <Pagination
            setDataPerPage={setDataPerPage}
            pageIndexOptions={pageIndexOptions}
            pageIndex={pageIndex}
            setPageIndex={setPageIndex}
            pageSize={dataPerPage}
          />
        )}
      </div>
    </>
  )
}
export default Leads
