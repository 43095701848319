import {
  ASSIGN_INTERNAL_LEAD,
  CREATE_EXTERNAL_LEAD,
  CREATE_INTERNAL_LEAD,
  GET_ALL_ASSIGNEE,
  GET_ALL_LEAD_COUNT_INFO,
  GET_ALL_LEAD_POOL,
  GET_INTERNAL_LEAD,
} from '../../../constants/asyncActionsType';
import createAsyncThunkForSlice from '../../../utils/utils';
import {
  assignInternalLeads,
  createExternalLeads,
  createInternalLeads,
  getAllAssignees,
  getAllLeadCountsInfo,
  getAllLeadPools,
} from './callCenter';

export const getAllLeadPool = createAsyncThunkForSlice(
  GET_ALL_LEAD_POOL,
  getAllLeadPools
);

export const createInternalLead = createAsyncThunkForSlice(
  CREATE_INTERNAL_LEAD,
  createInternalLeads,
  {
    isToast: true,
  }
);

export const getInternalLead = createAsyncThunkForSlice(
  GET_INTERNAL_LEAD,
  createInternalLeads
);

export const assignInternalLead = createAsyncThunkForSlice(
  ASSIGN_INTERNAL_LEAD,
  assignInternalLeads
);

export const createExternalLead = createAsyncThunkForSlice(
  CREATE_EXTERNAL_LEAD,
  createExternalLeads,
  { isToast: true }
);

export const getAllAssignee = createAsyncThunkForSlice(
  GET_ALL_ASSIGNEE,
  getAllAssignees
);

export const getAllLeadCountInfo = createAsyncThunkForSlice(
  GET_ALL_LEAD_COUNT_INFO,
  getAllLeadCountsInfo
);
