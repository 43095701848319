//Add New
export const EMPLOYEES_NAME = 'emp_name'
export const EMP_SALARY = 'emp_salary'
export const GRATUITY = 'emp_gratuity'
export const EMP_LEAVE = 'emp_leave'
export const EMPLOYEES_BENIFT = 'employees_benifit'
export const TOTAL_DEDUCTION = 'total_deduction'
export const NET_PAYABLE = 'net_payable'
export const PAYMENT_MODE = 'payment_mode'
export const PAYMENT_DETAILS = 'payment_details'
export const PAYMENT_STATUS = 'payment_status'
