import { useState, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router'
import Button from '../../../components/common/button/Button'
import { level5TableHeader } from '../../../constants/data'
import Level5Modal from '../../../components/common/modal/level5-modal/Level5Modal'
import Pagination from '../../../components/common/pagination/Pagination'
import { useAppDispatch, useAppSelector } from '../../../hooks'
import {
  getAllLevelData,
  getGenerateCode,
} from '../../../redux/features/view-chat-of-accounts/accountsAsynActions'
import { requestGenerator } from '../../../utils/payloadGenerator'
import { removeLastTwoCharacters } from '../../../utils/utils'
import { CustomModal } from '../../../components/common/custom-modal/modal'
import styles from './Level-5.module.scss'
import { setGenerateCode } from '../../../redux/features/view-chat-of-accounts/accountsSlice'
import TableV2 from '../../../components/common/table/tableV2/TableV2'
import { t } from 'i18next'

const Level5List = () => {
  const navigate = useNavigate()
  const [dataPerPage, setDataPerPage] = useState<number>(10)
  const [totalPage, setTotalPage] = useState<number>(0)
  const [pageIndex, setPageIndex] = useState<number>(1)
  const [showAddModal, setShowAddModal] = useState<boolean>(false)
  const [editAddData, setEditAddData] = useState<any>({})
  const dispatch = useAppDispatch()
  const location = useLocation()
  const [popupData, setPopupdata] = useState<any>({})
  const { getAllData, addAttributeData, backLevelId } = useAppSelector(
    (state) => state.accounts
  )
  const pageIndexArray = () => {
    let pageIndexOptions = []
    for (let i = 1; i <= totalPage; i++) {
      pageIndexOptions.push(i)
    }
    return pageIndexOptions
  }

  const pageIndexOptions = pageIndexArray()

  const generateCode = () => {
    const level5ID = {
      levelId: location?.state?.levelID?.level_Id,
      parentLevelTypeId: location?.state?.levelID?.parent_Level_Type_Id,
    }
    dispatch(getGenerateCode(requestGenerator(level5ID)))
    setShowAddModal((prevState) => !prevState)
    setEditAddData({})
  }

  useEffect(() => {
    const level5ID = {
      level_Id: location?.state?.levelID?.level_Id,
      parent_Level_Type_Id: location?.state?.levelID?.parent_Level_Type_Id,
      page: pageIndex,
      pageSize: dataPerPage,
    }
    dispatch(getAllLevelData(requestGenerator(level5ID))).then((result) =>
      setTotalPage(result.payload.lastPage)
    )
  }, [dispatch, dataPerPage, pageIndex])

  const pathArr = location?.state?.path.split('>')
  const backPath = pathArr?.pop()

  return (
    <>
      {/*  Popup Component */}
      <CustomModal
        showModal={showAddModal}
        closeModal={() => {
          setPopupdata({})
          setShowAddModal(false)
        }}
      >
        <Level5Modal
          popData={editAddData}
          formData={popupData}
          handleSuccess={() => {
            setPopupdata({})
            const level5ID = {
              level_Id: location?.state?.levelID?.level_Id,
              parent_Level_Type_Id:
                location?.state?.levelID?.parent_Level_Type_Id,
              page: pageIndex,
              pageSize: dataPerPage,
            }
            dispatch(getAllLevelData(requestGenerator(level5ID))).then(
              (result) => setTotalPage(result.payload.lastPage)
            )
          }}
          handleClose={() => {
            setPopupdata({})
            setShowAddModal(false)
          }}
        />
      </CustomModal>
      <div className={styles.level5Container}>
        <div className={styles.titleContainer}>
          <h1 className={styles.title}>
            {addAttributeData?.name}
            {' > '}
            {location?.state?.path}
          </h1>
          <Button
            title={t('ActionBar.Add') || 'Add'}
            customClass={styles.addBtn}
            type="button"
            handleClick={generateCode}
          />
        </div>
        <>
          <TableV2
            tableHeaderData={level5TableHeader}
            tableRowData={getAllData?.length > 0 ? getAllData : []}
            handleClick={(data: any) => {
              dispatch(setGenerateCode({ code: data?.code }))
              setShowAddModal((prevState) => !prevState)
              setPopupdata(data)
            }}
          />
        </>
        {getAllData?.length > 0 ? (
          <Pagination
            setDataPerPage={setDataPerPage}
            pageIndexOptions={pageIndexOptions}
            pageIndex={pageIndex}
            setPageIndex={setPageIndex}
            pageSize={dataPerPage}
          />
        ) : (
          ''
        )}
        <div className={styles.btnContainer}>
          <Button
            title={t('Common.Back') || 'Back'}
            type="button"
            handleClick={() => {
              const levelID = {
                level_Id: 4,
                parent_Level_Type_Id: removeLastTwoCharacters(backLevelId),
              }
              navigate('/viewchartofaccounts/level4list', {
                state: {
                  levelID,
                  path: pathArr.join('>'),
                },
              })
            }}
            customClass={styles.backBtn}
          />
        </div>
      </div>
    </>
  )
}
export default Level5List
