// IPD Constants

export const PATIENT_NAME = "patient_name";
export const DEPARTMENT = "department";
export const WARDS = "wards";
export const ROOM = "room";
export const BED = "bed";
export const BOOKING_DATE = "booking_date";
export const ADMITTED_DATE = "admitted_date";
export const DISCHARGE_DATE = "discharge_date";
export const CONSULTED_DOCTOR = "primary_doctor";
