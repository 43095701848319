import { FC } from 'react'
import styles from './assignTagModal.module.scss'
import { CloseIcon } from '../../svg-components'
import { colors } from '../../../../constants/color'
import { warning } from '../../../../constants/data'
import Button from '../../button/Button'
import Divider from '../../divider/Divider'
import { useAppDispatch, useAppSelector } from '../../../../hooks'
import { requestGenerator } from '../../../../utils/payloadGenerator'

import {
  assignTag,
  getAllAssignTag,
} from '../../../../redux/features/patient-emr/patient/patientAsyncAction'
import TableV2 from '../../table/tableV2/TableV2'
import Loader from '../../spinner/Loader'
import ToggleSwitchV2 from '../../toggle-switch/ToggleSwitchV2'
import { setTagData } from '../../../../redux/features/patient-emr/tag/tagSlice'
import { setMessage } from '../../../../redux/features/toast/toastSlice'
import { setAssignTagData } from '../../../../redux/features/patient-emr/patient/patientSlice'
import { useTranslation } from 'react-i18next'

interface IAssignTag {}

const AssignTagModal: FC<IAssignTag> = () => {
  const dispatch = useAppDispatch()
  const { t } = useTranslation()
  const { isLoading, tagData } = useAppSelector((state) => state.tag)
  const { patientDataObjectById, assignTagInfoData } = useAppSelector(
    (state) => state.patient
  )

  let activeTagIds = [] as any

  assignTagInfoData?.forEach((item: any) => {
    if (item.assigned === true && item?._id !== undefined) {
      activeTagIds.push(item?._id)
    }
  })

  const assignTagHeaderData: any = [
    {
      Header: t('AssigTagHeader.LABEL NAME'),
      accessor: 'label_name',
    },
    {
      Header: t('AssigTagHeader.LABEL ICON'),
      accessor: 'label_icon',
      Cell: (props: any) => {
        let imgUrl = props?.row?.original?.label_icon
        return (
          <div style={{ width: '25px', height: '25px', margin: 'auto' }}>
            <img
              src={imgUrl}
              alt="icon_img"
              style={{ width: 'fitContent', height: '100%' }}
            />
          </div>
        )
      },
    },

    {
      Header: t('AssigTagHeader.ACTIVE/INACTIVE'),
      accessor: 'assigned',
      Cell: ({ row }: any) => {
        const is_active = row?.original?.assigned

        const handleToggle = (item: any) => {
          const { _id } = item
          if (patientDataObjectById?._id !== undefined) {
            dispatch(setAssignTagData(_id))
          } else {
            dispatch(setTagData(_id))
          }
        }

        return (
          <ToggleSwitchV2
            isToggled={is_active}
            handleToggle={() => handleToggle(row?.original)}
          />
        )
      },
    },
  ]

  const handleAssignTag = () => {
    if (patientDataObjectById?._id === undefined) {
      let toastData = {
        message:
          t('PatientEMR.Please load patient data') ||
          'Please load patient data',
        type: warning,
      }
      dispatch(setMessage(toastData))
    } else {
      let reqData = {
        patient_id: patientDataObjectById?._id,
        // patient_id: "643cd77436c4ce562a664756",
        // tag_ids: ["64410e111df343703a80bc13", "64410e531df343703a80bc18"],
        tag_ids: activeTagIds,
      }
      dispatch(assignTag(requestGenerator(reqData))).then((e) => {
        if (e.type === 'patient/assignTag/fulfilled') {
          let data = {
            patient_id: patientDataObjectById?._id,
            // patient_id: "643cd77436c4ce562a664756",
          }
          dispatch(getAllAssignTag(requestGenerator(data)))
        }
      })
    }
  }

  return (
    <>
      {isLoading && <Loader />}
      <div className={styles.mainContainer}>
        <div className={styles.closeIconContainer}>
          <CloseIcon
            customClass={styles.closeIconStyle}
            fillColor={colors.green1}
          />
        </div>
        <p className={styles.title}> {t('PatientEMR.Assign Tag')}</p>
        <Divider customClass={styles.dividerStyle} />
        <div
          className={styles.tableContainer}
          onClick={(e) => {
            e.stopPropagation()
          }}
        >
          <TableV2
            tableHeaderData={assignTagHeaderData}
            // tableRowData={tagData}
            tableRowData={
              assignTagInfoData.length > 0 ? assignTagInfoData : tagData
            }
          />
        </div>

        <Button
          title={t('Common.Save') || 'Save'}
          customClass={styles.buttonStyle}
          handleClick={() => handleAssignTag()}
        />
      </div>
    </>
  )
}

export default AssignTagModal
