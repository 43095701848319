import { useAppSelector } from '../../../hooks'
import { t } from 'i18next'

// Apply Leave Table Data
export const applyLeaveTableHeaderData: any = [
  {
    Header: t('LeaveManagement.ANNUAL'),
    Cell: (props: any) => {
      const { allLeaveTable, leaveDataInfo } = useAppSelector(
        (state) => state.leave
      )
      return <>{leaveDataInfo?.leave_data?.days}</>
    },
  },
  {
    Header: t('LeaveManagement.USED'),
    Cell: (props: any) => {
      const { allLeaveTable } = useAppSelector((state) => state.leave)
      return (
        <>
          {allLeaveTable?.length > 0 ? props?.row?.original[0]?.leave_count : 0}
        </>
      )
    },
  },
  {
    Header: t('LeaveManagement.AVAILABLE'),

    Cell: (props: any) => {
      const { allLeaveTable, leaveDataInfo } = useAppSelector(
        (state) => state.leave
      )

      const totalLeave =
        allLeaveTable?.length > 0 ? props?.row?.original[0]?.leave_count : 0
      return <>{leaveDataInfo?.leave_data?.days - totalLeave}</>
    },
  },
  {
    Header: t('LeaveManagement.CURRENT'),

    Cell: () => {
      const { allCalculation } = useAppSelector((state) => state.leave)
      return <>{allCalculation?.workingDays ?? 0}</>
    },
  },
  {
    Header: t('LeaveManagement.BALANCE'),
    Cell: ({ row }: any) => {
      const { allLeaveTable } = useAppSelector((state) => state.leave)
      const { allCalculation, leaveDataInfo } = useAppSelector(
        (state) => state.leave
      )

      const totalLeave =
        allLeaveTable?.length > 0 ? row?.original[0]?.leave_count : 0

      const available = leaveDataInfo?.leave_data?.days - totalLeave

      const current = allCalculation?.workingDays ?? 0

      return <>{available - current}</>
    },
  },
]
