import { t } from 'i18next'
import styles from './tableData.module.scss'
import moment from 'moment'

export const ipdMedicationHeaderData: any = [
  {
    Header: t('RcptTableHeader.DATE'),
    accessor: 'date',
    Cell: ({ row }: any) => {
      return <p>{row?.original?.date ? row?.original?.date : '-'}</p>
    },
  },
  {
    Header: t('PatientHistoryTableHeader.MEDICATION'),
    accessor: 'medication',
    Cell: (props: any) => {
      return (
        <p
          className={styles.blueLinkText}
          onClick={() => {
            props?.onRowClick(
              props?.row?.original?.date,
              props?.row?.original?.medicines
            )
          }}
        >
          {t('Common.View')}
        </p>
      )
    },
  },
  {
    Header: t('ViewAppointmentHeader.DOCTOR'),
    accessor: 'primary_doctor_name',
  },
]

export const pharmacyMedicationHeaderData: any = [
  {
    Header: t('RcptTableHeader.DATE'),
    accessor: 'date',
    Cell: ({ row }: any) => {
      return moment(row?.original.date).format('DD/MM/YYYY') ?? '-'
    },
  },
  {
    Header: t('PatientHistoryTableHeader.MEDICATION'),
    accessor: 'medication',
    Cell: (props: any) => {
      return (
        <>
          {props?.row?.original?.medication?.length > 0 ? (
            <p
              className={styles.blueLinkText}
              onClick={() => {
                props?.onRowClick(
                  props?.row?.original?.date,
                  props?.row?.original?.medication
                )
              }}
            >
              {t('Common.View')}
            </p>
          ) : (
            <p>-</p>
          )}
        </>
      )
    },
  },
  {
    Header: t('ViewAppointmentHeader.DOCTOR'),
    accessor: 'doctor_name',
  },
]

export const ipdMedicationViewHeaderData: any = [
  {
    Header: t('PatientActivityheader.DATE & TIME'),
    accessor: 'date',
    Cell: ({ row }: any) => {
      return (
        <div className={styles.actionIconContainer}>
          <p>{row?.original?.date}, </p>
          <p className={styles.deleteIconStyle}>{row?.original?.time}</p>
        </div>
      )
    },
  },
  {
    Header: t('MedicationHeader.MEDICINE NAME'),
    accessor: 'medicine_name',
  },
  {
    Header: t('MedicationHeader.OTY'),
    accessor: 'quantity',
    Cell: ({ row }: any) => {
      return (
        <p>
          {row?.original?.quantity !== null ? row?.original?.quantity : '-'}
        </p>
      )
    },
  },
  {
    Header: t('MedicationHeader.DURATION'),
    accessor: 'duration',
    Cell: ({ row }: any) => {
      return (
        <p>
          {row?.original?.duration?.length > 0 ? row?.original?.duration : '-'}
        </p>
      )
    },
  },
  {
    Header: t('MedicationHeader.FREQUENCY'),
    accessor: 'frequency',
    Cell: ({ row }: any) => {
      return (
        <p>
          {row?.original?.frequency?.length > 0
            ? row?.original?.frequency
            : '-'}
        </p>
      )
    },
  },
]

export const pharmacyMedicationViewHeaderData: any = [
  {
    Header: t('MedicationHeader.MEDICINE NAME'),
    accessor: 'medicine_name',
  },
  {
    Header: t('MedicationHeader.OTY'),
    accessor: 'qty',
    Cell: ({ row }: any) => {
      return <p>{row?.original?.qty !== null ? row?.original?.qty : '-'}</p>
    },
  },
  {
    Header: t('MedicationHeader.DURATION'),
    accessor: 'duration',
    Cell: ({ row }: any) => {
      return (
        <p>
          {row?.original?.duration?.length > 0 ? row?.original?.duration : '-'}
        </p>
      )
    },
  },
  {
    Header: t('MedicationHeader.FREQUENCY'),
    accessor: 'frequency',
    Cell: ({ row }: any) => {
      return (
        <p>
          {row?.original?.frequency?.length > 0
            ? row?.original?.frequency
            : '-'}
        </p>
      )
    },
  },
]
