import { ROLE_TYPE, USERGROUP_NAME } from '../constants/constant'
import { t } from 'i18next'

export interface IDepartmentValidators {
  [USERGROUP_NAME]: {
    required: string
  }
  [ROLE_TYPE]: {
    required: string
  }
}

export const manageUserGroupValidators: IDepartmentValidators = {
  [USERGROUP_NAME]: {
    required: t('ManageUserRoles.Role_Name_validation'),
  },
  [ROLE_TYPE]: {
    required: t('ManageUserRoles.Role_Type_placeholder'),
  },
}
