import { getMessaging, getToken } from 'firebase/messaging'
const { REACT_APP_VAPID_KEY } = process.env
const publicKey = REACT_APP_VAPID_KEY
export const createFirebaseToken = async () => {
  try {
    const messaging = getMessaging()
    const currentToken = await getToken(messaging, {
      vapidKey: publicKey,
    })
    if (currentToken) {
      return currentToken
    } else {
      // Show permission request UI
      console.log(
        'No registration token available. Request permission to generate one.'
      )
      Notification.requestPermission().then(function (result) {
        console.log(result)
      })
    }
  } catch (err) {
    console.log('An error occurred while retrieving token. ', err)
  }
}
