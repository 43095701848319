import jsPDF from 'jspdf'
import styles from './voucherLogModal.module.scss'
import moment from 'moment'
import { Pdf } from '../../../components/common/svg-components'
import { useAppSelector } from '../../../hooks'
import { NumberToWord } from '../../../utils/utils'
import { t } from 'i18next'

export const voucherLogTableHeaderData: any = [
  {
    Header: t('RcptTableHeader.DATE'),
    Cell: ({ row }: any) => {
      let createdDate = moment(row?.original?.createdAt)?.format('DD MMM YYYY')
      const createTime = moment(row?.original?.createdAt).format('HH:mm')
      return (
        <p>
          {' '}
          {createdDate ?? '-'} | {createTime ?? '-'}{' '}
        </p>
      )
    },
  },

  {
    Header: t('Voucher.VOUCHER_NO'),
    Cell: (props: any) => {
      return (
        <span
          className={styles.voucherNoStytle}
          onClick={() => {
            if (props?.row?.original?.number) {
              props.onRowClick(props?.row?.original?.number)
            }
          }}
        >
          {props?.row?.original?.number ?? '-'}
        </span>
      )
    },
  },
  {
    Header: t('Voucher.VOUCHER_TYPE'),
    accessor: (row: any) => {
      return row?.type ? row?.type : '-'
    },
  },

  {
    Header: t('Voucher.VOUCHER_REMARKS'),
    accessor: (row: any) => {
      return row?.remarks ? row?.remarks : '-'
    },
  },

  {
    Header: t('Voucher.REFERENCE_DOC'),
    accessor: (row: any) => {
      return row?.reference_doc_category ? row?.reference_doc_category : '-'
    },
  },
  {
    Header: t('Voucher.REFERENCE_NO'),
    accessor: (row: any) => {
      return row?.reference_number ?? '-'
    },
  },
  {
    Header: t('Voucher.ACCOUNT_TYPE'),
    accessor: (row: any) => {
      return row?.entries?.[0]?.accounts_data?.name ?? '-'
    },
  },
  {
    Header: t('RcptTableHeader.STATUS'),
    Cell: ({ row }: any) => {
      return (
        <>
          {row?.original?.status ? (
            <span
              className={
                row?.original?.status === 'UPDATED'
                  ? styles.activityStatusYellow
                  : row?.original?.status === 'NON-POSTED'
                  ? styles.activityStatusRed
                  : row?.original?.status === 'POSTED'
                  ? styles.activityStatusGreen
                  : ''
              }
            >
              {row?.original?.status ?? ''}
            </span>
          ) : (
            '-'
          )}
        </>
      )
    },
  },

  {
    Header: t('DocUploadHeader.ACTION'),
    Cell: (props: any) => {
      const { userData } = useAppSelector((state) => state.login)
      const mainStoreRequestEditPopupOpen = async () => {
        const doc: any = new jsPDF('p', 'pt', 'a4')
        const width = doc.internal.pageSize.getWidth()
        let tableData: any = []
        let creditTotal = 0
        let debitTotal = 0

        props.row?.original?.entries?.forEach((items: any, index: number) => {
          let costCenterValue = ''
          creditTotal = creditTotal + items?.credit_amount
          debitTotal = debitTotal + items?.debit_amount

          items.cost_center?.forEach((testItem: any, index: any) => {
            testItem.values?.forEach((testChildItem: any, index: any) => {
              costCenterValue += testChildItem?.label_values + ' '
            })
          })

          tableData.push([
            items.accounts_data.code_id,
            items.accounts_data.name,
            costCenterValue,
            props.row?.original.remarks,
            items.debit_amount,
            items.credit_amount,
          ])
        })

        tableData.push(['', '', '', 'Total', debitTotal, creditTotal])

        let offsetY = 4.797777777777778
        let lineHeight = 6.49111111111111
        doc
          .text(`${userData?.mc_name}`, width / 2, 80, {
            align: 'center',
          })
          .setFontSize(10)

          .setFont(undefined, 'bold')
        doc
          .text(`Voucher no:  ${props.row.original.number}`, 60, 120)
          .setFontSize(8)
          .setFont(undefined, 'bold')
        doc
          .text(
            `Date ${moment(props?.row?.original?.date)?.format('DD/MM/YY')}`,
            400,
            120
          )
          .setFontSize(8)
          .setFont(undefined, 'bold')

        doc.autoTable({
          startY: 155,
          styles: {
            halign: 'center',
            textColor: '#000',
            fillColor: '#fefefe',
          },
          tableLineColor: '#000',
          tableLineWidth: 0.1,

          headStyles: { lineWidth: 0.1, lineColor: '#000' },
          bodyStyles: {
            fontSize: '8',
            lineWidth: 0.1,
            lineColor: '#000',
          },

          head: [
            ['A/c Code.', 'A/c. Head', 'Cost Center', 'Remarks', 'Dr.', 'Cr.'],
          ],
          body: tableData,
        })
        const dynamicHeight = doc?.autoTable?.previous?.finalY

        const getNumberIntoWords = await NumberToWord(debitTotal)

        doc
          .text(
            `Amount In Words : ${getNumberIntoWords}`,
            40,
            dynamicHeight + lineHeight * 5 + offsetY
          )
          .setFontSize(8)
          .setFont(undefined, 'bold')

        doc.save('request.pdf')
        window.open(doc.output('bloburl'), '_blank')
      }

      return (
        <Pdf
          handleClick={() => {
            if (props?.row?.original?.status === 'POSTED') {
              mainStoreRequestEditPopupOpen()
            }
          }}
        />
      )
    },
  },
]
