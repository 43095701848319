import moment from 'moment'
import { t } from 'i18next'

export const assignAgentTableHeader: any = [
  {
    Header: t('CallCenterAdmin.LEAD_NAME'),
    accessor: (row: any) => {
      return <>{row?.name ? row?.name : '-'}</>
    },
  },
  {
    Header: t('LeadPool.SOURCE'),
    Cell: ({ row }: any) => {
      const source = row?.original?.external_source
      return <>{source ? source : '-'}</>
    },
  },
  {
    Header: t('Campaigns.TAG'),
    Cell: ({ row }: any) => {
      const tags = row?.original?.tags
      return (
        <>
          {tags && tags?.length > 0
            ? tags?.map((item: string, index: number) => {
                return item ? (
                  <div key={item}>
                    {index + 1}. {item}
                  </div>
                ) : (
                  ''
                )
              })
            : '-'}
        </>
      )
    },
  },
  {
    Header: t('CallCenterAgent.START_DATE'),
    Cell: ({ row }: any) => {
      const originalDate = row?.original?.start_date
      const formattedDate = moment(originalDate).format('DD MMM YYYY')
      return <>{formattedDate}</>
    },
  },
  {
    Header: t('CallCenterAgent.END_DATE'),
    Cell: ({ row }: any) => {
      const originalDate = row?.original?.end_date
      const formattedDate = moment(originalDate).format('DD MMM YYYY')
      return <>{formattedDate}</>
    },
  },
  {
    Header: t('CreateIPDFrom.Contact No.'),
    accessor: 'phone',
    Cell: ({ row }: any) => {
      const phone = row?.original?.phone
      return phone ? phone : '-'
    },
  },
]
