// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.appointmentLogPopup_popupContainer__zbX3f {
  width: 520px;
  position: absolute;
  margin: auto;
  background: var(--white1);
  border-radius: 10px;
  padding: 10px;
}
.appointmentLogPopup_popupContainer__zbX3f .appointmentLogPopup_closeIconStyle__U2fTN {
  cursor: pointer;
  float: right;
}
.appointmentLogPopup_popupContainer__zbX3f .appointmentLogPopup_viewAppointment__gIcT0 {
  margin-top: 50px;
}
.appointmentLogPopup_popupContainer__zbX3f .appointmentLogPopup_viewAppointment__gIcT0 .appointmentLogPopup_title__5gm6k {
  text-align: center;
  font-weight: var(--font-semibold);
  font-size: var(--font-24);
  line-height: 36px;
  color: var(--black2);
}
.appointmentLogPopup_popupContainer__zbX3f .appointmentLogPopup_viewAppointment__gIcT0 .appointmentLogPopup_dividerStyle__BGLb8 {
  margin: 10px auto 30px auto;
  width: 80px;
  height: 3px;
  background: var(--green1);
}
.appointmentLogPopup_popupContainer__zbX3f .appointmentLogPopup_viewAppointment__gIcT0 .appointmentLogPopup_tableBlock__tbjUE {
  height: 100%;
  max-height: 350px;
  overflow: scroll;
  width: 100%;
  white-space: nowrap;
}
.appointmentLogPopup_popupContainer__zbX3f .appointmentLogPopup_viewAppointment__gIcT0 .appointmentLogPopup_tableBlock__tbjUE::-webkit-scrollbar {
  display: block;
  width: 7px;
  height: 7px;
}
.appointmentLogPopup_popupContainer__zbX3f .appointmentLogPopup_viewAppointment__gIcT0 .appointmentLogPopup_tableBlock__tbjUE::-webkit-scrollbar-track {
  background: transparent;
}
.appointmentLogPopup_popupContainer__zbX3f .appointmentLogPopup_viewAppointment__gIcT0 .appointmentLogPopup_tableBlock__tbjUE::-webkit-scrollbar-thumb {
  background-color: var(--grey7);
  border-radius: 10px;
  border: 1px solid var(--grey7);
}`, "",{"version":3,"sources":["webpack://./src/components/common/modal/view-appointment-popup/appointment-log-popup/appointmentLogPopup.module.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;EAEA,kBAAA;EAGA,YAAA;EACA,yBAAA;EACA,mBAAA;EACA,aAAA;AAFF;AAGE;EACE,eAAA;EACA,YAAA;AADJ;AAGE;EACE,gBAAA;AADJ;AAEI;EACE,kBAAA;EACA,iCAAA;EACA,yBAAA;EACA,iBAAA;EACA,oBAAA;AAAN;AAEI;EACE,2BAAA;EACA,WAAA;EACA,WAAA;EACA,yBAAA;AAAN;AAEI;EACE,YAAA;EACA,iBAAA;EACA,gBAAA;EACA,WAAA;EACA,mBAAA;AAAN;AACM;EACE,cAAA;EACA,UAAA;EACA,WAAA;AACR;AACM;EACE,uBAAA;AACR;AAEM;EACE,8BAAA;EACA,mBAAA;EACA,8BAAA;AAAR","sourcesContent":[".popupContainer {\n  width: 520px;\n  //   height: 0px;\n  position: absolute;\n  // top: 90px;\n  // margin-top: 15%;\n  margin: auto;\n  background: var(--white1);\n  border-radius: 10px;\n  padding: 10px;\n  .closeIconStyle {\n    cursor: pointer;\n    float: right;\n  }\n  .viewAppointment {\n    margin-top: 50px;\n    .title {\n      text-align: center;\n      font-weight: var(--font-semibold);\n      font-size: var(--font-24);\n      line-height: 36px;\n      color: var(--black2);\n    }\n    .dividerStyle {\n      margin: 10px auto 30px auto;\n      width: 80px;\n      height: 3px;\n      background: var(--green1);\n    }\n    .tableBlock {\n      height: 100%;\n      max-height: 350px;\n      overflow: scroll;\n      width: 100%;\n      white-space: nowrap;\n      &::-webkit-scrollbar {\n        display: block;\n        width: 7px;\n        height: 7px;\n      }\n      &::-webkit-scrollbar-track {\n        background: transparent;\n      }\n\n      &::-webkit-scrollbar-thumb {\n        background-color: var(--grey7);\n        border-radius: 10px;\n        border: 1px solid var(--grey7);\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"popupContainer": `appointmentLogPopup_popupContainer__zbX3f`,
	"closeIconStyle": `appointmentLogPopup_closeIconStyle__U2fTN`,
	"viewAppointment": `appointmentLogPopup_viewAppointment__gIcT0`,
	"title": `appointmentLogPopup_title__5gm6k`,
	"dividerStyle": `appointmentLogPopup_dividerStyle__BGLb8`,
	"tableBlock": `appointmentLogPopup_tableBlock__tbjUE`
};
export default ___CSS_LOADER_EXPORT___;
