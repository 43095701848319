// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.paymentsMode_mainContainer__hwtNr {
  background-color: var(--white1);
  box-shadow: 0px 10px 117px 14px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  padding: 25px;
  margin: 0 20px 50px 0;
}
.paymentsMode_mainContainer__hwtNr .paymentsMode_appointmentStatusInputMain__yCO8- {
  display: flex;
}
.paymentsMode_mainContainer__hwtNr .paymentsMode_appointmentStatusInputMain__yCO8- .paymentsMode_appointmanetInput__uFW6e .paymentsMode_appointmentLable__Fsddp {
  font-weight: 600;
  font-size: 16px;
}
.paymentsMode_mainContainer__hwtNr .paymentsMode_appointmentStatusInputMain__yCO8- .paymentsMode_appointmanetInput__uFW6e .paymentsMode_inputSearchContainer__OE3D7 {
  margin-left: 12px;
  width: 234px;
  height: 42px;
  border: none;
  padding: 15px;
  border-radius: 10px;
  background: rgba(255, 255, 255, 0.7);
  box-shadow: 0px 10px 117px 14px rgba(0, 0, 0, 0.05);
  border-radius: 6px;
  border: 1px solid #cdd4d8;
}
.paymentsMode_mainContainer__hwtNr .paymentsMode_appointmentStatusInputMain__yCO8- .paymentsMode_appointmanetInput__uFW6e .paymentsMode_inputAttechments__KKUK7 {
  display: flex;
  margin-left: 20px;
  width: 160px;
  align-items: center;
}
.paymentsMode_mainContainer__hwtNr .paymentsMode_appointmentStatusInputMain__yCO8- .paymentsMode_appointmanetInput__uFW6e .paymentsMode_appointmentLables__oh518 {
  font-weight: 600;
  font-size: 16px;
  margin-right: 10px;
}
.paymentsMode_mainContainer__hwtNr .paymentsMode_buttonMainColor__7\\+Nq1 {
  display: flex;
  width: 20%;
  justify-content: center;
  margin-top: 30px;
  margin-bottom: 30px;
}
.paymentsMode_mainContainer__hwtNr .paymentsMode_buttonMainColor__7\\+Nq1 .paymentsMode_addBUtton__6\\+wiO {
  width: 90px;
  height: 42px;
}

.paymentsMode_errorContainer__Pv12m {
  padding-left: 64px;
}

.paymentsMode_errorContainers__ZfZjT {
  padding-left: 22px;
}`, "",{"version":3,"sources":["webpack://./src/pages/manageMasterTable-New/managePaymentmodes/paymentsMode.module.scss"],"names":[],"mappings":"AACA;EACE,+BAAA;EACA,mDAAA;EACA,mBAAA;EACA,aAAA;EACA,qBAAA;AAAF;AACE;EACE,aAAA;AACJ;AACM;EACE,gBAAA;EACA,eAAA;AACR;AACM;EACE,iBAAA;EACA,YAAA;EACA,YAAA;EACA,YAAA;EACA,aAAA;EACA,mBAAA;EACA,oCAAA;EACA,mDAAA;EACA,kBAAA;EACA,yBAAA;AACR;AACM;EACE,aAAA;EACA,iBAAA;EACA,YAAA;EACA,mBAAA;AACR;AACM;EACE,gBAAA;EACA,eAAA;EACA,kBAAA;AACR;AAGE;EACE,aAAA;EACA,UAAA;EACA,uBAAA;EACA,gBAAA;EACA,mBAAA;AADJ;AAEI;EACE,WAAA;EACA,YAAA;AAAN;;AAIA;EACE,kBAAA;AADF;;AAGA;EACE,kBAAA;AAAF","sourcesContent":["@import '../../../styles/mixin.scss';\n.mainContainer {\n  background-color: var(--white1);\n  box-shadow: 0px 10px 117px 14px rgba(0, 0, 0, 0.05);\n  border-radius: 10px;\n  padding: 25px;\n  margin: 0 20px 50px 0;\n  .appointmentStatusInputMain {\n    display: flex;\n    .appointmanetInput {\n      .appointmentLable {\n        font-weight: 600;\n        font-size: 16px;\n      }\n      .inputSearchContainer {\n        margin-left: 12px;\n        width: 234px;\n        height: 42px;\n        border: none;\n        padding: 15px;\n        border-radius: 10px;\n        background: rgba(255, 255, 255, 0.7);\n        box-shadow: 0px 10px 117px 14px rgba(0, 0, 0, 0.05);\n        border-radius: 6px;\n        border: 1px solid #cdd4d8;\n      }\n      .inputAttechments {\n        display: flex;\n        margin-left: 20px;\n        width: 160px;\n        align-items: center;\n      }\n      .appointmentLables {\n        font-weight: 600;\n        font-size: 16px;\n        margin-right: 10px;\n      }\n    }\n  }\n  .buttonMainColor {\n    display: flex;\n    width: 20%;\n    justify-content: center;\n    margin-top: 30px;\n    margin-bottom: 30px;\n    .addBUtton {\n      width: 90px;\n      height: 42px;\n    }\n  }\n}\n.errorContainer {\n  padding-left: 64px;\n}\n.errorContainers {\n  padding-left: 22px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"mainContainer": `paymentsMode_mainContainer__hwtNr`,
	"appointmentStatusInputMain": `paymentsMode_appointmentStatusInputMain__yCO8-`,
	"appointmanetInput": `paymentsMode_appointmanetInput__uFW6e`,
	"appointmentLable": `paymentsMode_appointmentLable__Fsddp`,
	"inputSearchContainer": `paymentsMode_inputSearchContainer__OE3D7`,
	"inputAttechments": `paymentsMode_inputAttechments__KKUK7`,
	"appointmentLables": `paymentsMode_appointmentLables__oh518`,
	"buttonMainColor": `paymentsMode_buttonMainColor__7+Nq1`,
	"addBUtton": `paymentsMode_addBUtton__6+wiO`,
	"errorContainer": `paymentsMode_errorContainer__Pv12m`,
	"errorContainers": `paymentsMode_errorContainers__ZfZjT`
};
export default ___CSS_LOADER_EXPORT___;
