import {
  LAB_INVOICE_PATIENT_BRANCH_NAME,
  LAB_INVOICE_PATIENT_TYPE,
  LAB_INVOICE_PATIENT_NAME,
  LAB_INVOICE_PATIENT_PRIMARY_DOCTOR,
  LAB_INVOICE_PATIENT_MOBILE_NO,
} from '../constants/constant'
import { t } from 'i18next'

export interface IlabInvoiceValidators {
  [LAB_INVOICE_PATIENT_BRANCH_NAME]: {
    required: string
  }
  [LAB_INVOICE_PATIENT_TYPE]: {
    required: string
  }
  [LAB_INVOICE_PATIENT_NAME]: {
    required: string
  }
  [LAB_INVOICE_PATIENT_PRIMARY_DOCTOR]: {
    required: string
  }
  [LAB_INVOICE_PATIENT_MOBILE_NO]: {
    required: string
  }
}
export const labInvoiceValidators: IlabInvoiceValidators = {
  [LAB_INVOICE_PATIENT_BRANCH_NAME]: {
    required: t('InvoiceInfo.BranchVal'),
  },
  [LAB_INVOICE_PATIENT_TYPE]: {
    required: t('InvoiceInfo.PatientType'),
  },
  [LAB_INVOICE_PATIENT_NAME]: {
    required: t('IPDBooking.PleaseSelectPatient'),
  },
  [LAB_INVOICE_PATIENT_PRIMARY_DOCTOR]: {
    required: t('InvoiceInfo.PrimaryDoctor'),
  },
  [LAB_INVOICE_PATIENT_MOBILE_NO]: {
    required: t('PatientEmrValidators.MOB1'),
  },
}
