import { t } from 'i18next'
import moment from 'moment'

export const primaryInformationHeaderData: any = [
  {
    Header: t('RcptTableHeader.DATE'),
    accessor: (row: any) => {
      return moment(row?.createdAt).format('DD-MMM-YYYY') ?? '-'
    },
  },
  {
    Header: t('MainStore.PO SUPPLIER'),
    Cell: (props: any) => {
      return <>{props.row.original?.name}</>
    },
  },
]
