// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.directpopopup_tablestyle__YhH90 {
  max-height: 230px;
  background-color: var(--white1);
  overflow: auto;
  scroll-behavior: smooth;
}
.directpopopup_tablestyle__YhH90::-webkit-scrollbar {
  display: block !important;
  width: 7px !important;
  height: 7px !important;
}
.directpopopup_tablestyle__YhH90::-webkit-scrollbar-track {
  background: transparent !important;
}
.directpopopup_tablestyle__YhH90::-webkit-scrollbar-thumb {
  background-color: var(--grey7) !important;
  border-radius: 10px !important;
  border: 1px solid var(--grey7) !important;
}
.directpopopup_tablestyle__YhH90 tr th {
  min-width: 130px;
}
.directpopopup_tablestyle__YhH90 td:nth-last-child(8) {
  color: var(--blue1);
}

.directpopopup_btn__Cps\\+p {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
}`, "",{"version":3,"sources":["webpack://./src/pages/mainstore/direct-po-popup/directpopopup.module.scss"],"names":[],"mappings":"AAEA;EACE,iBAAA;EACA,+BAAA;EACA,cAAA;EACA,uBAAA;AADF;AAEE;EACE,yBAAA;EACA,qBAAA;EACA,sBAAA;AAAJ;AAEE;EACE,kCAAA;AAAJ;AAGE;EACE,yCAAA;EACA,8BAAA;EACA,yCAAA;AADJ;AAKI;EACE,gBAAA;AAHN;AAOE;EACE,mBAAA;AALJ;;AAQA;EACE,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,gBAAA;AALF","sourcesContent":["@import '../../../styles/mixin.scss';\n\n.tablestyle {\n  max-height: 230px;\n  background-color: var(--white1);\n  overflow: auto;\n  scroll-behavior: smooth;\n  &::-webkit-scrollbar {\n    display: block !important;\n    width: 7px !important;\n    height: 7px !important;\n  }\n  &::-webkit-scrollbar-track {\n    background: transparent !important;\n  }\n\n  &::-webkit-scrollbar-thumb {\n    background-color: var(--grey7) !important;\n    border-radius: 10px !important;\n    border: 1px solid var(--grey7) !important;\n  }\n\n  tr {\n    th {\n      min-width: 130px;\n    }\n  }\n\n  td:nth-last-child(8) {\n    color: var(--blue1);\n  }\n}\n.btn {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  margin-top: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tablestyle": `directpopopup_tablestyle__YhH90`,
	"btn": `directpopopup_btn__Cps+p`
};
export default ___CSS_LOADER_EXPORT___;
