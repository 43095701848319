import Button from '../../components/common/button/Button'
import { useAppDispatch, useAppSelector } from '../../hooks'
import styles from './labNewServices.module.scss'
import {
  handleAddedService,
  handleRemoveAddedService,
  setTestAddText,
} from '../../redux/features/lab-invoice/labInvoiceSlice'
import { t } from 'i18next'

export const labInvoiceNewServiceHeaderData: any = [
  {
    Header: t('InvoiceService.SERVICE'),
    accessor: 'name',
  },
  {
    Header: t('InvoiceService.DESCRIPTION'),
    Cell: (props: any) => {
      return (
        <>
          {props?.row?.original?.description == null ? (
            '-'
          ) : (
            <span
              className={styles.viewPopupLink}
              onClick={() => {
                props?.onClick(props?.row?.original)
              }}
            >
              {t('Common.View')}
            </span>
          )}
        </>
      )
    },
  },

  {
    Header: t('InvoiceService.AMOUNT'),
    accessor: 'sell_price',
  },

  {
    Header: t('InvoiceService.ADD TO INVOICE'),
    Cell: (props: any) => {
      const { labServicesList, addTestText } = useAppSelector(
        (state) => state.labInvoice
      )
      const dispatch = useAppDispatch()

      const handleTestData = () => {
        const checkCommonIds = labServicesList?.find(
          (s: any) => s._id === props.row.original._id
        )
        let profileTestData = {
          quantity: 1,
          unitPrice: props.row.original.sell_price,
          test_name: props.row.original.name,
          billable: 'Yes',
          ...checkCommonIds,
        }
        dispatch(handleAddedService(profileTestData))
      }

      const handleRemove = () => {
        dispatch(handleRemoveAddedService(props?.row?.original?._id))
      }

      return (
        <>
          <div className={styles.addTestPopupAddToJob}>
            {!addTestText.includes(props.row.original._id) ? (
              <Button
                title={t('InvoiceService.Add') || 'Add'}
                customClass={styles.addedServiceButton}
                handleClick={() => {
                  dispatch(setTestAddText(props.row.original._id))
                  handleTestData()
                }}
              />
            ) : (
              <Button
                title={t('InvoiceService.Added') || 'Added'}
                customClass={styles.addServiceButton}
                handleClick={() => {
                  dispatch(setTestAddText(props.row.original._id))
                  handleRemove()
                }}
              />
            )}
          </div>
        </>
      )
    },
  },
]
