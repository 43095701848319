import {
  APPOINTMENT_TAGS,
  PAYMENT_REMARKS,
  REFER_TO_DOCTOR,
  REFER_TO_RECEPTIONIST,
  REFFERAL_START_DATE,
  REMARKS,
} from "../constants/constant";
import { t } from "i18next";

export interface IreferralValidators {
  [REFER_TO_DOCTOR]: {
    required: string;
  };
  [APPOINTMENT_TAGS]: {
    required: string;
  };
  [REMARKS]: {
    required: string;
  };
  [PAYMENT_REMARKS]: {
    required: string;
  };
  [REFFERAL_START_DATE]: {
    required: any;
  };
  [REFER_TO_RECEPTIONIST]: {
    required: any;
  };
}

export const referralValidators: IreferralValidators = {
  [REFER_TO_DOCTOR]: {
    required: t("DentistReferral.ReferToDoctor"),
  },
  [APPOINTMENT_TAGS]: {
    required: t("DentistReferral.Appointment_Tags_Validation"),
  },
  [REMARKS]: {
    required: t("DentistReferral.Remarks_Validation"),
  },
  [PAYMENT_REMARKS]: {
    required: t("DentistReferral.Payment_Remarks_Validation"),
  },
  [REFFERAL_START_DATE]: {
    required: t("BookingConfirmationValidators.BOOKING_DATE"),
  },
  [REFER_TO_RECEPTIONIST]: {
    required:t("DentistReferral.Refer_To_Receptionist"),
  },
};
