import {
  CC_MAIL,
  EMAIL_BODY,
  FORM_DATA,
  PATIENT_NAME_SQ,
  PATIENT_NUMBER,
  SUBJECT,
} from './../constants/constant'
import { TO_MAIL } from '../constants/constant'
import { t } from 'i18next'

export const shareQuestionEmailValidators = {
  [TO_MAIL]: {
    required: t('ShareQuestionnaireValidators.SenderEmail'),
    pattern: {
      value: /^([a-zA-Z0-9_.-])+@(([a-zA-Z0-9-])+.)+([a-zA-Z0-9]{2,4})+$/,
      message: t('ShareQuestionnaireValidators.SenderEmailRegex'),
    },
  },
  [CC_MAIL]: {},
  [SUBJECT]: {
    required: t('ShareQuestionnaireValidators.Subject'),
  },
  [EMAIL_BODY]: {
    required: t('ShareQuestionnaireValidators.Body'),
  },
  [PATIENT_NAME_SQ]: {
    required: t('ShareQuestionnaireValidators.PNAME'),
  },
  [PATIENT_NUMBER]: {
    required: t('ShareQuestionnaireValidators.PNumber'),
  },
  [FORM_DATA]: {
    required: t('ShareQuestionnaireValidators.Form'),
  },
}
