// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_inputFieldContainer__p9IIs {
  display: flex;
  flex-direction: row;
  margin: 40px 0 0px 0;
  align-items: center;
}
.styles_inputFieldContainer__p9IIs .styles_label__9ehVp {
  color: var(--black2);
  font-weight: var(--font-semibold);
  font-size: var(--font-16);
  width: 78px;
}
.styles_inputFieldContainer__p9IIs .styles_fieldErrorContainer__xsy-f .styles_inputField__WABah {
  background: var(--white1);
  width: 245px;
  border: 1px solid #cdd4d8;
  border-radius: 6px;
  padding: 7px 10px;
  margin-right: 10px;
}
.styles_inputFieldContainer__p9IIs .styles_fieldErrorContainer__xsy-f .styles_select__UIozO {
  width: 245px;
  margin-right: 10px;
}

.styles_tableContainer__2iHCd {
  height: 40dvh;
  overflow: auto;
  margin-top: 40px;
}
.styles_tableContainer__2iHCd::-webkit-scrollbar {
  display: block !important;
  width: 7px !important;
  height: 7px !important;
}
.styles_tableContainer__2iHCd::-webkit-scrollbar-track {
  background: transparent !important;
}
.styles_tableContainer__2iHCd::-webkit-scrollbar-thumb {
  background-color: var(--grey7) !important;
  border-radius: 10px !important;
  border: 1px solid var(--grey7) !important;
}

.styles_mt_0__BYhQd {
  margin: 0;
}`, "",{"version":3,"sources":["webpack://./src/pages/mainstore/view-inventory/styles.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,mBAAA;EACA,oBAAA;EACA,mBAAA;AACF;AACE;EACE,oBAAA;EACA,iCAAA;EACA,yBAAA;EACA,WAAA;AACJ;AAGI;EACE,yBAAA;EACA,YAAA;EACA,yBAAA;EACA,kBAAA;EACA,iBAAA;EACA,kBAAA;AADN;AAII;EACE,YAAA;EACA,kBAAA;AAFN;;AAOA;EACE,aAAA;EACA,cAAA;EACA,gBAAA;AAJF;AAKE;EACE,yBAAA;EACA,qBAAA;EACA,sBAAA;AAHJ;AAKE;EACE,kCAAA;AAHJ;AAME;EACE,yCAAA;EACA,8BAAA;EACA,yCAAA;AAJJ;;AAQA;EACE,SAAA;AALF","sourcesContent":[".inputFieldContainer {\n  display: flex;\n  flex-direction: row;\n  margin: 40px 0 0px 0;\n  align-items: center;\n\n  .label {\n    color: var(--black2);\n    font-weight: var(--font-semibold);\n    font-size: var(--font-16);\n    width: 78px;\n  }\n\n  .fieldErrorContainer {\n    .inputField {\n      background: var(--white1);\n      width: 245px;\n      border: 1px solid #cdd4d8;\n      border-radius: 6px;\n      padding: 7px 10px;\n      margin-right: 10px;\n    }\n\n    .select {\n      width: 245px;\n      margin-right: 10px;\n    }\n  }\n}\n\n.tableContainer {\n  height: 40dvh;\n  overflow: auto;\n  margin-top: 40px;\n  &::-webkit-scrollbar {\n    display: block !important;\n    width: 7px !important;\n    height: 7px !important;\n  }\n  &::-webkit-scrollbar-track {\n    background: transparent !important;\n  }\n\n  &::-webkit-scrollbar-thumb {\n    background-color: var(--grey7) !important;\n    border-radius: 10px !important;\n    border: 1px solid var(--grey7) !important;\n  }\n}\n\n.mt_0 {\n  margin: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"inputFieldContainer": `styles_inputFieldContainer__p9IIs`,
	"label": `styles_label__9ehVp`,
	"fieldErrorContainer": `styles_fieldErrorContainer__xsy-f`,
	"inputField": `styles_inputField__WABah`,
	"select": `styles_select__UIozO`,
	"tableContainer": `styles_tableContainer__2iHCd`,
	"mt_0": `styles_mt_0__BYhQd`
};
export default ___CSS_LOADER_EXPORT___;
