import { createSlice } from '@reduxjs/toolkit'
import {
  addDocument,
  getAllDocument,
  getDocumentById,
  updateDocumentStatus,
  deleteDocument,
  getAllAttachs,
  getAllSearchUserList,
} from './documentAsyncActions'
export interface IDocument {
  isLoading: boolean
  allDocumentData: {}
  documentDetail: {}
  addDocumentData: []
  editDocumentData: {}
  error: any
  isStatusUpdated: boolean
  getAllDocumentPaylod: {}
  getAllAttachsPaylod: []
  allAttchsData: []
  employeeData: []
}
export const initialState: IDocument = {
  isLoading: false,
  allDocumentData: [],
  allAttchsData: [],
  documentDetail: {},
  addDocumentData: [],
  editDocumentData: {},
  isStatusUpdated: true,
  error: null,
  getAllDocumentPaylod: {},
  getAllAttachsPaylod: [],
  employeeData: [],
}

export const documentSlice = createSlice({
  name: 'document',
  initialState,
  reducers: {
    clearDocumentData: (state) => {
      state.allDocumentData = []
      state.documentDetail = {}
      state.editDocumentData = {}
      state.getAllDocumentPaylod = {}
      state.getAllAttachsPaylod = []
      state.allAttchsData = []
    },
    getEditDocumentData: (state, action) => {
      state.editDocumentData = action.payload
    },
    clearEditDocumentData: (state) => {
      state.editDocumentData = {}
    },
    clearAttach: (state) => {
      //state.allAttchsData = [];
      state.allAttchsData = []
    },
    getAllDocumentPayloadData: (state, action) => {
      state.getAllDocumentPaylod = action.payload
    },
    getAllAttachsPayloadData: (state, action) => {
      state.getAllAttachsPaylod = action.payload
    },
  },
  extraReducers(builder) {
    // GET ALL Document
    builder
      .addCase(getAllDocument.pending, (state) => {
        state.isLoading = true
        state.isStatusUpdated = false
      })
      .addCase(getAllDocument.fulfilled, (state, action) => {
        state.isLoading = false
        state.isStatusUpdated = false
        state.allDocumentData = action.payload?.data
      })
      .addCase(getAllDocument.rejected, (state, error) => {
        state.isLoading = false
        state.error = error
        state.isStatusUpdated = false
      })

    //ATTACH
    builder
      .addCase(getAllAttachs.pending, (state) => {
        state.isLoading = true
        state.isStatusUpdated = false
      })
      .addCase(getAllAttachs.fulfilled, (state, action) => {
        state.isLoading = false
        state.isStatusUpdated = false
        state.allAttchsData = action.payload
      })
      .addCase(getAllAttachs.rejected, (state, error) => {
        state.isLoading = false
        state.error = error
        state.isStatusUpdated = false
      })
    // ADD
    builder
      .addCase(addDocument.pending, (state) => {
        state.isLoading = true
      })
      .addCase(addDocument.fulfilled, (state, action) => {
        state.isLoading = false
        state.addDocumentData = action.payload
      })
      .addCase(addDocument.rejected, (state, error) => {
        state.isLoading = false
        state.error = error
      })
    //Edit
    builder
      .addCase(getDocumentById.pending, (state) => {
        state.isLoading = true
      })
      .addCase(getDocumentById.fulfilled, (state, action) => {
        state.isLoading = false
        state.documentDetail = action.payload
      })
      .addCase(getDocumentById.rejected, (state, error) => {
        state.isLoading = false
        state.error = error
      })
    // UPDATE DOCUMENT STATUS
    builder
      .addCase(updateDocumentStatus.pending, (state) => {
        state.isLoading = true
        state.isStatusUpdated = false
      })
      .addCase(updateDocumentStatus.fulfilled, (state) => {
        state.isLoading = false
        state.isStatusUpdated = true
      })
      .addCase(updateDocumentStatus.rejected, (state, error) => {
        state.isLoading = false
        state.isStatusUpdated = false
        state.error = error
      })
    //delete document
    builder
      .addCase(deleteDocument.pending, (state) => {
        state.isLoading = true
      })
      .addCase(deleteDocument.fulfilled, (state) => {
        state.isLoading = false
      })
      .addCase(deleteDocument.rejected, (state, error) => {
        state.isLoading = false
        state.error = error
      })
    // GET ALL EMPLOYEE
    builder.addCase(getAllSearchUserList.pending, (state) => {
      state.isLoading = true
      state.employeeData = []
    })
    builder.addCase(getAllSearchUserList.fulfilled, (state, action) => {
      state.isLoading = false
      state.employeeData = action.payload?.data
    })
    builder.addCase(getAllSearchUserList.rejected, (state, error) => {
      state.isLoading = false
      state.error = error
    })
  },
})
export const {
  clearDocumentData,
  getEditDocumentData,
  clearEditDocumentData,
  getAllDocumentPayloadData,
  clearAttach,
} = documentSlice.actions
export default documentSlice.reducer
