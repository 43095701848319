import JoditEditor from 'jodit-react'
import { FC, memo, useCallback } from 'react'
import { richTextEditorConfig } from '../../config/editorConfig'

interface IRichTextEditor {
  value: string
  setValue: (value: string) => void
}

const RichTextEditor: FC<IRichTextEditor> = ({ value, setValue }) => {
  const editorConfig: any = {
    ...richTextEditorConfig,
    buttons: [...richTextEditorConfig.buttons],
    uploader: {
      insertImageAsBase64URI: true,
    },
  }

  const isContentValid = useCallback((content: string): boolean => {
    // Trim the content to remove any leading or trailing whitespace
    const trimmedContent = content.trim()

    // Check for completely empty content
    if (trimmedContent === '') return false

    // Check for a single paragraph with only a <br> tag
    if (trimmedContent === '<p><br></p>') return false

    // Check for a single paragraph with only &nbsp; entities and optionally a <br> tag at the end
    if (/^<p>(&nbsp;\s*)*(<br>)?<\/p>$/.test(trimmedContent)) return false

    //   // Check for content with only non-breaking spaces
    //   const nbspOnlyContent = content.replace(/<p>(&nbsp;|\s)*<\/p>/g, '').trim()
    //   if (nbspOnlyContent === '') return false

    // If none of the above conditions are met, the content is valid
    return true
  }, [])

  const handleBlur = (newContent: string) => {
    const isValid = isContentValid(newContent)
    if (isValid) {
      setValue(newContent)
    } else {
      // If content is not valid, set it to an empty string or keep the previous valid value
      setValue('')
    }
  }

  return <JoditEditor config={editorConfig} value={value} onBlur={handleBlur} />
}

export default memo(RichTextEditor)
