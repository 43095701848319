import { FC, useEffect, useState } from 'react'
import Button from '../../components/common/button/Button'
import styles from './reportMapping.module.scss'
import { CustomModal } from '../../components/common/custom-modal/modal'
import TableV2 from '../../components/common/table/tableV2/TableV2'
import { reportMappingTableHeaderData } from '../../constants/table-data/reportMappingTableHeaderData'
import { useForm, SubmitHandler } from 'react-hook-form'
import { reactSelectStyle } from '../../constants/data'
import { IReportMapForm } from '../../interfaces/interfaces'
import Select from 'react-select'
import makeAnimated from 'react-select/animated'

import { REPORT_MAP_ROLE, REPORT_MAP_TYPE } from '../../constants/constant'
import { useAppDispatch, useAppSelector } from '../../hooks'
import {
  getUserRole,
  updateReportMapping,
} from '../../redux/features/role/roleAsynActions'
import { requestGenerator } from '../../utils/payloadGenerator'
import { reportMapValidators } from '../../form-validators/reportMappingValidators'
import { reportType } from '../report-builder/data'
import {
  GET__ROLE__USER,
  UPDATE_REPORT_MAPPING_TYPE,
} from '../../constants/asyncActionsType'
import Loader from '../../components/common/spinner/Loader'
import { t } from 'i18next'

interface IReportMapping {}

const ReportMapping: FC<IReportMapping> = () => {
  const [reportMappingPopup, setReportMappingPopup] = useState<boolean>(false)
  const dispatch = useAppDispatch()
  const { userRole, isLoading } = useAppSelector((state) => state.roleUser)
  const [roleData, setRoleData] = useState([])
  const animatedComponent = makeAnimated()

  const getRoles = () => {
    let data = {
      search: '',
      page: 1,
      pageSize: 1000,
      isAll: false,
    }
    dispatch(getUserRole(requestGenerator(data))).then((e) => {
      if (e.type === `${GET__ROLE__USER}/fulfilled`) {
        const systemRoles =
          e.payload && e.payload?.length > 0
            ? e.payload?.filter((item: any) => item?.system_defined)
            : []
        setRoleData(systemRoles)
      }
    })
  }
  const {
    register,
    reset,
    handleSubmit,
    watch,
    trigger,
    setValue,
    formState: { errors },
  } = useForm<IReportMapForm>({})
  const onSubmit: SubmitHandler<IReportMapForm> = (data) => {
    const payload = {
      [REPORT_MAP_ROLE]: data[REPORT_MAP_ROLE]?.value,
      [REPORT_MAP_TYPE]: data[REPORT_MAP_TYPE]?.map((item: any) => item?.value),
    }
    dispatch(updateReportMapping(requestGenerator(payload))).then((e) => {
      if (e.type === `${UPDATE_REPORT_MAPPING_TYPE}/fulfilled`) {
        reset({
          [REPORT_MAP_ROLE]: undefined,
          [REPORT_MAP_TYPE]: undefined,
        })
        setReportMappingPopup(false)
        getRoles()
      }
    })
  }
  useEffect(() => {
    getRoles()
  }, [dispatch])
  return (
    <>
      {isLoading ? <Loader /> : ''}
      <CustomModal
        showModal={reportMappingPopup}
        closeModal={() => setReportMappingPopup(false)}
        title={t('Header Title.Report Mapping') || 'Report Mapping'}
        width="30dvw"
        height="60dvh"
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className={styles.inputFieldsContainer}>
            <div className={styles.labelField}>
              <label htmlFor={REPORT_MAP_ROLE} className={styles.labelText}>
                {t('ManageUserRoles.Role')} :<span className="asterick">*</span>
              </label>
              <div className={styles.fieldErrorContainer}>
                <Select
                  className={styles.selectInputField}
                  isSearchable={true}
                  isClearable={true}
                  options={userRole
                    ?.filter((item: any) => item?.system_defined)
                    ?.map((item: any) => ({
                      label: item.name,
                      value: item._id,
                    }))}
                  value={watch(REPORT_MAP_ROLE)}
                  components={animatedComponent}
                  closeMenuOnSelect={true}
                  placeholder="Select Role"
                  {...register(
                    REPORT_MAP_ROLE,
                    reportMapValidators[REPORT_MAP_ROLE]
                  )}
                  onChange={(e: any) => {
                    setValue(REPORT_MAP_ROLE, e)
                    trigger(REPORT_MAP_ROLE)
                  }}
                  styles={reactSelectStyle}
                  maxMenuHeight={190}
                  isDisabled
                />

                <div className={styles.errorContainer}>
                  <span className={styles.extraSpan}></span>
                  {errors[REPORT_MAP_ROLE] && (
                    <p className="dashboardFormError">
                      {errors[REPORT_MAP_ROLE].message as any}
                    </p>
                  )}
                </div>
              </div>
            </div>
            <div className={styles.labelField}>
              <label htmlFor={REPORT_MAP_TYPE} className={styles.labelText}>
                {t('ReportMapping.Report_Type')} :
              </label>
              <div className={styles.fieldErrorContainer}>
                <Select
                  className={styles.selectInputField}
                  isSearchable={true}
                  isClearable={true}
                  isMulti
                  options={reportType}
                  value={watch(REPORT_MAP_TYPE)}
                  components={animatedComponent}
                  closeMenuOnSelect={false}
                  placeholder={t('ReportMapping.Report_Type_Placeholder')}
                  {...register(REPORT_MAP_TYPE)}
                  onChange={(e: any) => {
                    setValue(REPORT_MAP_TYPE, e)
                    trigger(REPORT_MAP_TYPE)
                  }}
                  styles={reactSelectStyle}
                  maxMenuHeight={190}
                />
              </div>
            </div>
            <div className={styles.submitButton}>
              <Button type="submit" title={t('Common.Submit') || 'Submit'} />
            </div>
          </div>
        </form>
      </CustomModal>
      <div className={styles.mainContainer}>
        <div className={styles.tableContainer}>
          <TableV2
            tableHeaderData={reportMappingTableHeaderData}
            tableRowData={roleData}
            handleClick={(item: any) => {
              const role: any = roleData?.find(
                (role: any) => role?._id === item?._id
              )
              const reportTypeData =
                role?.reports_access && role?.reports_access?.length > 0
                  ? role?.reports_access?.map((item: any) => {
                      const type: any = reportType?.find(
                        (repType: any) => repType?.value === item
                      )
                      return type
                    })
                  : []
              const data = {
                [REPORT_MAP_ROLE]: {
                  label: role?.name,
                  value: role?._id,
                },
                [REPORT_MAP_TYPE]: reportTypeData,
              }
              reset(data)
              setReportMappingPopup(true)
            }}
          />
        </div>
      </div>
    </>
  )
}
export default ReportMapping
