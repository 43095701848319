import { FORM_LABEL_NAME, ROLE } from '../constants/constant'

export const manageCreateNewFormsValidators = {
  [FORM_LABEL_NAME]: {
    required: 'Please enter form',
  },

  [ROLE]: {
    required: 'Please select role',
  },
}
