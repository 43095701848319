import { t } from 'i18next'
import Button from '../common/button/Button'
import styles from './samplCsv.module.scss'
import { FC } from 'react'

interface ISampleCSV {
  onExport: any
}

const SampleCSV: FC<ISampleCSV> = ({ onExport }) => {
  return (
    <Button
      title={t('LeadPool.Sample_CSV') || 'Sample CSV'}
      customClass={styles.csvBtn}
      type="button"
      handleClick={(e: any) => onExport(e.target.value)}
    />
  )
}

export default SampleCSV
