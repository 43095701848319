// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.chart_containerBody__5aLez::-webkit-scrollbar {
  display: block !important;
  width: 7px !important;
  height: 7px !important;
}
.chart_containerBody__5aLez::-webkit-scrollbar-track {
  background: transparent !important;
}
.chart_containerBody__5aLez::-webkit-scrollbar-thumb {
  background-color: var(--grey7) !important;
  border-radius: 10px !important;
  border: 1px solid var(--grey7) !important;
}

.chart_chart__ijly9 {
  width: 100%;
  margin: 30px 0px;
}
.chart_chart__ijly9::-webkit-scrollbar {
  display: block !important;
  width: 7px !important;
  height: 7px !important;
}
.chart_chart__ijly9::-webkit-scrollbar-track {
  background: transparent !important;
}
.chart_chart__ijly9::-webkit-scrollbar-thumb {
  background-color: var(--grey7) !important;
  border-radius: 10px !important;
  border: 1px solid var(--grey7) !important;
}`, "",{"version":3,"sources":["webpack://./src/components/charts/chart.module.scss"],"names":[],"mappings":"AAEE;EACE,yBAAA;EACA,qBAAA;EACA,sBAAA;AADJ;AAGE;EACE,kCAAA;AADJ;AAIE;EACE,yCAAA;EACA,8BAAA;EACA,yCAAA;AAFJ;;AAMA;EACE,WAAA;EACA,gBAAA;AAHF;AAIE;EACE,yBAAA;EACA,qBAAA;EACA,sBAAA;AAFJ;AAIE;EACE,kCAAA;AAFJ;AAKE;EACE,yCAAA;EACA,8BAAA;EACA,yCAAA;AAHJ","sourcesContent":[".containerBody{\n  // width: 100%;\n  &::-webkit-scrollbar {\n    display: block !important;\n    width: 7px !important;\n    height: 7px !important;\n  }\n  &::-webkit-scrollbar-track {\n    background: transparent !important;\n  }\n\n  &::-webkit-scrollbar-thumb {\n    background-color: var(--grey7) !important;\n    border-radius: 10px !important;\n    border: 1px solid var(--grey7) !important;\n  }\n}\n\n.chart {\n  width: 100%;\n  margin: 30px 0px;\n  &::-webkit-scrollbar {\n    display: block !important;\n    width: 7px !important;\n    height: 7px !important;\n  }\n  &::-webkit-scrollbar-track {\n    background: transparent !important;\n  }\n\n  &::-webkit-scrollbar-thumb {\n    background-color: var(--grey7) !important;\n    border-radius: 10px !important;\n    border: 1px solid var(--grey7) !important;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"containerBody": `chart_containerBody__5aLez`,
	"chart": `chart_chart__ijly9`
};
export default ___CSS_LOADER_EXPORT___;
