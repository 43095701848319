import * as React from 'react'
import styles from './searchDesign.module.scss'
import { SearchIcon } from '../svg-components'
import { colors } from '../../../constants/color'
import { useTranslation } from 'react-i18next'

interface ISearchDesign {
  handleSearchClick?: any
  customClass?: string
  customClassInput?: string
}

const SearchDesign: React.FunctionComponent<ISearchDesign> = ({
  handleSearchClick,
  customClass,
  customClassInput,
}) => {
  const { t } = useTranslation()
  return (
    <>
      <div
        onClick={() => handleSearchClick()}
        className={[styles.mainContainer, customClass ?? ''].join(' ')}
      >
        <div className={styles.searchIcon}>
          <SearchIcon
            fillColor={colors?.grey2}
            customClass={styles.searchIconStyle}
          />
        </div>

        <div className={[styles.searchContainer, customClassInput].join(' ')}>
          <p className={styles.searchText}>{t('Common.Search Patients')}</p>
        </div>
      </div>
    </>
  )
}

export default SearchDesign
