import { FC, useEffect, useState } from 'react'
import styles from './kpiRevenue.module.scss'
import Select from 'react-select'
import { useTranslation } from 'react-i18next'
import { kpiRangeData, reactSelectStyle } from '../../../../constants/data'
import PatientCard from './patient-card/PatientCard'
import NewPatientImage from '../../../../assets/images/NewPatientImage.png'
import OldPatientImage from '../../../../assets/images/OldPatientImage.png'
import NewPatientGraph from '../../../../assets/images/NewPatientGraph.png'
import OldPatientGraph from '../../../../assets/images/OldPatientGraph.png'
import BarChart from '../../../charts/Barchart'
import { useAppDispatch, useAppSelector } from '../../../../hooks'
import {
  RevenueAnylticsFilterUrl,
  patientSummaryFilterUrl,
  patientSummaryReport,
  revenueDetail,
} from '../../../../redux/features/role/roleAsynActions'
import { requestGenerator } from '../../../../utils/payloadGenerator'
import { getAllDoctors } from '../../../../redux/features/appointments/bookingAppointmentAsyncActions'
import { GET_ALL_DOCTORS_TYPE } from '../../../../constants/asyncActionsType'
import moment from 'moment'
import PaginationV2 from '../../pagination/paginationv2/PaginationV2'
interface IRadiologyReportModal {}

const KPIRevenueModal: FC<IRadiologyReportModal> = () => {
  let date = new Date()
  let firstDay = new Date(date.getFullYear(), date.getMonth(), 1)
  let lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0)
  const firstDateFormate = moment(firstDay).format('YYYY-MM-DD')
  const lastDateFormate = moment(lastDay).format('YYYY-MM-DD')
  const [doctorList, setDoctorList] = useState<any>([])
  const [patientFromDate, setPatientFromDate] = useState<any>(firstDateFormate)
  const [patientToDate, setpatientToDate] = useState<any>(lastDateFormate)
  const [revenueFromDate, setRevenueFromDate] = useState<any>(firstDateFormate)
  const [revenueToDate, setRevenueToDate] = useState<any>(lastDateFormate)

  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const { branchData } = useAppSelector((state) => state.login)
  const {
    revenueData,
    patientSummary,
    patientSummaryFilterList,
    patientRevenueFilterList,
  } = useAppSelector((state) => state.roleUser)
  // const [initialCount, setInitialCount] = useState({
  //   revenueCount: 0,
  //   patientSummaryCount: 0,
  // });
  const [patientSummryCount, setpatientSummryCount] = useState(0)
  const [revenueCount, setRevenueCount] = useState(0)

  const [patientType, setPatientType] = useState<any>(kpiRangeData[0])
  const [revenueType, setRevenueType] = useState<any>(kpiRangeData[0])

  const [patientSummaryDoctorFilter, setPatientSummaryDoctorFilter] =
    useState<any>(null)
  const [revenueFilterDoctor, setRevenueFilterDoctor] = useState<any>(null)

  // patient summary
  useEffect(() => {
    const payload = {
      doctor_id: branchData?.doctor_id || undefined,
      type: patientType?.value,
    }
    dispatch(patientSummaryReport(requestGenerator(payload)))
  }, [dispatch, branchData, patientType])

  // doctor revenue
  useEffect(() => {
    const payload = {
      doctor_id: branchData?.doctor_id || undefined,
      type: revenueType?.value,
    }
    dispatch(revenueDetail(requestGenerator(payload)))
  }, [dispatch, branchData, revenueType])

  useEffect(() => {
    let data = {
      page: 1,
      pageSize: 1000,
    }
    dispatch(getAllDoctors(requestGenerator(data))).then((res) => {
      if (res?.type === `${GET_ALL_DOCTORS_TYPE}/fulfilled`) {
        setDoctorList([
          {
            doctor_name: 'All',
            _id: null,
          },
          ...res?.payload?.data,
        ])
      }
    })
  }, [dispatch])

  useEffect(() => {
    if (patientFromDate && patientToDate) {
      const payload = {
        doctor_id: patientSummaryDoctorFilter?.value ?? undefined,
        range: {
          fromDate: patientFromDate,
          toDate: patientToDate,
        },
      }
      dispatch(patientSummaryFilterUrl(requestGenerator(payload)))
    }
  }, [dispatch, patientSummaryDoctorFilter, patientFromDate, patientToDate])

  useEffect(() => {
    if (revenueFromDate && revenueToDate) {
      const payload = {
        doctor_id: revenueFilterDoctor?.value ?? undefined,
        range: {
          fromDate: revenueFromDate,
          toDate: revenueToDate,
        },
      }
      dispatch(RevenueAnylticsFilterUrl(requestGenerator(payload)))
    }
  }, [dispatch, revenueFilterDoctor, revenueFromDate, revenueToDate])

  return (
    <>
      <div className={styles.kpiRevenueMainContainer}>
        <div className={styles.ButtonDropdownContanier}>
          <span className={styles.patientSummary}>
            {t('KPI.Patient Summary')}
          </span>
          <div className={styles.dropDownContainer}>
            <div className={styles.dropdown}>
              <Select
                className={styles.selectInputField}
                isSearchable={true}
                options={kpiRangeData}
                maxMenuHeight={200}
                value={patientType}
                placeholder={
                  t('MobileAppConfiguration.Type_placeholder') || 'Select Type'
                  // "hdshdh"
                }
                onChange={(e) => {
                  setPatientType(e)
                }}
                styles={reactSelectStyle}
              />
            </div>
          </div>
        </div>
        <div className={styles.patientCardContainer}>
          <PatientCard
            image={NewPatientImage}
            label="New Patients"
            value={patientSummary?.newPatient || 0}
            graph={NewPatientGraph}
          />
          <PatientCard
            image={OldPatientImage}
            label="Old Patients"
            value={patientSummary?.oldPatient || 0}
            graph={OldPatientGraph}
          />
        </div>

        <div className={styles.revenueAnalysisContainer} id="kpiRevenue">
          <div className={styles.ButtonDropdownContanier}>
            <span className={styles.patientSummary}>
              {t('KPI.Revenue Analysis')}
            </span>
            <div className={styles.dropDownContainer}>
              <div className={styles.dropdown}>
                <Select
                  className={styles.selectInputField}
                  isSearchable={true}
                  options={kpiRangeData}
                  maxMenuHeight={200}
                  value={revenueType}
                  placeholder={
                    t('MobileAppConfiguration.Type_placeholder') ||
                    'Select Type'
                  }
                  onChange={(e) => {
                    setRevenueType(e)
                  }}
                  styles={reactSelectStyle}
                />
              </div>
            </div>
          </div>
          {revenueData && revenueData.length > 0 ? (
            <BarChart
              data={revenueData}
              captionText={`${revenueType?.label} Revenue`}
              XDataField="name"
              series={[
                {
                  dataField: 'total_amount',
                  displayText: 'Revenue',
                  fillColor: '#048BA8',
                },
              ]}
              YDataDescription="Revenue"
              isDate={revenueType?.value === 'MONTHLY'}
            />
          ) : (
            <p style={{ textAlign: 'center', padding: '20px' }}>
              {t('Common.No records found')}
            </p>
          )}
        </div>

        {branchData?.is_management && (
          <>
            {/*  Patient Summry Filter */}
            <div className={styles.kpiFilterContainer}>
              <div className={styles.titleContainer}>
                <h2 className={styles.patientSummary}>
                  {t('KPI.Overall KPI Revenue')}
                </h2>
                <span className={styles.textUnderline} />
              </div>

              <div className={styles.patientSummaryFilterContainer}>
                <div className={styles.ButtonDropdownContanier}>
                  <span className={styles.patientSummary}>
                    {t('KPI.Patient Summary')}
                  </span>
                  <div className={styles.dropDownContainer}>
                    <div className={styles.dropdown}>
                      <Select
                        className={styles.selectInputField}
                        isSearchable={true}
                        options={doctorList?.map((item: any) => {
                          return {
                            label: item?.doctor_name,
                            value: item?._id,
                          }
                        })}
                        maxMenuHeight={200}
                        value={patientSummaryDoctorFilter}
                        placeholder={t('KPI.All Doctors') || 'All Doctors'}
                        onChange={(e) => {
                          setPatientSummaryDoctorFilter({
                            label: e?.label,
                            value: e?.value,
                          })
                        }}
                        styles={reactSelectStyle}
                      />
                    </div>

                    <div className={styles.dateFieldContainer}>
                      <input
                        type="date"
                        id="from-date"
                        onChange={(e: any) => {
                          setPatientFromDate(e.target.value)
                        }}
                        className={styles.dateField}
                        value={patientFromDate}
                      />
                    </div>

                    <div className={styles.dateFieldContainer}>
                      <input
                        type="date"
                        id="to-date"
                        onChange={(e: any) => {
                          setpatientToDate(e.target.value)
                        }}
                        className={styles.dateField}
                        value={patientToDate}
                      />
                    </div>
                  </div>
                </div>

                <div id="kpiRevenuePatient">
                  {patientSummaryFilterList &&
                  patientSummaryFilterList.length > 0 ? (
                    <BarChart
                      data={
                        patientSummaryFilterList?.slice(
                          patientSummryCount,
                          patientSummryCount + 15
                        ) ?? []
                      }
                      captionText="Patient Summary"
                      XDataField="doctor_name"
                      YDataDescription="Doctors"
                      series={[
                        {
                          dataField: 'oldPatientCount',
                          displayText: 'Old Patient',
                          fillColor: '#5936F1',
                        },
                        {
                          dataField: 'newPatientCount',
                          displayText: 'New Patient',
                          fillColor: '#2DCEE0',
                        },
                      ]}
                    />
                  ) : (
                    <p style={{ textAlign: 'center', padding: '20px' }}>
                      {t('Common.No records found')}
                    </p>
                  )}
                </div>

                <div className={styles.paginationContainer}>
                  <PaginationV2
                    handlePrevious={() => {
                      if (patientSummryCount >= 15) {
                        setpatientSummryCount(patientSummryCount - 15)
                      }
                    }}
                    handleReset={() => {
                      setpatientSummryCount(0)
                    }}
                    handleNext={() => {
                      if (
                        patientSummaryFilterList?.length >
                        patientSummryCount + 15
                      ) {
                        setpatientSummryCount(patientSummryCount + 15)
                      }
                    }}
                    nextPage={
                      patientSummaryFilterList?.length > patientSummryCount + 15
                    }
                    previousPage={!patientSummryCount ? true : false}
                  />
                  <p className={styles.totalCount}>{`${t(
                    'KPI.Total Doctors'
                  )} ${patientSummaryFilterList?.length}`}</p>
                </div>
              </div>
              {/*  Revenue Analysis Filter */}

              <div className={styles.revenueAnalysisFilterContainer}>
                <div className={styles.ButtonDropdownContanier}>
                  <span className={styles.patientSummary}>
                    {t('KPI.Revenue Analysis')}
                  </span>
                  <div className={styles.dropDownContainer}>
                    <div className={styles.dropdown}>
                      <Select
                        className={styles.selectInputField}
                        isSearchable={true}
                        options={doctorList?.map((item: any) => {
                          return {
                            label: item?.doctor_name,
                            value: item?._id,
                          }
                        })}
                        maxMenuHeight={200}
                        value={revenueFilterDoctor}
                        placeholder={t('KPI.All Doctors') || 'All Doctors'}
                        onChange={(e) => {
                          setRevenueFilterDoctor({
                            label: e?.label,
                            value: e?.value,
                          })
                        }}
                        styles={reactSelectStyle}
                      />
                    </div>

                    <div className={styles.dateFieldContainer}>
                      <input
                        type="date"
                        id="from-date"
                        onChange={(e: any) => {
                          setRevenueFromDate(e.target.value)
                        }}
                        className={styles.dateField}
                        value={revenueFromDate}
                      />
                    </div>

                    <div className={styles.dateFieldContainer}>
                      <input
                        type="date"
                        id="to-date"
                        onChange={(e: any) => {
                          setRevenueToDate(e.target.value)
                        }}
                        className={styles.dateField}
                        value={revenueToDate}
                      />
                    </div>
                  </div>
                </div>

                <div id="kpiRevenueOverAll">
                  {patientRevenueFilterList &&
                  patientRevenueFilterList.length > 0 ? (
                    <BarChart
                      data={
                        patientRevenueFilterList?.slice(
                          revenueCount,
                          revenueCount + 15
                        ) ?? []
                      }
                      captionText="Revenue Analysis"
                      XDataField="doctor_name"
                      YDataDescription="Revenue"
                      series={[
                        {
                          dataField: 'total_amount',
                          displayText: 'Revenue',
                          fillColor: '#5936F1',
                        },
                      ]}
                    />
                  ) : (
                    <p style={{ textAlign: 'center', padding: '20px' }}>
                      {t('Common.No records found')}
                    </p>
                  )}
                </div>

                <div className={styles.paginationContainer}>
                  <PaginationV2
                    handlePrevious={() => {
                      if (revenueCount >= 15) {
                        setRevenueCount(revenueCount - 15)
                      }
                    }}
                    handleReset={() => {
                      setRevenueCount(0)
                    }}
                    handleNext={() => {
                      if (
                        patientRevenueFilterList?.length >
                        revenueCount + 15
                      ) {
                        setRevenueCount(revenueCount + 15)
                      }
                    }}
                    nextPage={
                      patientRevenueFilterList?.length > revenueCount + 15
                    }
                    previousPage={!revenueCount ? true : false}
                  />

                  <p className={styles.totalCount}>{`${t(
                    'KPI.Total Doctors'
                  )} ${patientRevenueFilterList?.length}`}</p>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  )
}

export default KPIRevenueModal
