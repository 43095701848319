import { createSlice } from '@reduxjs/toolkit'
import { getAllLedgerBalance } from './ledgerAsyncActions'

export interface ITrialBalance {
  isLoading: boolean
  allLedgerBalanceData: []
  allLedgerListDataObject: {}
  error: any
  isStatusUpdated: boolean
}
export const initialState: ITrialBalance = {
  isLoading: false,
  allLedgerBalanceData: [],
  allLedgerListDataObject: {},
  isStatusUpdated: true,
  error: null,
}

export const ledgerSlice = createSlice({
  name: 'ledger',
  initialState,
  reducers: {
    clearLedgerData: (state) => {
      state.allLedgerBalanceData = []
    },
  },
  extraReducers(builder) {
    // GET ALL Trial Balance
    builder
      .addCase(getAllLedgerBalance.pending, (state) => {
        state.isLoading = true
        state.isStatusUpdated = false
      })
      .addCase(getAllLedgerBalance.fulfilled, (state, action) => {
        state.isLoading = false
        state.isStatusUpdated = false
        state.allLedgerBalanceData = action.payload?.data[0]?.voucher || []
        state.allLedgerListDataObject = action.payload
      })
      .addCase(getAllLedgerBalance.rejected, (state, error) => {
        state.isLoading = false
        state.error = error
        state.isStatusUpdated = false
      })
  },
})

export const { clearLedgerData } = ledgerSlice.actions
export default ledgerSlice.reducer
