import { createSlice } from '@reduxjs/toolkit'
import { IOngoingClaims } from '../../../interfaces/apiInterface'
import {
  createNewClaim,
  createSetteledClaims,
  getAllClaimData,
  getAllOngoingClaims,
  getClaimsByMarketPlace,
} from './onGoingClaimsAsyncActions'
import { getPandingClaims } from './onGoingClaimsAsyncActions'

export const initialState: IOngoingClaims = {
  isLoading: false,
  onGoingClaimsData: [],
  pandingClaimsData: [],
  claimsByMarketPlace: [],
  initiatedClaimsData: [],
}

export const onGoingClaims = createSlice({
  name: 'ongoing-claims',
  initialState,
  reducers: {
    clearPendingClaimsData: (state) => {
      state.isLoading = false
      state.pandingClaimsData = []
      state.initiatedClaimsData = []
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAllOngoingClaims.pending, (state) => {
      state.isLoading = true
      state.onGoingClaimsData = []
    })
    builder.addCase(getAllOngoingClaims.fulfilled, (state, action) => {
      state.isLoading = false
      state.onGoingClaimsData = action.payload.data ?? []
    })
    builder.addCase(getAllOngoingClaims.rejected, (state, error) => {
      state.isLoading = false
      state.onGoingClaimsData = []
    })

    // get panding claims case

    builder.addCase(getPandingClaims.pending, (state) => {
      state.isLoading = true
      state.pandingClaimsData = []
      state.initiatedClaimsData = []
    })
    builder.addCase(getPandingClaims.fulfilled, (state, action) => {
      state.isLoading = false
      state.pandingClaimsData = action.payload?.data ?? []
      state.initiatedClaimsData =
        action.payload?.data?.map((item: any) => {
          return { ...item, settled_amount: 0, rejected_amount: 0 }
        }) ?? []
    })
    builder.addCase(getPandingClaims.rejected, (state, error) => {
      state.isLoading = false
      state.pandingClaimsData = []
      state.initiatedClaimsData = []
    })

    builder.addCase(createNewClaim.pending, (state) => {
      state.isLoading = true
      state.onGoingClaimsData = []
    })
    builder.addCase(createNewClaim.fulfilled, (state, action) => {
      state.isLoading = false
    })
    builder.addCase(createNewClaim.rejected, (state, error) => {
      state.isLoading = false
    })

    // get all claims based on market place company
    builder.addCase(getClaimsByMarketPlace.pending, (state) => {
      state.isLoading = true
      state.claimsByMarketPlace = []
    })
    builder.addCase(getClaimsByMarketPlace.fulfilled, (state, action) => {
      state.isLoading = false
      state.claimsByMarketPlace = action.payload.data ?? []
    })
    builder.addCase(getClaimsByMarketPlace.rejected, (state, error) => {
      state.isLoading = false
    })

    builder.addCase(createSetteledClaims.pending, (state) => {
      state.isLoading = true
      state.onGoingClaimsData = []
    })
    builder.addCase(createSetteledClaims.fulfilled, (state, action) => {
      state.isLoading = false
    })
    builder.addCase(createSetteledClaims.rejected, (state, error) => {
      state.isLoading = false
    })

    // GET ALL CLAIM DATA BASED ON STATUS

    builder.addCase(getAllClaimData.pending, (state) => {
      state.isLoading = true
      state.onGoingClaimsData = []
    })
    builder.addCase(getAllClaimData.fulfilled, (state, action) => {
      state.isLoading = false
      state.pandingClaimsData = action.payload?.data ?? []
      state.initiatedClaimsData = action.payload?.data ?? []

      // action.payload?.data?.map((item: any) => {
      //   return { ...item, settled_amount: 0, rejected_amount: 0 }
      // }) ?? []
    })
    builder.addCase(getAllClaimData.rejected, (state) => {
      state.isLoading = false
    })
  },
})

export const { clearPendingClaimsData } = onGoingClaims.actions

export default onGoingClaims.reducer
