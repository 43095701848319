import moment from 'moment'
import styles from './agents.module.scss'
import { t } from 'i18next'

export const AgentHeader: any[] = [
  {
    Header: t('CallCenterAgent.AGENT_CODE'),
    accessor: (row: any) => {
      return row.agent_code
    },
  },
  {
    Header: t('CallCenterAgent.AGENT_NAME'),
    accessor: (row: any) => {
      return row.agent_name
    },
  },
  {
    Header: t('RcptTableHeader.MOBILE NO'),
    accessor: (row: any) => {
      return row.phone
    },
  },
  {
    Header: t('RcptTableHeader.STATUS'),
    Cell: (props: any) => {
      return (
        <span
          style={{
            cursor: 'pointer',
            fontSize: 'var(--font-12)',
            fontWeight: 'var(--font-normal)',
          }}
          className={
            props.row.original.status
              ? styles.statusActive
              : styles.statusInActive
          }
        >
          {props.row.original.status ? 'Active' : 'Inactive'}
        </span>
      )
    },
  },
  {
    Header: t('InsuranceHeader.DETAILS'),
    Cell: (props: any) => {
      return (
        <span
          style={{
            color: 'var(--blue1)',
            cursor: 'pointer',
            fontSize: 'var(--font-12)',
            fontWeight: 'var(--font-normal)',
          }}
          onClick={() => {
            props.onClick(props.row.original._id)
          }}
        >
          {t('Common.View')}
        </span>
      )
    },
  },
]

export const AgentDetailsHeader: any[] = [
  {
    Header: t('CallCenterAgent.CAMPAIGN_NAME'),
    accessor: (row: any) => {
      return row.campaign_name
    },
  },
  {
    Header: t('CallCenterAgent.START_DATE'),
    accessor: (row: any) => {
      return moment(row?.campaign_start_date).format('DD-MMM-YYYY') ?? '-'
    },
  },
  {
    Header: t('CallCenterAgent.END_DATE'),
    accessor: (row: any) => {
      return moment(row?.campaign_end_date).format('DD-MMM-YYYY') ?? '-'
    },
  },
]
