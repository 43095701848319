import { FC, useState } from 'react'
import { CloseIcon } from '../../../../components/common/svg-components'
import { colors } from '../../../../constants/color'
import styles from './addNotesPopup.module.scss'
import Divider from '../../../../components/common/divider/Divider'
import Button from '../../../../components/common/button/Button'
import { useTranslation } from 'react-i18next'

interface IAddNotesPopup {
  handleClose?: any
  handleSubmitData?: any
  popData?: any
}

const AddNotesPopup: FC<IAddNotesPopup> = ({
  handleClose,
  handleSubmitData,
  popData,
}) => {
  const [notesData, setNotesData] = useState(popData)
  const { t } = useTranslation()
  const submitNotes = () => {
    handleSubmitData(notesData)
    handleClose()
  }

  return (
    <>
      <div
        className={styles.notesPopupContainer}
        onClick={(e) => e.stopPropagation()}
      >
        <CloseIcon
          customClass={styles.closeIconStyle}
          fillColor={colors.green1}
          handleClick={handleClose}
        />
        <div className={styles.notesContainer}>
          <p className={styles.title}>{t('Common.Notes')}</p>
          <Divider customClass={styles.dividerStyle} />
          <div className={styles.labelFieldContainer}>
            <label className={styles.labelText}>{t('Common.Notes')}</label>
            <textarea
              className={styles.inputField}
              value={notesData}
              onChange={(e) => setNotesData(e.target.value)}
            />
          </div>
          <div className={styles.btnContainer}>
            <Button
              title={t('Common.Submit') || 'Submit'}
              handleClick={submitNotes}
            />
          </div>
        </div>
      </div>
    </>
  )
}

export default AddNotesPopup
