import {
  DEPARTMENT_PAYROL,
  MONTH_PAYROL,
  YEAR_PAYROL,
} from '../constants/constant'
import { t } from 'i18next'

export const generatePayrollValidators = {
  [YEAR_PAYROL]: {
    required: t('Attendance.month_validation'),
  },
  [MONTH_PAYROL]: {
    required: t('Attendance.year_validation'),
  },
  [DEPARTMENT_PAYROL]: {
    required: t('DepartmentSetup.Dept validation'),
  },
}
