// Manage Attendance
export const SUB_COMPANY = 'sub_company'
export const SELECT_MONTH = 'select_month'
export const SELECT_YEAR = 'select_year'
export const DEPARTMENT_HR = 'department'
export const DEPARTMENT = 'department_id'

// manage attendance modal
export const PUNCH_IN = 'start_time'
export const PUNCH_OUT = 'end_time'
export const IS_LATE = 'is_late'
export const LEAVE_TYPE = 'leave_type'
export const LEAVE_STATUS = 'isPaid'
export const REASON = 'reason'
export const HALFDAY = 'halfDay'
