import moment from 'moment'
import styles from './tableData.module.scss'
import { t } from 'i18next'

export const ipdTestHeaderData: any = [
  {
    Header: t('RcptTableHeader.DATE'),
    accessor: 'date',
    Cell: ({ row }: any) => {
      const originalDate = row?.original?.updatedAt
      const formattedDate = moment(originalDate).format('DD MMM YYYY')
      return <>{formattedDate}</>
    },
  },
  {
    Header: t('RequestDiagnosis.TESTTYPE'),
    accessor: 'TYPE',
    Cell: ({ row }: any) => {
      return <p>{row?.original?.is_internal ? 'Internal' : 'External'}</p>
    },
  },
  {
    Header: t('IPDHistory.TEST DEPT'),
    accessor: 'job_type',
  },
  {
    Header: t('ViewReports.TEST NAME'),
    accessor: 'nameww',
    Cell: (props: any) => {
      return (
        <p
          className={styles.blueLinkText}
          onClick={() => {
            props?.onClick(
              props?.row?.original?.profile[0]?.profile_name,
              props?.row?.original?.profile[0]?.tests
            )
          }}
        >
          {t('Common.View')}
        </p>
      )
    },
  },
  {
    Header: t('ViewAppointmentHeader.DOCTOR'),
    accessor: 'doctor_name',
  },
  {
    Header: t('IPDConsultation.RESULT'),
    accessor: 'result',
    Cell: (props: any) => {
      return (
        <p
          className={styles.blueLinkText}
          onClick={() => {
            props?.onRowClick(
              props?.row?.original?.profile[0]?.profile_name,
              props?.row?.original?.profile[0]?.tests
            )
          }}
        >
          {t('Common.View')}
        </p>
      )
    },
  },
  // {
  //   Header: 'NOTES',
  //   accessor: 'notes',
  //   Cell: (props: any) => {
  //     return (
  //       <p
  //         className={styles.blueLinkText}
  //         // onClick={() => {
  //         //   props?.onRowClick(props?.row?.original);
  //         // }}
  //       >
  //         View
  //       </p>
  //     );
  //   },
  // },
  {
    Header: t('RcptTableHeader.STATUS'),
    accessor: 'result_status',
  },
]
