import { createSlice } from '@reduxjs/toolkit'
import { IPharmacy } from '../../../interfaces/apiInterface'
import {
  addPharmacyOrder,
  adjustmentFormUpdateApi,
  deletePharmacyOnholdData,
  editPharmacyOrder,
  getAllPharmacyOnholdData,
  getPharmacyOutPatientDetail,
} from './pharmacyAsyncActions'

export const initialState: IPharmacy = {
  isLoading: false,
  onholdData: [],
  isFormLoading: false,
  itemOrderData: [],
  addedOrder: {},
  outPatientDetail: {},
  editOrderDetail: {},
}

export const PharmacySlice = createSlice({
  name: 'pharmacy',
  initialState,
  reducers: {
    setItemOrderData: (state, action) => {
      state.itemOrderData = action.payload
    },
    addItemOrderData: (state, action) => {
      state.itemOrderData = [...state.itemOrderData, action.payload]
    },
    removeItemOrderData: (state, action) => {
      state.itemOrderData = state.itemOrderData?.filter(
        (item: any) => item?.item_id !== action?.payload?.item_id
      )
    },
    clearOrderData: (state) => {
      state.isFormLoading = false
      state.itemOrderData = []
    },

    clearAllStates:(state)=>{
      state.isLoading= false
      state.onholdData= []
      state.isFormLoading= false
      state.itemOrderData= []
      state.addedOrder= {}
    }
  },
  extraReducers: (builder) => {
    // GET ALL PHARMACY ON HOLD DATA

    builder.addCase(getAllPharmacyOnholdData.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(getAllPharmacyOnholdData.fulfilled, (state, action) => {
      state.isLoading = false
      state.onholdData = action.payload?.data
    })
    builder.addCase(getAllPharmacyOnholdData.rejected, (state, err) => {
      state.isLoading = false
      state.onholdData = []
    })

    // DELETE PHARMACY ON HOLD DATA

    builder.addCase(deletePharmacyOnholdData.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(deletePharmacyOnholdData.fulfilled, (state) => {
      state.isLoading = false
    })
    builder.addCase(deletePharmacyOnholdData.rejected, (state, err) => {
      state.isLoading = false
    })

    // UPDATE ADJUSMENT FORM API

    builder.addCase(adjustmentFormUpdateApi.pending, (state) => {
      state.isFormLoading = true
    })
    builder.addCase(adjustmentFormUpdateApi.fulfilled, (state) => {
      state.isFormLoading = false
    })
    builder.addCase(adjustmentFormUpdateApi.rejected, (state, err) => {
      state.isFormLoading = false
    })
    // Add Pharmacy Order

    builder.addCase(addPharmacyOrder.pending, (state) => {
      state.isFormLoading = true
    })
    builder.addCase(addPharmacyOrder.fulfilled, (state, action) => {
      state.isFormLoading = false
      state.addedOrder = action.payload
    })
    builder.addCase(addPharmacyOrder.rejected, (state, err) => {
      state.isFormLoading = false
    })
    // Edit Pharmacy Order

    builder.addCase(editPharmacyOrder.pending, (state) => {
      state.isFormLoading = true
    })
    builder.addCase(editPharmacyOrder.fulfilled, (state, action) => {
      state.isFormLoading = false
      state.addedOrder = action.payload
    })
    builder.addCase(editPharmacyOrder.rejected, (state, err) => {
      state.isFormLoading = false
    })

    // Out-patient details

    builder.addCase(getPharmacyOutPatientDetail.pending, (state) => {
      state.isFormLoading = true
    })
    builder.addCase(getPharmacyOutPatientDetail.fulfilled, (state, action) => {
      state.isFormLoading = false
      state.outPatientDetail = action.payload
    })
    builder.addCase(getPharmacyOutPatientDetail.rejected, (state, err) => {
      state.isFormLoading = false
    })
  },
})

export const {
  setItemOrderData,
  addItemOrderData,
  removeItemOrderData,
  clearOrderData,
  clearAllStates
} = PharmacySlice.actions
export default PharmacySlice.reducer
