import {
  TEST_TYPE,
  TEST_NAME,
  PRICE,
  BILLABLE_TYPE,
  PRIORITY,
} from "../constants/diagnosisRequestConstant";
import { t } from "i18next";

export const diagnosisRequestValidators = {
  [TEST_TYPE]: {
    required: t("IPDRequest.SelectTypeValidation"),
  },
  [TEST_NAME]: {
    required: t("IPDRequest.SelectTestNameValidation"),
  },
  [PRICE]: {
    required: t("PurchaseInvoice.EnterPrice"),
    validate: {
      handleZero: (value: any) => {
        if (value <= 0) {
          return t("LabInvoice.value_validation");
        }
      },
    },
  },
  [BILLABLE_TYPE]: {
    required: t("DentistRequest.BillableType"),
  },
  [PRIORITY]: {
    required: t("IPDRequest.SelectPriority"),
  },
};
