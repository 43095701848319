import Button from '../../../components/common/button/Button'
import { Input } from '../../../components/common/input/input'
import Select from 'react-select'
import { DropdownIndicator } from '../../../components/common/dropdown-indicator/DropdownIndicator'
import TableV2 from '../../../components/common/table/tableV2/TableV2'
import { CrmSentHeaderData } from './data'
import { useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../../hooks'
import { getAllCreateNewForms } from '../../../redux/features/create-new-form/createNewFormAsynActions'
import { requestGenerator } from '../../../utils/payloadGenerator'
import { crmAdminSentAsync } from '../../../redux/features/crm-admin-store/crmAsyncActions'
import { useForm } from 'react-hook-form'
import Pagination from '../../../components/common/pagination/Pagination'
import { pageIndexArray } from '../../../utils/utils'
import { Label } from '../../../components/common/label'
import styles from '../styles.module.scss'
import Loader from '../../../components/common/spinner/Loader'
import { reactSelectStyle } from '../../../constants/data'
import { useTranslation } from 'react-i18next'

const CrmSent = () => {
  const { createNewFormData } = useAppSelector((state) => state.createNewForm)
  const { crmSentData, isLoading } = useAppSelector((state) => state.crmAdmin)
  const dispatch = useAppDispatch()
  const { t } = useTranslation()
  const [formValue, setFormValue] = useState<any>('')
  const [dataPerPage, setDataPerPage] = useState<number>(10)
  const [totalPage, setTotalPage] = useState<number>(0)
  const [pageIndex, setPageIndex] = useState<number>(1)
  const [totalData, setTotalData] = useState(0)
  const [tableHeaderData, setTableHeaderData] = useState<any>([])
  const pageIndexOptions = pageIndexArray(totalPage)
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    setValue,
  } = useForm()

  const formData = watch()

  useEffect(() => {
    let requestData = {
      search: '',
      page: 1,
      pageSize: 100,
    }
    dispatch(getAllCreateNewForms(requestGenerator(requestData)))
  }, [dispatch])

  const onSubmit = (items: any) => {
    let data = {
      page: pageIndex,
      pageSize: dataPerPage,
      form_id: formValue?.value,
      date:
        items?.dateFrom === '' && items?.dateTo === ''
          ? undefined
          : {
              from: items?.dateFrom === '' ? '' : items?.dateFrom,
              to: items?.dateTo === '' ? '' : items?.dateTo,
            },
    }
    dispatch(crmAdminSentAsync(requestGenerator(data))).then((res) => {
      setTotalPage(res.payload.lastPage)
      setTotalData(res.payload.total)
      let headerDataKey = Object.keys(res.payload.data[0]?.form_data)
      let filterKey = headerDataKey.filter((item: any) => item !== 'submit')
      let newHeader = filterKey?.map((item: any) => {
        return {
          Header: item.toUpperCase(),
          accessor: item,
          Cell: ({ row }: any) => {
            return (
              <div>
                {(row?.original?.form_data).hasOwnProperty(item)
                  ? row?.original?.form_data?.[item]
                  : '-'}
              </div>
            )
          },
        }
      })
      setTableHeaderData(CrmSentHeaderData.concat(newHeader))
    })
  }

  return (
    <>
      {isLoading && <Loader />}
      <div className={styles.admin}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className={styles.searchContainer}>
            <div className={styles.inputFieldContainer}>
              <Label
                labelText={t('Header.Forms') || 'Forms'}
                requiredField
                fontSize="16px"
              />
              <div className={styles.fieldAndErrorTxtContainer}>
                <Select
                  className={styles.selectInputField}
                  placeholder={t('CRMAdmin.SelectForms') || 'Select Forms'}
                  closeMenuOnSelect={true}
                  value={formValue}
                  components={{ DropdownIndicator }}
                  options={createNewFormData?.map((items: any) => ({
                    label: items?.name,
                    value: items?._id,
                  }))}
                  isSearchable={false}
                  maxMenuHeight={200}
                  onChange={(e: any) => {
                    setFormValue(e)
                    setValue('dateFrom', '')
                    setValue('dateTo', '')
                  }}
                  styles={reactSelectStyle}
                />
              </div>
            </div>
            <div
              style={{ flexBasis: '35%' }}
              className={styles.dateErrorContainer}
            >
              <Input
                type="date"
                labelText={t('ReportGenerator.Date_From') || 'Date From'}
                {...register('dateFrom')}
                showErrors={
                  formData['dateFrom'] === '' && formData['dateTo']?.length > 0
                }
                errorMessage={
                  t('ReportGenerator.Date_From_Validation') ||
                  'Please select date from'
                }
              />
            </div>
            <div
              style={{ flexBasis: '35%' }}
              className={styles.dateErrorContainer}
            >
              <Input
                type="date"
                labelText={t('ReportGenerator.Date_To') || 'Date To'}
                {...register('dateTo')}
                showErrors={
                  formData['dateTo'] === '' && formData['dateFrom']?.length > 0
                }
                errorMessage={
                  t('ReportGenerator.Date_To_Validation') ||
                  'Please select date to'
                }
              />
            </div>
          </div>
          <div className={styles.searchBtn}>
            <Button
              title={t('ServiceMaster.Search') || 'Search'}
              type="submit"
              disable={formValue?.value === undefined ? true : false}
            />
          </div>
        </form>
        {formValue?.value !== undefined && crmSentData?.length > 0 && (
          <TableV2
            tableHeaderData={tableHeaderData}
            tableRowData={crmSentData}
            active={false}
          />
        )}
        {totalData > 10 && (
          <Pagination
            setDataPerPage={setDataPerPage}
            pageIndexOptions={pageIndexOptions}
            pageIndex={pageIndex}
            setPageIndex={setPageIndex}
          />
        )}
      </div>
    </>
  )
}

export default CrmSent
