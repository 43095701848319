import { createSlice } from '@reduxjs/toolkit'
import { getAllProfitLossBalance } from './profitLossAsyncActions'

export interface IProfitLossBalance {
  isLoading: boolean
  allProfitLossBalanceData: []
  allProfitLossBalanceListDataObject: {}
  error: any
  isStatusUpdated: boolean
  addProfitAndLossAmt: number
}
export const initialState: IProfitLossBalance = {
  isLoading: false,
  allProfitLossBalanceData: [],
  allProfitLossBalanceListDataObject: {},
  isStatusUpdated: true,
  error: null,
  addProfitAndLossAmt: 0,
}

export const profitLossBalanceSlice = createSlice({
  name: 'profitLossBalance',
  initialState,
  reducers: {
    clearProfitLossBalanceData: (state) => {
      state.allProfitLossBalanceData = []
    },
    addProfitAndLossAmtData: (state, action) => {
      state.addProfitAndLossAmt = action.payload
    },
  },
  extraReducers(builder) {
    // GET ALL Trial Balance
    builder
      .addCase(getAllProfitLossBalance.pending, (state) => {
        state.isLoading = true
        state.isStatusUpdated = false
      })
      .addCase(getAllProfitLossBalance.fulfilled, (state, action) => {
        state.isLoading = false
        state.isStatusUpdated = false
        state.allProfitLossBalanceData = action.payload.data
        state.allProfitLossBalanceListDataObject = action.payload
      })
      .addCase(getAllProfitLossBalance.rejected, (state, error) => {
        state.isLoading = false
        state.error = error
        state.isStatusUpdated = false
      })
  },
})

export const { clearProfitLossBalanceData, addProfitAndLossAmtData } =
  profitLossBalanceSlice.actions
export default profitLossBalanceSlice.reducer
