import { t } from 'i18next'
import { TEMP_NAME, TEMP_TYPE } from './templateConstant'

export const templateValidators = {
  [TEMP_TYPE]: {
    required: t('DynamicTemplate.TempTypeVal'),
  },
  [TEMP_NAME]: {
    required: t('DynamicTemplate.TempNameVal'),
  },
}
