import React, { useState, useEffect } from 'react'
import styles from './PatientCharges.module.scss'
import Button from '../../../components/common/button/Button'
import TableV2 from '../../../components/common/table/tableV2/TableV2'
import { patientChargesHeaderData } from '../../../constants/table-data/patientIpdInvoiceTableData'
import { useAppDispatch, useAppSelector } from '../../../hooks'
import {
  getIpdInvoiceAllJobs,
  getIpdInvoiceTreatment,
  updatePatientIpdTreatment,
} from '../../../redux/features/ipd-invoice/ipdAsyncActions'
import { requestGenerator } from '../../../utils/payloadGenerator'
import {
  GET_ALL_IPD_INVOICE_JOBS,
  GET_IPD_INVOICE_TREATMENT,
} from '../../../constants/asyncActionsType'
import { useNavigate } from 'react-router-dom'
import {
  setChargesList,
  setFilteredChargesList,
  setServicesData,
} from '../../../redux/features/ipd-invoice/ipdInvoiceSlice'
import Loader from '../../../components/common/spinner/Loader'
import { useTranslation } from 'react-i18next'

const PatientCharges = () => {
  const [billStatus, setbillStatus] = useState('All')
  const { t } = useTranslation()
  const {
    patientIpdInfo,
    patientAllJobList,
    patientTreatmentList,
    treatmentBillStatusList,
    primaryChargesList,
    chargesFilterList,
    isLoading,
  } = useAppSelector((state) => state.ipdInvoice)

  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  // const flatData = patientAllJobList
  //   ?.flatMap(({ profile, createdAt }: any) =>
  //     profile?.map((x: any) => ({ ...x, createdAt }))
  //   )
  //   ?.flatMap(({ tests, createdAt }: any) =>
  //     tests?.map((y: any) => ({
  //       ...y,
  //       createdAt,
  //       date: createdAt,
  //       id: y?.test_no,
  //       name: y?.test_name,
  //       isBillable: y?.is_billable,
  //       price: y?.price,
  //       notes: y?.test_notes,
  //       type: "job_item",
  //     }))
  //   );

  // const filterFlatData = flatData?.filter((s: any) => s.isBillable === false);
  // const changeFilteredJobsArray = filterFlatData?.map((x: any) => ({
  //   ...x,
  //   isBillable: !x.isBillable,
  // }));

  // const mapData = patientTreatmentList?.data?.map((item: any) => ({
  //   ...item,
  //   name: item?.service_id?.name,
  //   isBillable: item?.billable,
  //   id: item?.sessionId,
  //   type: "treatment_item",
  // }));

  // const mergeTwoArrays: any =
  //   [...(changeFilteredJobsArray ?? []), ...(mapData ?? [])] ?? [];

  useEffect(() => {
    const flatData = patientAllJobList
      ?.flatMap(({ profile, createdAt }: any) =>
        profile?.map((x: any) => ({ ...x, createdAt }))
      )
      ?.flatMap(({ tests, createdAt }: any) =>
        tests?.map((y: any) => ({
          ...y,
          createdAt,
          date: createdAt,
          id: y?.test_no,
          name: y?.test_name,
          isBillable: y?.is_billable,
          price: y?.price,
          notes: y?.test_notes,
          type: 'job_item',
        }))
      )

    const filterFlatData = flatData?.filter((s: any) => s.isBillable === false)
    const changeFilteredJobsArray = filterFlatData?.map((x: any) => ({
      ...x,
      isBillable: !x.isBillable,
    }))

    const mapData = patientTreatmentList?.data?.map((item: any) => ({
      ...item,
      name: item?.service_id?.name,
      isBillable: item?.billable,
      id: item?.sessionId,
      type: 'treatment_item',
    }))

    const mergeTwoArrays: any =
      [...(changeFilteredJobsArray ?? []), ...(mapData ?? [])] ?? []

    dispatch(setChargesList(mergeTwoArrays))
  }, [patientAllJobList, patientTreatmentList])

  const [pageIndex, setPageIndex] = useState<number>(1)

  useEffect(() => {
    const treatmentPayload = {
      ipd_case_id: patientIpdInfo?.ipdData?._id,
      is_ipd_invoice_generate: false,
      insurance_plan_id: patientIpdInfo?.isIns
        ? patientIpdInfo?.ipdData?.insurance_plan_id
        : null,
      is_not_insurance: patientIpdInfo?.isIns,
    }

    const jobsPayload = {
      page: pageIndex,
      pageSize: 100,
      ipd_case_id: patientIpdInfo?.ipdData?._id,
      insurance_plan_id: patientIpdInfo?.isIns
        ? patientIpdInfo?.ipdData?.insurance_plan_id
        : null,
      is_not_insurance: patientIpdInfo?.isIns,
      is_ipd_invoice_generate: false,
    }

    dispatch(getIpdInvoiceTreatment(requestGenerator(treatmentPayload))).then(
      (e: any) => {
        if (e.type === `${GET_IPD_INVOICE_TREATMENT}/fulfilled`) {
          //   dispatch(setTreatmentServiceData(e?.payload?.data));
        }
      }
    )

    dispatch(getIpdInvoiceAllJobs(requestGenerator(jobsPayload))).then(
      (e: any) => {
        if (e.type === `${GET_ALL_IPD_INVOICE_JOBS}/fulfilled`) {
          //   dispatch(setAllJobsListData(e?.payload?.data));
        }
      }
    )
  }, [])

  const handleNavigate = () => {
    dispatch(setServicesData(primaryChargesList))
    dispatch(
      updatePatientIpdTreatment(
        requestGenerator({ treatments: treatmentBillStatusList })
      )
    )
    navigate('/ipd-invoice/services')
  }

  const handleRadioChange = (e: any) => {
    const { value } = e.target
    setbillStatus(value)
    dispatch(setFilteredChargesList(value))
  }

  return (
    <div className={styles.mainContainer}>
      {isLoading && <Loader />}

      <div className={styles.checkboxContainer}>
        <div className={styles.fieldErrorContainer}>
          <label htmlFor="billable" className={styles.radioLabel}>
            <input
              className={styles.radioInput}
              id="billable"
              type="radio"
              value="Billable"
              checked={billStatus === 'Billable'}
              onChange={handleRadioChange}
            />
            <span className={styles.customRadio} />
            {t('RequestDiagnosis.Billable')}
          </label>

          <label htmlFor="non-billable" className={styles.radioLabel}>
            <input
              className={styles.radioInput}
              type="radio"
              id="non-billable"
              value="Non-billable"
              checked={billStatus === 'Non-billable'}
              onChange={handleRadioChange}
            />
            <span className={styles.customRadio} />
            {t('IPDInvoice.Non_billable')}
          </label>

          <label htmlFor="all" className={styles.radioLabel}>
            <input
              className={styles.radioInput}
              type="radio"
              id="all"
              value="All"
              checked={billStatus === 'All'}
              onChange={handleRadioChange}
            />
            <span className={styles.customRadio} />
            {t('IPDBooking.All')}
          </label>
        </div>
      </div>

      <div className={styles.consumbleTableContainer}>
        <TableV2
          tableHeaderData={patientChargesHeaderData}
          tableRowData={chargesFilterList.length > 0 ? chargesFilterList : []}
        />
      </div>

      <div className={styles.buttonContainer}>
        <Button
          type="submit"
          title={t('DiagnosisForm.SaveAndNext') || 'Save & Next'}
          customClass={styles.nextButton}
          handleClick={handleNavigate}
        />

        <Button
          title={t('Common.Back') || 'Back'}
          type="button"
          customClass={styles.backButton}
          handleClick={() => navigate(`/ipd-invoice/consumble`)}
        />
      </div>
    </div>
  )
}

export default PatientCharges
