import moment from 'moment'
import {
  setOngoingTreatmentPlanPopup,
  updateTreatmentPlansFromtable,
} from '../../../../redux/features/treatmentPlans/treatmentPlansSlice'
import { useAppDispatch, useAppSelector } from '../../../../hooks'
import {
  DiagnosCheckIcon,
  DiagnosPauseIcon,
} from '../../../../components/common/svg-components'
import styles from './ongoingtreatmentplsnpopup.module.scss'
import { uuid } from '../../../../utils/utils'
import { t } from 'i18next'

export const ongoingTreatmentPlanPopupHeaderData: any = [
  {
    Header: t('RecentHistory.Date'),
    accessor: (row: any) => {
      return moment(row?.createdAt).format('DD-MMM-YYYY') ?? '-'
    },
  },
  {
    Header: t('DentistDiagnosis.DiagnosisNo'),
    accessor: (row: any) => {
      return row?.diagnosis_number ?? '-'
    },
  },
  {
    Header: t('AptDetails.Status'),
    accessor: (row: any) => {
      return row?.diagnosis_treatments_status ?? '-'
    },
  },
  {
    Header: t('TreatmentTableHeader.SERVICES'),
    Cell: (props: any) => {
      return (
        <p
          className={styles.blueLinkText}
          onClick={() => props.onRowClick(props.row.original)}
        >
          View
        </p>
      )
    },
  },
  {
    Header: t('AllAttachmentHeader.SELECT'),
    Cell: (props: any) => {
      const dispatch = useAppDispatch()
      var { treatmentPlanTableData } = useAppSelector(
        (state) => state.treatmentPlans
      )

      const handleAdd = () => {
        let taken: any[] = []

        let diagnosis_treatments =
          props.row.original.diagnosis_treatments.length > 0 &&
          props.row.original.diagnosis_treatments.filter(
            (fItem: any) => fItem.status === 'new'
          ).length > 0
            ? props.row.original.diagnosis_treatments
                .filter((fItem: any) => fItem.status === 'new')
                .map((item: any) => {
                  let obj = Object.assign({}, item)
                  obj._id = uuid()
                  obj.type = 'TREATMENT'
                  obj.age_group =
                    item.age_group === 'adult'
                      ? {
                          label: 'Adult',
                          value: 'adult',
                        }
                      : {
                          label: 'Kids',
                          value: 'kids',
                        }
                  obj.procedure_id = item.procedure_id._id
                  obj.procedure_name = item.procedure_id.name
                  obj.service_id = item.service_id ? item.service_id._id : null
                  obj.service_name = item.service_id ? item.service_id.name : ''
                  obj.insurance_plan_id = item.insurance_plan_id
                    ? item.insurance_plan_id._id
                    : null
                  obj.insurance_plan_name = item.insurance_plan_id
                    ? item.insurance_plan_id.insurance_plan_name
                    : ''
                  obj.attented_by_id = item.attended_by_id._id
                  obj.doctor_id = props.row.original.doctor_id
                  obj.doctor_name = props.row.original.doctor_name
                  obj.selected_tooths = item.selected_tooths
                    ? item.selected_tooths.map((t: any) => {
                        let str1 =
                          t.tooth_id._id +
                          '-' +
                          item.procedure_id._id +
                          '-' +
                          moment(item.createdAt).format('YYYY-MM-DD')
                        taken.push(str1)
                        let tooth = {
                          original_images: t.original_images,
                          treatment_images: t.treatment_images,
                          display_tooth_number:
                            item.age_group === 'adult'
                              ? t.tooth_number
                              : 40 + t.tooth_number,
                          ...t.tooth_id,
                        }
                        return tooth
                      })
                    : []
                  obj.last_treatment_done_at = new Date(item.createdAt)
                  obj.show_delete_icon = item.billed === 'billed' ? false : true
                  obj.from_diagnosis_id = props.row.original._id
                  obj.from_entry_id = item._id
                  return obj
                })
            : []

        let newArr: any = []
        newArr = [...diagnosis_treatments]

        diagnosis_treatments.slice().map((item: any) => {
          let item_not_exist = treatmentPlanTableData.find((trItem: any) => {
            let condition1 =
              trItem.from_diagnosis_id &&
              trItem.from_entry_id &&
              trItem.from_diagnosis_id === item.from_diagnosis_id &&
              trItem.from_entry_id === item.from_entry_id
                ? true
                : false
            if (condition1) {
              return false
            }
            let existing_tooths = trItem.selected_tooths
              ? trItem.selected_tooths.map(
                  (tooth: any) => tooth.display_tooth_number
                )
              : []
            let current_tooths = item.selected_tooths
              ? item.selected_tooths.map(
                  (tooth: any) => tooth.display_tooth_number
                )
              : []
            let condition2 =
              trItem.procedure_id === item.procedure_id &&
              trItem.service_id === item.service_id
            let condition3 = current_tooths.every((tooth_number: number) =>
              existing_tooths.includes(tooth_number)
            )
            if (condition2 && condition3) {
              return false
            } else {
              return true
            }
          })
          if (item_not_exist) {
            newArr.push(item_not_exist)
          }
        })
        dispatch(updateTreatmentPlansFromtable(newArr))
        dispatch(setOngoingTreatmentPlanPopup(false))
      }

      let diagnosis_used =
        treatmentPlanTableData.filter(
          (tri: any) => tri.from_diagnosis_id === props.row.original._id
        ).length > 0
          ? true
          : false

      const getIcon = (diagnosis_treatments_status: any): any => {
        if (diagnosis_treatments_status === 'none') {
          return ' - '
        } else if (diagnosis_treatments_status === 'ongoing') {
          return diagnosis_used ? (
            <>
              <DiagnosPauseIcon fillColor="#FFA009" />
            </>
          ) : (
            <>
              <DiagnosPauseIcon
                fillColor="#273fad"
                handleClick={() => handleAdd()}
              />
            </>
          )
        } else {
          return (
            <>
              <DiagnosCheckIcon fillColor="#02BF90" />
            </>
          )
        }
      }
      let diagnosis_treatments_status: any =
        props.row.original.diagnosis_treatments_status
      return <>{getIcon(diagnosis_treatments_status)}</>
    },
  },
]
