import { FC, useEffect, useState } from 'react'
import styles from './directpopopup.module.scss'
import { directPoPoupHeaderData } from '../../../constants/table-data/directPoPopupTableData'
import Button from '../../../components/common/button/Button'
import TableV3 from '../../../components/common/table/tableV3/TableV3'
import {
  Column,
  TableOptions,
  useGlobalFilter,
  useSortBy,
  useTable,
} from 'react-table'
import SmartSearch from '../../../components/common/smart-search/SmartSearch'
import { useAppDispatch, useAppSelector } from '../../../hooks'
import { requestGenerator } from '../../../utils/payloadGenerator'
import { getItemFromStore } from '../../../redux/features/inventory-request/inventoryRequestAsyncActions'
import Loader from '../../../components/common/spinner/Loader'
import Pagination from '../../../components/common/pagination/Pagination'
import { trimValue } from '../../../utils/utils'
import GlobalSearch from '../../../components/common/global-search-component/page'
import { useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
interface IDirectPoopup {
  closeModal?: () => void
}

const DirectPoPopup: FC<IDirectPoopup> = ({ closeModal }) => {
  const { isLoading, getItemWithStoreData } = useAppSelector(
    (state) => state.inventoryRequest
  )

  const location = useLocation()
  const { t } = useTranslation()
  const { branchData } = useAppSelector((state) => state.login)

  const [totalPage, setTotalPage] = useState<any>(0)
  const [dataPerPage, setDataPerPage] = useState<number>(10)
  const [pageIndex, setPageIndex] = useState<number>(1)
  const [totalData, setTotalData] = useState(0)
  const [searchTest, setSearchTest] = useState('')
  const [disable, setDisable] = useState(true)

  const pageIndexArray = () => {
    let pageIndexOptions = []
    for (let i = 1; i <= Math.ceil(totalPage); i++) {
      pageIndexOptions.push(i)
    }
    return pageIndexOptions
  }
  const pageIndexOptions = pageIndexArray()

  const dispatch = useAppDispatch()

  let requestData = {
    store_id:
      location?.pathname === '/mainstore'
        ? branchData?.main_store?.[0]?._id
        : branchData?.pharmacy_store?.[0]?._id,
    type: location?.pathname === '/mainstore' ? 'GENERAL' : 'MEDICINES',
    search: searchTest,
    page: pageIndex,
    pageSize: dataPerPage,
  }

  useEffect(() => {
    dispatch(getItemFromStore(requestGenerator(requestData))).then((result) => {
      setTotalPage(result.payload.lastPage)
      setTotalData(result.payload.total)
    })
  }, [dispatch, pageIndex, dataPerPage])

  const handleSearch = () => {
    setPageIndex(1)
    const updatedPayload = { ...requestData, page: 1 }
    if (searchTest?.length) {
      dispatch(getItemFromStore(requestGenerator(updatedPayload))).then(
        (result) => {
          setTotalPage(result.payload.lastPage)
          setTotalData(result.payload.total)
          setDisable(false)
        }
      )
    }
  }

  const data: any = getItemWithStoreData ?? []
  const columns: Column<any>[] = directPoPoupHeaderData ?? []
  const options: TableOptions<any> = {
    data,
    columns,
  }

  const {
    state,
    // @ts-ignore
    setGlobalFilter,
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable(options, useGlobalFilter, useSortBy)
  // @ts-ignore
  const { globalFilter } = state

  return (
    <>
      {isLoading && <Loader />}
      {/* <div className={styles.searchContainer}>
        <span className={styles.text}>Item Name</span>
      </div> */}
      <GlobalSearch
        placeholder={t('InventoryItemTable.Search') || 'Search by Item Name'}
        value={searchTest}
        onKeyDown={(e: any) => {
          if (e?.key === 'Enter') {
            handleSearch()
          }
        }}
        onChange={(e: any) => {
          trimValue(e)
          setSearchTest(e.target.value)
          if (searchTest !== '' && e.target.value === '') {
            setPageIndex(1)
            setDataPerPage(10)
            setGlobalFilter('')
            let requestData = {
              search: '',
              page: 1,
              pageSize: 10,
              store_id:
                location?.pathname === '/mainstore'
                  ? branchData?.main_store?.[0]?._id
                  : branchData?.pharmacy_store?.[0]?._id,
              type:
                location?.pathname === '/mainstore' ? 'GENERAL' : 'MEDICINES',
            }
            dispatch(getItemFromStore(requestGenerator(requestData))).then(
              (result) => {
                setTotalPage(result.payload.lastPage)
                setTotalData(result.payload.total)
                setDisable(true)
              }
            )
          }
        }}
        handleSearch={() => handleSearch()}
      >
        <SmartSearch
          placeHolder={t('Common.Smart Search') || 'Smart Search'}
          globalFilter={globalFilter}
          setGlobalFilter={setGlobalFilter}
          isDisable={disable}
        />
      </GlobalSearch>

      <div className={styles.tablestyle}>
        <TableV3
          getTableProps={getTableProps}
          getTableBodyProps={getTableBodyProps}
          headerGroups={headerGroups}
          rows={rows}
          prepareRow={prepareRow}
        />
      </div>
      {totalData > 10 && (
        <Pagination
          setDataPerPage={setDataPerPage}
          pageIndexOptions={pageIndexOptions}
          pageIndex={pageIndex}
          setPageIndex={setPageIndex}
          pageSize={dataPerPage}
        />
      )}
      <div className={styles.btn}>
        <Button
          title={t('MainStore.Submit PO') || 'Submit PO'}
          handleClick={closeModal}
        />
      </div>
    </>
  )
}

export default DirectPoPopup
