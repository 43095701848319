import { createSlice } from '@reduxjs/toolkit';
import { ILeadPool } from '../../../interfaces/apiInterface';
import {
  assignInternalLead,
  createExternalLead,
  createInternalLead,
  getAllAssignee,
  getAllLeadCountInfo,
  getAllLeadPool,
  getInternalLead,
} from './callCenterAsyncActions';

export const initialState: ILeadPool = {
  isLoading: false,
  error: null,
  allLeadData: [],
  internalLeadInfo: {},
  internalLeadData: [],
  externalLeadInfo: {},
  allUserData: [],
  allLeadCountInfo: {},
  totalLeadCountInfo: {},
};

export const CallCenterSlice = createSlice({
  name: 'callCenter',
  initialState,
  reducers: {
    clearAllLeadData: (state) => {
      state.isLoading = true;
      state.allLeadData = [];
    },
  },
  extraReducers: (builder) => {
    //GET ALL LEAD POOL

    builder.addCase(getAllLeadPool.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getAllLeadPool.fulfilled, (state, action) => {
      state.isLoading = false;
      state.allLeadData = action.payload?.data;
    });
    builder.addCase(getAllLeadPool.rejected, (state, error) => {
      state.isLoading = false;
      state.error = error;
    });

    //CREATE INTERNAL LEAD

    builder.addCase(createInternalLead.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(createInternalLead.fulfilled, (state, action) => {
      state.isLoading = false;
      state.internalLeadInfo = action.payload;
    });
    builder.addCase(createInternalLead.rejected, (state, error) => {
      state.isLoading = false;
      state.error = error;
    });

    // GET INTERNAL LEAD

    builder.addCase(getInternalLead.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getInternalLead.fulfilled, (state, action) => {
      state.isLoading = false;
      state.internalLeadData = action.payload?.data;
      state.totalLeadCountInfo = action.payload;
    });
    builder.addCase(getInternalLead.rejected, (state, error) => {
      state.isLoading = false;
      state.error = error;
    });

    //ASSIGN INTERNAL LEAD

    builder.addCase(assignInternalLead.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(assignInternalLead.fulfilled, (state, action) => {
      state.isLoading = false;
    });
    builder.addCase(assignInternalLead.rejected, (state, error) => {
      state.isLoading = false;
      state.error = error;
    });

    //CREATE EXTERNAL LEAD

    builder.addCase(createExternalLead.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(createExternalLead.fulfilled, (state, action) => {
      state.isLoading = false;
      state.externalLeadInfo = action.payload;
    });
    builder.addCase(createExternalLead.rejected, (state, error) => {
      state.isLoading = false;
      state.error = error;
    });

    // GET ALL ASSIGNEE

    builder.addCase(getAllAssignee.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getAllAssignee.fulfilled, (state, action) => {
      state.isLoading = false;
      state.allUserData = action.payload;
    });
    builder.addCase(getAllAssignee.rejected, (state, error) => {
      state.isLoading = false;
      state.error = error;
    });

    // GET ALL LEAD COUNT INFO

    builder.addCase(getAllLeadCountInfo.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getAllLeadCountInfo.fulfilled, (state, action) => {
      state.isLoading = false;
      state.allLeadCountInfo = action.payload;
    });
    builder.addCase(getAllLeadCountInfo.rejected, (state, error) => {
      state.isLoading = false;
      state.error = error;
    });
  },
});

export const { clearAllLeadData } = CallCenterSlice.actions;
export default CallCenterSlice.reducer;
