import {
  ATTACHEMENTS,
  DEPARTMENT,
  EMPLOYEE,
  EMPLOYEE_PROFILE_PIC,
  END_DATE,
  FILE,
  LEAVE_CALCULATION,
  LEAVE_TYPE,
  NAME,
  PAID_LEAVES,
  REASON,
  SELECTED_DAYS,
  START_DATE,
  STATUS,
  UNPAID_LEAVES,
  _id,
  emp_id,
  name_en,
} from '../../../constants/applyLeaveConstants'
import { t } from 'i18next'

export const applyLeaveValidator = {
  [STATUS]: {
    required: t('LeaveManagement.status_validation'),
  },
  [NAME]: {
    required: t('LeaveManagement.status_validation'),
  },
  [DEPARTMENT]: {
    required: t('LeaveManagement.status_validation'),
  },
  [_id]: {
    required: t('LeaveManagement.id_validation'),
  },
  [emp_id]: {
    required: t('LeaveManagement.id_validation'),
  },
  [name_en]: {
    required: t('LeaveManagement.id_validation'),
  },
  [EMPLOYEE]: {
    required: t('HrDocument.EmpVal'),
  },
  [LEAVE_TYPE]: {
    required: t('LeaveManagement.leave_type_validation'),
  },
  [LEAVE_CALCULATION]: {
    required: t('LeaveManagement.leave_calculation_validation'),
  },
  [START_DATE]: {
    required: t('BookingConfirmationValidators.RECURRING_START_DATE'),
  },
  [END_DATE]: {
    required: t('Campaigns.end_date_validation'),
  },
  [SELECTED_DAYS]: {
    required: t('LeaveManagement.Days_validation'),
  },
  [PAID_LEAVES]: {
    required: t('LeaveManagement.paid_leave_validation'),
  },
  [UNPAID_LEAVES]: {
    required: t('LeaveManagement.unpaid_leave_validation'),
  },
  [REASON]: {
    required: t('LeaveManagement.reason_validation'),
  },
  [FILE]: {
    required: t('ManageStaff.User_Photo_validation'),
  },
  [EMPLOYEE_PROFILE_PIC]: {
    required: t('ManageStaff.User_Photo_validation'),
  },
  [ATTACHEMENTS]: {
    required: '',
  },
}
