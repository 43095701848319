import { FC } from 'react'
import { Cols } from '../../employees/employeeInterface'
import {
  Column,
  TableOptions,
  useGlobalFilter,
  useSortBy,
  useTable,
} from 'react-table'
import TableV3 from '../../../components/common/table/tableV3/TableV3'
import { applyLeaveTableHeaderData } from './applyLeaveTableData'

interface IDocumnets {
  tableRowData?: any
}

const LeaveTable: FC<IDocumnets> = ({ tableRowData }) => {
  // Redux state
  // For Table

  const data: Cols[] = tableRowData
  const columns: Column<Cols>[] = applyLeaveTableHeaderData
  const options: TableOptions<Cols> = {
    data,
    columns,
  }
  const {
    state,
    // @ts-ignore
    setGlobalFilter,
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable(options, useGlobalFilter, useSortBy)
  return (
    <>
      <TableV3
        getTableProps={getTableProps}
        getTableBodyProps={getTableBodyProps}
        headerGroups={headerGroups}
        rows={rows}
        prepareRow={prepareRow}
        active={false}
      />
    </>
  )
}
export default LeaveTable
