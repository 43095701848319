import {
  ADMIN_AGENT_GET_DATA_TYPE,
  CALL_CENTER_AGENT_COUNT_TYPE,
  CALL_CENTER_AGENT_LEAD_TYPE,
  CALL_CENTER_API,
  CALL_CENTER_FEEDBACK_FORM_TYPE,
  GET_ALL_AGENT,
} from "../../../constants/asyncActionsType";
import createAsyncThunkForSlice from "../../../utils/utils";
import { adminAgentGetData, agentLeadFeedbackForm, callCenterAgentCounts, callCenterAgentLeads, callCenterApiToken, getAllAgentList } from "./agentCrud";

export const getAllAgent = createAsyncThunkForSlice(
  GET_ALL_AGENT,
  getAllAgentList,
  {
    isToast: false,
  }
);

export const callingApi = createAsyncThunkForSlice(
  CALL_CENTER_API,
  callCenterApiToken
);

export const callCenterAgentLead = createAsyncThunkForSlice(
  CALL_CENTER_AGENT_LEAD_TYPE,
  callCenterAgentLeads
);

export const callCenterAgentCount = createAsyncThunkForSlice(
  CALL_CENTER_AGENT_COUNT_TYPE,
  callCenterAgentCounts
);

export const callCenterFeedbackForm = createAsyncThunkForSlice(
  CALL_CENTER_FEEDBACK_FORM_TYPE,
  agentLeadFeedbackForm,
  {
    isToast: true,
  }
);

export const agentAdminGetData = createAsyncThunkForSlice(
  ADMIN_AGENT_GET_DATA_TYPE,
  adminAgentGetData,
);