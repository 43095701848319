import { TODO_TASK_NAME, TODO_TASK_PRIORITY } from "../constants/constant";
import { t } from "i18next";

export interface IaddTodoValidators {
  [TODO_TASK_NAME]: {
    required: string;
  };
  [TODO_TASK_PRIORITY]: {
    required: string;
  };
}
export const addTodoValidators: IaddTodoValidators = {
  [TODO_TASK_NAME]: {
    required: t("DoctorDashbord.TaskName_Validation"),
  },
  [TODO_TASK_PRIORITY]: {
    required: t("DoctorDashbord.TaskPriority_Validation"),
  },
};
