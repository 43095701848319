import { EditIcon } from '../../components/common/svg-components'
import ToggleSwitchV2 from '../../components/common/toggle-switch/ToggleSwitchV2'
import { useAppDispatch } from '../../hooks'
import { setMessage } from '../../redux/features/toast/toastSlice'
import {
  editInventoryUnits,
  getInventoryItemUnits,
} from '../../redux/features/unit-type/unittypeAsyncActions'
import { requestGenerator } from '../../utils/payloadGenerator'
import { EDIT_INVENTORY_ITEM_UNIT } from '../asyncActionsType'
import { success } from '../data'
import { t } from 'i18next'

export const unitTypeTableHeaderData: any = [
  {
    Header: t('ItemUnitMaster.BASE_UNIT'),
    Cell: ({ row, index }: any) => {
      return (
        <>
          <span>{row?.original?.base_unit_type_id?.value}</span>
        </>
      )
    },
  },
  {
    Header: t('ItemUnitMaster.CONV_UNIT'),
    Cell: ({ row, index }: any) => {
      return (
        <>
          <span>{row?.original?.mapped_unit_type_id?.value}</span>
        </>
      )
    },
  },

  {
    Header: t('MedicationHeader.QTY'),
    accessor: 'qty',
  },
  {
    Header: t('RcptTableHeader.STATUS'),
    Cell: ({ row }: any) => {
      const dispatch = useAppDispatch()
      const is_active = row?.original?.is_active

      const handleStatus = (itemRow: any) => {
        dispatch(
          editInventoryUnits(
            requestGenerator({
              id: itemRow?._id,
              is_active: !itemRow?.is_active,
            })
          )
        ).then((e) => {
          if (e.type === `${EDIT_INVENTORY_ITEM_UNIT}/fulfilled`) {
            dispatch(getInventoryItemUnits(requestGenerator({})))
            dispatch(
              setMessage({
                message: 'Status Updated Successfully',
                type: success,
              })
            )
          }
        })
      }

      return (
        <>
          <div>
            <ToggleSwitchV2
              isToggled={is_active}
              handleToggle={() => handleStatus(row?.original)}
            />
          </div>
        </>
      )
    },
  },

  {
    Header: t('DocUploadHeader.ACTION'),
    Cell: ({ row, onRowClick }: any) => {
      return (
        <>
          <div>
            <EditIcon handleClick={() => onRowClick(row?.original)} />
          </div>
        </>
      )
    },
  },
]
