// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.search_globalFilterSection__jWnDj {
  display: flex;
  align-items: center;
  position: relative;
}
.search_globalFilterSection__jWnDj .search_searchIconStyle__bwbhr {
  position: absolute;
  right: 22px;
  top: 16px;
}
.search_globalFilterSection__jWnDj .search_globalSearchInput__O4aYf {
  width: 285px;
  height: 50px;
  border: none;
  padding: 15px;
  border-radius: 10px;
  background: rgba(255, 255, 255, 0.7);
  box-shadow: 0px 10px 117px 14px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
}
.search_globalFilterSection__jWnDj .search_globalSearchInput__O4aYf:focus {
  outline: none !important;
}`, "",{"version":3,"sources":["webpack://./src/components/common/search/search.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,mBAAA;EACA,kBAAA;AACF;AACE;EACE,kBAAA;EACA,WAAA;EACA,SAAA;AACJ;AAEE;EACE,YAAA;EACA,YAAA;EACA,YAAA;EACA,aAAA;EACA,mBAAA;EACA,oCAAA;EACA,mDAAA;EACA,mBAAA;AAAJ;AAEI;EACE,wBAAA;AAAN","sourcesContent":[".globalFilterSection {\n  display: flex;\n  align-items: center;\n  position: relative;\n\n  .searchIconStyle {\n    position: absolute;\n    right: 22px;\n    top: 16px;\n  }\n\n  .globalSearchInput {\n    width: 285px;\n    height: 50px;\n    border: none;\n    padding: 15px;\n    border-radius: 10px;\n    background: rgba(255, 255, 255, 0.7);\n    box-shadow: 0px 10px 117px 14px rgba(0, 0, 0, 0.05);\n    border-radius: 10px;\n\n    &:focus {\n      outline: none !important;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"globalFilterSection": `search_globalFilterSection__jWnDj`,
	"searchIconStyle": `search_searchIconStyle__bwbhr`,
	"globalSearchInput": `search_globalSearchInput__O4aYf`
};
export default ___CSS_LOADER_EXPORT___;
