import { t } from "i18next";

export const ViewServicesTableData: any = [
  {
    Header:  t("TreatmentTableHeader.PLAN"),
    Cell: (props: any) => {
      return <>{props.row.original.treatmentPlanName ?? "-"}</>;
    },
  },
  {
    Header: t("LabRequest.Doctor_Name"),
    Cell: (props: any) => {
      return <>{props.row.original.doctor_id.doctor_name ?? ""}</>;
    },
  },
  {
    Header: t("TreatmentTableHeader.SESSIONS"),
    Cell: (props: any) => {
      return <>{props.row.original.sessions ?? ""}</>;
    },
  },
  {
    Header: t("TreatmentTableHeader.SESSIONS#"),
    Cell: (props: any) => {
      return <>{props.row.original.sessionsIndex ?? ""}</>;
    },
  },
  {
    Header: t("RcptTableHeader.STATUS"),
    Cell: (props: any) => {
      return (
        <div style={{ textTransform: "capitalize" }}>
          {props.row.original.status ?? ""}
        </div>
      );
    },
  },
  {
    Header: t("TreatmentTableHeader.ATTENDEDBY"),
    Cell: (props: any) => {
      return <>{props.row.original.attended_by_id.name ?? ""}</>;
    },
  },
];
