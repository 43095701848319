import { useAppDispatch, useAppSelector } from '../../hooks'
import { CrossIcon2 } from '../../components/common/svg-components'
import {
  removeUniqueMainStoreData,
  updateMainStoreData,
} from '../../redux/features/branch-store/branchStoreSlice'
import { setMessage } from '../../redux/features/toast/toastSlice'
import { disableArrowKey, blockInvalidCharacter } from '../../utils/utils'
import { failure } from '../../constants/data'
import { colors } from '../color'
import styles from '../../pages/branchstore/branchstore/branchstore.module.scss'
import { t } from 'i18next'

export const msRequestHeaderData: any = [
  {
    Header: t('INVTableHeader.REQID'),
    accessor: (row: any) => {
      return row?.req_no?.toString().padStart(6, '0')
    },
    Cell: (props: any) => {
      return (
        <>
          {props?.row?.original?.req_no ? (
            <span>
              {props?.row?.original?.req_no?.toString().padStart(6, '0')}
            </span>
          ) : (
            '-'
          )}
        </>
      )
    },
  },

  {
    Header: t('ViewInventory.ITEM NAME'),
    accessor: 'item_name',
  },

  {
    Header: t('INVTableHeader.REQ_QTY_MS'),
    disableSortBy: true,
    Cell: (props: any) => {
      const dispatch = useAppDispatch()
      const { selectedMainStoreData, selectedMainStoreUniqueData } =
        useAppSelector((state) => state.branchStore)
      const validateMainStoreQty = (mainStoreQty: string, item: any) => {
        if (Number(mainStoreQty) === undefined) {
          dispatch(
            setMessage({
              message: t('BranchStore.ValidMainStoreQTY'),
              type: failure,
            })
          )
          return {
            ...item,
            main_store_qty: Number(mainStoreQty),
          }
        } else if (mainStoreQty === '0') {
          dispatch(
            setMessage({
              message: t('BranchStore.ValidMainStoreQTY'),
              type: failure,
            })
          )
          return item
        } else if (Number(mainStoreQty) < 0) {
          dispatch(
            setMessage({
              message: t('BranchStore.ValidMainStoreQTY'),
              type: failure,
            })
          )
          return item
        } else {
          return {
            ...item,
            main_store_qty: Number(mainStoreQty),
          }
        }
      }
      const handleChange = (e: any) => {
        const mainStoreQty = e.target.value
        let tempArr: any = []
        tempArr = selectedMainStoreUniqueData?.map(
          (item: any, index: number) => {
            if (item?._id === props?.row?.original?._id) {
              return validateMainStoreQty(mainStoreQty, item)
            } else {
              return item
            }
          }
        )
        dispatch(updateMainStoreData(tempArr))
      }
      return (
        <input
          type="number"
          value={
            selectedMainStoreUniqueData[props.row.index]?.main_store_qty || ''
          }
          // placeholder="Text"
          className={styles.branchStoreQtyField}
          onChange={handleChange}
          onKeyDown={(e: any) => {
            disableArrowKey(e)
            blockInvalidCharacter(e)
          }}
          onWheel={(e: any) => {
            e.target.blur()
          }}
        />
      )
    },
  },
  {
    Header: t('InventoryItemTable.UNIT_TYPE'),
    accessor: 'req_unit_type.value',
  },

  {
    Header: t('INVTableHeader.Status'),
    disableSortBy: true,

    Cell: (props: any) => {
      return (
        <>
          <p>{t('OngoingClaims.Initiated')}</p>
        </>
      )
    },
  },
  {
    Header: ' ',
    Cell: (props: any) => {
      const dispatch = useAppDispatch()
      return (
        <>
          <div>
            <CrossIcon2
              fillColor1={colors.red1}
              handleClick={() => {
                dispatch(removeUniqueMainStoreData(props?.row?.original))
              }}
            />
          </div>
        </>
      )
    },
  },
]
