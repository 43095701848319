import {
  ADD_ATTACHMENT_ID,
  DEPT,
  SPECIALITY,
  SPECIALITY_IMG,
} from '../constants/constant'
import { t } from 'i18next'

export interface ISpecialityValidators {
  [DEPT]: {
    required: string
  }
  [SPECIALITY]: {
    required: string
  }
  [SPECIALITY_IMG]: {
    required: string
  }
  [ADD_ATTACHMENT_ID]: {
    required: string
  }
}

export const specialityValidators = {
  [DEPT]: {
    required: t('DepartmentSetup.Dept validation'),
  },
  [SPECIALITY]: {
    required: t('DepartmentSetup.Specialty Name validation'),
  },
  [SPECIALITY_IMG]: {
    required: t('DepartmentSetup.Specialty Photo validation'),
    validate: {
      fileSize: (file: FileList) => {
        return (
          file[0].size < 2 * 1024 * 1024 ||
          t('UploadImageValidators.UploadImgSize')
        )
      },
      fileType: (file: FileList) =>
        /jpeg|png/.test(file[0].type) ||
        t('UploadImageValidators.UploadImgType'),
    },
  },
  [ADD_ATTACHMENT_ID]: {
    required: 'Please select Attachment',
    validate: {
      fileSize: (file: FileList) => {
        return (
          file[0].size < 2 * 1024 * 1024 ||
          t('UploadImageValidators.UploadImgSize')
        )
      },
      fileType: (file: FileList) =>
        /jpeg|png/.test(file[0].type) ||
        t('UploadImageValidators.UploadImgType'),
    },
  },
}
