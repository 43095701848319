import {
  RADIOLOGY_COST_PRICE,
  RADIOLOGY_SELL_PRICE,
  RADIOLOGY_SOURCE,
  RADIOLOGY_TEST_CATEGORY,
  RADIOLOGY_TEST_NAME,
  RADIOLOGY_TEST_TAT,
} from '../constants/constant'
import { t } from 'i18next'

export const radiologyCreateTestValidators = {
  [RADIOLOGY_TEST_NAME]: {
    required: t('LabConfiguration.Test_Validation'),
  },
  [RADIOLOGY_TEST_CATEGORY]: {
    required: t('LabConfiguration.Test_Category_Validators'),
  },
  [RADIOLOGY_TEST_TAT]: {
    required: t('LabConfiguration.TAT_Validation'),
  },
  [RADIOLOGY_SOURCE]: {
    required: t('ReportGenerator.source_validation'),
  },
  [RADIOLOGY_COST_PRICE]: {
    required: t('BedSetup.cost_price_placeholder'),
    pattern: {
      value: /^[0-9]{0,6}(?:\.[0-9]{1,3})?$/,
      message: t('InventoryItemTable.Quantity_Value_Validation'),
    },
  },
  [RADIOLOGY_SELL_PRICE]: {
    required: t('BedSetup.sell_price_placeholder'),
    pattern: {
      value: /^[0-9]{0,6}(?:\.[0-9]{1,3})?$/,
      message: t('InventoryItemTable.Quantity_Value_Validation'),
    },
  },
}
