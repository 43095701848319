import axios from "axios";
import { IAPIPayload } from "../../../interfaces/apiInterface";
import {
  ADMIN_AGENT_GET_DATA,
  CALL_CENTER_AGENT_COUNTS,
  CALL_CENTER_AGENT_LEADS,
  CALL_CENTER_FEEDBACK_FORM,
  CALL_TOKEN,
  GET_ALL_ADMIN_AGENTS,
} from "../../../config/config";

export const getAllAgentList = (data: IAPIPayload) => {
  return axios.post(GET_ALL_ADMIN_AGENTS, data);
};

export const callCenterApiToken = (data: IAPIPayload) => {
  return axios.post(CALL_TOKEN, data);
};

export const callCenterAgentLeads = (data: IAPIPayload) => {
  return axios.post(CALL_CENTER_AGENT_LEADS, data);
};

export const callCenterAgentCounts = (data: IAPIPayload) => {
  return axios.post(CALL_CENTER_AGENT_COUNTS, data);
};

export const agentLeadFeedbackForm = (data: IAPIPayload) => {
  return axios.post(CALL_CENTER_FEEDBACK_FORM, data);
};

export const adminAgentGetData = (data: IAPIPayload) => {
  return axios.post(ADMIN_AGENT_GET_DATA, data);
};
