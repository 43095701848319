export const customSelectStyles = {
  control: (provided: any) => ({
    ...provided,
    height: '42px',
    boxShadow: 'none',
    cursor: 'pointer',
  }),
  indicatorSeparator: () => ({
    display: 'none',
    color: '#797979',
  }),
  dropdownIndicator: (provided: any, state: any) => ({
    ...provided,
    color: '#797979',
    transform: state.selectProps.menuIsOpen ? 'rotate(180deg)' : null,
  }),
  clearIndicator: (provided: any) => ({
    ...provided,
    color: '#797979',
    display: 'none',
  }),
  menuList: (base: any) => ({
    ...base,

    '::-webkit-scrollbar': {
      width: '4px',
      height: '0px',
    },
    '::-webkit-scrollbar-track': {
      background: '#f1f1f1',
    },
    '::-webkit-scrollbar-thumb': {
      background: '#a49e9e',
    },
    '::-webkit-scrollbar-thumb:hover': {
      background: '#a49e9e',
    },
  }),
};
