//Create Campaigns
export const CAMPAIGN_NAME = 'camp_name'
export const START_DATE = 'start_date'
export const END_DATE = 'end_date'
export const TARGET_AUDIENCE = 'target_audience'
export const NO_OF_TARGET_AUDIENCE = 'target_audience_no'
export const CAMPAIGN_STATUS = 'campaign_status'
export const GENDER = 'gender'
export const AGE_FROM = 'age_from'
export const AGE_TO = 'age_to'
export const NATIONALITY = 'nationality'
export const PIN_CODE = 'pincode'
export const TAGS_SOURCE = 'tags_or_sources'
export const EXTERNAL_SOURCE = 'external_sources'

//Assign Agent

export const AGENT_NAME = 'agent_id'
export const NO_OF_LEADS = 'no_of_leads'
