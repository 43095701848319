import styles from './comparePopup.module.scss'
import moment from 'moment'
import { t } from 'i18next'

// View Jobs Compare Popup Table
export const comparePopupTableHeaderData: any = [
  {
    Header: t('RcptTableHeader.DATE'),
    accessor: (row: any) => {
      return moment(row?.createdAt).format('DD-MMM-YYYY') ?? '-'
    },
  },
  {
    Header: t('ViewReports.TEST PROFILE'),
    Cell: (props: any) => {
      const profileData = props.row.original.profile
      return (
        <>
          {profileData?.map((s: any, id: number) =>
            s.isProfile ? <div key={id}>{s.profile_name}</div> : '-'
          )}
        </>
      )
    },
  },
  {
    Header: t('RcptTableHeader.RESULTS'),
    accessor: '_results',
    Cell: (props: any) => {
      return (
        <>
          <div className={styles.resultViewContainer}>
            <span className={styles.resultView} onClick={() => props.onClick()}>
              {' '}
              {t('Common.View')}
            </span>
            <label className={styles.checkboxContainer}>
              <input type="checkbox" className={styles.checkboxField} />
              <span className={styles.checkboxLabel}></span>
            </label>
          </div>
        </>
      )
    },
  },
]
