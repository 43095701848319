/* eslint-disable jsx-a11y/iframe-has-title */
/* eslint-disable jsx-a11y/img-redundant-alt */
import { FC, useEffect, useState } from 'react'
import { CloseIcon } from '../../../components/common/svg-components'
import Divider from '../../../components/common/divider/Divider'
import { colors } from '../../../constants/color'
import { getAllAttachs } from '../../../redux/features/document/documentAsyncActions'
import { useAppDispatch, useAppSelector } from '../../../hooks'
import { requestGenerator } from '../../../utils/payloadGenerator'
import Loader from '../../../components/common/spinner/Loader'
import Button from '../../../components/common/button/Button'
import styles from './filenamepopup.module.scss'
import { t } from 'i18next'
interface IPropsData {
  handleClose?: any
  popData?: string | any
}
const FilenamePopup: FC<IPropsData> = ({ handleClose, popData }) => {
  const dispatch = useAppDispatch()
  const { isLoading, allAttchsData } = useAppSelector((state) => state.document)

  const [indexImage, setIndexImage] = useState(0)

  useEffect(() => {
    let attachements = popData
    const requestData = {
      attachements: attachements,
    }
    dispatch(getAllAttachs(requestGenerator(requestData)))
  }, [dispatch])

  return (
    <>
      {isLoading && <Loader />}
      <div
        className={styles.actionSavePopupContainer}
        onClick={(e) => e.stopPropagation()}
      >
        <CloseIcon
          customClass={styles.closeIconStyle}
          fillColor={colors.green1}
          handleClick={() => handleClose()}
        />
        {/* <div className={styles.actionSaveContainer}>
          <p className={styles.title}>File Name</p>
          <Divider customClass={styles.dividerStyle} />

          <div className={styles.attachimg}>
            {allAttchsData?.map((s: any) => {
              return s?.includes("application/pdf") ||
                s?.includes("application/word") ? (
                <iframe
                  src={s}
                  title={
                    s?.includes("application/pdf")
                      ? "PDF Document"
                      : "WORD Document"
                  }
                  className={styles.pdfIframeStyle}
                />
              ) : (
                <img src={s} alt="attachments" />
              );
            })}
          </div>
        </div> */}

        <div className={styles.actionSaveContainer}>
          <p className={styles.title}>{t('HrDocument.File Name')}</p>
          <Divider customClass={styles.dividerStyle} />
          <div className={styles.sliders}>
            {typeof allAttchsData[indexImage] === 'string' &&
            allAttchsData[indexImage]?.includes('image') ? (
              <img
                src={allAttchsData[indexImage]}
                alt="image"
                className={styles.image}
              />
            ) : (
              <iframe src={allAttchsData[indexImage]} className={styles.doc} />
            )}
            <div>
              {allAttchsData?.length - 1 !== indexImage && (
                <Button
                  handleClick={() => setIndexImage(indexImage + 1)}
                  title={t('IPDInvoice.Next') || 'Next'}
                  customClass={styles.next}
                />
              )}
              {indexImage > 0 && (
                <Button
                  handleClick={() => setIndexImage(indexImage - 1)}
                  title={t('HrDocument.Previous') || 'Previous'}
                  customClass={styles.back}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
export default FilenamePopup
