import moment from 'moment'
import styles from '../style.module.scss'
import { t } from 'i18next'

export const ipdTestsData: any = [
  {
    Header: t('RcptTableHeader.DATE'),
    accessor: (row: any) => {
      return moment(row?.job_date).format('DD-MMM-YYYY') ?? '-'
    },
  },
  {
    Header: t('IPDHistory.TEST DEPT'),
    accessor: 'job_type',
  },
  {
    Header: t('ViewReports.TEST NAME'),
    Cell: (props: any) => {
      return (
        <p
          className={styles.blueLinkText}
          onClick={() => props.onClick(props.row.original.profile)}
        >
          {t('Common.View')}
        </p>
      )
    },
  },
  {
    Header: t('ViewAppointmentHeader.DOCTOR'),
    accessor: (row: any) => {
      return row?.doctor_name ?? '-'
    },
  },
  {
    Header: t('LabJob.RESULTS'),
    Cell: (props: any) => {
      return (
        <p
          className={styles.blueLinkText}
          onClick={() => props.onRowClick(props.row.original)}
        >
          {t('Common.View')}
        </p>
      )
    },
  },
]

export const ipdTestNameData: any = [
  {
    Header: t('LabJob.TEST_ID'),
    accessor: 'test_no',
  },
  {
    Header: t('LabJob.TESTS'),
    accessor: 'test_name',
  },
  {
    Header: t('InsurancePlan_Form.PRICE'),
    accessor: 'price',
  },
]

export const ipdTestResultsData: any = [
  {
    Header: t('ViewReports.TEST NAME'),
    accessor: (row: any) => {
      return row.test_name ?? '-'
    },
  },
  {
    Header: t('ViewReports.OBSERVATIONS'),
    accessor: (row: any) => {
      return row.result ?? '-'
    },
  },
  {
    Header: t('ViewReports.REMARKS'),
    accessor: (row: any) => {
      return row.remark ?? '-'
    },
  },
]
