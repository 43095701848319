import { FC, useEffect, useState } from 'react'
import { colors } from '../../../constants/color'
import Divider from '../../../components/common/divider/Divider'
import {
  CloseIcon,
  SearchIcon,
} from '../../../components/common/svg-components/index'
import { getVoucherLog } from '../../../redux/features/vouchers/vouchersAsynActions'
import { voucherLogTableHeaderData } from './voucherLogTableData'
import { useAppDispatch, useAppSelector } from '../../../hooks'
import { requestGenerator } from '../../../utils/payloadGenerator'
import Pagination from '../../../components/common/pagination/Pagination'
import styles from './voucherLogModal.module.scss'
import Loader from '../../../components/common/spinner/Loader'
import {
  Column,
  TableOptions,
  useGlobalFilter,
  useSortBy,
  useTable,
} from 'react-table'
import TableV3 from '../../../components/common/table/tableV3/TableV3'
import SmartSearch from '../../../components/common/smart-search/SmartSearch'
import Select from 'react-select'
import { voucherStatusFilterOptions } from '../../../constants/data'
import makeAnimated from 'react-select/animated'
import { reactDropdownStyles } from '../../../utils/utils'
import { t } from 'i18next'

interface IVoucherLogModal {
  handleClose: (e?: React.MouseEvent<HTMLElement>) => void
  isEditing?: boolean
  setIsEditing?: React.Dispatch<React.SetStateAction<boolean>>
  handleChildClick: any
}
export interface Cols {}
const VoucherLogModal: FC<IVoucherLogModal> = ({
  handleClose,
  setIsEditing,
  handleChildClick,
}) => {
  const dispatch = useAppDispatch()
  const [searchValue, setSearchValue] = useState<string>('')
  const [statusFilter, setStatusFilter] = useState({
    label: 'All status',
    value: '',
  })
  const [isSmartSearchDisable, setIsSmartSearchDisable] =
    useState<boolean>(true)
  const [dataPerPage, setDataPerPage] = useState<number>(10)
  const [totalPage, setTotalPage] = useState<number>(0)
  const [totalData, setTotalData] = useState(0)
  const [pageIndex, setPageIndex] = useState<number>(1)
  const animatedComponent = makeAnimated()

  const { isLoading, voucherLogData } = useAppSelector(
    (state) => state.vouchers
  )
  const data: Cols[] = voucherLogData ?? []
  const columns: Column<Cols>[] = voucherLogTableHeaderData ?? []
  const options: TableOptions<Cols> = { data, columns }
  const {
    state,
    // @ts-ignore
    setGlobalFilter,
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable(options, useGlobalFilter, useSortBy)
  // @ts-ignore
  const { globalFilter } = state

  //  API Calling for Get Data
  useEffect(() => {
    const requestData = {
      search: searchValue,
      is_active: true,
      page: pageIndex,
      pageSize: dataPerPage,
      status: statusFilter?.value,
    }
    dispatch(getVoucherLog(requestGenerator(requestData))).then((result) => {
      setTotalPage(result?.payload?.lastPage)
      setTotalData(result?.payload?.total)
    })
  }, [dispatch, pageIndex, dataPerPage])

  // Search and Filter
  const handleSearch = () => {
    setIsSmartSearchDisable(true)
    setPageIndex(1)
    let payloadData = {
      search: searchValue,
      page: 1,
      pageSize: dataPerPage,
      is_active: true,
      status: statusFilter?.value,
    }
    dispatch(getVoucherLog(requestGenerator(payloadData))).then((result) => {
      setIsSmartSearchDisable(false)
      setTotalPage(result.payload.lastPage)
      setTotalData(result.payload.total)
    })
  }
  // Pagination
  const pageIndexArray = () => {
    let pageIndexOptions = []
    for (let i = 1; i <= totalPage; i++) {
      pageIndexOptions.push(i)
    }
    return pageIndexOptions
  }
  const pageIndexOptions = pageIndexArray()

  return (
    <>
      {isLoading && <Loader />}
      <div
        className={styles.voucherLogModalContainer}
        onClick={(e) => e.stopPropagation()}
      >
        <CloseIcon
          customClass={styles.closeIconStyle}
          fillColor={colors.green1}
          handleClick={() => {
            handleClose()
            setIsEditing && setIsEditing(false)
          }}
        />
        <div className={styles.voucherLogContainer}>
          <p className={styles.title}>{t('Voucher.Voucher_Log')}</p>
          <Divider customClass={styles.dividerStyle} />
          {/* Search */}
          <div className={styles.searchFieldContainer}>
            <div className={styles.searchContainer}>
              <input
                className={styles.searchInput}
                type="text"
                placeholder={t('Voucher.Search') || 'Search By Voucher Number'}
                value={searchValue}
                onChange={(e) => {
                  setSearchValue(e.target.value)
                  setGlobalFilter('')
                  // setIsSmartSearchDisable(!e.target.value);
                  if (e.target.value.length === 0) {
                    let payloadData = {
                      search: '',
                      is_active: true,
                      page: pageIndex,
                      pageSize: dataPerPage,
                      status: statusFilter?.value,
                    }
                    dispatch(getVoucherLog(requestGenerator(payloadData))).then(
                      (result) => {
                        setTotalPage(result.payload.lastPage)
                        setTotalData(result.payload.total)
                      }
                    )
                  }
                }}
                onKeyDown={(e: any) => {
                  if (e.code === 'Space') {
                    e.preventDefault()
                  }
                  if (e?.key === 'Enter') {
                    handleSearch()
                  }
                }}
              />
              <Select
                className={styles.selectInputField}
                value={statusFilter}
                isClearable={true}
                options={voucherStatusFilterOptions}
                components={animatedComponent}
                maxMenuHeight={200}
                closeMenuOnSelect={true}
                placeholder={
                  <span className="selectPlaceholder">
                    {t('DoctorDiagnosis.Select Status')}
                  </span>
                }
                styles={reactDropdownStyles}
                onChange={(e: any) => {
                  setStatusFilter(e)
                }}
              />
              <div
                className={styles.searchButton}
                onClick={() => handleSearch()}
              >
                <SearchIcon fillColor={'#ffffff'} />
              </div>
              <SmartSearch
                placeHolder={t('Common.Smart Search') || 'Smart Search'}
                globalFilter={globalFilter}
                setGlobalFilter={setGlobalFilter}
                isDisable={isSmartSearchDisable}
                customClassInput={styles.smartSearchInput}
              />
            </div>
          </div>
          <div className={styles.tableContainer}>
            <TableV3
              getTableProps={getTableProps}
              getTableBodyProps={getTableBodyProps}
              headerGroups={headerGroups}
              rows={rows}
              // handleClick={handleViewBalancePopup}
              prepareRow={prepareRow}
              handleRowClick={(id: any) => handleChildClick(id)}
            />
          </div>
          {/* Pagination */}
          {!!(totalData > 10) && (
            <Pagination
              setDataPerPage={setDataPerPage}
              pageIndexOptions={pageIndexOptions}
              pageIndex={pageIndex}
              setPageIndex={setPageIndex}
            />
          )}
        </div>
      </div>
    </>
  )
}
export default VoucherLogModal
