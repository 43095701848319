import { FC } from 'react'
import { Outlet } from 'react-router-dom'
import FloatingBar from '../../../components/common/floatingbar/FloatingBar'
import { campaignsFloatingBarData } from '../../../constants/data'
import styles from './callcenterlayout.module.scss'
interface ICallCenterDashboardLayout {}
const CallCenterDashboardLayout: FC<ICallCenterDashboardLayout> = () => {
  return (
    <>
      <div className={styles.mainContainer}>
        <div className={styles.tabContainer}>
          <Outlet />
        </div>
        {/* <div className={styles.floatingBarContainer}>
          <FloatingBar floatingBarData={campaignsFloatingBarData}/>
        </div> */}
      </div>
    </>
  )
}
export default CallCenterDashboardLayout
