import React, { FC, useEffect } from 'react'
import styles from './AddItems.module.scss'
import { CloseIcon, SearchButton } from '../../svg-components'
import Divider from '../../divider/Divider'
import TableV2 from '../../table/tableV2/TableV2'
import Button from '../../button/Button'
import { purchaseInvoiceHeaderData } from '../../../../constants/table-data/purchaseInvoiceTableData'
import { colors } from '../../../../constants/color'
import { trimValue } from '../../../../utils/utils'
import Loader from '../../spinner/Loader'
import TableV3 from '../../table/tableV3/TableV3'
import { useTranslation } from 'react-i18next'

interface IInventoryItemsPopup {
  tableHeaderData?: any
  tableRowData?: any
  headerGroups?: any
  rows?: any
  getTableProps?: any
  getTableBodyProps?: any
  prepareRow?: any

  handleAllItemList?: any
  dataPerPage?: any
  pageIndex?: any
  searchValue?: any
  setSearchValue?: any
  handleSearch?: any
  activateSmartSearch?: any
  setGlobalFilter?: any
  isLoading?: any
  handleClose?: any
  children?: any
}

const AddItems: FC<IInventoryItemsPopup> = ({
  tableHeaderData,
  tableRowData,
  headerGroups,
  rows,
  getTableProps,
  getTableBodyProps,
  prepareRow,

  handleAllItemList,
  children,
  dataPerPage,
  pageIndex,
  searchValue,
  setSearchValue,
  handleSearch,
  activateSmartSearch,
  setGlobalFilter,
  isLoading,
  handleClose,
}) => {
  useEffect(handleAllItemList, [searchValue, dataPerPage, pageIndex])
  const { t } = useTranslation()
  return (
    <>
      {isLoading && <Loader />}
      <div
        className={styles.PurchaseInvoicePopupMainContainer}
        onClick={(e) => e.stopPropagation()}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            marginBottom: '25px',
          }}
        >
          <div
            style={{
              position: 'relative',
              display: 'flex',
              flexDirection: 'row',
            }}
          >
            <input
              type="text"
              className={styles.inputSearchContainer}
              placeholder={t('ServiceMaster.Search') || 'Search'}
              onKeyDown={(e: any) => {
                if (e?.key === 'Enter') {
                  handleSearch()
                }
              }}
              onChange={(e) => {
                trimValue(e)
                setSearchValue(e.target.value)
                // if (searchValue !== "" && e.target.value === "") {
                //   handleSearch();
                // }
                setGlobalFilter('')
              }}
            />

            <SearchButton
              handleClick={() => {
                if (!!searchValue) {
                  handleSearch()
                }
              }}
              customClass={styles.inputSearchButton}
            />
          </div>

          <div
            style={{
              position: 'relative',
              display: 'flex',
              flexDirection: 'row',
              marginLeft: '18px',
            }}
          >
            <input
              type="text"
              className={
                !activateSmartSearch
                  ? styles.inputSmartSearchContainer
                  : styles.inputSearchContainer
              }
              placeholder={t('Common.Smart Search') || 'Smart Search'}
              disabled={!activateSmartSearch}
              onChange={(e) => {
                trimValue(e)
                setGlobalFilter(e.target.value)
              }}
              // value={searchValue === '' ? searchValue : globalFilter}
            />
          </div>
        </div>

        <div className={styles.tableContainer}>
          <TableV3
            getTableProps={getTableProps}
            getTableBodyProps={getTableBodyProps}
            headerGroups={headerGroups}
            rows={rows}
            prepareRow={prepareRow}
          />
        </div>
        {children}
        <div className={styles.button}>
          <Button
            title={t('Common.Submit') || 'Submit'}
            handleClick={handleClose}
          />
        </div>
      </div>
    </>
  )
}

export default AddItems
