import {
  CREATE_TEMPLATE_TYPE,
  DELETE_TEMPLATE_TYPE,
  GET_TEMPLATE_BY_ID_TYPE,
  GET_TEMPLATE_TYPE,
  UPDATE_TEMPLATE_TYPE,
} from '../../../constants/asyncActionsType'
import createAsyncThunkForSlice from '../../../utils/utils'
import {
  createTemplate,
  deleteTemplate,
  getTemplateId,
  getTemplates,
  updateTemplate,
} from './dynamicTemplateCrud'

export const getAllTemplates = createAsyncThunkForSlice(
  GET_TEMPLATE_TYPE,
  getTemplates
)

export const addTemplate = createAsyncThunkForSlice(
  CREATE_TEMPLATE_TYPE,
  createTemplate,
  { isToast: true }
)

export const editTemplate = createAsyncThunkForSlice(
  UPDATE_TEMPLATE_TYPE,
  updateTemplate,
  {
    isToast: true,
  }
)
export const removeTemplate = createAsyncThunkForSlice(
  DELETE_TEMPLATE_TYPE,
  deleteTemplate,
  { isToast: true }
)

export const getTemplateById = createAsyncThunkForSlice(
  GET_TEMPLATE_BY_ID_TYPE,
  getTemplateId
)
