import {
  Level4_CHECK,
  Level4_CODE,
  Level4_LIST,
  Level4_NAME,
} from '../constants/constant'
import { t } from 'i18next'

export interface IaddLevel4Validators {
  [Level4_NAME]: {
    required: string
  }
  [Level4_CODE]: {}
  [Level4_CHECK]: {}
  [Level4_LIST]: {
    required: string
  }
}
export const addLevl4Validators = {
  [Level4_NAME]: {
    required: t('SupplierMaster.name_validation'),
  },
  [Level4_CODE]: {},
  [Level4_CHECK]: {},
  [Level4_LIST]: {
    required: t('ViewChartOfAccount.card_validation'),
  },
}
