import styles from './tableData.module.scss'
import { t } from 'i18next'

export const inusranceMasterHeaderData: any = [
  {
    Header: t('InsuranceMaster.MARKETPLACE'),
    accessor: 'marketplace_name',
  },
  {
    Header: t('InsuranceMaster.INSURANCE_COMPANY'),
    accessor: 'insurance_company_name',
  },
  {
    Header: t('InsuranceMaster.PLAN'),
    accessor: 'insurance_plan',
  },
  {
    Header: t('InsuranceMaster.CLAIM_TYPES'),
    Cell: ({ row }: any) => {
      let claimType = row?.original?.claim_type
      return (
        <>
          {claimType?.map((item: any, index: number) => (
            <p key={`${item}-${index}`}>{item}</p>
          ))}
        </>
      )
    },
  },
  {
    Header: t('ViewReports.REMARKS'),
    Cell: (props: any) => {
      return (
        <>
          {props?.row?.original?.remarks ? (
            <span
              className={styles.blueLinkText}
              onClick={() => {
                props?.onClick(props?.row?.original)
              }}
            >
              {t('Common.View')}
            </span>
          ) : (
            '-'
          )}
        </>
      )
    },
  },
  {
    Header: t('InsuranceMaster.DEPARTMENTS'),
    Cell: (props: any) => {
      return (
        <>
          {props?.row?.original?.departments?.length > 0 ? (
            <span
              className={styles.blueLinkText}
              onClick={() => {
                props?.onRowClick(props?.row?.original)
              }}
            >
              {t('Common.View')}
            </span>
          ) : (
            '-'
          )}
        </>
      )
    },
  },
  {
    Header: t('InsuranceMaster.DOCUMENTS'),
    Cell: (props: any) => {
      return (
        <>
          {props?.row?.original?.attachments?.length > 0 ? (
            <span
              className={styles.blueLinkText}
              onClick={() => {
                props?.onPopClose(props?.row?.original?._id)
              }}
            >
              {t('Common.View')}
            </span>
          ) : (
            '-'
          )}
        </>
      )
    },
  },
]
