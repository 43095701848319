import { createSlice } from '@reduxjs/toolkit'
import {
  getAllEmployeeAttendanceAction,
  addAllEmployeeAttendanceAction,
  getMonthlyEmployeeAttendanceAction,
} from './attendanceAsyncAction'

export interface IAttendance {
  isLoading: boolean
  error: any
  getAttendance: any
  addAttendance: any
  getMonthlyAttendance: any
  setAttendanceData: any
  setAddAttendanceData: any
  getAttendanceReducer: any
  setAllData: any
  attendenceOption: any
  selectedEmployList: any
  selectedMonthYear: any
  dateArray: any
  employIDList: any
  setAttendanceType: string
  attendanceHoliday: any
}
export const initialState: IAttendance = {
  isLoading: false,
  error: null,
  getAttendance: [],
  setAllData: [],
  addAttendance: {},
  setAttendanceData: {},
  setAddAttendanceData: [],
  getAttendanceReducer: {},
  getMonthlyAttendance: [],
  attendenceOption: '',
  selectedEmployList: [],
  selectedMonthYear: {},
  dateArray: [],
  employIDList: [],
  setAttendanceType: '',
  attendanceHoliday: [],
}
export const attendanceSlice = createSlice({
  name: 'attendanceSlice',
  initialState,
  reducers: {
    clearState: (state) => {
      state.isLoading = false
      state.error = ''
      state.getAttendance = []
      state.addAttendance = {}
      state.setAttendanceData = {}
      state.setAddAttendanceData = []
      state.getAttendanceReducer = {}
      state.selectedEmployList = []
      state.employIDList = []
      state.selectedEmployList = []
      state.setAttendanceType = ''
    },

    setDataInAttendance: (state, action) => {
      state.setAttendanceData = action?.payload
    },
    setAddDataInAttendance: (state, action) => {
      state.getAttendance = action?.payload
    },
    addAllDataBySelectInAttendance: (state, action) => {
      const { emp_id } = action?.payload
      const isDuplicate = state?.setAllData.find(
        (item: any) => item?.emp_id === emp_id
      )
      if (!isDuplicate) {
        state.setAllData = [...state.setAllData, action?.payload]
      }
    },
    setDeleteArrayAttendance: (state, action) => {
      state.setAllData = state.setAllData?.filter(
        (data: any) => data?._id !== action?.payload
      )
    },

    // set Attendence Option
    setAttendenceOption: (state, action) => {
      state.attendenceOption = action.payload
    },

    // Set Emplyess List
    setSelectedEmployes: (state, action) => {
      state.selectedEmployList = [action.payload, ...state.selectedEmployList]
      state.employIDList = [action.payload?._id, ...state.employIDList]
    },
    // Remove Item From Employee List
    removeSelctedEmployes: (state, action) => {
      state.selectedEmployList = state.selectedEmployList?.filter(
        (item: any) => {
          return item?._id !== action.payload
        }
      )
      state.employIDList = state.employIDList?.filter((item: any) => {
        return item !== action.payload
      })
    },

    setSelectedMonthYear: (state, action) => {
      state.selectedMonthYear = action.payload
    },
    setValueInAttendance: (state, action) => {
      state.setAttendanceType = action.payload
    },
    clearEmployIDList: (state) => {
      state.employIDList = []
    },
    clearSelectedEmployList: (state) => {
      state.selectedEmployList = []
    },
  },
  extraReducers(builder) {
    // GET ALL EMPLOYEE ATTENDANCE
    builder
      .addCase(getAllEmployeeAttendanceAction.pending, (state) => {
        state.isLoading = true
      })
      .addCase(getAllEmployeeAttendanceAction.fulfilled, (state, action) => {
        state.isLoading = false
        state.getAttendance = action?.payload?.data
      })
      .addCase(getAllEmployeeAttendanceAction.rejected, (state, error) => {
        state.isLoading = false
        state.error = error
      })
    // ADD ALL EMPLOYEE ATTENDANCE
    builder
      .addCase(addAllEmployeeAttendanceAction.pending, (state) => {
        state.isLoading = true
      })
      .addCase(addAllEmployeeAttendanceAction.fulfilled, (state, action) => {
        state.isLoading = false
        state.addAttendance = action?.payload
      })
      .addCase(addAllEmployeeAttendanceAction.rejected, (state, error) => {
        state.isLoading = false
        state.error = error
      })
    // ADD MONTHLY EMPLOYEE ATTENDANCE
    builder
      .addCase(getMonthlyEmployeeAttendanceAction.pending, (state) => {
        state.isLoading = true
      })
      .addCase(
        getMonthlyEmployeeAttendanceAction.fulfilled,
        (state, action) => {
          state.isLoading = false
          state.getMonthlyAttendance = action?.payload?.data?.map(
            (item: any) => {
              return { ...item, holiday: action?.payload?.holydays }
            }
          )
          // state.getMonthlyAttendance = action?.payload?.data
        }
      )
      .addCase(getMonthlyEmployeeAttendanceAction.rejected, (state, error) => {
        state.isLoading = false
        state.error = error
      })
  },
})
export const {
  setDataInAttendance,
  setAddDataInAttendance,
  clearState,
  addAllDataBySelectInAttendance,
  setDeleteArrayAttendance,
  setAttendenceOption,
  setSelectedEmployes,
  removeSelctedEmployes,
  setSelectedMonthYear,
  setValueInAttendance,
  clearEmployIDList,
  clearSelectedEmployList,
} = attendanceSlice.actions
export default attendanceSlice.reducer
