// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.issuemainstoretabledata_select__FKgCU {
  width: 93px;
}

.issuemainstoretabledata_inputField__L3G9N {
  border: 1px solid var(--grey6);
  border-radius: 6px;
  padding: 8px;
  width: 70px;
}

.issuemainstoretabledata_poselect__bqL0f {
  width: 80%;
  margin: auto;
}`, "",{"version":3,"sources":["webpack://./src/pages/mainstore/mainstore/issuemainstoretabledata.module.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;AACF;;AAEA;EACE,8BAAA;EACA,kBAAA;EACA,YAAA;EACA,WAAA;AACF;;AAEA;EACE,UAAA;EACA,YAAA;AACF","sourcesContent":[".select {\n  width: 93px;\n}\n\n.inputField {\n  border: 1px solid var(--grey6);\n  border-radius: 6px;\n  padding: 8px;\n  width: 70px;\n}\n\n.poselect {\n  width: 80%;\n  margin: auto;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"select": `issuemainstoretabledata_select__FKgCU`,
	"inputField": `issuemainstoretabledata_inputField__L3G9N`,
	"poselect": `issuemainstoretabledata_poselect__bqL0f`
};
export default ___CSS_LOADER_EXPORT___;
