import axios from "axios";
import { CREATE_IPD_NEW_INVOICE, GET_ALL_IPD_INVOICE_JOBS, GET_INVENTORY_ITEM_ALL, GET_IPD_INVOICE_CASE, GET_IPD_INVOICE_PATIENT_DETAILS, GET_IPD_INVOICE_PATIENT_LIST, GET_IPD_INVOICE_TREATMENT, GET_PATIENT_PROFILE_PIC, UPDATE_IPD_INVOICE_TREATMENT } from "../../../config/config";
import { IAPIPayload } from "../../../interfaces/apiInterface";


export const ipdInvoicePatientList = (data: IAPIPayload) => {
    return axios.post(GET_IPD_INVOICE_PATIENT_LIST, data);
};

export const patientInvoiceDetails = (data: IAPIPayload) => {
    return axios.post(GET_IPD_INVOICE_PATIENT_DETAILS, data);
};

export const patientInvoiceCase = (data: IAPIPayload) => {
    return axios.post(GET_IPD_INVOICE_CASE, data);
};

export const patientInvoiceTreatment = (data: IAPIPayload) => {
    return axios.post(GET_IPD_INVOICE_TREATMENT, data);
};

export const patientInvoiceAllJobs = (data: IAPIPayload) => {
    return axios.post(GET_ALL_IPD_INVOICE_JOBS, data);
};

export const allInventoryItems = (data: IAPIPayload) => {
    return axios.post(GET_INVENTORY_ITEM_ALL, data);
};

export const createNewIpdInvoice = (data: IAPIPayload) => {
    return axios.post(CREATE_IPD_NEW_INVOICE, data);
};

export const patientProfilePic = (data: IAPIPayload) => {
    return axios.post(GET_PATIENT_PROFILE_PIC, data);
};

export const patientTreatmentUpdate = (data: IAPIPayload) => {
    return axios.post(UPDATE_IPD_INVOICE_TREATMENT, data);
};