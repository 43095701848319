import {
  APPOINTMENT__BG_COLOR,
  APPOINTMENT_COLOR,
  APPOINTMENT_STATUS,
} from '../constants/constant'
import { t } from 'i18next'

export interface IMasterValueValidators {
  [APPOINTMENT_STATUS]: {
    required: string
  }
  [APPOINTMENT_COLOR]: {
    required: string
  }
  [APPOINTMENT__BG_COLOR]: {
    required: string
  }
}

export const masterValueVadidator: IMasterValueValidators = {
  [APPOINTMENT_STATUS]: {
    required: t('ManageCategory.Appointment_Status_validation'),
  },
  [APPOINTMENT_COLOR]: {
    required: t('ManageCategory.Color_validation'),
  },
  [APPOINTMENT__BG_COLOR]: {
    required: t('ManageCategory.BG_Color_validation'),
  },
}
