import { useEffect } from 'react'
import { useAppDispatch, useAppSelector } from '../../../hooks'
import styles from './dynamicFormSubmit.module.scss'
import { useLocation } from 'react-router-dom'
import { getSearchQueryinObject } from '../../../utils/utils'
import { requestGenerator } from '../../../utils/payloadGenerator'
import {
  getAllCreateNewFormById,
  submitDynamicFormData,
} from '../../../redux/features/create-new-form/createNewFormAsynActions'
import { Form } from 'react-formio/lib/components'
import Loader from '../../../components/common/spinner/Loader'

const DynamicFormSubmit = () => {
  const dispatch = useAppDispatch()
  const state = useLocation()
  const { isLoading, createNewFormByIdData } = useAppSelector(
    (state) => state.createNewForm
  )

  const queryString = getSearchQueryinObject(state?.search)

  useEffect(() => {
    const payload = {
      id: queryString?.form,
      que_sentby_user_id: queryString?.uid,
    }
    dispatch(getAllCreateNewFormById(requestGenerator(payload)))
  }, [])

  const handleFormSubmit = (formData: any) => {
    const reqPayload = {
      form_used_in: 'CRM_PATIENT_FEEDBACK',
      payload: formData?.data ? formData?.data : {},
      form_builder_id: queryString?.form,
      criteria_id: queryString?.cid,
      form_sentby_user_id: queryString?.uid,
      submitted_by_email: queryString?.email,
    }
    dispatch(submitDynamicFormData(requestGenerator(reqPayload))).then((e) => {
      if (e?.type === 'createNewForm/submitDynamicFormData/fulfilled') {
        setTimeout(() => {
          window.close()
        }, 3000)
      }
    })
  }

  return (
    <>
      {isLoading && <Loader />}
      <form className={styles.submitQuestionContainer}>
        <div style={{ minHeight: '300px' }}>
          <section className={styles.sectionContainer}>
            {createNewFormByIdData?.form && (
              <Form
                form={JSON.parse(createNewFormByIdData?.form)}
                onSubmit={(e: any) => handleFormSubmit(e)}
              />
            )}
          </section>
        </div>
      </form>
    </>
  )
}

export default DynamicFormSubmit
