// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.viewSpecialitiesPopup_popupContainer__ZNtDV {
  width: 400px;
  margin: auto;
  background: var(--white1);
  border-radius: 10px;
  padding: 10px;
}
.viewSpecialitiesPopup_popupContainer__ZNtDV .viewSpecialitiesPopup_closeIconStyle__XJtyS {
  cursor: pointer;
  float: right;
}
.viewSpecialitiesPopup_popupContainer__ZNtDV .viewSpecialitiesPopup_viewContent__Cc8IB {
  margin: 50px 10px;
  text-align: center;
}
.viewSpecialitiesPopup_popupContainer__ZNtDV .viewSpecialitiesPopup_viewContent__Cc8IB .viewSpecialitiesPopup_title__TRLok {
  text-align: center;
  font-weight: var(--font-semibold);
  font-size: var(--font-24);
  line-height: 36px;
  color: var(--black2);
}
.viewSpecialitiesPopup_popupContainer__ZNtDV .viewSpecialitiesPopup_viewContent__Cc8IB .viewSpecialitiesPopup_dividerStyle__2HRbP {
  margin: 10px auto 30px auto;
  width: 80px;
  height: 3px;
  background: var(--green1);
}
.viewSpecialitiesPopup_popupContainer__ZNtDV .viewSpecialitiesPopup_viewContent__Cc8IB .viewSpecialitiesPopup_tableContainer__3UIFb {
  max-height: 250px;
  overflow-y: scroll;
}
.viewSpecialitiesPopup_popupContainer__ZNtDV .viewSpecialitiesPopup_viewContent__Cc8IB .viewSpecialitiesPopup_tableContainer__3UIFb::-webkit-scrollbar {
  display: block !important;
  width: 7px !important;
  height: 7px !important;
}
.viewSpecialitiesPopup_popupContainer__ZNtDV .viewSpecialitiesPopup_viewContent__Cc8IB .viewSpecialitiesPopup_tableContainer__3UIFb::-webkit-scrollbar-track {
  background: transparent !important;
}
.viewSpecialitiesPopup_popupContainer__ZNtDV .viewSpecialitiesPopup_viewContent__Cc8IB .viewSpecialitiesPopup_tableContainer__3UIFb::-webkit-scrollbar-thumb {
  background-color: var(--grey7) !important;
  border-radius: 10px !important;
  border: 1px solid var(--grey7) !important;
}`, "",{"version":3,"sources":["webpack://./src/components/common/modal/view-specialities-popup/viewSpecialitiesPopup.module.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;EACA,YAAA;EACA,yBAAA;EACA,mBAAA;EACA,aAAA;AACF;AAAE;EACE,eAAA;EACA,YAAA;AAEJ;AAAE;EACE,iBAAA;EACA,kBAAA;AAEJ;AADI;EACE,kBAAA;EACA,iCAAA;EACA,yBAAA;EACA,iBAAA;EACA,oBAAA;AAGN;AADI;EACE,2BAAA;EACA,WAAA;EACA,WAAA;EACA,yBAAA;AAGN;AADI;EACE,iBAAA;EACA,kBAAA;AAGN;AACM;EACE,yBAAA;EACA,qBAAA;EACA,sBAAA;AACR;AACM;EACE,kCAAA;AACR;AAEM;EACE,yCAAA;EACA,8BAAA;EACA,yCAAA;AAAR","sourcesContent":[".popupContainer {\n  width: 400px;\n  margin: auto;\n  background: var(--white1);\n  border-radius: 10px;\n  padding: 10px;\n  .closeIconStyle {\n    cursor: pointer;\n    float: right;\n  }\n  .viewContent {\n    margin: 50px 10px;\n    text-align: center;\n    .title {\n      text-align: center;\n      font-weight: var(--font-semibold);\n      font-size: var(--font-24);\n      line-height: 36px;\n      color: var(--black2);\n    }\n    .dividerStyle {\n      margin: 10px auto 30px auto;\n      width: 80px;\n      height: 3px;\n      background: var(--green1);\n    }\n    .tableContainer {\n      max-height: 250px;\n      overflow-y: scroll;\n      // .customClassHeader {\n      //   width: 120px;\n      // }\n      &::-webkit-scrollbar {\n        display: block !important;\n        width: 7px !important;\n        height: 7px !important;\n      }\n      &::-webkit-scrollbar-track {\n        background: transparent !important;\n      }\n\n      &::-webkit-scrollbar-thumb {\n        background-color: var(--grey7) !important;\n        border-radius: 10px !important;\n        border: 1px solid var(--grey7) !important;\n      }\n    }\n    // .tableContainer::-webkit-scrollbar {\n    //   display: none;\n    // }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"popupContainer": `viewSpecialitiesPopup_popupContainer__ZNtDV`,
	"closeIconStyle": `viewSpecialitiesPopup_closeIconStyle__XJtyS`,
	"viewContent": `viewSpecialitiesPopup_viewContent__Cc8IB`,
	"title": `viewSpecialitiesPopup_title__TRLok`,
	"dividerStyle": `viewSpecialitiesPopup_dividerStyle__2HRbP`,
	"tableContainer": `viewSpecialitiesPopup_tableContainer__3UIFb`
};
export default ___CSS_LOADER_EXPORT___;
