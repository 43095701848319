import {
  AGE,
  FILE_NO,
  GENDER,
  LOCATION_ID,
  MOBILE_NO,
  NAME,
  PATIENT,
} from '../../../constants/createJobConstants'
import { t } from 'i18next'

export const createJobsValidators = {
  [NAME]: {
    required: t('PatientEmrValidators.PatientName'),
  },
  [FILE_NO]: {
    required: 'Please search file no',
  },
  // [FIXED_FILE_NO]: {
  //   required: 'Please search fixed file no',
  // },
  [MOBILE_NO]: {
    required: t('BookingConfirmationValidators.PATINET_MOBILE_NO'),
  },
  [PATIENT]: {
    required: 'Please select any one',
  },
  [AGE]: {
    required: 'Please enter age',
    pattern: {
      value: /^\d+$/,
      message: 'Please enter only numbers',
    },
    // validate: {
    //   handleZero: (value: any) => {
    //     if (value <= 0) {
    //       return 'Please enter valid age'
    //     }
    //   },
    // },
  },
  [GENDER]: {
    required: 'Please select any one ',
  },
  [LOCATION_ID]: {
    required: 'Please select any one ',
  },
  // [TASK]: {
  //   required: 'Please select any one ',
  // },
}
