import { Suspense, lazy } from "react";
import { ErrorBoundary } from "../../../../components/core/error-boundary/ErrorBoundary";
import Loader from "../../../../components/common/spinner/Loader";

const LazyLoadFollowUp = lazy(() => import("./FollowUp"));

const FollowUp = () => {
  return (
    <ErrorBoundary fallback={<>error occured....</>}>
      <Suspense fallback={<Loader />}>
        <LazyLoadFollowUp />
      </Suspense>
    </ErrorBoundary>
  );
};

export default FollowUp;
