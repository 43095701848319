import {
  GET_RECEPTIONIST_TYPE,
  IMPORT_APPOINTMENT_TYPE,
  IMPORT_INV_ITEMS_TYPE,
  IMPORT_INVOICE_TYPE,
  IMPORT_PATIENT_EMR_TYPE,
  IMPORT_RECEIPT_TYPE,
  IMPORT_TREATMENT_SERVICE_TYPE,
} from '../../../constants/asyncActionsType'
import createAsyncThunkForSlice from '../../../utils/utils'
import {
  getReceptionistWithBranch,
  importAppointment,
  importInvItems,
  importInvoice,
  importPatientEmr,
  importReceipt,
  importTreatmentSerives,
} from './importUtilityCrud'

export const importPatientEmrUtility = createAsyncThunkForSlice(
  IMPORT_PATIENT_EMR_TYPE,
  importPatientEmr,
  {
    isToast: true,
  }
)

export const importTreatementServicesUtility = createAsyncThunkForSlice(
  IMPORT_TREATMENT_SERVICE_TYPE,
  importTreatmentSerives,
  {
    isToast: true,
  }
)

export const importInvItemsUtility = createAsyncThunkForSlice(
  IMPORT_INV_ITEMS_TYPE,
  importInvItems,
  {
    isToast: true,
  }
)

export const importAppointmentUtility = createAsyncThunkForSlice(
  IMPORT_APPOINTMENT_TYPE,
  importAppointment,
  {
    isToast: true,
  }
)

export const getAllReceptionistWithBranches = createAsyncThunkForSlice(
  GET_RECEPTIONIST_TYPE,
  getReceptionistWithBranch
)

export const importReceiptUtility = createAsyncThunkForSlice(
  IMPORT_RECEIPT_TYPE,
  importReceipt,
  {
    isToast: true,
  }
)

export const importInvoiceUtility = createAsyncThunkForSlice(
  IMPORT_INVOICE_TYPE,
  importInvoice,
  {
    isToast: true,
  }
)
