import { t } from 'i18next'

export const searchCriteriaHeaderData: any = [
  {
    Header: t('CallCenterAdmin.LEAD_NAME'),
    accessor: (row: any) => {
      return `${row?.name}`
    },
  },

  {
    Header: t('CreateIPDFrom.Contact No.'),
    accessor: (row: any) => {
      const phone = row?.phone
      return phone ? phone : '-'
    },
  },
  {
    Header: t('LeadPool.AGE'),
    accessor: (row: any) => {
      const age = row?.age
      return age ? age : '-'
    },
  },
  {
    Header: t('LabConfiguration.GENDER'),
    accessor: (row: any) => {
      return `${row?.gender}`
    },
  },
  {
    Header: t('LeadPool.NATIONALITY'),
    accessor: (row: any) => {
      const nationality = row?.nationality
      return nationality ? nationality : '-'
    },
  },
  {
    Header: t('Marketplace_Form.PIN_CODE'),
    accessor: (row: any) => {
      const pinCode = row?.pincode
      return pinCode ? pinCode : '-'
    },
  },
  {
    Header: t('LeadPool.TAGS'),
    accessor: (row: any) => {
      const tags = row?.tags
      return (
        <>
          {tags && tags?.length > 0
            ? tags?.map((item: string, index: number) => {
                return item ? (
                  <div key={item}>
                    {index + 1}. {item}
                  </div>
                ) : (
                  ''
                )
              })
            : '-'}
        </>
      )
    },
  },
]
