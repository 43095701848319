import { t } from 'i18next'
import { CheckIcon, UncheckIcon } from '../../components/common/svg-components'
import { colors } from '../../constants/color'
import { useAppDispatch, useAppSelector } from '../../hooks'
import { setSelectedSlotStatus } from '../../redux/features/physio/physioSlice'
import moment from 'moment'

export const viewSlotsHeaderData: any = [
  {
    Header: t('RcptTableHeader.TIME'),
    Cell: (props: any) => {
      return <>{props.row.original.label}</>
    },
  },

  {
    Header: t('DocUploadHeader.ACTION'),
    Cell: ({ row }: any) => {
      const { selectedSlotsStatus } = useAppSelector((state) => state.physio)
      const dispatch = useAppDispatch()
      const handleSlotsSelection = (items: any) => {
        const updateArr = selectedSlotsStatus?.map((s: any) => {
          if (s.id === row.original.id) {
            return { ...s, selected: !s.selected }
          } else {
            return { ...s }
          }
        })
        dispatch(setSelectedSlotStatus(updateArr))
      }
      return row?.original?.selected ? (
        <CheckIcon
          fillColor={colors.green1}
          handleClick={handleSlotsSelection}
        />
      ) : (
        <UncheckIcon
          fillColor={colors.grey1}
          handleClick={handleSlotsSelection}
        />
      )
    },
  },
]

export const viewSlotsHeaderDataRecurring: any = [
  {
    Header: t('RcptTableHeader.DATE'),
    // accessor: 'date',
    Cell: ({ row }: any) => {
      return <>{moment(row?.original?.date).format('DD-MM-YYYY')}</>
    },
  },
  {
    Header: t('ViewSlots.DAYS'),
    accessor: 'day',
  },
  {
    Header: t('RcptTableHeader.TIME'),
    Cell: (props: any) => {
      return <>{props.row.original.label}</>
    },
  },
  {
    Header: t('DocUploadHeader.ACTION'),
    Cell: ({ row }: any) => {
      const { selectedSlotsStatus } = useAppSelector((state) => state.physio)
      const dispatch = useAppDispatch()
      const handleSlotsSelection = (items: any) => {
        const updateArr = selectedSlotsStatus?.map((s: any) => {
          if (s.id === row.original.id) {
            return { ...s, selected: !s.selected }
          } else {
            return { ...s }
          }
        })
        dispatch(setSelectedSlotStatus(updateArr))
      }
      return row?.original?.selected ? (
        <CheckIcon
          fillColor={colors.green1}
          handleClick={handleSlotsSelection}
        />
      ) : (
        <UncheckIcon
          fillColor={colors.grey1}
          handleClick={handleSlotsSelection}
        />
      )
    },
  },
]
