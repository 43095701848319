import { createSlice } from '@reduxjs/toolkit'
import {
  addEmployee,
  editEmployeeInfo,
  getAllEmployee,
  getAllSearchUserList,
  updateEmployeeStatus,
  deleteEmployee,
  getAllReportingUserList,
} from './employeeAsyncActions'

export interface IEmployee {
  isLoading: boolean
  allEmployeeData: []
  allEmployeeListDataObject: {}
  addEmployeeData: string
  editEmployeeData: {}
  error: any
  isStatusUpdated: boolean
  userListData: []
  reportingUserListData: []
  branchData: []
  nationalityData: []
  employeeLoader: boolean
  selectedObj: {}
}
export const initialState: IEmployee = {
  isLoading: false,
  allEmployeeData: [],
  allEmployeeListDataObject: {},
  addEmployeeData: '',
  editEmployeeData: {},
  isStatusUpdated: true,
  error: null,
  userListData: [],
  reportingUserListData: [],
  branchData: [],
  nationalityData: [],
  employeeLoader: false,
  selectedObj: {},
}

export const employeeSlice = createSlice({
  name: 'employee',
  initialState,
  reducers: {
    clearEmployeeData: (state) => {
      state.allEmployeeData = []
      state.editEmployeeData = {}
      state.userListData = []
      state.reportingUserListData = []
      state.branchData = []
      state.nationalityData = []
    },
    clearSearchEmployeeData: (state) => {
      state.allEmployeeData = []
      state.userListData = []
    },
    getEditEmployeeData: (state, action) => {
      state.editEmployeeData = action.payload
    },
    clearEditEmployeeData: (state) => {
      state.editEmployeeData = {}
    },
    clearBranchData: (state) => {
      state.branchData = []
    },

    setSelectedObj: (state, action) => {
      state.selectedObj = action.payload
    },
  },
  extraReducers(builder) {
    // GET ALL EMPLOYEE
    builder
      .addCase(getAllEmployee.pending, (state) => {
        state.employeeLoader = true
        state.isStatusUpdated = false
      })
      .addCase(getAllEmployee.fulfilled, (state, action) => {
        state.employeeLoader = false
        state.isStatusUpdated = false
        state.allEmployeeData = action.payload?.data
        // state.allEmployeeListDataObject = action.payload;
      })
      .addCase(getAllEmployee.rejected, (state, error) => {
        state.employeeLoader = false
        state.error = error
        state.isStatusUpdated = false
      })

    // ADD EMPLOYEE
    builder
      .addCase(addEmployee.pending, (state) => {
        state.isLoading = true
      })
      .addCase(addEmployee.fulfilled, (state, action) => {
        state.isLoading = false
        state.addEmployeeData = action.payload
      })
      .addCase(addEmployee.rejected, (state, error) => {
        state.isLoading = false
        state.error = error
      })

    // EDIT EMPLOYEE BY ID
    builder
      .addCase(editEmployeeInfo.pending, (state) => {
        state.isLoading = true
      })
      .addCase(editEmployeeInfo.fulfilled, (state, action) => {
        state.isLoading = false
        state.addEmployeeData = action.payload
      })
      .addCase(editEmployeeInfo.rejected, (state, error) => {
        state.isLoading = false
        state.error = error
      })

    // UPDATE EMPLOYEE STATUS
    builder
      .addCase(updateEmployeeStatus.pending, (state) => {
        state.isLoading = true
        state.isStatusUpdated = false
      })
      .addCase(updateEmployeeStatus.fulfilled, (state) => {
        state.isLoading = false
        state.isStatusUpdated = true
      })
      .addCase(updateEmployeeStatus.rejected, (state, error) => {
        state.isLoading = false
        state.isStatusUpdated = false
        state.error = error
      })
    //delete employee
    builder
      .addCase(deleteEmployee.pending, (state) => {
        state.isLoading = true
      })
      .addCase(deleteEmployee.fulfilled, (state) => {
        state.isLoading = false
      })
      .addCase(deleteEmployee.rejected, (state, error) => {
        state.isLoading = false
        state.error = error
      })
    // Search User List
    builder
      .addCase(getAllSearchUserList.pending, (state) => {
        state.isLoading = true
      })
      .addCase(getAllSearchUserList.fulfilled, (state, action) => {
        state.isLoading = false
        state.userListData = action?.payload?.data
      })
      .addCase(getAllSearchUserList.rejected, (state, error) => {
        state.isLoading = false
        state.error = error
      })

    // Reporting User List
    builder
      .addCase(getAllReportingUserList.pending, (state) => {
        state.isLoading = true
      })
      .addCase(getAllReportingUserList.fulfilled, (state, action) => {
        state.isLoading = false
        state.reportingUserListData = action?.payload?.data
      })
      .addCase(getAllReportingUserList.rejected, (state, error) => {
        state.isLoading = false
        state.error = error
      })
  },
})

export const {
  clearEditEmployeeData,
  clearEmployeeData,
  getEditEmployeeData,
  setSelectedObj,
} = employeeSlice.actions
export default employeeSlice.reducer
