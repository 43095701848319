import { useNavigate } from 'react-router-dom'
import { DeleteIcon, EditIcon } from '../../components/common/svg-components'
import { colors } from '../color'
import { t } from 'i18next'
import styles from './tableData.module.scss'

export const dynamicTemplateHeaderData: any = [
  {
    Header: t('AllAttachmentHeader.NAME'),
    accessor: 'name',
  },
  {
    Header: t('RcptTableHeader.TYPE'),
    accessor: 'type',
  },
  {
    Header: t('DynamicTemplate.DefaultTemp'),
    Cell: ({ row }: any) => {
      const is_default = row?.original?.is_default
      return <>{is_default ? 'Yes' : 'No'}</>
    },
  },
  {
    Header: t('DocUploadHeader.ACTION'),
    Cell: ({ row, onClick }: any) => {
      const handleEdit = (item: any, type: string) => {
        onClick(item, type)
      }
      return (
        <>
          <EditIcon
            fillColor={colors.grey4}
            handleClick={() => handleEdit(row?.original, 'edit')}
          />
          <DeleteIcon
            fillColor={colors.grey4}
            handleClick={() => handleEdit(row?.original, 'delete')}
            customClass={styles.deleteIcon}
          />
        </>
      )
    },
  },
]
