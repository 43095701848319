import { createSlice } from '@reduxjs/toolkit'
import { ICallCenterAdminDashbord } from '../../../interfaces/apiInterface'
import {
  adminDashbordAgentDetails,
  adminDashbordAgentStatusUpdate,
  getCallCenterDashbordAgent,
  getCallCenterDashbordAllLeads,
  getCallCenterDashbordAssignLeads,
  getCallCenterDashbordCount,
} from './callCenterDashbordAsyncActions'

export const initialState: ICallCenterAdminDashbord = {
  count: [],
  agentsList: [],
  allLeadsList: [],
  assignLeadList: [],
  selectLeadAssignFlag: false,
  isLoading: false,
  agentDetailsList: [],
}

export const adminDashbordSlice = createSlice({
  name: 'adminDashbord',
  initialState,
  reducers: {
    setAssignList: (state, action) => {
      state.assignLeadList = [...state.assignLeadList, action.payload]
    },

    removeFromAssignList: (state, action) => {
      state.assignLeadList = state.assignLeadList?.filter((item: any) => {
        return item?._id !== action.payload
      })
    },

    setSelectAllAssignList: (state, action) => {
      state.assignLeadList = [...state.assignLeadList, ...action.payload]
      // state.selectLeadAssignFlag = true;
    },

    resetAssignList: (state) => {
      state.assignLeadList = []
    },
  },

  extraReducers: (builder) => {
    // Dashbord Count
    builder.addCase(getCallCenterDashbordCount.pending, (state, action) => {
      state.isLoading = true
    })

    builder.addCase(getCallCenterDashbordCount.fulfilled, (state, action) => {
      state.isLoading = false
      state.count = action.payload
    })
    builder.addCase(getCallCenterDashbordCount.rejected, (state, error) => {
      state.isLoading = false
    })

    // GET ALL DASHBORD AGENT LIST
    builder.addCase(getCallCenterDashbordAgent.pending, (state, action) => {
      state.isLoading = true
    })

    builder.addCase(getCallCenterDashbordAgent.fulfilled, (state, action) => {
      state.isLoading = false
      state.agentsList = action.payload
    })
    builder.addCase(getCallCenterDashbordAgent.rejected, (state, error) => {
      state.isLoading = false
    })

    // GET ALL LEADS
    builder.addCase(getCallCenterDashbordAllLeads.pending, (state, action) => {
      state.isLoading = true
    })

    builder.addCase(
      getCallCenterDashbordAllLeads.fulfilled,
      (state, action) => {
        state.isLoading = false
        state.allLeadsList = action.payload
      }
    )
    builder.addCase(getCallCenterDashbordAllLeads.rejected, (state, error) => {
      state.isLoading = false
    })

    //ASSIGN LEADS
    builder.addCase(getCallCenterDashbordAssignLeads.pending, (state) => {
      state.isLoading = true
    })

    builder.addCase(
      getCallCenterDashbordAssignLeads.fulfilled,
      (state, action) => {
        state.isLoading = false
        // state.allLeadsList = action.payload;
      }
    )
    builder.addCase(
      getCallCenterDashbordAssignLeads.rejected,
      (state, error) => {
        state.isLoading = false
      }
    )

    //DASHBORD AGENT STATUS UPDATE
    builder.addCase(adminDashbordAgentStatusUpdate.pending, (state, action) => {
      state.isLoading = true
    })

    builder.addCase(
      adminDashbordAgentStatusUpdate.fulfilled,
      (state, action) => {
        state.isLoading = false
        // state.allLeadsList = action.payload;
      }
    )
    builder.addCase(adminDashbordAgentStatusUpdate.rejected, (state, error) => {
      state.isLoading = false
    })

    // DASHBORD AGENT DETAILS
    builder.addCase(adminDashbordAgentDetails.pending, (state, action) => {
      state.isLoading = true
    })

    builder.addCase(adminDashbordAgentDetails.fulfilled, (state, action) => {
      state.isLoading = false
      state.agentDetailsList = action.payload
    })
    builder.addCase(adminDashbordAgentDetails.rejected, (state, error) => {
      state.isLoading = false
    })
  },
})

export const {
  setAssignList,
  removeFromAssignList,
  setSelectAllAssignList,
  resetAssignList,
} = adminDashbordSlice.actions
export default adminDashbordSlice.reducer
